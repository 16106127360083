import React from "react";

const FreeTrial = (props) => {
  const className = `mt-4 ${props.className}`;
  return (
    <p className={className}>
      {" "}
      <a href="/get-started" className={"linkbutton"}>
        Unlock free trial {"->"}
      </a>
    </p>
  );
};

export default FreeTrial;
