import React from "react";
import Intro from "./intro";
import Switch from "../../components/Switch";
// import VideoPlayer from "../../components/VideoPlayer";
import GetStartedForm from "../../components/GetStartedForm";
import {t} from "i18next";
import CircleCheckSvg from "../../assets/svgs/circle-tick.svg";

const AffiliateCampaign = () => {
  return (
    <div className={"view-home"}>
      <Intro />
      <Switch />
      {/*<VideoPlayer venueText={"Venue"} />*/}
      <div className='mt-12 flex flex-row'>
        <div className='mt-8 flex flex-col justify-between md:pr-[60px] mb-7 flex-1'>
          <h2 className='text-[32px] md:text-40 font-extrabold leading-[50px] my-6'>
            Request a demo
            <span className='text-primary1'> And </span>
            try it yourself
          </h2>
          <p className='text-14 md:text-18 text-[#666666] font-medium leading-7 my-7 md:my-0 mb-2'>
            {t("forms.get_started.description")}
          </p>

          <p className='text-14 md:text-18 text-[#666666] font-medium leading-7 mt-6'>
            {t("forms.get_started.description_2")}
          </p>
        </div>

        <div className='mt-8 bg-[#F9F7F7] border-[1px] border-[#E0E0E0] rounded-[13px] py-8 pl-8 pr-8 md:pr-8'>
          <div className='flex flex-row mb-6 items-center  '>
            <img
              src={CircleCheckSvg}
              className='w-5 h-5 md:w-[25px] md:h-[25px] mr-4'
            />
            <p className='text-16 md:text-18 text-[#666666] font-medium leading-7'>
              {t("forms.get_started.content.part_1")}
            </p>
          </div>
          <div className='flex flex-row mb-6 items-center  '>
            <img
              src={CircleCheckSvg}
              className='w-5 h-5 md:w-[25px] md:h-[25px] mr-4'
            />
            <p className='text-16 md:text-18 text-[#666666] font-medium leading-7'>
              {t("forms.get_started.content.part_2")}
            </p>
          </div>
          <div className='flex flex-row mb-6 items-center'>
            <img
              src={CircleCheckSvg}
              className='w-5 h-5 md:w-[25px] md:h-[25px] mr-4'
            />
            <p className='text-16 md:text-18 text-[#666666] font-medium leading-7'>
              {t("forms.get_started.content.part_3")}{" "}
            </p>
          </div>
          <div className='flex flex-row mb-6 items-center'>
            <img
              src={CircleCheckSvg}
              className='w-5 h-5 md:w-[25px] md:h-[25px] mr-4'
            />
            <p className='text-16 md:text-18 text-[#666666] font-medium leading-7'>
              {t("forms.get_started.content.part_4")}
            </p>
          </div>
          <div className='flex flex-row mb-6 items-center'>
            <img
              src={CircleCheckSvg}
              className='w-5 h-5 md:w-[25px] md:h-[25px] mr-4'
            />
            <p className='text-16 md:text-18 text-[#666666] font-medium leading-7'>
              {t("forms.get_started.content.part_5")}
            </p>
          </div>
        </div>
      </div>
      <GetStartedForm />
    </div>
  );
};

export default AffiliateCampaign;
