import React, { useEffect, useMemo, useState } from "react";
import "./index.css";
import { Seo } from "../../components/Seo";
import "keen-slider/keen-slider.min.css";
import { isMobile } from "react-device-detect";
import BlogSubscribeFloatButton from "../../components/BlogSubscribeFloatButton";
import QuizCompletionModal from "./quiz/quiz-completion-modal ";
import { useNavigate, useParams } from "react-router-dom";
import { RelatedBlogs } from "./related-blogs";
import { BlogHeader } from "./blog-header";
import { StaticBlogDetail } from "./static-blog-detail";
import QuizComponent from "../../components/Quiz/Quiz";
import { calculateWordCount } from "../../utils/common";
import { analyticsService } from "../../services/analytics";
import { identifyDevice, trackEvent } from "../../utils/mixpanelUtil";
import { FaChevronRight } from "react-icons/fa";
import { blogDetailsData } from "./blog-details-data";
const { v4: uuidv4 } = require("uuid");

const BlogDetailUpdated = () => {
  // ** States
  const user_id = uuidv4();
  const { id } = useParams();

  const [questions, setQuestions] = useState();
  const [displayQuiz, setDisplayQuiz] = useState(true);
  const [results, setResults] = useState();
  const navigate = useNavigate();
  const [activeHeading, setActiveHeading] = useState(null);
  const [introduction, setIntroduction] = useState("");
  const [{ headings, totalWords }, setContentData] = useState({
    headings: [],
    totalWords: 0,
  });
  const [currentBlog, setCurrentBlog] = useState({});
  const [blogCountCalled, setBlogCountCalled] = useState(false);

  const getBlogs = () => {
    window.scrollTo(0, 0);

    if (id) {
      const startTime = performance.now();
      analyticsService()
        .getBlogsById(id)
        .then((res = {}) => {
          const endTime = performance.now();
          const duration = endTime - startTime;
          console.log(`Data fetched in ${duration / 1000} seconds`);

          if (res.is_new_type === 0) {
            const currentBlog = blogDetailsData.find(
              (bd) => bd.slug === res.slug
            );
            setCurrentBlog(currentBlog);
          } else {
            setCurrentBlog(res);
          }
        })
        .catch((err) => {
          if (err.error === "Blog not found") {
            const currentBlog = blogDetailsData.find((bd) => bd.slug === id);
            if (currentBlog) {
              setCurrentBlog(currentBlog);
            } else {
              console.log(err, "error");
            }
          } else {
            console.log(err, "error");
          }
        });
    }
  };

  useEffect(() => {
    getBlogs();
  }, [id]);

  const addBlogCount = (payload) => {
    if (!blogCountCalled) {
      analyticsService()
        .blogCount(payload)
        .then((res) => {
          setQuestions(res);
          identifyDevice();
          trackEvent("Blog View", {
            action_category: "load_blog",
            action_outcome: "success",
            interaction_element: "screen",
            source: "vb_web",
          });
          trackEvent(currentBlog?.title, {
            action_category: "blog_details",
            action_outcome: "success",
            interaction_element: "screen",
            source: "vb_web",
          });
        })
        .catch(() => {
          setQuestions(undefined);
          identifyDevice();
          trackEvent("Blog View", {
            action_category: "load_blog",
            action_outcome: "fail",
            interaction_element: "screen",
            source: "vb_web",
          });
        });
      setBlogCountCalled(true);
    }
  };

  const blogs = useMemo(
    () => JSON.parse(localStorage.getItem("showQuiz")) ?? [],
    []
  );

  const userDetails = useMemo(() => {
    if (blogs) {
      const blogExists = blogs.some(
        (entry) => entry.blog_id === currentBlog.id
      );
      const sumEarned = blogs.reduce(
        (acc, entry) => acc + Number(entry.earned_from_quiz),
        0
      );
      return { blogExists, sumEarned };
    }
    return { blogExists: false, sumEarned: 0 };
  }, [blogs, currentBlog?.id]);

  const showQuiz = useMemo(() => {
    if (
      !questions ||
      questions.can_show_quiz === false ||
      userDetails.blogExists
    ) {
      return false;
    }

    const maxSum =
      userDetails.sumEarned +
      Number(questions.credits_per_correct_answer) *
        questions.quiz.questions.length;

    return maxSum <= Number(questions.max_earn) && displayQuiz;
  }, [questions, userDetails, displayQuiz]);

  const answerArray = (quiz) => {
    return quiz.questions.map((userAnswer, index) => {
      const question = questions.quiz.questions[index];
      const correctAnswer = question.answers.find(
        (answer) => answer.is_correct === 1
      );

      return {
        quiz_id: questions.quiz.id,
        question_id: question.id,
        answer_id: correctAnswer.id,
      };
    });
  };

  const updateLocalStorage = (value) => {
    const blogIdExists = blogs.findIndex(
      (blog) => blog.blog_id === currentBlog.id
    );

    const blogIdProps = {
      user_id,
      earned_from_quiz: value.correctPoints,
    };

    if (blogIdExists !== -1) {
      blogs[blogIdExists] = {
        ...blogs[blogIdExists],
        ...blogIdProps,
      };
    } else {
      blogs.push({
        blog_id: currentBlog.id,
        ...blogIdProps,
      });
    }

    localStorage.setItem("showQuiz", JSON.stringify(blogs));
  };

  const isValidPayload = (payload) => {
    return (
      payload.blog_title &&
      payload.blog_content &&
      payload.blog_word_count > 0 &&
      payload.author_name &&
      payload.author_designation &&
      payload.read_time
    );
  };

  const createQuiz = (value) => {
    const payload = {
      session_id: user_id,
      responses: answerArray(value),
    };
    if (questions) {
      setResults(value);
      analyticsService()
        .createQuiz(payload)
        .then(() => {
          updateLocalStorage(value);
          identifyDevice();
          trackEvent("Quiz", {
            action_category: "Complete Quiz",
            action_outcome: "success",
            interaction_element: "button",
            source: "vb_web",
          });
        })
        .catch((err) => {
          identifyDevice();
          trackEvent("Quiz", {
            action_category: "Complete Quiz",
            action_outcome: "fail",
            interaction_element: "button",
            source: "vb_web",
          });
        });
    }
  };

  useEffect(() => {
    if (
      currentBlog &&
      Object.keys(currentBlog).length > 0 &&
      introduction &&
      totalWords > 0 &&
      !blogCountCalled
    ) {
      const payload = {
        blog_title: currentBlog?.title,
        blog_content: currentBlog?.introduction ?? introduction,
        blog_word_count:
          calculateWordCount(currentBlog) > 0
            ? calculateWordCount(currentBlog)
            : totalWords,
        author_name: currentBlog?.author_name,
        author_designation: currentBlog?.author_designation ?? "-",
        read_time: parseInt(currentBlog?.time_read ?? currentBlog?.read_time),
      };
      if (isValidPayload(payload)) {
        addBlogCount(payload);
        setBlogCountCalled(true);
      }
    }
  }, [currentBlog, introduction, totalWords, blogCountCalled]);

  useEffect(() => {
    if (!currentBlog?.body) return;

    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(currentBlog.body, "text/html");

    // Handle headings
    ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].forEach(tag => {
      htmlDoc.querySelectorAll(tag).forEach(element => {
        if (element.childNodes.length === 1 && element.childNodes[0].nodeName === "BR") {
          element.parentNode.removeChild(element);
        } else {
          element.classList.add('custom-heading', `custom-${tag}`);
        }
      });
    });

    // Handle large and huge strong elements
    htmlDoc.querySelectorAll("strong.ql-size-large, strong.ql-size-huge").forEach(element => {
      element.classList.add("toc-heading", "custom-large-text");
    });

    // Handle paragraphs
    htmlDoc.querySelectorAll("p").forEach(element => {
      element.classList.add("custom-paragraph");
      if (element.childNodes.length === 1 && element.childNodes[0].nodeName === "BR") {
        element.parentNode.removeChild(element);
      }
    });

    // Handle lists
    htmlDoc.querySelectorAll("ul, ol").forEach(element => {
      element.classList.add("custom-list");
    });

    // Handle list items
    htmlDoc.querySelectorAll("li").forEach(element => {
      element.classList.add("custom-list-item");
    });

    // Handle links
    htmlDoc.querySelectorAll("a").forEach(element => {
      element.classList.add("custom-link");
      if (element.getAttribute("target") === "_blank") {
        element.setAttribute("rel", "noopener noreferrer");
      }
    });

    // Update blog body
    currentBlog.body = htmlDoc.body.innerHTML;

    // Extract TOC headings
    const tocHeadings = Array.from(htmlDoc.querySelectorAll(".toc-heading")).map(heading => heading.textContent);
    setContentData(prev => ({ ...prev, headings: tocHeadings }));

    // Calculate total words
    const totalWords = Array.from(htmlDoc.querySelectorAll("p"))
        .reduce((acc, paragraph) => acc + paragraph.textContent.trim().split(/\s+/).length, 0);
    setContentData(prev => ({ ...prev, totalWords }));

    // Set introduction
    const firstParagraph = htmlDoc.querySelector("p");
    setIntroduction(firstParagraph ? firstParagraph.textContent : "");

  }, [currentBlog?.body]);

  const handleClose = () => {
    setResults(undefined);
    setDisplayQuiz(false);
  };

  const handleOk = () => {
    navigate("/get-started");
    setResults(undefined);
    setDisplayQuiz(false);
  };

  const currentUrl = "https://venueboost.io" + currentBlog?.slug_related;

  const handleFacebookShare = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        currentUrl
      )}`,
      "_blank"
    );
  };

  const handleTwitterShare = () => {
    const text = "Check out this awesome page!"; // Custom text for tweet
    window.open(
      `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        currentUrl
      )}&text=${encodeURIComponent(text)}`,
      "_blank"
    );
  };

  const handleLinkedInShare = () => {
    window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
        currentUrl
      )}`,
      "_blank"
    );
  };

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(currentUrl)
      .then(() => alert("Link copied to clipboard!"))
      .catch((err) => console.error("Error copying link: ", err));
  };

  let tagsString = "exploring,mysterical";
  if (currentBlog?.tags) {
    tagsString = currentBlog.tags;
  }

  const tagsArray = useMemo(() => tagsString.split(","), [tagsString]);

  const tagsWithHash = tagsArray.map((tag) => `${tag.trim()}`).join(" ");

  return (
    <>
      {results && (
        <QuizCompletionModal
          handleClose={handleClose}
          open={Boolean(results)}
          scores={results}
          handleOk={handleOk}
        />
      )}

      <div
        style={{ maxWidth: isMobile ? "" : "1366px" }}
        className={
          isMobile
            ? "align-col-middle"
            : "align-col-middle mx-auto px-4 sm:px-6 md:px-8 lg:px-16"
        }
      >
        <Seo
          title={currentBlog?.title + " | VenueBoost"}
          description={
            currentBlog?.introduction ?? currentBlog?.section_1_content
          }
          image={currentBlog?.image}
          url={currentUrl}
        />
        <BlogHeader
          currentBlog={currentBlog}
          handleCopyLink={handleCopyLink}
          handleFacebookShare={handleFacebookShare}
          handleLinkedInShare={handleLinkedInShare}
          handleTwitterShare={handleTwitterShare}
          isMobile={isMobile}
        />

        <div
          style={{ paddingLeft: "16px", paddingRight: "16px" }}
          className="flex gap-[30px] flex-col md:flex-row content mt-5"
        >
          {currentBlog?.is_new_type === 1 ? (
            <>
              <div
                className="custom-quill flex flex-col gap-4 w-full md:w-[75%]"
                dangerouslySetInnerHTML={{ __html: currentBlog?.body }}
              />
            </>
          ) : (
            currentBlog && (
              <StaticBlogDetail headings={headings} currentBlog={currentBlog} />
            )
          )}

          {!isMobile && headings.length > 0 && (
            <div className="w-full md:w-[25%] mt-1">
              <h2 className="font-bold">Table of contents</h2>
              <ul className="mt-4 overflow-scroll">
                {headings.map((heading, index) => (
                  <li
                    key={index}
                    className={`my-2 cursor-pointer flex items-center ${
                      activeHeading === heading
                        ? "bg-white hd-shadow font-bold"
                        : ""
                    }`}
                    onClick={() => setActiveHeading(heading)}
                  >
                    <div>
                      <FaChevronRight size="0.8em" />
                    </div>
                    <div className="ml-2">{heading}</div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        <div
          className="w-full"
          style={{ paddingLeft: "16px", paddingRight: "16px" }}
        >
          {currentBlog?.tags && (
            <p
              className={
                "text-5 font-normal text-[#666666] md:text-17  leading-[26px] md:leading-[32px] mt-4"
              }
            >
              <b>{tagsWithHash}</b>
            </p>
          )}

          {currentBlog?.has_tags === true && (
            <p
              className={
                "text-5 font-normal text-[#666666] md:text-17  leading-[26px] md:leading-[32px] mt-4"
              }
            >
              <b>
                #VenueBoost #SimplifyOptimizeGrow &nbsp;
                {currentBlog.own_tags && <>{currentBlog.own_tags}</>}
                {!currentBlog.own_tags && <>#Simplify #Grow #Optimize</>}
              </b>
            </p>
          )}
        </div>

        <div
          className="flex flex-col w-full mt-4 mb-10 gap-6"
          style={{ paddingLeft: "16px", paddingRight: "16px" }}
        >
          <div className="flex flex-row justify-start w-full gap-5">
            <div
              onClick={handleTwitterShare}
              className="cursor-pointer w-[48px] h-[48px] bg-gradient-to-r from-[#240B3B] via-[#5D1C82] to-[#BC14FE] rounded-full flex items-center justify-center"
            >
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.533 7.59759C21.5482 7.81078 21.5482 8.02401 21.5482 8.2372C21.5482 14.7397 16.599 22.2321 7.5533 22.2321C4.76648 22.2321 2.17767 21.425 0 20.024C0.395953 20.0697 0.776625 20.0849 1.18781 20.0849C3.48727 20.0849 5.60405 19.3083 7.29441 17.9834C5.13197 17.9377 3.31978 16.5215 2.69541 14.5722C3 14.6179 3.30455 14.6483 3.62437 14.6483C4.06598 14.6483 4.50764 14.5874 4.91878 14.4809C2.66498 14.024 0.974578 12.0443 0.974578 9.65343V9.59254C1.62937 9.95803 2.39086 10.1864 3.19791 10.2169C1.87303 9.33361 1.00505 7.82601 1.00505 6.12042C1.00505 5.20673 1.24866 4.36917 1.67508 3.6382C4.09641 6.62296 7.73602 8.57217 11.8172 8.7854C11.7411 8.41992 11.6954 8.03925 11.6954 7.65853C11.6954 4.94784 13.8883 2.73975 16.6141 2.73975C18.0304 2.73975 19.3095 3.33365 20.208 4.29304C21.3197 4.07986 22.3857 3.66867 23.3299 3.10523C22.9643 4.24739 22.1877 5.20678 21.1674 5.81587C22.1573 5.70932 23.1167 5.43515 23.9999 5.05448C23.33 6.02906 22.4924 6.89704 21.533 7.59759Z"
                  fill="white"
                />
              </svg>
            </div>
            <div
              onClick={handleLinkedInShare}
              className="cursor-pointer w-[48px] h-[48px] bg-gradient-to-r from-[#240B3B] via-[#5D1C82] to-[#BC14FE] rounded-full flex items-center justify-center"
            >
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_123_1524)">
                  <path
                    d="M6.20062 21.4859H1.84688V7.46555H6.20062V21.4859ZM4.02141 5.55305C2.62922 5.55305 1.5 4.39992 1.5 3.00773C1.5 2.33902 1.76565 1.69769 2.2385 1.22483C2.71136 0.751975 3.35269 0.486328 4.02141 0.486328C4.69012 0.486328 5.33145 0.751975 5.80431 1.22483C6.27716 1.69769 6.54281 2.33902 6.54281 3.00773C6.54281 4.39992 5.41313 5.55305 4.02141 5.55305ZM22.4953 21.4859H18.1509V14.6609C18.1509 13.0343 18.1181 10.9484 15.8873 10.9484C13.6237 10.9484 13.2769 12.7155 13.2769 14.5437V21.4859H8.92781V7.46555H13.1034V9.37805H13.1644C13.7456 8.27648 15.1655 7.11398 17.2838 7.11398C21.69 7.11398 22.5 10.0155 22.5 13.7843V21.4859H22.4953Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_123_1524">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      transform="translate(0 0.48584)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div
              onClick={handleFacebookShare}
              className="cursor-pointer w-[48px] h-[48px] bg-gradient-to-r from-[#240B3B] via-[#5D1C82] to-[#BC14FE] rounded-full flex items-center justify-center"
            >
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_123_1527)">
                  <path
                    d="M17.584 13.9858L18.2505 9.6424H14.0829V6.82381C14.0829 5.63553 14.6651 4.47725 16.5316 4.47725H18.4263V0.779277C18.4263 0.779277 16.707 0.48584 15.063 0.48584C11.6309 0.48584 9.38742 2.56615 9.38742 6.33209V9.6424H5.57227V13.9858H9.38742V24.4858H14.0829V13.9858H17.584Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_123_1527">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      transform="translate(0 0.48584)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div
              onClick={handleCopyLink}
              className="cursor-pointer w-[48px] h-[48px] bg-gradient-to-r from-[#240B3B] via-[#5D1C82] to-[#BC14FE] rounded-full flex items-center justify-center"
            >
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.9999 16.2358C17.5959 16.2357 17.196 16.3174 16.8244 16.4762C16.4528 16.6349 16.1173 16.8673 15.838 17.1593L8.90054 13.256C9.03492 12.7514 9.03492 12.2203 8.90054 11.7157L15.838 7.81241C16.3432 8.33616 17.022 8.65776 17.7473 8.71695C18.4726 8.77614 19.1946 8.56886 19.778 8.13394C20.3614 7.69903 20.7662 7.06632 20.9166 6.35434C21.067 5.64237 20.9527 4.89999 20.595 4.26627C20.2373 3.63256 19.6609 3.15099 18.9736 2.91179C18.2864 2.6726 17.5355 2.69218 16.8616 2.96687C16.1878 3.24156 15.6372 3.75251 15.3131 4.40401C14.9889 5.0555 14.9134 5.80284 15.1007 6.506L8.1632 10.4093C7.7488 9.97721 7.2146 9.679 6.62933 9.55302C6.04405 9.42704 5.43447 9.47905 4.879 9.70237C4.32353 9.92569 3.84758 10.3101 3.51237 10.8061C3.17717 11.3022 2.99805 11.8872 2.99805 12.4858C2.99805 13.0845 3.17717 13.6695 3.51237 14.1656C3.84758 14.6616 4.32353 15.046 4.879 15.2693C5.43447 15.4926 6.04405 15.5447 6.62933 15.4187C7.2146 15.2927 7.7488 14.9945 8.1632 14.5624L15.1007 18.4657C14.9398 19.0713 14.9726 19.712 15.1945 20.298C15.4163 20.8839 15.816 21.3858 16.3376 21.733C16.8591 22.0802 17.4763 22.2555 18.1025 22.2341C18.7287 22.2127 19.3325 21.9958 19.8291 21.6138C20.3258 21.2319 20.6904 20.704 20.8717 20.1042C21.0531 19.5045 21.0421 18.863 20.8404 18.2698C20.6386 17.6766 20.2562 17.1615 19.7468 16.7967C19.2373 16.4319 18.6265 16.2358 17.9999 16.2358Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
        </div>

        <div
          className="w-full"
          style={{ paddingLeft: "16px", paddingRight: "16px" }}
        >
          <div className="w-full md:w-[880px] bg-gradient-to-r from-[#240B3B] via-[#5D1C82] to-[#BC14FE] rounded-md gap-5 px-8 py-10 flex items-center justify-start">
            <div className="min-w-[60px] h-[52px] rounded-xl bg-white flex justify-center items-center">
              <svg
                width="29"
                height="30"
                viewBox="0 0 29 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.4008 2.28564C7.44266 2.28564 1.80078 7.92752 1.80078 14.8856C1.80078 21.8438 7.44266 27.4856 14.4008 27.4856C21.3589 27.4856 27.0008 21.8438 27.0008 14.8856C27.0008 7.92752 21.3589 2.28564 14.4008 2.28564ZM14.4008 25.3481C8.62391 25.3481 3.93828 20.6625 3.93828 14.8856C3.93828 9.10877 8.62391 4.42314 14.4008 4.42314C20.1777 4.42314 24.8633 9.10877 24.8633 14.8856C24.8633 20.6625 20.1777 25.3481 14.4008 25.3481Z"
                  fill="#240B3B"
                />
                <path
                  d="M17.5391 9.39322C16.6954 8.65354 15.5816 8.24854 14.4004 8.24854C13.2191 8.24854 12.1054 8.65635 11.2616 9.39322C10.3841 10.161 9.90039 11.1932 9.90039 12.2985V12.5123C9.90039 12.636 10.0016 12.7373 10.1254 12.7373H11.4754C11.5991 12.7373 11.7004 12.636 11.7004 12.5123V12.2985C11.7004 11.0582 12.9126 10.0485 14.4004 10.0485C15.8882 10.0485 17.1004 11.0582 17.1004 12.2985C17.1004 13.1732 16.4816 13.9748 15.5226 14.3432C14.9263 14.571 14.4201 14.9704 14.0573 15.4935C13.6888 16.0279 13.4976 16.6692 13.4976 17.3188V17.9235C13.4976 18.0473 13.5988 18.1485 13.7226 18.1485H15.0726C15.1963 18.1485 15.2976 18.0473 15.2976 17.9235V17.2851C15.299 17.0121 15.3827 16.7458 15.5378 16.521C15.6928 16.2963 15.9119 16.1235 16.1666 16.0251C17.826 15.3867 18.8976 13.9242 18.8976 12.2985C18.9004 11.1932 18.4166 10.161 17.5391 9.39322ZM13.2754 21.0735C13.2754 21.3719 13.3939 21.6581 13.6049 21.869C13.8159 22.08 14.102 22.1985 14.4004 22.1985C14.6988 22.1985 14.9849 22.08 15.1959 21.869C15.4069 21.6581 15.5254 21.3719 15.5254 21.0735C15.5254 20.7752 15.4069 20.489 15.1959 20.278C14.9849 20.0671 14.6988 19.9485 14.4004 19.9485C14.102 19.9485 13.8159 20.0671 13.6049 20.278C13.3939 20.489 13.2754 20.7752 13.2754 21.0735Z"
                  fill="#240B3B"
                />
              </svg>
            </div>
            <div className="text-16 font-semibold text-white">
              Questions? Comments? Visit our Contact Page for support.
            </div>
          </div>
        </div>

        {showQuiz &&
          questions &&
          questions.quiz.questions.length > 0 &&
          !results && (
            <div style={{ paddingLeft: "16px", paddingRight: "16px" }}>
              <QuizComponent
                handleSubmit={createQuiz}
                questions={questions}
                results={results}
              />
            </div>
          )}

        <RelatedBlogs />
      </div>
      <BlogSubscribeFloatButton />
    </>
  );
};

export default BlogDetailUpdated;
