import { CircularProgress } from "@mui/material";
import { t } from "i18next";
import React from "react";

const FooterButtons = ({
  loading = false,
  onSubmit,
  setStep,
  step = 1,
  next = t("who_we_serve.accommodation_industry.next"),
}) => {
  return (
    <div className="w-full px-8 justify-between flex absolute bottom-[58px] left-0">
      {step > 1 && step < 4 ? (
        <button
          onClick={() => setStep(step - 1)}
          className="text-[#707070] text-16 mt-5 leading-[16px] border border-[#240b3b] font-semibold rounded-[63px] px-[26px] h-[36px]"
        >
          {t("who_we_serve.accommodation_industry.back")}
        </button>
      ) : (
        <div></div>
      )}
      <button
        onClick={onSubmit}
        className="bg-[#240b3b]  rounded-[63px] px-[26px] h-[36px] text-white text-16 mt-5 self-end leading-[16px] font-semibold"
      >
        {loading ? <CircularProgress size={16} /> : next}
      </button>
    </div>
  );
};

export default FooterButtons;
