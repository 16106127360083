import React from 'react';

const GuideItem = ({ image, title }) => {
  return (
    <div className='flex flex-col border rounded-xl'>
      <img src={image} alt='guide-item' className='rounded-t-xl' />
      <div className='flex flex-col px-6 py-4 gap-10'>
        <span className='font-extrabold text-28'>
          {title}
        </span>
        <span className='bg-[#E1484833] text-sm text-[#E14848] font-semibold px-3 py-0.5 rounded-[4px] w-max'>
          Retail Management
        </span>
      </div>
    </div>
  )
}

export default GuideItem;
