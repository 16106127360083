import React from "react";

const ProductCard = ({ tag, img, title, desc, slug }) => {
  return (
    <div className="relative flex flex-col  bg-white blog-card overflow-hidden border-[1px] rounded-[13px]">
      <img
        className="w-full h-[160px] md:h-[208px] rounded-t-[13px]"
        src={img}
        alt="aboutus"
      />
      <div className="py-4 md:py-5 gap-6 flex flex-col justify-start items-start pt-[33px] px-[24px]">

        <div className="flex flex-col gap-4">
          <p className="text-[#111010] text-[18px] font-semibold leading-5">
            {title}
          </p>
        </div>

        <div className="flex flex-col gap-4">
          <p className="text-[#717484] text-[14px] font-normal leading-5">
            {'June 2, 2023'}
          </p>
        </div>

        <div class="flex items-start justify-start">
          <a class="h-[39px] w-[140px] rounded-md bg-gradient-to-r from-[#240B3B] via-[#3D1E59] to-[#BC14FE] p-[2px]" href={`/product-updates/${slug}`}>
            <div class="flex h-full w-full items-center justify-center bg-white rounded-md">
              <h1 class="text-14 font-semibold text-[#373941]">Read story -></h1>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
