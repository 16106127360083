  import React from "react";

const StoryCard = ({ img, title, date, desc, slug }) => {
  return (
    <div className="relative flex flex-col bg-transparent blog-card overflow-hidden w-full h-[424px] md:w-[388px] md:h-[546px] border-[1px] rounded-[13px] border-[#E0E0E0]">
      <img
        className="w-full h-[220px] md:min-h-[284px] rounded-t-[13px] object-cover"
        src={img}
        alt="aboutus"
      />
      <div className="flex flex-col gap-[15px] md:gap-[25px] justify-center items-start px-6 py-8 md:py-8">

        <p className="text-[#252525] text-18 md:text-[24px] font-semibold leading-[22px] md:leading-7">
          {title}
        </p>

        <p className="right-6 md:bottom-0 md:right-0 md:relative text-[#7F7F7F] text-[10px] md:text-14 leading-5 font-normal">
          {date}
        </p>
        
        <div class="flex items-center justify-start">
          <div class="flex p-[2px] justify-center items-center h-[32px] w-[104px] md:w-[133px] md:h-[42px] rounded-md bg-gradient-to-br from-[#240B3B] via-[#3D1E59] to-[#BC14FE]">
            <div class="flex w-full h-full items-center justify-center bg-white rounded-md">
              <a
                className=" text-[10px] md:text-14 font-semibold text-[#373941]"
                href={`/resources/success-stories/${slug}`}
              >
                Read story <span> →</span>
              </a>
            </div>
          </div>
        </div>

        
      </div>
    </div>
  );
};

export default StoryCard;