import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { productOptions } from "../data";
import "../index.css";

import { beachBarOrderText } from "..";

const Details = (props) => {
  const {
    className = "",
    product,
    selectedItem,
    setSelectedItem,
    setPreviewProducts,
    products,
    selectedProduct,
  } = props;
  const [note, setNote] = useState("");
  const [selectedOption, setSelectedOption] = useState(0);

  const [productList, setProductList] = useState(productOptions);

  useEffect(() => {
    setProductList(
      productList.map((item, index) => {
        return index === selectedOption
          ? { ...item, quantity: selectedProduct.value }
          : item;
      })
    );
  }, [selectedProduct, selectedOption]);

  useEffect(() => {
    setSelectedItem(productList[selectedOption]);
  }, [selectedOption, setSelectedItem]);

  const maxLength = 250;

  const handleNoteChange = (e) => {
    setNote(e.target.value);
  };

  const handleOptionChange = (index) => {
    const updatedObj = {
      ...selectedProduct,
      name: `${selectedProduct.name} (${selectedItem?.size})`,
      totalPrice:
        Number(productList[index]?.price ?? 0) *
        Number(selectedProduct?.value ?? 0),
    };

    setSelectedOption(index);
    setPreviewProducts([...products, updatedObj]);
  };

  return (
    <div
      className={`w-full flex-1 flex flex-col gap-5 text-left text-base text-neutral-neutral-600 font-manrope ${className}`}
    >
      <div className="flex flex-col gap-[12px] text-xl text-neutral-neutral-800 w-full">
        <div className="flex w-full items-center justify-between gap-5">
          <h3 className="m-0 relative text-inherit leading-[30px] font-extrabold font-inherit text-[#32324D]">
            {product?.name}
          </h3>
          <div className="w-[70px] flex gap-[2px] text-darkgray-100 text-[#AAAAAA]">
            <div className="flex flex-col pt-0 px-0 pb-[5px]">
              <b className="relative text-sm leading-[24px] inline-block min-w-[24px]">
                {product?.currency}
              </b>
            </div>
            <div className="flex-1 relative text-2xl leading-5 font-extrabold text-mediumseagreen text-[#17CE8C]">
              {product?.price}
            </div>
          </div>
        </div>
        <div className="relative text-sm leading-[24px] font-medium text-neutral-neutral-600 text-[#666687] product-details-description">
          {product?.description}
        </div>
      </div>
      <div className="flex flex-col gap-[12px]">
        <span className={"label-product-details-description"}>
          {beachBarOrderText("chooseVariation")}
        </span>

        <div className="flex flex-col gap-[15px] text-sm">
          {productList.map((option, index) => (
            <div key={index} className="flex product-volume">
              <div className="flex-1 rounded-2xl bg-bg flex items-center py-3 pr-2.5 pl-4 box-border gap-[10px]">
                <div className="flex-1 flex flex-row items-center justify-start gap-[10px] min-w-[162px]">
                  <input
                    className="m-0 h-5 w-5 relative text-[#17CE8C] custom-checkbox"
                    type="checkbox"
                    checked={selectedOption === index}
                    onChange={() => handleOptionChange(index)}
                  />
                  <b className="h-6 flex-1 relative leading-5 flex items-center text-[#666687]">
                    {option?.size}
                  </b>
                </div>
                <div className="flex flex-row text-5xs text-darkgray-200">
                  <div className="flex flex-col pt-0 px-0 pb-[5px]">
                    <b className="relative leading-[15px] inline-block min-w-[15px] text-[#AAAAAA]">
                      {option?.currency}
                    </b>
                  </div>
                  <b className="relative text-sm leading-5 inline-block text-mediumseagreen min-w-[27px] text-[#17CE8C]">
                    {option?.price}
                  </b>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="flex flex-col w-full gap-[12px]">
        <span className={"label-product-details-description"}>
          {beachBarOrderText("addNote")}{" "}
        </span>

        <div className="bg-white notes-block-bb-bar w-full flex flex-col gap-[5px] text-smi text-neutral-neutral-400 font-button-medium-extrabold">
          <div className="w-full flex flex-col text-sm text-lightslategray font-manrope">
            <div className="w-full rounded-2xl bg-bg flex flex-row py-2.5 px-[15px] border-[1px] border-solid border-whitesmoke">
              <div className="w-full  h-[58px] flex-1 flex flex-col items-start justify-between">
                <input
                  type="text"
                  value={note}
                  onChange={handleNoteChange}
                  className="w-full h-[38px] leading-[18px] font-semibold  border-none outline-none"
                  placeholder={beachBarOrderText("notePlaceholder")}
                />
                <div className="w-full text-xs leading-5 text-neutral-neutral-300 text-right text-[#C0C0CF]">
                  {note.length}/{maxLength}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Details.propTypes = {
  className: PropTypes.string,
};

export default Details;
