import React, { useState } from "react";
import { Popover } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguage } from "../../redux/actions/app";
import { Langs } from "../../constants/config";
import "./index.css";
import { AiOutlineCheck } from "react-icons/ai";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const LangSelector = () => {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.app.language);
  const [visible, setVisible] = useState(false);
  const [closeTimeout, setCloseTimeout] = useState(null);
  const navigate = useNavigate();

  const handleMouseEnter = () => {
    // Clear the close timeout when the mouse enters the dropdown
    if (closeTimeout) {
      clearTimeout(closeTimeout);
    }
    setVisible(true);
  };

  const handleMouseLeave = () => {
    // Set a delay before closing the dropdown
    const timeout = setTimeout(() => {
      setVisible(false);
    }, 200); // You can adjust the delay as needed
    setCloseTimeout(timeout);
  };

  return (
    <div className="lang-selector-container">
      <Popover
        overlayClassName={`lang-selector ${visible ? "active" : ""}`}
        placement="bottomRight"
        content={
          <ul
            className="gx-sub-popover"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {Langs.map((item) => (
              <li
                className={
                  "flex flex-row justify-center items-center w-full cursor-pointer py-1 px-1 " +
                  (item.id === language ? "bg-gray-200" : "bg-white")
                }
                key={item.id}
                onClick={(e) => {
                  dispatch(changeLanguage(item.id));
                  setVisible(false);
                  navigate(0);
                }}
              >
                <div className="w-5 flex justify-center items-center pl-1">
                  {item.id === language && <AiOutlineCheck size={18} />}
                </div>
                <div className="flex-1 text-16 ml-2">{item.name}</div>
              </li>
            ))}
          </ul>
        }
        trigger="click"
        open={visible}
        onOpenChange={(v) => {
          // Clear the close timeout when the popover state changes
          if (!v && closeTimeout) {
            clearTimeout(closeTimeout);
          }
          setVisible(v);
        }}
      >
        <div
          className="flex justify-center items-center cursor-pointer lang-btn"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <span className="pr-2">
            {Langs.find((l) => l.id === language)?.title}
          </span>
          {visible ? <FiChevronUp size={16} /> : <FiChevronDown size={16} />}
        </div>
      </Popover>
    </div>
  );
};

export default LangSelector;
