import React from 'react';
import Faq from '../../../components/Faq';

export default function Faqs() {
  const venueBoostAffiliateFAQ = [
    {
      id: 1,
      status: false,
      title: 'What is the VenueBoost Referral Program?',
      content: "The VenueBoost Referral Program is a rewarding opportunity for our users to share the benefits of VenueBoost with others. When you refer a new member, both you and the referred party can enjoy exclusive benefits and rewards as part of our community.",
    },
   {
     id: 2,
     status: false,
     title: 'How do I participate in the VenueBoost Referral Program?\n',
     content: "Participation is simple! Just share your unique referral link provided by VenueBoost with potential new members. When they sign up using your link, they become part of your referral network, and you'll be eligible for rewards.",
   },
  {
     id: 3,
     status: false,
     title: 'What benefits can I expect from referring someone to VenueBoost?\n',
     content: "Referrers in the VenueBoost program are rewarded with various benefits, such as discounts, exclusive features, or even monetary rewards. The specific benefits can vary, so we encourage you to check the latest updates in your VenueBoost account.",
  },
  {
     id: 4,
     status: false,
     title: 'Is there a limit to how many people I can refer to VenueBoost?',
     content: "Currently, there is no set limit to the number of people you can refer. The more successful referrals you make, the more rewards you can earn. It's our way of saying thank you for expanding the VenueBoost community.\n",
  },
  {
     id: 5,
     status: false,
     title: 'How does VenueBoost ensure fairness in the referral program?\n',
     content: "VenueBoost is committed to transparency and fairness in our referral program. We track each referral through unique links and ensure that rewards are accurately distributed. This system maintains clarity and trust among all participants in the program.",
  },
      {
          id: 6,
          status: false,
          title: 'How can I track my referrals in the VenueBoost Referral Program?\n',
          content: "In the VenueBoost Referral Program, you can effortlessly track your referrals through our user-friendly dashboard. Once you log in to your account, you'll find a dedicated section for referral tracking, displaying detailed information about each referral's status, including who has signed up through your link and the rewards you've earned.\n",
      }
  ];

  return (
    <div className='flex flex-col bg-[#F9F7F7] px-4 xl:px-14 pt-8 xl:pt-20 pb-4 xl:pb-8 gap-4 xl:gap-8 rounded-lg'>
      <span className='text-xl xl:text-4xl font-black text-center'>
        Frequently Asked Questions (FAQ(s))
      </span>
      <span className='text-[#666A73] text-lg xl:text-xl text-center'>
       VenueBoost Referral Program
      </span>
      <Faq faqData={venueBoostAffiliateFAQ} className="mt-2 mb-4 md:mb-16 rounded-lg" />
    </div>
  )
}
