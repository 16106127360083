import React, {useEffect, useState} from "react";
import "./index.css";
import ToolCard from "../../../components/ToolCard";
import BannerImg from "../../../assets/images/sitemap.png";
import {Pagination} from "antd";
import "react-multi-carousel/lib/styles.css";
import ToolImg1 from "../../../assets/images/tools/tools-guide-marketing.jpg";
import {Seo} from "../../../components/Seo";

const responsive = {
  superLargeDesktop: {
    breakpoint: {max: 4000, min: 3000},
    items: 1,
  },
  largeDesktop: {
    breakpoint: {max: 3000, min: 2050},
    items: 1,
  },
  desktop: {
    breakpoint: {max: 2050, min: 1240},
    items: 1,
  },
  tablet: {
    breakpoint: {max: 1240, min: 768},
    items: 1,
  },
  mobile1: {
    breakpoint: {max: 768, min: 425},
    items: 1,
  },
  mobile2: {
    breakpoint: {max: 425, min: 0},
    items: 1,
  },
};

const cardVariants = {
  offscreen: {
    scale: 0.8,
    opacity: 0,
  },
  onscreen: {
    scale: 1,
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0.1,
      duration: 0.8,
    },
  },
};

const Rtools = () => {
  const data = [
    {
      tagId: 1,
      title: "The Ultimate Marketing Essentials Toolkit for VenueBoost",
      date: "June 12, 2023",
      img: ToolImg1,
      slug: "the-ultimate-marketing-essentials-toolkit-for-venueboost",
    },
  ];

  const tags = [
    {title: "All", url: "/resources"},
    {
      title: "Marketing",
      url: "/resources",
    },
    {
      title: "Software",
      url: "/resources",
    },
    {
      title: "Venue Management",
      url: "/resources",
    },
    {
      title: "Account Management",
      url: "/resources",
    },
    {
      title: "News & Trends",
      url: "/resources",
    },
    {
      title: "Guides",
      url: "/resources",
    },
    {
      title: "eBook",
      url: "/resources",
    },
  ];

  const [tag, setTag] = useState(0);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(window.innerWidth > 640 ? 15 : 5);
  const onPaginationChange = (page, pageSize) => {
    setMinValue((page - 1) * pageSize);
    setMaxValue(page * pageSize);
  };

  return (
    <div className='flex flex-col align-col-middle view-terms'>
      <Seo title={"Resources - Tools and Guides | VenueBoost"} />
      <div className='flex flex-col align-middle'>
        <h1 className='text-[#312A37] text-[32px] md:text-[53px] leading-8 font-extrabold text-center px-5 py-10 md:py-20'>
          Tools and Guides
        </h1>
        <p className='text-[#666A73] text-14  md:text-20 font-semibold leading-7 text-center px-4 w-full md:w-[70%]'>
          VenueBoost is easy to use and becomes a part of your daily life in no
          time. We have developed tools and guides to help you through the
          initial phase and tap into the full potential of VenueBoost.
        </p>
      </div>
      <div>
        <h1 className='text-[#373941] text-[32px] leading-[44px] font-semibold mb-2 md:mb-9'>
          Filters
        </h1>
        <div className='flex flex-wrap gap-[10px] md:gap-5 mb-10'>
          {tags &&
            tags.length > 0 &&
            tags.map((tagItem, index) => (
              <a
                href={tagItem.url}
                key={index}
                className={`border-[1px] text-14  md:text-16 leading-4 border-primary1 px-[27px] py-[15px] rounded-[60px] ${
                  tag === index
                    ? "text-white bg-primary1"
                    : "text-primary1 bg-white"
                } `}
              >
                {tagItem.title}
              </a>
            ))}
        </div>
      </div>

      <div className='grid grid-cols-1 sm:grid-cols-2 gap-x-[47px] gap-y-[29px] md:gap-y-[37px]'>
        {data &&
          data.length > 0 &&
          data
            .slice(minValue, maxValue)
            .map((card, index) => (
              <ToolCard
                img={card.img}
                tag={tags[card.tagId].title}
                title={card.title}
                date={card.date}
                slug={card.slug}
              />
            ))}
      </div>
      <div className='mx-auto my-[56px] w-full align-middle blog-pagination'>
        <Pagination
          total={data.length}
          defaultCurrent={1}
          pageSize={window.innerWidth > 640 ? 15 : 5}
          onChange={onPaginationChange}
        />
      </div>
      <div className='w-[100vw]  relative translate-y-[1px]'>
        <img src={BannerImg} className='min-w-full h-[240px] object-cover' />
        <div className='absolute top-0  w-full h-full align-middle flex flex-col md:flex-row'>
          <h1 className='text-white text-24 md:text-[48px] leading-[41px] font-extrabold text-center md:mr-[95px] mb-8 md:mb-0 px-[25%]  md:px-0'>
            Elevate Your Business with VenueBoost
          </h1>
          <a
            className='text-[#240b3b] bg-white rounded-md text-16 md:text-18 leading-8 font-extrabold text-center px-9 md:px-20 py-1 md:py-[10px] whitespace-nowrap'
            href='/get-started'
          >
            Free Demo
          </a>
        </div>
      </div>
    </div>
  );
};

export default Rtools;
