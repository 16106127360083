import React, { useState } from "react";
import "../index.css";
import { arrowLeft, arrowRight } from "../icons";

export const ShopPagination = () => {
  const paginationLength = 10;
  const [page, setPage] = useState(1);

  const rangeStart = Math.max(0, page - 5);
  const rangeEnd = Math.min(paginationLength, rangeStart + 5);

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNext = () => {
    if (page < paginationLength) {
      setPage(page + 1);
    }
  };

  return (
    <div className="flex gap-2 items-center">
      <button onClick={handlePrevious} disabled={page === 1}>
        {arrowLeft(page)}
      </button>
      <div className="flex gap-2 justify-center items-center">
        {Array.from({ length: rangeEnd - rangeStart }).map((_, index) => {
          const actualPage = rangeStart + index;
          return (
            <div
              key={actualPage}
              className={`pagination-cell flex justify-center items-center cursor-pointer ${
                page === actualPage + 1
                  ? "bg-[#e14848] !text-white"
                  : "text-[#131118]"
              }`}
              onClick={() => setPage(actualPage + 1)}
            >
              <span>{actualPage + 1}</span>
            </div>
          );
        })}
      </div>
      <button onClick={handleNext} disabled={page === paginationLength}>
        {arrowRight(page, paginationLength)}
      </button>
    </div>
  );
};
