import React from "react";
import CircletickSvg from "../../assets/svgs/circle-tick.svg";
import CirclecrossSvg from "../../assets/svgs/circle-cross.svg";

import "./index.css";
import {t} from "i18next";

const Advantage = ({venueDefinition}) => {
  return (
    <div className="align-col-middle">
      <div className="w-[100vw] bg-white">
        <div className="mx-auto max-w-screen-main px-4 sm:px-6 md:px-8 lg:px-16">
          <div className="flex flex-col align-middle mt-0 md:mt-14 mb-4 md:mb-20">
            <div className="align-middle flex-col my-10 w-[80%]">
              <p className="text-[#240B3B] text-[26px] md:text-[32px] lg:text-[42px] font-extrabold text-center ">
                {t("components.advantage.title.part1")}
                <span className="text-[#240b3b]">{t("components.advantage.title.part2")}</span>
              </p>
              <p className="text-center font-semibold text-[#666A73] text-14 md:text-26 mt-4">
                {t("components.advantage.description", { venueDefinition: venueDefinition ? venueDefinition : 'restaurants' })}
              </p>

            </div>
            <div className="flex flex-row w-full rounded-t-[13px] mt-0 md:mt-16">
              <div className="w-full ">
                <div className="bg-[#F3F3F3] align-middle py-3 rounded-tl-[13px] md:h-[95px]">
                  <p className="text-[#5C1C81] font-bold text-14 md:text-[34px]">
                    {t("components.advantage.criteria.heading")}
                  </p>
                </div>
                <div className="">
                  <p className="h-[60px] md:h-[85px] flex items-center justify-start w-full px-2 md:px-8 text-[10px] md:text-20 font-bold text-[#240B3B] bg-[#fafafa]">
                    {t("components.advantage.criteria.actionableInsights")}
                  </p>
                  <p className="h-[60px] md:h-[85px] flex items-center justify-start w-full px-2 md:px-8 text-[10px] md:text-20 font-bold text-[#240B3B] bg-[#f3f3f3]">
                    {t("components.advantage.criteria.streamlineOperations")}
                  </p>
                  <p className="h-[60px] md:h-[85px] flex items-center justify-start w-full px-2 md:px-8 text-[10px] md:text-20 font-bold text-[#240B3B] bg-[#fafafa]">
                    {t("components.advantage.criteria.techPlatform")}
                  </p>
                  <p className="h-[60px] md:h-[85px] flex items-center justify-start w-full px-2 md:px-8 text-[10px] md:text-20 font-bold text-[#240B3B] bg-[#f3f3f3]">
                    {t("components.advantage.criteria.customerEngage")}
                  </p>
                </div>
              </div>
              <div className="w-full bg-[#f2f2f2] shadow-md">
                <div className="w-full h-full flex flex-col ">
                  <div className=" md:h-[117px] align-middle py-3 rounded-t-[13px] translate-y-[-24px] bg-gradient-to-r from-[#280B3B] via-[#5D1C82] to-[#BC14FE]">
                    <p className="text-14 md:text-[34px] font-bold text-white">
                      {t("components.advantage.venueBoost")}
                    </p>
                  </div>
                  <div className=" w-full align-middle flex-col translate-y-[-24px]">
                    <p className="flex items-center justify-center text-center w-full h-[60px] md:h-[85px] px-2 sm:px-6 lg:px-12 text-12 md:text-18 bg-[#fafafa]"> {t("components.advantage.unlimited")}</p>
                    <div className="bg-[#f3f3f3] w-full h-[60px] md:h-[85px] flex justify-center items-center ">
                      <img
                        alt="asd"
                        src={CircletickSvg}
                        className="w-[35px] h-[25px] "
                      />
                    </div>
                    <div className="bg-[#fafafa] w-full h-[60px] md:h-[85px] flex justify-center items-center">
                      <img
                        alt="asd"
                        src={CircletickSvg}
                        className="w-[35px] h-[25px] "
                      />
                    </div>
                    <div className="bg-[#f3f3f3] w-full h-[60px] md:h-[85px] flex justify-center items-center">
                      <img
                        alt="asd"
                        src={CircletickSvg}
                        className="w-[35px] h-[25px] "
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full">
                <div className="bg-[#F3F3F3]   align-middle py-3 rounded-tr-[13px] md:h-[95px]">
                  <p className="text-[#5C1C81] text-14 md:text-[34px] font-bold">
                    {t("components.advantage.others")}
                  </p>
                </div>
                <div className="w-full align-middle flex-col">
                  <p className="flex items-center justify-center text-center w-full h-[60px] md:h-[85px] px-2 sm:px-6 lg:px-12 text-12 md:text-18 bg-[#fafafa]">
                    {t("components.advantage.limitations")}
                  </p>
                  <div className="bg-[#f3f3f3] w-full h-[60px] md:h-[85px] flex justify-center items-center">
                    <img
                      alt="asd"
                      src={CirclecrossSvg}
                      className="w-[22px] h-[5px]"
                    />
                  </div>
                  <div className="bg-[#fafafa] w-full h-[60px] md:h-[85px] flex justify-center items-center">
                    <img
                      alt="asd"
                      src={CirclecrossSvg}
                      className="w-[22px] h-[5px]"
                    />
                  </div>
                  <div className="bg-[#f3f3f3] w-full h-[60px] md:h-[85px] flex justify-center items-center">
                    <img
                      alt="asd"
                      src={CirclecrossSvg}
                      className="w-[22px] h-[5px]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Advantage;
