import PropTypes from "prop-types";
import Product from "../Product";
import CustomDrawer from "../../../components/custom-drawer/custom-drawer";
import "../index.css";
import { minusIcon, plusIcon } from "../data";

const Content = ({
  className = "",
  product,
  setProducts,
  isOpen,
  setIsOpen,
  selectedProduct,
  setSelectedProduct,
  products = [],
  currency,
  index,
}) => {
  const toggleDrawer = (open) => () => {
    setIsOpen(open);
    if (!open) {
      setSelectedProduct(undefined);
    }
  };

  const updateQuantity = (value) => {
    setProducts((prevProducts) =>
      prevProducts.map((p, i) =>
        i === index
          ? {
              ...p,
              value:
                p.value < 1 && value === -1
                  ? 0
                  : `${parseInt(p.value) + value}`,
            }
          : p
      )
    );
  };

  return (
    <>
      <CustomDrawer isOpen={isOpen} toggleDrawer={toggleDrawer}>
        <Product
          {...{
            product,
            setIsOpen,
            selectedProduct,
            setProducts,
            index: selectedProduct?.index,
            products,
            currency,
          }}
        />
      </CustomDrawer>

      <div
        className={`cursor-pointer self-stretch  rounded-2xl bg-bg flex flex-row flex-wrap py-[5px] px-3 gap-[12px] text-left text-sm text-neutral-neutral-800 font-manrope max-w-full w-full min-w-[300px] ${className}`}
      >
        {product.imgSrc && (
          <div
            onClick={toggleDrawer(true)}
            className="h-[82px] w-[59px] relative bg-bg overflow-hidden shrink-0"
          >
            <img
              className="absolute top-[calc(50%_-_31.4px)] left-[calc(50%_-_16.5px)] w-[33.9px] h-[62.8px] object-contain"
              loading="lazy"
              alt=""
              src={product.imgSrc}
            />
          </div>
        )}
        <div
          onClick={toggleDrawer(true)}
          className="flex-1 flex flex-col pt-1 px-0 pb-0 box-border min-w-[127px]"
        >
          <div className="self-stretch flex flex-col gap-[12px]">
            <div className="self-stretch flex flex-col gap-[4px]">
              <b className="self-stretch relative leading-[22px] text-[16px] font-bold">
                {product?.name}
              </b>
              <div className="flex flex-row items-center text-xs text-neutral-neutral-300 font-button-medium-extrabold">
                <div className="relative leading-[16px] font-semibold inline-block min-w-[64px] text-[#c0c0cf] text-[14px]">
                  {product?.description}
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-[2px] text-5xs text-darkgray-100 font-button-medium-extrabold">
              <div className="flex flex-col items-start justify-center pt-0 px-0 pb-[5px]">
                <b className="relative leading-[15px] inline-block min-w-[15px] text-[#AAAA]">
                  {product?.currency}
                </b>
              </div>
              <b className="text-[#17CE8C] relative text-sm leading-[20px] inline-block font-manrope text-mediumseagreen min-w-[25px]">
                {product?.price}
              </b>
            </div>
          </div>
        </div>
        <div className="flex flex-col h-full pt-[2.4px] px-0 pb-0 text-2xs-8 text-neutral-neutral-600 font-button-medium-extrabold">
          <div className="flex items-end h-full">
            <div className="flex flex-col items-center justify-between h-full w-full gap-[6.2px]">
              <div className="flex pr-2 pb-2">
                <div
                  className="h-[26px] w-[26px] rounded-[12.36px] bg-mintcream flex py-[5.4px] px-[5px] box-border"
                  onClick={() => updateQuantity(-1)}
                >
                  <span className="h-[13.9px] w-[13.9px] relative shrink-0">
                    {minusIcon}
                  </span>
                </div>
              </div>
              <div className="relative leading-[15.44px] font-black inline-block min-w-[7px]">
                {product?.value ?? ""}
              </div>
              <div className="flex pr-2 pb-2">
                <div
                  className="h-[26px] w-[26px] rounded-[12.36px] bg-mintcream flex py-[5.4px] px-[5px] box-border"
                  onClick={() => updateQuantity(1)}
                >
                  <span className="h-[13.9px] w-[13.9px] relative shrink-0">
                    {plusIcon}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Content.propTypes = {
  className: PropTypes.string,
  product: PropTypes.shape({
    imgSrc: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    currency: PropTypes.string,
    price: PropTypes.string,
    value: PropTypes.string,
  }),
  index: PropTypes.number.isRequired,
  setProducts: PropTypes.func.isRequired,
};

export default Content;
