import React, { useState } from "react";
import "./index.css";
import {useLocation, useNavigate} from "react-router-dom";

const DropdownMenu = ({ title, menu }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const isResourceRoute = location.pathname === '/features'
    || location.pathname === '/affiliates'
    || `/${location.pathname.split('/')[1]}` === '/blog';

    const isWhyVenueBoostRoute = location.pathname === '/why-venueboost'
    || location.pathname === '/resources/success-stories'
    || location.pathname === '/about';

    const navigateToUrl = (url) => {
        navigate(url);
    };
        return (
            <>
                <div
                    className={`flex w-full text-16 lg:h-20 align-middle ${isWhyVenueBoostRoute && title === 'Why VenueBoost' ? 'text-[#8b1dbf]' : ''} ${isResourceRoute && title === 'Resources' ? 'text-[#8b1dbf]' : ''} nav-item-header font-medium text-[#666A73] transition flex gap-1 cursor-pointer px-4 hover:text-black1/75"`}
                    onMouseEnter={() => setIsCollapsed(true)}
                    onMouseLeave={() => setIsCollapsed(false)}
                    onClick={() => menu.length === 0 ? navigateToUrl("about") : "#"}
                >
                    <span className="whitespace-nowrap"> {title}</span>
                </div>

                {menu.length !== 0 && (
                    <div
                    className={`  fixed overflow-hidden right-1/4 left-1/4 z-10 rounded-[13px] bg-white px-5  transition-all duration-100 delay-100 ${
                    isCollapsed
                    ? "flex py-2 lg:py-5 dropdownmenu max-h-full"
                    : "max-h-0 py-0 lg:py-0"
                }`}
                    onMouseLeave={() => setIsCollapsed(false)}
                    onMouseEnter={() => setIsCollapsed(true)}
                    >
                    <ul className={`grid grid-cols-2 gap-2 w-70 text-18`}>
                       {menu.map((item, i) => (
                          <li
                            key={i}
                            className="px-4 py-1 lg:py-2 text-xl hover:bg-gray-100 rounded-lg"
                          >
                            <a href={item.url} className="flex" style={{alignItems: "center"}}>
                            {item.icon &&
                                <img
                                    src={item.icon}
                                    className={"w-7 h-7"}
                                    alt="icon"
                                />
                            }
                        <div className="ml-5">
                        <span className="text-20  text-[#312A37] font-extrabold">
                    {item.label}
                        </span>
                    {item.subtitle && (
                        <p
                        // className={`${
                        //   className === "mobile" ? "text-xs mb-4" : "text-sm"
                        // } text-slate-600`}
                        className="text-xs lg:text-[13px] font-medium text-[#666666]"
                        key={item.key}
                        >
                    {item.subtitle}
                        </p>
                        )}
                        </div>
                        </a>
                          </li>
                       ))}
                    </ul>
                    </div>
                )}
            </>
        );
};

export default DropdownMenu;
