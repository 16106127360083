import React from "react";
import { ShopPagination } from "./shop-pagination";
import RetailProductCard from "./retail-product-card";
import EmptyState from "./empty-state";

const ProductItems = ({
  className = "md:grid-cols-3",
  topIcon,
  onClickView,
  buttonLabel,
  onAddCard,
  showPagination = false,
  data = [],
  showBottom,
  showDescription,
  cartProp = true,
}) => {
  const props = {
    topIcon,
    onClickView,
    buttonLabel,
    onAddCard,
    showBottom,
    showDescription,
    cartProp,
  };
  return (
    <div className="w-full">
      <div className={`md:grid ${className} md:gap-[30px] mb-10`}>
        {data.length > 0 ? (
          data.map((product, index) => (
            <RetailProductCard product={product} key={index} {...props} />
          ))
        ) : (
          <EmptyState />
        )}
      </div>
      {showPagination && (
        <div className="col-span-3 w-full flex justify-center items-center">
          <ShopPagination />
        </div>
      )}
    </div>
  );
};

export default ProductItems;
