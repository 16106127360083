import {
  cashIcon,
  creditCard,
  email,
  location,
  phone,
} from "../../venue-retail/icons";
import Product1 from "../../../assets/images/cart/Group 427319082.png";
import Product2 from "../../../assets/images/cart/kindpng_4794023 1.png";
import handProduct from "../../../assets/images/retail/handproduct.png";
import secondImg from "../../../assets/images/retail/second_img.png";
import thirdImg from "../../../assets/images/retail/Group 427319072.png";
import fourthImg from "../../../assets/images/retail/Group 427319073.png";
import Accessories from "../../../assets/images/retail/Accessories.png";
import Decoration from "../../../assets/images/retail/Decoration.png";
import Jewelry from "../../../assets/images/retail/Jewelry.png";
import Watches from "../../../assets/images/retail/Watches.png";

export const addressData = [
  {
    name: "Robert Fox",
    defaultAddress: true,
    address: "4517 Washington Ave. Manchester, Kentucky 39495",
  },
  {
    name: "John Willions",
    defaultAddress: false,
    address: "3891 Ranchview Dr. Richardson, California 62639",
  },
];

export const paymentOptions = [
  {
    value: "Card",
    label: "Credit Card",
    icon: creditCard,
  },
  { value: "Cash", label: "Cash on Delivery", icon: cashIcon },
];

export const productData = [
  {
    image_path: Product1,
    title: "Iconic Swan Drop Earrings",
    short_description: "Color: Black",
    price: "$80",
  },
  {
    image_path: Product2,
    title: "Luna Cocktail Ring",
    short_description: "Size: 55",
    price: "$80",
  },
];

export const colorData = [
  { bg_color: "bg-[#E15353]" },
  { bg_color: "bg-[#5553E1]" },
  { bg_color: "bg-[#E1A053]" },
  { bg_color: "bg-[#131118]" },
  { bg_color: "bg-[#A3D139]" },
  { bg_color: "bg-[#E1D353]" },
];

export const dummyData = [
  {
    icon: handProduct,
    price: "€ 450.00",
    previous_price: "€480.00",
    description: "Swiss Made, Leather strap, Black",
    title: "Crystalline Wonder Watch",
    offer: "50% OFF",
    label: "Watches",
  },
  {
    icon: secondImg,
    price: "€ 170.00",
    previous_price: "",
    description: "Swan, White, Rhodium plated",
    title: "Iconic Swan Drop Earrings",
    label: "Jewelry",
  },
  {
    icon: thirdImg,
    price: "€ 150.00",
    previous_price: "",
    description: "Moon, White, Rhodium plated",
    title: "Luna Cocktail Ring",
    label: "Decoration",
  },
  {
    icon: fourthImg,
    price: "€ 160.00",
    previous_price: "",
    description: "Round cut, White, Rhodium plated",
    title: "Matrix Tennis Bracelet",
    label: "Accessories",
  },
  {
    icon: handProduct,
    price: "€ 150.00",
    previous_price: "",
    description: "Swiss Made, Leather strap, Black",
    title: "Crystalline Wonder Watch",
    offer: "50% OFF",
    label: "",
  },
  {
    icon: thirdImg,
    price: "€ 450.00",
    previous_price: "€ 480.00",
    description: "Moon, White, Rhodium plated",
    title: "Luna Cocktail Ring",
    label: "",
  },
  {
    icon: secondImg,
    price: "€ 170.00",
    previous_price: "",
    description: "Swan, White, Rhodium plated",
    title: "Iconic Swan Drop Earrings",
    label: "Jewelry",
  },
  {
    icon: thirdImg,
    price: "€ 450.00",
    previous_price: "€ 480.00",
    description: "Moon, White, Rhodium plated",
    title: "Luna Cocktail Ring",
    label: "",
  },
];

export const checkboxLabels = [
  {
    label: "Watches",
    value: true,
  },
  {
    label: "Jewelry",
    value: false,
  },
  {
    label: "Decoration",
    value: false,
  },
  {
    label: "Accessories",
    value: false,
  },
  {
    label: "Bracelets",
    value: false,
  },
  {
    label: "Necklace",
    value: false,
  },
];

export const colorLabels = [
  {
    label: "Red",
    value: 10,
    color: "#e15353",
  },
  {
    label: "Blue",
    value: 14,
    color: "#5553E1",
  },
  {
    label: "Orange",
    value: 8,
    color: "#E1A053",
  },
  {
    label: "Black",
    value: 9,
    color: "#131118",
  },
  {
    label: "Green",
    value: 4,
    color: "#A3D139",
  },
  {
    label: "Yellow",
    value: 2,
    color: "#E1D353",
  },
];

export const timeData = [
  { label: "Days", value: 120 },
  { label: "Hours", value: 18 },
  { label: "Mins", value: 15 },
  { label: "Secs", value: 10 },
];

export const categoryData = [
  {
    icon: Watches,
    price: "€ 450.00",
    previous_price: "€480.00",
    description: "Swiss Made, Leather strap, Black",
    title: "Crystalline Wonder Watch",
    label: "Watches",
  },
  {
    icon: Jewelry,
    price: "€ 170.00",
    previous_price: "",
    description: "Swan, White, Rhodium plated",
    title: "Iconic Swan Drop Earrings",
    label: "Jewelry",
  },
  {
    icon: Decoration,
    price: "€ 150.00",
    previous_price: "",
    description: "Moon, White, Rhodium plated",
    title: "Luna Cocktail Ring",
    label: "Decoration",
  },
  {
    icon: Accessories,
    price: "€ 160.00",
    previous_price: "",
    description: "Round cut, White, Rhodium plated",
    title: "Matrix Tennis Bracelet",
    label: "Accessories",
  },
];

export const retailInfoData = [
  {
    icon: phone,
    label: "+355 68 907 9797",
  },
  {
    icon: email,
    label: "swarovski@bybest.com.al",
  },
  {
    icon: location,
    label: "Rruga Sami Frasheri, Metropol Qëndra Tregtare",
  },
];

export const infoData = [
  {
    label: "Information",
  },
  {
    label: "My Cart",
  },
  {
    label: "My Wishlist",
  },
  {
    label: "Checkout",
  },
];

export const serviceData = [
  {
    label: "Service",
  },
  {
    label: "About",
  },
  {
    label: "Contact Us",
  },
  {
    label: "Shop",
  },
];

export const subscribeData = [
  {
    label: "Subscribe",
  },
  {
    label:
      "Enter your email below to be the first to know about new collections and product launches.",
  },
];
