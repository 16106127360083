import WhoWeServeFoodOverview from "../../views/whoweserve/food_overview";
import Restaurants from "../../views/whoweserve/food_overview/restaurants";
import Cafes from "../../views/whoweserve/food_overview/cafes";
import Bars from "../../views/whoweserve/food_overview/bars";
import Bistros from "../../views/whoweserve/food_overview/bistros";
import PubAndClubs from "../../views/whoweserve/food_overview/pub_and_clubs";
import FoodTrucks from "../../views/whoweserve/food_overview/food_trucks";
import Bakeries from "../../views/whoweserve/food_overview/bakeries";
import Buffets from "../../views/whoweserve/food_overview/buffets";
import CateringServices from "../../views/whoweserve/food_overview/catering_services";
import FastFoodJoints from "../../views/whoweserve/food_overview/fast_food_joints";
import FineDiningRestaurants from "../../views/whoweserve/food_overview/fine_dining_restaurants";
import FoodHalls from "../../views/whoweserve/food_overview/food_halls";
import FoodStalls from "../../views/whoweserve/food_overview/food_stalls";
import IceCreamParlors from "../../views/whoweserve/food_overview/ice_cream_parlors";
import JuiceBars from "../../views/whoweserve/food_overview/juice_bars";
import PopUpRestaurants from "../../views/whoweserve/food_overview/pop_up_restaurants";
import SupperClubs from "../../views/whoweserve/food_overview/supper_clubs";
import Pizzeria from "../../views/whoweserve/food_overview/pizzeria";
import Delis from "../../views/whoweserve/food_overview/delis";
import FoodCourt from "../../views/whoweserve/food_overview/food_court";
import CoffeShop from "../../views/whoweserve/food_overview/coffee_shop";
import FoodCheckout from "../../views/food-checkout";
import { Route } from "react-router-dom";
import RouteNames from "../../constants/route_names";

const foodRoutes = [
  <Route
    exact
    path={RouteNames.whoweserve_food_overview}
    element={<WhoWeServeFoodOverview />}
  />,
  <Route
    exact
    path={RouteNames.whoweserve_restaurants}
    element={<Restaurants />}
  />,
  <Route exact path={RouteNames.whoweserve_cafes} element={<Cafes />} />,
  <Route exact path={RouteNames.whoweserve_bars} element={<Bars />} />,
  <Route exact path={RouteNames.whoweserve_bistros} element={<Bistros />} />,
  <Route
    exact
    path={RouteNames.whoweserve_pub_and_clubs}
    element={<PubAndClubs />}
  />,
  <Route
    exact
    path={RouteNames.whoweserve_food_trucks}
    element={<FoodTrucks />}
  />,
  <Route exact path={RouteNames.whoweserve_bakeries} element={<Bakeries />} />,
  <Route exact path={RouteNames.whoweserve_buffets} element={<Buffets />} />,
  <Route
    exact
    path={RouteNames.whoweserve_catering_services}
    element={<CateringServices />}
  />,
  <Route
    exact
    path={RouteNames.whoweserve_fast_food_joints}
    element={<FastFoodJoints />}
  />,
  <Route
    exact
    path={RouteNames.whoweserve_fine_dining_restaurants}
    element={<FineDiningRestaurants />}
  />,
  <Route
    exact
    path={RouteNames.whoweserve_food_halls}
    element={<FoodHalls />}
  />,
  <Route
    exact
    path={RouteNames.whoweserve_food_stalls}
    element={<FoodStalls />}
  />,
  <Route
    exact
    path={RouteNames.whoweserve_ice_cream_parlors}
    element={<IceCreamParlors />}
  />,
  <Route
    exact
    path={RouteNames.whoweserve_juice_bars}
    element={<JuiceBars />}
  />,
  <Route
    exact
    path={RouteNames.whoweserve_pop_up_restaurants}
    element={<PopUpRestaurants />}
  />,
  <Route
    exact
    path={RouteNames.whoweserve_supper_clubs}
    element={<SupperClubs />}
  />,
  <Route exact path={RouteNames.whoweserve_pizzeria} element={<Pizzeria />} />,
  <Route exact path={RouteNames.whoweserve_delis} element={<Delis />} />,
  <Route
    exact
    path={RouteNames.whoweserve_food_court}
    element={<FoodCourt />}
  />,
  <Route
    exact
    path={RouteNames.whoweserve_coffe_shop}
    element={<CoffeShop />}
  />,
  <Route exact path={RouteNames.food_checkout} element={<FoodCheckout />} />,
];

export default foodRoutes;
