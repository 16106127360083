import { API_AI, API_QUIZ, WEB_AI } from "../constants/apiConfig";
import apiFactory from "../utils/apiFactory";

export const analyticsService = () => {
  const getBlogs = async (query = "", slug) => {
    let api = {
      content_type: "application/json",
      path: `${API_AI.path}/${slug}`,
      method: "GET",
      query,
    };
    return apiFactory(api);
  };

  const getFeaturedBlog = async () => {
    let api = {
      content_type: "application/json",
      path: `${API_AI.path}/featured-blog`,
      method: "GET",
    };
    return apiFactory(api);
  };

  const getBlogsById = async (id) => {
    let api = {
      content_type: "application/json",
      path: `${API_AI.path}/blogs/${id}`,
      method: "GET",
    };
    return apiFactory(api);
  };

  const blogCount = async (payload) => {
    let api = {
      content_type: "application/json",
      path: `${API_AI.path}/suggest-quiz`,
      method: "POST",
    };
    return apiFactory(api, payload);
  };

  const faqCount = async (payload) => {
    let api = {
      content_type: "application/json",
      path: `${WEB_AI.path}/update-statistics`,
      method: "POST",
    };
    return apiFactory(api, payload);
  };

  const createQuiz = async (payload) => {
    let api = {
      content_type: "application/json",
      path: API_QUIZ.path,
      method: "POST",
    };
    return apiFactory(api, payload);
  };

  return {
    blogCount,
    faqCount,
    createQuiz,
    getBlogs,
    getBlogsById,
    getFeaturedBlog,
  };
};
