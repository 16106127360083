import { Button } from "@mui/material";
import SocialIcon1 from "../../../assets/images/beachbarorder/social-icon1.svg";
import LoaderIcon1 from "../../../assets/images/beachbarorder/iconsloader1.svg";
import BeachOrderIcon1 from "../../../assets/images/beachbarorder/iconsarrowright1.svg";
import IconSocial2 from "../../../assets/images/beachbarorder/social-icon2.svg";
import LoaderIcon2 from "../../../assets/images/beachbarorder/iconsloader2.svg";
import IconChevronUp from "../../../assets/images/beachbarorder/iconschevronup.svg";
import ProductHeader from "./product-header";
import ProductList from "./product-list";
import { useState } from "react";
import SendOrderBtn from "./send-order-btn";
import { FaChevronDown } from "react-icons/fa";
import { beachBarOrderText } from "..";

const OrderPreview = ({
  products = [],
  currency = "",
  totalPrice = "",
  setIsDetailsOpen,
  setIsPreviewOpen,
  setProducts,
}) => {
  const [showProducts, setShowProducts] = useState(true);

  const closeModals = () => {
    setIsDetailsOpen(false);
    setIsPreviewOpen(false);
  };

  const filteredProducts = products.filter((item) => Number(item.value) > 0);

  return (
    <div className="w-full relative flex px-0 pb-0">
      <section className="flex-1 rounded-t-7xl rounded-b-none [background:linear-gradient(90deg,_#fcfcfc,_#f7f7f7_10.04%,_#f7f7f7_51.56%,_#f7f7f7_89.58%,_#fcfcfc)] overflow-hidden flex flex-col pt-0 px-0 pb-[13px] gap-[4px] max-w-full z-[2] text-center text-base text-primary-color-purple-3 font-button-medium-extrabold">
        <div className="w-[327px] h-[54px] rounded-2xl hidden flex-row items-center justify-center py-4 px-6 gap-[8px] max-w-full">
          <img
            className="h-5 w-5 relative overflow-hidden shrink-0 hidden"
            alt=""
            src={SocialIcon1}
          />
          <img
            className="h-5 w-5 relative overflow-hidden shrink-0 hidden"
            alt=""
            src={LoaderIcon1}
          />
          <div className="self-stretch relative leading-[22px] font-semibold flex items-center justify-center">
            Enter a new location
          </div>
          <div className="self-stretch relative leading-[22px] font-extrabold flex items-center justify-center">
            Extra
          </div>
          <img
            className="h-5 w-5 relative overflow-hidden shrink-0 hidden"
            alt=""
            src={BeachOrderIcon1}
          />
        </div>
        <div className="self-stretch flex flex-row py-0 px-6 max-w-full text-neutral-neutral-600">
          <div className="flex-1 flex flex-col gap-[33px] max-w-full">
            <div className="self-stretch flex flex-col gap-[24px]">
              <ProductHeader setIsOpen={setIsPreviewOpen} />
              <div className="self-stretch flex flex-col gap-[20px] text-left text-sm text-lightslategray font-manrope">
                <Button
                  className="self-stretch h-[54px]"
                  startIcon={
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 4.16675V15.8334"
                        stroke="#240B3B"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.16797 10H15.8346"
                        stroke="#240B3B"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  }
                  onClick={closeModals}
                  disableElevation
                  variant="contained"
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    textTransform: "none",
                    color: "#240B3B",
                    fontSize: "16",
                    background: "white",
                    border: "#eaeaef solid 1px",
                    borderRadius: "16px",
                    "&:hover": { background: "#fff" },
                    height: 54,
                    fontWeight: 600,
                  }}
                >
                  {beachBarOrderText("addMoreProducts")}
                </Button>
                <div className="bg-white self-stretch shadow-[0px_0px_1px_rgba(12,_26,_75,_0.03),_0px_4px_20px_-2px_rgba(50,_50,_71,_0.04)] rounded-2xl bg-bg flex flex-col pt-px px-4 pb-4 gap-[1px]">
                  <div className="self-stretch flex flex-row flex-wrap gap-[16px]">
                    <div className="flex-1 flex flex-col pt-[11px] px-0 pb-0 min-w-[153px]">
                      <div className="self-stretch relative leading-[22px] font-semibold text-[#8E8EA9]">
                        {beachBarOrderText("Products")} (
                        {filteredProducts.length}{" "}
                        {beachBarOrderText("products")})
                      </div>
                    </div>
                    <div className="rounded-xl flex flex-row p-3 gap-[8px] text-center text-secondary-color-yellow-1 font-button-medium-extrabold">
                      <img
                        className="h-5 w-5 relative overflow-hidden shrink-0 hidden min-h-[20px]"
                        alt=""
                        src={IconSocial2}
                      />
                      <img
                        className="h-5 w-5 relative overflow-hidden shrink-0 hidden min-h-[20px]"
                        alt=""
                        src={LoaderIcon2}
                      />

                      {showProducts ? (
                        <img
                          className="cursor-pointer h-5 w-5 relative overflow-hidden shrink-0"
                          loading="lazy"
                          alt=""
                          src={showProducts ? IconChevronUp : <FaChevronDown />}
                          onClick={() => setShowProducts(!showProducts)}
                        />
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="cursor-pointer h-6 w-6 relative overflow-hidden shrink-0 font-light"
                          onClick={() => setShowProducts(!showProducts)}
                        >
                          <path
                            fillRule="evenodd"
                            d="M12 15.5l6-6-1.5-1.5L12 12.5 7.5 8 6 9.5l6 6z"
                            clipRule="evenodd"
                          />
                        </svg>
                      )}
                    </div>
                  </div>
                  <div className="self-stretch product-list-wrapper flex flex-col gap-[15.5px] text-neutral-neutral-800">
                    {showProducts && (
                      <ProductList products={filteredProducts} />
                    )}
                    <div className="self-stretch h-px relative border-t-[1px] border-solid border-whitesmoke" />
                    <div className="self-stretch flex flex-row items-center justify-between gap-[20px] text-base text-neutral-neutral-700">
                      <b
                        className="relative leading-[22px] inline-block min-w-[77px]"
                        style={{ paddingLeft: "10px" }}
                      >
                        {beachBarOrderText("totalPrice")}
                      </b>
                      <div className="flex flex-row gap-[2px] text-[9px] text-lightslategray font-button-medium-extrabold">
                        <div className="flex flex-col pt-0 px-0 pb-[5px]">
                          <b
                            className="relative leading-[15px] inline-block min-w-[17px] currency-product text-[#8E8EA9s]"
                            style={{ paddingRight: "10px" }}
                          >
                            {currency}
                          </b>
                        </div>
                        <div className="relative text-base leading-[20px] font-extrabold text-mediumseagreen inline-block min-w-[29px] text-[#17CE8C]">
                          {totalPrice}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <SendOrderBtn
              setIsDetailsOpen={setIsDetailsOpen}
              setProducts={setProducts}
              products={products}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default OrderPreview;
