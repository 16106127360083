import React from "react";
import './index.css'

const InfoCard = ({ title, desc }) => {
  return (
    <div className="info-card relative flex flex-col rounded-[12px] md:rounded-[20px] border-bottom-[1px] border-bottom-[#E0E0E0] bg-whitemd:px-[18px] px-[16px] py-[18px] md:py-[24px]">
      <h3 className="font-bold text-[18px] md:text-[22px]  text-[#240B3B] leading-4 md:leading-7 text-center md:text-start">
        {title}
      </h3>
      <p
        className={
          " font-medium text-16 md:text-18 leading-4 md:leading-7 text-[#666666] text-center md:text-start mt-4"
        }
      >
        {desc}
      </p>
    </div>
  );
};

export default InfoCard;
