import React, { useEffect } from "react";
import "./index.css";
import { Seo } from "../../../components/Seo";
import Faq from "../../../components/Faq";
import Advantage from "../../../components/Advantage";
import Grow from "../grow";
import CircleTick from "../../../assets/svgs/checkfull.svg";
import OverviewSportEntertainment from "../../../assets/images/who-we-serve/venue.webp";
import EvBookingsManagement from "../../../assets/images/who-we-serve/ev-bookings-management.webp";
import EvLoyalty from "../../../assets/images/who-we-serve/ev-loyalty.webp";
import EvPaymentLinks from "../../../assets/images/who-we-serve/ev-payment-links.webp";
import EvMarketing from "../../../assets/images/who-we-serve/ev-marketing.webp";
import EvGuestsManagement from "../../../assets/images/who-we-serve/ev-guests-management.webp";
import EvGuestsSurveys from "../../../assets/images/who-we-serve/ev-guest-surveys.webp";
import EvAffiliates from "../../../assets/images/who-we-serve/ev-affiliates.webp";
import EvInventoryManagement from "../../../assets/images/who-we-serve/ev-inventory-management.webp";
import EvAnalytics from "../../../assets/images/who-we-serve/ev-analytics.webp";
import EvStaffManagement from "../../../assets/images/who-we-serve/ev-staff-management.webp";
import { t } from "i18next";
import OverviewFoodGuestsManagement from "../../../assets/images/who-we-serve/food-guests-management.webp";
import { isMobile } from "react-device-detect";
import { CheckLine } from "../../../components/CheckLine";
import FreeTrial from "../../../components/FreeTrial";
import FreePlanCard from "../../../components/FreePlanCard";
import { genEvText } from "../../resources/faqs";
import { whoTabText } from "../accommodation_overview/index";

const generalFaq = [
  {
    id: 1,
    status: false,
    title: genEvText("typesOfVenues"),
    content: genEvText("venueBoostTypesOfVenuesDescription"),
  },
  {
    id: 2,
    status: false,
    title: genEvText("venueBoostBookingsManagementQuestion"),
    content: genEvText("venueBoostBookingsManagementDescription"),
  },
  {
    id: 3,
    status: false,
    title: genEvText("venueBoostInventoryManagementQuestion"),
    content: genEvText("venueBoostInventoryManagementDescription"),
  },
  {
    id: 4,
    status: false,
    title: genEvText("venueBoostStaffManagementQuestion"),
    content: genEvText("venueBoostStaffManagementDescription"),
  },
  {
    id: 5,
    status: false,
    title: genEvText("venueBoostMarketingSupportQuestion"),
    content: genEvText("venueBoostMarketingSupportDescription"),
  },
  {
    id: 6,
    status: false,
    title: genEvText("venueBoostLoyaltyProgramsQuestion"),
    content: genEvText("venueBoostLoyaltyProgramsDescription"),
  },
  {
    id: 7,
    status: false,
    title: genEvText("venueBoostAnalyticsToolsQuestion"),
    content: genEvText("venueBoostAnalyticsToolsDescription"),
  },
  {
    id: 8,
    status: false,
    title: genEvText("venueBoostPaymentProcessingQuestion"),
    content: genEvText("venueBoostPaymentProcessingDescription"),
  },
  {
    id: 9,
    status: false,
    title: genEvText("venueBoostGuestManagementQuestion"),
    content: genEvText("venueBoostGuestManagementDescription"),
  },
  {
    id: 10,
    status: false,
    title: genEvText("venueBoostOnboardingSupportQuestion"),
    content: genEvText("venueBoostOnboardingSupportDescription"),
  },
];

const entText = (label) =>
  t(`who_we_serve.sport_entertainment_overview.${label}`);

const tabText = (label) =>
  t(`who_we_serve.sport_entertainment_overview.tabs.${label}`);

const SportsAndEntertainmentIndustry = () => {
  const currentUrl = "https://venueboost.io/who-we-serve/entertainment-venues";
  const seoTitle = entText("ScheduleDemoVenueBoost");
  const seoDescription = entText("description");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const tabs = [
    {
      title: tabText("EntertainmentMembershipProgram"),
      badge: tabText("EntertainmentMembershipProgram"),
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-0 md:mt-[58px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0">
            <img alt="" src={OverviewFoodGuestsManagement} />
          </div>
          <div className="w-full md:w-[50%] md:p-0 md:pr-0  flex-col">
            <h1
              className="text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
              style={{ alignSelf: "left" }}
            >
              {tabText("ExclusiveExperiencesforLoyalFans")}
            </h1>

            <p className="text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {tabText("EntertainmentMembershipProgramContent")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("Encouragefanstoattendeventsandearnpoints")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("Provideexclusiveaccessordiscountstoloyalmembers")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("Boostattendanceandengagement")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("Monetizefanloyaltywithspecialofferings")}
                </span>
              </div>
            </div>

            <p className="text-[#666666] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px] mb-8">
              {tabText(
                "EnhancetheentertainmentexperiencewithVenueBoostsEntertainmentMembershipProgram"
              )}
            </p>
          </div>
        </div>
      ),
      title_modal: tabText("EntertainmentMembershipProgram"),
      content1: tabText("ExclusiveExperiencesforLoyalFans"),
      content2: tabText("EntertainmentMembershipProgramContent"),
      content3: [
        tabText("Encouragefanstoattendeventsandearnpoints"),
        tabText("Provideexclusiveaccessordiscountstoloyalmembers"),
        tabText("Boostattendanceandengagement"),
        tabText("Monetizefanloyaltywithspecialofferings"),
      ],
      content4: tabText(
        "EnhancetheentertainmentexperiencewithVenueBoostsEntertainmentMembershipProgram"
      ),
    },
    {
      title: tabText("AdvancedCustomerBehaviorAnalytics"),
      badge: tabText("AdvancedCustomerBehaviorAnalytics"),
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-0 md:mt-[58px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0">
            <img alt="" src={OverviewFoodGuestsManagement} />
          </div>
          <div className="w-full md:w-[50%] md:p-0 md:pr-0  flex-col">
            <h1
              className="text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
              style={{ alignSelf: "left" }}
            >
              {tabText("DataDrivenCustInsights")}
            </h1>

            <p className="text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {tabText("GainDeepInsights")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("AnalyzePref")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("MakeDataDriven")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("EffectivelyTarget")}
                </span>
              </div>
            </div>
            <p className="text-[#666666] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px] mb-8">
              {tabText("VenueBoostEmpowers")}
            </p>
          </div>
        </div>
      ),
      title_modal: tabText("AdvancedCustomerBehaviorAnalytics"),
      content1: tabText("DataDrivenCustInsights"),
      content2: tabText("GainDeepInsights"),
      content3: [
        tabText("AnalyzePref"),
        tabText("MakeDataDriven"),
        tabText("EffectivelyTarget"),
      ],
      content4: tabText("VenueBoostEmpowers"),
    },
    {
      title: tabText("MarketingAutomation"),
      badge: tabText("MarketingAutomation"),
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-0 md:mt-[58px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0">
            <img alt="" src={OverviewFoodGuestsManagement} />
          </div>
          <div className="w-full md:w-[50%] md:p-0 md:pr-0  flex-col">
            <h1
              className="text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
              style={{ alignSelf: "left" }}
            >
              {tabText("BoostEngagementandSales")}
            </h1>

            <p className="text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {tabText("MarketingAutomationContent")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("CreateTargetedEmailCampaigns")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("SendPersonalizedOffers")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("MeasureEffectiveness")}
                </span>
              </div>
            </div>
            <p className="text-[#666666] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px] mb-8">
              {tabText("VenueBoostStreamlinesYourMarketing")}
            </p>
          </div>
        </div>
      ),
      title_modal: tabText("MarketingAutomation"),
      content1: tabText("BoostEngagementandSales"),
      content2: tabText("MarketingAutomationContent"),
      content3: [
        tabText("CreateTargetedEmailCampaigns"),
        tabText("SendPersonalizedOffers"),
        tabText("MeasureEffectiveness"),
      ],
      content4: tabText("VenueBoostStreamlinesYourMarketing"),
    },
    {
      title: tabText("PaymentsClickSecure"),
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-0 md:mt-[58px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0">
            <img alt="" src={OverviewFoodGuestsManagement} />
          </div>
          <div className="w-full md:w-[50%] md:p-0 md:pr-0  flex-col">
            <h1
              className="text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
              style={{ alignSelf: "left" }}
            >
              {tabText("PaymentsClickSecure")}
            </h1>

            <p className="text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {tabText("InPersonPaymentsFoodIndustry")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("AcceptPaymentsSecurely")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("QuickReliableTransactions")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("UserFriendlyInterface")}
                </span>
              </div>
            </div>
            <p className="text-[#666666] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px] mb-8">
              -
            </p>
          </div>
        </div>
      ),
      title_modal: tabText("PaymentsClickSecure"),
      badge: tabText("InPersonPayments"),
      content1: tabText("PaymentsClickSecure"),
      content2: tabText("InPersonPaymentsFoodIndustry"),
      content3: [
        tabText("AcceptPaymentsSecurely"),
        tabText("QuickReliable"),
        tabText("UserFriendlyInterface"),
      ],
      content4: "",
    },
    {
      title: tabText("BrandingUniquelyYours"),
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-0 md:mt-[58px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0">
            <img alt="" src={OverviewFoodGuestsManagement} />
          </div>
          <div className="w-full md:w-[50%] md:p-0 md:pr-0  flex-col">
            <h1
              className="text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
              style={{ alignSelf: "left" }}
            >
              {tabText("BrandingUniquelyYours")}
            </h1>

            <p className="text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {tabText("Tailortheplatformtoyourbrand")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("BrandConsistency")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("EnhancedCustomerExperience")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("MarketingSynergy")}
                </span>
              </div>
            </div>
            <p className="text-[#666666] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px] mb-8">
              {tabText("Professionalism")}
            </p>
          </div>
        </div>
      ),
      title_modal: tabText("BrandingUniquelyYours"),
      badge: tabText("BrandingUniquelyYours"),
      content1: tabText("BrandingUniquelyYours"),
      content2: tabText("Tailortheplatformtoyourbrand"),
      content3: [
        tabText("BrandConsistency"),
        tabText("EnhancedCustomerExperience"),
        tabText("MarketingSynergy"),
      ],
      content4: tabText("Professionalism"),
    },
  ];

  // TODO
  return (
    <div className={"align-col-middle ent-industry mt-0"}>
      <Seo
        title={seoTitle + " | VenueBoost"}
        description={seoDescription}
        image={OverviewSportEntertainment}
        url={currentUrl}
      />
      <div className="flex flex-col md:flex-row justify-center overflow-hidden bg-[#F3F3F3] px-4 sm:px-6 md:px-8 lg:px-16 pb-20 w-[100vw] relative md:min-h-[668px]">
        <div className="xl:!w-[1228px] flex flex-col md:flex-row">
          <div className="w-full md:w-[50%] flex flex-col md:pr-[58px] py-6 md:py-[44px] z-10">
            {!isMobile && (
              <span className=" text-[32px]  md:text-[60px] leading-[45px] md:leading-[60px] font-extrabold ">
                {t(
                  "who_we_serve.sport_and_entertainment_industry.sport_entertainment"
                )}
                <br />
                {t("who_we_serve.sport_and_entertainment_industry.industry")}
              </span>
            )}

            {isMobile && (
              <span className=" text-[32px]  md:text-[60px] leading-[45px] md:leading-[60px] font-extrabold ">
                {t("new_menu.sport_entertainment.label")}
              </span>
            )}

            <p className="hidden md:block text-[#000000] text-14 md:text-20 font-medium leading-5 md:leading-7 mt-2 md:mt-4 mb-[22px] md:mb-[44px]">
              {t("who_we_serve.sport_and_entertainment_industry.industry_desc")}
            </p>
            <div className="hidden md:block mb-[15px] md:mb-0 mt-10">
              <a
                href="/get-started"
                className="rounded-md text-white font-bold text-[11px] md:text-18 leading-[11px] md:leading-[18px] px-[17px] md:px-[30px] py-[9px] md:py-[15px] uppercase bg-gradient-to-r from-[#240B3B] via-[#5E1B83] to-[#BC14FE]"
              >
                {t("who_we_serve.food_industry.unlock_free_plan")}
              </a>
            </div>
          </div>
          <div className=" md:block md:w-[50%] md:mt-12  relative align-middle">
            <img
              alt=""
              className="object-cover md:absolute rounded-[20px] right-[29px] max-h-full"
              src={OverviewSportEntertainment}
            />
          </div>

          <div className="flex flex-col md:hidden mt-6">
            <p className="text-[#000000] text-14 md:text-20 font-medium leading-5 md:leading-7 mt-2 md:mt-4 mb-[22px] md:mb-[44px]">
              {t("who_we_serve.sport_and_entertainment_industry.industry_desc")}
            </p>
            <div className="mt-6 md:mt-0">
              <a
                href="/get-started"
                className="flex w-full justify-center rounded-md text-white font-bold text-18 leading-[18px] md:px-[30px] py-[15px] uppercase bg-gradient-to-r from-[#240B3B] via-[#5E1B83] to-[#BC14FE]"
              >
                {t("who_we_serve.food_industry.unlock_free_plan")}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col-reverse md:flex-row mt-10 md:mt-[48px]">
        <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
          <img alt="" src={EvBookingsManagement} />
        </div>

        <div className="w-full md:w-[50%] md:p-12 md:pr-0  flex-col">
          <h1 className="text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4">
            {t("who_we_serve.accommodation_overview.bookingsManagement")}
          </h1>
          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            {entText("OptimizedEntertainmentBookings")}
          </p>

          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            {entText("VenueBoostAmusementParksAndMoreTools")}
          </p>
          <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
            {[
              entText("EasilyCreateBookingsSpecifyingDatesTicketsGuests"),
              entText("DashboardToViewModifyAndOrganizeReservations"),
              entText("RSVPFeaturesForSmoothCommunication"),
              entText("GainInsightsIntoAttendancePatterns"),
            ].map((item) => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className="text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4">
            {entText("VenueBoostOffers")}
          </p>
          <FreeTrial />
        </div>
      </div>
      <div className="flex flex-col-reverse md:flex-row-reverse mt-10 md:mt-[48px] ">
        <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
          <img alt="" src={EvInventoryManagement} />
        </div>
        <div className="w-full md:w-[50%] md:p-4 md:pl-0  ">
          <h1 className="text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4">
            {t(
              "who_we_serve.sport_and_entertainment_industry.inventory_management"
            )}{" "}
          </h1>
          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            {entText("StreamlinedEquipmentInventory")}
          </p>
          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            {t(
              "who_we_serve.sport_and_entertainment_industry.inventory_management_sub"
            )}
          </p>
          <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
            {[
              t(
                "who_we_serve.sport_and_entertainment_industry.inventory_management_tracking"
              ),
              t(
                "who_we_serve.sport_and_entertainment_industry.inventory_management_update"
              ),
              t(
                "who_we_serve.sport_and_entertainment_industry.inventory_management_trend"
              ),
              t(
                "who_we_serve.sport_and_entertainment_industry.inventory_management_view"
              ),
              t(
                "who_we_serve.sport_and_entertainment_industry.inventory_management_lifecycle"
              ),
            ].map((item) => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className="text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4">
            {entText("InsightsKeepVenuesRunningSmoothly")}
          </p>
          <FreeTrial />
        </div>
      </div>

      <div className="flex flex-col-reverse md:flex-row mt-10 md:mt-[48px]">
        <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
          <img alt="" src={EvStaffManagement} />
        </div>
        <div className="w-full md:w-[50%] md:p-12 md:pr-0  flex-col">
          <h1
            className="text-[#240b3b] text-22 align-left md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
            style={{ alignSelf: "left" }}
          >
            {t("who_we_serve.sport_and_entertainment_industry.staff_managment")}
          </h1>
          <p className="text-[#666666] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
            {entText("SimplifiedSchedulingAndPayroll")}
          </p>
          <p className="text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
            {t(
              "who_we_serve.sport_and_entertainment_industry.staff_managment_sub"
            )}
          </p>
          <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
            {[
              entText("SchedulingAutomationBasedonEvents"),
              entText("TrackTimeOffRequests"),
              entText("IntegratedPayrollProcessing"),
              entText("PerformanceMonitoringIdentifyCoaching"),
              entText("CoordinateStaffSeamlessOperations"),
            ].map((item) => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className="text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4">
            {entText("StreamlineTeamManagementOptimalOperations")}
          </p>
          <FreeTrial />
        </div>
      </div>
      <div className="flex flex-col-reverse md:flex-row-reverse mt-10 md:mt-[48px] ">
        <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
          <img alt="" src={EvMarketing} />
        </div>
        <div className="w-full md:w-[50%] md:p-4 md:pl-0   ">
          <h1 className="text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4">
            {t("who_we_serve.sport_and_entertainment_industry.marketing")}{" "}
          </h1>
          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            {entText("ExpandBrandReach")}
            {}
          </p>
          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            {t("who_we_serve.sport_and_entertainment_industry.marketing_sub")}
          </p>
          <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
            {[
              t(
                "who_we_serve.sport_and_entertainment_industry.marketing_email"
              ),
              t(
                "who_we_serve.sport_and_entertainment_industry.marketing_promotion"
              ),
              t(
                "who_we_serve.sport_and_entertainment_industry.marketing_campaigns"
              ),
              t(
                "who_we_serve.sport_and_entertainment_industry.marketing_refferals"
              ),
              "Coordinate staff for seamless operations",
            ].map((item) => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className="text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4">
            {entText("ConsolidateEntertainmentMarketing")}
          </p>
          <FreeTrial />
        </div>
      </div>

      <div className="flex flex-col-reverse md:flex-row mt-10 md:mt-[48px]">
        <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
          <img alt="" src={EvLoyalty} />
        </div>
        <div className="w-full md:w-[50%] md:p-12 md:pr-0  flex-col">
          <h1
            className="text-[#240b3b] text-22 align-left md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
            style={{ alignSelf: "left" }}
          >
            {t("who_we_serve.sport_and_entertainment_industry.loyalty")}
          </h1>
          <p className="text-[#666666] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
            {entText("BuildingGuestLoyaltyRetention")}
          </p>
          <p className="text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
            {t("who_we_serve.sport_and_entertainment_industry.loyalty_sub")}
          </p>
          <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
            {[
              entText("BuildSpecialTiersBenefits"),
              entText("ViewPurchaseHistoryActivityTrends"),
              entText("SendTailoredRewardsOffers"),
              entText("AnalyzeEngagementAcrossTiers"),
            ].map((item) => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className="text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4">
            {entText("StrengthenRelationshipsWithReturnGuests")}
          </p>
          <FreeTrial />
        </div>
      </div>
      <div className="flex flex-col-reverse md:flex-row-reverse mt-10 md:mt-[48px] ">
        <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
          <img alt="" src={EvAnalytics} />
        </div>
        <div className="w-full md:w-[50%] md:p-4 md:pl-0   ">
          <h1 className="text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4">
            {t("who_we_serve.sport_and_entertainment_industry.reporting")}
          </h1>
          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            {entText("ActionableEntertainmentInsights")}
          </p>

          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            {t("who_we_serve.sport_and_entertainment_industry.reporting_sub")}
          </p>
          <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
            {[
              t(
                "who_we_serve.sport_and_entertainment_industry.reporting_sales"
              ),
              t(
                "who_we_serve.sport_and_entertainment_industry.reporting_hours"
              ),
              t(
                "who_we_serve.sport_and_entertainment_industry.reporting_metrics"
              ),
              t(
                "who_we_serve.sport_and_entertainment_industry.reporting_insight"
              ),
              t(
                "who_we_serve.sport_and_entertainment_industry.reporting_courses"
              ),
            ].map((item) => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className="text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4">
            {entText("StopRelyingonGuesswork")}
          </p>
          <FreeTrial />
        </div>
      </div>

      <div className="flex flex-col-reverse md:flex-row mt-10 md:mt-[48px]">
        <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
          <img alt="" src={EvPaymentLinks} />
        </div>
        <div className="w-full md:w-[50%] md:p-12 md:pr-0  flex-col">
          <h1
            className="text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
            style={{ alignSelf: "left" }}
          >
            {t("who_we_serve.sport_and_entertainment_industry.payment_links")}
          </h1>
          <p className="text-[#666666] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
            {entText("StreamlinedOnlineTicketingPayments")}
          </p>
          <p className="text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
            {t(
              "who_we_serve.sport_and_entertainment_industry.payment_links_sub"
            )}
          </p>
          <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
            {[
              entText("SecurelyAcceptTicketPurchasesAndDeposits"),
              entText("OrganizedLinkListToTrackTransactions"),
              entText("InsightsIntoSalesVolumesAndRevenue"),
              entText("ConvenientIntegratedBookingPayments"),
            ].map((item) => (
              <CheckLine text={item} />
            ))}
          </div>
          <FreeTrial />
        </div>
      </div>
      <div className="flex flex-col-reverse md:flex-row-reverse mt-10 md:mt-[78px] ">
        <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
          <img alt="" src={EvGuestsManagement} />
        </div>
        <div className="w-full md:w-[50%] md:p-4 md:pl-0   ">
          <h1 className="text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4">
            {t(
              "who_we_serve.sport_and_entertainment_industry.customer_management"
            )}{" "}
          </h1>
          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            {entText("PersonalizedInteractionsLoyalRelationships")}
          </p>

          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            {entText("VenueBoostEquipsEntertainmentBusinesses")}
          </p>
          <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
            {[
              t(
                "who_we_serve.sport_and_entertainment_industry.customer_management_profiles"
              ),
              t(
                "who_we_serve.sport_and_entertainment_industry.customer_management_preference"
              ),
              t(
                "who_we_serve.sport_and_entertainment_industry.customer_management_history"
              ),
              "Custom loyalty programs with special tiers",
            ].map((item) => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className="text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4">
            {entText("DeliverExceptionalVisits")}
          </p>
          <FreeTrial />
        </div>
      </div>

      <div className="flex flex-col-reverse md:flex-row mt-10 md:mt-[78px]">
        <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0">
          <img alt="" src={EvGuestsSurveys} />
        </div>
        <div className="w-full md:w-[50%] md:p-0 md:pr-0  flex-col">
          <h1
            className="text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
            style={{ alignSelf: "left" }}
          >
            {entText("GuestSurveysRatings")}
          </h1>

          <p className="text-[#666666] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
            {entText("GatherValuableFeedback")}
          </p>
          <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
            {[
              entText("SurveysAndRatings"),
              entText("CustomizedSurveys"),
              entText("CollectRatingsReviews"),
              entText("InsightsPreferences"),
              entText("InformedImprovements"),
            ].map((item) => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className="text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4">
            {entText("ListenToGuests")}
          </p>
          <FreeTrial />
        </div>
      </div>
      <div className="flex flex-col-reverse md:flex-row-reverse mt-10 md:mt-[78px] ">
        <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
          <img alt="" src={EvAffiliates} />
        </div>
        <div className="w-full md:w-[50%] md:p-4 md:pl-0   ">
          <h1 className="text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4">
            {t("who_we_serve.food_overview.affiliatePartnerships")}{" "}
          </h1>
          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            {t("who_we_serve.accommodation_overview.ExpandYourReachAndRevenue")}
          </p>

          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            {t(
              "who_we_serve.food_overview.tabs.withVenueBoostNewAffiliatesFeature"
            )}
          </p>
          <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
            {[
              whoTabText("establishAffiliatePartnershipsSeamlessly"),
              whoTabText("trackReferralsAndCommissionsInRealTime"),
              whoTabText("accessValuableMarketingResources"),
              whoTabText("extendBrandReach"),
            ].map((item) => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className="text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4">
            {entText("JoinForcesAffiliates")}
          </p>
          <FreeTrial />
        </div>
      </div>

      <Grow
        venueColor={"#F4D35E"}
        title={"Additional Entertainment Venues Features"}
        subtitle={entText("DiveIntoVenueBoostExpandedCapabilities")}
        tabs={tabs}
      />

      <div className="align-col-middle ">
        <div className="w-[100vw] bg-[#240B3B]">
          <div className="max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 ">
            <div className="flex flex-col align-middle w-full">
              <h1 className="text-white text-26 md:text-[42px] text-center font-extrabold leading-[60px] mt-10 md:mt-20 ">
                {t("who_we_serve.sport_and_entertainment_industry.faq")}
                <span className="text-white">
                  {" "}
                  (
                  {t("who_we_serve.sport_and_entertainment_industry.faq_short")}
                  ){" "}
                </span>
              </h1>
              <p className="text-14 md:text-20 text-white px-7 leading-5 font-medium mt-4 mb:mt-20 mb-5 md:mb-[66px]">
                {t(
                  "who_we_serve.sport_and_entertainment_industry.sport_entertainment_industry"
                )}
              </p>
              <Faq faqData={generalFaq} className="mt-2 mb-4 md:mb-16" />
            </div>
          </div>
        </div>
      </div>
      <Advantage venueDefinition={"sports & entertainment industry"} />
      <div className="align-col-middle">
        <div className="w-[100vw] bg-white">
          <div className="mx-auto max-w-screen-main px-4 sm:px-6 md:px-8 lg:px-16">
            <div className="flex flex-col align-middle mb-4 md:mb-20">
              <FreePlanCard />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SportsAndEntertainmentIndustry;
