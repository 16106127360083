import React, { useState } from "react";
import Quiz from "react-quiz-component-vb";
import QuizTermsCTA from "../../views/blog-detail-updated/quiz/quiz-terms-cta"; // Import the previously created component
import "./index.css";

const QuizComponent = ({ handleSubmit, questions, results }) => {
  const index = (answers) =>
    answers.findIndex((answer) => answer.is_correct === 1);
  const quizQuestions = questions.quiz.questions.map((item) => {
    return {
      question: item.question_text ?? "",
      questionType: "text",
      answerSelectionType: "single",
      correctAnswer: String(Number(index(item.answers)) + 1),
      answers: item.answers.map((ans) => ans.answer_text),
      point: questions.credits_per_correct_answer ?? "0.0",
    };
  });

  const quiz = {
    quizTitle: "Welcome to the VenueBoost Rewards Quiz! ",
    quizSynopsis: `Take our 3-question quiz and earn up to $ ${Number(
      questions.max_earn
    )} in VenueBoost credits! Use your winnings to unlock premium features and elevate your venue management. Dive in now and start claiming your rewards!`,
    nrOfQuestions: "4",
    questions: quizQuestions,
  };

  const [termsModalOpen, setTermsModalOpen] = useState(false);

  const handleTermsModalOpen = () => setTermsModalOpen(true);
  const handleTermsModalClose = () => setTermsModalOpen(false);

  return (
    <div className="quiz-container relative">
      {!results && (
        <div className="ctaTerms absolute top-0 right-0 p-0 m-0">
          <button
            onClick={handleTermsModalOpen}
            className="start-quiz-button text-16"
          >
            Quiz Participation Terms
          </button>
        </div>
      )}

      <Quiz
        quiz={quiz}
        customStyles={{ answerOption: "quiz-answer-dropdown" }}
        onComplete={handleSubmit}
        showDefaultResult={false}
      />

      <QuizTermsCTA open={termsModalOpen} handleClose={handleTermsModalClose} />
    </div>
  );
};

export default QuizComponent;
