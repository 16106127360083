import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import "./index.css";
import {Seo} from "../../../../components/Seo";
import Faq from "../../../../components/Faq";
import Advantage from "../../../../components/Advantage";
import OverviewBannerImg from "../../../../assets/images/who-we-serve/food.webp";
import OverviewImg1 from "../../../../assets/images/who-we-serve/overviewimg1.webp";
import OverviewImg2 from "../../../../assets/images/who-we-serve/overviewimg2.webp";
import OverviewImg3 from "../../../../assets/images/who-we-serve/overviewimg3.webp";
import OverviewImg4 from "../../../../assets/images/who-we-serve/overviewimg4.webp";
import OverviewImg5 from "../../../../assets/images/who-we-serve/overviewimg5.webp";
import OverviewImg6 from "../../../../assets/images/who-we-serve/overviewimg6.webp";
import OverviewImg7 from "../../../../assets/images/who-we-serve/overviewimg7.webp";
import OverviewImg8 from "../../../../assets/images/who-we-serve/overviewimg8.webp";
import OverviewFoodGuestsManagement from "../../../../assets/images/who-we-serve/food-guests-management.webp";
import {isMobile} from "react-device-detect";
import {CircleTick, CheckLine} from "../../../../components/CheckLine";
import FreeTrial from "../../../../components/FreeTrial";
import {create as createContactSale} from "../../../../redux/actions/contactsales";
import {NotificationManager} from "react-notifications";
import {t} from "i18next";

import Grow from "../../grow";
import FreePlanCard from "../../../../components/FreePlanCard";

const generalFaq = [
  {
    id: 1,
    status: false,
    title:
      "What types of food and beverage businesses can benefit from using VenueBoost?",
    content:
      "VenueBoost caters to a wide range of businesses including restaurants, bistros, cafes, bars, clubs, food trucks, fine dining, bakeries, catering, fast food, ice cream parlors, food stalls, buffets, pop-up dining, juice bars, food halls, supper clubs, pizzerias, coffee shops, delis, and food courts.",
  },
  {
    id: 2,
    status: false,
    title:
      "What is the onboarding process like for new clients using VenueBoost?",
    content:
      "The onboarding process is streamlined and user-friendly. We provide personalized support to help you set up and familiarize yourself with our platform. This includes training sessions, resource materials, and dedicated customer service to ensure a smooth transition and effective utilization of all VenueBoost features.",
  },
  {
    id: 3,
    status: false,
    title:
      "How can VenueBoost streamline the reservations process for my food and beverage business?",
    content:
      "VenueBoost offers intuitive tools for managing reservations to enhance guest experiences and efficiently handle bookings.",
  },
  {
    id: 4,
    status: false,
    title:
      "What inventory management capabilities does VenueBoost provide for restaurants and cafes?",
    content:
      "Our system offers real-time inventory tracking and analytics, enabling you to effectively manage stock levels and optimize ordering.",
  },
  {
    id: 5,
    status: false,
    title:
      "Can VenueBoost assist in staff scheduling and management for my dining establishment?",
    content:
      "Yes, VenueBoost enables efficient staff scheduling, shift management, payroll processing, and provides data-driven insights for optimal staff utilization.",
  },
  {
    id: 6,
    status: false,
    title:
      "How does VenueBoost support marketing efforts for food and beverage businesses?",
    content:
      "VenueBoost provides comprehensive marketing tools for promotions, email campaigns, referrals, and targeted marketing efforts.",
  },
  {
    id: 7,
    status: false,
    title:
      "What kind of loyalty and retention programs can I implement with VenueBoost?",
    content:
      "Our platform allows the creation of customized loyalty programs to encourage repeat visits and enhance customer loyalty.",
  },
  {
    id: 8,
    status: false,
    title: "Can I track and analyze my business performance with VenueBoost?",
    content:
      "Absolutely, VenueBoost offers detailed analytics and reporting tools for insightful business decision-making.",
  },
  {
    id: 9,
    status: false,
    title:
      "How does VenueBoost facilitate the management of online payments and reservations?",
    content:
      "We provide secure and customizable payment link generation for online reservations, ensuring a smooth payment process for your customers.",
  },
  {
    id: 10,
    status: false,
    title:
      "Can VenueBoost assist in improving my guest management and engagement strategies?",
    content:
      "Yes, VenueBoost enables effective management of guest profiles and preferences, offering personalized experiences to enhance satisfaction and loyalty.",
  },
];

const PopUpRestaurants = () => {
  const navigate = useNavigate();
  const currentUrl =
    "https://venueboost.io/who-we-serve/food-and-beverage/pop-up-restaurants";
  const seoTitle = "Effortlessly Manage Pop-Up Dining Events";
  const seoDescription =
    "Effortlessly manage temporary dining events and create memorable experiences with VenueBoost's tailored solutions for pop-up dining establishments.";

  const [step, setStep] = useState(1);
  const [venuetype, setVenuetype] = useState("restaurant");
  const [first_name, setFirstname] = useState("");
  const [last_name, setLastname] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [restaurant_name, setRestaurantName] = useState("");
  const [restaurant_city, setRestaurantCity] = useState("");
  const [restaurant_state, setRestaurantState] = useState("");
  const [restaurant_zipcode, setRestaurantZipcode] = useState("");
  const [restaurant_country, setRestaurantCountry] = useState("");
  const [showMore, setShowMore] = useState(false);

  const handleSecondStepSelect = () => {
    if (!first_name || !last_name || !mobile || !email) {
      NotificationManager.error(
        t("who_we_serve.food_industry.error_msg"),
        t("who_we_serve.food_industry.error"),
        3000
      );
    } else {
      setStep(3);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigateToUrl = url => {
    navigate(url);
  };

  const handleVenueZipCode = e => {
    setRestaurantZipcode(e.target.value);
  };

  const handleVenueState = e => {
    setRestaurantState(e.target.value);
  };

  const handleVenueName = e => {
    setRestaurantName(e.target.value);
  };

  const handleVenueCity = e => {
    setRestaurantCity(e.target.value);
  };

  const handleVenueCountry = e => {
    setRestaurantCountry(e.target.value);
  };

  const handleFirstname = e => {
    setFirstname(e.target.value);
  };

  const handleLastname = e => {
    setLastname(e.target.value);
  };

  const handleMobile = e => {
    setMobile(e.target.value);
  };

  const handleEmail = e => {
    setEmail(e.target.value);
  };

  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      title: "Premium Tables with Bidding",
      content: (
        <div className='flex flex-col-reverse md:flex-row mt-0 md:mt-[58px]'>
          <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0'>
            <img src={OverviewFoodGuestsManagement} />
          </div>
          <div className='w-full md:w-[50%] md:p-0 md:pr-0  flex-col'>
            <h1
              className='text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'
              style={{alignSelf: "left"}}
            >
              A New Dimension for Reservations
            </h1>

            <p className='text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
              VenueBoost presents Premium Tables with Bidding, adding excitement
              and revenue potential to table reservations.
            </p>
            <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Allow guests to bid on premium tables for special events
                </span>
              </div>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Set dynamic pricing based on demand and popularity
                </span>
              </div>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Increase revenue by optimizing table allocations
                </span>
              </div>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Enhance the guest experience with personalized table choices
                </span>
              </div>
            </div>
            <p className='text-[#666666] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px] mb-8'>
              Maximize your revenue and create unforgettable experiences with
              VenueBoost's Premium Tables with Bidding.
            </p>
          </div>
        </div>
      ),
      title_modal: "Premium Tables with Bidding",
      content1: "A New Dimension for Reservations",
      content2:
        "VenueBoost presents Premium Tables with Bidding, adding excitement and revenue potential to table reservations.",
      content3: [
        "Allow guests to bid on premium tables for special events",
        "Set dynamic pricing based on demand and popularity",
        "Increase revenue by optimizing table allocations",
        "Enhance the guest experience with personalized table choices",
      ],
      content4:
        "Maximize your revenue and create unforgettable experiences with VenueBoost's Premium Tables with Bidding.",
    },
    {
      title: "Dining Guest Loyalty Program",
      content: (
        <div className='flex flex-col-reverse md:flex-row mt-0 md:mt-[58px]'>
          <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0'>
            <img src={OverviewFoodGuestsManagement} />
          </div>
          <div className='w-full md:w-[50%] md:p-0 md:pr-0  flex-col'>
            <h1
              className='text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'
              style={{alignSelf: "left"}}
            >
              For Streamlined Operations
            </h1>

            <p className='text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
              In dining, VenueBoost introduces the Guest Loyalty Program to
              streamline orders, reservations, and guest interactions.
            </p>
            <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Allow guests to earn points with every order or reservation
                </span>
              </div>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Offer rewards like free meals or discounts based on points
                  earned
                </span>
              </div>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Enhance guest experiences with personalized services
                </span>
              </div>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Manage operations efficiently
                </span>
              </div>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Drive loyalty through exclusive offers
                </span>
              </div>
            </div>

            <p className='text-[#666666] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px] mb-8'>
              Elevate your dining business with VenueBoost's Dining Guest
              Loyalty Program.
            </p>
          </div>
        </div>
      ),
      title_modal: "Dining Guest Loyalty Program",
      content1: "For Streamlined Operations",
      content2:
        "In dining, VenueBoost introduces the Guest Loyalty Program to streamline orders, reservations, and guest interactions.",
      content3: [
        "Allow guests to earn points with every order or reservation",
        "Offer rewards like free meals or discounts based on points earned",
        "Enhance guest experiences with personalized services",
        "Manage operations efficiently",
        "Drive loyalty through exclusive offers",
      ],
      content4:
        "Elevate your dining business with VenueBoost's Dining Guest Loyalty Program.",
    },
    {
      title: "Advanced Guest Behavior Analytics",
      content: (
        <div className='flex flex-col-reverse md:flex-row mt-0 md:mt-[58px]'>
          <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0'>
            <img src={OverviewFoodGuestsManagement} />
          </div>
          <div className='w-full md:w-[50%] md:p-0 md:pr-0  flex-col'>
            <h1
              className='text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'
              style={{alignSelf: "left"}}
            >
              Data-Driven Guest Insights
            </h1>

            <p className='text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
              Gain deep insights into guest behavior with VenueBoost's Advanced
              Guest Behavior Analytics. Understand your guests better and tailor
              your services accordingly.
            </p>
            <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Analyze preferences, history, and engagement patterns
                </span>
              </div>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Make data-driven decisions to improve experiences
                </span>
              </div>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Effectively target marketing based on behavior insights
                </span>
              </div>
            </div>
            <p className='text-[#666666] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px] mb-8'>
              VenueBoost empowers businesses to enhance guest engagement through
              data-driven strategies.
            </p>
          </div>
        </div>
      ),
      title_modal: "Advanced Guest Behavior Analytics",
      content1: "Data-Driven Guest Insights",
      content2:
        "Gain deep insights into guest behavior with VenueBoost's Advanced Guest Behavior Analytics. Understand your guests better and tailor your services accordingly.",
      content3: [
        "Analyze preferences, history, and engagement patterns",
        "Make data-driven decisions to improve experiences",
        "Effectively target marketing based on behavior insights",
      ],
      content4:
        "VenueBoost empowers businesses to enhance guest engagement through data-driven strategies.",
    },
    {
      title: "Marketing Automation",
      content: (
        <div className='flex flex-col-reverse md:flex-row mt-0 md:mt-[58px]'>
          <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0'>
            <img src={OverviewFoodGuestsManagement} />
          </div>
          <div className='w-full md:w-[50%] md:p-0 md:pr-0  flex-col'>
            <h1
              className='text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'
              style={{alignSelf: "left"}}
            >
              Boost Engagement and Sales
            </h1>

            <p className='text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
              Take your marketing to the next level with VenueBoost's Marketing
              Automation. Seamlessly automate and personalize campaigns to drive
              engagement and sales.
            </p>
            <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Create targeted email campaigns based on guest behavior
                </span>
              </div>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Send personalized offers and recommendations
                </span>
              </div>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Measure effectiveness and optimize strategies
                </span>
              </div>
            </div>
            <p className='text-[#666666] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px] mb-8'>
              VenueBoost streamlines your marketing across industries with
              powerful automation.
            </p>
          </div>
        </div>
      ),
      title_modal: "Marketing Automation",
      content1: "Boost Engagement and Sales",
      content2:
        "Take your marketing to the next level with VenueBoost's Marketing Automation. Seamlessly automate and personalize campaigns to drive engagement and sales.",
      content3: [
        "Create targeted email campaigns based on guest behavior",
        "Send personalized offers and recommendations",
        "Measure effectiveness and optimize strategies",
      ],
      content4:
        "VenueBoost streamlines your marketing across industries with powerful automation.",
    },
  ];

  const onSubmit = () => {
    if (
      !restaurant_country ||
      !restaurant_zipcode ||
      !restaurant_state ||
      !restaurant_city
    ) {
      NotificationManager.error(
        t("who_we_serve.food_industry.error_msg"),
        t("who_we_serve.food_industry.error"),
        3000
      );

      return false;
    }

    const data = {
      industry: "Food",
      category: venuetype === "Any" ? "restaurant" : venuetype,
      is_demo: true,
      restaurant_country: restaurant_country,
      restaurant_zipcode: restaurant_zipcode,
      restaurant_state: restaurant_state,
      restaurant_city: restaurant_city,
      restaurant_name: restaurant_name,
      email: email,
      mobile: mobile,
      last_name: last_name,
      first_name: first_name,
      contact_reason: "I want to schedule a demo of VenueBoost",
    };
    createContactSale(data)
      .then(data => {
        setRestaurantZipcode(null);
        setRestaurantState(null);
        setRestaurantName(null);
        setRestaurantCountry(null);
        setRestaurantCity(null);
        setVenuetype(null);
        setFirstname(null);
        setLastname(null);
        setMobile(null);
        setEmail(null);
        NotificationManager.success(
          data?.message || t("who_we_serve.food_industry.success_msg"),
          t("who_we_serve.food_industry.success"),
          5000
        );

        setStep(1);
      })
      .catch(error => {
        NotificationManager.error(
          error.message || t("who_we_serve.food_industry.error_msg"),
          t("who_we_serve.food_industry.error"),
          3000
        );
      });
  };

  return (
    <div className={"align-col-middle food-industry mt-0"}>
      <Seo
        title={seoTitle + " | VenueBoost"}
        description={seoDescription}
        image={OverviewBannerImg}
        url={currentUrl}
      />
      <div className='flex flex-col md:flex-row justify-center overflow-hidden bg-[#F3F3F3] px-4 sm:px-6 md:px-8 lg:px-16 pb-20 w-[100vw] relative'>
        {/*<div*/}
        {/*    className="hidden md:block w-[900px] h-[900px] absolute -left-[400px] -top-[100px]"*/}
        {/*    style={{*/}
        {/*        background:*/}
        {/*            "radial-gradient(circle at center, rgba(183, 255, 157, 0.7), transparent, transparent)",*/}
        {/*    }}*/}
        {/*></div>*/}

        <div className='xl:!w-[1228px] flex flex-col md:flex-row'>
          <div className='w-full md:w-[50%] flex flex-col md:pr-[58px] py-6 md:py-[44px] z-10 '>
            <span className=' text-[32px]  md:text-[60px] leading-[45px] md:leading-[60px] font-extrabold '>
              Pop-up Dining
            </span>

            <p className='hidden md:block text-[#000000] text-14 md:text-20 font-medium leading-5 md:leading-7 mt-2 md:mt-4 mb-[22px] md:mb-[44px]'>
              Turn pop-up dining into a memorable experience with VenueBoost.
              Manage reservations, streamline operations, and engage customers
              with real-time promotions. Our platform provides insights to make
              each pop-up event a success.
            </p>

            <div className='hidden md:block mb-[15px] mt-10 md:mb-0'>
              <a
                href='/get-started'
                className='rounded-md text-white text-[11px] md:text-18 leading-[11px] md:leading-[18px] px-[17px] md:px-[30px] py-[9px] md:py-[15px] uppercase bg-gradient-to-r from-[#240B3B] via-[#5E1B83] to-[#BC14FE]'
              >
                {t("who_we_serve.food_industry.unlock_free_plan")}
              </a>
            </div>
          </div>
          <div className=' md:block md:w-[50%] md:mt-12  relative align-middle'>
            <img
              className='object-cover md:absolute rounded-[20px] right-[29px] max-h-full'
              src={OverviewBannerImg}
            />
          </div>

          <div className='flex flex-col md:hidden mt-6'>
            <p className='text-[#000000] text-14 md:text-20 font-medium leading-5 md:leading-7 mt-2 md:mt-4 mb-[22px] md:mb-[44px]'>
              Turn pop-up dining into a memorable experience with VenueBoost.
              Manage reservations, streamline operations, and engage customers
              with real-time promotions. Our platform provides insights to make
              each pop-up event a success.
            </p>

            <div className=' mt-6 md:mt-0'>
              <a
                href='/get-started'
                className='flex w-full justify-center rounded-md text-white font-bold text-18 leading-[18px] md:px-[30px] py-[15px] uppercase bg-gradient-to-r from-[#240B3B] via-[#5E1B83] to-[#BC14FE]'
              >
                {t("who_we_serve.food_industry.unlock_free_plan")}
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="flex flex-col w-full mt-8 md:mt-18">
        <p className="text-20 md:text-[39px] leading-[26px] md:leading-[48px] font-bold text-center mb-2 w-[80%] md:w-full mx-auto">
          {t("who_we_serve.food_industry.who_we_serve")}
        </p>
        <p className="text-center text-[#5E5E5E] font-medium text-14 md:text-[24px] mt-0 mb-8">
          VenueBoost delivers customized food service tools for:
        </p>
        <ServicesCarousel  type={"food"}/>
      </div> */}
      <div className='flex flex-col-reverse md:flex-row mt-6 md:mt-[38px]'>
        <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0'>
          <img src={OverviewImg1} />
        </div>

        <div className='w-full md:w-[50%] md:p-12 md:pr-0  flex-col'>
          <h1 className='text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-8'>
            {t("who_we_serve.food_industry.streamlined_reservation")}
          </h1>
          <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
            <div className='flex flex-col w-full md:w-[80%]'>
              <CheckLine
                text={t("who_we_serve.food_industry.streamlined_reservation")}
              />
              <p className='mt-2 text-14 md:text-16 font-medium text-[#161C2D]  '>
                {t("who_we_serve.food_industry.streamlined_reservation_sub")}{" "}
              </p>
            </div>
            <div className='flex flex-col w-full md:w-[80%]'>
              <CheckLine
                text={t("who_we_serve.food_industry.rservation_booking")}
              />
              <p className='mt-2 text-14 md:text-16 font-medium text-[#161C2D]  '>
                {t("who_we_serve.food_industry.rservation_booking_sub")}
              </p>
            </div>
            <div className='flex flex-col w-full md:w-[80%]'>
              <CheckLine text={"Data-Driven Insights"} />
              <p className='mt-2 text-14 md:text-16 font-medium text-[#161C2D]  '>
                Gain visibility into booking trends through actionable
                analytics. Identify high traffic periods to optimize seating and
                staffing.
              </p>
            </div>
          </div>
          <p className='text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4'>
            Stop juggling fragmented systems. VenueBoost centralizes dining
            reservations management to simplify operations.
          </p>
          <FreeTrial />
        </div>
      </div>
      <div className='flex flex-col-reverse md:flex-row-reverse mt-10 md:mt-[38px] '>
        <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0'>
          <img src={OverviewImg2} />
        </div>
        <div className='w-full md:w-[40%] md:p-0 md:pl-0  '>
          <h1 className='text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'>
            {t("who_we_serve.food_industry.inventory_management")}{" "}
          </h1>

          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            Tracking and Insights Simplified
          </p>
          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            {t("who_we_serve.food_industry.inventory_management_sub")}
          </p>
          <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
            {[
              t("who_we_serve.food_industry.inventory_management_tracking"),
              t("who_we_serve.food_industry.inventory_management_update"),
              t("who_we_serve.food_industry.inventory_management_insight"),
              t("who_we_serve.food_industry.inventory_management_view"),
              t("who_we_serve.food_industry.inventory_management_history"),
            ].map(item => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className='text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4'>
            Take the complexity out of stock management. VenueBoost offers the
            visibility needed to streamline purchasing and menus.
          </p>
          <FreeTrial />
        </div>
      </div>

      <div className='flex flex-col-reverse md:flex-row mt-6 md:mt-[38px]'>
        <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0'>
          <img src={OverviewImg3} />
        </div>
        <div className='w-full md:w-[50%] md:p-12 md:pr-0  flex-col'>
          <h1
            className='text-[#240b3b] text-22 align-left md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'
            style={{alignSelf: "left"}}
          >
            {t("who_we_serve.food_industry.staff_managment")}
          </h1>
          <p className='text-[#666666] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
            Easier Scheduling and Payroll
          </p>

          <p className='text-[#666666] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
            VenueBoost empowers restaurants, cafes, bars, and more, to
            efficiently schedule staff, manage shifts, process payroll, and gain
            data-driven insights.
          </p>
          <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
            {[
              "Create and update schedules with ease",
              "Automate wage and tip calculations",
              "Track hours and staff performance",
              "Identify scheduling gaps and bottlenecks",
              "Ensure smooth operations and coordination",
            ].map(item => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className='text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4'>
            VenueBoost centralizes staff management to optimize operations.
          </p>
          <FreeTrial />
        </div>
      </div>
      <div className='flex flex-col-reverse md:flex-row-reverse mt-10 md:mt-[38px] '>
        <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0'>
          <img src={OverviewImg4} />
        </div>
        <div className='w-full md:w-[40%] md:p-0 md:pl-0   '>
          <h1 className='text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'>
            {t("who_we_serve.food_industry.marketing")}{" "}
          </h1>
          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            Expanded Reach and Brand Promotion
          </p>

          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            {t("who_we_serve.food_industry.marketing_sub")}
          </p>
          <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
            {[
              t("who_we_serve.food_industry.marketing_email"),
              t("who_we_serve.food_industry.marketing_promotion"),
              t("who_we_serve.food_industry.marketing_campaigns"),
              t("who_we_serve.food_industry.marketing_refferals"),
            ].map(item => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className='text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4'>
            Consolidate marketing activities. VenueBoost brings together the
            capabilities dining venues need to grow.
          </p>
          <FreeTrial />
        </div>
      </div>

      <div className='flex flex-col-reverse md:flex-row mt-6 md:mt-[38px]'>
        <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0'>
          <img src={OverviewImg5} />
        </div>
        <div className='w-full md:w-[50%] md:p-12 md:pr-0  flex-col'>
          <h1
            className='text-[#240b3b] text-22 align-left md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'
            style={{alignSelf: "left"}}
          >
            {t("who_we_serve.food_industry.loyalty")}
          </h1>

          <p className='text-[#666666] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
            Reward Your Guests and Motivate Return Visits
          </p>
          <p className='text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
            {t("who_we_serve.food_industry.loyalty_sub")}
          </p>
          <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
            {[
              "Tailor tiers and benefits",
              "Track member profiles and purchase history",
              "Send personalized rewards and offers",
              "Analyze engagement and activity",
            ].map(item => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className='text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4'>
            Strengthen relationships with regulars. VenueBoost provides the
            tools to increase loyalty, retention, and repeat visits.
          </p>
          <FreeTrial />
        </div>
      </div>
      <div className='flex flex-col-reverse md:flex-row-reverse mt-10 md:mt-[38px] '>
        <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0'>
          <img src={OverviewImg6} />
        </div>
        <div className='w-full md:w-[40%] md:p-12 md:pl-0   '>
          <h1 className='text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'>
            {t("who_we_serve.food_industry.reporting")}{" "}
          </h1>
          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            Actionable Dining Insights
          </p>

          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            {t("who_we_serve.food_industry.reporting_sub")}
          </p>
          <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
            {[
              t("who_we_serve.food_industry.reporting_sales"),
              t("who_we_serve.food_industry.reporting_staff"),
              t("who_we_serve.food_industry.reporting_table"),
              t("who_we_serve.food_industry.reporting_insight"),
              t("who_we_serve.food_industry.reporting_waitlist"),
            ].map(item => (
              <CheckLine text={item} />
            ))}
          </div>

          <p className='text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4'>
            Stop relying on guesswork. VenueBoost provides clear visibility into
            key metrics to run a smarter dining business.
          </p>
          <FreeTrial />
        </div>
      </div>

      <div className='flex flex-col-reverse md:flex-row mt-6 md:mt-[58px]'>
        <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-2 md:mt-0'>
          <img src={OverviewImg7} />
        </div>
        <div className='w-full md:w-[50%] md:p-0 md:pr-0  flex-col'>
          <h1
            className='text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'
            style={{alignSelf: "left"}}
          >
            {t("who_we_serve.food_industry.payment_links")}
          </h1>

          <p className='text-[#666666] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
            Simplified Online Reservations Payments
          </p>

          <p className='text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
            {t("who_we_serve.food_industry.payment_links_sub")}
          </p>
          <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
            {[
              "Create secure online reservation payment links",
              "Organized list to track payment links",
              "Insights into transaction volumes and revenue",
              "Smooth, convenient payment process for guests",
            ].map(item => (
              <CheckLine text={item} />
            ))}
          </div>
          <FreeTrial />
        </div>
      </div>
      <div className='flex flex-col-reverse md:flex-row-reverse mt-10 md:mt-[38px] '>
        <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0'>
          <img src={OverviewImg8} />
        </div>
        <div className='w-full md:w-[40%] md:p-12 md:pl-0   '>
          <h1 className='text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'>
            {t("who_we_serve.food_industry.order")}{" "}
          </h1>
          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            Streamlined Dining Delivery
          </p>

          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            {t("who_we_serve.food_industry.order_sub")}
          </p>
          <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
            {[
              t("who_we_serve.food_industry.order_house"),
              t("who_we_serve.food_industry.order_provider"),
              t("who_we_serve.food_industry.order_management"),
              t("who_we_serve.food_industry.order_detail"),
              t("who_we_serve.food_industry.order_history"),
            ].map(item => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className='text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4'>
            Delivery without the headaches. VenueBoost simplifies dining
            order-to-door workflows.
          </p>
          <FreeTrial />
        </div>
      </div>
      <div className='flex flex-col-reverse md:flex-row mt-0 md:mt-[38px]'>
        <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0'>
          <img src={OverviewFoodGuestsManagement} />
        </div>
        <div className='w-full md:w-[50%] md:p-0 md:pr-0  flex-col'>
          <h1
            className='text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'
            style={{alignSelf: "left"}}
          >
            {t("who_we_serve.food_industry.guest_management")}
          </h1>

          <p className='text-[#666666] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
            Streamline Reservations, Loyalty, and Communication
          </p>
          <p className='text-[#666666] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
            {t("who_we_serve.food_industry.guest_management_sub")}
          </p>

          <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
            {[
              t("who_we_serve.food_industry.guest_management_suite"),
              t("who_we_serve.food_industry.guest_management_preference"),
              t("who_we_serve.food_industry.guest_management_loyalty"),
              "Custom loyalty programs with special rewards",
              t("who_we_serve.food_industry.order_history"),
            ].map(item => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className='text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4'>
            Deliver exceptional experiences by truly understanding your guests.
            VenueBoost provides the tools to engage, retain, and attract
            customers.
          </p>
          <FreeTrial />
        </div>
      </div>

      <div className='flex flex-col-reverse md:flex-row-reverse mt-10 md:mt-[38px] '>
        <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0'>
          <img src={OverviewImg8} />
        </div>
        <div className='w-full md:w-[40%] md:p-12 md:pl-0   '>
          <h1 className='text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'>
            Affiliate Partnerships{" "}
          </h1>
          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            Expand Your Reach and Revenue
          </p>
          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            With VenueBoost's new Affiliates feature, you can grow your business
            and revenue by partnering with like-minded organizations.
          </p>
          <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
            {[
              "Establish affiliate partnerships seamlessly",
              "Track referrals and commissions in real-time",
              "Access valuable marketing resources",
              "Custom loyalty programs with special rewards",
              "Extend your brand's reach through strategic collaborations",
            ].map(item => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className='text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4'>
            Join forces with affiliates and unlock new opportunities with
            VenueBoost's Affiliates.
          </p>
          <FreeTrial />
        </div>
      </div>
      <div className='flex flex-col-reverse md:flex-row mt-0 md:mt-[38px]'>
        <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0'>
          <img src={OverviewFoodGuestsManagement} />
        </div>
        <div className='w-full md:w-[50%] md:p-0 md:pr-0  flex-col'>
          <h1
            className='text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'
            style={{alignSelf: "left"}}
          >
            Guest Surveys and Ratings
          </h1>
          <p className='text-[#666666] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
            Gather Valuable Feedback
          </p>
          <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
            {[
              "VenueBoost now offers powerful Surveys and Ratings to help venues enhance guest experiences and gather crucial feedback.",
              "Create customized guest surveys",
              "Collect ratings and reviews to gauge satisfaction",
              "Gain insights into preferences and opinions",
              "Make informed improvements using data-driven decisions",
            ].map(item => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className='text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4'>
            Listen to your guests and optimize offerings with VenueBoost's
            Surveys and Ratings.
          </p>
          <FreeTrial />
        </div>
      </div>

      {/* <div className={"md:px-8 px-4 mt-10 md:mt-10"}>
        <div
          className="tabs-container"
          role="tablist"
          aria-label="Products"
          data-tracking-container="Product Tabs"
        >
          {tabs.map((tab, index) => (
            <button
              key={index}
              className={`tab js-tab ${activeTab === index ? "is-active" : ""}`}
              role="tab"
              aria-selected={activeTab === index}
              aria-controls={`tab-content-${index}`}
              tabIndex="0"
              onClick={() => setActiveTab(index)}
            >
              {tab.title}
            </button>
          ))}
        </div>
        <div className="tabs-content" data-tracking-container="Tab Content">
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={`tab tab-content__panel js-tab-panel ${
                activeTab === index ? "is-active" : ""
              }`}
              role="tabpanel"
              id={`tab-content-${index}`}
              aria-labelledby={`tab-${index}`}
              aria-hidden={activeTab !== index}
              tabIndex="0"
            >
              {tab.content}
            </div>
          ))}
        </div>
      </div> */}

      <Grow
        venueColor={"#17CE8C"}
        title={"Additional Food & Beverage Features"}
        subtitle={
          "Dive into VenueBoost's Expanded Capabilities for the Food & Beverage Industry"
        }
        tabs={tabs}
      />

      <div className='align-col-middle '>
        <div className='w-[100vw] bg-[#240B3B]'>
          <div className='max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 '>
            <div className='flex flex-col align-middle w-full'>
              <h1 className='text-white  text-26 md:text-[42px] text-center font-extrabold leading-[60px] mt-10 md:mt-20 '>
                {t("who_we_serve.food_industry.faq")}
                <span className='text-white'>
                  {" "}
                  ({t("who_we_serve.food_industry.faq_short")}){" "}
                </span>
              </h1>
              <p className='text-14 md:text-20 text-white px-7 leading-5 font-medium mt-4 mb:mt-20 mb-5 md:mb-[66px]'>
                {t("who_we_serve.food_industry.food_industry")}
              </p>
              <Faq faqData={generalFaq} className='mt-2 mb-4 md:mb-16' />
            </div>
          </div>
        </div>
      </div>

      <Advantage venueDefinition={"food industry"} />
      <div className='align-col-middle'>
        <div className='w-[100vw] bg-white'>
          <div className='mx-auto max-w-screen-main px-4 sm:px-6 md:px-8 lg:px-16'>
            <div className='flex flex-col align-middle mb-4 md:mb-20'>
              <FreePlanCard />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopUpRestaurants;
