import apiFactory from '../../utils/apiFactory';
import {API_MARKETING_ONBOARDING, API_MARKETING_WAITLIST, API_PRIVACY_RIGHTS_FORM} from '../../constants/apiConfig';


export const joinWaitlist = (payload) => {
    return new Promise(async (resolve, reject) => {
        apiFactory({ ...API_MARKETING_WAITLIST, method: 'post' }, payload)
            .then(async (data) => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            })
    });
};


export const verifyMarketingWaitlistLink = (payload) => {
    return new Promise(async (resolve, reject) => {
        apiFactory({ ...API_MARKETING_WAITLIST, path: (API_MARKETING_WAITLIST.path + '/verify-email-link'), method: 'post' }, payload)
            .then(async (data) => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const generateMarketingWaitlistLink = (payload) => {
    return new Promise(async (resolve, reject) => {
        apiFactory({ ...API_MARKETING_WAITLIST, path: (API_MARKETING_WAITLIST.path + '/generate-email-link'), method: 'post' }, payload)
            .then(async (data) => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const requestPrivacyRightsForm = (payload) => {
    return new Promise(async (resolve, reject) => {
        apiFactory({ ...API_PRIVACY_RIGHTS_FORM, method: 'post' }, payload)
            .then(async (data) => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const verifyPrivacyRightRequestLink = (payload) => {
    return new Promise(async (resolve, reject) => {
        apiFactory({ ...API_PRIVACY_RIGHTS_FORM, path: (API_PRIVACY_RIGHTS_FORM.path + '/verify-email-link'), method: 'post' }, payload)
            .then(async (data) => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            })
    });
};