import React, {useEffect, useState} from "react";

const GetStartedDetails = () => {
  return (
    <div className='flex flex-col'>
       GetStarted Details
    </div>
  );
};

export default GetStartedDetails;
