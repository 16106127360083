import React from "react";
import FooterImg from "../../../assets/images/sitemap.webp";
import ToolImg1 from "../../../assets/images/tools/tools-guide-marketing.webp";
import {Seo} from "../../../components/Seo";
import ToolCard from "../../../components/ToolCard";

const detailsSlug = window.location.pathname;
const data = [
  {
    tagId: 1,
    title: "The Ultimate Marketing Essentials Toolkit for VenueBoost",
    date: "June 12, 2023",
    image: ToolImg1,
    author: "Kristi",
    slug: "the-ultimate-marketing-essentials-toolkit-for-venueboost",
    slug_related:
      "/resources/tools-guides/the-ultimate-marketing-essentials-toolkit-for-venueboost",
    introduction:
      "In today's competitive landscape, effective marketing plays a crucial role in driving success for businesses like VenueBoost. This toolkit and guide have been specifically crafted to provide you with the essential tools and knowledge to excel in marketing your VenueBoost software and services. By leveraging these resources, you'll be equipped to reach your target audience, strengthen your brand, and drive customer engagement.",
    feature_1_title: "Market Research and Analysis",
    feature_1_content:
      "To effectively market VenueBoost, understanding your target audience, competitors, and industry trends is essential. This section provides you with practical tools and techniques for conducting market research. From customer surveys and competitor analysis to trend analysis, these insights will inform your marketing strategies and enable you to make data-driven decisions.",
    feature_2_title: "Branding and Positioning",
    feature_2_content:
      "Building a strong and recognizable brand is vital for VenueBoost's success. In this section, we delve into the world of branding, guiding you through key elements such as logos, taglines, brand messaging, and brand voice. Utilize the provided templates and examples to create or refine your VenueBoost brand identity. Additionally, discover effective brand positioning strategies to differentiate yourself from competitors and resonate with your target customers.",
    feature_3_title: "Content Marketing",
    feature_3_content:
      "Engaging and attracting your audience is crucial, and content marketing is the key. This section equips you with a comprehensive content planning framework, covering content ideation, creation, and distribution strategies. Explore templates and examples for various content formats, such as blog posts, social media posts, videos, and infographics. Learn how to optimize content for search engines and leverage it to drive conversions.",
    feature_4_title: "Digital Advertising and Social Media Marketing",
    feature_4_content:
      "Digital advertising and social media marketing are powerful channels for promoting VenueBoost. Dive into the world of digital advertising platforms, including Google Ads and Facebook Ads. Discover targeting options, budgeting techniques, and campaign optimization strategies. Furthermore, learn best practices for effective social media marketing, including content strategies, community engagement, and influencer partnerships.",
    feature_6_title:
      "Email Marketing and Customer Relationship Management (CRM)",
    feature_6_content:
      "Nurturing leads and building customer relationships are vital for VenueBoost's growth. This section covers the importance of email marketing and offers best practices for creating compelling email campaigns. Explore strategies for personalization, segmentation, and effective email communication. Additionally, learn about Customer Relationship Management (CRM) systems and how they can streamline customer interactions, track data, and personalize communications.",
    feature_7_title: "Analytics and Performance Tracking",
    feature_7_content:
      "To measure marketing success and optimize strategies, analytics are essential. This section guides you through setting up analytics tools, such as Google Analytics, and tracking relevant metrics. Learn how to analyze data, identify trends, and make data-driven marketing decisions to continuously improve VenueBoost's marketing performance.",
    feature_end_title: "Conclusion",
    feature_end_content:
      "Congratulations on exploring the VenueBoost Marketing Essentials Toolkit and Guide! By leveraging these tools and knowledge, you are equipped to drive VenueBoost's marketing success. Remember, marketing is an ever-evolving field, so continue to adapt, learn, and experiment to stay ahead of the competition. Start implementing the strategies and techniques provided in this toolkit, and unleash the full potential of VenueBoost's marketing capabilities.",
  },
];

const tags = [
  {title: "All", url: "/resources"},
  {
    title: "Marketing",
    url: "/resources",
  },
  {
    title: "Software",
    url: "/resources",
  },
  {
    title: "Venue Management",
    url: "/resources",
  },
  {
    title: "Account Management",
    url: "/resources",
  },
  {
    title: "News & Trends",
    url: "/resources",
  },
  {
    title: "Guides",
    url: "/resources",
  },
  {
    title: "eBook",
    url: "/resources",
  },
];

const currentTool = data.find(bd => bd.slug_related === detailsSlug);

const Tool = () => (
  <div className='align-col-middle  '>
    <Seo title={currentTool.title + " | VenueBoost"} />
    <div className='flex flex-col md:flex-row md:rounded-[13px] overflow-hidden'>
      <div className='w-full md:w-[60%] flex flex-col md:pr-[58px] md:py-[24px]'>
        <a
          className='text-[#240b3b] text-[17px] font-medium leading-5  mt-6'
          href='/resources/tools-guides'
        >
          {"< Tools & Guides"}
        </a>

        <h1 className='text-[#0A0A0A] text-34 md:text-[34px] font-semibold leading-[50px] md:leading-[50px] mt-2 md:mt-6 '>
          {currentTool.title}
        </h1>
        <div className='flex items-center mt-3 md:mt-7'>
          <div className='flex flex-col md:flex-row'>
            <p className='font-normal leading-5 text-16 text-[#0A0A0A] mr-8'>
              {currentTool.author}
            </p>
            <p className='font-normal leading-5 text-16 text-[#0A0A0A] mr-8'>
              {currentTool.date}
            </p>
          </div>
        </div>
      </div>
      <div className='w-full md:w-[40%] mt-7 md:mt-4 '>
        <img className='object-cover rounded-[20px]' src={ToolImg1} />
      </div>
    </div>
    <div className='w-full py-[1px] bg-[#AFAFAF] opacity-30 my-6 hidden md:block'></div>
    <div>
      <p className='text-18 leading-[31px] font-medium my-8'>
        {currentTool.introduction}
      </p>
    </div>
    <div className='content mt-0 md:mt-6'>
      <h3 className='text-[35px] md:text-[37px] leading-[50px] md:leading-[67px] font-bold mb-5 mt-10 md:mt-0'>
        {currentTool.feature_1_title}{" "}
      </h3>

      <div className='md:w-[100%] '>
        <p className='text-18 leading-[31px] font-medium mt-6 md:mt-6  '>
          {currentTool.feature_1_content}
        </p>
      </div>
    </div>
    <div className='content mt-0 md:mt-6'>
      <h3 className='text-[35px] md:text-[37px] leading-[50px] md:leading-[67px] font-bold mb-5 mt-10 md:mt-0'>
        {currentTool.feature_2_title}{" "}
      </h3>

      <div className='md:w-[100%] '>
        <p className='text-18 leading-[31px] font-medium mt-6 md:mt-6  '>
          {currentTool.feature_2_content}
        </p>
      </div>
    </div>
    <div className='content mt-0 md:mt-6'>
      <h3 className='text-[35px] md:text-[37px] leading-[50px] md:leading-[67px] font-bold mb-5 mt-10 md:mt-0'>
        {currentTool.feature_3_title}{" "}
      </h3>

      <div className='md:w-[100%] '>
        <p className='text-18 leading-[31px] font-medium mt-6 md:mt-6  '>
          {currentTool.feature_3_content}
        </p>
      </div>
    </div>
    <div className='content mt-0 md:mt-6'>
      <h3 className='text-[35px] md:text-[37px] leading-[50px] md:leading-[67px] font-bold mb-5 mt-10 md:mt-0'>
        {currentTool.feature_4_title}{" "}
      </h3>

      <div className='md:w-[100%] '>
        <p className='text-18 leading-[31px] font-medium mt-6 md:mt-6  '>
          {currentTool.feature_4_content}
        </p>
      </div>
    </div>
    <div className='content mt-0 md:mt-6'>
      <h3 className='text-[35px] md:text-[37px] leading-[50px] md:leading-[67px] font-bold mb-5 mt-10 md:mt-0'>
        {currentTool.feature_6_title}{" "}
      </h3>

      <div className='md:w-[100%] '>
        <p className='text-18 leading-[31px] font-medium mt-6 md:mt-6  '>
          {currentTool.feature_6_content}
        </p>
      </div>
    </div>
    <div className='content mt-0 md:mt-6'>
      <h3 className='text-[35px] md:text-[37px] leading-[50px] md:leading-[67px] font-bold mb-5 mt-10 md:mt-0'>
        {currentTool.feature_7_title}{" "}
      </h3>

      <div className='md:w-[100%] '>
        <p className='text-18 leading-[31px] font-medium mt-6 md:mt-6  '>
          {currentTool.feature_7_content}
        </p>
      </div>
    </div>
    <div className='content mt-0 md:mt-6'>
      <h3 className='text-[35px] md:text-[37px] leading-[50px] md:leading-[67px] font-bold mb-5 mt-10 md:mt-0'>
        {currentTool.feature_end_title}{" "}
      </h3>

      <div className='md:w-[100%] '>
        <p className='text-18 leading-[31px] font-medium mt-6 md:mt-6  '>
          {currentTool.feature_end_content}
        </p>
      </div>
    </div>
    <div className='my-8 md:my-20 align-middle flex flex-col '>
      <h1 className='text-20 md:text-40 font-semibold text-[#333333] leading-[31px] mb-8 md:mb-[60px]'>
        More Tools & Guides
      </h1>
      <div className='grid grid-cols-1 sm:grid-cols-3 gap-x-[60px] gap-y-[29px] md:gap-y-[37px]'>
        {data &&
          data.length > 0 &&
          data.map((card, index) => (
            <ToolCard
              key={index}
              img={card.image}
              tag={tags[card.tagId].title}
              title={card.title}
              desc={card.desc}
              slug={card.slug}
            />
          ))}
      </div>{" "}
    </div>

    <div className='w-[100vw]  relative translate-y-[1px]'>
      <img src={FooterImg} className='min-w-full h-[352px] object-cover' />
      <div className='absolute top-0  w-full h-full align-middle flex flex-col md:flex-row'>
        <h1 className='text-white text-24 md:text-[48px] leading-[41px] font-extrabold text-center md:mr-[95px] mb-8 md:mb-0 px-[25%]  md:px-0'>
          Elevate Your Business with VenueBoost
        </h1>
        <a
          className='text-[#240b3b] bg-white rounded-md text-16 md:text-18 leading-8 font-extrabold text-center px-9 md:px-20 py-1 md:py-[10px] whitespace-nowrap'
          href='/get-started'
        >
          ACCESS FREE PLAN
        </a>
      </div>
    </div>
  </div>
);

export default Tool;
