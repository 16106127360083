import React from "react";
import cs from "classnames";

function CircleTick(props) {
  return (
    <div
      className={cs(props.className, "rounded-full flex-shrink-0 circle-tick")}
    >
      <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Group 427319155">
          <circle id="Ellipse 963" cx="12.5" cy="12.5" r="12.5" fill="none" />
          <path
            id="Vector"
            d="M18.2375 7L10 15.2375L6.7625 12.0125L5 13.775L10 18.775L20 8.775L18.2375 7Z"
            fill="white"
          />
        </g>
      </svg>
    </div>
  );
}

const CheckLine = ({ text }) => {
  return (
    <div className="flex flex-row items-center">
      <CircleTick className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]" />
      <span className="text-14  md:text-18 font-bold text-[#240B3B] inline">
        {text}
      </span>
    </div>
  );
};

export { CircleTick, CheckLine };
