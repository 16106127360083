import PropTypes from "prop-types";
import "../index.css";
import OrderPreview from "../order-details/order-preview";
import IconArrowRight from "../../../assets/images/beachbarorder/iconsarrowright.svg";
import CustomDrawer from "../../../components/custom-drawer/custom-drawer";
import { beachBarOrderText } from "..";

const ButtonsSectionsConfirmOrder = ({
  className = "",
  price = "",
  amount = 0,
  isOpen,
  setIsDetailsOpen,
  setIsPreviewOpen,
  products,
  currency,
  totalPrice,
  setProducts,
}) => {
  const toggleDrawer = (open) => () => {
    setIsPreviewOpen(open);
    setIsDetailsOpen(false);
  };
  return (
    <>
      <CustomDrawer isOpen={isOpen} toggleDrawer={toggleDrawer}>
        <OrderPreview
          {...{
            setIsDetailsOpen,
            setIsPreviewOpen,
            products,
            currency,
            totalPrice,
            setProducts,
          }}
        />
      </CustomDrawer>

      <button
        disabled={amount === 0}
        onClick={toggleDrawer(true)}
        className={`${
          amount === 0 ? "cursor-not-allowed opacity-70" : "cursor-pointer"
        } h-[54px] bg-custom-green shadow-[0px_0px_5px_rgba(12,_26,_75,_0.02),_0px_-10px_20px_rgba(220,_220,_228,_0.3)] rounded-t-7xl rounded-b-none bg-bg flex flex-row items-start justify-start py-4 px-6 box-border max-w-full text-left text-base text-bg font-button-medium-extrabold ${className}`}
      >
        <div className="rounded-2xl bg-mediumseagreen overflow-x-auto flex items-center justify-center pt-[13.9px] px-5 md:px-[59px] pb-[13.8px] box-border gap-[8px] max-w-full">
          <div className="text-white relative md:leading-[22px] font-semibold inline-block md:min-w-[103px] text-[14px] md:text-[16px]">
            {beachBarOrderText("confirmOrder")}
          </div>
          <div className="text-white text-[14px] md:text-[16px] relative leading-[22px] font-extrabold text-center inline-block min-w-[64px]">
            {price}
          </div>
          <div className="w-[26px] shrink-0 flex flex-row items-start justify-start text-2xs-8 text-neutral-neutral-600">
            <div className="rounded-number flex-1 rounded-[12.36px] bg-honeydew flex flex-row items-center justify-center pt-[5.4px] px-[9px] pb-[5.5px]">
              <span className="text-[#666687] font-bold">{amount}</span>
            </div>
          </div>
          <img
            className="h-5 w-5 relative overflow-hidden shrink-0 hidden"
            alt=""
            src={IconArrowRight}
          />
        </div>
      </button>
    </>
  );
};

ButtonsSectionsConfirmOrder.propTypes = {
  className: PropTypes.string,
};

export default ButtonsSectionsConfirmOrder;
