import React from "react";
import "../index.css";
import { emptyStateIcon } from "../icons";

const EmptyState = () => {
  return (
    <div className="empty-state col-span-3 mt-8 flex flex-col items-center justify-center gap-2">
      <div className="relative">
        {emptyStateIcon}
        <div className="absolute flex flex-col items-center gap-2 bottom-2 w-full z-[9999] bg-white">
          <h1>No Result</h1>
          <span>No results shown for “Swarovski”</span>
        </div>
      </div>
    </div>
  );
};

export default EmptyState;
