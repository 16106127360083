import React from "react";
import Faq from "../../components/Faq";
import { generalFaq } from "./blog-details-data";

export const StaticBlogDetail = ({ currentBlog, headings }) => {
  return (
    <div className={`w-full ${headings.length > 0 ? "md:w-[75%]" : ""}`}>
      <p className="text-16 font-normal text-[#666666] md:text-18 leading-[26px] md:leading-[32px]">
        {currentBlog.introduction}
      </p>
      <h2
        className={
          currentBlog.section_1_h1
            ? "toc-heading text-20 md:text-28 leading-[32x] font-bold mt-7"
            : "text-20 md:text-28 leading-[32x] font-bold mt-7"
        }
      >
        {currentBlog.section_1_h1 ? currentBlog.section_1_h1 : ""}
      </h2>
      <h3
        className={`${
          currentBlog.section_1_title ? "toc-heading " : ""
        }text-18 md:text-24 md:leading-[32px] font-bold mt-7 mb-4`}
      >
        {currentBlog.section_1_title ? currentBlog.section_1_title : ""}
      </h3>

      {currentBlog.detail_image_4 && (
        <>
          <img
            src={currentBlog.detail_image_4}
            className="article__img mt-4 mb-6"
            alt=""
          />
          {currentBlog.show_source && (
            <div className="flex justify-center mb-6 italic text-18">
              <h4 className="text-gray-500">Source:</h4>
              <a className="ml-1" href="#">
                VenueBoost
              </a>
            </div>
          )}
        </>
      )}

      <p className="text-16 font-normal text-[#666666] md:text-18  leading-[26px] md:leading-[32px] mt-2">
        {currentBlog.section_1_content}
      </p>

      {currentBlog.detail_image_5_new && (
        <>
          <img
            src={currentBlog.detail_image_5_new}
            className="article__img mt-4 mb-6"
            alt=""
          />
          {currentBlog.show_source && (
            <div className="flex justify-center mb-6 italic text-18">
              <h4 className="text-gray-500">Source:</h4>
              <a className="ml-1" href="#">
                VenueBoost
              </a>
            </div>
          )}
        </>
      )}

      <h3
        className={`${
          currentBlog.section_1_point_1_title ? "toc-heading " : ""
        }text-18 md:text-24 leading-[32x] font-bold mt-7 mb-4`}
      >
        {currentBlog.section_1_point_1_title
          ? currentBlog.section_1_point_1_title
          : ""}
      </h3>
      {/*)}*/}
      <h3
        className={`${
          currentBlog.section_1_point_1_title ? "toc-heading " : ""
        }text-18 md:text-24 leading-[32x] font-bold mt-7 mb-4`}
      >
        {currentBlog.section_1_point_1_title
          ? currentBlog.section_1_point_1_title
          : ""}
      </h3>

      <p className="text-16 font-normal text-[#666666] md:text-18  leading-[26px] md:leading-[32px] mt-2">
        {currentBlog.section_1_point_1_content}
      </p>

      {currentBlog.section_1_list && (
        <div className="my-4">
          <h2 className="text-18 md:text-24 leading-[32x] font-bold">
            {currentBlog.section_1_list.title}
          </h2>
          <ul className="mt-4 list-disc ml-10">
            {currentBlog.section_1_list.items &&
              currentBlog.section_1_list.items.map((item, index) => {
                return (
                  <li className="text-18">
                    {item.title && (
                      <span className="font-bold">{item.title}</span>
                    )}
                    <span className="ml-1">{item.content}</span>
                  </li>
                );
              })}
          </ul>
        </div>
      )}

      <h3
        className={`${
          currentBlog.section_1_point_2_title ? "toc-heading " : ""
        }text-18 md:text-24 leading-[32x] font-bold mt-7`}
      >
        {currentBlog.section_1_point_2_title
          ? currentBlog.section_1_point_2_title
          : ""}
      </h3>

      <p className="text-16 font-normal text-[#666666] md:text-18  leading-[26px] md:leading-[32px] mt-2">
        {currentBlog.section_1_point_2_content}
      </p>

      {currentBlog.section_1_ul_list && (
        <div className="my-4">
          <h2 className="text-18 md:text-24 leading-[32x] font-bold">
            {currentBlog.section_1_ul_list.title ?? ""}
          </h2>
          <ul className="mt-4 list-disc ml-4 md:ml-10">
            {currentBlog.section_1_ul_list.items &&
              currentBlog.section_1_ul_list.items.map((item, index) => {
                return (
                  <li className="text-16 md:text-18 ">
                    <span className="font-bold">{item.title}</span>
                    <span className="ml-1 text-[#666666]">{item.content}</span>
                  </li>
                );
              })}
          </ul>
        </div>
      )}

      {currentBlog.section_1_new_ul_list && (
        <div className="my-4">
          <h2 className="text-18 md:text-20 leading-[32x] font-bold">
            {currentBlog.section_1_new_ul_list.title ?? ""}
          </h2>
          <ul className="mt-4 list-disc ml-4 md:ml-10">
            {currentBlog.section_1_new_ul_list.items &&
              currentBlog.section_1_new_ul_list.items.map((item, index) => {
                return (
                  <li className="text-16 md:text-18 ">
                    {item.title && (
                      <span className="font-bold">{item.title}</span>
                    )}
                    <span className="text-[#666666]">{item.content}</span>
                  </li>
                );
              })}
          </ul>
        </div>
      )}

      {currentBlog.section_2_new_ul_list && (
        <div className="my-4">
          <h2 className="text-18 md:text-20 leading-[32x] font-bold">
            {currentBlog.section_2_new_ul_list.title ?? ""}
          </h2>
          <ul className="mt-4 list-disc ml-4 md:ml-10">
            {currentBlog.section_2_new_ul_list.items &&
              currentBlog.section_2_new_ul_list.items.map((item, index) => {
                return (
                  <li className="text-16 md:text-18 ">
                    {item.title && (
                      <span className="font-bold">{item.title}</span>
                    )}
                    <span className="text-[#666666]">{item.content}</span>
                  </li>
                );
              })}
          </ul>
        </div>
      )}

      {currentBlog.section_3_new_ul_list && (
        <div className="my-4">
          <h2 className="text-18 md:text-20 leading-[32x] font-bold">
            {currentBlog.section_3_new_ul_list.title ?? ""}
          </h2>
          <ul className="mt-4 list-disc ml-4 md:ml-10">
            {currentBlog.section_3_new_ul_list.items &&
              currentBlog.section_3_new_ul_list.items.map((item, index) => {
                return (
                  <li className="text-16 md:text-18 ">
                    {item.title && (
                      <span className="font-bold">{item.title}</span>
                    )}
                    <span className="text-[#666666]">{item.content}</span>
                  </li>
                );
              })}
          </ul>
        </div>
      )}

      {currentBlog.section_4_new_ul_list && (
        <div className="my-4">
          <h2 className="text-18 md:text-20 leading-[32x] font-bold">
            {currentBlog.section_4_new_ul_list.title ?? ""}
          </h2>
          <ul className="mt-4 list-disc ml-4 md:ml-10">
            {currentBlog.section_4_new_ul_list.items &&
              currentBlog.section_4_new_ul_list.items.map((item, index) => {
                return (
                  <li className="text-16 md:text-18 ">
                    {item.title && (
                      <span className="font-bold">{item.title}</span>
                    )}
                    <span className="text-[#666666]">{item.content}</span>
                  </li>
                );
              })}
          </ul>
        </div>
      )}

      {currentBlog.section_5_new_ul_list && (
        <div className="my-4">
          <h2 className="text-18 md:text-20 leading-[32x] font-bold">
            {currentBlog.section_5_new_ul_list.title ?? ""}
          </h2>
          <ul className="mt-4 list-disc ml-4 md:ml-10">
            {currentBlog.section_5_new_ul_list.items &&
              currentBlog.section_5_new_ul_list.items.map((item, index) => {
                return (
                  <li className="text-16 md:text-18 ">
                    {item.title && (
                      <span className="font-bold">{item.title}</span>
                    )}
                    <span className="text-[#666666]">{item.content}</span>
                  </li>
                );
              })}
          </ul>
        </div>
      )}

      {currentBlog.section_6_new_ul_list && (
        <div className="my-4">
          <h2 className="text-18 md:text-20 leading-[32x] font-bold">
            {currentBlog.section_6_new_ul_list.title ?? ""}
          </h2>
          <ul className="mt-4 list-disc ml-4 md:ml-10">
            {currentBlog.section_6_new_ul_list.items &&
              currentBlog.section_6_new_ul_list.items.map((item, index) => {
                return (
                  <li className="text-16 md:text-18 ">
                    {item.title && (
                      <span className="font-bold">{item.title}</span>
                    )}
                    <span className="text-[#666666]">{item.content}</span>
                  </li>
                );
              })}
          </ul>
        </div>
      )}

      {currentBlog.section_7_new_ul_list && (
        <div className="my-4">
          <h2 className="text-18 md:text-20 leading-[32x] font-bold">
            {currentBlog.section_7_new_ul_list.title ?? ""}
          </h2>
          <ul className="mt-4 list-disc ml-4 md:ml-10">
            {currentBlog.section_7_new_ul_list.items &&
              currentBlog.section_7_new_ul_list.items.map((item, index) => {
                return (
                  <li className="text-16 md:text-18 ">
                    {item.title && (
                      <span className="font-bold">{item.title}</span>
                    )}
                    <span className="text-[#666666]">{item.content}</span>
                  </li>
                );
              })}
          </ul>
        </div>
      )}

      {currentBlog.section_8_new_ul_list && (
        <div className="my-4">
          <h2 className="text-18 md:text-20 leading-[32x] font-bold">
            {currentBlog.section_8_new_ul_list.title ?? ""}
          </h2>
          <ul className="mt-4 list-disc ml-4 md:ml-10">
            {currentBlog.section_8_new_ul_list.items &&
              currentBlog.section_8_new_ul_list.items.map((item, index) => {
                return (
                  <li className="text-16 md:text-18 ">
                    {item.title && (
                      <span className="font-bold">{item.title}</span>
                    )}
                    <span className="text-[#666666]">{item.content}</span>
                  </li>
                );
              })}
          </ul>
        </div>
      )}

      {currentBlog.section_9_new_ul_list && (
        <div className="my-4">
          <h2 className="text-18 md:text-20 leading-[32x] font-bold">
            {currentBlog.section_9_new_ul_list.title ?? ""}
          </h2>
          <ul className="mt-4 list-disc ml-4 md:ml-10">
            {currentBlog.section_9_new_ul_list.items &&
              currentBlog.section_9_new_ul_list.items.map((item, index) => {
                return (
                  <li className="text-16 md:text-18 ">
                    {item.title && (
                      <span className="font-bold">{item.title}</span>
                    )}
                    <span className="text-[#666666]">{item.content}</span>
                  </li>
                );
              })}
          </ul>
        </div>
      )}

      {currentBlog.detail_image_new && (
        <>
          <img
            src={currentBlog.detail_image_new}
            className="article__img mt-4 mb-6"
            alt=""
          />
          {currentBlog.show_source && (
            <div className="flex justify-center mb-6 italic text-18">
              <h4 className="text-gray-500">Source:</h4>
              <a className="ml-1" href="#">
                VenueBoost
              </a>
            </div>
          )}
        </>
      )}

      {currentBlog.section_2_list && (
        <div className="my-4">
          <h2 className="text-18 md:text-24 leading-[32x] font-bold">
            {currentBlog.section_2_list.title ?? ""}
          </h2>
          <ul className="mt-4 list-disc ml-4 md:ml-10">
            {currentBlog.section_2_list.items &&
              currentBlog.section_2_list.items.map((item, index) => {
                return (
                  <li className="text-16 md:text-18 ">
                    {item.title && (
                      <span className="font-bold">{item.title}</span>
                    )}
                    <span className="ml-1 text-[#666666]">{item.content}</span>
                  </li>
                );
              })}
          </ul>
        </div>
      )}

      <h2
        className={
          currentBlog.section_1_h3
            ? "toc-heading text-20 md:text-28 leading-[32x] font-bold mt-7"
            : "text-20 md:text-28 leading-[32x] font-bold mt-7"
        }
      >
        {currentBlog.section_1_h3 ? currentBlog.section_1_h3 : ""}
      </h2>
      <h3
        className={`${
          currentBlog.section_1_point_2_title_before ? "toc-heading " : ""
        }text-18 md:text-24 leading-[32x] font-bold mt-7`}
      >
        {currentBlog.section_1_point_2_title_before
          ? currentBlog.section_1_point_2_title_before
          : ""}
      </h3>

      <p className="text-16 font-normal text-[#666666] md:text-18  leading-[26px] md:leading-[32px] mt-2">
        {currentBlog.section_1_point_2_content_before}
      </p>
      <h3
        className={`${
          currentBlog.section_1_point_3_title_before ? "toc-heading " : ""
        }text-18 md:text-24 leading-[32x] font-bold mt-7`}
      >
        {currentBlog.section_1_point_3_title_before
          ? currentBlog.section_1_point_3_title_before
          : ""}
      </h3>

      <p className="text-16 font-normal text-[#666666] md:text-18  leading-[26px] md:leading-[32px] mt-2">
        {currentBlog.section_1_point_3_content_before}
      </p>

      <h3
        className={`${
          currentBlog.section_1_point_3_title ? "toc-heading " : ""
        }text-18 md:text-24 leading-[32x] font-bold mt-7`}
      >
        {currentBlog.section_1_point_3_title
          ? currentBlog.section_1_point_3_title
          : ""}
      </h3>

      {currentBlog.detail_image_3 && (
        <>
          <img
            src={currentBlog.detail_image_3}
            className="article__img mt-4 mb-6"
            alt=""
          />
          {currentBlog.show_source && (
            <div className="flex justify-center mb-6 italic text-18">
              <h4 className="text-gray-500">Source:</h4>
              <a className="ml-1" href="#">
                VenueBoost
              </a>
            </div>
          )}
        </>
      )}
      <p className="text-16 font-normal text-[#666666] md:text-18  leading-[26px] md:leading-[32px] mt-2">
        {currentBlog.section_1_point_3_content}
      </p>

      <p className="text-16 font-normal text-[#666666] md:text-18  leading-[26px] md:leading-[32px] mt-2">
        {currentBlog.section_1_point_3_content_p_1}
      </p>

      <p className="text-16 font-normal text-[#666666] md:text-18  leading-[26px] md:leading-[32px] mt-2">
        {currentBlog.section_1_point_3_content_p_2}
      </p>
      <p className="text-16 font-normal text-[#666666] md:text-18  leading-[26px] md:leading-[32px] mt-2">
        {currentBlog.section_1_point_3_content_p_3}
      </p>

      <p className="text-16 font-normal text-[#666666] md:text-18  leading-[26px] md:leading-[32px] mt-2">
        {currentBlog.section_1_point_3_content_p_4}
      </p>

      {currentBlog.section_3_numbers_new_ul_list && (
        <div className="my-4">
          <h2 className="text-18 md:text-20 leading-[32x] font-bold">
            {currentBlog.section_3_numbers_new_ul_list.title ?? ""}
          </h2>
          <ul
            style={{ listStyle: "number" }}
            className="mt-4 list-number ml-4 md:ml-10"
          >
            {currentBlog.section_3_numbers_new_ul_list.items &&
              currentBlog.section_3_numbers_new_ul_list.items.map(
                (item, index) => {
                  return (
                    <li className="text-16 md:text-18 ">
                      {item.title && (
                        <span className="font-bold">{item.title}</span>
                      )}
                      <span className="text-[#666666]">{item.content}</span>
                    </li>
                  );
                }
              )}
          </ul>
        </div>
      )}

      {currentBlog.section_blog12_ul_new_1_list && (
        <div className="my-4">
          <h2 className="text-18 md:text-24 leading-[32x] font-bold">
            {currentBlog.section_blog12_ul_new_1_list.title ?? ""}
          </h2>
          <ul className="mt-4 list-disc ml-4 md:ml-10">
            {currentBlog.section_blog12_ul_new_1_list.items &&
              currentBlog.section_blog12_ul_new_1_list.items.map(
                (item, index) => {
                  return (
                    <li className="text-16 md:text-18 ">
                      <span className="font-bold">{item.title}</span>
                      <span className="ml-1 text-[#666666]">
                        {item.content}
                      </span>
                    </li>
                  );
                }
              )}
          </ul>
        </div>
      )}

      {currentBlog.section_blog14_ul_new_1_list && (
        <div className="my-4">
          <h2 className="text-18 md:text-24 leading-[32x] font-bold">
            {currentBlog.section_blog14_ul_new_1_list.title ?? ""}
          </h2>
          <ul className="mt-4">
            {currentBlog.section_blog14_ul_new_1_list.items &&
              currentBlog.section_blog14_ul_new_1_list.items.map(
                (item, index) => {
                  return (
                    <li className="text-16 md:text-18 ">
                      <span className="font-bold">{item.title}</span>
                      <span className="ml-1 text-[#666666]">
                        {item.content}
                      </span>
                    </li>
                  );
                }
              )}
          </ul>
        </div>
      )}

      {currentBlog.section_blog14_ul_new_2_list && (
        <div className="my-4">
          <h2 className="text-18 md:text-20 leading-[32x] font-bold">
            {currentBlog.section_blog14_ul_new_2_list.title ?? ""}
          </h2>
          <ul className="mt-4 list-disc ml-4 md:ml-10">
            {currentBlog.section_blog14_ul_new_2_list.items &&
              currentBlog.section_blog14_ul_new_2_list.items.map(
                (item, index) => {
                  return (
                    <li className="text-16 md:text-18 ">
                      {item.title && (
                        <span className="font-bold">{item.title}</span>
                      )}
                      <span className="text-[#666666]">{item.content}</span>
                    </li>
                  );
                }
              )}
          </ul>
        </div>
      )}

      {currentBlog.section_blog14_ul_new_3_list && (
        <div className="my-4">
          <h2 className="text-18 md:text-20 leading-[32x] font-bold">
            {currentBlog.section_blog14_ul_new_3_list.title ?? ""}
          </h2>
          <ul className="mt-4 list-disc ml-4 md:ml-10">
            {currentBlog.section_blog14_ul_new_3_list.items &&
              currentBlog.section_blog14_ul_new_3_list.items.map(
                (item, index) => {
                  return (
                    <li className="text-16 md:text-18 ">
                      {item.title && (
                        <span className="font-bold">{item.title}</span>
                      )}
                      <span className="text-[#666666]">{item.content}</span>
                    </li>
                  );
                }
              )}
          </ul>
        </div>
      )}

      {currentBlog.section_blog14_ul_new_4_list && (
        <div className="my-4">
          <h2 className="text-18 md:text-20 leading-[32x] font-bold">
            {currentBlog.section_blog14_ul_new_4_list.title ?? ""}
          </h2>
          <ul className="mt-4 list-disc ml-4 md:ml-10">
            {currentBlog.section_blog14_ul_new_4_list.items &&
              currentBlog.section_blog14_ul_new_4_list.items.map(
                (item, index) => {
                  return (
                    <li className="text-16 md:text-18 ">
                      {item.title && (
                        <span className="font-bold">{item.title}</span>
                      )}
                      <span className="text-[#666666]">{item.content}</span>
                    </li>
                  );
                }
              )}
          </ul>
        </div>
      )}

      {currentBlog.section_blog14_ul_new_5_list && (
        <div className="my-4">
          <h2 className="text-18 md:text-20 leading-[32x] font-bold">
            {currentBlog.section_blog14_ul_new_5_list.title ?? ""}
          </h2>
          <ul className="mt-4 list-disc ml-4 md:ml-10">
            {currentBlog.section_blog14_ul_new_5_list.items &&
              currentBlog.section_blog14_ul_new_5_list.items.map(
                (item, index) => {
                  return (
                    <li className="text-16 md:text-18 ">
                      {item.title && (
                        <span className="font-bold">{item.title}</span>
                      )}
                      <span className="text-[#666666]">{item.content}</span>
                    </li>
                  );
                }
              )}
          </ul>
        </div>
      )}

      <h2
        className={
          currentBlog.section_1_point_4_h1
            ? "toc-heading text-20 md:text-28 leading-[32x] font-bold mt-7"
            : "text-20 md:text-28 leading-[32x] font-bold mt-7"
        }
      >
        {currentBlog.section_1_point_4_h1
          ? currentBlog.section_1_point_4_h1
          : ""}
      </h2>
      <h3
        className={`${
          currentBlog.section_1_point_4_title ? "toc-heading " : ""
        }text-18 md:text-24 leading-[32x] font-bold mt-7`}
      >
        {currentBlog.section_1_point_4_title
          ? currentBlog.section_1_point_4_title
          : ""}
      </h3>

      {currentBlog.section_blog14_ul_new_6_list && (
        <div className="my-4">
          <h2 className="text-18 md:text-20 leading-[32x] font-bold">
            {currentBlog.section_blog14_ul_new_6_list.title ?? ""}
          </h2>
          <ul className="mt-4 list-disc ml-4 md:ml-10">
            {currentBlog.section_blog14_ul_new_6_list.items &&
              currentBlog.section_blog14_ul_new_6_list.items.map(
                (item, index) => {
                  return (
                    <li className="text-16 md:text-18 ">
                      {item.title && (
                        <span className="font-bold">{item.title}</span>
                      )}
                      <span className="text-[#666666]">{item.content}</span>
                    </li>
                  );
                }
              )}
          </ul>
        </div>
      )}
      {currentBlog.section_blog14_ul_new_7_list && (
        <div className="my-4">
          <h2 className="text-18 md:text-20 leading-[32x] font-bold">
            {currentBlog.section_blog14_ul_new_7_list.title ?? ""}
          </h2>
          <ul className="mt-4 list-disc ml-4 md:ml-10">
            {currentBlog.section_blog14_ul_new_7_list.items &&
              currentBlog.section_blog14_ul_new_7_list.items.map(
                (item, index) => {
                  return (
                    <li className="text-16 md:text-18 ">
                      {item.title && (
                        <span className="font-bold">{item.title}</span>
                      )}
                      <span className="text-[#666666]">{item.content}</span>
                    </li>
                  );
                }
              )}
          </ul>
        </div>
      )}
      {currentBlog.section_blog14_ul_new_8_list && (
        <div className="my-4">
          <h2 className="text-18 md:text-20 leading-[32x] font-bold">
            {currentBlog.section_blog14_ul_new_8_list.title ?? ""}
          </h2>
          <ul className="mt-4 list-disc ml-4 md:ml-10">
            {currentBlog.section_blog14_ul_new_8_list.items &&
              currentBlog.section_blog14_ul_new_8_list.items.map(
                (item, index) => {
                  return (
                    <li className="text-16 md:text-18 ">
                      {item.title && (
                        <span className="font-bold">{item.title}</span>
                      )}
                      <span className="text-[#666666]">{item.content}</span>
                    </li>
                  );
                }
              )}
          </ul>
        </div>
      )}
      {currentBlog.section_blog14_ul_new_9_list && (
        <div className="my-4">
          <h2 className="text-18 md:text-20 leading-[32x] font-bold">
            {currentBlog.section_blog14_ul_new_9_list.title ?? ""}
          </h2>
          <ul className="mt-4 list-disc ml-4 md:ml-10">
            {currentBlog.section_blog14_ul_new_9_list.items &&
              currentBlog.section_blog14_ul_new_9_list.items.map(
                (item, index) => {
                  return (
                    <li className="text-16 md:text-18 ">
                      {item.title && (
                        <span className="font-bold">{item.title}</span>
                      )}
                      <span className="text-[#666666]">{item.content}</span>
                    </li>
                  );
                }
              )}
          </ul>
        </div>
      )}
      {currentBlog.section_blog14_ul_new_10_list && (
        <div className="my-4">
          <h2 className="text-18 md:text-20 leading-[32x] font-bold">
            {currentBlog.section_blog14_ul_new_10_list.title ?? ""}
          </h2>
          <ul className="mt-4 list-disc ml-4 md:ml-10">
            {currentBlog.section_blog14_ul_new_10_list.items &&
              currentBlog.section_blog14_ul_new_10_list.items.map(
                (item, index) => {
                  return (
                    <li className="text-16 md:text-18 ">
                      {item.title && (
                        <span className="font-bold">{item.title}</span>
                      )}
                      <span className="text-[#666666]">{item.content}</span>
                    </li>
                  );
                }
              )}
          </ul>
        </div>
      )}
      {currentBlog.section_4_ul_list && (
        <h3
          className={`${
            currentBlog.section_1_point_4_title ? "toc-heading " : ""
          }text-18 md:text-24 leading-[32x] font-bold mt-7`}
        >
          {currentBlog.section_1_point_4_title
            ? currentBlog.section_1_point_4_title
            : ""}
        </h3>
      )}
      {currentBlog.section_4_ul_list && (
        <div className="my-4">
          <h2 className="text-18 md:text-24 leading-[32x] font-bold">
            {currentBlog.section_4_ul_list.title ?? ""}
          </h2>
          <ul className="mt-4 list-disc ml-4 md:ml-10">
            {currentBlog.section_4_ul_list.items &&
              currentBlog.section_4_ul_list.items.map((item, index) => {
                return (
                  <li className="text-16 md:text-18 ">
                    <span className="font-bold">{item.title}</span>
                    <span className="ml-1 text-[#666666]">{item.content}</span>
                  </li>
                );
              })}
          </ul>
        </div>
      )}

      {currentBlog.detail_image_2 && (
        <>
          <img
            src={currentBlog.detail_image_2}
            className="article__img mt-4 mb-6"
            alt=""
          />
          {currentBlog.show_source && (
            <div className="flex justify-center mb-6 italic text-18">
              <h4 className="text-gray-500">Source:</h4>
              <a className="ml-1" href="#">
                VenueBoost
              </a>
            </div>
          )}
        </>
      )}
      <p className="text-16 font-normal text-[#666666] md:text-18  leading-[26px] md:leading-[32px] mt-2">
        {currentBlog.section_1_point_4_content}
      </p>

      <p className="text-16 font-normal text-[#666666] md:text-18  leading-[26px] md:leading-[32px] mt-2">
        {currentBlog.section_1_point_4_content_p}
      </p>

      {currentBlog.section_4_ul_new_1_list && (
        <div className="my-4">
          <h2 className="text-18 md:text-24 leading-[32x] font-bold">
            {currentBlog.section_4_ul_new_1_list.title ?? ""}
          </h2>
          <ul className="mt-4 list-disc ml-4 md:ml-10">
            {currentBlog.section_4_ul_new_1_list.items &&
              currentBlog.section_4_ul_new_1_list.items.map((item, index) => {
                return (
                  <li className="text-16 md:text-18 ">
                    {item.title && (
                      <span className="font-bold">{item.title}</span>
                    )}
                    <span className="text-[#666666]">{item.content}</span>
                  </li>
                );
              })}
          </ul>
        </div>
      )}
      {currentBlog.section_4_ul_new_2_list && (
        <div className="my-4">
          <h2 className="text-18 md:text-24 leading-[32x] font-bold">
            {currentBlog.section_4_ul_new_2_list.title ?? ""}
          </h2>
          <ul className="mt-4 list-disc ml-4 md:ml-10">
            {currentBlog.section_4_ul_new_2_list.items &&
              currentBlog.section_4_ul_new_2_list.items.map((item, index) => {
                return (
                  <li className="text-16 md:text-18 ">
                    {item.title && (
                      <span className="font-bold">{item.title}</span>
                    )}
                    <span className="text-[#666666]">{item.content}</span>
                  </li>
                );
              })}
          </ul>
        </div>
      )}
      {currentBlog.section_4_ul_new_3_list && (
        <div className="my-4">
          <h2 className="text-18 md:text-24 leading-[32x] font-bold">
            {currentBlog.section_4_ul_new_3_list.title ?? ""}
          </h2>
          <ul className="mt-4 list-disc ml-4 md:ml-10">
            {currentBlog.section_4_ul_new_3_list.items &&
              currentBlog.section_4_ul_new_3_list.items.map((item, index) => {
                return (
                  <li className="text-16 md:text-18 ">
                    {item.title && (
                      <span className="font-bold">{item.title}</span>
                    )}
                    <span className="text-[#666666]">{item.content}</span>
                  </li>
                );
              })}
          </ul>
        </div>
      )}
      {currentBlog.section_4_ul_new_4_list && (
        <div className="my-4">
          <h2 className="text-18 md:text-24 leading-[32x] font-bold">
            {currentBlog.section_4_ul_new_4_list.title ?? ""}
          </h2>
          <ul className="mt-4 list-disc ml-4 md:ml-10">
            {currentBlog.section_4_ul_new_4_list.items &&
              currentBlog.section_4_ul_new_4_list.items.map((item, index) => {
                return (
                  <li className="text-16 md:text-18 ">
                    {item.title && (
                      <span className="font-bold">{item.title}</span>
                    )}
                    <span className="text-[#666666]">{item.content}</span>
                  </li>
                );
              })}
          </ul>
        </div>
      )}
      {currentBlog.section_4_ul_new_5_list && (
        <div className="my-4">
          <h2 className="text-18 md:text-24 leading-[32x] font-bold">
            {currentBlog.section_4_ul_new_5_list.title ?? ""}
          </h2>
          <ul className="mt-4 list-disc ml-4 md:ml-10">
            {currentBlog.section_4_ul_new_5_list.items &&
              currentBlog.section_4_ul_new_5_list.items.map((item, index) => {
                return (
                  <li className="text-16 md:text-18 ">
                    {item.title && (
                      <span className="font-bold">{item.title}</span>
                    )}
                    <span className="text-[#666666]">{item.content}</span>
                  </li>
                );
              })}
          </ul>
        </div>
      )}
      {currentBlog.section_4_ul_new_6_list && (
        <div className="my-4">
          <h2 className="text-18 md:text-24 leading-[32x] font-bold">
            {currentBlog.section_4_ul_new_6_list.title ?? ""}
          </h2>
          <ul className="mt-4 list-disc ml-4 md:ml-10">
            {currentBlog.section_4_ul_new_6_list.items &&
              currentBlog.section_4_ul_new_6_list.items.map((item, index) => {
                return (
                  <li className="text-16 md:text-18 ">
                    {item.title && (
                      <span className="font-bold">{item.title}</span>
                    )}
                    <span className="text-[#666666]">{item.content}</span>
                  </li>
                );
              })}
          </ul>
        </div>
      )}
      {currentBlog.section_4_ul_new_7_list && (
        <div className="my-4">
          <h2 className="text-18 md:text-24 leading-[32x] font-bold">
            {currentBlog.section_4_ul_new_7_list.title ?? ""}
          </h2>
          <ul className="mt-4 list-disc ml-4 md:ml-10">
            {currentBlog.section_4_ul_new_7_list.items &&
              currentBlog.section_4_ul_new_7_list.items.map((item, index) => {
                return (
                  <li className="text-16 md:text-18 ">
                    {item.title && (
                      <span className="font-bold">{item.title}</span>
                    )}
                    <span className="text-[#666666]">{item.content}</span>
                  </li>
                );
              })}
          </ul>
        </div>
      )}

      {currentBlog.section_4_numbers_new_ul_list && (
        <div className="my-4">
          <h2 className="text-18 md:text-20 leading-[32x] font-bold">
            {currentBlog.section_4_numbers_new_ul_list.title ?? ""}
          </h2>
          <ul
            style={{ listStyle: "number" }}
            className="mt-4 list-number ml-4 md:ml-10"
          >
            {currentBlog.section_4_numbers_new_ul_list.items &&
              currentBlog.section_4_numbers_new_ul_list.items.map(
                (item, index) => {
                  return (
                    <li className="text-16 md:text-18 ">
                      {item.title && (
                        <span className="font-bold">{item.title}</span>
                      )}
                      <span className="text-[#666666]">{item.content}</span>
                    </li>
                  );
                }
              )}
          </ul>
        </div>
      )}

      {currentBlog?.section_1_post_5_pre_content && (
        <p className="text-16 font-normal text-[#666666] md:text-18  leading-[26px] md:leading-[32px] mt-2">
          {currentBlog.section_1_post_5_pre_content}
        </p>
      )}

      <h3
        className={`${
          currentBlog.section_1_point_5_title ? "toc-heading " : ""
        }text-18 md:text-24 leading-[32x] font-bold mt-7`}
      >
        {currentBlog.section_1_point_5_title
          ? currentBlog.section_1_point_5_title
          : ""}
      </h3>
      <p className="text-16 font-normal text-[#666666] md:text-18  leading-[26px] md:leading-[32px] mt-2">
        {currentBlog.section_1_point_5_content}
      </p>

      {currentBlog.section_blog14_ul_new_11_list && (
        <div className="my-4">
          <h2 className="text-18 md:text-20 leading-[32x] font-bold">
            {currentBlog.section_blog14_ul_new_11_list.title ?? ""}
          </h2>
          <ul className="mt-4 list-disc ml-4 md:ml-10">
            {currentBlog.section_blog14_ul_new_11_list.items &&
              currentBlog.section_blog14_ul_new_11_list.items.map(
                (item, index) => {
                  return (
                    <li className="text-16 md:text-18 ">
                      {item.title && (
                        <span className="font-bold">{item.title}</span>
                      )}
                      <span className="text-[#666666]">{item.content}</span>
                    </li>
                  );
                }
              )}
          </ul>
        </div>
      )}

      {currentBlog.section_blog14_ul_new_12_list && (
        <div className="my-4">
          <h2 className="text-18 md:text-20 leading-[32x] font-bold">
            {currentBlog.section_blog14_ul_new_12_list.title ?? ""}
          </h2>
          <ul className="mt-4 list-disc ml-4 md:ml-10">
            {currentBlog.section_blog14_ul_new_12_list.items &&
              currentBlog.section_blog14_ul_new_12_list.items.map(
                (item, index) => {
                  return (
                    <li className="text-16 md:text-18 ">
                      {item.title && (
                        <span className="font-bold">{item.title}</span>
                      )}
                      <span className="text-[#666666]">{item.content}</span>
                    </li>
                  );
                }
              )}
          </ul>
        </div>
      )}

      {currentBlog.section_blog14_ul_new_13_list && (
        <div className="my-4">
          <h2 className="text-18 md:text-20 leading-[32x] font-bold">
            {currentBlog.section_blog14_ul_new_13_list.title ?? ""}
          </h2>
          <ul className="mt-4 list-disc ml-4 md:ml-10">
            {currentBlog.section_blog14_ul_new_13_list.items &&
              currentBlog.section_blog14_ul_new_13_list.items.map(
                (item, index) => {
                  return (
                    <li className="text-16 md:text-18 ">
                      {item.title && (
                        <span className="font-bold">{item.title}</span>
                      )}
                      <span className="text-[#666666]">{item.content}</span>
                    </li>
                  );
                }
              )}
          </ul>
        </div>
      )}

      {currentBlog.section_blog14_ul_new_14_list && (
        <div className="my-4">
          <h2 className="text-18 md:text-20 leading-[32x] font-bold">
            {currentBlog.section_blog14_ul_new_14_list.title ?? ""}
          </h2>
          <ul className="mt-4 list-disc ml-4 md:ml-10">
            {currentBlog.section_blog14_ul_new_14_list.items &&
              currentBlog.section_blog14_ul_new_14_list.items.map(
                (item, index) => {
                  return (
                    <li className="text-16 md:text-18 ">
                      {item.title && (
                        <span className="font-bold">{item.title}</span>
                      )}
                      <span className="text-[#666666]">{item.content}</span>
                    </li>
                  );
                }
              )}
          </ul>
        </div>
      )}

      {currentBlog.section_blog14_ul_new_15_list && (
        <div className="my-4">
          <h2 className="text-18 md:text-20 leading-[32x] font-bold">
            {currentBlog.section_blog14_ul_new_15_list.title ?? ""}
          </h2>
          <ul className="mt-4 list-disc ml-4 md:ml-10">
            {currentBlog.section_blog14_ul_new_15_list.items &&
              currentBlog.section_blog14_ul_new_15_list.items.map(
                (item, index) => {
                  return (
                    <li className="text-16 md:text-18 ">
                      {item.title && (
                        <span className="font-bold">{item.title}</span>
                      )}
                      <span className="text-[#666666]">{item.content}</span>
                    </li>
                  );
                }
              )}
          </ul>
        </div>
      )}

      <h2
        className={
          currentBlog.section_1_point_6_h1
            ? "toc-heading text-20 md:text-28 leading-[32x] font-bold mt-7"
            : "text-20 md:text-28 leading-[32x] font-bold mt-7"
        }
      >
        {currentBlog.section_1_point_6_h1
          ? currentBlog.section_1_point_6_h1
          : ""}
      </h2>

      <h3
        className={`${
          currentBlog.section_1_point_6_title ? "toc-heading " : ""
        }text-18 md:text-24 leading-[32x] font-bold mt-7`}
      >
        {currentBlog.section_1_point_6_title
          ? currentBlog.section_1_point_6_title
          : ""}
      </h3>
      <p className="text-16 font-normal text-[#666666] md:text-18  leading-[26px] md:leading-[32px] mt-2">
        {currentBlog.section_1_point_6_content}
      </p>

      <h2
        className={
          currentBlog.section_1_h7
            ? "toc-heading text-20 md:text-28 leading-[32x] font-bold mt-7"
            : "text-20 md:text-28 leading-[32x] font-bold mt-7"
        }
      >
        {currentBlog.section_1_h7 ? currentBlog.section_1_h7 : ""}
      </h2>
      <h3
        className={`${
          currentBlog.section_1_point_7_title ? "toc-heading " : ""
        }text-18 md:text-24 leading-[32x] font-bold mt-7`}
      >
        {currentBlog.section_1_point_7_title
          ? currentBlog.section_1_point_7_title
          : ""}
      </h3>
      {currentBlog.detail_image_3_new && (
        <>
          <img
            src={currentBlog.detail_image_3_new}
            className="article__img mt-4 mb-6"
            alt=""
          />
          {currentBlog.show_source && (
            <div className="flex justify-center mb-6 italic text-18">
              <h4 className="text-gray-500">Source:</h4>
              <a className="ml-1" href="#">
                VenueBoost
              </a>
            </div>
          )}
        </>
      )}
      <p className="text-16 font-normal text-[#666666] md:text-18  leading-[26px] md:leading-[32px] mt-2">
        {currentBlog.section_1_point_7_content}
      </p>

      <h3
        className={
          currentBlog.section_1_point_8_title
            ? "toc-heading text-18 md:text-24 leading-[32x] font-bold mt-7"
            : "text-18 md:text-24 leading-[32x] font-bold mt-7"
        }
      >
        {currentBlog.section_1_point_8_title
          ? currentBlog.section_1_point_8_title
          : ""}
      </h3>

      <p className="text-16 font-normal text-[#666666] md:text-18  leading-[26px] md:leading-[32px] mt-2">
        {currentBlog.section_1_point_8_content}
      </p>

      <h3
        className={
          currentBlog.section_1_point_9_title
            ? "toc-heading text-18 md:text-24 leading-[32x] font-bold mt-7"
            : "text-18 md:text-24 leading-[32x] font-bold mt-7"
        }
      >
        {currentBlog.section_1_point_9_title
          ? currentBlog.section_1_point_9_title
          : ""}
      </h3>

      <p className="text-16 font-normal text-[#666666] md:text-18  leading-[26px] md:leading-[32px] mt-2">
        {currentBlog.section_1_point_9_content}
      </p>

      {currentBlog.detail_image_9_new && (
        <>
          <img
            src={currentBlog.detail_image_9_new}
            className="article__img mt-4 mb-6"
            alt={currentBlog.id === 27 ? "Event Management Infographic" : ""}
          />
          {currentBlog.show_source && (
            <div className="flex justify-center mb-6 italic text-18">
              <h4 className="text-gray-500">Source:</h4>
              <a className="ml-1" href="#">
                VenueBoost
              </a>
            </div>
          )}
        </>
      )}

      <h2
        className={
          currentBlog.section_1_point_10_h1
            ? "toc-heading text-20 md:text-28 leading-[32x] font-bold mt-7"
            : "text-20 md:text-28 leading-[32x] font-bold mt-7"
        }
      >
        {currentBlog.section_1_point_10_h1
          ? currentBlog.section_1_point_10_h1
          : ""}
      </h2>

      <h3
        className={
          currentBlog.section_1_point_10_title
            ? "toc-heading text-18 md:text-24 leading-[32x] font-bold mt-7"
            : "text-18 md:text-24 leading-[32x] font-bold mt-7"
        }
      >
        {currentBlog.section_1_point_10_title
          ? currentBlog.section_1_point_10_title
          : ""}
      </h3>

      <p className="text-16 font-normal text-[#666666] md:text-18  leading-[26px] md:leading-[32px] mt-2">
        {currentBlog.section_1_point_10_content}
      </p>

      <h3
        className={
          currentBlog.section_1_point_11_title
            ? "toc-heading text-18 md:text-24 leading-[32x] font-bold mt-7"
            : "text-18 md:text-24 leading-[32x] font-bold mt-7"
        }
      >
        {currentBlog.section_1_point_11_title
          ? currentBlog.section_1_point_11_title
          : ""}
      </h3>

      <p className="text-16 font-normal text-[#666666] md:text-18  leading-[26px] md:leading-[32px] mt-2">
        {currentBlog.section_1_point_11_content}
      </p>

      <p className="text-16 font-normal text-[#666666] md:text-18  leading-[26px] md:leading-[32px] mt-2">
        {currentBlog.section_1_point_11_1_content}
      </p>

      <h2
        className={
          currentBlog.section_1_point_12_h1
            ? "toc-heading text-20 md:text-28 leading-[32x] font-bold mt-7"
            : "text-20 md:text-28 leading-[32x] font-bold mt-7"
        }
      >
        {currentBlog.section_1_point_12_h1
          ? currentBlog.section_1_point_12_h1
          : ""}
      </h2>

      <h3
        className={
          currentBlog.section_1_point_12_title
            ? "toc-heading text-18 md:text-24 leading-[32x] font-bold mt-7"
            : "text-18 md:text-24 leading-[32x] font-bold mt-7"
        }
      >
        {currentBlog.section_1_point_12_title
          ? currentBlog.section_1_point_12_title
          : ""}
      </h3>

      <p className="text-16 font-normal text-[#666666] md:text-18  leading-[26px] md:leading-[32px] mt-2">
        {currentBlog.section_1_point_12_content}
      </p>

      {currentBlog.section_12_new_ul_list && (
        <div className="my-4">
          <h2 className="text-18 md:text-20 leading-[32x] font-bold">
            {currentBlog.section_12_new_ul_list.title ?? ""}
          </h2>
          <ul className="mt-4 list-disc ml-4 md:ml-10">
            {currentBlog.section_12_new_ul_list.items &&
              currentBlog.section_12_new_ul_list.items.map((item, index) => {
                return (
                  <li className="text-16 md:text-18 ">
                    {item.title && (
                      <span className="font-bold">{item.title}</span>
                    )}
                    <span className="text-[#666666]">{item.content}</span>
                  </li>
                );
              })}
          </ul>
        </div>
      )}

      <h3
        className={
          currentBlog.section_1_point_13_title
            ? "toc-heading text-18 md:text-24 leading-[32x] font-bold mt-7"
            : "text-18 md:text-24 leading-[32x] font-bold mt-7"
        }
      >
        {currentBlog.section_1_point_13_title
          ? currentBlog.section_1_point_13_title
          : ""}
      </h3>

      <p className="text-16 font-normal text-[#666666] md:text-18  leading-[26px] md:leading-[32px] mt-2">
        {currentBlog.section_1_point_13_content}
      </p>

      <h2
        className={
          currentBlog.section_1_point_14_h1
            ? "toc-heading text-20 md:text-28 leading-[32x] font-bold mt-7"
            : "text-20 md:text-28 leading-[32x] font-bold mt-7"
        }
      >
        {currentBlog.section_1_point_14_h1
          ? currentBlog.section_1_point_14_h1
          : ""}
      </h2>

      <h3
        className={
          currentBlog.section_1_point_14_title
            ? "toc-heading text-18 md:text-24 leading-[32x] font-bold mt-7"
            : "text-18 md:text-24 leading-[32x] font-bold mt-7"
        }
      >
        {currentBlog.section_1_point_14_title
          ? currentBlog.section_1_point_14_title
          : ""}
      </h3>

      <p className="text-16 font-normal text-[#666666] md:text-18  leading-[26px] md:leading-[32px] mt-2">
        {currentBlog.section_1_point_14_content}
      </p>

      <h3
        className={
          currentBlog.section_1_point_15_title
            ? "toc-heading text-18 md:text-24 leading-[32x] font-bold mt-7"
            : "text-18 md:text-24 leading-[32x] font-bold mt-7"
        }
      >
        {currentBlog.section_1_point_15_title
          ? currentBlog.section_1_point_15_title
          : ""}
      </h3>

      <p className="text-16 font-normal text-[#666666] md:text-18  leading-[26px] md:leading-[32px] mt-2">
        {currentBlog.section_1_point_15_content}
      </p>

      <h2
        className={
          currentBlog.section_1_point_16_h1
            ? "toc-heading text-20 md:text-28 leading-[32x] font-bold mt-7"
            : "text-20 md:text-28 leading-[32x] font-bold mt-7"
        }
      >
        {currentBlog.section_1_point_16_h1
          ? currentBlog.section_1_point_16_h1
          : ""}
      </h2>

      <h3
        className={
          currentBlog.section_1_point_16_title
            ? "toc-heading text-18 md:text-24 leading-[32x] font-bold mt-7"
            : "text-18 md:text-24 leading-[32x] font-bold mt-7"
        }
      >
        {currentBlog.section_1_point_16_title
          ? currentBlog.section_1_point_16_title
          : ""}
      </h3>

      <p className="text-16 font-normal text-[#666666] md:text-18  leading-[26px] md:leading-[32px] mt-2">
        {currentBlog.section_1_point_16_content}
      </p>

      <h3
        className={
          currentBlog.section_1_point_17_title
            ? "toc-heading text-18 md:text-24 leading-[32x] font-bold mt-7"
            : "text-18 md:text-24 leading-[32x] font-bold mt-7"
        }
      >
        {currentBlog.section_1_point_17_title
          ? currentBlog.section_1_point_17_title
          : ""}
      </h3>

      <p className="text-16 font-normal text-[#666666] md:text-18  leading-[26px] md:leading-[32px] mt-2">
        {currentBlog.section_1_point_17_content}
      </p>

      <h2
        className={
          currentBlog.section_1_point_18_h1
            ? "toc-heading text-20 md:text-28 leading-[32x] font-bold mt-7"
            : "text-20 md:text-28 leading-[32x] font-bold mt-7"
        }
      >
        {currentBlog.section_1_point_18_h1
          ? currentBlog.section_1_point_18_h1
          : ""}
      </h2>
      <h3
        className={
          currentBlog.section_1_point_18_title
            ? "toc-heading text-18 md:text-24 leading-[32x] font-bold mt-7"
            : "text-18 md:text-24 leading-[32x] font-bold mt-7"
        }
      >
        {currentBlog.section_1_point_18_title
          ? currentBlog.section_1_point_18_title
          : ""}
      </h3>

      <p className="text-16 font-normal text-[#666666] md:text-18  leading-[26px] md:leading-[32px] mt-2">
        {currentBlog.section_1_point_18_content}
      </p>

      <h3
        className={
          currentBlog.section_1_point_19_title
            ? "toc-heading text-18 md:text-24 leading-[32x] font-bold mt-7"
            : "text-18 md:text-24 leading-[32x] font-bold mt-7"
        }
      >
        {currentBlog.section_1_point_19_title
          ? currentBlog.section_1_point_19_title
          : ""}
      </h3>

      <p className="text-16 font-normal text-[#666666] md:text-18  leading-[26px] md:leading-[32px] mt-2">
        {currentBlog.section_1_point_19_content}
      </p>

      <h2
        className={
          currentBlog.section_1_point_20_h1
            ? "toc-heading text-20 md:text-28 leading-[32x] font-bold mt-7"
            : "text-20 md:text-28 leading-[32x] font-bold mt-7"
        }
      >
        {currentBlog.section_1_point_20_h1
          ? currentBlog.section_1_point_20_h1
          : ""}
      </h2>
      <h3
        className={
          currentBlog.section_1_point_20_title
            ? "toc-heading text-18 md:text-24 leading-[32x] font-bold mt-7"
            : "text-18 md:text-24 leading-[32x] font-bold mt-7"
        }
      >
        {currentBlog.section_1_point_20_title
          ? currentBlog.section_1_point_20_title
          : ""}
      </h3>

      <p className="text-16 font-normal text-[#666666] md:text-18  leading-[26px] md:leading-[32px] mt-2">
        {currentBlog.section_1_point_20_content}
      </p>

      <h3
        className={
          currentBlog.section_1_point_21_title
            ? "toc-heading text-18 md:text-24 leading-[32x] font-bold mt-7"
            : "text-18 md:text-24 leading-[32x] font-bold mt-7"
        }
      >
        {currentBlog.section_1_point_21_title
          ? currentBlog.section_1_point_21_title
          : ""}
      </h3>

      <p className="text-16 font-normal text-[#666666] md:text-18  leading-[26px] md:leading-[32px] mt-2">
        {currentBlog.section_1_point_21_content}
      </p>

      <p className="text-16 font-normal text-[#666666] md:text-18  leading-[26px] md:leading-[32px] mt-6">
        {currentBlog.section_1_p ? currentBlog.section_1_p : ""}
      </p>

      {currentBlog.has_faq && (
        <div className="align-col-middle ">
          <div className="bg-[#F9F7F7]">
            <div className="max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 ">
              <div className="flex flex-col align-middle w-full">
                <h1 className="text-[#240b3b]  text-26 md:text-[32px] text-center font-extrabold leading-[40px] mt-5 md:mt-10 ">
                  FAQs About VenueBoost in Diverse Accommodations
                </h1>
                <Faq faqData={generalFaq} className="mt-4 mb-4 md:mb-16" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
