import React, { useState } from "react";
import "./index.css";
import FormTextInput from "../../components/FormTextInput";
import FormSelect from "../../components/FormSelect";
import { t } from "i18next";
import Dot1 from "../../assets/svgs/dot1-getstarted.svg";
import { CircularProgress } from "@mui/material";
import { isMobile } from "react-device-detect";
import { categoryList, industryList } from "./data";
import { NotificationManager } from "react-notifications";
import { trackOnboarding } from "../../redux/actions/onboarding";

const StepOne = ({
  data,
  handleChange,
  countries = [],
  states = [],
  cities = [],
  onboardingEmail,
  setStep,
  country,
  city,
  state,
}) => {
  const [loading, setLoading] = useState(false);

  const onSubmitBusinessStep = () => {
    if (!data["restaurant_name"]) {
      NotificationManager.warning(
        `Business name field is required`,
        "Warning",
        3000
      );
      return;
    }

    if (!data["restaurant_country"]) {
      NotificationManager.warning(
        `Business country field is required`,
        "Warning",
        3000
      );
      return;
    }

    if (!data["restaurant_city"]) {
      NotificationManager.warning(
        `Business city field is required`,
        "Warning",
        3000
      );
      return;
    }

    if (!data["restaurant_state"]) {
      NotificationManager.warning(
        `Business state field is required`,
        "Warning",
        3000
      );
      return;
    }
    if (!data["restaurant_zipcode"]) {
      NotificationManager.warning(
        `Business postal code field is required`,
        "Warning",
        3000
      );
      return;
    }

    if (!data["venue_industry"]) {
      NotificationManager.warning(
        `Business industry is required`,
        "Warning",
        3000
      );
      return;
    }

    if (!data["venue_type"]) {
      NotificationManager.warning(`Business type is required`, "Warning", 3000);
      return;
    }

    if (!data["years_in_business"]) {
      NotificationManager.warning(
        `Years in business field is required`,
        "Warning",
        3000
      );
      return;
    }

    data["email"] = onboardingEmail;
    data["step"] = "business_details";
    data["address_line1"] = "-";
    data["country"] = country;
    data["state"] = state;
    data["city"] = city;

    const payload = {
      ...data,
      state: data?.restaurant_state,
      city: data?.restaurant_city,
    };

    setLoading(true);
    trackOnboarding(payload)
      .then(() => {
        setStep(2);
        window.scrollTo(0, 0);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (typeof err.error === "string") {
          NotificationManager.error(err.error);
        } else {
          const errorKeys = Object.keys(err.error || {});
          if (errorKeys.length > 0) {
            const firstErrorKey = errorKeys[0];
            const firstErrorMessage = err.error[firstErrorKey][0];
            NotificationManager.error(
              firstErrorMessage || "Something went wrong!"
            );
          } else {
            NotificationManager.error(
              "Oops! Something went wrong. Please try again or contact us directly at contact@venueboost.io."
            );
          }
        }
        throw new Error("An error occurred while submitting the data");
      });
  };

  return (
    <div
      className="  bg-white rounded-[31px] p-[24px] md:p-[33px] relative"
      style={{ height: isMobile ? "851px" : "721px" }}
    >
      <div className="flex justify-start  items-center">
        <span className="text-[32px] md:text-32 font-extrabold leading-[50px] my-6]">
          Business Details
        </span>
      </div>
      <p className="text-18 font-semibold text-[#240b3b] my-4">
        Welcome to VenueBoost! To ensure we tailor our services to your unique
        business needs, please provide a few details about your establishment.
        This information will help us create a more personalized experience for
        you.
      </p>

      <div className="">
        <div className="grid grid-cols-2 gap-[14px]">
          <div>
            <FormTextInput
              name="restaurant_name"
              placeholder="Business Name"
              value={data?.restaurant_name || ""}
              onChange={handleChange}
              required={true}
            />
          </div>
          <div>
            <FormSelect
              name={"venue_industry"}
              placeholder="Industry"
              value={data?.venue_industry || industryList[0].value}
              dataList={industryList}
              onChanage={handleChange}
              required={true}
            />
          </div>
          <div className={"mt-5"}>
            <FormSelect
              name={"venue_type"}
              placeholder="Business Type"
              value={data?.venue_type || ""}
              dataList={
                data?.venue_industry
                  ? categoryList[data?.venue_industry]
                  : [{ value: "", title: "Please select" }]
              }
              onChanage={handleChange}
              required={true}
            />
          </div>
          <div className={"mt-5"}>
            <FormTextInput
              name="years_in_business"
              placeholder="Years in business"
              value={data?.years_in_business || ""}
              onChange={handleChange}
              required={true}
              tooltipText={
                "Help us understand your business's journey and experience."
              }
            />
          </div>
          <div className={"mt-5"}>
            <FormSelect
              name={"restaurant_country"}
              placeholder="Country"
              value={data?.restaurant_country}
              dataList={countries}
              onChanage={handleChange}
              required={true}
            />
          </div>
          <div className={"mt-5"}>
            <FormSelect
              name={"restaurant_state"}
              placeholder="State"
              value={data?.restaurant_state || ""}
              dataList={states}
              onChanage={handleChange}
              required={true}
            />
          </div>
          <div className={"mt-5"}>
            <FormSelect
              name={"restaurant_city"}
              placeholder="City"
              value={data?.restaurant_city || ""}
              dataList={cities}
              onChanage={handleChange}
              required={true}
            />
          </div>
          <div className={"mt-5"}>
            <FormTextInput
              name="restaurant_zipcode"
              placeholder="Zip/Postal Code"
              value={data?.restaurant_zipcode || ""}
              onChange={handleChange}
              required={true}
            />
          </div>
        </div>
      </div>

      <div className="w-full px-8 justify-between flex absolute bottom-[58px] left-0">
        <button
          // onClick={() => setStep(2)}
          className="text-[#707070] text-16 px-0 mt-5 leading-[16px]  h-[36px] font-semibold"
        ></button>
        <button
          onClick={onSubmitBusinessStep}
          className="bg-[#240b3b]  rounded-[63px] px-[26px] h-[36px] text-white text-16 mt-5 self-end leading-[16px] font-semibold"
        >
          {loading ? (
            <CircularProgress size={16} />
          ) : (
            t("who_we_serve.accommodation_industry.next")
          )}
        </button>
      </div>
      <div className="w-full align-middle absolute bottom-[30px] left-0">
        <img alt="" src={Dot1} />
      </div>
    </div>
  );
};

export default StepOne;
