import React from 'react';
import Intro from './intro';
import HowItWork from './howitwork';
import Faqs from './faqs';

const Refer = () => {
  return (
    <div className={'sub-container view-sales overflow-auto py-8 px-4 h-full flex flex-col gap-8 md:gap-20 xl:gap-28'}>
      <Intro />
      <HowItWork />
      <Faqs />
    </div>
  )
}

export default Refer;
