import { useState } from "react";
import { TextField } from "@mui/material";
import Content from "./Content";
import PropTypes from "prop-types";
import SearchIcon from "../../../assets/images/beachbarorder/left-icon.svg";
import "../index.css";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguage } from "../../../redux/actions/app";
import { beachBarOrderText } from "..";

const ProductCards = ({
  className = "",
  products = [],
  setProducts,
  isOpen,
  setIsOpen,
  setSelectedProduct,
  selectedProduct,
  currency,
}) => {
  const dispatch = useDispatch();
  const languageSelected = useSelector((state) => state.app.language) ?? "";

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTab, setSelectedTab] = useState("Drinks");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const tabs = ["Drinks", "Pizza", "Dessert", "Coffee"];
  const languages = ["en", "al"];

  const handleLanguageChange = (lang) => {
    setDropdownOpen(false);
    dispatch(changeLanguage(lang));
  };

  return (
    <div
      className={`flex-1 flex flex-col items-start gap-[16px] max-w-full text-left text-sm text-neutral-neutral-800 font-manrope ${className}`}
    >
      <div className="self-stretch flex flex-col items-start pt-0 px-0 pb-1 box-border gap-[12px] max-w-full text-center text-base text-neutral-neutral-600">
        <div className="self-stretch flex flex-row flex-wrap items-start gap-[7px]">
          <TextField
            className="[border:none] bg-[transparent] h-[42px] flex-1 font-manrope font-semibold text-sm text-lightslategray min-w-[183px]"
            placeholder={beachBarOrderText("searchOrder")}
            variant="outlined"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <img alt="" width="20px" height="20px" src={SearchIcon} />
              ),
            }}
            sx={{
              "& fieldset": { borderColor: "#eaeaef" },
              "& .MuiInputBase-root": {
                height: "42px",
                backgroundColor: "#fff",
                paddingLeft: "16px",
                borderRadius: "16px",
                fontSize: "14px",
              },
              "& .MuiInputBase-input": {
                paddingLeft: "10px",
                color: "#8e8ea9",
              },
            }}
          />
          <div className="relative bg-opacity-100 rounded-[12px]">
            <div
              className="w-[39px] bg-white shadow-[0px_0px_2px_rgba(12,_26,_75,_0.05),_0px_4px_20px_-2px_rgba(50,_50,_71,_0.02)] rounded-xl bg-bg flex py-[10.5px] px-[7px] box-border cursor-pointer"
              onClick={() => setDropdownOpen(!dropdownOpen)}
            >
              <div className="text-[#666687] font-bold cursor-pointer en-text [text-decoration:none] relative leading-[20px] text-[inherit] inline-block min-w-[23px]">
                {languageSelected.toUpperCase()}
              </div>
            </div>
            {dropdownOpen && (
              <div className="absolute top-full mt-1 w-[60px] bg-white shadow-lg rounded-xl z-10">
                {languages.map((lang) => (
                  <div
                    key={lang}
                    className="px-2 py-1 cursor-pointer hover:bg-white text-[#666687] font-bold"
                    onClick={() => handleLanguageChange(lang)}
                  >
                    {lang.toUpperCase()}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="self-stretch flex flex-row items-center justify-center max-w-full mt-2">
          <div
            className={`cursor-pointer flex-1 flex justify-center py-0 px-0 box-border gap-[8px] max-w-full`}
          >
            {tabs.map((category, index) => (
              <div
                onClick={() => setSelectedTab(category)}
                key={index}
                className={`flex-1 rounded-2xl ${
                  selectedTab === category
                    ? "active-product-tab text-white"
                    : "text-[#666687]"
                } flex flex-row items-center justify-center min-w-[49px]`}
              >
                <div
                  className={`flex-1 rounded-2xl flex flex-row items-center justify-center py-3 px-[13px]`}
                >
                  <div className="[text-decoration:none] relative leading-[20px] font-bold text-[inherit] inline-block min-w-[48px]">
                    {category}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col gap-4">
        {products
          .filter((item) =>
            item.name.toLowerCase().includes(searchQuery.toLowerCase())
          )
          .map((product, index, arr) => (
            <div
              className="product-content-bg w-full"
              onClick={() => setSelectedProduct({ ...product, index })}
              key={index}
              style={index === arr.length - 1 ? { marginBottom: "80px" } : {}}
            >
              <Content
                key={index}
                {...{
                  products,
                  product,
                  selectedProduct,
                  index,
                  setProducts,
                  isOpen,
                  setIsOpen,
                  setSelectedProduct,
                  currency,
                }}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

ProductCards.propTypes = {
  className: PropTypes.string,
};

export default ProductCards;
