import React, { Component, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguage } from "../../redux/actions/app";
import { Langs } from "../../constants/config";
import "./index.css";
import { useNavigate } from "react-router-dom";
// import { AiOutlineCheck } from "react-icons/ai";
// import { FiChevronDown, FiChevronUp } from "react-icons/fi";

const LangSelector = ({ title, closeNav }) => {
  const dispatch = useDispatch();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const language = useSelector((state) => state.app.language);

  const navigate = useNavigate();

  return (
    <>
      <a
        className="flex m-4 mx-6 w-full text-18 align-middle font-medium text-black1 transition flex gap-1 cursor-pointer hover:text-black1/75"
        onClick={() => setIsCollapsed(!isCollapsed)}
        // onMouseEnter={() => setIsCollapsed(true)}
        // onMouseLeave={() => setIsCollapsed(false)}
      >
        <span className="whitespace-nowrap align-start "> {title}</span>
      </a>
      <div className={`${isCollapsed ? "block" : "hidden"}`}>
        <ul className={`grid grid-cols-1  text-18`}>
          {Langs.map((item, i) => (
            <li key={i} className="px-10 py-3 text-md bg-white  ">
              <a
                className="flex"
                onClick={(e) => {
                  dispatch(changeLanguage(item.id));
                  closeNav(false);
                  navigate(0);
                }}
              >
                <div className="ml-1">
                  <span>{item.name}</span>
                </div>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default LangSelector;
