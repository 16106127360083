import React, { useState } from "react";

import speedImg from '../../assets/images/resources/speed.png'
import FormTextInput from "../../components/FormTextInput";
import Faq from "../../components/Faq";

const generalFaq = [
  {
    id: 1,
    status: false,
    title:
        "What is VenueBoost?",
    content:
        "VenueBoost is an all-in-one venue management platform designed to help businesses streamline their operations, optimize their resources, and enhance their customer experiences. It offers a suite of tools and features tailored to the needs of various venue types, including restaurants, hotels, retail stores, and entertainment centers",
  },
  {
    id: 2,
    status: false,
    title:
        "How can VenueBoost help my business?",
    content:
        "VenueBoost can help your business in several ways:\n",
    ulItems: [
      "Streamline your reservation and booking processes\n",
      "Optimize your inventory and resource management",
      "Automate your marketing and customer engagement efforts\n",
      "Provide valuable insights and analytics to guide your decision-making\n",
      "Enhance your customer experience and loyalty",
      "Increase your operational efficiency and productivity\n",
      "Boost your revenue and profitability\n",
    ],
  },
  {
    id: 3,
    status: false,
    title:
        "What types of venues does VenueBoost cater to?",
    content:
        "VenueBoost caters to a wide range of venue types, including:\n",
    ulItems: [
        "Restaurants and bars\n",
        "Hotels and accommodations\n",
        "Retail stores and boutiques",
        "Entertainment centers and event venues",
        "Fitness and wellness studios",
        "Beauty and spa salons",
        "And more!\n"
    ],
  },
  {
    id: 4,
    status: false,
    title:
        "Is VenueBoost easy to use?",
    content:
        "Yes, VenueBoost is designed with user-friendliness in mind. It offers an intuitive interface, step-by-step guides, and helpful resources to ensure that you can easily navigate and utilize its features. Our support team is also available to assist you with any questions or issues you may encounter.\n",
  }
];

const GuideDetails = () => {
  const [email, setEmail] = useState('')

  return (
    <div className='flex flex-col'>
      <div className="flex flex-col max-w-screen-main mx-auto gap-12 py-16 px-4 sm:px-6 md:px-8 lg:px-16 relative">
        <div className="grid grid-cols-1 md:grid-cols-7 gap-10">
          <div className="md:col-span-4 flex flex-col gap-5">
            <span className='bg-[#E1484833] text-sm text-[#E14848] font-semibold px-3 py-0.5 rounded-[4px] w-max'>
              Retail Management
            </span>
            <span className="text-30 md:text-48 font-extrabold md:leading-16 leading-12">
              Speed Networking: <br />
              A New Kind of Professional Event
            </span>
            <span className="text-lg leading-8">
              Much like speed dating, speed networking is a great way to meet potential new business partners, clients, and even employers. In this guide, we’ll walk you through exactly what speed networking is and why it’s useful for everyone from freelancers to corporations to social justice activists.
            </span>
          </div>
          <div className="md:col-span-3">
            <img src={speedImg} alt="img" className="rounded-[37px] w-full max-w-[500px]" />
          </div>
        </div>
        <hr />
        <div className="grid grid-cols-1 md:grid-cols-7 gap-10">
          <div className="md:col-span-4 flex flex-col gap-5">
            <span className="font-bold text-28 leading-[34px]">
              Menu Management
            </span>
            <span className="text-lg pr-5">
              With our enhanced Menu Management functionality, you can easily create and curate stunning menus that showcase your culinary creations in the most captivating way. From seasonal specials to daily offerings, you have complete control over your menu items, pricing, and descriptions. Say goodbye to manual updates and embrace the simplicity and efficiency of VenueBoost's streamlined menu management.<br />
              With VenueBoost's advanced analytics and reporting capabilities, you gain valuable insights into your menu's performance. Track the popularity of different dishes, identify top-sellers, and make data-driven decisions to optimize your menu offerings and maximize profitability.
            </span>
          </div>
          <div className="md:col-span-3">
            <div className="flex flex-col items-center rounded-[37px] border-[#0000001A] px-12 py-12 bg-[#F4F5F7]">
              <span className="font-bold text-40 text-center">
                Get the templates
              </span>
              <span className="text-base text-center">
                Enter your email enjoy all the services without any ads, and download the templates.
              </span>
              <FormTextInput
                name='email'
                placeholder='Email address'
                enableLabel={false}
                value={email}
                className='mt-2'
                onChange={(e) => setEmail(e.target.value)}
              />
              <a href="#">
                <button className='font-semibold text-[#ffffff] text-[16px] mt-5 px-[80px] py-[4px] bg-gradient-to-r from-[#240B3B] via-[#3D1E59] to-[#BC14FE]'>
                  Download now
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <span className="font-bold text-28">
            Menu Management
          </span>
          <span className="text-lg">
            With our enhanced Menu Management functionality, you can easily create and curate stunning menus that showcase your culinary creations in the most captivating way. From seasonal specials to daily offerings, you have complete control over your menu items, pricing, and descriptions. Say goodbye to manual updates and embrace the simplicity and efficiency of VenueBoost's streamlined menu management.
            With VenueBoost's advanced analytics and reporting capabilities, you gain valuable insights into your menu's performance. Track the popularity of different dishes, identify top-sellers, and make data-driven decisions to optimize your menu offerings and maximize profitability.
          </span>
        </div>
      </div>
      <div className="bg-[#F9F7F7]">
        <div className="flex flex-col max-w-screen-main mx-auto gap-8 py-16 px-4 sm:px-6 md:px-8 lg:px-16 relative">
          <span className="font-extrabold text-3xl text-center">
            Frequently Asked Questions
          </span>
          <div className="flex flex-col bg-white px-2 md:px-16 rounded-2xl">
            <Faq
              faqData={generalFaq}
              isFaq={true}
              className="bg-white mt-2 my-4 md:my-8"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuideDetails;
