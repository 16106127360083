import React, { useEffect, useState } from "react";

import "./index.css";
import IconUp from "../../assets/svgs/drop-up-black.svg";
import IconDown from "../../assets/svgs/drop-down-black.svg";

const Dropdown = ({
  title,
  content,
  ulItems,
  introItem,
  ulItems2,
  extraItem,
  status = false,
  onAction = () => {},
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    setIsCollapsed(status);
  }, [status]);

  return (
    <div
      className={`flex flex-col bg-white  my-2 px-6 py-[18px] hover:cursor-pointer  ${
        isCollapsed ? "dropdown-opened" : "dropdown-closed"
      }`}
      onClick={() => onAction()}
    >
      <div className={` flex flex-row justify-between  `}>
        <span className={`text-16 md:text-20 font-bold `}>{title}</span>
        <img alt="" src={isCollapsed ? IconUp : IconDown} />
      </div>
      {
        <div className={` ${isCollapsed ? "block" : "hidden"} mt-4`}>
          <span className="text-14 md:text-20 text-[#3C3C43] font-medium ">
            {content}
          </span>
          {ulItems && (
            <ul
              style={{ padding: "15px 15px 10px 20px" }}
              className="list-disc text-14 md:text-20 text-[#3C3C43] font-medium "
            >
              {ulItems.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          )}
          {introItem && (
            <span className="text-14 md:text-20 text-[#3C3C43] font-medium ">
              {introItem}
            </span>
          )}
          {ulItems2 && (
            <ul
              style={{ padding: "15px 15px 10px 20px" }}
              className="list-disc text-14 md:text-20 text-[#3C3C43] font-medium "
            >
              {ulItems2.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          )}
          {extraItem && (
            <span className="text-14 md:text-20 text-[#3C3C43] font-medium ">
              {extraItem}
            </span>
          )}
        </div>
      }
    </div>
  );
};

export default Dropdown;
