import { Navigate, Route } from "react-router-dom";

const whoWeServeRoutes = [
  <Route
    path="/who-we-serve/food"
    element={<Navigate replace to="/who-we-serve/food-and-beverage" />}
  />,
  <Route
    path="/who-we-serve/entertainment-venues/sports-complexes"
    element={
      <Navigate replace to="/who-we-serve/entertainment-venues/sports-arenas" />
    }
  />,
  <Route
    path="/who-we-serve/retail/boutiques-and-specialty-stores"
    element={
      <Navigate
        replace
        to="/who-we-serve/retail-management/fashion-boutiques"
      />
    }
  />,
  <Route
    path="/who-we-serve/food/juice-bars"
    element={
      <Navigate replace to="/who-we-serve/food-and-beverage/juice-bars" />
    }
  />,
  <Route
    path="/who-we-serve/food/food-stalls"
    element={
      <Navigate replace to="/who-we-serve/food-and-beverage/food-stalls" />
    }
  />,
  <Route
    path="/who-we-serve/retail/home-decor"
    element={
      <Navigate replace to="/who-we-serve/retail-management/home-decor" />
    }
  />,
  <Route
    path="/who-we-serve/food/ice-cream-parlors"
    element={
      <Navigate
        replace
        to="/who-we-serve/food-and-beverage/ice-cream-parlors"
      />
    }
  />,
  <Route
    path="/who-we-serve/food/cafes"
    element={<Navigate replace to="/who-we-serve/food-and-beverage/cafes" />}
  />,
  <Route
    path="/who-we-serve/food/food-halls"
    element={
      <Navigate replace to="/who-we-serve/food-and-beverage/food-halls" />
    }
  />,
  <Route
    path="/who-we-serve/food/bars"
    element={<Navigate replace to="/who-we-serve/food-and-beverage/bars" />}
  />,
  <Route
    path="/who-we-serve/retail"
    element={<Navigate replace to="/who-we-serve/retail-management" />}
  />,
  <Route
    path="/who-we-serve/food/catering-services"
    element={
      <Navigate
        replace
        to="/who-we-serve/food-and-beverage/catering-services"
      />
    }
  />,
  <Route
    path="/who-we-serve/sports-and-entertainment"
    element={<Navigate replace to="/who-we-serve/entertainment-venues" />}
  />,
  <Route
    path="/who-we-serve/entertainment-venues/concert-venues-and-theaters"
    element={
      <Navigate
        replace
        to="/who-we-serve/entertainment-venues/concert-halls-and-theaters"
      />
    }
  />,
  <Route
    path="/who-we-serve/food/buffets"
    element={<Navigate replace to="/who-we-serve/food-and-beverage/buffets" />}
  />,
  <Route
    path="/who-we-serve/food/pub-and-clubs"
    element={
      <Navigate replace to="/who-we-serve/food-and-beverage/pub-and-clubs" />
    }
  />,
  <Route
    path="/resources/pricing-plans"
    element={<Navigate replace to="/pricing-plans" />}
  />,
  <Route
    path="/who-we-serve/sports-and-entertainment/amusement-parks"
    element={
      <Navigate
        replace
        to="/who-we-serve/entertainment-venues/amusement-parks"
      />
    }
  />,
  <Route
    path="/who-we-serve/sports-and-entertainment/concert-venues-and-theaters"
    element={
      <Navigate
        replace
        to="/who-we-serve/entertainment-venues/concert-halls-and-theaters"
      />
    }
  />,
];

export default whoWeServeRoutes;
