import React from "react";
import { Seo } from "../../components/Seo";
import { t } from "i18next";

const Sitemap = () => {
  const sitemapSections = [
    {
      title: t("components.sitemap.who_we_serve"),
      links: [
        { text: "Food & Beverage", url: "/who-we-serve/food-and-beverage" },
        { text: "Entertainment Venues", url: "/who-we-serve/entertainment-venues" },
        { text: "Accommodation", url: "/who-we-serve/accommodation" },
        { text: "Retail Management", url: "/who-we-serve/retail-management" },
      ],
    },
    {
      title: "Company",
      links: [
        { text: "About Us", url: "/about" },
        { text: "Why VenueBoost", url: "/why-venueboost" },
        { text: "Case Studies", url: "/resources/tools-guides" },
        { text: "Contact Us", url: "/contact-us" },
      ],
    },
    {
      title: "Resources",
      links: [
        { text: "Features", url: "/features" },
        { text: "What\'s New", url: "/product-updates" },
        // { text: "Help Center", url: "https://support.venueboost.io/hc/en-us" },
        { text: "Affiliate Program", url: "/affiliates" },
      ],
    },
    // {
    //   title: "Links",
    //   links: [
    //     { text: "FAQ(s)", url: "https://support.venueboost.io/hc/en-us" },
    //     { text: "Community Forum", url: "https://support.venueboost.io/hc/en-us" },
    //     { text: "Get Started", url: "/get-started" },
    //     { text: "Status Page", url: "#" },
    //     { text: "Careers", url: "#" },
    //   ],
    // },
  ];
  const sitemapSections2 = [
    {
      title: "Food & Beverage",
      links: [
        { text: t("header.food.overview.label"), url: "/who-we-serve/food-and-beverage" },
        { text: t("header.food.restaurants.label"), url: "/who-we-serve/food-and-beverage/restaurants" },
        { text: t("header.food.bistros.label"), url: "/who-we-serve/food-and-beverage/bistros" },
        { text: t("header.food.cafes.label"), url: "/who-we-serve/food-and-beverage/cafes" },
        { text: t("header.food.bars.label"), url: "/who-we-serve/food-and-beverage/bars" },
        { text: t("header.food.pubclubs.label"), url: "/who-we-serve/food-and-beverage/pub-and-clubs" },
        { text: t("header.food.foodtrucks.label"), url: "/who-we-serve/food-and-beverage/food-trucks" },
        { text: t("header.food.finedining.label"), url: "/who-we-serve/food-and-beverage/fine-dining-restaurants" },
        { text: t("header.food.bakeries.label"), url: "/who-we-serve/food-and-beverage/bakeries" },
        { text: t("header.food.catering.label"), url: "/who-we-serve/food-and-beverage/catering-services" },
        { text: t("header.food.fastfood.label"), url: "/who-we-serve/food-and-beverage/fast-food-joints" },
        { text: t("header.food.icecream.label"), url: "/who-we-serve/food-and-beverage/ice-cream-parlors" },
        { text: t("header.food.foodstalls.label"), url: "/who-we-serve/food-and-beverage/food-stalls" },
        { text: t("header.food.buffets.label"), url: "/who-we-serve/food-and-beverage/buffets" },
        { text: t("header.food.popups.label"), url: "/who-we-serve/food-and-beverage/pop-up-restaurants" },
        { text: t("header.food.juicebars.label"), url: "/who-we-serve/food-and-beverage/juice-bars" },
        { text: t("header.food.foodhalls.label"), url: "/who-we-serve/food-and-beverage/food-halls" },
        { text: t("header.food.supperclubs.label"), url: "/who-we-serve/food-and-beverage/supper-clubs" },
        { text: "Pizzerias", url: "/who-we-serve/food-and-beverage/pizzeria" },
        { text: "Coffee Shops", url: "/who-we-serve/food-and-beverage/coffe-shop" },
        { text: "Delis", url: "/who-we-serve/food-and-beverage/delis" },
        { text: "Food Courts", url: "/who-we-serve/food-and-beverage/food-court" },
      ],
      background: 'bg-gradient-to-r from-[#17CE8C] to-[#CDF38E]'
    },
    {
      title: "Entertainment Venues",
      links: [
        { text: t("header.new_menu.sport_entertainment.content.label_1"), url: "/who-we-serve/entertainment-venues" },
        { text: t("header.new_menu.sport_entertainment.content.label_2"), url: "/who-we-serve/entertainment-venues/golf-courses" },
        { text: t("header.new_menu.sport_entertainment.content.label_3"), url: "/who-we-serve/entertainment-venues/bowling" },
        { text: t("header.new_menu.sport_entertainment.content.label_4"), url: "/who-we-serve/entertainment-venues/gyms-and-fitness-centers" },
        { text: t("header.new_menu.sport_entertainment.content.label_5"), url: "/who-we-serve/entertainment-venues/arcades-and-game-centers" },
        { text: t("header.new_menu.sport_entertainment.content.label_6"), url: "/who-we-serve/entertainment-venues/sports-arenas" },
        { text: t("header.new_menu.sport_entertainment.content.label_7"), url: "/who-we-serve/entertainment-venues/concert-halls-and-theaters" },
        { text: t("header.new_menu.sport_entertainment.content.label_8"), url: "/who-we-serve/entertainment-venues/amusement-parks" },
        { text: t("header.new_menu.sport_entertainment.content.label_9"), url: "/who-we-serve/entertainment-venues/ski-resorts" },
        { text: "Museums", url: "/who-we-serve/entertainment-venues/museum" },
        { text: "Cinemas", url: "/who-we-serve/entertainment-venues/cinema" }
      ], 
      background: 'bg-gradient-to-r from-[#F4D35E] to-[#F7EF8A]'
    },
    {
      title: "Accommodation",
      links: [
        { text: t("header.new_menu.accommodation.content.label_1"), url: "/who-we-serve/accommodation" },
        { text: t("header.new_menu.accommodation.content.label_2"), url: "/who-we-serve/accommodation/hotels" },
        { text: t("header.new_menu.accommodation.content.label_3"), url: "/who-we-serve/accommodation/cozy-retreats" },
        { text: t("header.new_menu.accommodation.content.label_4"), url: "/who-we-serve/accommodation/hostels" },
        { text: t("header.new_menu.accommodation.content.label_5"), url: "/who-we-serve/accommodation/hotel-chains" },
        { text: t("header.new_menu.accommodation.content.label_6"), url: "/who-we-serve/accommodation/vacation-rentals" },
        { text: t("header.new_menu.accommodation.content.label_7"), url: "/who-we-serve/accommodation/luxury-resorts" },
        { text: t("header.new_menu.accommodation.content.label_8"), url: "/who-we-serve/accommodation/family-resorts" },
        { text: t("header.new_menu.accommodation.content.label_9"), url: "/who-we-serve/accommodation/service-apartments" },
        { text: "Bed and Breakfasts", url: "/who-we-serve/accommodation/bed-and-breakfast" },
        { text: "Motels", url: "/who-we-serve/accommodation/motel" },
        { text: "Capsule Hotels", url: "/who-we-serve/accommodation/capsule-hotel" },
        { text: "Youth Hostels", url: "/who-we-serve/accommodation/youth-hostel" },
        { text: "Campgrounds", url: "/who-we-serve/accommodation/campground" },
        { text: "RV Parks", url: "/who-we-serve/accommodation/rv-park" }
      ], 
      background: 'bg-gradient-to-r from-[#6DDFE2] to-[#A9FFFF]'
    },
    {
      title: "Retail Management",
      links: [
        { text: t("header.new_menu.retail.content.label_1"), url: "/who-we-serve/retail-management" },
        { text: t("header.new_menu.retail.content.label_2"), url: "/who-we-serve/retail-management/sporting-goods-stores" },
        { text: t("header.new_menu.retail.content.label_3"), url: "/who-we-serve/retail-management/home-decor" },
        { text: t("header.new_menu.retail.content.label_4"), url: "/who-we-serve/retail-management/fashion-boutiques" },
        { text: t("header.new_menu.retail.content.label_5"), url: "/who-we-serve/retail-management/retail-chains" },
        { text: t("header.new_menu.retail.content.label_6"), url: "/who-we-serve/retail-management/boutiques-and-specialty-stores" },
        { text: t("header.new_menu.retail.content.label_7"), url: "/who-we-serve/retail-management/online-shops" },
        { text: t("header.new_menu.retail.content.label_8"), url: "/who-we-serve/retail-management/grocery-stores" },
        { text: t("header.new_menu.retail.content.label_9"), url: "/who-we-serve/retail-management/electronics-stores" },
        { text: t("header.new_menu.retail.content.label_10"), url: "/who-we-serve/retail-management/pharmacies" },
        { text: t("header.new_menu.retail.content.label_11"), url: "/who-we-serve/retail-management/auto-parts-stores" },
        { text: t("header.new_menu.retail.content.label_12"), url: "/who-we-serve/retail-management/liquor-stores" },
        { text: t("header.new_menu.retail.content.label_13"), url: "/who-we-serve/retail-management/pet-supply-stores" }
      ], 
      background: 'bg-gradient-to-r from-[#E14848] to-[#FFC6C6]'
    }

  ];

  return (
       <div className="flex flex-col align-col-middle view-terms">
          <Seo title={t("components.sitemap.seo_title")} />
          <div>
            <h1 className="text-[#312A37] text-[32px] md:text-72 leading-8 font-extrabold text-center py-10 md:py-20">
              {t("components.sitemap.title")}
            </h1>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12 md:mb-2 m-auto w-full justify-center md:justify-between">
            {sitemapSections.map((section, index) => (
                <div
                    key={index}
                    className="flex flex-col w-full md:w-auto my-0 md:mx-auto py-10 border-b-2 md:border-b-0"
                >
                  <nav className="">
                    <ul className="space-y-5 md:space-y-6 text-18 md:text-20 text-center md:text-start">
                      <p className="text-24 md:text-[28px] font-extrabold text-[#240B3B]">
                        {section.title}
                      </p>
                      {section.links.map((link, linkIndex) => (
                          <li key={linkIndex}>
                            <a className="text-black transition" href={link.url}>
                              {link.text}
                            </a>
                          </li>
                      ))}
                    </ul>
                  </nav>
                </div>
            ))}
          </div>
          <div className="flex flex-col md:flex-row gap-4 md:mb-2 md:m-auto w-full justify-center md:justify-between mb-8">
            {sitemapSections2.map((section, index) => (
              <div className={`${section.background} md:pl-[5px] my-10`}>
                <div
                    key={index}
                    className={`bg-white flex flex-col w-full h-full md:w-auto my-0 md:mx-auto pl-8 justify-between border-b-2 md:border-b-0`}
                >
                  <nav className="">
                    <ul className="space-y-5 md:space-y-6 text-18 md:text-20 text-center md:text-start">
                      <p className={`text-20 md:text-20 font-extrabold text-transparent bg-clip-text ${section.background}`}>
                        {section.title}
                      </p>
                      {section.links.map((link, linkIndex) => (
                          <li key={linkIndex}>
                            <a className="text-black transition text-20 font-normal" href={link.url}>
                              {link.text}
                            </a>
                          </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              </div>
            ))}
          </div>
        </div>
  );
};

export default Sitemap;
