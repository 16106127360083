import React from "react";
import { useNavigate } from "react-router-dom";
import Dot4 from "../../assets/svgs/dot4.svg";
import FooterButtons from "./footer-buttons";

const StepFour = ({ confirmed_success, setStep }) => {
  const navigate = useNavigate();
  return (
    <div
      className="  bg-white rounded-[31px] p-[24px] md:p-[33px] relative"
      style={{ height: "600px" }}
    >
      <div className="flex justify-start  items-center">
        <span className="text-[32px] md:text-32 font-extrabold leading-[50px] my-6]">
          Thank you!
        </span>
      </div>
      <p className="text-18 font-semibold text-[#240b3b] my-4">
        We're excited to have you as part of the VenueBoost platform. You will
        soon receive an email from us with details on how to log in to your
        VenueBoost Admin Panel.
      </p>

      <div className="flex justify-center">
        <img
          src={confirmed_success}
          alt="Confirmation Icon"
          className="w-[200px] h-[200px] py-4"
        />
      </div>

      <div className="w-full align-middle absolute bottom-[30px] left-0">
        <img alt="" src={Dot4} />
      </div>

      <FooterButtons
        {...{ setStep, step: 4 }}
        onSubmit={() => navigate("/")}
        next="Finish"
      />
    </div>
  );
};

export default StepFour;
