import { NotificationManager } from "react-notifications";
import { jwtDecode } from "jwt-decode";

export const transformArray = (data = []) =>
  data.map((state) => ({
    value: state.id,
    title: state.name,
    id: state.id,
  }));

export const handleError = (err) => {
  if (typeof err.error === "string") {
    NotificationManager.error(err?.error);
  } else {
    // Handle case where err.error is an object
    const errorKeys = Object.keys(err?.error || {});
    if (errorKeys.length > 0) {
      const firstErrorKey = errorKeys[0];
      const firstErrorMessage = err.error[firstErrorKey][0];
      NotificationManager.error(firstErrorMessage || "Something went wrong!");
    } else {
      NotificationManager.error(
        "Oops! Something went wrong. Please try again or contact us directly at contact@venueboost.io."
      );
    }
  }
  throw new Error("An error occurred while submitting the data");
};

export const tokenExpiry = () => {
  const token = localStorage.getItem("vbToken");
  if (token) {
    const decoded = jwtDecode(token);
    return decoded.exp;
  }
};
