import React, { useState } from "react";
import EntertainmentVenues from "../../components/Icons/EntertainmentVenues";
import Accommodation from "../../components/Icons/Accommodation";
import RetailManagement from "../../components/Icons/RetailManagement";
import FoodBeverage from "../../components/Icons/FoodBeverage";

import downIcon from '../../assets/svgs/down_black.svg';
import event1Img from "../../assets/images/blog/blog-26.webp";
import event6Img from "../../assets/images/blog/blog-25.webp";
import event3Img from '../../assets/images/blog/blog-10.webp';
import event4Img from '../../assets/images/blog/blog-29.webp';
import event5Img from '../../assets/images/blog/blog-18.webp';
import event2Img from '../../assets/images/blog/blog-22.webp';
import { Pagination } from "antd";
import DoubleCheck from "../../components/Icons/DoubleCheck";
import {Seo} from "../../components/Seo";

const MyIcon = props => {
  switch (props.category) {
    case "Entertainment Venues":
      return <EntertainmentVenues />;
    case "Accommodation":
      return <Accommodation />;
    case "Retail Management":
      return <RetailManagement />;
    case "Food & Beverage":
      return <FoodBeverage />;
    default:
      return null;
  }
};

const list = [
  {
    type_color: '#17CE8C',
    slug: "10-proven-strategies-boost-restaurant-sales",
    image: event1Img,
    category: 'FOOD & BEVERAGE',
    title: '10 Proven Strategies to Boost Your Restaurant\'s Online Sales',
  },
  {
    type_color: '#E14848',
    slug: "complete-checklist-launch-successful-ecommerce-store",
    image: event2Img,
    category: 'RETAIL MANAGEMENT',
    title: 'The Complete Checklist for Launching a Successful E-commerce Store',
  },
  {
    type_color: '#6DDFE2',
    slug: "essential-metrics-hotel-manager-track",
    image: event3Img,
    category: 'ACCOMMODATION',
    title: '5 Essential Metrics Every Hotel Manager Should Track',
  },
  {
    type_color: '#240b3b',
    slug: "loyalty-program-keeps-customers-coming-back",
    image: event6Img,
    category: 'ALL INDUSTRIES',
    title: 'How to Create a Loyalty Program That Keeps Customers Coming Back',
  },
  {
    type_color: '#E14848',
    slug: "ultimate-guide-streamlining-retail-operations",
    image: event5Img,
    category: 'RETAIL MANAGEMENT',
    title: 'The Ultimate Guide to Streamlining Your Retail Operations',
  },
  {
    type_color: '#F4D35E',
    slug: "strategies-maximize-revenue-delight-guests",
    image: event4Img,
    category: 'ENTERTAINMENT VENUES',
    title: 'Unlocking Success: 7 Strategies to Maximize Revenue and Delight Guests at Your Entertainment Venue',
  },
];


const Bpractices = () => {
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(window.innerWidth > 640 ? 15 : 5);

  const onPaginationChange = (page, pageSize) => {
    setMinValue((page - 1) * pageSize);
    setMaxValue(page * pageSize);
  };

  const currentUrl = "https://venueboost.io/resources/best-practices";
  const seoTitle =
      "Discover Powerful Industry Best Practices for VenueBoost | Boost Your Business";
  const seoDescription =
      "Explore the diverse range of best practices for VenueBoost and discover how it can boost your business. From streamlining operations to engaging customers, VenueBoost offers solutions tailored to your industry needs.";

  return (
      <div className="flex flex-col max-w-screen-main mx-auto gap-12 py-16 px-4 sm:px-6 md:px-8 lg:px-16 relative">
        <Seo
            title={seoTitle + " | VenueBoost"}
            description={seoDescription}
            url={currentUrl}
        />
      <div className="flex flex-col items-left">
        <span className="gradient-title font-extrabold text-48 leading-[60px]">
          Best Practices
        </span>
        <span className="text-lg font-semibold">
          Get the latest venue and event management insights, Momentus news, industry tips and more.
        </span>
      </div>
      <div className="flex flex-col gap-12">
        <div className='w-full flex-wrap flex items-center pb-4 justify-start gap-4'>
          <div>Filter</div>
          <div className='h-[40px] rounded-[60px] border border-[#240B3B]'>
            <div className='flex h-full  px-4  items-center justify-center bg-[rgba(36,11,59,.2)] rounded-[60px]'>
              <DoubleCheck/>
              <h1 className='text-14 font-semibold text-[#240B3B]'>All</h1>
            </div>
          </div>
          <div className='h-[40px] cursor-pointer  rounded-[60px] border border-[#F4D35E] p-[1px]'>
            <div className='flex h-full px-4 items-center justify-center bg-white rounded-[60px]'>
              <MyIcon category={"Entertainment Venues"}/>
              <h1 className='text-14 font-semibold text-[#373941] ml-1.5'>
                Entertainment Venues
              </h1>
            </div>
          </div>
          <div className='h-[40px] cursor-pointer  rounded-[60px] border border-[#6DDFE2] p-[1px]'>
            <div className='flex h-full px-4 items-center justify-center bg-white rounded-[60px]'>
              <MyIcon category={"Accommodation"}/>
              <h1 className='text-14 font-semibold text-[#373941] ml-2'>
                Accommodation
              </h1>
            </div>
          </div>
          <div className='h-[40px] cursor-pointer rounded-[60px] border border-[#E14848] p-[1px]'>
            <div className='flex h-full px-4 items-center justify-center bg-white rounded-[60px]'>
              <MyIcon category={"Retail Management"}/>
              <h1 className='text-14 font-semibold text-[#373941] ml-2'>
                Retail Management
              </h1>
            </div>
          </div>
          <div className='h-[40px] cursor-pointer  rounded-[60px] border border-[#17CE8C] p-[1px]'>
            <div className='flex h-full px-4 items-center justify-center bg-white rounded-[60px]'>
              <MyIcon category={"Food & Beverage"}/>
              <h1 className='text-14 font-semibold text-[#373941] ml-2'>
                Food & Beverage
              </h1>
            </div>
          </div>
        </div>
        <div className='grid grid-cols-1 mt-0 sm:grid-cols-3 gap-[38px]'>
          {list.length > 0 && list.slice(minValue, maxValue).map((item, index) => (
              <a href={`/resources/best-practices/${item.slug}`}>
                <div key={index} className="flex flex-col gap-3">
                  <img src={item.image} alt="img" className="w-full rounded-[20px]" />
                  <span className={`text-sm font-bold text-[${item.type_color}]`}>
                    {item.category}
                  </span>
                  <span className="text-primary1 text-xl font-bold">
                    {item.title}
                  </span>
                </div>
              </a>
          ))}
        </div>
        <div className='mx-auto w-full align-middle blog-pagination'>
          <Pagination
            total={list.length}
            defaultCurrent={1}
            pageSize={window.innerWidth > 640 ? 15 : 5}
            onChange={onPaginationChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Bpractices;
