import React, { useState } from "react";
import "./index.css";
import Dot3 from "../../assets/svgs/dot3-getstartd.svg";
import { CircularProgress, Tooltip } from "@mui/material";
import InformationIcon from "../../assets/svgs/info-icon.svg";
import TBDIcon from "../../assets/svgs/TBD.svg";
import FooterButtons from "./footer-buttons";
import { confirmOnboarding } from "../../redux/actions/onboarding";
import { handleError } from "./common-functions";

const StepThree = ({
  suggestedPlan,
  suggestedPlanFeatures = [],
  onBillingMethodChange,
  billingMethod,
  freePrice,
  freeSubtitle,
  venueType,
  optimizePrice,
  optimizeSubtitle,
  startingTrial1,
  startingTrial2,
  startingTrial3,
  onStartTrial,
  launchPrice,
  launchSubtitle,
  elevatePrice,
  elevateSubtitle,
  setStep,
  whichSelected,
  setWhichSelected,
  onboardingEmail,
}) => {
  const [loading, setLoading] = useState(false);

  const handleThirdStepSelect = () => {
    const finalData = {
      email: onboardingEmail,
      freemium: true,
      requested_custom: whichSelected === "Enterprise",
      mode: billingMethod,
    };
    setLoading(true);
    confirmOnboarding(finalData)
      .then(() => {
        setLoading(false);
        setStep(4);
      })
      .catch((err) => {
        setLoading(false);
        handleError(err);
      });
  };

  return (
    <div className="  bg-white rounded-[31px] p-[24px] md:p-[33px] relative">
      <div className="flex justify-start  items-center">
        <span className="text-[32px] md:text-32 font-extrabold leading-[50px] my-6]">
          Plan Recommendation
        </span>
      </div>
      <div>
        <p className="text-18 font-semibold text-[#240b3b] my-4 mb-6">
          Based on the information you've provided, we recommend the VenueBoost{" "}
          <b>{suggestedPlan}</b> Plan for your business.
        </p>
        <p className="text-18 font-semibold text-[#240b3b] my-4 mb-6">
          This plan includes:
          <ul
            style={{
              listStyleType: "disc",
              marginTop: "10px",
              marginLeft: "20px",
            }}
          >
            {suggestedPlanFeatures.map((feature, index) => (
              <li key={index} style={{ marginBottom: "10px" }}>
                {feature}
              </li>
            ))}
          </ul>
        </p>
        <p className="text-18 font-semibold text-[#240b3b] my-4 mb-6">
          If you'd like to discuss this recommendation or explore other options,
          we're here for a chat or call at your convenience.
        </p>
      </div>

      <div className="mt-8">
        <div
          className="flex flex-col md:flex-row align-middle mt-6 md:mt-8 mb-4 md:mb-8"
          style={{ justifyContent: "right" }}
        >
          <div className="flex h-full align-middle mt-4 md:mt-0">
            <div className="border-[1px] border-[#E3E3E3] flex flex-row rounded-[15px] align-middle ">
              <div
                onClick={() => onBillingMethodChange("monthly")}
                // onClick={() => setBillingMethod("monthly")}
                className={`cursor-pointer text-[13px] leading-6 md:text-14 font-bold px-4 md:px-7 py-3 ${
                  billingMethod === "monthly"
                    ? "text-white bg-primary1 rounded-[15px]"
                    : ""
                }`}
              >
                MONTHLY
              </div>
              <div
                onClick={() => onBillingMethodChange("annual")}
                // onClick={() => setBillingMethod("annual")}
                className={`cursor-pointer text-[13px] leading-6 md:text-14 font-bold px-4 md:px-7 py-3 ${
                  billingMethod === "annual"
                    ? "text-white bg-primary1 rounded-[15px]"
                    : ""
                } `}
              >
                YEARLY (SAVE 17%)
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            "grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-5 gap-3 xl:gap-3 mb-20"
          }
        >
          <div
            className={`rounded-[13px] border-[#E0E0E0] relative border-[1px] overflow-hidden `}
          >
            <div className="text-14 font-bold leading-6 text-white py-[6px] align-middle  w-full rounded-t-[16px]   bg-[#808080] border-[#808080] border-[1px] box-content translate-x-[-1px]">
              Welcome aboard!
            </div>
            <div className="bg-white h-full rounded-2xl w-full  flex flex-col ">
              <div
                className="bg-white  pt-10 px-6 flex flex-col justify-start border-[#E0E0E0]"
                style={{ paddingBottom: "55px" }}
              >
                <div className="h-full flex flex-col items-start">
                  <div
                    className={`flex w-full justify-left items-left leading-[8px] mb-2`}
                  >
                    <p className="text-20 text-[#333333] font-extrabold leading-[22px] mb-[0px]">
                      Discover
                    </p>
                  </div>
                  <p className="text-[48px] text-[#333333] font-bold  leading-[55px] align-middle flex mb-[16px]">
                    ${freePrice}
                    <span className="text-[15px] content-center ml-1">
                      {billingMethod === "monthly" ? "month" : "year"}
                    </span>
                  </p>
                  <p className="text-[#676879] text-14 font-medium leading-[22px] min-h-[88px] mb-[18px]">
                    {freeSubtitle}
                  </p>
                </div>
                <div className="flex">
                  <button
                    disabled={whichSelected === "Discover"}
                    className={` xl:text-14 w-full text-white py-[11px]  rounded-[40px] transition ease-in-out hover:scale-105`}
                    style={
                      whichSelected === "Discover"
                        ? {
                            background: "#80808014",
                            pointerEvents: "none",
                            color: "#240b3b",
                            border: "1PX SOLID #240b3b",
                          }
                        : {
                            color: "#240b3b",
                            border: "1PX SOLID #240b3b",
                          }
                    }
                    onClick={() => {
                      setWhichSelected("Discover");
                    }}
                  >
                    {whichSelected === "Discover" ? "Selected" : "Select"}
                  </button>
                </div>
              </div>
              <hr className="border-[#E0E0E0] border-[1px] mx-6" />
              <div className="mt-6 px-6 w-full">
                <p className="text-16 mb-6 font-bold">Discover includes:</p>
                {venueType === "food" && (
                  <>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Manage and streamline your booking process efficiently. Limit: 25/month for Discover; Unlimited for other plans."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-6"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Streamlined Reservations: Limited to 25 reservations per
                        month.
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Efficiently organize and update your venue's menu. This feature allows you to add and edit up to 25 menu items per month, ensuring your offerings are always current and appealing to your customers"
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-3"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Menu Management: Limited to 25 items per month.
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Keep track of your stock levels with real-time updates. Low stock alerts included."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-6"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Basic Inventory Management: Alerts for low stock levels,
                        limited to 25 items
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Gain insights into your business performance with detailed analytics. Advanced analytics available from Elevate plan."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-3"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Basic Analytics & Reporting: Essential insights for
                        small-scale operations.
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Manage guest profiles and preferences effectively. Up to 50 profiles for Discover; Unlimited for other plans."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-6"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Basic Guest Management: Manage up to 50 guest profiles
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Access tools for promotions, emails, and campaigns to boost your marketing efforts. Basic tools in Launch; Advanced from Elevate."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-6"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Basic Marketing Strategy: Limited to 1 campaign per
                        month.
                      </p>
                    </div>
                  </>
                )}

                {venueType === "sport_entertainment" && (
                  <>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Manage and streamline your booking process efficiently. Limit: 40/month for Discover; Unlimited for other plans."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-6"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Bookings Management: Limit of 40 bookings per month.
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Efficiently organize and update your venue's items. This feature allows you to add and edit up to 40 items per month."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-3"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Items Management: Limited to 40 items per month.
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Keep track of your stock levels with real-time updates. Limit: 40/month for Discover; Unlimited for other plans. "
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-6"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Inventory Management: Manage up to 40 inventory items.
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Gain insights into your business performance with detailed analytics. Advanced analytics available from Elevate plan."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-3"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Analytics & Reporting: Basic reports only.
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Manage customer profiles and preferences effectively. Up to 80 profiles for Discover; Unlimited for other plans."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-6"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Customer Management: Up to 80 customer profiles.
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Access tools for promotions, emails, and campaigns to boost your marketing efforts. Basic tools in Launch; Advanced from Elevate."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-3 "
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Marketing Tools: Limited to 1 campaign per month.
                      </p>
                    </div>
                  </>
                )}
                {venueType === "accommodation" && (
                  <>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Manage and streamline your booking process efficiently. Limit: 30/month for Discover; Unlimited for other plans."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-6"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Bookings Management: Limit of 30 bookings per month.
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Efficiently organize and manage your available units or rooms. Limit: 30/month for Discover; Unlimited for other plans."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-3"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Units/Rooms Management: Limited to 30 units/rooms per
                        month.
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Keep track of your stock levels with real-time updates. Limit: 30/month for Discover; Unlimited for other plans."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-6"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Inventory Management: Manage up to 30 inventory items
                        per month.
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Efficiently organize and update your venue's items. Limit: 30/month for Discover; Unlimited for other plans."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-6"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Items Management: Limited to 30 items per month.
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Manage guest profiles and preferences effectively. Up to 75 profiles for Discover; Unlimited for other plans."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-3"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Guest Management: Up to 75 guest profiles.
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Access tools for promotions, emails, and campaigns to boost your marketing efforts. Basic tools in Launch; Advanced from Elevate."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-3"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Marketing Tools: Limited to 1 campaign per month.
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Gain insights into your business performance with detailed analytics. Advanced analytics available from Elevate plan."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-3"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Analytics & Reporting: Basic reports only.
                      </p>
                    </div>
                  </>
                )}

                {venueType === "retail" && (
                  <>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Manage and streamline your orders efficiently. Limit: 50/month for Discover; Unlimited for other plans."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-6"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Order Management: Limit of 50 orders per month.
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Efficiently organize and update your store's items. This feature allows you to add and edit up to 50 products per month."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-3"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Products Management: Up to 50 products.
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Keep track of your stock levels with real-time updates. Low stock alerts included."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-6"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Inventory Management: Manage up to 50 inventory items.
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Gain insights into your business performance with basic analytics. Advanced analytics available from Elevate plan."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-6"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Dashboard & Revenue Analytics: Basic reports only.
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Access tools for promotions, emails, and campaigns to boost your marketing efforts. Basic tools in Launch; Advanced from Elevate."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-3"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Marketing Tools: Limited to 1 campaign per month.
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Manage your store’s essential operations with ease."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-1"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Basic Store Management
                      </p>
                    </div>
                  </>
                )}
                <hr
                  className="border-[#E0E0E0] border-[2px] mb-4 mx-0"
                  style={{ borderColor: "#3d1e59" }}
                />
                <p className="text-16 mb-2 font-bold">Customization:</p>

                <div className="flex flex-row-reverse justify-between gap-2">
                  <Tooltip arrow placement="top" title={""}>
                    <img
                      alt=""
                      src={InformationIcon}
                      className="w-5 h-5 mt-3"
                    />
                  </Tooltip>
                  <p className="text-16 mb-6">
                    Not available in the Discover plan.
                  </p>
                </div>
                <p className="text-16 mb-2 font-bold">Support:</p>
                <div className="flex flex-row-reverse justify-between gap-2">
                  <Tooltip arrow placement="top" title={""}>
                    <img
                      alt=""
                      src={InformationIcon}
                      className="w-5 h-5 mt-3"
                    />
                  </Tooltip>
                  <p className="text-16 mb-6" style={{ textWrap: "balance" }}>
                    Access to online help center and community forums.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`rounded-[13px] ${
              suggestedPlan === "Launch"
                ? "border-[#333333] border-[2px] relative box-border"
                : "border-[#E0E0E0] border-[1px] overflow-hidden"
            }`}
          >
            <div
              className={`text-14 font-bold leading-6 text-white py-[6px] align-middle  w-full rounded-t-[16px] ${
                suggestedPlan === "Launch"
                  ? "absolute top-[-16px] bg-[#240b3b] border-[#240b3b]"
                  : "bg-[#808080] border-[#808080]"
              }`}
            >
              Great Value
            </div>
            <div className="bg-white h-full rounded-2xl w-full  flex flex-col ">
              <div className="bg-white  pt-10 pb-6 px-6 flex flex-col justify-start border-[#E0E0E0]">
                <div className="h-full flex flex-col items-start">
                  <div
                    className={`flex w-full justify-left ${
                      suggestedPlan === "Launch"
                        ? "items-center leading-[22px]"
                        : "items-left leading-[8px]"
                    } mb-2`}
                  >
                    <p
                      className={`text-20 text-[#333333] font-extrabold ${
                        suggestedPlan === "Launch"
                          ? ""
                          : "leading-[22px] mb-[0px]"
                      }`}
                    >
                      Launch
                    </p>
                    {suggestedPlan === "Launch" && (
                      <div
                        className={"flex justify-center items-center "}
                        style={{ marginLeft: "10px" }}
                      >
                        <div
                          className={`
                          w-0 h-0 border-t-[8px] border-t-transparent
                          border-r-[8px] border-r-[#240b3b]
                          border-b-[8px] border-b-transparent`}
                        />
                        <div
                          className={`px-3 py-1 rounded-[8px] bg-[#240b3b] text-white text-[12px]`}
                        >
                          Suggested
                          <br />
                          for you
                        </div>
                      </div>
                    )}
                  </div>
                  <p className="text-[48px] text-[#333333] font-bold  leading-[55px] align-middle flex mb-[16px]">
                    ${launchPrice}
                    <span className="text-[15px] content-center ml-1">
                      {billingMethod === "monthly" ? "month" : "year"}
                    </span>
                  </p>
                  <p className="text-[#676879] text-14 font-medium leading-[22px] min-h-[88px] mb-[18px]">
                    {launchSubtitle}
                  </p>
                </div>
                <div className="flex">
                  <button
                    disabled={startingTrial1}
                    onClick={() => onStartTrial("Launch")}
                    className={` xl:text-14 w-full text-white py-[11px]  rounded-[40px] transition ease-in-out hover:scale-105`}
                    style={{
                      background: "white",
                      color: "rgb(46, 39, 60)",
                      border: "1PX SOLID rgb(46, 39, 60)",
                      marginTop: `${
                        suggestedPlan === "Launch" ? "5px" : "0px"
                      }`,
                    }}
                  >
                    {startingTrial1 ? (
                      <CircularProgress
                        sx={{
                          "& .MuiCircularProgress-svg": {
                            color: "rgb(46, 39, 60)",
                          },
                        }}
                        size={16}
                      />
                    ) : (
                      "Start your free trial"
                    )}
                  </button>
                </div>
                <Tooltip
                  arrow
                  placement="bottom"
                  title={
                    "Experience all premium features risk-free for 14 days. Available for Launch and Elevate plans."
                  }
                >
                  <div
                    style={{
                      color: "#3d1e59",
                      textAlign: "center",
                      marginTop: "10px",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                  >
                    14-DAYS FREE TRIAL
                  </div>
                </Tooltip>
              </div>
              <hr className="border-[#E0E0E0] border-[1px] mx-6" />
              <div className="mt-6 px-6 w-full">
                <p className="text-16 mb-6 font-bold">
                  All from Discover plus:
                </p>
                {venueType === "food" && (
                  <>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Manage and streamline your booking process efficiently. Limit: 25/month for Discover; Unlimited for other plans."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-3"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Unlimited Streamlined Reservations
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Keep track of your stock levels with real-time updates. Low stock alerts included."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-1"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Full Inventory Management
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Efficiently manage your team with integrated staff scheduling and time tracking. Available from Launch plan."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-1"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Staff Management
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Access tools for promotions, emails, and campaigns to boost your marketing efforts. Basic tools in Launch; Advanced from Elevate."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-3"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Enhanced Marketing Strategy tools
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Build customer loyalty with customizable retention programs. Available from Launch plan."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-3"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Loyalty and Retention Program
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Facilitate easy payments with secure, shareable payment links. Available from Launch plan"
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Payment Links
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Collect valuable feedback directly from your customers. Available from Launch plan."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-3"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Basic Guest Surveys and Ratings
                      </p>
                    </div>
                  </>
                )}

                {venueType === "sport_entertainment" && (
                  <>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Manage and streamline your booking process efficiently. Limit: 40/month for Discover; Unlimited for other plans."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-3"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Unlimited Bookings Management
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Keep track of your stock levels with real-time updates. Limit: 40/month for Discover; Unlimited for other plans."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-1"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Full Inventory Management
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Efficiently manage your team with integrated staff scheduling and time tracking. Available from Launch plan."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-1"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Staff Management
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Access tools for promotions, emails, and campaigns to boost your marketing efforts. Basic tools in Launch; Advanced from Elevate."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-2"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Enhanced Marketing Tools
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Build customer loyalty with customizable retention programs. Available from Launch plan."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-3"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Basic Loyalty and Retention Program
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Facilitate easy payments with secure, shareable payment links. Available from Launch plan"
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Basic Payment Links
                      </p>
                    </div>
                  </>
                )}
                {venueType === "accommodation" && (
                  <>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Manage and streamline your booking process efficiently. Limit: 30/month for Discover; Unlimited for other plans."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-3"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Unlimited Bookings Management
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Manage and streamline your Units/Rooms efficiently. Limit: 30/month for Discover; Unlimited for other plans."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-3"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Unlimited Units/Rooms Management
                      </p>
                    </div>

                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Keep track of your stock levels with real-time updates. Limit: 30/month for Discover; Unlimited for other plans."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-1"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Full Inventory Management
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Efficiently manage your team with integrated staff scheduling and time tracking. Available from Launch plan."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-1"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Staff Management
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Access tools for promotions, emails, and campaigns to boost your marketing efforts. Basic tools in Launch; Advanced from Elevate."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-2"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Enhanced Marketing Tools
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Build customer loyalty with customizable retention programs. Available from Launch plan."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-3"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Basic Loyalty and Retention Program
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Facilitate easy payments with secure, shareable payment links. Available from Launch plan"
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Payment Links
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Collect valuable feedback directly from your customers. Available from Launch plan."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-3"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Basic Guest Surveys and Ratings
                      </p>
                    </div>
                  </>
                )}

                {venueType === "retail" && (
                  <>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Manage and streamline your orders efficiently. Limit: 50/month for Discover; Unlimited for other plans."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-3"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Unlimited Order Management
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Keep track of your stock levels with real-time updates. Limit: 50/month for Discover; Unlimited for other plans."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-1"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Full Inventory Management
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Efficiently manage your team with integrated staff scheduling and time tracking. Available from Launch plan."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-1"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Staff Management
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Access tools for promotions, emails, and campaigns to boost your marketing efforts. Basic tools in Launch; Advanced from Elevate."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-2"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Enhanced Marketing Tools
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          "Build customer loyalty with customizable retention programs. Available from Launch plan."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-3"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Basic Loyalty and Retention Program
                      </p>
                    </div>
                  </>
                )}

                <hr
                  className="border-[#E0E0E0] border-[2px] mb-4 mx-0"
                  style={{ borderColor: "#3d1e59" }}
                />
                <p className="text-16 mb-2 font-bold">Customization:</p>

                <div className="flex flex-row-reverse justify-between gap-2">
                  <Tooltip
                    arrow
                    placement="top"
                    title={
                      "Customize the look and feel of your platform to match your brand. Contact us for pricing and options."
                    }
                  >
                    <img
                      alt=""
                      src={InformationIcon}
                      className="cursor-pointer w-5 h-5 mt-1"
                    />
                  </Tooltip>
                  <p className="text-16 mb-6">
                    {venueType === "food" &&
                      "UI Changes: $50 - $100 one-time fee."}
                    {venueType === "accommodation" &&
                      "$75 - $150 one-time fee."}
                    {venueType === "sport_entertainment" &&
                      "$85 - $170 one-time fee."}
                    {venueType === "retail" && "$80 - $160 one-time fee."}
                  </p>
                </div>
                <p className="text-16 mb-2 font-bold">
                  Communication Templates:
                </p>
                <div className="flex flex-row-reverse justify-between gap-2">
                  <Tooltip
                    arrow
                    placement="top"
                    title={
                      "Personalize communication templates for emails and notifications. Pricing varies by plan. Contact us for pricing and options."
                    }
                  >
                    <img
                      alt=""
                      src={InformationIcon}
                      className="cursor-pointer w-5 h-5"
                    />
                  </Tooltip>
                  <p className="text-16 mb-6" style={{ textWrap: "balance" }}>
                    {venueType === "food" && "$50 per template."}
                    {venueType === "accommodation" && "$60 one-time fee."}
                    {venueType === "sport_entertainment" && "$70 one-time fee."}
                    {venueType === "retail" && "$65 one-time fee."}
                  </p>
                </div>
                <p className="text-16 mb-2 font-bold">Support:</p>
                <div className="flex flex-row-reverse justify-between gap-2">
                  <Tooltip
                    arrow
                    placement="top"
                    title={
                      "Get assistance via email. Response time varies by plan: 48 hours for Launch, 24 hours for Elevate, 12 hours for Optimize."
                    }
                  >
                    <img
                      alt=""
                      src={InformationIcon}
                      className="cursor-pointer w-5 h-5 mt-3"
                    />
                  </Tooltip>
                  <p className="text-16 mb-6" style={{ textWrap: "balance" }}>
                    Email support with a 48-hour response time.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`rounded-[13px] ${
              suggestedPlan === "Elevate"
                ? "border-[#333333] border-[2px] relative box-border"
                : "border-[#E0E0E0] border-[1px] overflow-hidden"
            }`}
          >
            <div
              className={`text-14 font-bold leading-6 text-white py-[6px] align-middle  w-full rounded-t-[16px] ${
                suggestedPlan === "Elevate"
                  ? "absolute top-[-16px] bg-[#240b3b] border-[#240b3b]"
                  : "bg-[#808080] border-[#808080]"
              }`}
            >
              Just Right
            </div>
            <div className="bg-white h-full w-full rounded-[13px]  flex flex-col ">
              <div className="bg-white  pt-10 pb-6 px-6 flex flex-col justify-start">
                <div className="h-full flex flex-col items-start">
                  <div
                    className={`flex w-full justify-left ${
                      suggestedPlan === "Elevate"
                        ? "items-center leading-[22px]"
                        : "items-left leading-[8px]"
                    } mb-2`}
                  >
                    <p
                      className={`text-20 text-[#333333] font-extrabold ${
                        suggestedPlan === "Elevate"
                          ? ""
                          : "leading-[22px] mb-[0px]"
                      }`}
                    >
                      Elevate
                    </p>
                    {suggestedPlan === "Elevate" && (
                      <div
                        className={"flex justify-center items-center "}
                        style={{ marginLeft: "10px" }}
                      >
                        <div
                          className={`
                          w-0 h-0 border-t-[8px] border-t-transparent
                          border-r-[8px] border-r-[#240b3b]
                          border-b-[8px] border-b-transparent`}
                        />
                        <div
                          className={`px-3 py-1 rounded-[8px] bg-[#240b3b] text-white text-[12px]`}
                        >
                          Suggested
                          <br />
                          for you
                        </div>
                      </div>
                    )}
                  </div>
                  <p
                    className="text-[48px] text-[#333333] font-bold  leading-[55px] align-middle flex mb-[16px]"
                    style={{
                      marginBottom: `${
                        suggestedPlan === "Elevate" ? "20px" : "15px"
                      }`,
                    }}
                  >
                    ${elevatePrice}
                    <span className="text-[15px] content-center ml-1">
                      {billingMethod === "monthly" ? "month" : "year"}
                    </span>
                  </p>
                  <p className="text-[#676879] text-14 font-medium leading-[22px] min-h-[88px] mb-[18px]">
                    {elevateSubtitle}
                  </p>
                </div>
                <div className="flex">
                  <button
                    disabled={startingTrial2}
                    onClick={() => onStartTrial("Elevate")}
                    className={` xl:text-14 w-full text-white py-[11px]  rounded-[40px] transition ease-in-out hover:scale-105`}
                    style={{
                      background: "white",
                      color: "#240b3b",
                      border: "1PX SOLID #240b3b",
                    }}
                  >
                    {startingTrial2 ? (
                      <CircularProgress
                        sx={{
                          "& .MuiCircularProgress-svg": {
                            color: "rgb(46, 39, 60)",
                          },
                        }}
                        size={16}
                      />
                    ) : (
                      "Start your free trial"
                    )}
                  </button>
                </div>
                <Tooltip
                  arrow
                  placement="bottom"
                  title={
                    "Experience all premium features risk-free for 14 days. Available for Launch and Elevate plans."
                  }
                >
                  <div
                    style={{
                      color: "#3d1e59",
                      textAlign: "center",
                      marginTop: "10px",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                  >
                    14-DAYS FREE TRIAL
                  </div>
                </Tooltip>
              </div>
              <hr className="border-[#E0E0E0] border-[1px] mx-6" />

              <div className="mt-6 px-6 w-[100%]">
                <p className="text-16 mb-6 font-bold">All from Launch plus:</p>
                {venueType === "food" && (
                  <>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          "Automate your marketing activities for efficiency and consistency. Available from Elevate plan."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-1"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Marketing Automation
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          "Expand your reach through strategic affiliate partnerships. Available from Elevate plan."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-1"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Affiliate Partnerships
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          "Gain insights into your business performance with detailed analytics. Advanced analytics available from Elevate plan."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-3"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Advanced Analytics & Reporting
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          "Streamline your delivery process with comprehensive order management tools. Available from Elevate plan."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-3"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Delivery Orders management
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          "Unlock deeper insights into customer preferences and behaviors."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-3"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Advanced Guest Behavior Analytics
                      </p>
                    </div>
                  </>
                )}

                {venueType === "sport_entertainment" && (
                  <>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          "Automate your marketing activities for efficiency and consistency. Available from Elevate plan."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-1"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Marketing Automation
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          "Expand your reach through strategic affiliate partnerships. Available from Elevate plan."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-1"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Basic Affiliate Partnerships
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          "Gain insights into your business performance with detailed analytics. Advanced analytics available from Elevate plan."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-3"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Advanced Analytics & Reporting
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          "Elevate the customer experience with our Entertainment Membership Program. Offer exclusive benefits."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-3"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Entertainment Membership Program
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          "Gather valuable insights with Advanced Customer Surveys. Track ratings to continually improve cuest experiences and services."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-3"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Advanced Customer Surveys and Ratings
                      </p>
                    </div>
                  </>
                )}
                {venueType === "accommodation" && (
                  <>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          "Automate your marketing activities for efficiency and consistency. Available from Elevate plan."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-1"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Marketing Automation including Guest Journey support
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          "Expand your reach through strategic affiliate partnerships. Available from Elevate plan."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-1"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Basic Affiliate Partnerships
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          "Gain insights into your business performance with detailed analytics. Advanced analytics available from Elevate plan."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-3"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Advanced Analytics & Reporting
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          "With iCal integration, synchronize your bookings across various platforms to ensure seamless coordination and up-to-date scheduling."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-1"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        iCal Integration
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          "Unlock deeper insights into customer preferences and behaviors."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-3"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Advanced Guest Behavior Analytics
                      </p>
                    </div>
                  </>
                )}

                {venueType === "retail" && (
                  <>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          "Automate your marketing activities for efficiency and consistency. Available from Elevate plan."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-1"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Marketing Automation
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          "Expand your reach through strategic affiliate partnerships. Available from Elevate plan."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-1"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Basic Affiliate Partnerships
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          "Gain comprehensive insights with our Advanced Dashboard. Track key performance indicators and analyze revenue streams to make data-driven decisions and boost your business growth."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-3"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Advanced Dashboard & Revenue Analytics
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          "Build strong customer relationships with our Retail Customer Loyalty Program. Offer rewards and personalized experiences to encourage repeat business and foster brand loyalty."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-3"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Retail Customer Loyalty Program
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          "Gather valuable insights with Advanced Customer Surveys. Track ratings to continually improve cuest experiences and services."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-3"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Customer Surveys and Ratings
                      </p>
                    </div>

                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          "Ensure real-time inventory synchronization with 3rd party integrations like Woocommerce and Shopify. This feature helps in maintaining an up-to-date and consistent inventory across multiple sales channels, reducing the risk of stock discrepancies and improving customer satisfaction."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-1"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Consistent Inventory
                      </p>
                    </div>
                  </>
                )}

                <hr
                  className="border-[#E0E0E0] border-[2px] mb-4 mx-0"
                  style={{ borderColor: "#3d1e59" }}
                />
                <p className="text-16 mb-2 font-bold">Customization:</p>

                <div className="flex flex-row-reverse justify-between gap-2">
                  <Tooltip
                    arrow
                    placement="top"
                    title={
                      "Enhance your platform with additional features tailored to your needs. Contact us for pricing and options."
                    }
                  >
                    <img
                      alt=""
                      src={InformationIcon}
                      className="cursor-pointer w-5 h-5 mt-3"
                    />
                  </Tooltip>
                  <p className="text-16 mb-6">
                    {venueType === "food" &&
                      "Feature Additions: $100 - $300 per feature."}
                    {venueType === "accommodation" &&
                      "Feature Additions: $150 - $400 per feature."}
                    {venueType === "sport_entertainment" &&
                      "Feature Additions: $170 - $500 per feature."}
                    {venueType === "retail" &&
                      "Feature Additions: $160 - $450 per feature."}
                  </p>
                </div>
                <div className="flex flex-row-reverse justify-between gap-2">
                  <Tooltip
                    arrow
                    placement="top"
                    title={
                      "Customize the look and feel of your platform to match your brand. Contact us for pricing and options."
                    }
                  >
                    <img
                      alt=""
                      src={InformationIcon}
                      className="cursor-pointer w-5 h-5 mt-3"
                    />
                  </Tooltip>
                  <p className="text-16 mb-6">
                    {venueType === "food" &&
                      "Advanced UI Customization: $200 - $500 one-time fee."}
                    {venueType === "accommodation" &&
                      "Advanced UI Customization: $250 - $600 one-time fee."}
                    {venueType === "sport_entertainment" &&
                      "Advanced UI Customization: $270 - $700 one-time fee."}
                    {venueType === "retail" &&
                      "Advanced UI Customization: $260 - $650 one-time fee."}
                  </p>
                </div>
                <div className="flex flex-row-reverse justify-between gap-2">
                  <Tooltip arrow placement="top" title={""}>
                    <img
                      alt=""
                      src={InformationIcon}
                      className="w-5 h-5 mt-3"
                    />
                  </Tooltip>
                  <p className="text-16 mb-6">
                    10% discount on customization for Elevate subscribers.
                  </p>
                </div>
                <p className="text-16 mb-2 font-bold">Support:</p>
                <div className="flex flex-row-reverse justify-between gap-2">
                  <Tooltip
                    arrow
                    placement="top"
                    title={
                      "Get assistance via email. Response time varies by plan: 48 hours for Launch, 24 hours for Elevate, 12 hours for Optimize."
                    }
                  >
                    <img
                      alt=""
                      src={InformationIcon}
                      className="cursor-pointer w-5 h-5 mt-3"
                    />
                  </Tooltip>
                  <p className="text-16 mb-6" style={{ textWrap: "balance" }}>
                    Priority email support with a 24-hour response time.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`rounded-[13px] ${
              suggestedPlan === "Optimize"
                ? "border-[#333333] border-[2px] relative box-border"
                : "border-[#E0E0E0] border-[1px] overflow-hidden"
            }`}
          >
            <div
              className={`text-14 font-bold leading-6 text-white py-[6px] align-middle  w-full rounded-t-[16px] ${
                suggestedPlan === "Optimize"
                  ? "absolute top-[-16px] bg-[#240b3b] border-[#240b3b]"
                  : "bg-[#808080] border-[#808080]"
              }`}
            >
              Worth It
            </div>
            <div className="bg-white h-full w-full rounded-[13px]  flex flex-col ">
              <div className="bg-white  pt-10 pb-6 px-6 flex flex-col justify-start border-[#E0E0E0]">
                <div className="h-full flex flex-col items-start">
                  <div
                    className={`flex w-full justify-left ${
                      suggestedPlan === "Optimize"
                        ? "items-center leading-[22px]"
                        : "items-left leading-[8px]"
                    } mb-2`}
                  >
                    <p
                      className={`text-20 text-[#333333] font-extrabold ${
                        suggestedPlan === "Optimize"
                          ? ""
                          : "leading-[22px] mb-[0px]"
                      }`}
                    >
                      Optimize
                    </p>
                    {suggestedPlan === "Optimize" && (
                      <div
                        className={"flex justify-center items-center "}
                        style={{ marginLeft: "10px" }}
                      >
                        <div
                          className={`
                          w-0 h-0 border-t-[8px] border-t-transparent
                          border-r-[8px] border-r-[#240b3b]
                          border-b-[8px] border-b-transparent`}
                        />
                        <div
                          className={`px-3 py-1 rounded-[8px] bg-[#240b3b] text-white text-[12px]`}
                        >
                          Suggested
                          <br />
                          for you
                        </div>
                      </div>
                    )}
                  </div>
                  <p
                    className="text-[48px] text-[#333333] font-bold  leading-[55px] align-middle flex mb-[16px]"
                    style={{
                      marginTop: `${
                        suggestedPlan === "Optimize" ? "5px" : "0px"
                      }`,
                    }}
                  >
                    ${optimizePrice}
                    <span className="text-[15px] content-center ml-1">
                      {billingMethod === "monthly" ? "month" : "year"}
                    </span>
                  </p>
                  <p className="text-[#676879] text-14 font-medium leading-[22px] min-h-[88px] mb-[18px]">
                    {optimizeSubtitle}
                  </p>
                </div>
                <div className="flex">
                  <button
                    disabled={startingTrial3}
                    onClick={() => onStartTrial("Optimize")}
                    className={` xl:text-14 w-full text-white py-[11px]  rounded-[40px] transition ease-in-out hover:scale-105`}
                    style={{
                      background: "white",
                      color: "#240b3b",
                      border: "1PX SOLID #240b3b",
                    }}
                  >
                    {startingTrial3 ? (
                      <CircularProgress
                        sx={{
                          "& .MuiCircularProgress-svg": {
                            color: "rgb(46, 39, 60)",
                          },
                        }}
                        size={16}
                      />
                    ) : (
                      "Start your free trial"
                    )}
                  </button>
                </div>
                <Tooltip
                  arrow
                  placement="bottom"
                  title={
                    "Extended 30-day trial to fully explore advanced features."
                  }
                >
                  <div
                    style={{
                      color: "#3d1e59",
                      textAlign: "center",
                      marginTop: "10px",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                  >
                    30-DAYS FREE TRIAL
                  </div>
                </Tooltip>
              </div>
              <hr className="border-[#E0E0E0] border-[1px] mx-6" />

              <div className="mt-6 px-6 w-[100%]">
                <p className="text-16 mb-6 font-bold">
                  All from Elevate plus:{" "}
                  {/*Solutions include, but not limited to:*/}
                </p>
                {venueType === "food" && (
                  <>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          "Offer premium dining experiences with table bidding options. Exclusive to Optimize and Enterprise plans."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-5"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Premium Tables with Pricing / Bidding and Floorplan
                        Visibility
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          "Enhance customer retention with a tailored dining loyalty program."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-3"
                        />
                      </Tooltip>

                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Dining Guest Loyalty Program
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          "Personalize your VenueBoost interface with your brand's unique identity."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-1"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Customizable Brand Profile
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          "Accept payments securely in-person at your venue place."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-1"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        In-Person Payments
                      </p>
                    </div>
                  </>
                )}

                {venueType === "sport_entertainment" && (
                  <>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          "Unlock deep insights into customer preferences and behaviors with our Advanced Analytics. Make data-driven decisions to optimize your services."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-5"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Advanced Customer Behavior Analytics
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          "Elevate your marketing efforts with our suite of Advanced Marketing Tools. Enhance engagement and maximize your outreach potential."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-1"
                        />
                      </Tooltip>

                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Advanced Marketing Tools
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          "Personalize your VenueBoost interface with your brand's unique identity."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-1"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Customizable Brand Profile
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          "Accept payments securely in-person at your venue place."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-1"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        In-Person Payments
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          "Expand your business reach with our Advanced Affiliate Partnerships."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-3"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Advanced Affiliate Partnerships
                      </p>
                    </div>
                  </>
                )}
                {venueType === "accommodation" && (
                  <>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          "Enhance guest retention with our Loyalty Program. Reward frequent guests, personalize their experience, and encourage repeat bookings."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-3"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Accommodation Guest Loyalty Program
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          "Streamline your housekeeping operations with real-time updates, ensuring efficient room management and superior guest satisfaction."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-5"
                        />
                      </Tooltip>

                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Housekeeping Management with Real-Time Updates
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          "Expand your business reach with our Advanced Affiliate Partnerships."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-2"
                        />
                      </Tooltip>

                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Advanced Affiliate Partnerships
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          "Personalize your VenueBoost interface with your brand's unique identity."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-1"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Customizable Brand Profile
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          "Accept payments securely in-person at your venue place."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-1"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        In-Person Payments
                      </p>
                    </div>
                  </>
                )}

                {venueType === "retail" && (
                  <>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          "Streamline and optimize your order processing with Advanced Order Management."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-2"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Advanced Order Management
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          "Harness the power of data with Centralized Analytics for Multi-Brand Retailers."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-3"
                        />
                      </Tooltip>

                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Centralized Analytics for Multi-Brand Retailers
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          "Personalize your VenueBoost interface with your brand's unique identity."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-1"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Customizable Brand Profile
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          "Accept payments securely in-person at your venue place."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-1"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        In-Person Payments
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          "Delve into customer insights with Advanced Customer Behavior Analytics. Understand your customers' preferences and purchasing habits to tailor your offerings and marketing strategies."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-3"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Advanced Customer Behavior Analytics
                      </p>
                    </div>
                    <div className="flex flex-row-reverse justify-between gap-2">
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          "Manage your store’s essential operations with advanced features."
                        }
                      >
                        <img
                          alt=""
                          src={InformationIcon}
                          className="cursor-pointer w-5 h-5 mt-3"
                        />
                      </Tooltip>
                      <p
                        className="text-16 mb-6"
                        style={{ textWrap: "balance" }}
                      >
                        Advanced Store Management
                      </p>
                    </div>
                  </>
                )}
                <hr
                  className="border-[#E0E0E0] border-[2px] mb-4 mx-0"
                  style={{ borderColor: "#3d1e59" }}
                />
                <p className="text-16 mb-2 font-bold">Customization:</p>

                <div className="flex flex-row-reverse justify-between gap-2">
                  <Tooltip
                    arrow
                    placement="top"
                    title={
                      "Enhance your platform with additional features tailored to your needs. Contact us for pricing and options."
                    }
                  >
                    <img
                      alt=""
                      src={InformationIcon}
                      className="cursor-pointer w-5 h-5 mt-3"
                    />
                  </Tooltip>
                  <p className="text-16 mb-6">
                    {venueType === "food" &&
                      "Major Feature Integration: $500 - $2000 per feature."}
                    {venueType === "accommodation" &&
                      "Major Feature Integration: $600 - $2500 per feature."}
                    {venueType === "sport_entertainment" &&
                      "Major Feature Integration: $700 - $3000 per feature."}
                    {venueType === "retail" &&
                      "Major Feature Integration: $650 - $2700 per feature."}
                  </p>
                </div>
                <div className="flex flex-row-reverse justify-between gap-2">
                  <Tooltip arrow placement="top" title={""}>
                    <img
                      alt=""
                      src={InformationIcon}
                      className="w-5 h-5 mt-3"
                    />
                  </Tooltip>
                  <p className="text-16 mb-6">
                    15% discount on customization for Optimize subscribers.
                  </p>
                </div>

                <p className="text-16 mb-2 font-bold">Support:</p>
                <div className="flex flex-row-reverse justify-between gap-2">
                  <Tooltip
                    arrow
                    placement="top"
                    title={"Direct phone support for immediate assistance."}
                  >
                    <img
                      alt=""
                      src={InformationIcon}
                      className="cursor-pointer w-5 h-5 mt-3"
                    />
                  </Tooltip>
                  <p className="text-16 mb-6" style={{ textWrap: "balance" }}>
                    Phone and email support with a 12-hour response time.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`rounded-[13px] border-[#E0E0E0] border-[1px] overflow-hidden`}
          >
            <div className="text-14 font-bold leading-6 text-white py-[6px] align-middle  w-full rounded-t-[16px] bg-[#808080] border-[#808080] border-[1px] box-content translate-x-[-1px]">
              Built for you
            </div>
            <div className="bg-white h-full rounded-2xl w-full  flex flex-col ">
              <div className="bg-white pt-10 pb-6 px-6 flex flex-col justify-start border-[#E0E0E0]">
                <div className="h-full flex flex-col items-start">
                  <p className="text-20 text-[#333333] font-extrabold leading-[22px] mb-[6px]">
                    Enterprise
                  </p>
                  <img
                    alt=""
                    src={TBDIcon}
                    className="h-[55px] w-[55px]"
                    style={{
                      marginTop: "8px",
                      marginBottom: "8px",
                    }}
                  />
                  <p className="text-[#676879] text-14 font-medium leading-[22px] min-h-[88px] mb-[18px]">
                    Get a custom quote for tailored solutions for large-scale
                    operations, ensuring optimal performance.
                  </p>
                </div>
                <div className="flex">
                  <Tooltip
                    arrow
                    placement="top"
                    title={
                      "Once selected, our team will reach out to you directly to discuss and define the unique features and capabilities that best align with your business objectives. We'll work closely with you to craft a plan that precisely fits your venue's requirements, ensuring you get the most out of VenueBoost."
                    }
                  >
                    <button
                      disabled={whichSelected === "Enterprise"}
                      className={` xl:text-14 w-full text-white py-[11px]  rounded-[40px] transition ease-in-out hover:scale-105`}
                      style={
                        whichSelected === "Enterprise"
                          ? {
                              background: "#80808014",
                              pointerEvents: "none",
                              color: "#240b3b",
                              border: "1PX SOLID #240b3b",
                            }
                          : {
                              color: "#240b3b",
                              border: "1PX SOLID #240b3b",
                            }
                      }
                      onClick={() => {
                        setWhichSelected("Enterprise");
                      }}
                    >
                      {whichSelected === "Enterprise" ? "Selected" : "Select"}
                    </button>
                  </Tooltip>
                </div>
                <Tooltip
                  arrow
                  placement="bottom"
                  title={
                    "Extended 30-day trial to fully explore tailored solutions built for you."
                  }
                >
                  <div
                    style={{
                      color: "#3d1e59",
                      textAlign: "center",
                      marginTop: "10px",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                  >
                    30-DAYS FREE TRIAL
                  </div>
                </Tooltip>
              </div>
              <hr className="border-[#E0E0E0] border-[1px] mx-6" />

              <div className="mt-6 px-6 w-[100%]">
                <p className="text-16 mb-6 font-bold">
                  All from Optimize, plus:
                  {/*Solutions include, but not limited to:*/}
                </p>
                <div className="flex flex-row-reverse justify-between gap-2">
                  <Tooltip
                    arrow
                    placement="top"
                    title={
                      "Receive personalized service and support from a dedicated account manager. Exclusive to Enterprise plan"
                    }
                  >
                    <img
                      alt=""
                      src={InformationIcon}
                      className="cursor-pointer w-5 h-5 mt-4"
                    />
                  </Tooltip>
                  <p className="text-16 mb-6">Dedicated account manager. </p>
                </div>
                <div className="flex flex-row-reverse justify-between gap-2">
                  <img alt="" src={InformationIcon} className="w-5 h-5 mt-8" />
                  <p className="text-16 mb-6">
                    Custom integrations and feature development.
                  </p>
                </div>
                <div className="flex flex-row-reverse justify-between gap-2">
                  <img alt="" src={InformationIcon} className="w-5 h-5 mt-3" />
                  <p className="text-16 mb-6">Premium support and training.</p>
                </div>

                <hr
                  className="border-[#E0E0E0] border-[2px] mb-4 mx-0"
                  style={{ borderColor: "#3d1e59" }}
                />
                <p className="text-16 mb-2 font-bold">Price:</p>
                <div className="flex flex-row-reverse justify-between gap-2">
                  <Tooltip arrow placement="top" title={""}>
                    <img
                      alt=""
                      src={InformationIcon}
                      className="w-5 h-5 mt-5"
                    />
                  </Tooltip>
                  <p className="text-16 mb-6" style={{ textWrap: "balance" }}>
                    {venueType === "accommodation" &&
                      "Custom pricing (starting around $599 per month)"}
                    {venueType === "retail" &&
                      "Custom pricing (starting around $649 per month)"}
                    {venueType === "sport_entertainment" &&
                      "Custom pricing (starting around $699 per month)"}
                    {venueType === "food" &&
                      "Custom pricing (starting around $499 per month)"}
                  </p>
                </div>

                <p className="text-16 mb-2 font-bold">Customization:</p>

                <div className="flex flex-row-reverse justify-between gap-2">
                  <Tooltip arrow placement="top" title={""}>
                    <img
                      alt=""
                      src={InformationIcon}
                      className="w-5 h-5 mt-7"
                    />
                  </Tooltip>
                  <p className="text-16 mb-6">
                    Custom Feature Development: Custom pricing based on
                    requirements.
                  </p>
                </div>
                <div className="flex flex-row-reverse justify-between gap-2">
                  <Tooltip arrow placement="top" title={""}>
                    <img
                      alt=""
                      src={InformationIcon}
                      className="w-5 h-5 mt-5"
                    />
                  </Tooltip>
                  <p className="text-16 mb-6">
                    20% discount on all customization for Enterprise
                    subscribers.
                  </p>
                </div>
                <p className="text-16 mb-2 font-bold">Support:</p>
                <div className="flex flex-row-reverse justify-between gap-2">
                  <Tooltip arrow placement="top" title={""}>
                    <img
                      alt=""
                      src={InformationIcon}
                      className="w-5 h-5 mt-3"
                    />
                  </Tooltip>
                  <p className="text-16 mb-6" style={{ textWrap: "balance" }}>
                    24/7 priority phone and email support with immediate
                    response.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterButtons
        {...{
          loading,
          onSubmit: handleThirdStepSelect,
          setStep,
          next: "Proceed with " + whichSelected,
          step: 3,
        }}
      />
      <div className="w-full align-middle absolute bottom-[30px] left-0">
        <img alt="" src={Dot3} />
      </div>
    </div>
  );
};

export default StepThree;
