import React from 'react';
import SwitchIcon1 from "../../../assets/svgs/switch1.svg";
import {t} from "i18next";
import SwitchIcon2 from "../../../assets/svgs/switch2.svg";
import SwitchIcon3 from "../../../assets/svgs/switch3.svg";
import SwitchIcon4 from "../../../assets/svgs/switch4.svg";
import {motion} from "framer-motion";
import {useNavigate} from "react-router-dom";

export default function HowItWork() {
    const navigate = useNavigate();
    const FeatureBlock = ({ iconSrc, title, description }) => (
        <motion.div
            className="flex-1"
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ amount: 0.8, once: true }}
        >
            <motion.div
                className="h-full flex"
                variants={{
                    offscreen: { opacity: 0, y: 100 },
                    onscreen: {
                        y: 0,
                        opacity: 1,
                        transition: {
                            type: "spring",
                            bounce: 0,
                            duration: 1,
                        },
                    },
                }}
            >
                <div className="flex flex-col items-center  border-[1px] rounded-[13px] border-[#E0E0E0] bg-white p-11 md:p-6">
                    <img src={iconSrc} className=" w-[50px] h-[50px] mb-8 mt-5" />
                    <p className="text-[#333333] text-18 md:text-22 font-bold text-center mb-5">
                        {title}
                    </p>
                    <p className="text-[#666666] font-normal text-center text-16">
                        {description}
                    </p>
                </div>
            </motion.div>
        </motion.div>
    );

    const navigateToDemoUrl = (url) => {
        navigate(url);
    };
  return (
      <div className='flex flex-col gap-4 xl:gap-10'>
        <div className='flex flex-col p-5 xl:p-12 border-2 border-primary1 rounded-xl'>
        <span className='text-xl xl:text-26 font-black'>
          How it Works
        </span>
          <div className='grid grid-cols-1 xl:grid-cols-3 mt-5 xl:mt-12 gap-4'>
            <div className='flex flex-col gap-2 border-r-0 border-b-2 xl:border-r-2 xl:border-b-0 border-primary1 pr-0 pb-4 xl:pb-0 xl:pr-4'>
              <div className='flex flex-row justify-between'>
                <div className='flex flex-row xl:flex-col text-lg font-bold'>
                  <span>Initiate Your VenueBoost Adventure</span>
                </div>
                <span className='text-lg font-black'>01.</span>
              </div>
              <span className='text-base'>
              Click on "Get Started" using the referral link provided to you.
                  Begin your VenueBoost journey with a simple and intuitive registration process.
            </span>
            </div>
            <div className='flex flex-col gap-2 border-r-0 border-b-2 xl:border-r-2 xl:border-b-0 border-primary1 pr-0 pb-4 xl:pb-0 xl:pr-4'>
              <div className='flex flex-row justify-between'>
                <div className='flex flex-row xl:flex-col text-lg font-bold'>
                  <span>Provide Your Specific Venue Details</span>
                </div>
                <span className='text-lg font-black'>02.</span>
              </div>
              <span className='text-base'>
              In the "Get Started" form, accurately describe your venue. Provide key details about your industry and specific requirements that VenueBoost can <fulfill className=""></fulfill>
            </span>
            </div>
            <div className='flex flex-col gap-2'>
              <div className='flex flex-row justify-between'>
                <div className='flex flex-row xl:flex-col text-lg font-bold'>
                  <span>Submit and Embark on a New Chapter</span>
                </div>
                <span className='text-lg font-black'>03.</span>
              </div>
              <span className='text-base'>
              Once you've completed the form, submit it to us. We'll promptly review your details. Expect a follow-up with comprehensive registration and onboarding steps, making your transition to VenueBoost smooth and rewarding.
              </span>
            </div>
          </div>
        </div>
        <div className='mt-3 mb-3 flex justify-center' onClick={() => navigateToDemoUrl("/get-started")}>
            <span className='bg-primary1 text-white rounded-lg px-9 py-4 text-sm font-bold m-auto'>
              Begin Your Journey with Us
            </span>
        </div>
        <p className="text-14 md:text-20 leading-5 md:leading-[27px] font-medium text-center text-[#666A73] md:w-full">
            We prioritize transparency in our referral program. When you join through a referral, we share a clear summary of your account setup and activities with your referrer. This approach guarantees that rewards are fairly and accurately allocated. Our system is designed to ensure that those who refer new members to VenueBoost are recognized and rewarded appropriately. We believe in honoring the efforts of our community members.

        </p>
          <div className="mt-8 md:mt-20 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-5 md:gap-[76px] mb-4 md:mb-8">

              {/* First Block */}
              <FeatureBlock
                  iconSrc={SwitchIcon1}
                  title="Effortless Management "
                  description="Experience the simplicity of VenueBoost's user-friendly platform. Designed for easy navigation, it minimizes the need for extensive staff training, saving valuable time."
              />

              {/* Second Block */}
              <FeatureBlock
                  iconSrc={SwitchIcon2}
                  title="Seamless Transition"
                  description="Our dedicated support team guarantees a smooth onboarding process. Embrace VenueBoost's advanced features rapidly and move beyond outdated systems with ease."
              />

              {/* Third Block */}
              <FeatureBlock
                  iconSrc={SwitchIcon3}
                  title="Omni-Channel Excellence"
                  description="VenueBoost is intricately crafted for seamless integration across both online and in-person channels, ensuring efficient transactions, sales, and bookings."
              />

              {/* Fourth Block */}
              <FeatureBlock
                  iconSrc={SwitchIcon4}
                  title="Streamlining Digital Transactions"
                  description="Our system is specifically engineered for secure online payments. It offers robust capabilities for recurring billing and smooth digital transactions, right out of the box."
              />

          </div>

      </div>

  );
}