import { setByBestLanguage, setLanguage } from "../../localisations";
import { KEYS, getStorageKey, setStorageKey } from "../../utils/storage";
import { SET_BYBESTLANGUAGE, SET_LANGUAGE, SET_SHOW_TOP_BTN } from "./types";

const initialState = {
  language: "en",
};

export const initLanguage =
  (defaultLang = "en") =>
  async (dispatch) => {
    const storedLang = getStorageKey(KEYS.LANGUAGE_VB) || defaultLang;
    await setLanguage(storedLang);
    dispatch({
      type: SET_LANGUAGE,
      payload: storedLang,
    });
  };

export const initBybestLanguage =
  (defaultLang = "al") =>
  async (dispatch) => {
    const storedLang = getStorageKey(KEYS.BYBEST_LANGUAGE) || defaultLang;
    await setByBestLanguage(storedLang);
    dispatch({
      type: SET_BYBESTLANGUAGE,
      payload: storedLang,
    });
  };

export const changeLanguage = (lang) => async (dispatch) => {
  await setLanguage(lang);
  dispatch({
    type: SET_LANGUAGE,
    payload: lang,
  });
};

export const changeBybestLanguage = (lang) => async (dispatch) => {
  await setByBestLanguage(lang);
  dispatch({
    type: SET_BYBESTLANGUAGE,
    payload: lang,
  });
};

export const initCountry = () => (dispatch) => {
  return new Promise(async (resolve) => {
    let countryCode = getStorageKey(KEYS.COUNTRY_CODE);
    countryCode = countryCode ?? "US";
    setStorageKey(KEYS.COUNTRY_CODE, countryCode);

    resolve();
  });
};

export const setShowTopBtn = (payload) => ({
  type: SET_SHOW_TOP_BTN,
  payload,
});

export const languageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    default:
      return state;
  }
};
