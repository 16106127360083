import { Drawer } from "@mui/material";
import React from "react";
import "./index.css";

const CustomDrawer = ({
  isOpen = false,
  toggleDrawer = () => {},
  children,
}) => {
  return (
    <Drawer
      anchor="bottom"
      open={isOpen}
      onClose={toggleDrawer(false)}
      className="border-none"
      PaperProps={{
        sx: {
          borderRadius: "16px 16px 0 0",
          boxShadow: "0px -4px 20px rgba(0, 0, 0, 0.1)",
          padding: "16px",
          maxHeight: "80vh",
          border: "none",
          outline: "none",
          background:
            "linear-gradient(90deg, #fcfcfc, #f7f7f7 10.04%, #f7f7f7 51.56%, #f7f7f7 89.58%, #fcfcfc)",
        },
      }}
    >
      <div
        className="component !border-none p-2 cursor-pointer"
        onClick={toggleDrawer(false)}
      >
        <div className="line"></div>
      </div>
      {children}
    </Drawer>
  );
};

export default CustomDrawer;
