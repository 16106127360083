export default function EntertainmentVenues(props) {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
      <g clip-path="url(#clip0_527_3630)">
        <g clip-path="url(#clip1_527_3630)">
          <path
            d="M-0.109375 8.11772C-0.109375 7.10329 0.293601 6.13041 1.0109 5.41311C1.72815 4.69582 2.70102 4.29285 3.71546 4.29285H18.5048C19.5193 4.29285 20.4921 4.69582 21.2094 5.41311C21.9267 6.13041 22.3296 7.10329 22.3296 8.11772V19.5922H21.4729L18.209 15.5124H20.7998V8.11772C20.7998 7.50904 20.5579 6.92533 20.1276 6.4949C19.6972 6.06455 19.1134 5.8228 18.5048 5.8228H3.71546C3.10678 5.8228 2.52307 6.06455 2.09273 6.4949C1.66239 6.92533 1.42054 7.50904 1.42054 8.11772V15.5124H4.01127L0.747396 19.5922H-0.109375V8.11772ZM15.8784 21.1221H22.3296V22.907C22.3296 23.9214 21.9267 24.8943 21.2094 25.6116C20.4921 26.3289 19.5193 26.7319 18.5048 26.7319H17.6134L17.3227 25.7384L15.8784 21.1221ZM19.5135 19.5922H15.4L14.1231 15.5124H16.2497L19.5135 19.5922ZM8.93351 19.5922H13.7967L12.5207 15.5124H10.2095L8.93351 19.5922ZM14.2751 21.1221H8.45517L6.86717 26.1954L6.69888 26.7319H16.0192L15.8601 26.1883L14.2751 21.1221ZM8.6062 15.5124L7.33117 19.5922H2.70571L5.96961 15.5124H8.6062ZM6.85185 21.1221H-0.109375V22.907C-0.109375 23.9214 0.293601 24.8943 1.0109 25.6116C1.72815 26.3289 2.70102 26.7319 3.71546 26.7319H5.09546L5.40864 25.7374L6.85185 21.1221Z"
            fill="#F4D35E"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_527_3630">
          <rect
            width="30"
            height="30"
            fill="white"
            transform="translate(0.390625 0.292664)"
          />
        </clipPath>
        <clipPath id="clip1_527_3630">
          <rect
            width="30"
            height="30"
            fill="white"
            transform="translate(-4.10938 0.292664)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
