import React from 'react';

import './index.css';
import affImg from '../../../assets/images/affiliate.png'
import affImgNew from '../../../assets/images/affiliate-new-header.png'

export default function Intro () {
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 items-center'>
      <div className='flex flex-col gap-8'>
        <span className='text-2xl xl:text-5xl text-black1 font-black pr-0 xl:pr-10'>
          Partner with VenueBoost’s Affiliate Program

        </span>
        <div className='text-lg xl:text-22'>
          <p className='leading-9'>
            Expand Your Reach and Revenue
          </p>
        </div>
        <div className='flex'>
          <a href={'/affiliates/register'}>
            <span className=' text-white rounded-lg px-9 py-4 text-sm font-bold cursor-pointer bg-gradient-to-r from-[#280B3B] via-[#5D1C82] to-[#BC14FE]'>
               Join the Affiliate Program
            </span>
          </a>
        </div>
      </div>
      <img src={affImgNew} alt='img' />
    </div>
  )
}
