import WhoWeServeRetailOverview from "../../views/whoweserve/retail_overview";
import FashionAndThreads from "../../views/whoweserve/retail_overview/fashion_threads";
import HomeDecor from "../../views/whoweserve/retail_overview/home_decor";
import SportsEssentials from "../../views/whoweserve/retail_overview/sports_essentials";
import AutoPartsStores from "../../views/whoweserve/retail_overview/auto_parts_stores";
import BoutiquesSpecialtyStores from "../../views/whoweserve/retail_overview/boutiques_and_specialty_stores";
import ElectronicsStores from "../../views/whoweserve/retail_overview/electronics_stores";
import GroceryStores from "../../views/whoweserve/retail_overview/grocery_stores";
import LiquorStores from "../../views/whoweserve/retail_overview/liquor_stores";
import OnlineRetailers from "../../views/whoweserve/retail_overview/online_retailers";
import PetSupplyStores from "../../views/whoweserve/retail_overview/pet_supply_stores";
import Pharmacies from "../../views/whoweserve/retail_overview/pharmacies";
import RetailChains from "../../views/whoweserve/retail_overview/retail_chains";

import { Route } from "react-router-dom";
import RouteNames from "../../constants/route_names";

const retailRoutes = [
  <Route
    exact
    path={RouteNames.whoweserve_retail_overview}
    element={<WhoWeServeRetailOverview />}
  />,
  <Route
    exact
    path={RouteNames.whoweserve_sports_essentials}
    element={<SportsEssentials />}
  />,
  <Route
    exact
    path={RouteNames.whoweserve_fashion_threads}
    element={<FashionAndThreads />}
  />,
  <Route
    exact
    path={RouteNames.whoweserve_home_decor}
    element={<HomeDecor />}
  />,
  <Route
    exact
    path={RouteNames.whoweserve_online_retailers}
    element={<OnlineRetailers />}
  />,
  <Route
    exact
    path={RouteNames.whoweserve_pet_supply_stores}
    element={<PetSupplyStores />}
  />,
  <Route
    exact
    path={RouteNames.whoweserve_pharmacies}
    element={<Pharmacies />}
  />,
  <Route
    exact
    path={RouteNames.whoweserve_retail_chains}
    element={<RetailChains />}
  />,
  <Route
    exact
    path={RouteNames.whoweserve_boutiques_and_specialty_stores}
    element={<BoutiquesSpecialtyStores />}
  />,
  <Route
    exact
    path={RouteNames.whoweserve_auto_parts_stores}
    element={<AutoPartsStores />}
  />,
  <Route
    exact
    path={RouteNames.whoweserve_grocery_stores}
    element={<GroceryStores />}
  />,
  <Route
    exact
    path={RouteNames.whoweserve_liquor_stores}
    element={<LiquorStores />}
  />,
  <Route
    exact
    path={RouteNames.whoweserve_electronics_stores}
    element={<ElectronicsStores />}
  />,
  
];

export default retailRoutes;
