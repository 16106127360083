import React, {useEffect, useState} from "react";
import "./index.css";
import {Seo} from "../../components/Seo";

import confirmed_success from "../../assets/svgs/confirmed_success_brand.svg";
import {verifyPrivacyRightRequestLink} from "../../redux/actions/marketing";
import {useNavigate, useParams} from "react-router-dom";

const PrivacyRequestFormConfirm = () => {

  const navigate = useNavigate();
  const { token } = useParams();

  useEffect(() => {
    checkPrivacyRightRequestLink();
    }, []);


  const checkPrivacyRightRequestLink = () => {
    verifyPrivacyRightRequestLink({ token })
        .then((data) => {
         // do nothing
        })
        .catch((error) => {
          setTimeout(() => {
            navigate("/");
          }, 2000);
        });
  };

  return (
    <div className={"align-col-middle "}>
      <Seo title={"Privacy Request Form Confirmation | VenueBoost"} />
      <div className=" w-[100vw] getstarted-bg">
        <div className="mx-auto max-w-screen-main px-4 sm:px-6 md:px-8 lg:px-8 ">
          <div className="flex md:flex-row justify-center flex-col pt-4 pb-11 h-full">
            <div className=" w-full md:w-[100%] bg-[#F9F7F7] border-[1px] border-[#E0E0E0] rounded-[13px] p-4 md:p-8 py-6 gap-y-4 gap-x-8">
              <div className="rounded-[31px] p-[24px] md:p-[33px] relative">
                <div className="flex justify-center">
                  <img
                      src={confirmed_success}
                      alt="Confirmation Icon"
                      className="w-[200px] h-[200px] py-4"
                  />
                </div>

                <div className="flex justify-center items-center">
                  <span className="text-[32px] md:text-32 font-extrabold leading-[50px] my-6]">
                   Confirmation Success
                  </span>
                </div>
                <p className="text-18 font-semibold text-[#240b3b] my-4">
                  Thank you for confirming your email address. We have successfully received your privacy rights request.
                </p>
                <p className="text-18"><b>What Happens Next?</b></p>
                <p className="text-18 font-semibold text-[#240b3b] my-4">
                  Request Processing: Our team is now reviewing your request. We are committed to handling it promptly and in accordance with relevant data protection regulations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyRequestFormConfirm;
