import React, { useState } from "react";
import "./index.css";

import DropdownSubMenu from "./DropdownSubMenu";
import rightIcon from "../../assets/svgs/menu-right.svg";
import {useLocation, useNavigate} from "react-router-dom";

const DropdownsNewMenuMobile = ({ title, menu }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [childrens, setChildrens] = useState([]);
  const [hoveredItem, setHoveredItem] = useState(0);
  useState(() => {
    setChildrens(menu[0].children);
    setHoveredItem(0);
  }, []);

  const location = useLocation();

  const isWhoWeServeRoute =  `/${location.pathname.split('/')[1]}` === '/who-we-serve'
  ;

  // Inside your functional component
  const navigate = useNavigate();

  return (
    <>
      <div
        className={`flex w-full text-16 lg:h-20 align-middle nav-item-header ${isWhoWeServeRoute ? 'text-[#8b1dbf]' : ''} font-medium text-[#666A73] transition flex gap-1 cursor-pointer px-4 hover:text-black1/75`}
        onMouseEnter={() => setIsCollapsed(true)}
        onMouseLeave={() => setIsCollapsed(false)}
      >
        <span className="whitespace-nowrap"> {title}</span>
      </div>
      <div
        className={`fixed overflow-hidden z-10 rounded-[13px] bg-white    transition-all duration-100 delay-100 mb-4 ${
          isCollapsed
            ? "flex dropdownnewmenu max-h-full"
            : "max-h-0 py-0 lg:py-0"
        }`}
        onMouseLeave={() => setIsCollapsed(false)}
        onMouseEnter={() => setIsCollapsed(true)}
      >
        <div className=" px-3 py-5 bg-[#F9F7F7] basis-1/3">
          <div className="px-6 mb-5">
            <span className="text-20  text-[#111B29] font-bold leading-[150%] ">
            Industries by VenueBoost
            </span>
            <p className=" lg:text-[14px] font-medium text-[#677489] leading-[150%] ">
            The premier solution for hospitality industry venues, streamlining operations and elevating success
            </p>
          </div>
          <ul className={`gap-4 text-18`}>
            {menu.map((item, i) => (
              <li
                key={i}
                className={`px-4 py-4 text-x rounded-lg w-full ${
                  hoveredItem === i ? "item-hover-bg" : ""
                }`}
                onMouseEnter={() => {
                  setChildrens(item.children);
                  setHoveredItem(i);
                }}
                style={{ cursor: "pointer" }}
              >
                <div className="flex justify-between items-center">
                  <div className="flex items-center">
                    {hoveredItem === i ? (
                      <img
                        src={item.hoverIcon}
                        className={"w-[42px] h-[42px] object-fit"}
                        alt="icon"
                      />
                    ) : (
                      <img
                        src={item.icon}
                        className={"w-[42px] h-[42px] object-fit"}
                        alt="icon"
                      />
                    )}
                    <div className="mx-6">
                      <span className="text-20  text-[#111B29] leading-[150%]">
                        {item.label}
                      </span>
                      {/*
                      {item.description && (
                        <p
                          className="   text-[14px] font-medium text-[#677489] leading-[150%]"
                          key={item.key}
                        >
                          {item.description}
                        </p>
                      )}*/}
                    </div>
                  </div>
                  {
                    /*
                  
                  <img
                    src={rightIcon}
                    className={`w-[25px] h-[25px] ${
                      hoveredItem === i
                        ? "item-hover-transform"
                        : "item-not-hover-transform"
                    }`}
                    alt="icon"
                  />*/
                  }
                  <img
                    src={rightIcon}
                    className={`w-[10px] h-[10px] item-not-hover-transform`}
                    alt="icon"
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
        <ul className={`gap-4 text-18 bg-gray px-8 pt-5 pb-5 basis-2/3`} style={{overflowY: "auto", height: "465px"}}>
          <li className="px-2 mb-4 py-1 lg:py-2 text-xl transition-all duration-100 delay-100 border-b border-gray-300">
            <div
                onClick={() => {
                  setIsCollapsed(false);
                  navigate(childrens[0]?.url);
                }}
                className={`flex cursor-pointer items-center ${childrens[0]?.className||''}`}
                style={{alignItems: "center"}}
            >
                <img src={childrens[0]?.icon} className={"w-7 h-7 object-fit"} alt="icon" />
                <div className="mx-5 flex-1">
                  <span className="text-20  text-[#111B29] font-bold leading-[150%] ">
                    {childrens[0]?.label}
                  </span>
                  <p className=" lg:text-[14px] font-medium text-[#677489] leading-[150%] ">
                    {childrens[0]?.subtitle}
                  </p>
                </div>
              <div
                  onClick={() => {
                      setIsCollapsed(false);
                      navigate(childrens[0]?.url);
                  }}
                  className="rounded-full overflow-hidden px-2.5 overview-more">
                <span className="lg:text-[14px] text-gray-900 block">Learn more</span>
              </div>
            </div>
          </li>
          <DropdownSubMenu key={String(hoveredItem)} menu={childrens.slice(1)}/>
        </ul>
      </div>
    </>
  );
};

export default DropdownsNewMenuMobile;
