import React from "react";

const StoryCard = ({ img, title, date, desc, slug }) => {
  return (
    <div className="relative flex flex-row bg-transparent blog-card overflow-hidden w-[335px] h-[203px] md:w-[580px] md:h-[384px] gap-[31px] md:gap-[35px]">
      <img
        className="h-full w-[144px] md:w-[272px] rounded-[10px] object-cover"
        src={img}
        alt="aboutus"
      />
      <div className="flex flex-col gap-2 md:gap-[25px] justify-center items-start">
        {/*<p className="bottom-7 right-6 md:bottom-0 md:right-0 md:relative text-[#7F7F7F] text-[10px] md:text-14 leading-5 font-normal mt-0 md:mt-6 py-2 md:py-0">*/}
        {/*  /!*{date}*!/*/}
        {/*</p>*/}

        <p className="text-[#252525] text-18 md:text-[22px] font-bold leading-[18px] md:leading-8">
          {title}
        </p>
        
        <div class="flex items-center justify-start">
          <div class="flex p-[2px] justify-center items-center h-[22px] w-[80px] md:w-[140px] md:h-[40px]">
            <div class="flex w-full h-full items-center justify-center bg-white rounded-md">
              <a
                className=" text-[10px] md:text-14 font-semibold"
                href={`/resources/success-stories/${slug}`}
              >
                Explore Story <span> →</span>
              </a>
            </div>
          </div>
        </div>

        
      </div>
    </div>
  );
};

export default StoryCard;