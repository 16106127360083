import React from "react";
import "./index.css";
import { Seo } from "../../components/Seo";
// import Integration from "../../components/Integration";
import HomeIntegration from "../home/integration";
import { t } from "i18next";

const Integrations = () => {
  return (
    <div className={"align-col-middle view-terms"}>
      <Seo title={t("components.integrations.seo_title")} />
      <HomeIntegration />
    </div>
  );
};

export default Integrations;
