import React from "react";
import { Modal } from "antd";
import { modalButtonProps } from "../../../utils/common";
import { isMobile } from "react-device-detect";

const QuizCompletionModal = ({ handleClose, open, scores, handleOk }) => {
  const winner = scores.numberOfCorrectAnswers > 0;

  return (
    <Modal
      title={
        <div className="flex items-center gap-2">
          {winner ? "🎉" : "😔"}
          <span>Quiz Completion Summary</span>
        </div>
      }
      open={open}
      onCancel={handleClose}
      {...modalButtonProps}
      okButtonProps={{
        style: {
          background:
            "linear-gradient(128deg, rgb(36, 11, 59) 3.95%, rgb(61, 30, 89) 34%, rgb(51, 5, 94) 67.3%, rgb(188, 20, 254) 94.28%)",
          color: "white",
          height: "40px",
          width: isMobile ? "100%" : "50%",
          marginLeft: isMobile ? "0px" : "8px",
          marginTop: isMobile ? "15px" : "0",
        },
      }}
      cancelButtonProps={{
        style: {
          backgroundColor: "transparent",
          color: "#240b3b",
          borderColor: "#CBD5E1",
          height: "40px",
          width: isMobile ? "100%" : "50%",
        },
      }}
      cancelText="Close"
      okText="Get started"
      className="freeJourneyModal"
      width={500}
      maskClosable={false}
      onOk={handleOk}
      centered
    >
      <p>
        You scored{" "}
        <span
          style={{
            fontWeight: 700,
            color: winner ? "green" : "red",
          }}
        >{`${scores.numberOfCorrectAnswers}/${scores.numberOfQuestions}`}</span>{" "}
        correct answers
      </p>
      <p>
        You earned{" "}
        <span
          style={{
            fontWeight: 700,
            color: winner ? "green" : "red",
          }}
        >{`${scores.correctPoints}/${scores.totalPoints}`}</span>{" "}
        points
      </p>
      <p className={"mt-2"}>
        {winner
          ? "Congratulations! You've successfully earned rewards. To claim and utilize your rewards, please proceed to create an account."
          : "Oops! It seems you didn't get any questions right. Feel free to try your luck in another quiz for a chance to win rewards!"}
      </p>
    </Modal>
  );
};

export default QuizCompletionModal;
