import React, {useEffect} from "react";
import {motion} from "framer-motion";
import {LazyLoadImage} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import OpenAiLogo from "../../assets/images/integration/openai.png";
import ShopifyLogo from "../../assets/images/integration/shopify.png";
import Googlelogo from "../../assets/images/integration/google.svg";
import Mailchimplogo from "../../assets/images/integration/mailchimp.svg";
import ZapierLogo from "../../assets/images/integration/zapier.png";
import SurveyMonkeyLogo from "../../assets/images/integration/surveymonkey.svg";
import Sendgridlogo from "../../assets/images/integration/sendgrid.svg";
import Stripelogo from "../../assets/images/integration/stripe.svg";
import TwilioLogo from "../../assets/images/integration/twilio.svg";
import MixpanelLogo from "../../assets/images/integration/mixpanel.svg";
import MondayLogo from "../../assets/images/integration/monday.svg";
import DocuSignLogo from "../../assets/images/integration/docusign.svg";
import MakeLogo from "../../assets/images/integration/makecom.svg";
import WooCommerceLogo from "../../assets/images/integration/woocommerce.svg";
import ZendeskLogo from "../../assets/images/integration/zendesk.svg";
import TypeformLogo from "../../assets/images/integration/typeform.svg";
import RebrandlyLogo from "../../assets/images/integration/rebrandly.svg";
import QuickbooksLogo from "../../assets/images/integration/quickbooks.svg";
import iCalendarLogo from "../../assets/images/integration/icalendar600.svg";
import heapLogo from "../../assets/images/integration/heap.svg";

const data = [
  {logo: Stripelogo, alt: "Stripe"},
  {logo: Sendgridlogo, alt: "SendGrid"},
  {logo: Mailchimplogo, alt: "Mailchimp"},
  {logo: Googlelogo, alt: "Google"},
  {logo: SurveyMonkeyLogo, alt: "SurveyMonkey"},
  {logo: MixpanelLogo, alt: "Mixpanel"},
  {logo: TwilioLogo, alt: "Twilio"},
  {logo: ZapierLogo, alt: "Zapier"},
  {logo: ShopifyLogo, alt: "Shopify"},
  {logo: MondayLogo, alt: "Monday.com"},
  {logo: DocuSignLogo, alt: "DocuSign"},
  {logo: OpenAiLogo, alt: "OpenAI"},
  {logo: MakeLogo, alt: "Make"},
  {logo: WooCommerceLogo, alt: "WooCommerce"},
  {logo: ZendeskLogo, alt: "Zendesk"},
  {logo: TypeformLogo, alt: "Typeform"},
  {logo: RebrandlyLogo, alt: "Rebrandly"},
  {logo: QuickbooksLogo, alt: "QuickBooks"},
  {logo: iCalendarLogo, alt: "iCalendar"},
  {logo: heapLogo, alt: "Heap"},
];
const HomeLogoIntegration = ({className}) => {
  return (
    <div className={className}>
      <div className=''>
        <div className='grid grid-cols-2 lg:grid-cols-5 gap-y-12'>
          {data.map((item, index) => (
            <motion.div
              key={index}
              className='flex-1'
              initial='offscreen'
              whileInView='onscreen'
              viewport={{amount: 0.8, once: true}}
            >
              <motion.div
                className='h-full flex'
                variants={{
                  offscreen: {opacity: 0, y: 100},
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      type: "spring",
                      bounce: 0,
                      duration: 1,
                      delay: 0.1 * index,
                    },
                  },
                }}
              >
                <div className='px-4 md:px-10 h-full align-middle' key={index}>
                  <LazyLoadImage
                    alt={item.alt}
                    src={item.logo}
                    className=''
                    effect='blur'
                  />
                </div>
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default HomeLogoIntegration;
