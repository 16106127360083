import React from "react";

import "./index.css";
import lineImg from "../../../assets/images/pink-line.webp";

const list = [
  {
    title: "Customized Solutions Just for You\n",
    content:
      "VenueBoost recognizes the distinctiveness of each business. We craft our services to align with your precise objectives and hurdles.",
  },
  {
    title: "Explore with Our No-Cost Freemium Plan\n",
    content:
      "Hit the ground running with no delays. Start exploring VenueBoost through our Freemium option, designed for you to test our features without any financial commitment.",
  },
  {
    title: "Unwavering Support for Your Success\n",
    content:
      "We're invested in your journey, not just offering tools but also providing consistent support to help you leverage every aspect of VenueBoost to its fullest.\n",
  },
  {
    title: "Transparent and Honest Pricing",
    content:
      "At VenueBoost, clarity and honesty are at our core. Our straightforward pricing model is designed to deliver real value, making sure your investment truly pays off.",
  },
];

const Switching = () => {
  return (
    <div className={"align-col-middle view-terms mt-0"}>
      <div className='w-[100vw]  '>
        <div className='mx-auto max-w-screen-main px-7 sm:px-6 md:px-8 lg:px-16'>
          <div className='grid grid-cols-1 md:grid-cols-3 gap-8 pt-8 md:pt-20 pb-8 md:pb-24'>
            <div className='flex flex-col justify-between'>
              <span className='text-[#240B3B] font-bold text-28 md:text-[48px] text-center md:text-left md:leading-[68px]'>
                Transform Your Business with VenueBoost
              </span>
              <div className='hidden md:block h-1 w-28'>
                <img src={lineImg} className='w-full h-full' />
              </div>
              <div className='flex flex-col'>
                <span className='text-black text-[19px] font-normal leading-[28px]'>
                  Discover VenueBoost, where your business’s growth is our core
                  mission. Beyond a mere platform, we serve as the spark for
                  your success, providing a foundation that not only supports
                  but actively propels your operations forward.
                </span>
                <br />
                {/*<span className='text-black text-[19px] font-normal leading-[28px]'>*/}
                {/*Embark on Your VenueBoost Journey:*/}
                {/*</span>*/}
              </div>
            </div>
            <div className='col-span-1 md:col-span-2 flex flex-row flex-wrap gap-x-10 gap-y-4 md:gap-y-16'>
              {list.map((item, index) => (
                <div
                  key={index}
                  className='flex flex-col switch-item rounded-2xl max-w-[375px] px-8 py-8 gap-5 !bg-white z-10'
                >
                  <span className='text-pink1 font-bold text-2xl md:text-[28px] leading-9 max-w-full'>
                    {item.title}
                  </span>
                  <span className='text-sm md:text-[18px] font-medium text-black2'>
                    {item.content}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Switching;
