import React from 'react';
import Intro from './intro';
import HowItWork from './howitwork';
import WhoFor from './whofor';
import Audience from './audience';
import Benefits from './benefits';
import Faqs from './faqs';

import FreePlanCard from '../../components/FreePlanCard';
import {Seo} from "../../components/Seo";

const Affilicates = () => {

    const currentUrl = "https://venueboost.io/affiliates";
    const seoTitle = "Join Our Affiliate Program and Earn Rewards";
    const seoDescription = "Become a VenueBoost affiliate and start earning rewards. Promote our innovative solutions and earn commissions for each referral. Join today and grow your business with VenueBoost!";

    return (
    <div className='flex justify-center items-center flex-col'>
        <Seo
            title={seoTitle + " | VenueBoost"}
            description={seoDescription}
            url={currentUrl}
        />
      <div className={`bg-[#f3f3f3] w-[100vw] relative flex justify-center items-center`}>
        <div className={'sub-container view-sales overflow-auto py-8 px-4 h-full flex flex-col items-center justify-center gap-8 md:gap-20 xl:gap-32 xl:max-w-[1223px]'}>
          <Intro />
          <HowItWork />
          <WhoFor />
          <Audience />
          <Benefits />
        </div>
      </div>
      <div className={`bg-[#240B3B] w-[100vw] relative flex justify-center items-center`}>
        <div className={'sub-container view-sales overflow-auto py-8 px-4 h-full flex flex-col items-center justify-center gap-8 md:gap-20 xl:gap-32 xl:max-w-[1223px] w-full'}>
          <div className='w-full'>
            <Faqs />
          </div>
        </div>
      </div>

      <div className={`bg-[#f3f3f3] w-[100vw] relative flex justify-center items-center`}>
        <div className={'sub-container view-sales overflow-auto py-8 px-4 h-full flex flex-col items-center justify-center gap-8 md:gap-20 xl:gap-32 xl:max-w-[1223px]'}>
          <div className="mx-auto flex items-center justify-center my-6">
            <FreePlanCard />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Affilicates;
