import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { verifyCoupon } from "../../../redux/actions/cart";

export const Cart = ({
  onClick,
  tableProducts = [],
  quantities = [],
  discount = 0,
  selectedShipping = 0,
  isCheckout = true,
  buttonLabel = "Proceed to checkout",
  isProceed = false
}) => {
  const dispatch = useDispatch();
  const [couponCode, setCouponCode] = useState("");

  const totalSum = tableProducts.reduce((sum, item, index) => {
    const price = parseFloat(item.price.replace("$", "")) ?? 1;
    const totalPrice = price * quantities[index];
    return sum + totalPrice;
  }, 0);

  const applyCoupon = () => {
    let payload = {
      coupon_code: couponCode,
      subtotal: totalSum,
    };
    dispatch(verifyCoupon(payload));
  };

  const lines = [
    {
      label: "Subtotal",
      value: `$${totalSum}`,
    },
    {
      label: "Shipping",
      value: Number(selectedShipping) === 0 ? "Free" : `$${selectedShipping}`,
    },
    {
      label: "Discound",
      value: `-$${discount}`,
    },
    // {
    //   label: "Total",
    //   value: `$${totalSum - discount}`,
    // },
  ];

  return (
    <div className="w-full flex flex-col gap-2 cart-border">
      <span className="title">Cart Total</span>
      <div className="flex flex-col gap-2">
        {lines.map((item) => {
          return (
            <div className="line">
              <span className="line-label">{item.label}</span>
              <span className="line-value">{item.value}</span>
            </div>
          );
        })}
        <div className="flex justify-between items-center my-2">
          <span className="text-[#1A1A1A] text-14 md:text-16 font-semibold">Total</span>
          <span className="text-[#1A1A1A] text-14 md:text-16 font-semibold">{ `$${totalSum - discount}` }</span>
        </div>
      </div>

      {isCheckout && (
        <>
          <button className="proceed" onClick={onClick}>
            <span>{buttonLabel}</span>
          </button>

          <span className="enter-code mt-4">Enter Discount Code</span>
          <div className="enter-code-input flex items-center relative">
            <input
              placeholder="Enter discount code"
              className="border-transparent outline-none h-full"
              value={couponCode ?? ""}
              onChange={(e) => setCouponCode(e.target.value)}
            />
            <button onClick={applyCoupon} className="absolute right-0">
              <span>Apply</span>
            </button>
          </div>
        </>
      )}

      {
        isProceed && (
          <>
            <button className="proceed" onClick={onClick}>
              <span>Proceed to checkout</span>
            </button>

            <span className="enter-code mt-4">Enter Discount Code</span>
            <div className="enter-code-input flex items-center relative">
              <input
                placeholder="Enter discount code"
                className="border-transparent outline-none h-full"
                value={couponCode ?? ""}
                onChange={(e) => setCouponCode(e.target.value)}
              />
              <button onClick={applyCoupon} className="absolute right-0">
                <span>Apply</span>
              </button>
            </div>
          </>
        )
      }
    </div>
  );
};
