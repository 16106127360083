import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";

import "./index.css";

import {Seo} from "../../../../components/Seo";
import Faq from "../../../../components/Faq";
import Advantage from "../../../../components/Advantage";
import Grow from "../../grow";

import CircleTick from "../../../../assets/svgs/checkfull.svg";
// import SportAmusementParks from "../../../../assets/svgs/park.svg";
// import SportConcertVenuesAndTheaters from "../../../../assets/svgs/concert.svg";
// import SportArcadesAndGameCenters from "../../../../assets/svgs/aracde.svg";
// import SportBowling from "../../../../assets/svgs/sport-bowling.svg";

import OverviewSportEntertainment from "../../../../assets/images/who-we-serve/venue.webp";
import OverviewImg1 from "../../../../assets/images/who-we-serve/overviewimg1.webp";
import OverviewImg2 from "../../../../assets/images/who-we-serve/overviewimg2.webp";
import OverviewImg3 from "../../../../assets/images/who-we-serve/overviewimg3.webp";
import OverviewImg4 from "../../../../assets/images/who-we-serve/overviewimg4.webp";
import OverviewImg5 from "../../../../assets/images/who-we-serve/overviewimg5.webp";
import SportEntertainmentDashboardOverview from "../../../../assets/images/who-we-serve/se-dashboard-venue.webp";
import OverviewImg7 from "../../../../assets/images/who-we-serve/overviewimg7.webp";
import SportEntertainmentGuestsManagement from "../../../../assets/images/who-we-serve/se-guests-management.webp";
// import CheckOutlineIcon from "../../../../assets/svgs/checkoutline.svg";
// import Avatar1 from "../../../../assets/svgs/eric_p_avatar.svg";
// import Avatar2 from "../../../../assets/svgs/alex_r_avatar.svg";
// import Avatar3 from "../../../../assets/svgs/sara_m_avatar.svg";

// import AnyVenue from "../../../../assets/svgs/anyvenue.svg";
// import Dot1 from "../../../../assets/svgs/dot1.svg";
// import Dot2 from "../../../../assets/svgs/dot2.svg";
// import Dot3 from "../../../../assets/svgs/dot3.svg";
// import SportsEntertainmentGolf from "../../../../assets/svgs/sport-golf.svg";
// import SportsEntertainmentGym from "../../../../assets/svgs/sport-gym.svg";
// import SportsEntertainmentBowling from "../../../../assets/svgs/sport-bowling.svg";
import {NotificationManager} from "react-notifications";
import {create as createContactSale} from "../../../../redux/actions/contactsales";
import {t} from "i18next";
// import AccommodationHotel from "../../../../assets/svgs/accommodation-hotel.svg";
// import AccommodationResorts from "../../../../assets/svgs/resort.svg";
// import AccommodationVacation from "../../../../assets/svgs/accommodation-vacation.svg";
// import AccommodationHostel from "../../../../assets/svgs/accommodation-hostel.svg";
import OverviewFoodGuestsManagement from "../../../../assets/images/who-we-serve/food-guests-management.webp";
import OverviewImg8 from "../../../../assets/images/who-we-serve/overviewimg8.webp";
// import NewMenuGolf from "../../../../assets/svgs/new-menu-golf.svg";
// import SportSportsComplexes from "../../../../assets/svgs/sportcomplex.svg";
// import SportSkiResorts from "../../../../assets/svgs/ski.svg";
// import NewMenuCinema from "../../../../assets/svgs/new-menu-cinema.svg";
// import NewMenuMuseum from "../../../../assets/svgs/new-menu-museum.svg";
// import { isMobile } from "react-device-detect";
import {CheckLine} from "../../../../components/CheckLine";
import FreeTrial from "../../../../components/FreeTrial";
import FreePlanCard from "../../../../components/FreePlanCard";

const generalFaq = [
  {
    id: 1,
    status: false,
    title: "What types of entertainment venues can use VenueBoost?",
    content:
      "VenueBoost is designed for a wide array of entertainment venues, including golf courses, bowling alleys, gyms & fitness centers, arcades & game centers, sports arenas, concert halls & theaters, amusement & theme parks, ski resorts, museums, and cinemas.",
  },
  {
    id: 2,
    status: false,
    title:
      "How does VenueBoost assist with bookings management for entertainment venues?",
    content:
      "VenueBoost provides robust tools for managing reservations and ticketing, streamlining the booking process for venues like amusement parks, cinemas, theaters, and more, ensuring a smooth customer experience.",
  },
  {
    id: 3,
    status: false,
    title:
      "Can VenueBoost support inventory management for entertainment venues?",
    content:
      "Yes, VenueBoost helps venues efficiently manage their equipment, supply, and rental inventory, facilitating better resource allocation and availability.",
  },
  {
    id: 4,
    status: false,
    title:
      "What staff management capabilities does VenueBoost offer for entertainment businesses?",
    content:
      "VenueBoost enables efficient staff scheduling and time tracking, enhancing coordination and management of staff duties, crucial for maintaining high-quality service in entertainment venues.",
  },
  {
    id: 5,
    status: false,
    title:
      "How can VenueBoost enhance the marketing efforts of entertainment venues?",
    content:
      "VenueBoost includes integrated marketing tools, helping venues promote their brand, attract new customers, and create effective campaigns with promotions, emails, and more.",
  },
  {
    id: 6,
    status: false,
    title:
      "Does VenueBoost offer loyalty and retention programs for entertainment venues?",
    content:
      "Yes, VenueBoost enables entertainment venues to create customized loyalty programs, helping to keep guests engaged and encouraging repeat visits.",
  },
  {
    id: 7,
    status: false,
    title:
      "What analytics and reporting features does VenueBoost provide for entertainment venues?",
    content:
      "VenueBoost offers analytics and reporting optimized for entertainment venues, providing insightful data analysis and comprehensive reports for informed decision-making.",
  },
  {
    id: 8,
    status: false,
    title:
      "How does VenueBoost facilitate payment processing for entertainment venues?",
    content:
      "The platform enables easy generation and management of tailored payment links for online bookings, ensuring secure and hassle-free financial transactions.",
  },
  {
    id: 9,
    status: false,
    title: "Can VenueBoost improve guest management for entertainment venues?",
    content:
      "Yes, VenueBoost equips entertainment businesses with the tools to engage visitors, manage bookings, and offer personalized experiences, enhancing overall guest satisfaction.",
  },
  {
    id: 10,
    status: false,
    title:
      "What kind of onboarding support does VenueBoost offer to entertainment venues?",
    content:
      "VenueBoost provides a comprehensive onboarding process, including training, resource materials, and dedicated customer support, to ensure a smooth setup and effective use of our platform for new clients.",
  },
];

const Museum = () => {
  const navigate = useNavigate();

  const currentUrl =
    "https://venueboost.io/who-we-serve/entertainment-venues/museum";
  const seoTitle = "Engaging Museum Exhibits and Streamline Ticketing";
  const seoDescription =
    "Streamline museum operations and enhance visitor experiences with VenueBoost. Manage exhibit schedules, optimize ticketing, and engage visitors with personalized tours.";

  const [step, setStep] = useState(1);
  const [venuetype, setVenuetype] = useState("golf_venue");
  const [first_name, setFirstname] = useState("");
  const [last_name, setLastname] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [restaurant_name, setRestaurantName] = useState("");
  const [restaurant_city, setRestaurantCity] = useState("");
  const [restaurant_state, setRestaurantState] = useState("");
  const [restaurant_zipcode, setRestaurantZipcode] = useState("");
  const [restaurant_country, setRestaurantCountry] = useState("");
  const [showMore, setShowMore] = useState(false);

  const handleSecondStepSelect = () => {
    if (!first_name || !last_name || !mobile || !email) {
      NotificationManager.error(
        t("who_we_serve.sport_and_entertainment_industry.error_msg"),
        t("who_we_serve.sport_and_entertainment_industry.error"),
        3000
      );
    } else {
      setStep(3);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigateToUrl = url => {
    navigate(url);
  };

  const handleVenueZipCode = e => {
    setRestaurantZipcode(e.target.value);
  };

  const handleVenueState = e => {
    setRestaurantState(e.target.value);
  };

  const handleVenueName = e => {
    setRestaurantName(e.target.value);
  };

  const handleVenueCity = e => {
    setRestaurantCity(e.target.value);
  };

  const handleVenueCountry = e => {
    setRestaurantCountry(e.target.value);
  };

  const handleFirstname = e => {
    setFirstname(e.target.value);
  };

  const handleLastname = e => {
    setLastname(e.target.value);
  };

  const handleMobile = e => {
    setMobile(e.target.value);
  };

  const handleEmail = e => {
    setEmail(e.target.value);
  };

  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      title: "Entertainment Membership Program",
      content: (
        <div className='flex flex-col-reverse md:flex-row mt-0 md:mt-[58px]'>
          <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0'>
            <img src={OverviewFoodGuestsManagement} />
          </div>
          <div className='w-full md:w-[50%] md:p-0 md:pr-0  flex-col'>
            <h1
              className='text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'
              style={{alignSelf: "left"}}
            >
              Exclusive Experiences for Loyal Fans
            </h1>

            <p className='text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
              VenueBoost`s Entertainment Membership Program offers exclusive
              benefits and experiences to members of sports venues and
              entertainment hubs.
            </p>
            <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Encourage fans to attend events and earn points
                </span>
              </div>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Provide exclusive access or discounts to loyal members
                </span>
              </div>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Boost attendance and engagement
                </span>
              </div>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Monetize fan loyalty with special offerings
                </span>
              </div>
            </div>

            <p className='text-[#666666] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px] mb-8'>
              Enhance the entertainment experience with VenueBoost`s
              Entertainment Membership Program.
            </p>
          </div>
        </div>
      ),
      title_modal: "Entertainment Membership Program",
      content1: "Exclusive Experiences for Loyal Fans",
      content2:
        "VenueBoost`s Entertainment Membership Program offers exclusive benefits and experiences to members of sports venues and entertainment hubs.",
      content3: [
        "Encourage fans to attend events and earn points",
        "Provide exclusive access or discounts to loyal members",
        "Boost attendance and engagement",
        "Monetize fan loyalty with special offerings",
      ],
      content4:
        "Enhance the entertainment experience with VenueBoost`s Entertainment Membership Program.",
    },
    {
      title: "Advanced Customer Behavior Analytics",
      content: (
        <div className='flex flex-col-reverse md:flex-row mt-0 md:mt-[58px]'>
          <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0'>
            <img src={OverviewFoodGuestsManagement} />
          </div>
          <div className='w-full md:w-[50%] md:p-0 md:pr-0  flex-col'>
            <h1
              className='text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'
              style={{alignSelf: "left"}}
            >
              Data-Driven Customer Insights
            </h1>

            <p className='text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
              Gain deep insights into customer behavior with VenueBoost's
              Advanced Customer Behavior Analytics. Understand your customers
              better and tailor your services accordingly.
            </p>
            <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Analyze preferences, history, and engagement patterns
                </span>
              </div>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Make data-driven decisions to improve experiences
                </span>
              </div>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Effectively target marketing based on behavior insights
                </span>
              </div>
            </div>
            <p className='text-[#666666] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px] mb-8'>
              VenueBoost empowers businesses to enhance customer engagement
              through data-driven strategies.
            </p>
          </div>
        </div>
      ),
      title_modal: "Advanced Customer Behavior Analytics",
      content1: "Data-Driven Customer Insights",
      content2:
        "Gain deep insights into customer behavior with VenueBoost's Advanced Customer Behavior Analytics. Understand your customers better and tailor your services accordingly.",
      content3: [
        "Analyze preferences, history, and engagement patterns",
        "Make data-driven decisions to improve experiences",
        "Effectively target marketing based on behavior insights",
      ],
      content4:
        "VenueBoost empowers businesses to enhance customer engagement through data-driven strategies.",
    },
    {
      title: "Marketing Automation",
      content: (
        <div className='flex flex-col-reverse md:flex-row mt-0 md:mt-[58px]'>
          <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0'>
            <img src={OverviewFoodGuestsManagement} />
          </div>
          <div className='w-full md:w-[50%] md:p-0 md:pr-0  flex-col'>
            <h1
              className='text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'
              style={{alignSelf: "left"}}
            >
              Boost Engagement and Sales
            </h1>

            <p className='text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
              Take your marketing to the next level with VenueBoost's Marketing
              Automation. Seamlessly automate and personalize campaigns to drive
              engagement and sales.
            </p>
            <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Create targeted email campaigns based on customer behavior
                </span>
              </div>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Send personalized offers and recommendations
                </span>
              </div>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Measure effectiveness and optimize strategies
                </span>
              </div>
            </div>
            <p className='text-[#666666] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px] mb-8'>
              VenueBoost streamlines your marketing across industries with
              powerful automation.
            </p>
          </div>
        </div>
      ),
      title_modal: "Marketing Automation",
      content1: "Boost Engagement and Sales",
      content2:
        "Take your marketing to the next level with VenueBoost's Marketing Automation. Seamlessly automate and personalize campaigns to drive engagement and sales.",
      content3: [
        "Create targeted email campaigns based on customer behavior",
        "Send personalized offers and recommendations",
        "Measure effectiveness and optimize strategies",
      ],
      content4:
        "VenueBoost streamlines your marketing across industries with powerful automation.",
    },
  ];

  const onSubmit = () => {
    if (
      !restaurant_country ||
      !restaurant_zipcode ||
      !restaurant_state ||
      !restaurant_city
    ) {
      NotificationManager.error(
        t("who_we_serve.sport_and_entertainment_industry.error_msg"),
        t("who_we_serve.sport_and_entertainment_industry.error"),
        3000
      );

      return false;
    }

    const data = {
      industry: "Sport & Entertainment",
      category: venuetype === "Any" ? "golf_venue" : venuetype,
      is_demo: true,
      restaurant_country: restaurant_country,
      restaurant_zipcode: restaurant_zipcode,
      restaurant_state: restaurant_state,
      restaurant_city: restaurant_city,
      restaurant_name: restaurant_name,
      email: email,
      mobile: mobile,
      last_name: last_name,
      first_name: first_name,
      contact_reason: "I want to schedule a demo of VenueBoost",
    };
    createContactSale(data)
      .then(data => {
        setRestaurantZipcode(null);
        setRestaurantState(null);
        setRestaurantName(null);
        setRestaurantCountry(null);
        setRestaurantCity(null);
        setVenuetype(null);
        setFirstname(null);
        setLastname(null);
        setMobile(null);
        setEmail(null);
        NotificationManager.success(
          data?.message ||
            t("who_we_serve.sport_and_entertainment_industry.success_msg"),
          t("who_we_serve.sport_and_entertainment_industry.success"),
          5000
        );

        setStep(1);
      })
      .catch(error => {
        NotificationManager.error(
          error.message ||
            t("who_we_serve.sport_and_entertainment_industry.error_msg"),
          t("who_we_serve.sport_and_entertainment_industry.error"),
          3000
        );
      });
  };

  return (
    <div className={"align-col-middle ent-industry mt-0"}>
      <Seo
        title={seoTitle + " | VenueBoost"}
        description={seoDescription}
        image={OverviewSportEntertainment}
        url={currentUrl}
      />
      <div className='flex flex-col md:flex-row justify-center overflow-hidden bg-[#F3F3F3] px-4 sm:px-6 md:px-8 lg:px-16 pb-20 w-[100vw] relative md:min-h-[668px]'>
        {/*<div*/}
        {/*    className="hidden md:block w-[900px] h-[900px] absolute -left-[400px] -top-[100px]"*/}
        {/*    style={{*/}
        {/*        background:*/}
        {/*            "radial-gradient(circle at center, rgba(255, 245, 157, 0.7), transparent, transparent)",*/}
        {/*    }}*/}
        {/*></div>*/}

        <div className='xl:!w-[1228px] flex flex-col md:flex-row'>
          <div className='w-full md:w-[50%] flex flex-col md:pr-[58px] py-6 md:py-[44px] z-10'>
            <span className=' text-[32px]  md:text-[60px] leading-[45px] md:leading-[60px] font-extrabold '>
              Museum
            </span>

            <p className='hidden md:block text-[#000000] text-14 md:text-20 font-medium leading-5 md:leading-7 mt-2 md:mt-4 mb-[22px] md:mb-[44px]'>
              VenueBoost enhances museum experiences. Manage ticket sales,
              streamline exhibit schedules, and engage visitors with real-time
              promotions. Gain insights to optimize exhibit layouts and create
              an enriching museum experience for patrons.
            </p>
            <div className='hidden md:block mb-[15px] md:mb-0 mt-10'>
              <a
                href='/get-started'
                className='rounded-md text-white font-bold text-[11px] md:text-18 leading-[11px] md:leading-[18px] px-[17px] md:px-[30px] py-[9px] md:py-[15px] uppercase bg-gradient-to-r from-[#240B3B] via-[#5E1B83] to-[#BC14FE]'
              >
                {t("who_we_serve.food_industry.unlock_free_plan")}
              </a>
            </div>
          </div>
          <div className=' md:block md:w-[50%] md:mt-12  relative align-middle'>
            <img
              className='object-cover md:absolute rounded-[20px] right-[29px] max-h-full'
              src={OverviewSportEntertainment}
            />
          </div>

          <div className='flex flex-col md:hidden mt-6'>
            <p className='text-[#000000] text-14 md:text-20 font-medium leading-5 md:leading-7 mt-2 md:mt-4 mb-[22px] md:mb-[44px]'>
              VenueBoost enhances museum experiences. Manage ticket sales,
              streamline exhibit schedules, and engage visitors with real-time
              promotions. Gain insights to optimize exhibit layouts and create
              an enriching museum experience for patrons.
            </p>
            <div className=' mt-6 md:mt-0'>
              <a
                href='/get-started'
                className='flex w-full justify-center rounded-md text-white font-bold text-18 leading-[18px] md:px-[30px] py-[15px] uppercase bg-gradient-to-r from-[#240B3B] via-[#5E1B83] to-[#BC14FE]'
              >
                {t("who_we_serve.food_industry.unlock_free_plan")}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col-reverse md:flex-row mt-6 md:mt-[38px]'>
        <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0'>
          <img src={OverviewImg1} />
        </div>

        <div className='w-full md:w-[50%] md:p-12 md:pr-0  flex-col'>
          <h1 className='text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'>
            Bookings Management
          </h1>
          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            Optimized Entertainment Bookings
          </p>

          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            VenueBoost provides amusement parks, cinemas, golf courses,
            theaters, museums, and more with robust tools to seamlessly manage
            reservations and ticketing.
          </p>
          <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
            {[
              "Easily create bookings specifying dates, tickets, guests",
              "Dashboard to view, modify, and organize reservations",
              "RSVP features for smooth communication",
              "Gain insights into attendance patterns",
            ].map(item => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className='text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4'>
            VenueBoost offers complete, customized reservations management.
          </p>
          <FreeTrial />
        </div>
      </div>
      <div className='flex flex-col-reverse md:flex-row-reverse mt-10 md:mt-[38px] '>
        <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0'>
          <img src={OverviewImg2} />
        </div>
        <div className='w-full md:w-[40%] md:p-4 md:pl-0  '>
          <h1 className='text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'>
            {t(
              "who_we_serve.sport_and_entertainment_industry.inventory_management"
            )}{" "}
          </h1>
          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            Streamlined Equipment & Inventory
          </p>
          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            {t(
              "who_we_serve.sport_and_entertainment_industry.inventory_management_sub"
            )}
          </p>
          <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
            {[
              t(
                "who_we_serve.sport_and_entertainment_industry.inventory_management_tracking"
              ),
              t(
                "who_we_serve.sport_and_entertainment_industry.inventory_management_update"
              ),
              t(
                "who_we_serve.sport_and_entertainment_industry.inventory_management_trend"
              ),
              t(
                "who_we_serve.sport_and_entertainment_industry.inventory_management_view"
              ),
              t(
                "who_we_serve.sport_and_entertainment_industry.inventory_management_lifecycle"
              ),
            ].map(item => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className='text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4'>
            Get the insights you need to keep venues running smoothly.
            VenueBoost simplifies inventory.
          </p>
          <FreeTrial />
        </div>
      </div>

      <div className='flex flex-col-reverse md:flex-row mt-6 md:mt-[38px]'>
        <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0'>
          <img src={OverviewImg3} />
        </div>
        <div className='w-full md:w-[50%] md:p-12 md:pr-0  flex-col'>
          <h1
            className='text-[#240b3b] text-22 align-left md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'
            style={{alignSelf: "left"}}
          >
            {t("who_we_serve.sport_and_entertainment_industry.staff_managment")}
          </h1>
          <p className='text-[#666666] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
            Simplified Scheduling and Payroll
          </p>
          <p className='text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
            {t(
              "who_we_serve.sport_and_entertainment_industry.staff_managment_sub"
            )}
          </p>
          <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
            {[
              "Automated scheduling based on events",
              "Track time-off requests",
              "Integrated payroll processing",
              "Performance monitoring to identify coaching opportunities",
              "Coordinate staff for seamless operations",
            ].map(item => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className='text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4'>
            VenueBoost streamlines team management for optimal operations.
          </p>
          <FreeTrial />
        </div>
      </div>
      <div className='flex flex-col-reverse md:flex-row-reverse mt-10 md:mt-[38px] '>
        <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0'>
          <img src={OverviewImg4} />
        </div>
        <div className='w-full md:w-[40%] md:p-12 md:pl-0   '>
          <h1 className='text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'>
            {t("who_we_serve.sport_and_entertainment_industry.marketing")}{" "}
          </h1>
          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            Expanding Your Brand and Reach
          </p>
          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            {t("who_we_serve.sport_and_entertainment_industry.marketing_sub")}
          </p>
          <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
            {[
              t(
                "who_we_serve.sport_and_entertainment_industry.marketing_email"
              ),
              t(
                "who_we_serve.sport_and_entertainment_industry.marketing_promotion"
              ),
              t(
                "who_we_serve.sport_and_entertainment_industry.marketing_campaigns"
              ),
              t(
                "who_we_serve.sport_and_entertainment_industry.marketing_refferals"
              ),
              "Coordinate staff for seamless operations",
            ].map(item => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className='text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4'>
            Consolidate your entertainment marketing. VenueBoost brings your
            tools together.
          </p>
          <FreeTrial />
        </div>
      </div>

      <div className='flex flex-col-reverse md:flex-row mt-6 md:mt-[38px]'>
        <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0'>
          <img src={OverviewImg5} />
        </div>
        <div className='w-full md:w-[50%] md:p-12 md:pr-0  flex-col'>
          <h1
            className='text-[#240b3b] text-22 align-left md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'
            style={{alignSelf: "left"}}
          >
            {t("who_we_serve.sport_and_entertainment_industry.loyalty")}
          </h1>
          <p className='text-[#666666] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
            Building Guest Loyalty and Retention
          </p>
          <p className='text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
            {t("who_we_serve.sport_and_entertainment_industry.loyalty_sub")}
          </p>
          <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
            {[
              "Build special tiers and benefits for loyal members",
              "View purchase history and activity trends",
              "Send tailored rewards and offers",
              "Analyze engagement across tiers",
            ].map(item => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className='text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4'>
            Strengthen relationships with return guests. VenueBoost provides the
            tools to build loyalty, increase retention, and encourage repeat
            visits.
          </p>
          <FreeTrial />
        </div>
      </div>
      <div className='flex flex-col-reverse md:flex-row-reverse mt-10 md:mt-[38px] '>
        <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0'>
          <img src={SportEntertainmentDashboardOverview} />
        </div>
        <div className='w-full md:w-[40%] md:p-12 md:pl-0   '>
          <h1 className='text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'>
            {t("who_we_serve.sport_and_entertainment_industry.reporting")}
          </h1>
          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            Actionable Entertainment Insights
          </p>

          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            {t("who_we_serve.sport_and_entertainment_industry.reporting_sub")}
          </p>
          <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
            {[
              t(
                "who_we_serve.sport_and_entertainment_industry.reporting_sales"
              ),
              t(
                "who_we_serve.sport_and_entertainment_industry.reporting_hours"
              ),
              t(
                "who_we_serve.sport_and_entertainment_industry.reporting_metrics"
              ),
              t(
                "who_we_serve.sport_and_entertainment_industry.reporting_insight"
              ),
              t(
                "who_we_serve.sport_and_entertainment_industry.reporting_courses"
              ),
            ].map(item => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className='text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4'>
            Stop relying on guesswork. VenueBoost reporting provides the clarity
            to optimize your venue.
          </p>
          <FreeTrial />
        </div>
      </div>

      <div className='flex flex-col-reverse md:flex-row mt-6 md:mt-[78px]'>
        <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0'>
          <img src={OverviewImg7} />
        </div>
        <div className='w-full md:w-[50%] md:p-12 md:pr-0  flex-col'>
          <h1
            className='text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'
            style={{alignSelf: "left"}}
          >
            {t("who_we_serve.sport_and_entertainment_industry.payment_links")}
          </h1>
          <p className='text-[#666666] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
            Streamlined Online Ticketing Payments
          </p>
          <p className='text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
            {t(
              "who_we_serve.sport_and_entertainment_industry.payment_links_sub"
            )}
          </p>
          <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
            {[
              "Securely accept ticket purchases and deposits",
              "Organized link list to track transactions",
              "Insights into sales volumes and revenue",
              "Convenient, integrated booking payments",
            ].map(item => (
              <CheckLine text={item} />
            ))}
          </div>
          <FreeTrial />
        </div>
      </div>
      <div className='flex flex-col-reverse md:flex-row-reverse mt-10 md:mt-[78px] '>
        <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0'>
          <img src={SportEntertainmentGuestsManagement} />
        </div>
        <div className='w-full md:w-[40%] md:p-12 md:pl-0   '>
          <h1 className='text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'>
            {t(
              "who_we_serve.sport_and_entertainment_industry.customer_management"
            )}{" "}
          </h1>
          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            Personalized Interactions, Loyal Relationships
          </p>

          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            VenueBoost equips entertainment businesses to engage visitors,
            manage bookings, and provide personalized experiences.
          </p>
          <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
            {[
              t(
                "who_we_serve.sport_and_entertainment_industry.customer_management_profiles"
              ),
              t(
                "who_we_serve.sport_and_entertainment_industry.customer_management_preference"
              ),
              t(
                "who_we_serve.sport_and_entertainment_industry.customer_management_history"
              ),
              "Custom loyalty programs with special tiers",
            ].map(item => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className='text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4'>
            Deliver exceptional visits by truly understanding each guest.
            VenueBoost helps you attract, engage, and retain visitors.
          </p>
          <FreeTrial />
        </div>
      </div>

      <div className='flex flex-col-reverse md:flex-row mt-0 md:mt-[58px]'>
        <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0'>
          <img src={OverviewFoodGuestsManagement} />
        </div>
        <div className='w-full md:w-[50%] md:p-0 md:pr-0  flex-col'>
          <h1
            className='text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'
            style={{alignSelf: "left"}}
          >
            Guest Surveys and Ratings
          </h1>

          <p className='text-[#666666] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
            Gather Valuable Feedback
          </p>
          <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
            {[
              "VenueBoost now offers powerful Surveys and Ratings to help venues enhance guest experiences and gather crucial feedback.",
              "Create customized guest surveys",
              "Collect ratings and reviews to gauge satisfaction",
              "Gain insights into preferences and opinions",
              "Make informed improvements using data-driven decisions",
            ].map(item => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className='text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4'>
            Listen to your guests and optimize offerings with VenueBoost's
            Surveys and Ratings.
          </p>
          <FreeTrial />
        </div>
      </div>
      <div className='flex flex-col-reverse md:flex-row-reverse mt-10 md:mt-[38px] '>
        <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0'>
          <img src={OverviewImg8} />
        </div>
        <div className='w-full md:w-[40%] md:p-12 md:pl-0   '>
          <h1 className='text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'>
            Affiliate Partnerships{" "}
          </h1>
          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            Expand Your Reach and Revenue
          </p>

          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            With VenueBoost's new Affiliates feature, you can grow your business
            and revenue by partnering with like-minded organizations.
          </p>
          <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
            {[
              "Establish affiliate partnerships seamlessly",
              "Track referrals and commissions in real-time",
              "Access valuable marketing resources",
              "Extend your brand's reach through strategic collaborations",
            ].map(item => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className='text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4'>
            Join forces with affiliates and unlock new opportunities with
            VenueBoost's Affiliates.
          </p>
          <FreeTrial />
        </div>
      </div>

      {/*<div className={"md:px-8 px-4 mt-10 md:mt-10"}>*/}
      {/*  <div*/}
      {/*    className="tabs-container"*/}
      {/*    role="tablist"*/}
      {/*    aria-label="Products"*/}
      {/*    data-tracking-container="Product Tabs"*/}
      {/*  >*/}
      {/*    {tabs.map((tab, index) => (*/}
      {/*      <button*/}
      {/*        key={index}*/}
      {/*        className={`tab js-tab ${activeTab === index ? "is-active" : ""}`}*/}
      {/*        role="tab"*/}
      {/*        aria-selected={activeTab === index}*/}
      {/*        aria-controls={`tab-content-${index}`}*/}
      {/*        tabIndex="0"*/}
      {/*        onClick={() => setActiveTab(index)}*/}
      {/*      >*/}
      {/*        {tab.title}*/}
      {/*      </button>*/}
      {/*    ))}*/}
      {/*  </div>*/}
      {/*  <div className="tabs-content" data-tracking-container="Tab Content">*/}
      {/*    {tabs.map((tab, index) => (*/}
      {/*      <div*/}
      {/*        key={index}*/}
      {/*        className={`tab tab-content__panel js-tab-panel ${*/}
      {/*          activeTab === index ? "is-active" : ""*/}
      {/*        }`}*/}
      {/*        role="tabpanel"*/}
      {/*        id={`tab-content-${index}`}*/}
      {/*        aria-labelledby={`tab-${index}`}*/}
      {/*        aria-hidden={activeTab !== index}*/}
      {/*        tabIndex="0"*/}
      {/*      >*/}
      {/*        {tab.content}*/}
      {/*      </div>*/}
      {/*    ))}*/}
      {/*  </div>*/}
      {/*</div>*/}

      {/*<div className="w-full">*/}
      {/*  <VideoPlayer venueText={"Sports and Entertainment"} />*/}
      {/*</div>*/}

      {/*<div className="flex   bg-[#F9F7F7] flex-col mt-10 md:mt-10">*/}
      {/*  <p className="text-20 md:text-[39px] leading-[26px] md:leading-[48px] font-bold text-center my-10  md:mb-20 md:mt-20 w-[80%] md:w-full mx-auto">*/}
      {/*    {t("who_we_serve.sport_and_entertainment_industry.right_price")}*/}
      {/*  </p>*/}
      {/*  <Pricing hasMoreBtn={true} />*/}
      {/*</div>*/}

      <Grow
        venueColor={"#F4D35E"}
        title={"Additional Entertainment Venues Features"}
        subtitle={
          "Dive into VenueBoost's Expanded Capabilities for the Entertainment Venues Industry"
        }
        tabs={tabs}
      />

      <div className='align-col-middle '>
        <div className='w-[100vw] bg-[#240B3B]'>
          <div className='max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 '>
            <div className='flex flex-col align-middle w-full'>
              <h1 className='text-white text-26 md:text-[42px] text-center font-extrabold leading-[60px] mt-10 md:mt-20 '>
                {t("who_we_serve.sport_and_entertainment_industry.faq")}
                <span className='text-white'>
                  {" "}
                  (
                  {t("who_we_serve.sport_and_entertainment_industry.faq_short")}
                  ){" "}
                </span>
              </h1>
              <p className='text-14 md:text-20 text-white px-7 leading-5 font-medium mt-4 mb:mt-20 mb-5 md:mb-[66px]'>
                {t(
                  "who_we_serve.sport_and_entertainment_industry.sport_entertainment_industry"
                )}
              </p>
              <Faq faqData={generalFaq} className='mt-2 mb-4 md:mb-16' />
            </div>
          </div>
        </div>
      </div>
      <Advantage venueDefinition={"sports & entertainment industry"} />
      <div className='align-col-middle'>
        <div className='w-[100vw] bg-white'>
          <div className='mx-auto max-w-screen-main px-4 sm:px-6 md:px-8 lg:px-16'>
            <div className='flex flex-col align-middle mb-4 md:mb-20'>
              <FreePlanCard />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Museum;
