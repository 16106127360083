export const contactReason = [
  { value: "", title: "Please select" },
  { value: "Access Free Plan", title: "Access Free Plan" },
  {
    value: "Request Personalized Consultation",
    title: "Request Personalized Consultation",
  },
  { value: "Subscribe to Updates", title: "Subscribe to Updates" },
];

export const howDidYouHearOptions = [
  { value: "", title: "Please select" },
  { value: "Email", title: "Email" },
  { value: "Social media", title: "Social media" },
  { value: "Search engines", title: "Search engines" },
  { value: "Ads", title: "Ads" },
  { value: "Referral", title: "Referral" },
  { value: "From a friend", title: "From a friend" },
  { value: "Other", title: "Other" },
];

export const businessChallenges = [
  { value: "", title: "Please select" },
  { value: "Staffing Issues", title: "Staffing Issues" },
  { value: "Sales and Marketing", title: "Sales and Marketing" },
  { value: "Inventory Management", title: "Inventory Management" },
  { value: "Operational Efficiency", title: "Operational Efficiency" },
  {
    value: "Brand Development and Positioning",
    title: "Brand Development and Positioning",
  },
  {
    value: "Customer Engagement and Retention",
    title: "Customer Engagement and Retention",
  },
  { value: "Other", title: "Other" },
];

export const socialMediaOptions = [
  { label: "Facebook", value: "facebook" },
  { label: "Twitter", value: "twitter" },
  { label: "Instagram", value: "instagram" },
  { label: "TikTok", value: "tiktok" },
];

export const featuresByIndustryAndPlan = {
  food: {
    Launch: [
      "Includes all from Discover plus:",
      "Unlimited Streamlined Reservations",
      "Full Inventory Management",
      "Staff Management",
      "Enhanced Marketing Strategy tools",
      "Loyalty and Retention Program",
      "Payment Links",
      "Basic Guest Surveys and Ratings",
    ],
    Elevate: [
      "Includes all from Launch plus:",
      "Marketing Automation",
      "Affiliate Partnerships",
      "Advanced Analytics & Reporting",
      "Delivery Orders management",
      "Advanced Guest Behavior Analytics",
    ],
    Optimize: [
      "Includes all from Elevate plus:",
      "Premium Tables with Pricing / Bidding and Floorplan Visibility",
      "Dining Guest Loyalty Program",
      "Customizable Brand Profile",
      "In-Person Payments",
    ],
  },
  accommodation: {
    Launch: [
      "Includes all from Discover plus:",
      "Unlimited Bookings Management",
      "Unlimited Units/Rooms Management",
      "Full Inventory Management",
      "Staff Management",
      "Enhanced Marketing Tools",
      "Basic Loyalty and Retention Program",
      "Payment Links",
      "Basic Guest Surveys and Ratings",
    ],
    Elevate: [
      "Includes all from Launch plus:",
      "Marketing Automation including Guest Journey support",
      "Advanced Analytics & Reporting",
      "iCal Integration",
      "Basic Affiliate Partnerships",
      "Advanced Guest Behavior Analytics",
    ],
    Optimize: [
      "All Elevate Plan features with higher limits",
      "Accommodation Guest Loyalty Program",
      "Housekeeping Management with Real-Time Updates\n" + "\n",
      "Advanced Affiliate Partnerships",
      "Customizable Brand Profile",
      "In-Person Payments",
    ],
  },

  sport_entertainment: {
    Launch: [
      "Includes all from Discover plus:",
      "Unlimited Bookings Management",
      "Full Inventory Management",
      "Staff Management",
      "Enhanced Marketing Tools",
      "Basic Loyalty and Retention Program",
      "Basic Payment Links",
    ],
    Elevate: [
      "Includes all from Launch plus:",
      "Marketing Automation",
      "Entertainment Membership Program",
      "Advanced Analytics & Reporting",
      "Customer Surveys and Ratings",
      "Basic Affiliate Partnerships",
    ],
    Optimize: [
      "Includes all from Elevate plus:",
      "Advanced Customer Behavior Analytics",
      "Advanced Marketing Tools",
      "Advanced Affiliate Partnerships",
      "Customizable Brand Profile",
      "In-Person Payments",
    ],
  },

  retail_management: {
    Launch: [
      "Includes all from Discover plus",
      "Unlimited Order Management",
      "Full Inventory Management",
      "Staff Management",
      "Enhanced Marketing Tools",
      "Basic Loyalty and Retention Program",
    ],
    Elevate: [
      "Includes all from Launch plus:",
      "Marketing Automation",
      "Retail Customer Loyalty Program",
      "Advanced Dashboard & Revenue Analytics",
      "Consistent Inventory",
      "Basic Affiliate Partnerships",
      "Customer Surveys and Ratings",
    ],
    Optimize: [
      "Includes all from Elevate plus:",
      "Advanced Order Management",
      "Advanced Customer Behavior Analytics",
      "Centralized Analytics for Multi-Brand Retailers",
      "Advanced Store Management",
      "Customizable Brand Profile",
      "In-Person Payments",
    ],
  },
};

export const countryList = [
  { value: "United States", title: "United States" },
  // { value: "Italy", title: "Italy" },
  // { value: "Montenegro", title: "Montenegro" },
];

export const industryList = [
  { value: "", title: "Please select" },
  { value: "Food", title: "Food & Beverage" },
  { value: "Sport & Entertainment", title: "Entertainment Venues" },
  { value: "Accommodation", title: "Accommodation" },
  { value: "Retail", title: "Retail Management" },
  // { value: "Healthcare", title: "Healthcare" },
];

export const categoryList = {
  Food: [
    { value: "", title: "Please select" },
    { value: "restaurant", title: "Restaurant" },
    { value: "bistro", title: "Bistro" },
    { value: "cafe", title: "Cafe" },
    { value: "bar", title: "Bar" },
    { value: "pub_club", title: "Pub & Club" },
    { value: "food_truck", title: "Food Truck" },
    { value: "fine_dining", title: "Fine Dining" },
    { value: "bakery", title: "Bakery" },
    { value: "catering_service", title: "Catering Service" },
    { value: "fast_food", title: "Fast Food" },
    { value: "ice_cream_parlor", title: "Ice Cream Parlor" },
    { value: "food_stall", title: "Food Stall" },
    { value: "buffet", title: "Buffet" },
    { value: "pop_up_dining", title: "Pop-Up Dining" },
    { value: "juice_bar", title: "Juice Bar" },
    { value: "food_hall", title: "Food Hall" },
    { value: "supper_club", title: "Supper Club" },
    { value: "pizzeria", title: "Pizzeria" },
    { value: "coffee_shop", title: "Coffee Shop" },
    { value: "delis", title: "Delis" },
    { value: "food_court", title: "Food Court" },
  ],
  "Sport & Entertainment": [
    { value: "", title: "Please select" },
    { value: "golf_venue", title: "Golf Course" },
    { value: "bowling", title: " Bowling Alley" },
    { value: "gym", title: "Gym & Fitness Center" },
    { value: "arcade_and_game_center", title: "Arcade & Game Center" },
    { value: "sports_arena", title: "Sports Arena" },
    { value: "concert_hall_and_theater", title: "Concert Hall & Theater" },
    { value: "amusement_and_theme_park", title: "Amusement & Theme Park" },
    { value: "ski_resort", title: "Ski Resort" },
    { value: "museum", title: "Museum" },
    { value: "cinema", title: "Cinema" },
  ],
  Accommodation: [
    { value: "", title: "Please select" },
    { value: "hotel", title: "Hotel" },
    { value: "cozy_retreat", title: "Cozy Retreat" },
    { value: "hostel", title: "Hostel" },
    { value: "hotel_chain", title: "Hotel Chain" },
    { value: "vacation_rental", title: "Vacation Rental" },
    { value: "luxury_resort", title: " Luxury Resort" },
    { value: "family_resort", title: "Family Resorts" },
    { value: "service_apartment", title: "Service Apartment" },
    { value: "bed_and_breakfast", title: "Bed and Breakfast" },
    { value: "motel", title: "Motel" },
    { value: "capsule_hotel", title: "Capsule Hotel" },
    { value: "youth_hostel", title: "Youth Hostel" },
    { value: "campground", title: "Campground" },
    { value: "rv_park", title: "RV Park" },
  ],
  Retail: [
    { value: "", title: "Please select" },
    { value: "sport_essentials", title: "Sporting Goods Store" },
    { value: "home_decor", title: "Home Décor Store" },
    { value: "fashion_threads", title: "Fashion Boutique" },
    { value: "retail_chain", title: "Retail Chain" },
    { value: "specialty_store", title: "Specialty Store" },
    { value: "online_shop", title: "Online Shop" },
    { value: "grocery_store", title: "Grocery Store" },
    { value: "electronics_store", title: "Electronics Store" },
    { value: "pharmacy", title: "Pharmacy" },
    { value: "auto_motorcycle_parts", title: "Auto Parts Store" },
    { value: "liquor_store", title: "Liquor Store" },
    { value: "pet_store", title: "Pet Store" },
  ],
};

export const pricingData = {
  food: {
    monthly: {
      free: 0,
      freeSubtitle:
        "Access essential features at no cost to experience the foundational benefits of VenueBoost.",
      launch: 49,
      launchSubtitle:
        "Ideal for small businesses, this plan offers expanded functionality to enhance your operational efficiency.",
      elevate: 129,
      elevateSubtitle:
        "Scale your business seamlessly with advanced tools and analytics tailored for expanding businesses.",
      optimize: 249,
      optimizeSubtitle:
        "Unlock the full potential of VenueBoost with sophisticated features.",
    },
    annual: {
      free: 0,
      freeSubtitle:
        "Access essential features at no cost to experience the foundational benefits of VenueBoost.",
      launch: 490,
      launchSubtitle:
        "Ideal for small businesses, this plan offers expanded functionality to enhance your operational efficiency.",
      elevate: 1290,
      elevateSubtitle:
        "Scale your business seamlessly with advanced tools and analytics tailored for expanding businesses.",
      optimize: 2490,
      optimizeSubtitle:
        "Unlock the full potential of VenueBoost with sophisticated features.",
    },
  },
  sport_entertainment: {
    monthly: {
      free: 0,
      freeSubtitle:
        "Access essential features at no cost to experience the foundational benefits of VenueBoost.",
      launch: 79,
      launchSubtitle:
        "Get started with essential tools to manage bookings and engage your audience.",
      elevate: 169,
      elevateSubtitle:
        "Scale your business seamlessly with advanced tools and analytics tailored for expanding businesses.",
      optimize: 329,
      optimizeSubtitle:
        "Unlock the full potential of VenueBoost with sophisticated features.",
    },
    annual: {
      free: 0,
      freeSubtitle:
        "Access essential features at no cost to experience the foundational benefits of VenueBoost.",
      launch: 790,
      launchSubtitle:
        "Get started with essential tools to manage bookings and engage your audience.",
      elevate: 1690,
      elevateSubtitle:
        "Scale your business seamlessly with advanced tools and analytics tailored for expanding businesses.",
      optimize: 3290,
      optimizeSubtitle:
        "Unlock the full potential of VenueBoost with sophisticated features.",
    },
  },
  accommodation: {
    monthly: {
      free: 0,
      freeSubtitle:
        "Access essential features at no cost to experience the foundational benefits of VenueBoost.",
      launch: 59,
      launchSubtitle:
        "Ideal for small businesses, this plan offers expanded functionality to enhance your operational efficiency.",
      elevate: 149,
      elevateSubtitle:
        "Unlock a comprehensive set of features to enhance guest experiences and streamline operations.",
      optimize: 299,
      optimizeSubtitle:
        "Access advanced capabilities to optimize guest services and boost revenue",
    },
    annual: {
      free: 0,
      freeSubtitle:
        "Access essential features at no cost to experience the foundational benefits of VenueBoost.",
      launch: 590,
      launchSubtitle:
        "Ideal for small businesses, this plan offers expanded functionality to enhance your operational efficiency.",
      elevate: 1490,
      elevateSubtitle:
        "Unlock a comprehensive set of features to enhance guest experiences and streamline operations.",
      optimize: 2990,
      optimizeSubtitle:
        "Access advanced capabilities to optimize guest services and boost revenue",
    },
  },
  retail: {
    monthly: {
      free: 0,
      freeSubtitle:
        "Access essential features at no cost to experience the foundational benefits of VenueBoost.",
      launch: 69,
      launchSubtitle:
        "Begin your retail journey with essential tools for inventory management and customer engagement.",
      elevate: 159,
      elevateSubtitle:
        "Gain access to a comprehensive suite of features to streamline inventory and sales.",
      optimize: 319,
      optimizeSubtitle:
        "Access advanced capabilities to optimize guest services and boost revenue",
    },
    annual: {
      free: 0,
      freeSubtitle:
        "Access essential features at no cost to experience the foundational benefits of VenueBoost.",
      launch: 690,
      launchSubtitle:
        "Begin your retail journey with essential tools for inventory management and customer engagement.",
      elevate: 1590,
      elevateSubtitle:
        "Gain access to a comprehensive suite of features to streamline inventory and sales.",
      optimize: 3190,
      optimizeSubtitle:
        "Unlock advanced capabilities to manage retail operations and boost profitability.",
    },
  },
};
