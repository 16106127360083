import React, {useEffect, useState} from "react";
import FormTextInput from "../../components/FormTextInput";
// import BannerImg from "../../assets/images/contactus/bannerimg.png";
// import FormImg from "../../assets/images/contactus/formimg.png";
import FormTextarea from "../../components/FormTextarea";
// import { Seo } from "../../components/Seo";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import {Spin} from "antd";
import * as yup from "yup";
import FormSelect from "../../components/FormSelect";
// import CustomSelect from "../../components/CustomSelect";
import {addCartBillingDetails} from "../../redux/actions/cart";

const validator = yup.object().shape({
  first_name: yup
    .string()
    .trim()
    .required("First name is required")
    .max(72, "Max exceeded")
    .min(2, "Min not met"),
  last_name: yup
    .string()
    .trim()
    .required("Last name is required")
    .max(72, "Max exceeded")
    .min(2, "Min not met"),
  email: yup
    .string()
    .trim()
    .required("Email is required")
    .max(72, "Max exceeded")
    .min(5, "Min not met")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email"
    ),
  phone: yup.string().trim().required("Phone number is required"),

  state: yup.string().trim().required("State is required"),
  address_line1: yup
    .string()
    .trim()
    .required("Address Line 1 is required")
    .max(250, "Max exceeded")
    .min(6, "Min not met"),
  address_line2: yup.string().trim(),
  city: yup.string().required("City is required"),
  postcode: yup.string().required("Postal code is required"),
  company_name: yup.string(),
  additional_notes: yup.string(),
});

const stateList = [
  {value: "New York", title: "New York"},
  {value: "Tirana", title: "Tirana"},
];

const BillingInformationScreen = () => {
  const [data, setData] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {app_key} = useParams();
  const [loading, setLoading] = useState(false);

  const {billing_detail} = useSelector(state => state.cart);

  const formikProps = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      address_line1: "",
      address_line2: "",
      state: "",
      city: "",
      postcode: "",
      company_name: "",
      additional_notes: "",
    },
    validationSchema: validator,
    onSubmit: values => onSubmit(values),
    validateOnChange: true,
    validateOnBlur: false,
    validateOnMount: true,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (billing_detail) {
      formikProps.setValues(billing_detail);
    }
  }, []);

  const onSubmit = values => {
    window.scrollTo(0, 0);

    dispatch(addCartBillingDetails(values));
    navigate(`/checkout`);
    // setLoading(true);
    // registerRestaurant(payload)
    //   .then((data) => {
    //     setLoading(false);
    //     dispatch(setRestaurantData(data?.restaurant));
    //     NotificationManager.success(
    //       "Venue Registration successfully completed. Please check your email for verification",
    //       "Success",
    //       3000
    //     );
    //     navigate(`/${token}/apply/complete-registration/verify-email`);
    //   })
    //   .catch((error) => {
    //     setLoading(false);
    //     NotificationManager.error(
    //       error.message || "Something went wrong!",
    //       "Error",
    //       3000
    //     );
    //   });
  };
  return (
    <div className={"align-col-middle view-terms my-10"}>
      <div className='w-full sm:w-[80%] rounded-4xl p-14 gap-10 mt-10 shadow-2xl'>
        <Spin spinning={loading} />
        <div className='relative started-form'>
          <hr />
          <div className='grid md:grid-cols-2 grid-cols-1 flex-col mt-8 bg-white rounded-3xl gap-3 md:gap-6'>
            <div className='flex flex-col gap-6'>
              <div className='flex flex-col justify-center pr-10 mb-2'>
                <p className='md:text-3xl text-2xl text-primary1 font-semibold'>
                  Shipping Details
                </p>
              </div>
              <FormTextInput
                placeholder='First Name'
                value={formikProps.values.first_name}
                error={formikProps.errors.first_name}
                onChange={e => {
                  formikProps.setFieldValue("first_name", e.target.value);
                }}
              />
              <FormTextInput
                placeholder='Last Name'
                value={formikProps.values.last_name}
                error={formikProps.errors.last_name}
                onChange={e => {
                  formikProps.setFieldValue("last_name", e.target.value);
                }}
              />
              <FormTextInput
                placeholder='Company Name (optional)'
                value={formikProps.values.company_name}
                error={formikProps.errors.company_name}
                onChange={e => {
                  formikProps.setFieldValue("company_name", e.target.value);
                }}
              />
              <FormTextInput
                placeholder='Address Line 1'
                value={formikProps.values.address_line1}
                error={formikProps.errors.address_line1}
                onChange={e => {
                  formikProps.setFieldValue("address_line1", e.target.value);
                }}
              />
              <FormTextInput
                placeholder='Address Line 2'
                type={"email"}
                value={formikProps.values.address_line2}
                error={formikProps.errors.address_line2}
                onChange={e => {
                  formikProps.setFieldValue("address_line2", e.target.value);
                }}
              />
              <FormSelect
                name={"state"}
                placeholder='State'
                value={formikProps.values.state}
                error={formikProps.errors.state}
                dataList={stateList}
                onChanage={e => {
                  formikProps.setFieldValue("state", e.target.value);
                }}
              />
              {/* <FormTextInput
                                placeholder="State"
                                value={formikProps.values.state}
                                error={formikProps.errors.state}
                                onChange={(e) => {
                                    formikProps.setFieldValue("state", e.target.value);
                                }}
                            /> */}
              <FormTextInput
                placeholder='Town / City'
                type={"email"}
                value={formikProps.values.city}
                error={formikProps.errors.city}
                onChange={e => {
                  formikProps.setFieldValue("city", e.target.value);
                }}
              />
              <FormTextInput
                placeholder='Postal Code'
                value={formikProps.values.postcode}
                error={formikProps.errors.postcode}
                onChange={e => {
                  formikProps.setFieldValue("postcode", e.target.value);
                }}
              />
              <FormTextInput
                placeholder='Phone Number'
                value={formikProps.values.phone}
                error={formikProps.errors.phone}
                onChange={e => {
                  formikProps.setFieldValue("phone", e.target.value);
                }}
              />
              <FormTextInput
                placeholder='Email'
                type={"email"}
                value={formikProps.values.email}
                error={formikProps.errors.email}
                onChange={e => {
                  formikProps.setFieldValue("email", e.target.value);
                }}
              />
              <div className='flex flex-row justify-start align-center gap-4 w-full'>
                <button
                  className=' bg-white text-primary1 rounded-md border-2 border-primary1 font-normal text-18 mt-5 px-10 inline'
                  onClick={() => navigate(-1)}
                >
                  Back
                </button>
                <button
                  className='bg-primary1 text-white rounded-md  mt-5 w-max px-10 text-16'
                  onClick={formikProps.handleSubmit}
                >
                  Provide payment
                </button>
              </div>
            </div>
            <div className='flex flex-col gap-6'>
              <div className='flex flex-col justify-center pr-10 mb-2'>
                <p className='md:text-3xl text-2xl text-primary1 font-semibold'>
                  Notes
                </p>
              </div>
              <FormTextarea
                placeholder='Order Notes (optional)'
                rows={5}
                value={formikProps.values.additional_notes}
                error={formikProps.errors.additional_notes}
                onChange={e => {
                  formikProps.setFieldValue("additional_notes", e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingInformationScreen;
