import React from 'react';
import {isMobile} from "react-device-detect";

export default function HowItWork() {
  return (
      <div className='flex flex-col gap-4 xl:gap-10'>
        <div className='bg-gradient-to-r from-[#280B3B] via-[#5D1C82] to-[#BC14FE] p-[2px] rounded-xl'>
          <div className='flex flex-col p-4 xl:p-12 rounded-xl bg-[#f3f3f3]'>
          <span className='text-xl xl:text-26 font-black'>
            How it works
          </span>
            {!isMobile && <div className='grid grid-cols-1 xl:grid-cols-3 mt-5 xl:mt-12 gap-4'>
              <div className='flex flex-col gap-2 border-r-0 border-b-2 xl:border-r-2 xl:border-b-0 border-[#BC14FE] pr-0 pb-4 xl:pb-0 xl:pr-4'>
                <div className='flex flex-row justify-between'>
                  <div className='flex flex-row xl:flex-col text-lg font-bold'>
                    <span>Step 1: Join the</span>
                    <span>Affiliate Program</span>
                  </div>
                  <span className='text-lg font-black'>01.</span>
                </div>
                <span className='text-base'>
              Sign up through the affiliate portal on VenueBoost's website. Provide information about your business and audience reach.
              </span>
              </div>
              <div className='flex flex-col gap-2 border-r-0 border-b-2 xl:border-r-2 xl:border-b-0 border-[#BC14FE] pr-0 pb-4 xl:pb-0 xl:pr-4'>
                <div className='flex flex-row justify-between'>
                  <div className='flex flex-row xl:flex-col text-lg font-bold'>
                    <span>Step 2: Refer VenueBoost</span>
                    <span>To Your Audience</span>
                  </div>
                  <span className='text-lg font-black'>02.</span>
                </div>
                <span className='text-base'>
                Gain access to the resources portal to obtain banners, text links, and promo materials.
                Start sharing VenueBoost with your audience through your website, email, social media, etc.
              </span>
              </div>
              <div className='flex flex-col gap-2'>
                <div className='flex flex-row justify-between'>
                  <div className='flex flex-row xl:flex-col text-lg font-bold'>
                    <span>Step 3: Earn</span>
                    <span>Commissions</span>
                  </div>
                  <span className='text-lg font-black'>03.</span>
                </div>
                <span className='text-base'>
                Become a VenueBoost affiliate partner and earn up to 40% commission
                  payouts when your referred audiences subscribe to VenueBoost.
              </span>
              </div>
            </div>}
            {isMobile && <div className='grid grid-cols-1 xl:grid-cols-3 mt-5 mb-6 xl:mt-12 gap-4'>
              <div className='flex flex-col gap-2 border-r-0 border-b-2 xl:border-r-2 xl:border-b-0 border-primary1 pr-0 pb-4 xl:pb-0 xl:pr-4'>
                <div className='flex flex-row justify-between'>
                  <div className='flex flex-row xl:flex-col text-l font-bold'>
                    <span>Join the Affiliate Program</span>
                  </div>
                  <span className='text-lg font-black'>01.</span>
                </div>
                <span className='text-sm'>
              Sign up through the affiliate portal on VenueBoost's website. Provide information about your business and audience reach.
              </span>
              </div>
              <div className='flex flex-col gap-2 border-r-0 border-b-2 xl:border-r-2 xl:border-b-0 border-primary1 pr-0 pb-4 xl:pb-0 xl:pr-4'>
                <div className='flex flex-row justify-between'>
                  <div className='flex flex-row xl:flex-col text-l font-bold'>
                    <span>Refer VenueBoost To Your Audience</span>
                  </div>
                  <span className='text-lg font-black'>02.</span>
                </div>
                <span className='text-sm'>
                Gain access to the resources portal to obtain banners, text links, and promo materials.
                Start sharing VenueBoost with your audience through your website, email, social media, etc.
              </span>
              </div>
              <div className='flex flex-col gap-2'>
                <div className='flex flex-row justify-between'>
                  <div className='flex flex-row xl:flex-col text-l font-bold'>
                    <span>Earn Commissions</span>
                  </div>
                  <span className='text-lg font-black'>03.</span>
                </div>
                <span className='text-sm'>
                Become a VenueBoost affiliate partner and earn up to 40% commission
                  payouts when your referred audiences subscribe to VenueBoost.
              </span>
              </div>
            </div>}
          </div>
          {/* <div className='flex justify-center'>
            <a href={'/affiliates/register'}>
              <span className='bg-primary1 text-white rounded-lg px-9 py-4 text-sm font-bold m-auto'>
                Get started as an affiliate
              </span>
            </a>
          </div> */}
        </div>
      </div>
  );
}