import React, {useEffect, useState} from "react";
import "./index.css";
import {Seo} from "../../components/Seo";
import {useNavigate, useParams} from "react-router-dom";
import {verifyOnboardingLink} from "../../redux/actions/onboarding";
import {isMobile} from "react-device-detect";

const RateExperience = () => {
  const navigate = useNavigate();
  const {token, venue_name} = useParams();

  const [data, setData] = useState({});

  const navigateToUrl = url => {
    navigate(url);
  };

  const [onboardingEmail, setOnboardingEmail] = useState("");

  useEffect(() => {
    checkOnboardingLink();
  }, []);

  const checkOnboardingLink = () => {
    verifyOnboardingLink({token})
      .then(data => {
        setOnboardingEmail(data?.email);
      })
      .catch(error => {
        // setTimeout(() => {
        //   navigate("/");
        // }, 2000);
      });
  };

  const handleChange = e => {
    const {name, value} = e.target;

    // Update the data state as usual
    setData(data => ({
      ...data,
      [name]: value,
    }));
  };

  return (
    <div className={"align-col-middle "}>
      <Seo title={"Get Started | VenueBoost"} />
      <div className=' w-[100vw] getstarted-bg'>
        <div className='mx-auto max-w-screen-main px-4 sm:px-6 md:px-8 lg:px-8 '>
          <div className='flex md:flex-row justify-center flex-col pt-4 pb-11 h-full'>
            <div className=' w-full md:w-[100%] bg-[#F9F7F7] border-[1px] border-[#E0E0E0] rounded-[13px] p-4 md:p-8 py-6 gap-y-4 gap-x-8'>
              <div
                className='  bg-white rounded-[31px] p-[24px] md:p-[33px] relative'
                style={{height: isMobile ? "851px" : "721px"}}
              >
                <div className='flex justify-start  items-center'>
                  <span className='text-[32px] md:text-32 font-extrabold leading-[50px] my-6]'>
                    Business Details
                  </span>
                </div>
                <p className='text-18 font-semibold text-[#240b3b] my-4'>
                  Welcome to VenueBoost! To ensure we tailor our services to
                  your unique business needs, please provide a few details about
                  your establishment. This information will help us create a
                  more personalized experience for you.
                </p>

                <div className=''>
                  {/*<div className="grid grid-cols-2 gap-[14px]">*/}
                  {/*  <div>*/}
                  {/*    <FormTextInput*/}
                  {/*        name="restaurant_name"*/}
                  {/*        placeholder="Business Name"*/}
                  {/*        value={data?.restaurant_name || ""}*/}
                  {/*        onChange={handleChange}*/}
                  {/*        required={true}*/}
                  {/*    />*/}
                  {/*  </div>*/}
                  {/*  <div className={"mt-5"}>*/}
                  {/*    <FormSelect*/}
                  {/*        name={"venue_type"}*/}
                  {/*        placeholder="Business Type"*/}
                  {/*        value={data?.venue_type || ""}*/}
                  {/*        dataList={data?.venue_industry ? categoryList[data?.venue_industry] : [*/}
                  {/*          { value: "", title: "Please select" },*/}
                  {/*        ]}*/}
                  {/*        onChanage={handleChange}*/}
                  {/*        required={true}*/}
                  {/*    />*/}
                  {/*  </div>*/}
                </div>

                <div className='w-full px-8 justify-between flex absolute bottom-[58px] left-0'>
                  <button
                    // onClick={() => setStep(2)}
                    className='text-[#707070] text-16 px-0 mt-5 leading-[16px]  h-[36px] font-semibold'
                  ></button>
                  <button
                    // onClick={onSubmitBusinessStep}
                    className='bg-[#240b3b]  rounded-[63px] px-[26px] h-[36px] text-white text-16 mt-5 self-end leading-[16px] font-semibold'
                  >
                    Rate
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RateExperience;
