import React from "react";
import "./index.css";
import {Seo} from "../../components/Seo";

import confirmed_success from "../../assets/svgs/confirmed_success_brand.svg";
import {useNavigate, useParams} from "react-router-dom";

const SurveyCompleted = () => {
  const navigate = useNavigate();
  const {token} = useParams();

  return (
    <div className={"align-col-middle "}>
      <Seo title={"Survey Completed | VenueBoost"} />
      <div className=' w-[100vw] getstarted-bg'>
        <div className='mx-auto max-w-screen-main px-4 sm:px-6 md:px-8 lg:px-8 '>
          <div className='flex md:flex-row justify-center flex-col pt-4 pb-11 h-full'>
            <div className=' w-full md:w-[100%] bg-[#F9F7F7] border-[1px] border-[#E0E0E0] rounded-[13px] p-4 md:p-8 py-6 gap-y-4 gap-x-8'>
              <div className='rounded-[31px] p-[24px] md:p-[33px] relative'>
                <div className='flex justify-center'>
                  <img
                    src={confirmed_success}
                    alt='Confirmation Icon'
                    className='w-[200px] h-[200px] py-4'
                  />
                </div>

                <div className='flex justify-center items-center'>
                  <span className='text-[32px] md:text-32 font-extrabold leading-[50px] my-6]'>
                    Survey Completed Successfully
                  </span>
                </div>
                <p className='text-18 font-semibold text-[#240b3b] my-4'>
                  Thank you for taking the time to complete our survey. Your
                  feedback is invaluable to us and will be used to improve our
                  services.
                </p>
                <p className='text-18 font-semibold text-[#240b3b] my-4'>
                  We are currently processing your responses and will take
                  appropriate actions based on your valuable input. We are
                  committed to enhancing your experience with our services.
                </p>
                <p className='text-18 font-semibold text-[#240b3b] my-4'>
                  Should you have any further questions or require assistance,
                  please do not hesitate to contact us at
                  <a
                    href={"mailto:contact@venueboost.io"}
                    style={{
                      color: "#607D8B",
                      textDecoration: "underline",
                      fontWeight: "bold",
                    }}
                  >
                    {" contact@venueboost.io"}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyCompleted;
