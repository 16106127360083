import React from "react";
import "./index.css";
import { Seo } from "../../components/Seo";

import CheckIcon from "../../assets/svgs/why-venueboost-check.svg";

import ComprehensiveIcon from "../../assets/svgs/why-venueboost/comprehensive.svg";
import UserFriendlyIcon from "../../assets/svgs/why-venueboost/user-friendly.svg";
import DataDrivenIcon from "../../assets/svgs/why-venueboost/data-driven.svg";
import UnmatchedIcon from "../../assets/svgs/why-venueboost/unmatched.svg";
import ContinuousIcon from "../../assets/svgs/why-venueboost/continuous.svg";
import CostEffectiveIcon from "../../assets/svgs/why-venueboost/cost-effective.svg";

import BusinessWorldwide from "./business-worldwide";
import FreePlanCard from "../../components/FreePlanCard";
import { t } from "i18next";

export const vbText = (label) => t(`why_vb.${label}`);

const WhyVenueBoost = () => {
  const currentUrl = "https://venueboost.io/why-venueboost";
  const seoTitle = vbText("WhyChooseVenueBoost");
  const seoDescription = vbText("seoDescription");
  return (
    <div className="flex flex-col align-col-middle view-terms ">
      <Seo
        title={seoTitle + " | VenueBoost"}
        description={seoDescription}
        url={currentUrl}
      />
      <div className=" bg-[#F3F3F3] w-[100vw] ">
        <div className="flex flex-col justify-center items-center px-7 md:px-8 lg:px-16">
          <div className="flex flex-col align-middle">
            <h1 className=" mt-24 text-[36px] md:text-[48px] font-extrabold text-center text-transparent bg-clip-text leading-[60px] bg-gradient-to-r from-[#240B3B] via-[#5E1B83] to-[#BC14FE]">
              {vbText("whyVb")}
            </h1>

            <p className="text-[#161616] text-14 md:text-20 leading-6 md:leading-7 font-semibold text-center my-6  ">
              {vbText("HarnessSolutions")}
            </p>
          </div>

          <div className="flex flex-col gap-1">
            <div className="flex flex-col md:flex-row gap-4 justify-center">
              <div className="flex text-14 font-light text-[#323338] items-center gap-2 leading-8 ">
                <img alt="" src={CheckIcon} className=" w-4 h-4" />

                {vbText("IndustrySpecificSolutions")}
              </div>
              <div className="flex text-14 font-light text-[#323338] items-center gap-2 leading-8">
                <img alt="" src={CheckIcon} className=" w-4 h-4" />A Platform
                {vbText("EasyForEveryoneToUse")}
              </div>
              <div className="flex text-14 font-light text-[#323338] items-center gap-2 leading-8">
                <img alt="" src={CheckIcon} className=" w-4 h-4" />
                {vbText("InsightsFromData")}
              </div>
            </div>

            <div className="flex flex-col md:flex-row gap-4 justify-center">
              <div className="flex text-14 font-light text-[#323338] items-center gap-2 leading-8 md:pl-3 md:pr-6">
                <img alt="" src={CheckIcon} className=" w-4 h-4" />
                {vbText("ExceptionalSupport")}
              </div>
              <div className="flex text-14 font-light text-[#323338] items-center gap-2 leading-8">
                <img alt="" src={CheckIcon} className=" w-4 h-4" />
                {vbText("RegularUpdates")}
              </div>
              <div className="flex text-14 font-light text-[#323338] items-center gap-2 leading-8">
                <img alt="" src={CheckIcon} className=" w-4 h-4" />
                {vbText("ValueDrivenPricing")}
              </div>
            </div>
          </div>

          <div>
            <div class="mx-auto flex items-center justify-center my-14">
              <div class="h-[49px] w-[172px] rounded-md bg-gradient-to-r from-[#240B3B] via-[#3D1E59] to-[#BC14FE] p-[1px]">
                <div class="flex h-full w-full items-center justify-center bg-transparent rounded-md">
                  <a
                    class=" text-16 font-semibold text-white uppercase"
                    href="#"
                  >
                    {vbText("UnlockFreePlan")}
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-center md:justify-between md:flex-row md:flex-wrap  gap-6 mt-9 mb-16 md:mt-14 md:mb-28 xl:w-[1228px]">
            <div className="flex flex-col gap-6 w-full md:w-[353px]">
              <img
                alt=""
                src={ComprehensiveIcon}
                className="w-[40px] h-[40px]"
              />
              <div className="flex flex-col gap-4 pr-7">
                <div className="text-[#0D0E10] text-20 font-bold w-[284px]">
                  {vbText("IndustrySpecificSolutions")}
                </div>
                <div className="text-[#2A2B2C] text-16 font-normal leading-28px">
                  {vbText("MeticulouslyCraftedSolutions")}
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-6 w-full md:w-[353px]">
              <img
                alt=""
                src={UserFriendlyIcon}
                className="w-[40px] h-[40px]"
              />
              <div className="flex flex-col gap-4 pr-7">
                <div className="text-[#0D0E10] text-20 font-bold w-[284px]">
                  {vbText("EasyToUsePlatform")}
                </div>
                <div className="text-[#2A2B2C] text-16 font-normal leading-28px">
                  {vbText("IntuitiveInterface")}
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-6 w-full md:w-[353px]">
              <img alt="" src={DataDrivenIcon} className="w-[40px] h-[40px]" />
              <div className="flex flex-col gap-4 pr-7">
                <div className="text-[#0D0E10] text-20 font-bold w-[284px]">
                  {vbText("DataDrivenInsights")}
                </div>
                <div className="text-[#2A2B2C] text-16 font-normal leading-28px">
                  {vbText("LeveragePowerfulAnalytics")}
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-6 w-full md:w-[353px]">
              <img alt="" src={UnmatchedIcon} className="w-[40px] h-[40px]" />
              <div className="flex flex-col gap-4 pr-7">
                <div className="text-[#0D0E10] text-20 font-bold w-[284px]">
                  {vbText("ExceptionalSupportWelcomingCommunity")}
                </div>
                <div className="text-[#2A2B2C] text-16 font-normal leading-28px">
                  {vbText("BenefitDedicatedSupportTeam")}
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-6 w-full md:w-[353px]">
              <img alt="" src={ContinuousIcon} className="w-[40px] h-[40px]" />
              <div className="flex flex-col gap-4 pr-7">
                <div className="text-[#0D0E10] text-20 font-bold w-[284px]">
                  {vbText("NeverEndingInnovations")}
                </div>
                <div className="text-[#2A2B2C] text-16 font-normal leading-28px">
                  {vbText("StayAhead")}
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-6 w-full md:w-[353px]">
              <img
                alt=""
                src={CostEffectiveIcon}
                className="w-[40px] h-[40px]"
              />
              <div className="flex flex-col gap-4 pr-7">
                <div className="text-[#0D0E10] text-20 font-bold w-[284px]">
                  {vbText("ValueDrivenPricing")}
                </div>
                <div className="text-[#2A2B2C] text-16 font-normal leading-28px">
                  {vbText("EnjoyCompetitivePricing")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col bg-white">
        <BusinessWorldwide />

        <div className=" max-w-screen-main mx-auto flex items-center justify-center mt-6 pb-14 px-6 md:px-16">
          <FreePlanCard />
        </div>
      </div>
    </div>
  );
};
export default WhyVenueBoost;
