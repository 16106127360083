import React, { useState } from "react";
import { Modal } from "antd";
import { isMobile } from "react-device-detect";

import "./index.css";
import videoImg from "../../../assets/images/eight-features-video.webp";
import { useNavigate } from "react-router-dom";

const Grow = ({ venueColor = "", title = "", subtitle = "", tabs = [] }) => {
  const [selected, setSelected] = useState(tabs[0]);
  const [visibleGetStartedModal, setVisibleGetStartedModal] = useState(false);

  const navigate = useNavigate();
  const navigateToUrl = (url) => {
    navigate(url);
  };

  const handleShowModal = () => {
    setVisibleGetStartedModal(true);
  };

  return (
    <div className={"align-col-middle view-terms pt-8 md:pt-16 "}>
      <div className="w-[100vw] relative bg-gray2 py-8 md:py-16">
        <div className="mx-auto max-w-screen-main px-7 sm:px-6 md:px-8 lg:px-16">
          <div className="flex flex-col items-center py-10 gap-4">
            <span className="text-primary1 text-28 md:text-44 font-black text-center md:text-left">
              {title}
            </span>
            <span className="text-sm md:text-xl text-gray4 text-semibold text-left md:text-center">
              {subtitle}
            </span>
            <div className="w-full flex flex-col md:flex-row justify-between mt-4 md:mt-12 ">
              {/*<div className='hidden md:flex flex-col gap-6 pr-[60px] justify-center'>*/}
              <div
                className="hidden md:flex flex-col gap-6 pr-[0px] justify-center"
                style={{ width: "80%" }}
              >
                {tabs?.map((item, index) => (
                  <div
                    key={index}
                    className={`flex items-center px-8 cursor-pointer w-[400px] h-20 ${
                      item.content1 === selected.content1
                        ? `bg-[${venueColor}] rounded-lg`
                        : `rounded-[20px] border-b-2 border-[${venueColor}] bg-white`
                    }`}
                    style={{
                      borderColor:
                        item.content1 === selected.content1
                          ? venueColor
                          : "none",
                    }}
                    onClick={() => setSelected(item)}
                  >
                    <span className={`text-xl font-bold text-primary1`}>
                      {item.content1}
                    </span>
                  </div>
                ))}
              </div>
              {/* <div className='hidden md:block h-full w-1 bg-primary1'></div> */}
              <div className="flex flex-col md:hidden gap-3 border-primary1 w-full">
                {tabs?.map((item, index) => (
                  <>
                    <div
                      key={index}
                      className={`flex items-center px-8 cursor-pointer w-full h-20 ${
                        item.content1 === selected.content1
                          ? `bg-[${venueColor}] rounded-lg`
                          : `rounded-[10px] border-b-2 border-[${venueColor}] bg-white`
                      }`}
                      style={{
                        borderColor:
                          item.content1 === selected.content1
                            ? venueColor
                            : "none",
                      }}
                      onClick={() => setSelected(item)}
                    >
                      <span className={`text-base font-bold text-primary1`}>
                        {item.content1}
                      </span>
                      <span className="text-pink3 font-bold">+</span>
                    </div>

                    {item.content1 === selected.content1 && (
                      <div
                        className={`col-span-1 md:col-span-2 flex flex-col rounded-2xl px-8 md:px-10 py-6 md:py-10 gap-8 bg-white  border-b-2 border-b-[${venueColor}]`}
                        style={{ borderColor: venueColor }}
                      >
                        <div className="flex flex-col gap-2">
                          <span className="text-xl text-[#240B3B] font-black">
                            {selected.content1}
                          </span>
                          <span className="text-md text-[#240B3B] font-medium">
                            {selected.content2}
                          </span>
                          <span
                            className=" text-18 text-[#240B3B] font-medium hover:cursor-pointer"
                            onClick={handleShowModal}
                          >
                            See more{" "}
                          </span>
                        </div>

                        <div className="w-full">
                          <img
                            src={videoImg}
                            className="w-full h-full"
                            alt=""
                          />
                        </div>

                        <div
                          onClick={() => {
                            navigateToUrl("/get-started");
                          }}
                          className={`w-full md:w-[216px] cursor-pointer rounded py-2 mb-4 md:mb-0 bg-[${venueColor}] mx-auto text-center`}
                        >
                          <span className="text-lg font-bold text-[#240B3B]">
                            Try it out
                          </span>
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </div>
              <div className="flex">
                <div
                  className={`hidden col-span-1 md:col-span-2 md:flex flex-col rounded-2xl px-8 md:px-10 py-6 md:py-10 gap-8 bg-white  ml-[60px] border-b-2 border-b-[${venueColor}]`}
                  style={{ borderColor: venueColor }}
                >
                  <div className="flex flex-col gap-2">
                    <span className="text-xl text-[#240B3B] font-black">
                      {selected.content1}
                    </span>
                    <span className="text-md text-[#240B3B] font-medium">
                      {selected.content2?.replace(
                        "who_we_serve.retail_overview.tabs.",
                        ""
                      )}
                    </span>
                    <span
                      className=" text-18 text-[#240B3B] font-medium hover:cursor-pointer"
                      onClick={handleShowModal}
                    >
                      See more
                    </span>
                  </div>

                  <div className="w-full">
                    <img src={videoImg} className="w-full h-full" alt="" />
                  </div>

                  <div
                    onClick={() => {
                      navigateToUrl("/get-started");
                    }}
                    className={`w-full md:w-[216px] cursor-pointer rounded py-2 mb-4 md:mb-0 bg-[${venueColor}] mx-auto text-center`}
                  >
                    <span className="text-lg font-bold text-[#240B3B]">
                      Try it out
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        centered
        open={visibleGetStartedModal}
        onOk={() => navigateToUrl("/get-started")}
        onCancel={() => setVisibleGetStartedModal(false)}
        maskClosable={false}
        closable={true}
        closeIcon={<></>}
        okText={"UNLOCK FREE PLAN"}
        cancelText={"CLOSE"}
        className="freeJourneyModal [&>div>div]:flex [&>div>div]:justify-end [&>div]:!p-5 [&>div]:md:!p-10"
        okButtonProps={{
          style: {
            background:
              "linear-gradient(128deg, rgb(36, 11, 59) 3.95%, rgb(61, 30, 89) 34%, rgb(51, 5, 94) 67.3%, rgb(188, 20, 254) 94.28%)",
            color: "white",
            height: "54px",
            width: isMobile ? "100%" : "188px",
            marginLeft: isMobile ? "0px" : "8px",
            marginTop: isMobile ? "15px" : "0",
            fontSize: "16px",
          },
        }}
        cancelButtonProps={{
          style: {
            backgroundColor: "transparent",
            color: "#240b3b",
            borderColor: "#CBD5E1",
            height: "54px",
            width: isMobile ? "100%" : "188px",
            fontSize: "16px",
          },
        }}
        width={655}
      >
        <div className={"flex flex-col w-full h-max overflow-auto gap-5"}>
          <div className="flex flex-row justify-between">
            <div
              className={`w-[250px] h-[32px] flex justify-center items-center text-white bg-[${venueColor}] text-12 font-bold`}
            >
              {selected.badge}
            </div>
            <svg
              onClick={() => setVisibleGetStartedModal(false)}
              width="33"
              height="33"
              viewBox="0 0 33 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="16.2795" cy="16.5608" r="15.9719" fill="#D9D9D9" />
              <path
                d="M10.5186 22.5901L15.1299 16.4938L10.619 10.5315H12.9861L16.2911 14.953L19.5625 10.5315H21.9296L17.4188 16.4938L22.0413 22.5901H19.663L16.2911 18.0346L12.8968 22.5901H10.5186Z"
                fill="#838383"
              />
            </svg>
          </div>

          <div className="text-[#240B3B] text-[28px] font-extrabold leading-[38px]">
            {selected.content1}
          </div>

          <div className="text-[#240B3B] text-18 font-medium leading-[28px]">
            {selected.content2}
          </div>

          <div className="flex flex-col gap-5 mb-2">
            {selected.content3?.map((item, index) => (
              <div
                className="flex gap-4 justify-start items-center"
                key={index}
              >
                <svg
                  width="21"
                  height="16"
                  viewBox="0 0 21 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.06055 7.25146L6.56055 13.2515L19.5605 1.75146"
                    stroke="#D261FF"
                    strokeWidth="3"
                  />
                </svg>
                <div className="text-18 font-medium text-[#707070]">{item}</div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Grow;
