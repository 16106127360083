// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import moment from "moment";
import translationEN from "../assets/locales/en.json";
import translationES from "../assets/locales/es.json";
import translationFR from "../assets/locales/fr.json";
import translationGR from "../assets/locales/gr.json";
import translationAL from "../assets/locales/al.json";
import { KEYS, setStorageKey, getStorageKey } from "../utils/storage";

export const appMoment = moment;

// the translations
const resources = {
  en: { translation: translationEN },
  es: { translation: translationES },
  fr: { translation: translationFR },
  gr: { translation: translationGR },
  al: { translation: translationAL },
};
const bybestStarts =
  window.location.pathname.startsWith("/venue/accommodation") ||
  window.location.pathname.includes("rental/") ||
  window.location.pathname.includes("/customer/") ||
  window.location.pathname.includes("/page/");

let storedLanguage;
if (bybestStarts) {
  storedLanguage = getStorageKey(KEYS.BYBEST_LANGUAGE) || "al";
} else {
  storedLanguage = getStorageKey(KEYS.LANGUAGE_VB) || "en";
}

i18n.use(initReactI18next).init({
  resources,
  lng: storedLanguage,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  cleanCode: true,
  react: {
    useSuspense: false,
  },
});

export const getLanguage = () => i18n.language;

export const setLanguage = async (lang) => {
  // await i18n.changeLanguage(lang);
  setStorageKey(KEYS.LANGUAGE_VB, lang);
  appMoment.locale(lang);
};

export const setByBestLanguage = async (lang) => {
  // await i18n.changeLanguage(lang);
  setStorageKey(KEYS.BYBEST_LANGUAGE, lang);
  appMoment.locale(lang);
};

export default i18n;
