import React from 'react';

import youtubersIcon from '../../../assets/svgs/youtubers.svg'
import influencersIcon from '../../../assets/svgs/influencers.svg'
import bloggerWriterIcon from '../../../assets/svgs/bloggers_writers.svg'
import mediaNetworkIcon from '../../../assets/svgs/media_networks.svg'
import webDeveloperIcon from '../../../assets/svgs/web_developers.svg'
import educatorIcon from '../../../assets/svgs/educators.svg'

const ServeItem = ({ label, icon }) => (
  <div className='flex flex-row justify-between items-center px-6 py-3 md:px-8 md:py-6 bg-[#240B3B] rounded-2xl'>
    <span className='text-base md:text-lg font-bold text-[#E39DFF]'>
      {label}
    </span>
    <img src={icon} alt='icon' />
  </div>
)

export default function WhoFor() {
  const serviceList = [
    { label: 'Vloggers', icon: youtubersIcon },
    { label: 'Influencers', icon: influencersIcon },
    { label: 'Bloggers and writers', icon: bloggerWriterIcon },
    { label: 'Media Networks', icon: mediaNetworkIcon },
    { label: 'Web Developers', icon: webDeveloperIcon },
    { label: 'Business Experts/Coaches', icon: educatorIcon },
    { label: 'Content Creators', icon: educatorIcon },
    { label: 'Podcasters', icon: educatorIcon },
    { label: 'Marketing Companies', icon: educatorIcon },
  ]
  return (
    <div className='flex flex-col gap-6 md:gap-10'>
      <span className='text-2xl md:text-4xl font-black text-center'>
        The affiliate program assists:
      </span>
      <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 md:gap-6'>
        {serviceList.map((item, index) => (
          <ServeItem key={index} {...item} />
        ))}
      </div>
    </div>
  )
}
