import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

const BlogCard = ({ img, tag, title, date, slug, id }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [prefetchedImage, setPrefetchedImage] = useState(null);

  useEffect(() => {
    const imgObj = new Image();
    imgObj.src = img;
    imgObj.onload = () => {
      setPrefetchedImage(imgObj.src);
      setIsLoading(false);
    };
    imgObj.onerror = () => {
      setIsLoading(false);
    };
  }, [img]);

  return (
    <div
      className="cursor-pointer"
      onClick={() => {
        navigate(`/blog/${slug}`, {
          state: id,
        });
      }}
    >
      <div className="flex flex-col rounded-[13px] border-[1px] border-[#E0E0E0] bg-white blog-card overflow-hidden">
        {isLoading && (
          <div className="w-full h-[160px] md:h-[258px] bg-gray-200 flex justify-center items-center">
            <CircularProgress />
          </div>
        )}

        {prefetchedImage && (
          <img
            className="w-full h-[160px] md:h-[258px] object-cover"
            src={prefetchedImage}
            alt={title}
            loading="lazy"
          />
        )}

        <div className="py-9 px-6 relative">
          <div className="cursor-pointer absolute flex rounded-[60px] bg-gradient-to-r from-[#240B3B] via-[#3D1E59] to-[#BC14FE] p-[1px] top-[-17px] w-[212px] h-[36px]">
            <div className="flex h-full w-full items-center justify-center bg-white rounded-[60px]">
              <h1 className="text-14 font-normal text-[#454545]">{tag}</h1>
            </div>
          </div>

          <p className="text-[#373941] text-20 leading-7 font-normal">
            {title}
          </p>
          <p className="text-[#717484] text-14 leading-5 font-normal mt-5">
            {date}
          </p>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
