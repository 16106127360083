import React, { useState } from "react";
import { addressData } from "../data";
import { addressData as initialLabels } from "../data";
import { Checkbox } from "@mui/material";
import { editIcon, trash } from "../../../venue-retail/icons";
import ShippingForm from "./shipping-form";

const CheckoutShipping = ({ setActiveStep }) => {
  const [checkboxLabels, setCheckboxLabels] = useState(initialLabels);

  const handleChange = (index) => {
    const updatedCheckboxLabels = [...addressData];
    updatedCheckboxLabels.forEach((item, i) => {
      item.defaultAddress = i === index;
    });

    setCheckboxLabels(updatedCheckboxLabels);
  };

  return (
    <div className="flex flex-col gap-5 md:gap-6 lg:gap-8 md:col-span-3">
      <div className="intro mt-5">
        <p className="text-lg md:text-xl lg:text-2xl font-semibold">
          Select a delivery address
        </p>
        <span className=" font-normal text-12 md:text-14 text-[#4D4D4D]">
        Is the address you'd like to use displayed below? If so, click the corresponding "Deliver to this address" button. Or you can enter a new delivery address.
        </span>
      </div>

      <div className="flex flex-col lg:flex-row items-start justify-between gap-5 w-full">
        {addressData.map((item, index) => (
          <div
            key={index}
            className="flex flex-col address gap-4 p-4 rounded-lg w-full "
          >
            <div className="flex items-center justify-between gap-2">
              <p className="name font-medium text-base md:text-lg lg:text-xl">
                {item.name}
              </p>
              <div className="flex items-center">
                <label
                  htmlFor={`checkbox-${index}`}
                  className="cursor-pointer default-address text-xs md:text-sm lg:text-base"
                >
                  {checkboxLabels[index].defaultAddress
                    ? "Default Address"
                    : ""}
                </label>
                <Checkbox
                  label={item.title}
                  defaultChecked={item.defaultAddress}
                  onChange={() => handleChange(index)}
                  checked={checkboxLabels[index].defaultAddress}
                  className="cursor-pointer"
                  id={`checkbox-${index}`}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fill: "#131118",
                    },
                  }}
                />
              </div>
            </div>
            <span className="span text-sm md:text-base lg:text-lg">
              {item.address}
            </span>
            <div className="flex items-center gap-2 justify-between">
              <button className="edit flex items-center gap-1 text-xs md:text-sm lg:text-base">
                <span>{editIcon}</span> <p className="!text-12 !font-medium">Edit</p>
              </button>
              <button className="delete flex items-center gap-1 text-xs md:text-sm lg:text-base">
                <span >{trash}</span> <p className="!text-[#E14848] !text-12 !font-medium">Delete</p>
              </button>
            </div>
          </div>
        ))}
      </div>
      <button
        className="deliver mt-4 md:mt-6 lg:mt-8 py-2 px-4 bg-blue-500 text-white rounded-md"
        onClick={() => setActiveStep(1)}
      >
        <span className="text-sm md:text-base lg:text-lg">Deliver Here</span>
      </button>

      <span className="separator w-full h-px bg-gray-300 mt-4 md:mt-6 lg:mt-8" />
      <ShippingForm setActiveStep={setActiveStep} />
    </div>
  );
};

export default CheckoutShipping;
