import React, { useState } from "react";
import FormTextInput from "../../../../components/FormTextInput";
import { useFormik } from "formik";
import * as yup from "yup";
import { Checkbox } from "@mui/material";

const CreditCardForm = ({ setActiveStep }) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [isChecked, setIsChecked] = useState(true);

  const formatCardNumber = (value) => {
    const numericValue = value.replace(/\D/g, "");
    const formattedValue = numericValue.match(/.{1,4}/g)?.join("-") || "";
    return formattedValue;
  };

  const formatExpiryDate = (value) => {
    const numericValue = value.replace(/\D/g, "");
    const formattedValue = numericValue.match(/.{1,2}/g)?.join("/") || "";
    return formattedValue;
  };

  const [formFields, setFormFields] = useState({
    card_number: undefined,
    holder_name: undefined,
    expiry_date: undefined,
    cvv: undefined,
  });

  const errorValue = (name) => {
    const isFalse = formFields[name] === false;

    if (isSubmit && formFields[name] === undefined) {
      setFormFields({
        ...formFields,
        [name]: false,
      });
    }

    return isFalse ? formikProps.errors[name] : false;
  };

  const validator = yup.object().shape({
    card_number: yup
      .string()
      .trim()
      .required("Card number is required")
      .max(16, "Max exceeded")
      .min(2, "Min not met"),
    holder_name: yup.string().required("Holder name is required"),
    expiry_date: yup.string().required("Expiry date is required"),
    cvv: yup.string().required("CVV is required"),
  });

  const formikProps = useFormik({
    initialValues: {
      card_number: "",
      holder_name: "",
      expiry_date: "",
      cvv: "",
    },
    validationSchema: validator,
    validateOnChange: true,
    validateOnBlur: false,
    validateOnMount: true,
  });

  const allFieldsValidated = Object.values(formFields).every(
    (value) => value === true
  );

  return (
    <form className="md:grid md:grid-cols-2 gap-2">
      <FormTextInput
        placeholder="Card Number"
        value={formikProps.values.card_number}
        error={errorValue("card_number")}
        onChange={(e) => {
          const formattedValue = formatCardNumber(e.target.value);
          setFormFields({
            ...formFields,
            card_number: formattedValue.length > 0,
          });
          formikProps.setFieldValue("card_number", formattedValue);
        }}
        className="h-[80px] [&>input]:rounded-[10px]"
        enableLabel={false}
      />

      <FormTextInput
        placeholder="Holder name"
        value={formikProps.values.holder_name}
        error={errorValue("holder_name")}
        onChange={(e) => {
          formikProps.setFieldValue("holder_name", e.target.value);
          setFormFields({
            ...formFields,
            holder_name: e.target.value.length > 0,
          });
        }}
        className="h-[80px] [&>input]:rounded-[10px]"
        enableLabel={false}
      />

      <FormTextInput
        placeholder="Expiry date MM/YY"
        value={formikProps.values.expiry_date}
        error={errorValue("expiry_date")}
        onChange={(e) => {
          const formattedValue = formatExpiryDate(e.target.value);
          formikProps.setFieldValue("expiry_date", formattedValue);
          setFormFields({
            ...formFields,
            expiry_date: formattedValue.length > 0,
          });
        }}
        className="h-[80px] [&>input]:rounded-[10px]"
        enableLabel={false}
        type="text"
      />

      <FormTextInput
        placeholder="CVV"
        type="password"
        value={formikProps.values.cvv}
        error={errorValue("cvv")}
        onChange={(e) => {
          formikProps.setFieldValue("cvv", e.target.value);
          setFormFields({
            ...formFields,
            cvv: e.target.value.length > 0,
          });
        }}
        className="h-[80px] [&>input]:rounded-[10px]"
        enableLabel={false}
      />
      <div className="col-span-2 flex flex-col gap-4 mt-4">
        <div className="flex items-center">
          <Checkbox
            label="Save my card for future"
            defaultChecked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
            checked={isChecked}
            className="cursor-pointer"
            id={"saveCard"}
            sx={{
              "& .MuiSvgIcon-root": {
                fill: "#131118",
              },
            }}
          />
          <label htmlFor={"saveCard"} className="cursor-pointer text-sm">
            Save my card for future
          </label>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
          <button
            type="button"
            className=" text-white py-3 rounded bg-[#131118] w-full"
            onClick={() => {
              if (allFieldsValidated) {
                setActiveStep(2);
              } else {
                setIsSubmit(true);
              }
            }}
          >
            <span>Confirm and Pay</span>
          </button>
          <div></div>
        </div>
      </div>
    </form>
  );
};

export default CreditCardForm;
