import React, { useEffect, useState } from "react";
import { Seo } from "../../components/Seo";
import { Select } from "antd";
import WhiteLabelService from "../../services/whiteLabelService";
import { useNavigate, useParams } from "react-router";
import { NotificationManager } from "react-notifications";
import CardIcon from "../../assets/svgs/card.svg";
import CashIcon from "../../assets/svgs/cash.svg";
import TrashIcon from "../../assets/svgs/trash.svg";
import ImgPlaceholder from "../../assets/images/img_placeholder.png";
import * as yup from "yup";
import OrderSuccessScreen from "../cart/order-sucess";
import Enroll from "../../components/venue/enroll";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import {
  addCartBillingDetails,
  addOrderSucessDetails,
  addToCart,
  clearCheckoutForm,
  removeFromCart,
  removeFromCartBillingDetails,
  updateCartProductQuantity,
  verifyRestaurantCoupon,
} from "../../redux/actions/cart";
import { isEmpty } from "../../utils/common";
import { KEYS, getStorageKey } from "../../utils/storage";
import { STRIPE_PUB_KEY } from "../../constants/config";
import { Elements } from "@stripe/react-stripe-js";
import StripeCheckoutForm from "../../components/StripeCheckout";
import { loadStripe } from "@stripe/stripe-js";
import { identifyVenue, trackEvent } from "../../utils/mixpanelUtil";

const deliveryProfileList = [
  { label: "For myself", value: 0 },
  { label: "For another person", value: 1 },
];

const stripePromise = loadStripe(STRIPE_PUB_KEY);

const FoodCheckout = () => {
  const { app_key } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {
    products,
    billing_detail,
    coupon_detail,
    venue_app_key,
    currency,
    shippingMethod,
    venueName,
    venueLogo,
    deliveryFee,
    discount,
  } = useSelector((state) => state.cart);
  const [total, setTotal] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [discountValue, setDiscountValue] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("Cash");
  const [clientSecret, setClientSecret] = useState("");
  const [tableProducts, setTableProducts] = useState([]);
  const [couponCode, setCouponCode] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [canEnroll, setCanEnroll] = useState(false);
  const [enrolledCompleted, setEnrolledCompleted] = useState(false);
  const [enrollEmail, setEnrollEmail] = useState(null);
  const [appKey, setAppKey] = useState("");
  const [selectedDeliveryProfile, setSelectedDeliveryProfile] = useState(0);
  const [hospitalRoomId, setHospitalRoomId] = useState("");

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  const validator = yup.object().shape({
    first_name: yup
      .string()
      .trim()
      .required("First name is required")
      .max(72, "Max exceeded")
      .min(2, "Min not met"),
    last_name: yup
      .string()
      .trim()
      .required("Last name is required")
      .max(72, "Max exceeded")
      .min(2, "Min not met"),
    email: yup
      .string()
      .trim()
      .required("Email is required")
      .max(72, "Max exceeded")
      .min(5, "Min not met")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Invalid email"
      ),
    phone: yup.string().trim().required("Phone number is required"),

    state: yup.string().trim().required("State is required"),
    address_line1: yup
      .string()
      .trim()
      .required("Address Line 1 is required")
      .max(250, "Max exceeded")
      .min(6, "Min not met"),
    address_line2: yup.string().trim(),
    city: yup.string().required("City is required"),
    postcode: yup.string().required("Postal code is required"),
    company_name: yup.string(),
    additional_notes: yup.string(),
    card_number:
      paymentMethod === "Card"
        ? yup.string().required("Card number is required")
        : yup.string(),
    // ccv: paymentMethod === 'Card' ?  yup.string().required("CCV is required") : yup.string(),
    // month_year: paymentMethod === 'Card' ?  yup.string().required("Month/Year is required") : yup.string(),
    // other_person_name: selectedDeliveryProfile === 2?   yup.string().required("Other person name is required") : yup.string(),
  });

  const formikProps = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      address_line1: "",
      address_line2: "",
      state: "",
      city: "",
      postcode: "",
      company_name: "",
      additional_notes: "",
      phone: "",
      // card_number: "",
      // month_year: "",
      // ccv: "",
      other_person_name: "",
    },
    validationSchema: validator,
    onSubmit: (values) => onSubmit(values, null),
    validateOnChange: true,
    validateOnBlur: false,
    validateOnMount: true,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    // Create PaymentIntent as soon as the page loads
    setAppKey(venue_app_key);
    let hospitalRoomKey = getStorageKey(KEYS.HOSPITAL_ROOM_KEY);
    if (hospitalRoomKey) {
      setHospitalRoomId(hospitalRoomKey.value);
    }
  }, []);

  useEffect(() => {
    if (products) {
      let sub = 0;
      let disc = 0;
      let shipping = 0;
      products.forEach((item) => {
        sub += parseFloat(item.price * item?.quantity);
      });
      setTableProducts(products);
      setSubtotal(sub);
      if (discount) {
        if (discount?.type === "fixed") {
          disc = parseFloat(discount?.value);
        } else if (discount?.type === "percentage") {
          disc = (sub * parseFloat(discount?.value)) / 100;
        }
      }

      if (
        coupon_detail &&
        (coupon_detail?.discount_value || coupon_detail?.coupon_code)
      ) {
        dispatch(verifyRestaurantCoupon(null));
        if (coupon_detail?.discount_value) {
          disc = coupon_detail?.discount_value;
        }
        if (coupon_detail?.coupon_code) {
          setCouponCode(coupon_detail?.coupon_code);
        }
      }
      setDiscountValue(disc);
      setTotal(sub - disc + deliveryFee);
    }
  }, [products, coupon_detail, discount]);

  const onRemoveProduct = (product) => {
    if (product.quantity === 1) {
      dispatch(removeFromCart(product));
    } else {
      const newPrroduct = { ...product, quantity: -1 };
      dispatch(
        addToCart(
          newPrroduct,
          venue_app_key,
          venueName,
          currency,
          venueLogo,
          deliveryFee,
          discount
        )
      );
    }
  };

  const onDeleteProduct = (product) => {
    dispatch(removeFromCart(product));
  };

  const onAddProduct = (product) => {
    const newPrroduct = { ...product, quantity: 1 };
    dispatch(
      addToCart(
        newPrroduct,
        venue_app_key,
        venueName,
        currency,
        venueLogo,
        deliveryFee,
        discount
      )
    );
  };

  const onChangeProductQuantity = (product, quantity) => {
    const newPrroduct = { ...product, quantity: quantity };
    dispatch(
      updateCartProductQuantity(
        newPrroduct,
        venue_app_key,
        venueName,
        currency,
        venueLogo,
        deliveryFee,
        discount
      )
    );
  };

  const applyCoupon = () => {
    let payload = {
      coupon_code: couponCode,
      subtotal: subtotal,
    };
    dispatch(verifyRestaurantCoupon(payload));
  };

  const handlePaymentMethodChange = async (method) => {
    setPaymentMethod(method);

    if (method === "Card") {
      try {
        setIsLoading(true); // indicate loading

        const stripeTotal = Math.round(total * 100);

        const payload = {
          amount: stripeTotal,
          description: "Food Order Payment",
        };
        const response = await WhiteLabelService.createPaymentIntent(
          payload,
          appKey
        );
        setClientSecret(response.data.client_secret);
        identifyVenue(app_key);
        trackEvent("Stripe Payment Intent", {
          action_category: "stripe create payment intent",
          action_outcome: "success",
          interaction_element: "api",
          source: "vb_web",
        });
      } catch (err) {
        NotificationManager.error(
          err?.error ?? "Something went wrong while setting up payment!"
        );
        identifyVenue(app_key);
        trackEvent("Stripe Payment Intent", {
          action_category: "stripe create payment intent",
          action_outcome: "fail",
          interaction_element: "api",
          source: "vb_web",
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onSubmit = (values, paymentIntentId) => {
    window.scrollTo(0, 0);

    dispatch(addCartBillingDetails(values));

    const orderProducts = products.map((item) => {
      return {
        product_id: item.id,
        product_total_price: parseFloat(item.price),
        product_quantity: item.quantity,
        product_discount_price: 0,
      };
    });

    const payload = {
      order_products: orderProducts,
      customer: {
        first_name: values?.first_name,
        last_name: values?.last_name,
        email: values?.email,
        phone: values?.phone,
      },
      shipping_address: {
        line1: values?.address_line1,
        line2: values?.address_line2,
        state: values?.state,
        city: values?.city,
        postal_code: values?.postcode,
      },
      notes: values?.additional_notes,
      payment_method: paymentMethod,
      total_amount: total,
      discount_value: discountValue,
      subtotal: subtotal,
      coupon: coupon_detail?.coupon_code ?? null,
      discount_id: coupon_detail?.coupon_code ? null : discount?.id,
      is_for_self: selectedDeliveryProfile === 0 ? 1 : 0,
      other_person_name:
        selectedDeliveryProfile === 1 ? values.other_person_name : "",
      stripe_payment_id: paymentMethod === "Card" ? clientSecret : null,
      hospital_room_id: hospitalRoomId,
    };
    setEnrollEmail(values?.email);
    WhiteLabelService.checkoutRestaurantOrder(payload, venue_app_key)
      .then((data) => {
        setLoading(false);
        dispatch(
          addOrderSucessDetails({
            email: values?.email,
            type: "food",
            appKey: appKey,
            can_enroll: data?.can_enroll,
          })
        );

        dispatch(clearCheckoutForm());
        dispatch(removeFromCartBillingDetails());
        NotificationManager.success(
          "Order was placed successfully",
          "Success",
          3000
        );
        // navigate('/order-sucess')
        setSubmitted(true);
        if (data?.can_enroll) {
          setCanEnroll(true);
        }
        identifyVenue(app_key);
        trackEvent("Checkout Order Food", {
          action_category: "create order",
          action_outcome: "success",
          interaction_element: "button",
          source: "vb_web",
        });
      })
      .catch((err) => {
        setLoading(false);
        NotificationManager.error(err?.error ?? "Something went wrong!");
        identifyVenue(app_key);
        trackEvent("Checkout Order Food", {
          action_category: "create order",
          action_outcome: "success",
          interaction_element: "button",
          source: "vb_web",
        });
      });
  };

  const onEnrollSubmit = () => {
    const payload = {
      email: enrollEmail,
    };
    WhiteLabelService.guestEnroll(payload, appKey)
      .then((res) => {
        NotificationManager.success(
          res?.message ?? "Guest enrolled successfully"
        );
        setEnrolledCompleted(true);
        identifyVenue(app_key);
        trackEvent("F & B Loyalty", {
          action_category: "Guests Enrolled",
          action_outcome: "success",
          interaction_element: "button",
          source: "vb_web",
        });
      })
      .catch((err) => {
        NotificationManager.error(err?.error ?? "Something went wrong!");
        identifyVenue(app_key);
        trackEvent("F & B Loyalty", {
          action_category: "Guests Enrolled",
          action_outcome: "fail",
          interaction_element: "button",
          source: "vb_web",
        });
      });
  };

  if (submitted) {
    return (
      <div>
        <OrderSuccessScreen fullName={""} />
        {canEnroll ? (
          <Enroll
            onSubmit={onEnrollSubmit}
            completed={enrolledCompleted}
            title={"Unlock Rewards with Our Loyalty Program"}
            description={
              "Every order brings you closer to exclusive discounts. Earn points with each order and use them to save on your next visit. Your loyalty matters to us!"
            }
          />
        ) : (
          ""
        )}
      </div>
    );
  }

  return (
    <div className={"align-col-middle view-terms mt-0"}>
      <Seo title={"Who We Serve - Sports & Entertainment | VenueBoost"} />
      <div className="  w-[100vw] relative  ">
        <div className="mx-auto max-w-screen-main px-4 sm:px-6 md:px-8 lg:px-16">
          <h1 className="text-20 md:text-26 font-semibold md:font-bold  leading-[30px] md:leading-[34px] text-[#240b3b] my-5">
            Order checkout
          </h1>
          <div className="rounded-[10px] border-[1px] border-[#E7E7E7] flex flex-col flex-1 p-4 md:p-6 mb-5">
            <h2 className="hidden md:block text-20 font-bold leading-[34px] md:mb-5">
              Restaurant Info
            </h2>
            <div className="flex flex-col md:flex-row md:gap-12 mb-5">
              <div className="w-full md:w-[60%]  ">
                <div className="rounded-[10px] md:border-[1px] border-[#e7e7e7] md:px-6  py-2 items-center flex flex-1 justify-between">
                  <p className="text-[#240b3b] text-20 font-bold leading-[34px] ">
                    {venueName}
                  </p>
                  {venueLogo ? (
                    <div className="border-[1px] rounded-[10px] border-[#e7e7e7] w-[75px] h-[75px] align-middle">
                      <img src={venueLogo} className="w-[75px] h-[75px]" />
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="w-full md:w-[40%] ">
                <div className="rounded-[10px] md:border-[1px] border-[#e7e7e7]  md:p-6">
                  <p className="md:block text-18 font-semibold leading-[34px] text-[#240b3b] mb-[8px]">
                    Order for
                  </p>
                  <Select
                    className="w-full  "
                    size="large"
                    placeholder="Order for"
                    options={deliveryProfileList}
                    value={selectedDeliveryProfile}
                    onChange={(value) => {
                      setSelectedDeliveryProfile(value);
                    }}
                  />
                  {selectedDeliveryProfile === 1 && (
                    <div className="mt-4">
                      <p className="hidden md:block text-18 font-semibold leading-[34px] text-[#240b3b] mb-[8px]">
                        Other person name
                      </p>
                      <input
                        className="border-[1px] h-[48px] w-full rounded-[5px] border-[#cbd5e1] bg-white px-3 py-[10px] text-[#707070] text-18 font-medium leading-[34px]"
                        placeholder="Enter other person name"
                        value={formikProps.values.other_person_name}
                        onChange={(e) => {
                          formikProps.setFieldValue(
                            "other_person_name",
                            e.target.value
                          );
                        }}
                      />
                      {formikProps.errors.other_person_name && (
                        <div className={"text-red-600 text-12 mt-2"}>
                          {formikProps.errors.other_person_name}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="flex flex-col-reverse md:flex-row md:gap-12">
              <div className="w-full md:w-[60%] flex flex-col gap-12">
                <div>
                  <h2 className="text-20 md:text-20 font-bold leading-[34px] mb-2 md:mt-0  mt-5">
                    Customer Details
                  </h2>
                  <div className="rounded-[10px] md:border-[1px] border-[#e7e7e7] md:p-6 grid grid-cols-1  md:grid-cols-2 gap-x-[56px] gap-y-[19px] ">
                    <div>
                      <p className="hidden md:block text-18 font-semibold leading-[34px] text-[#240b3b] mb-[8px]">
                        First name
                      </p>
                      <input
                        className="border-[1px] h-[48px] w-full rounded-[5px] border-[#cbd5e1] bg-white px-3 py-[10px] text-[#707070] text-18 font-medium leading-[34px]"
                        placeholder="Enter first name"
                        value={formikProps.values.first_name}
                        onChange={(e) => {
                          formikProps.setFieldValue(
                            "first_name",
                            e.target.value
                          );
                        }}
                      />
                      {formikProps.errors.first_name && (
                        <div className={"text-red-600 text-12 mt-2"}>
                          {formikProps.errors.first_name}
                        </div>
                      )}
                    </div>
                    <div>
                      <p className="hidden md:block text-18 font-semibold leading-[34px] text-[#240b3b] mb-[8px]">
                        Last name
                      </p>
                      <input
                        className="border-[1px] h-[48px] w-full rounded-[5px] border-[#cbd5e1] bg-white px-3 py-[10px] text-[#707070] text-18 font-medium leading-[34px]"
                        placeholder="Enter last name"
                        value={formikProps.values.last_name}
                        onChange={(e) => {
                          formikProps.setFieldValue(
                            "last_name",
                            e.target.value
                          );
                        }}
                      />
                      {formikProps.errors.last_name && (
                        <div className={"text-red-600 text-12 mt-2"}>
                          {formikProps.errors.last_name}
                        </div>
                      )}
                    </div>
                    <div>
                      <p className="hidden md:block text-18 font-semibold leading-[34px] text-[#240b3b] mb-[8px]">
                        Email address
                      </p>
                      <input
                        className="border-[1px] h-[48px] w-full rounded-[5px] border-[#cbd5e1] bg-white px-3 py-[10px] text-[#707070] text-18 font-medium leading-[34px]"
                        placeholder="Enter address"
                        value={formikProps.values.email}
                        onChange={(e) => {
                          formikProps.setFieldValue("email", e.target.value);
                        }}
                      />
                      {formikProps.errors.email && (
                        <div className={"text-red-600 text-12 mt-2"}>
                          {formikProps.errors.email}
                        </div>
                      )}
                    </div>
                    <div>
                      <p className="hidden md:block text-18 font-semibold leading-[34px] text-[#240b3b] mb-[8px]">
                        Phone number
                      </p>
                      <input
                        className="border-[1px] h-[48px] w-full rounded-[5px] border-[#cbd5e1] bg-white px-3 py-[10px] text-[#707070] text-18 font-medium leading-[34px]"
                        placeholder="Enter phone number"
                        value={formikProps.values.phone}
                        onChange={(e) => {
                          formikProps.setFieldValue("phone", e.target.value);
                        }}
                      />
                      {formikProps.errors.phone && (
                        <div className={"text-red-600 text-12 mt-2"}>
                          {formikProps.errors.phone}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div>
                  <h2 className="text-20 md:text-20 font-bold leading-[34px] mb-2">
                    Address Information
                  </h2>
                  <div className="rounded-[10px] md:border-[1px] border-[#e7e7e7] md:p-6 grid grid-cols-1  md:grid-cols-2 gap-x-[56px] gap-y-[19px] ">
                    <div>
                      <p className="hidden md:block text-18 font-semibold leading-[34px] text-[#240b3b] mb-[8px]">
                        Address
                      </p>
                      <input
                        className="border-[1px] h-[48px] w-full rounded-[5px] border-[#cbd5e1] bg-white px-3 py-[10px] text-[#707070] text-18 font-medium leading-[34px]"
                        placeholder="Enter address"
                        value={formikProps.values.address_line1}
                        onChange={(e) => {
                          formikProps.setFieldValue(
                            "address_line1",
                            e.target.value
                          );
                        }}
                      />
                      {formikProps.errors.address_line1 && (
                        <div className={"text-red-600 text-12 mt-2"}>
                          {formikProps.errors.address_line1}
                        </div>
                      )}
                    </div>
                    <div>
                      <p className="hidden md:block text-18 font-semibold leading-[34px] text-[#240b3b] mb-[8px]">
                        Address Details
                      </p>
                      <input
                        className="border-[1px] h-[48px] w-full rounded-[5px] border-[#cbd5e1] bg-white px-3 py-[10px] text-[#707070] text-18 font-medium leading-[34px]"
                        placeholder="Enter address details (optional)"
                        value={formikProps.values.address_line2}
                        onChange={(e) => {
                          formikProps.setFieldValue(
                            "address_line2",
                            e.target.value
                          );
                        }}
                      />
                      {formikProps.errors.address_line2 && (
                        <div className={"text-red-600 text-12 mt-2"}>
                          {formikProps.errors.address_line2}
                        </div>
                      )}
                    </div>
                    <div>
                      <p className="hidden md:block text-18 font-semibold leading-[34px] text-[#240b3b] mb-[8px]">
                        City
                      </p>
                      <input
                        className="border-[1px] h-[48px] w-full rounded-[5px] border-[#cbd5e1] bg-white px-3 py-[10px] text-[#707070] text-18 font-medium leading-[34px]"
                        placeholder="Enter city"
                        value={formikProps.values.city}
                        onChange={(e) => {
                          formikProps.setFieldValue("city", e.target.value);
                        }}
                      />
                      {formikProps.errors.city && (
                        <div className={"text-red-600 text-12 mt-2"}>
                          {formikProps.errors.city}
                        </div>
                      )}
                    </div>
                    <div>
                      <p className="hidden md:block text-18 font-semibold leading-[34px] text-[#240b3b] mb-[8px]">
                        State
                      </p>
                      <input
                        className="border-[1px] h-[48px] w-full rounded-[5px] border-[#cbd5e1] bg-white px-3 py-[10px] text-[#707070] text-18 font-medium leading-[34px]"
                        placeholder="Enter state"
                        value={formikProps.values.state}
                        onChange={(e) => {
                          formikProps.setFieldValue("state", e.target.value);
                        }}
                      />
                      {formikProps.errors.state && (
                        <div className={"text-red-600 text-12 mt-2"}>
                          {formikProps.errors.state}
                        </div>
                      )}
                    </div>

                    <div>
                      <p className="hidden md:block text-18 font-semibold leading-[34px] text-[#240b3b] mb-[8px]">
                        Postal code
                      </p>
                      <input
                        className="border-[1px] h-[48px] w-full rounded-[5px] border-[#cbd5e1] bg-white px-3 py-[10px] text-[#707070] text-18 font-medium leading-[34px]"
                        placeholder="Enter postal code"
                        value={formikProps.values.postcode}
                        onChange={(e) => {
                          formikProps.setFieldValue("postcode", e.target.value);
                        }}
                      />
                      {formikProps.errors.postcode && (
                        <div className={"text-red-600 text-12 mt-2"}>
                          {formikProps.errors.postcode}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div>
                  <h2 className="text-20 md:text-20 font-bold leading-[34px] md:mb-2">
                    Choose Payment
                  </h2>
                  <div className="rounded-t-[10px] md:border-[1px] border-[#e7e7e7] md:px-6 md:py-9 pt-6  grid grid-cols-1 md:grid-cols-2 gap-x-[56px] gap-y-[19px] ">
                    <button
                      className={`rounded-[5px] border-[1px] ${
                        paymentMethod === "Card"
                          ? "border-[#240b3b]"
                          : "border-none"
                      } bg-[#f9f6f6] px-6 py-8 flex items-center`}
                      onClick={() => handlePaymentMethodChange("Card")}
                    >
                      <img src={CardIcon} className="mr-4" />
                      <p
                        className={`text-[#707070] text-18 font-medium leading-[34px] ${
                          paymentMethod === "Card"
                            ? "text-[#707070]"
                            : "text-gray1"
                        }`}
                      >
                        Pay with Card
                      </p>
                    </button>

                    <button
                      className={`rounded-[5px] border-[1px] ${
                        paymentMethod === "Cash"
                          ? "border-[#240b3b]"
                          : "border-none"
                      } bg-[#f9f6f6] px-6 py-8 flex items-center`}
                      onClick={() => handlePaymentMethodChange("Cash")}
                    >
                      <img src={CashIcon} className="mr-4" />
                      <p
                        className={`text-[#707070] text-18 font-medium leading-[34px] ${
                          paymentMethod === "Cash"
                            ? "text-[#707070]"
                            : "text-gray1"
                        }`}
                      >
                        Cash on Delivery
                      </p>
                    </button>
                  </div>
                </div>
                <div className="flex   md:hidden gap-4">
                  {paymentMethod == "Card" && clientSecret ? (
                    <Elements options={options} stripe={stripePromise}>
                      <StripeCheckoutForm
                        onStripeCheckout={(paymentIntentId) =>
                          onSubmit(formikProps.values, paymentIntentId)
                        }
                        buttonClasses="flex-1 rounded-[5px] border-[1px] h-[70px] text-white bg-primary1 border-[#e0e0e0] py-[17px] px-[39px] text-20 font-bold leading-[34px]"
                        type={"food"}
                      />
                    </Elements>
                  ) : (
                    <>
                      <button
                        className="rounded-[5px] border-[1px] h-[52px] border-[#e0e0e0]   px-[39px] text-primary1 text-16 font-bold leading-[34px] "
                        onClick={() => navigate(-1)}
                      >
                        Back
                      </button>
                      <button
                        className="flex-1 rounded-[5px] border-[1px] h-[52px] text-white bg-primary1 border-[#e0e0e0] px-[39px] text-16 font-bold leading-[34px] "
                        onClick={formikProps.handleSubmit}
                      >
                        Place order
                      </button>
                    </>
                  )}
                </div>
              </div>
              <div className="w-full md:w-[40%] flex flex-col gap-12">
                <div>
                  <h2 className="hidden md:block text-20 font-bold leading-[34px] mb-2">
                    Order Information
                  </h2>
                  {tableProducts.map((item, index) => (
                    <>
                      <div className="rounded-t-[10px] md:border-[1px] border-[#e7e7e7] md:p-6 ">
                        <div className="flex flex-1 w-full gap-4">
                          {/* <img
                          src={FoodImg}
                          className="rounded-[4px] border-[1px] border-[#acacac] "
                        /> */}
                          {isEmpty(item?.image_path) ? (
                            <img
                              src={ImgPlaceholder}
                              alt="food"
                              className="h-[104px] w-[104px] rounded-lg"
                            />
                          ) : (
                            <img
                              className="w-[104px] h-[104px] rounded-lg"
                              style={{ objectFit: "cover" }}
                              src={item?.image_path}
                              alt={item.title}
                            />
                          )}
                          <div className="flex justify-between w-full py-2">
                            <div className="flex flex-col">
                              <p className="text-18 mb-4 font-bold text-primary1 leading-[22px]">
                                {item?.title}
                              </p>
                              <p className="text-16 font-medium leading-5 text-[#ACACAC]">
                                {item?.description
                                  ? item?.description.length > 40
                                    ? item?.description.slice(0, 40) + "..."
                                    : item?.description
                                  : ""}
                              </p>
                            </div>
                            <div className="flex flex-col justify-between">
                              <p className="text-18 font-bold text-primary1 leading-[22px]">
                                {currency ?? ""}{" "}
                                {parseFloat(item?.price * item.quantity)}
                              </p>
                              <Select
                                className="w-full  block md:hidden"
                                // size="small"
                                value={item.quantity}
                                onChange={(value) => {
                                  onChangeProductQuantity(item, value);
                                }}
                                options={[
                                  { value: 1, label: 1 },
                                  { value: 2, label: 2 },
                                  { value: 3, label: 3 },
                                  { value: 4, label: 4 },
                                  { value: 5, label: 5 },
                                  { value: 6, label: 6 },
                                  { value: 7, label: 7 },
                                  { value: 8, label: 8 },
                                  { value: 9, label: 9 },
                                  { value: 10, label: 10 },
                                ]}
                              />
                            </div>
                          </div>
                        </div>
                        <div className=" hidden md:flex  justify-between mt-[14px]">
                          <div className="flex gap-3">
                            <button
                              className=" align-middle border-[1px] rounded-[6px] border-[#cbd5 mt-[14px]e1] text-primary1 text-16 font-medium leading-3 w-[46px] h-[46px]"
                              onClick={() => onRemoveProduct(item)}
                            >
                              -
                            </button>
                            <button className=" align-middle border-[1px] rounded-[6px] border-[#cbd5 mt-[14px]e1] text-primary1 text-16 font-medium leading-3 w-[46px] h-[46px]">
                              {item.quantity}
                            </button>
                            <button
                              className=" align-middle border-[1px] rounded-[6px] border-[#cbd5 mt-[14px]e1] text-primary1 text-16 font-medium leading-3 w-[46px] h-[46px]"
                              onClick={() => onAddProduct(item)}
                            >
                              +
                            </button>
                          </div>
                          <button
                            className="p-0 align-middle border-[1px] rounded-[6px] border-[#cbd5 mt-[14px]e1] align-middle w-[46px] h-[46px] cursor-pointer"
                            onClick={() => onDeleteProduct(item)}
                          >
                            <img
                              src={TrashIcon}
                              className="w-[24px] h-[24px]"
                            />
                          </button>
                        </div>
                      </div>
                      <hr className="my-4 block md:hidden  " />
                    </>
                  ))}
                  <div className="flex flex-row justify-start items-center md:border-[1px] border-[#e7e7e7] md:p-6 mt-6 gap-2">
                    <input
                      className="flex-1 w-full h-[48px] rounded-[5px] border-[1px] border-[#cbd5e1] text-[#707070] font-medium leading-[34px] text-18 px-[14px] py-2"
                      placeholder="Enter coupon code"
                      value={couponCode}
                      onChange={(e) => {
                        setCouponCode(e.target.value);
                      }}
                    />
                    <button
                      className="submit-button font-normal bg-primary1 md:min-w-[100px] h-auto py-3 text-[13px] md:text-16 rounded-[5px] text-white transition ease-in-out hover:scale-101 "
                      onClick={() => {
                        applyCoupon();
                      }}
                    >
                      Apply
                    </button>
                  </div>
                  <div className=" md:border-[1px] border-[#e7e7e7] md:p-6 mt-6 ">
                    <textarea
                      className="flex-1 w-full rounded-[5px] border-[1px] border-[#cbd5e1] text-[#707070] font-medium leading-[34px] text-18 px-[14px] py-2"
                      placeholder="Enter order notes"
                      value={formikProps.values.additional_notes}
                      onChange={(e) => {
                        formikProps.setFieldValue(
                          "additional_notes",
                          e.target.value
                        );
                      }}
                    />
                  </div>

                  <div className=" md:border-[1px] border-[#e7e7e7] md:p-6 mt-6">
                    <div className="flex flex-col gap-4">
                      <div className="flex justify-between">
                        <p className="text-[#0A0D13] text-18 font-medium leading-[12px]">
                          Subtotal
                        </p>
                        <p className="text-[#0A0D13] text-18 font-medium leading-[12px]">
                          {currency ?? ""} {subtotal}
                        </p>
                      </div>
                      {discountValue != 0 ? (
                        <div className="flex justify-between">
                          <p className="text-[#240b3b] text-18 font-medium leading-[12px]">
                            Discount
                          </p>
                          <p className="text-[#240b3b] text-18 font-medium leading-[12px]">
                            - {currency ?? ""} {discountValue}
                          </p>
                        </div>
                      ) : null}
                      <div className="flex justify-between">
                        <p className="text-[#0A0D13] text-18 font-medium leading-[12px]">
                          Delivery fee
                        </p>
                        <p className="text-[#0A0D13] text-18 font-medium leading-[12px]">
                          {currency ?? ""} {deliveryFee}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="rounded-b-[10px] md:border-[1px] border-[#e7e7e7] md:p-6 mt-4">
                    <div className="flex justify-between">
                      <p className="text-[#0A0D13] text-20 leading-[18px] font-semibold">
                        Total
                      </p>
                      <p className="text-[#0A0D13] text-20 leading-[18px] font-semibold">
                        {currency ?? ""} {total}
                      </p>
                    </div>
                  </div>
                  <div className="mt-6 md:flex gap-4 hidden">
                    {paymentMethod == "Card" && clientSecret ? (
                      <Elements options={options} stripe={stripePromise}>
                        <StripeCheckoutForm
                          onStripeCheckout={(paymentIntentId) =>
                            onSubmit(formikProps.values, paymentIntentId)
                          }
                          buttonClasses="flex-1 rounded-[5px] border-[1px] h-[70px] text-white bg-primary1 border-[#e0e0e0] py-[17px] px-[39px] text-20 font-bold leading-[34px]"
                          type={"food"}
                        />
                      </Elements>
                    ) : (
                      <>
                        <button
                          className="rounded-[5px] border-[1px] h-[48px] border-[#e0e0e0] py-[6px] px-[39px] text-primary1 text-20 font-bold leading-[34px] "
                          onClick={() => navigate(-1)}
                        >
                          Back
                        </button>
                        <button
                          className="flex-1 rounded-[5px] border-[1px] h-[48px] text-white bg-primary1 border-[#e0e0e0] py-[6px] px-[39px] text-20 font-bold leading-[34px]"
                          onClick={formikProps.handleSubmit}
                        >
                          Place order
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FoodCheckout;
