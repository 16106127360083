import apiFactory from '../../utils/apiFactory';
import {
    API_MARKETING_ONBOARDING, API_WEB
} from '../../constants/apiConfig';


export const joinVenueBoost = (payload) => {
    return new Promise(async (resolve, reject) => {
        apiFactory({ ...API_MARKETING_ONBOARDING, method: 'post' }, payload)
            .then(async (data) => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const sendFormSubmission = (payload) => {
    return new Promise(async (resolve, reject) => {
        apiFactory({ ...API_WEB, path: (API_WEB.path + '/contact'), method: 'post' }, payload)
            .then(async (data) => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            })
    });
};


export const verifyOnboardingLink = (payload) => {
    return new Promise(async (resolve, reject) => {
        apiFactory({ ...API_MARKETING_ONBOARDING, path: (API_MARKETING_ONBOARDING.path + '/verify-email-link'), method: 'post' }, payload)
            .then(async (data) => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            })
    });
};


export const verifyResetPasswordLink = (payload) => {
    return new Promise(async (resolve, reject) => {
        apiFactory({ ...API_MARKETING_ONBOARDING, path: (API_MARKETING_ONBOARDING.path + '/verify-reset-password-link'), method: 'post' }, payload)
            .then(async (data) => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            })
    });
};


export const resetPassword = (payload) => {
    return new Promise(async (resolve, reject) => {
        apiFactory({ ...API_MARKETING_ONBOARDING, path: (API_MARKETING_ONBOARDING.path + '/reset-password'), method: 'post' }, payload)
            .then(async (data) => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const trackOnboarding = (payload) => {
    return new Promise(async (resolve, reject) => {
        apiFactory({ ...API_MARKETING_ONBOARDING, path: (API_MARKETING_ONBOARDING.path + '/track-onboarding'), method: 'post' }, payload)
            .then(async (data) => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const recommendPlan = (payload) => {
    return new Promise(async (resolve, reject) => {
        apiFactory({ ...API_MARKETING_ONBOARDING, path: (API_MARKETING_ONBOARDING.path + '/recommend-plan'), method: 'post' }, payload)
            .then(async (data) => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const confirmOnboarding = (payload) => {
    return new Promise(async (resolve, reject) => {
        apiFactory({ ...API_MARKETING_ONBOARDING, path: (API_MARKETING_ONBOARDING.path + '/confirm-onboarding'), method: 'post' }, payload)
            .then(async (data) => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const confirmTrialSubscription = (payload) => {
    return new Promise(async (resolve, reject) => {
        apiFactory({ ...API_MARKETING_ONBOARDING, path: (API_MARKETING_ONBOARDING.path + '/confirm-subscription-onboarding'), method: 'post' }, payload)
            .then(async (data) => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const createCheckoutSession = (payload) => {
    return new Promise(async (resolve, reject) => {
        apiFactory({ ...API_MARKETING_ONBOARDING, path: (API_MARKETING_ONBOARDING.path + '/create-checkout-session-onboarding'), method: 'post' }, payload)
            .then(async (data) => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

