import React from "react";
// import ProductCard from "../../../components/ProductCard";
import entertainmentTag from "../../../assets/svgs/entertainment-tag.svg";
// import ProductUpdateImg1 from "../../../assets/images/blog/blog-15.png";
import FeatureImg from "../../../assets/images/see-it-in-action.png";
import Blockquote from "../../../assets/svgs/Blockquote.svg";
// import BlogImg1 from "../../../assets/images/blog/vb-blog.jpg";
// import BlogImg2 from "../../../assets/images/blog/vb-blog-2.jpg";
// import BlogImg4 from "../../../assets/images/blog/charting-success-exploring-venueboost-s-cost-free-journey.jpg";
import {Seo} from "../../../components/Seo";
import UseCaseCard from "../../../components/UseCaseCard";
import {FiX} from "react-icons/fi";
import BlogImg7 from "../../../assets/images/blog/blog7-primary.webp";

const data = {
  tagId: 1,
  date: "January 22, 2024",
  category: "Entertainment Venues",
  title: "Automation: Streamlining Marketing Efforts",
  desc: "Harnessing the power of automation, VenueBoost revolutionizes the way businesses in the entertainment industry connect with their audience. Automating repetitive tasks such as scheduling social media posts.",
  img: BlogImg7,
  slug: "automation-streamlining-marketing-efforts",
  slug_related:
    "/resources/use-cases/automation-streamlining-marketing-efforts",
  content:
    "Harnessing the power of automation, VenueBoost revolutionizes the way businesses in the entertainment industry connect with their audience. By automating repetitive tasks such as scheduling social media posts, sending personalized promotional emails, and managing customer engagement campaigns, entertainment venues can focus their energy on strategic planning and creative endeavors. ",
  values: [
    {val: "10%", label: "increase in conversions"},
    {val: "50%", label: "uplift in bookings made"},
    {val: "98%", label: "rise in recruitment"},
  ],
  reasons: [
    "Automate tracking and routing of marketing initiatives to ensure timely execution and avoid delays in promotional activities.",
    "Gain real-time insights into marketing budgets across various teams and departments, enabling effective allocation of resources and expenditure monitoring.",
    "Measure and streamline marketing processes with defined key performance indicators (KPIs) such as campaign spend, audience engagement, and conversion rates.",
    "Optimize negotiation strategies by efficiently managing marketing workload, fostering a balanced and productive team environment.",
    "Establish transparency and accountability in the approval process for marketing initiatives, from cost estimation to progress tracking, enhancing decision-making and collaboration.",
  ],
  quote:
    "“We really wanted to improve on that number,” says Joost. “We did some experiments and there were some successes, but only minor successes. And then VenueBoost came along and we realized it could help.”",
  feature_title: "So how does marketing automation work?",
  features: [
    {
      title: "Step 1",
      desc: "VenueBoost's automation capabilities capture and analyze customer engagement data across various channels, including social media and email campaigns.",
    },
    {
      title: "Step 2",
      desc: "VenueBoost breaks down marketing statistics and customer interactions to identify areas for improvement and strategic focus.",
    },
    {
      title: "Step 3",
      desc: "VenueBoost's marketing automation tools provide data-driven recommendations to optimize promotional strategies and enhance audience engagement.",
    },
    {
      title: "Step 4",
      desc: "VenueBoost stores valuable insights from marketing campaigns, empowering entertainment venues to refine future marketing efforts for greater success.",
    },
  ],
};

const caseData = [
  {
    id: 1,
    title: "Automation: Streamlining Marketing Efforts",
    category: "Entertainment Venues",
    slug: "automation-streamlining-marketing-efforts",
    date: "January 22, 2024",
    description:
      "Harnessing the power of automation, VenueBoost revolutionizes the way businesses connect with their audience. By automating repetitive tasks such as scheduling social media posts, sending personalized promotional emails, and managing customer engagement campaigns, businesses can focus their energy on strategic planning and creative endeavors. ",
    image: BlogImg7,
  },
  {
    id: 2,
    title: "Automation: Streamlining Marketing Efforts",
    category: "Accommodation",
    slug: "automation-streamlining-marketing-efforts",
    date: "January 26, 2024",
    description:
      "Harnessing the power of automation, VenueBoost revolutionizes the way businesses connect with their audience. By automating repetitive tasks such as scheduling social media posts, sending personalized promotional emails, and managing customer engagement campaigns, businesses can focus their energy on strategic planning and creative endeavors.",

    image: BlogImg7,
  },
  {
    id: 3,
    title: "Automation: Streamlining Marketing Efforts",
    category: "Food & Beverage",
    slug: "automation-streamlining-marketing-efforts",
    date: "February 2, 2024",
    description:
      "Harnessing the power of automation, VenueBoost revolutionizes the way businesses connect with their audience. By automating repetitive tasks such as scheduling social media posts, sending personalized promotional emails, and managing customer engagement campaigns, businesses can focus their energy on strategic planning and creative endeavors.",

    image: BlogImg7,
  },
];

const UseCaseDetail = () => (
  <div className='align-col-middle w-full pt-8 '>
    <Seo
      title={data.title + " - Use Case " + "| VenueBoost"}
      description={data.introduction}
      image={data.detail_image}
      url={"https://venueboost.io" + data.slug_related}
    />
    <div className='w-full max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 '>
      <div className='flex flex-col md:flex-row rounded-[13px] md:rounded-[36px] border-[#E0E0E0] border-[1px] overflow-hidden w-full'>
        <div className='w-full md:w-[60%] flex flex-col'>
          <div className='w-full py-5 px-7'>
            <div className='w-full flex flex-row items-center'>
              <div className='flex flex-row items-center px-4 py-2 rounded-full border-[1px] border-[#F4D35E]'>
                <img
                  src={entertainmentTag}
                  className='w-[30px] h-[30px] object-contain'
                  alt=''
                />
                <p className=' text-[#240B3B] font-semibold text-[13px] ml-2  '>
                  {data.category}
                </p>
              </div>
              <p className=' font-normal text-14 text-[#717484] ml-4'>
                {data.date}
              </p>
            </div>
            <h1
              className='text-[#240B3B] text-[24px] md:text-[32px] font-extrabold leading-[35
          px] md:leading-[44px] mt-4 md:mt-6'
            >
              {data.title}
            </h1>
            <p
              className=' font-medium text-14 md:text-18 text-[#667085] leading-[20
          px] md:leading-[24px] mt-4 md:mt-6'
            >
              {data.desc}
            </p>
          </div>
          <div className='w-full grid grid-cols-1 md:grid-cols-3 gap-2 px-4 md:px-8 py-3 md:py-6 rounded-[13px] md:rounded-tl-[36px] md:rounded-tr-[0px] md:rounded-b-[0px] border-[#E0E0E0] border-t-[1px]'>
            {data.values.map((v, index) => (
              <div key={index} className='flex flex-col '>
                <h2 className=' pl-3 text-[#F4D35E] text-[30px] font-bold'>
                  {v.val}
                </h2>
                <p className=' mt-2 text-[#656D72] text-[16px] font-normal'>
                  {v.label}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className='w-full md:w-[40%] flex flex-col'>
          <img
            className='rounded-[13px] md:rounded-[36px] object-cover w-full max-h-[427px] '
            src={data.img}
            alt=''
          />
        </div>
      </div>

      <div className='flex flex-col md:flex-row w-full mt-8'>
        <div className='flex flex-col w-full md:w-[60%] pr-0 md:pr-12'>
          <h1 className=' text-[24px] md:text-[32px] text-[#240B3B] font-bold'>
            Overview
          </h1>
          <p
            className='font-medium text-14 md:text-18 text-[#585965] leading-[23
            px] md:leading-[29px] mt-4 md:mt-6'
          >
            {data.content}
          </p>
          {data.reasons.map((v, index) => (
            <div key={index} className='flex flex-col mt-4 md:mt-6'>
              <h2 className=' text-[#F4D35E] text-[20px] font-bold'>
                REASON {index + 1}
              </h2>
              <p
                className='font-medium text-14 md:text-18 text-[#585965] leading-[23
            px] md:leading-[29px] mt-2'
              >
                {v}
              </p>
            </div>
          ))}
          <div className='w-full border-l-4 border-[#F4D35E] py-2 px-4 mt-4 md:mt-6'>
            <img
              src={Blockquote}
              className='w-[55px] md:w-[70px] object-contain'
              alt=''
            />
            <p
              className='font-medium text-12 md:text-16 text-[#585965] leading-[20
            px] md:leading-[28px] mt-4'
            >
              {data.quote}
            </p>
          </div>
        </div>
        <div className='w-full md:w-[40%] flex flex-col'>
          <div className=' relative mt-6 md:mt-0 flex flex-col justify-center items-center p-6 bg-[#F4F5F7] rounded-[13px] md:rounded-[36px] w-full '>
            <h3 className='text-center text-[24px] font-bold text-[#232E35]'>
              Try VenueBoost today
            </h3>
            <p className='mt-4 text-center text-[16px] font-normal text-[#656D72]'>
              Getting started is easy. No credit card required. <br />
              Cancel anytime.
            </p>
            <a href={"/get-started"}>
              <button className='font-semibold text-[#ffffff] text-[16px] mt-5 px-[80px] py-[4px] bg-gradient-to-r from-[#240B3B] via-[#3D1E59] to-[#BC14FE]'>
                UNLOCK FREE TRIAL
              </button>
            </a>
            <p className='mt-5 text-center text-[16px] font-normal text-[#240B3B]'>
              See how it works
            </p>
            <div className=' cursor-pointer absolute top-5 right-5 '>
              <FiX color='#656D72' size={16} />
            </div>
          </div>
          <div className='flex flex-col w-full  mt-4 md:mt-6'>
            <h1 className=' text-[24px] md:text-[32px] text-[#240B3B] font-bold'>
              Discover VenueBoost in Action
            </h1>
            <p
              className='font-medium text-14 md:text-18 text-[#585965] leading-[23
            px] md:leading-[29px] mt-3 md:mt-4'
            >
              Explore the seamless integration of VenueBoost's marketing
              automation tools, providing a comprehensive view of your
              promotional activities and their impact on audience engagement.
            </p>
            <img
              src={FeatureImg}
              alt=''
              className='w-full max-h-[232px] rounded-xl object-cover mt-4 md:mt-5'
            />
          </div>
        </div>
      </div>

      <div className='flex flex-col w-full mt-8 md:mt-12'>
        <h1 className=' text-[24px] md:text-[32px] text-[#240B3B] font-bold'>
          {data.feature_title}
        </h1>
        <div className='w-full grid grid-cols-1 md:grid-cols-4 gap-4 py-6 md:py-12 '>
          {data.features.map((v, index) => (
            <div
              key={index}
              className='flex flex-col rounded-xl px-4 py-6
            shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)]
            border-b-[1px] border-b-[#F4D35E]
            '
            >
              <h3 className='text-[#000000] text-[18px] font-bold'>
                {v.title}
              </h3>
              <p className=' mt-4 text-[#000000] text-[16px] font-normal'>
                {v.desc}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
    <div className='w-full py-7 md:py-11 bg-[#F4F4F4]'>
      <div className='w-full max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 flex flex-col'>
        <h1 className=' text-[24px] md:text-[32px] text-[#240B3B] font-bold'>
          Related Use Cases
        </h1>
        <div className='mt-5 md:mt-8 grid grid-cols-1 sm:grid-cols-3  gap-[38px]'>
          {caseData &&
            caseData.length > 0 &&
            caseData.map((card, index) => (
              <UseCaseCard
                img={card.image}
                tag={card.category}
                title={card.title}
                description={card.description}
                date={card.date}
                slug={card.slug}
              />
            ))}
        </div>
      </div>
    </div>
  </div>
);

export default UseCaseDetail;
