import React, { useEffect, useState } from "react";
import Faq from "../../../components/Faq";
import "./index.css";
import { Seo } from "../../../components/Seo";
import { analyticsService } from "../../../services/analytics";
import { identifyDevice, trackEvent } from "../../../utils/mixpanelUtil";
import { t } from "i18next";

export const genAccText = (label) => t(`faqs.generalAccFaq.${label}`);

export const text = (label) => t(`faqs.${label}`);
export const genRetailText = (label) => t(`faqs.generalRetailFaq.${label}`);
export const genEvText = (label) => t(`faqs.generalEVFaq.${label}`);
const vbAffiliateText = (label) => t(`faqs.venueBoostAffiliateFAQ.${label}`);
const genTabText = (label) => t(`faqs.tabs.${label}`);

const content = `${text("venueBoostDescription")} ${text(
  "streamlineOperations"
)} ${text("optimizeResources")} ${text("enhanceCustomerExperiences")} ${text(
  "tailoredFeatures"
)}`;

const ulItems1 = [
  `${text("streamlineProcesses")}\n`,
  `${text("optimizeInventory")}`,
  `${text("automateMarketing")}\n`,
  `${text("provideInsights")}\n`,
  `${text("enhanceCustomerExperience")}\n`,
  `${text("increaseOperationalEfficiency")}\n`,
  `${text("boostRevenue")}\n`,
];

const ulItems2 = [
  `${text("restaurantAndBars")}\n`,
  `${text("hotelsAndAccomodations")}\n`,
  text("retailStoresAndBoutiques"),
  text("entertainmentCentersAndEventVenues"),
  text("fitnessAndWellnessStudios"),
  text("beautyAndSpaSalons"),
  `${text("andMore")}\n`,
];

const generalFaq = [
  {
    id: 1,
    status: false,
    title: text("whatIsVB"),
    content,
  },
  {
    id: 2,
    status: false,
    title: text("howVenueBoostHelp"),
    content: `${text("venueBoostHelpIntro")}\n`,
    ulItems: ulItems1,
  },
  {
    id: 3,
    status: false,
    title: text("venueTypesQuestion"),
    content: `${text("venueTypesCatered")}\n`,
    ulItems: ulItems2,
  },
  {
    id: 4,
    status: false,
    title: text("venueBoostEaseOfUse"),
    content: `${text("intuitiveInterface")} ${text(
      "supportTeamAvailability"
    )} ${text("userFriendliness")} \n`,
  },
];

const gettingStartedFaq = [
  {
    id: 1,
    status: false,
    title: text("howDoISignForVB"),
    content: text("howDoISignForVBContent"),
  },
  {
    id: 2,
    status: false,
    title: text("systemRequirementsForVB"),
    content: text("systemRequirementsForVBContent"),
  },
  {
    id: 3,
    status: false,
    title: text("setupTimeForVB"),
    content: text("setupTimeForVBContent"),
  },
  {
    id: 4,
    status: false,
    title: text("trainingAndSupportForVB"),
    content: text("trainingAndSupportForVBContent"),
  },
];

const gettingFeaturesFunctionalityFaq = [
  {
    id: 1,
    status: false,
    title: text("reservationAndBookingFeaturesTitle"),
    content: text("reservationAndBookingFeaturesContent"),
    ulItems: [
      text("onlineBooking"),
      text("realTimeAvailability"),
      text("customizableForms"),
      text("automatedEmails"),
      text("waitlistManagement"),
      text("tableAndRoomTools"),
    ],
  },
  {
    id: 2,
    status: false,
    title: text("inventoryManagementFeaturesTitle"),
    content: text("inventoryManagementFeaturesContent"),
    ulItems: [
      text("realTimeTracking"),
      text("vendorManagement"),
      text("recipeCosting"),
      text("wastePrevention"),
      text("resourceAllocation"),
      text("predictiveOrdering"),
    ],
  },
  {
    id: 3,
    status: false,
    title: text("marketingEngagementFeaturesTitle"),
    content: text("marketingEngagementFeaturesContent"),
    ulItems: [
      text("emailCampaigns"),
      text("loyaltyPrograms"),
      text("customerFeedback"),
      text("socialMediaIntegration"),
      text("personalizedPromotions"),
      text("customerSegmentation"),
    ],
  },
  {
    id: 4,
    status: false,
    title: text("analyticsReportingFeaturesTitle"),
    content: text("analyticsReportingFeaturesContent"),
    ulItems: [
      text("salesTrends"),
      text("marketingEffectiveness"),
      text("performanceBenchmarking"),
      text("revenueForecasting"),
      text("businessImprovement"),
    ],
  },
];

const generalPPFaq = [
  {
    id: 1,
    status: false,
    title: text("pricingPlansTitle"),
    content: text("pricingPlansContent"),
  },
  {
    id: 2,
    status: false,
    title: text("enterprisePlanTitle"),
    content: text("enterprisePlanContent"),
  },
  {
    id: 3,
    status: false,
    title: text("optimizePlanTitle"),
    content: text("optimizePlanContent"),
  },
  {
    id: 4,
    status: false,
    title: text("elevatePlanTitle"),
    content: text("elevatePlanContent"),
  },
  {
    id: 5,
    status: false,
    title: text("launchPlanTitle"),
    content: text("launchPlanContent"),
  },
  {
    id: 6,
    status: false,
    title: text("discoverPlanTitle"),
    content: text("discoverPlanContent"),
  },
  {
    id: 7,
    status: false,
    title: text("customizationOptionsTitle"),
    content: text("customizationOptionsContent"),
  },
  {
    id: 8,
    status: false,
    title: text("supportProvidedTitle"),
    content: text("supportProvidedContent"),
  },
];

const venueBoostInformationSecurityFAQ = [
  {
    id: 1,
    status: false,
    title: text("venueBoostIntegrationTitle"),
    content: text("venueBoostIntegrationContent"),
    ulItems: [
      text("posSystems"),
      text("pmsSystems"),
      text("crmPlatforms"),
      text("accountingSoftware"),
      text("marketingAutomationTools"),
      text("andMore"),
    ],
    extraItem: text("integrationExtraItem"),
  },
  {
    id: 2,
    status: false,
    title: text("dataSecurityTitle"),
    content: text("dataSecurityContent"),
    ulItems: [
      text("sslEncryption"),
      text("regularBackups"),
      text("accessControls"),
      text("complianceWithRegulations"),
      text("thirdPartyAudits"),
    ],
    extraItem: text("securityExtraItem"),
  },
  {
    id: 3,
    status: false,
    title: text("dataOwnershipTitle"),
    content: text("dataOwnershipContent"),
  },
];

const venueBoostSupportResources = [
  {
    id: 1,
    status: false,
    title: text("contactSupportTitle"),
    content: text("contactSupportContent"),
    ulItems: [
      text("emailSupport"),
      text("phoneSupport"),
      text("liveChatSupport"),
      text("knowledgeBase"),
      text("communityForum"),
    ],
    extraItem: text("contactSupportExtraItem"),
  },
  {
    id: 2,
    status: false,
    title: text("learningResourcesTitle"),
    content: text("learningResourcesContent"),
    ulItems: [
      text("knowledgeBase"),
      text("blog"),
      text("webinars"),
      text("caseStudies"),
      text("communityForum"),
      text("onboardingAndTraining"),
    ],
    extraItem: text("learningResourcesExtraItem"),
  },
  {
    id: 3,
    status: false,
    title: text("certificationsTrainingTitle"),
    content: text("certificationsTrainingContent"),
    ulItems: [
      text("onlineCourses"),
      text("handsOnProjects"),
      text("recognitionAndBadges"),
      text("exclusiveResources"),
    ],
    extraItem: text("certificationsTrainingExtraItem"),
  },
  {
    id: 4,
    status: false,
    title: text("feedbackSubmissionTitle"),
    content: text("feedbackSubmissionContent"),
    ulItems: [
      text("feedbackForm"),
      text("emailFeedback"),
      text("communityForum"),
      text("userInterviews"),
    ],
    extraItem: text("feedbackSubmissionExtraItem"),
  },
  {
    id: 5,
    status: false,
    title: text("releaseUpdatesTitle"),
    content: text("releaseUpdatesContent"),
    ulItems: [text("majorReleases"), text("minorReleases"), text("hotfixes")],
    introItem: text("releaseCommunicationIntro"),
    ulItems2: [
      text("inAppNotifications"),
      text("emailNewsletters"),
      text("blogPosts"),
      text("communityForumDiscussions"),
    ],
    extraItem: text("releaseUpdatesExtraItem"),
  },
  {
    id: 6,
    status: false,
    title: text("stayInformedTitle"),
    content: text("stayInformedContent"),
    ulItems: [
      text("emailNewsletter"),
      text("socialMedia"),
      text("blog"),
      text("eventsCalendar"),
      text("pressRoom"),
    ],
    extraItem: text("stayInformedExtraItem"),
  },
];

const generalFBFaq = [
  {
    id: 1,
    status: false,
    title: text("foodBeverageBenefits"),
    content: text("foodBeverageBenefitsContent"),
  },
  {
    id: 2,
    status: false,
    title: text("onboardingProcess"),
    content: text("onboardingProcessContent"),
  },
  {
    id: 3,
    status: false,
    title: text("reservationsProcess"),
    content: text("reservationsProcessContent"),
  },
  {
    id: 4,
    status: false,
    title: text("inventoryManagement"),
    content: text("inventoryManagementContent"),
  },
  {
    id: 5,
    status: false,
    title: text("staffScheduling"),
    content: text("staffSchedulingContent"),
  },
  {
    id: 6,
    status: false,
    title: text("marketingSupport"),
    content: text("marketingSupportContent"),
  },
  {
    id: 7,
    status: false,
    title: text("loyaltyProgramsQuestion"),
    content: text("loyaltyProgramsContent"),
  },
  {
    id: 8,
    status: false,
    title: text("businessPerformanceTracking"),
    content: text("businessPerformanceTrackingContent"),
  },
  {
    id: 9,
    status: false,
    title: text("onlinePayments"),
    content: text("onlinePaymentsContent"),
  },
  {
    id: 10,
    status: false,
    title: text("guestManagement"),
    content: text("guestManagementContent"),
  },
];

const generalAccFaq = [
  {
    id: 1,
    status: false,
    title: genAccText("typeOfAccommodation"),
    content: genAccText("accommodationBusinesses"),
  },
  {
    id: 2,
    status: false,
    title: genAccText("bookingsManagement"),
    content: genAccText("bookingsManagementProcess"),
  },
  {
    id: 3,
    status: false,
    title: genAccText("inventoryManagement"),
    content: genAccText("inventoryManagementAccommodation"),
  },
  {
    id: 4,
    status: false,
    title: genAccText("staffManagement"),
    content: genAccText("staffManagementAccommodation"),
  },
  {
    id: 5,
    status: false,
    title: genAccText("marketingTools"),
    content: genAccText("marketingToolsAccommodation"),
  },
  {
    id: 6,
    status: false,
    title: genAccText("loyaltyPrograms"),
    content: genAccText("loyaltyProgramsAccommodation"),
  },
  {
    id: 7,
    status: false,
    title: genAccText("analyticsReporting"),
    content: genAccText("analyticsReportingAccommodation"),
  },
  {
    id: 8,
    status: false,
    title: genAccText("paymentProcessing"),
    content: genAccText("paymentProcessingAccommodation"),
  },
  {
    id: 9,
    status: false,
    title: genAccText("guestManagement"),
    content: genAccText("guestManagementAccommodation"),
  },
  {
    id: 10,
    status: false,
    title: genAccText("onboardingSupport"),
    content: genAccText("onboardingSupportAccommodation"),
  },
];

const generalRetailFaq = [
  {
    id: 1,
    status: false,
    title: genRetailText("venueBoostBenefitTypes"),
    content: genRetailText("venueBoostVersatility"),
  },
  {
    id: 2,
    status: false,
    title: genRetailText("venueBoostOrderManagement"),
    content: genRetailText("venueBoostOrderManagementDescription"),
  },
  {
    id: 3,
    status: false,
    title: genRetailText("venueBoostInventoryManagementQuestion"),
    content: genRetailText("venueBoostInventoryManagementDescription"),
  },
  {
    id: 4,
    status: false,
    title: genRetailText("venueBoostStaffManagementQuestion"),
    content: genRetailText("venueBoostStaffManagementDescription"),
  },
  {
    id: 5,
    status: false,
    title: genRetailText("venueBoostMarketingSupportQuestion"),
    content: genRetailText("venueBoostMarketingSupportDescription"),
  },
  {
    id: 6,
    status: false,
    title: genRetailText("venueBoostLoyaltyProgramsQuestion"),
    content: genRetailText("venueBoostLoyaltyProgramsDescription"),
  },
  {
    id: 7,
    status: false,
    title: genRetailText("venueBoostAnalyticsToolsQuestion"),
    content: genRetailText("venueBoostAnalyticsToolsDescription"),
  },
  {
    id: 8,
    status: false,
    title: genRetailText("venueBoostInPersonPaymentsQuestion"),
    content: genRetailText("venueBoostInPersonPaymentsDescription"),
  },
  {
    id: 9,
    status: false,
    title: genRetailText("venueBoostBrandProfileQuestion"),
    content: genRetailText("venueBoostBrandProfileDescription"),
  },
  {
    id: 10,
    status: false,
    title: genRetailText("venueBoostOnboardingSupportQuestion"),
    content: genRetailText("venueBoostOnboardingSupportDescription"),
  },
];

const generalEVFaq = [
  {
    id: 1,
    status: false,
    title: genEvText("typesOfVenues"),
    content: genEvText("venueBoostTypesOfVenuesDescription"),
  },
  {
    id: 2,
    status: false,
    title: genEvText("venueBoostBookingsManagementQuestion"),
    content: genEvText("venueBoostBookingsManagementDescription"),
  },
  {
    id: 3,
    status: false,
    title: genEvText("venueBoostInventoryManagementQuestion"),
    content: genEvText("venueBoostInventoryManagementDescription"),
  },
  {
    id: 4,
    status: false,
    title: genEvText("venueBoostStaffManagementQuestion"),
    content: genEvText("venueBoostStaffManagementDescription"),
  },
  {
    id: 5,
    status: false,
    title: genEvText("venueBoostMarketingSupportQuestion"),
    content: genEvText("venueBoostMarketingSupportDescription"),
  },
  {
    id: 6,
    status: false,
    title: genEvText("venueBoostLoyaltyProgramsQuestion"),
    content: genEvText("venueBoostLoyaltyProgramsDescription"),
  },
  {
    id: 7,
    status: false,
    title: genEvText("venueBoostAnalyticsToolsQuestion"),
    content: genEvText("venueBoostAnalyticsToolsDescription"),
  },
  {
    id: 8,
    status: false,
    title: genEvText("venueBoostPaymentProcessingQuestion"),
    content: genEvText("venueBoostPaymentProcessingDescription"),
  },
  {
    id: 9,
    status: false,
    title: genEvText("venueBoostGuestManagementQuestion"),
    content: genEvText("venueBoostGuestManagementDescription"),
  },
  {
    id: 10,
    status: false,
    title: genEvText("venueBoostOnboardingSupportQuestion"),
    content: genEvText("venueBoostOnboardingSupportDescription"),
  },
];

const venueBoostAffiliateFAQ = [
  {
    id: 1,
    status: false,
    title: vbAffiliateText("venueBoostReferralProgramQuestion"),
    content: vbAffiliateText("venueBoostReferralProgramDescription"),
  },
  {
    id: 2,
    status: false,
    title: vbAffiliateText("venueBoostParticipationQuestion"),
    content: vbAffiliateText("venueBoostParticipationDescription"),
  },
  {
    id: 3,
    status: false,
    title: vbAffiliateText("venueBoostReferralBenefitsQuestion"),
    content: vbAffiliateText("venueBoostReferralBenefitsDescription"),
  },
  {
    id: 4,
    status: false,
    title: vbAffiliateText("venueBoostReferralLimitQuestion"),
    content: vbAffiliateText("venueBoostReferralLimitDescription"),
  },
  {
    id: 5,
    status: false,
    title: vbAffiliateText("venueBoostFairnessQuestion"),
    content: vbAffiliateText("venueBoostFairnessDescription"),
  },
  {
    id: 6,
    status: false,
    title: vbAffiliateText("venueBoostReferralTrackingQuestion"),
    content: vbAffiliateText("venueBoostReferralTrackingDescription"),
  },
];

const tabList = [
  {
    title: genTabText("entertainmentVenuesTitle"),
    key: "entertainment_venues",
    background: "bg-gradient-to-r from-[#F4D35E] to-[#F4D35E]",
  },
  {
    title: genTabText("accommodationTitle"),
    key: "accommodation",
    background: "bg-gradient-to-r from-[#6DDFE2] to-[#6DDFE2]",
  },
  {
    title: genTabText("retailManagementTitle"),
    key: "retail_management",
    background: "bg-gradient-to-r from-[#E14848] to-[#E14848]",
  },
  {
    title: genTabText("foodBeverageTitle"),
    key: "food_beverage",
    background: "bg-gradient-to-r from-[#17CE8C] to-[#17CE8C]",
  },
];

const Faqs = () => {
  const addFaqCount = () => {
    const payload = { type: "faqs_screen" };
    analyticsService()
      .faqCount(payload)
      .then(() => {
        identifyDevice();
        trackEvent("Analytics", {
          action_category: "Faq Count",
          action_outcome: "success",
          interaction_element: "screen",
          industry: "entertainment_venues",
          venue_type: "faq",
        });
      })
      .catch(() => {
        identifyDevice();
        trackEvent("Analytics", {
          action_category: "Faq Count",
          action_outcome: "fail",
          interaction_element: "screen",
          industry: "entertainment_venues",
          venue_type: "faq",
        });
      });
  };
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    addFaqCount();
  }, []);

  const currentUrl = "https://venueboost.io/resources/faqs";
  const seoTitle = t("faqs.seo.title");
  const seoDescription = t("faqs.seo.description");

  return (
    <div className="align-col-middle">
      <Seo
        title={seoTitle + " | VenueBoost"}
        description={seoDescription}
        url={currentUrl}
      />
      <div className="w-[100vw] relative bg-[#F9F7F7]">
        <div className="align-middle flex-col top-0 left-0 right-0 bottom-0 px-4 sm:px-6 md:px-8 lg:px-16 py-8 md:py-12">
          <div class="flex items-center justify-center md:justify-start md:items-start mb-4">
            <div class="h-[34px] w-[180px] rounded-md bg-gradient-to-r from-[#240B3B] via-[#3D1E59] to-[#BC14FE] p-[1px]">
              <div class="flex h-full w-full items-center justify-center bg-[#F9F9F9] rounded-md">
                <h1 class="text-xl font-normal text-[#454545]">
                  {t("footer.resources")}
                </h1>
              </div>
            </div>
          </div>

          <p className="text-black text-[32px] md:text-[48px] leading-[45px] md:leading-[60px]  font-normal text-center">
            {t("faqs.frequently")}
          </p>
          <p className="text-transparent text-[32px] md:text-[48px] leading-[45px] md:leading-[60px] font-extrabold text-center bg-clip-text bg-gradient-to-r from-[#280B3B] via-[#5D1C82] to-[#BC14FE]">
            {t("faqs.askedQuestions")}
          </p>
          <p className="text-[#4F4F4F] text-16  font-medium leading-[17px] md:leading-[27px] mt-4 text-center">
            {t("header.resourceMenu.faq_subtitle")}
          </p>
        </div>
      </div>

      <div className="align-col-middle">
        <div className="w-[100vw] bg-[#F9F7F7]">
          <div className="max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 ">
            <div className="flex flex-col align-middle w-full">
              <h1 className="text-[#240b3b] text-30 leading-[60px] font-extrabold mt-6 text-center">
                {t("faqs.generalQuestions")}{" "}
              </h1>
              <Faq
                faqData={generalFaq}
                isFaq={true}
                className="bg-white mt-2 my-4 md:my-8"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="align-col-middle">
        <div className="w-[100vw] bg-[#F9F7F7]">
          <div className="max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 ">
            <div className="flex flex-col align-middle w-full">
              <h1 className="text-[#240b3b] text-30 leading-[60px] font-extrabold mt-6 text-center">
                {t("faqs.gettingStarted")}{" "}
              </h1>
              <Faq
                faqData={gettingStartedFaq}
                isFaq={true}
                className="bg-white mt-2 my-4 md:my-8"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="align-col-middle">
        <div className="w-[100vw] bg-[#F9F7F7]">
          <div className="max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 ">
            <div className="flex flex-col align-middle w-full">
              <h1 className="text-[#240b3b] text-30 leading-[60px] font-extrabold mt-6 text-center">
                {t("faqs.featuresFunctionality")}{" "}
              </h1>
              <Faq
                faqData={gettingFeaturesFunctionalityFaq}
                isFaq={true}
                className="bg-white mt-2 my-4 md:my-8"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="align-col-middle">
        <div className="w-[100vw] bg-[#F9F7F7]">
          <div className="max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 ">
            <div className="flex flex-col align-middle w-full">
              <h1 className="text-[#240b3b] text-30 leading-[60px] font-extrabold mt-6 text-center">
                {t("faqs.ourPricingPlans")}{" "}
              </h1>
              <Faq
                faqData={generalPPFaq}
                isFaq={true}
                className="bg-white mt-2 my-4 md:my-8"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="align-col-middle">
        <div className="w-[100vw] bg-[#F9F7F7]">
          <div className="max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 ">
            <div className="flex flex-col align-middle w-full">
              <h1 className="text-[#240b3b] text-30 leading-[60px] font-extrabold mt-6 text-center">
                {t("faqs.integrationSecurity")}{" "}
              </h1>
              <Faq
                faqData={venueBoostInformationSecurityFAQ}
                isFaq={true}
                className="bg-white mt-2 my-4 md:my-8"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="align-col-middle">
        <div className="w-[100vw] bg-[#F9F7F7]">
          <div className="max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 ">
            <div className="flex flex-col align-middle w-full">
              <h1 className="text-[#240b3b] text-30 leading-[60px] font-extrabold mt-6 text-center">
                {t("faqs.supportAndResources")}{" "}
              </h1>
              <Faq
                faqData={venueBoostSupportResources}
                isFaq={true}
                className="bg-white mt-2 my-4 md:my-8"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="align-col-middle">
        <div className="w-[100vw] bg-[#F9F7F7]">
          <div className="max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 ">
            <div
              className="bg-white rounded-[18px] w-full md:h-[80px] flex px-4 pt-4 md:px-[30px] md:pt-0 flex-col md:flex-row md:gap-4 items-center"
              role="tablist"
              aria-label="Products"
              data-tracking-container="Product Tabs"
            >
              {tabList.map((tab, index) => (
                <button
                  key={index}
                  className={`tab ${
                    activeTab === index
                      ? `rounded-[18px] ${tab.background}`
                      : ""
                  }`}
                  role="tab"
                  aria-selected={activeTab === index}
                  aria-controls={`tab-content-${index}`}
                  tabIndex="0"
                  onClick={() => setActiveTab(index)}
                >
                  {tab.title}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>

      {activeTab === 3 && (
        <div className="align-col-middle">
          <div className="w-[100vw] bg-[#F9F7F7]">
            <div className="max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 ">
              <div className="flex flex-col align-middle w-full">
                <h1 className="text-[#240b3b] text-30 leading-[40px] font-extrabold mt-6 text-center">
                  {t("faqs.footAndBeverage")}
                </h1>
                <Faq
                  faqData={generalFBFaq}
                  isFaq={true}
                  background={tabList[3].background}
                  className="bg-white mt-2 my-4 md:my-8"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {activeTab === 1 && (
        <div className="align-col-middle">
          <div className="w-[100vw] bg-[#F9F7F7]">
            <div className="max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 ">
              <div className="flex flex-col align-middle w-full">
                <h1 className="text-[#240b3b]  text-26 md:text-[42px] text-center font-extrabold leading-[40px] mt-5 md:mt-10 ">
                  {t("faqs.accommodationIndustry")}
                </h1>
                <Faq
                  faqData={generalAccFaq}
                  isFaq={true}
                  background={tabList[1].background}
                  className="bg-white mt-2 my-4 md:my-8"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {activeTab === 2 && (
        <div className="align-col-middle">
          <div className="w-[100vw] bg-[#F9F7F7]">
            <div className="max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 ">
              <div className="flex flex-col align-middle w-full">
                <h1 className="text-[#240b3b] text-30 leading-[40px] font-extrabold mt-6 text-center">
                  {t("faqs.retailManagement")}
                </h1>
                <Faq
                  faqData={generalRetailFaq}
                  isFaq={true}
                  background={tabList[2].background}
                  className="bg-white mt-2 my-4 md:my-8"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {activeTab === 0 && (
        <div className="align-col-middle">
          <div className="w-[100vw] bg-[#F9F7F7]">
            <div className="max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 ">
              <div className="flex flex-col align-middle w-full">
                <h1 className="text-[#240b3b] text-30 leading-[40px] font-extrabold mt-6 text-center">
                  {t("faqs.entertainmentVenue")}
                </h1>
                <Faq
                  faqData={generalEVFaq}
                  isFaq={true}
                  background={tabList[0].background}
                  className="bg-white mt-2 my-4 md:my-8"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {/*Referral Faqs*/}
      <div className="align-col-middle">
        <div className="w-[100vw] bg-[#F9F7F7]">
          <div className="max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 ">
            <div className="flex flex-col align-middle w-full">
              <h1 className="text-[#240b3b] text-30 leading-[40px] font-extrabold mt-6 text-center">
                {t("faqs.vbReferalProgram")}
              </h1>
              <Faq
                faqData={venueBoostAffiliateFAQ}
                isFaq={true}
                className="bg-white mt-2 my-4 md:my-8"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
