import { useMemo, useState } from "react";
import ProductCards from "./components/ProductCards";
import ButtonsSectionsConfirmOrder from "./components/ButtonsSectionsConfirmOrder";
import { drinkProducts } from "./data";
import { t } from "i18next";

export const beachBarOrderText = (value) => t(`beachbar_order.${value}`);

const Menu = () => {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const [products, setProducts] = useState(drinkProducts);

  const { numberOfProducts, totalPriceWithCurrency, currency, totalPrice } =
    useMemo(() => {
      const updatedProducts = products.filter((item) => Number(item.value) > 0);
      const numberOfProducts = updatedProducts.length;
      const totalPrice = updatedProducts.reduce((sum, product) => {
        return (
          sum +
          (product?.totalPrice ??
            parseFloat(product?.price ?? 0) * parseInt(product?.value ?? 0))
        );
      }, 0);
      const currency =
        updatedProducts.length > 0
          ? updatedProducts[0].currency
          : products?.[0]?.currency ?? "";
      const totalPriceWithCurrency = `${totalPrice} ${currency}`;

      return { numberOfProducts, totalPriceWithCurrency, currency, totalPrice };
    }, [products]);

  return (
    <div className="px-1 max-h-[100vh] overflow-y-auto w-full relative shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] overflow-hidden flex flex-col items-start justify-start leading-[normal] tracking-[normal]">
      <section className="mt-5 self-stretch flex pt-0 px-6 pb-4 box-border max-w-full">
        <ProductCards
          {...{
            products,
            setProducts,
            setIsPreviewOpen,
            setSelectedProduct,
            selectedProduct,
            currency,
          }}
          isOpen={isDetailsOpen}
          setIsOpen={setIsDetailsOpen}
        />
      </section>
      <div className="bg-white-order bottom-1 fixed z-[1000]">
        <ButtonsSectionsConfirmOrder
          amount={numberOfProducts}
          price={totalPriceWithCurrency}
          isOpen={isPreviewOpen}
          setIsDetailsOpen={setIsDetailsOpen}
          products={products}
          currency={currency}
          totalPrice={totalPrice}
          setIsPreviewOpen={setIsPreviewOpen}
          setProducts={setProducts}
        />
      </div>
    </div>
  );
};

export default Menu;
