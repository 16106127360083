// AffiliateRoutes.js
import React from "react";
import { Route } from "react-router-dom";
import RouteNames from "../../constants/route_names";

import AffiliatesRegister from "../../views/affilates-module/register";
import AffiliatesLogin from "../../views/affilates-module/login";
import AuthMiddleware from "../authMiddleware";
import AffiliatesDashboard from "../../views/affilates-module/dashboard";

const AffiliateRoutes = [
  <Route
    key={RouteNames.affiliates_register}
    exact
    path={RouteNames.affiliates_register}
    element={<AffiliatesRegister />}
  />,
  <Route
    key={RouteNames.affiliates_login}
    exact
    path={RouteNames.affiliates_login}
    element={<AffiliatesLogin />}
  />,
  <Route
    exact
    path={RouteNames.affiliates_dashboard}
    element={
      <AuthMiddleware>
        <AffiliatesDashboard />
      </AuthMiddleware>
    }
  />,
];

export default AffiliateRoutes;
