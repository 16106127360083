import { useLocation, useNavigate } from "react-router-dom";
import Icon1 from "../../assets/images/beachbarorder/icon1.svg";
import IconMenu1 from "../../assets/images/beachbarorder/iconsmenu-21.svg";
import Vector1 from "../../assets/images/beachbarorder/vector1.svg";
import Content1 from "./components/Content1";
import { beachBarOrderText } from ".";

const Success = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  return (
    <div className="bg-[#F6F6F9] min-h-[98vh] !border-none w-full relative flex flex-col justify-center items-center pb-0 pr-0 pl-px bg-cover bg-no-repeat bg-[top] leading-[normal] tracking-[normal]">
      <section className="flex-1 h-full rounded-t-7xl rounded-b-none overflow-hidden flex flex-col gap-5 items-center justify-center pt-11 px-0 pb-[15px] relative max-w-full z-[2] text-center text-base text-neutral-neutral-600 font-button-medium-extrabold">
        <div className="flex items-start py-0 w-full">
          <div className="flex-1 flex items-start justify-between pt-px px-0 pb-[0.5px]  max-w-full gap-[20px]">
            <div className="flex items-end gap-[12.2px]">
              <div className="rounded-xl bg-white flex items-center p-3 gap-[8px]">
                <img
                  className="h-5 w-5 relative overflow-hidden shrink-0 cursor-pointer"
                  loading="lazy"
                  alt=""
                  src={Icon1}
                  onClick={() => navigate(-1)}
                />
              </div>
              <div className="flex flex-col items-start justify-end pt-0 px-0 pb-[13px]">
                <img
                  className="w-[18px] h-[18px] relative"
                  loading="lazy"
                  alt=""
                  src={Vector1}
                />
              </div>
              <div className="flex flex-col items-start gap-[2px] text-left text-lg text-neutral-neutral-800 font-manrope">
                <div className="relative leading-[22px] font-semibold inline-block min-w-[126px]">
                  {beachBarOrderText("umbrellaNumber")} 12
                </div>
                <div className="relative text-xs leading-[20px] font-medium text-lightslategray whitespace-nowrap text-[#8E8EA9]">
                  {beachBarOrderText("order_successful")}
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start pt-2.5 px-0 pb-0">
              <img
                className="w-6 h-6 relative overflow-hidden shrink-0"
                loading="lazy"
                alt=""
                src={IconMenu1}
              />
            </div>
          </div>
        </div>
        <Content1 products={state} />
      </section>
    </div>
  );
};

export default Success;
