import React, { useState } from "react";
import "./index.css";
const DropdownItem = ({ item }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <div>
      <li
        className="px-10 py-3 text-md bg-[#FCF6f8]  "
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <div className="ml-1">{item.label}</div>
      </li>
      <ul
        className={`px-14 text-md bg-white ${isCollapsed ? "block" : "hidden"}`}
      >
        {item.children.map((subitem, j) => (
          <li>
            <a href={subitem.url} key={j} className="flex py-2">
              <div className="ml-4">{subitem.label}</div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

const DropdownMenuMobile2 = ({ title, menu }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  return (
    <>
      <a
        className="flex m-4 mx-6 w-full text-18 align-middle font-medium text-black1 transition flex gap-1 cursor-pointer hover:text-black1/75"
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <span className="whitespace-nowrap align-start "> {title}</span>
      </a>
      <div className={`${isCollapsed ? "block" : "hidden"}`}>
        <ul className={`grid grid-cols-1  text-18`}>
          {menu.map((item, i) => (
            <DropdownItem item={item} key={i} />
          ))}
        </ul>
      </div>
    </>
  );
};

export default DropdownMenuMobile2;
