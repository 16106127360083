import NewMenuFeatures from "../../assets/svgs/new-menu-features.svg";
import NewMenuBlog from "../../assets/svgs/new-menu-blog.svg";
import NewMenuCaseStudies from "../../assets/svgs/new-menu-case-studies.svg";
import NewMenuWhyUs from "../../assets/svgs/new-menu-why.svg";
import NewMenuAbout from "../../assets/svgs/new-menu-about.svg";
import FoodIcon from "../../assets/svgs/food.svg";
import SportIcon from "../../assets/svgs/sport.svg";
import AccommodationIcon from "../../assets/svgs/accommodation.svg";
import RetailIcon from "../../assets/svgs/retail-2.svg";
import OverviewIcon from "../../assets/svgs/food-overview-1.svg";
import FoodRestaurant from "../../assets/svgs/serve_rest.svg";
import FoodCateringServices from "../../assets/svgs/serve_caterer.svg";
import NewMenuCoffeShops from "../../assets/svgs/serve_coffee_shops.svg";
import FoodJuiceBars from "../../assets/svgs/serve_juice_bars.svg";
import FoodFoodHalls from "../../assets/svgs/serve_food_halls.svg";
import FoodPopUpRestaurants from "../../assets/svgs/serve_pop_up_dining.svg";
import FoodFoodStalls from "../../assets/svgs/serve_food_stalls.svg";
import FoodBistro from "../../assets/svgs/serve_bistro.svg";
import FoodIceCreamParlors from "../../assets/svgs/serve_Ice_cream_parlors.svg";
import FoodFastFoodJoints from "../../assets/svgs/serve_fast_food.svg";
import NewMenuFoodCourts from "../../assets/svgs/serve_food_courts.svg";
import FoodCafe from "../../assets/svgs/serve_cafe.svg";
import FoodBakeries from "../../assets/svgs/serve_bakeries.svg";
import FoodTruck from "../../assets/svgs/serve_truck.svg";
import FoodFineDining from "../../assets/svgs/serve_dining.svg";
import FoodBar from "../../assets/svgs/serve_bar.svg";
import FoodPub from "../../assets/svgs/serve_pubs.svg";
import NewMenuPizza from "../../assets/svgs/serve_pizzerias.svg";
import NewMenuDelis from "../../assets/svgs/serve_delis.svg";
import FoodSupperClubs from "../../assets/svgs/serve_supper_clubs.svg";
import FoodBuffets from "../../assets/svgs/serve_buffets.svg";

import SportAmusementParks from "../../assets/svgs/new_for_industries/serve_amusement_parks.svg";
import NewMenuCinema from "../../assets/svgs/new_for_industries/serve_cinemas.svg";
import SportConcertVenuesAndTheaters from "../../assets/svgs/new_for_industries/serve_concent.svg";
import SportArcadesAndGameCenters from "../../assets/svgs/new_for_industries/serve_game_centers.svg";
import NewMenuGolf from "../../assets/svgs/new_for_industries/serve_golf_courses.svg";
import SportSkiResorts from "../../assets/svgs/new_for_industries/serve_ski_resorts.svg";
import SportSportsComplexes from "../../assets/svgs/new_for_industries/serve_sports_arenas.svg";
import SportBowling from "../../assets/svgs/new_for_industries/serve_bowling_alleys.svg";
import SportGym from "../../assets/svgs/new_for_industries/serve_gyms_and_fitness.svg";
import NewMenuMuseum from "../../assets/svgs/new_for_industries/serve_museum.svg";
import NewMenuBeachBar from "../../assets/svgs/new_for_industries/ent_beachbar.svg";
import SportOverview from "../../assets/svgs/sport-overview.svg";
import AccommodationOverview from "../../assets/svgs/accommodation-overview.svg";
import NewMenuHotel from "../../assets/svgs/new_for_industries/accom_hotel.svg";
import AccommodationCozy from "../../assets/svgs/new_for_industries/accom_cozy.svg";
import AccommodationHostel from "../../assets/svgs/new_for_industries/accom_hostel.svg";
import AccommodationHotelchain from "../../assets/svgs/new_for_industries/accom_hotelchain.svg";
import AccommodationVacation from "../../assets/svgs/accommodation-vacation-1.svg";
import AccommodationLuxuryResorts from "../../assets/svgs/new_for_industries/accom_luxury.svg";
import AccommodationHotel from "../../assets/svgs/new_for_industries/accom_family.svg";
import NewMenuApt from "../../assets/svgs/new_for_industries/accom_service.svg";
import NewMenuBedAndBreakfast from "../../assets/svgs/new_for_industries/accom_bed.svg";
import NewMenuMotel from "../../assets/svgs/new_for_industries/accom_motel.svg";
import NewMenuCapsuleHotels from "../../assets/svgs/new_for_industries/accom_capsule.svg";
import NewMenuHostel from "../../assets/svgs/new_for_industries/accom_youth.svg";
import NewMenuCampGround from "../../assets/svgs/new_for_industries/accom_campground.svg";
import NewMenuRVParks from "../../assets/svgs/new_for_industries/accom_rv.svg";
import RetailOverview from "../../assets/svgs/retail-overview.svg";
import RetailSport from "../../assets/svgs/new_for_industries/retail_sport.svg";
import RetailHome from "../../assets/svgs/new_for_industries/retail_home.svg";
import RetailFashion from "../../assets/svgs/new_for_industries/retail_fashion.svg";
import RetailRetailChains from "../../assets/svgs/new_for_industries/retail_chain.svg";
import RetailBoutiquesAndSpecialtyStores from "../../assets/svgs/new_for_industries/retail_specialty.svg";
import RetailOnlineRetailers from "../../assets/svgs/new_for_industries/retail_online.svg";
import RetailGroceryStores from "../../assets/svgs/new_for_industries/retail_grocery.svg";
import RetailElectronicsStores from "../../assets/svgs/new_for_industries/retail_electron.svg";
import RetailPharmacies from "../../assets/svgs/new_for_industries/retail_pharmacy.svg";
import RetailAutoPartsStores from "../../assets/svgs/new_for_industries/retail_auto.svg";
import RetailLiquorStores from "../../assets/svgs/new_for_industries/retail_liquor.svg";
import RetailPetSupplyStores from "../../assets/svgs/new_for_industries/retail_pet.svg";

import GetStartedIcon from "../../assets/svgs/resources/getstarted.svg";
import GuidesIcon from "../../assets/svgs/resources/guides.svg";
import PracticeIcon from "../../assets/svgs/resources/practice.svg";
import TutorialsIcon from "../../assets/svgs/resources/tutorials.svg";
import HubFaq from "../../assets/svgs/hub_faq.svg";
import { t } from "i18next";

export const resource_menus = [
  {
    label: t("header.resourceMenu.get_started_label"),
    url: "/resources/getting-started",
    icon: GetStartedIcon,
    subtitle: t("header.resourceMenu.get_started_subtitle"),
  },
  {
    label: t("header.resourceMenu.feature_tutorial_label"),
    url: "/resources/feature-tutorials",
    icon: TutorialsIcon,
    subtitle: t("header.resourceMenu.feature_tutorial_subtitle"),
  },
  {
    label: t("header.resourceMenu.best_practice_label"),
    url: "/resources/best-practices",
    icon: PracticeIcon,
    subtitle: t("header.resourceMenu.best_practice_subtitle"),
  },
  {
    label: t("header.resourceMenu.case_studies_label"),
    url: "/resources/success-stories",
    icon: NewMenuCaseStudies,
    subtitle: t("header.resourceMenu.case_studies_subtitle"),
  },
  {
    label: t("header.resourceMenu.guide_label"),
    url: "/resources/guides-and-templates",
    icon: GuidesIcon,
    subtitle: t("header.resourceMenu.guide_subtitle"),
  },

  {
    label: t("header.resourceMenu.blog_label"),
    url: "/blog",
    icon: NewMenuBlog,
    subtitle: t("header.resourceMenu.blog_subtitle"),
  },
  {
    label: t("header.resourceMenu.faq_label"),
    url: "/resources/faqs",
    icon: HubFaq,
    subtitle: t("header.resourceMenu.faq_subtitle"),
  },
];

export const why_venueboost_menu = [
  {
    label: t("header.whyVenueBoost.label"),
    url: "/why-venueboost",
    subtitle: t("header.whyVenueBoost.subtitle"),
    icon: NewMenuWhyUs,
  },
  {
    label: t("header.aboutUs.label"),
    url: "/about",
    subtitle: t("header.aboutUs.subtitle"),
    icon: NewMenuAbout,
  },
  {
    label: t("header.resourceMenu.menu_1_label"),
    url: "/features",
    icon: NewMenuFeatures,
    subtitle: t("header.resourceMenu.menu_1_subtitle"),
  },
];

export const whoweserve_new_menu = [
  {
    label: t("header.food.label"),
    description: t("header.food.description"),
    icon: FoodIcon,
    //hoverIcon: FoodHoverIcon,
    hoverIcon: FoodIcon,
    children: [
      {
        label: t("header.food.overview.label"),
        url: "/who-we-serve/food-and-beverage",
        // url: "#",
        subtitle: t("header.food.overview.subtitle"),
        icon: OverviewIcon,
        className: "food-overview",
      },
      {
        label: t("header.food.restaurants.label"),
        url: "/who-we-serve/food-and-beverage/restaurants",
        // url: "#",
        subtitle: t("header.food.restaurants.subtitle"),
        icon: FoodRestaurant,
      },
      {
        label: t("header.food.bistros.label"),
        url: "/who-we-serve/food-and-beverage/bistros",
        // url: "#",
        subtitle: t("header.food.bistros.subtitle"),
        icon: FoodBistro,
      },
      {
        label: t("header.food.cafes.label"),
        url: "/who-we-serve/food-and-beverage/cafes",
        // url: "#",
        subtitle: t("header.food.cafes.subtitle"),
        icon: FoodCafe,
      },
      {
        label: t("header.food.bars.label"),
        url: "/who-we-serve/food-and-beverage/bars",
        // url: "#",
        subtitle: t("header.food.bars.subtitle"),
        icon: FoodBar,
      },
      {
        label: t("header.food.pubclubs.label"),
        url: "/who-we-serve/food-and-beverage/pub-and-clubs",
        // url: "#",
        subtitle: t("header.food.pubclubs.subtitle"),
        icon: FoodPub,
      },
      {
        label: t("header.food.foodtrucks.label"),
        url: "/who-we-serve/food-and-beverage/food-trucks",
        // url: "#",
        subtitle: t("header.food.foodtrucks.subtitle"),
        icon: FoodTruck,
      },
      {
        label: t("header.food.finedining.label"),
        url: "/who-we-serve/food-and-beverage/fine-dining-restaurants",
        // url: "#",
        subtitle: t("header.food.finedining.subtitle"),
        icon: FoodFineDining,
      },
      {
        label: t("header.food.bakeries.label"),
        url: "/who-we-serve/food-and-beverage/bakeries",
        // url: "#",
        subtitle: t("header.food.bakeries.subtitle"),
        icon: FoodBakeries,
      },
      {
        label: t("header.food.catering.label"),
        url: "/who-we-serve/food-and-beverage/catering-services",
        // url: "#",
        subtitle: t("header.food.catering.subtitle"),
        icon: FoodCateringServices,
      },
      {
        label: t("header.food.fastfood.label"),
        url: "/who-we-serve/food-and-beverage/fast-food-joints",
        // url: "#",
        subtitle: t("header.food.fastfood.subtitle"),
        icon: FoodFastFoodJoints,
      },
      {
        label: t("header.food.icecream.label"),
        url: "/who-we-serve/food-and-beverage/ice-cream-parlors",
        // url: "#",
        subtitle: t("header.food.icecream.subtitle"),
        icon: FoodIceCreamParlors,
      },
      {
        label: t("header.food.foodstalls.label"),
        url: "/who-we-serve/food-and-beverage/food-stalls",
        // url: "#",
        subtitle: t("header.food.foodstalls.subtitle"),
        icon: FoodFoodStalls,
      },
      {
        label: t("header.food.buffets.label"),
        url: "/who-we-serve/food-and-beverage/buffets",
        // url: "#",
        subtitle: t("header.food.buffets.subtitle"),
        icon: FoodBuffets,
      },
      {
        label: t("header.food.popups.label"),
        url: "/who-we-serve/food-and-beverage/pop-up-restaurants",
        // url: "#",
        subtitle: t("header.food.popups.subtitle"),
        icon: FoodPopUpRestaurants,
      },
      {
        label: t("header.food.juicebars.label"),
        url: "/who-we-serve/food-and-beverage/juice-bars",
        subtitle: t("header.food.juicebars.subtitle"),
        // url: "#",
        icon: FoodJuiceBars,
      },
      {
        label: t("header.food.foodhalls.label"),
        url: "/who-we-serve/food-and-beverage/food-halls",
        // url: "#",
        subtitle: t("header.food.foodhalls.subtitle"),
        icon: FoodFoodHalls,
      },
      {
        label: t("header.food.supperclubs.label"),
        url: "/who-we-serve/food-and-beverage/supper-clubs",
        // url: "#",
        subtitle: t("header.food.supperclubs.subtitle"),
        icon: FoodSupperClubs,
      },
      {
        label: "Pizzerias",
        url: "/who-we-serve/food-and-beverage/pizzeria",
        // url: "#",
        subtitle: "Streamline ordering, delivery and dine-in",
        icon: NewMenuPizza,
      },
      {
        label: "Coffee Shops",
        url: "/who-we-serve/food-and-beverage/coffe-shop",
        // url: "#",
        subtitle: "Build loyalty and showcase specialty brews",
        icon: NewMenuCoffeShops,
      },
      {
        label: "Delis",
        url: "/who-we-serve/food-and-beverage/delis",
        // url: "#",
        subtitle: "Manage made-to-order sandwiches and salads",
        icon: NewMenuDelis,
      },
      {
        label: "Food Courts",
        url: "/who-we-serve/food-and-beverage/food-court",
        // url: "#",
        subtitle: "Unify options for malls, airports and more",
        icon: NewMenuFoodCourts,
      },
    ],
  },
  {
    label: t("header.new_menu.sport_entertainment.label"),
    description: t("header.new_menu.sport_entertainment.desc"),
    icon: SportIcon,
    hoverIcon: SportIcon,
    children: [
      {
        label: t("header.new_menu.sport_entertainment.content.label_1"),
        url: "/who-we-serve/entertainment-venues",
        subtitle: t("header.new_menu.sport_entertainment.content.subtitle_1"),
        icon: SportOverview,
        className: "entertainment-overview",
      },
      {
        label: t("header.new_menu.sport_entertainment.content.label_2"),
        url: "/who-we-serve/entertainment-venues/golf-courses",
        // url: "#",
        subtitle: t("header.new_menu.sport_entertainment.content.subtitle_2"),
        icon: NewMenuGolf,
      },
      {
        label: t("header.new_menu.sport_entertainment.content.label_3"),
        url: "/who-we-serve/entertainment-venues/bowling",
        // url: "#",
        subtitle: t("header.new_menu.sport_entertainment.content.subtitle_3"),
        icon: SportBowling,
      },
      {
        label: t("header.new_menu.sport_entertainment.content.label_4"),
        url: "/who-we-serve/entertainment-venues/gyms-and-fitness-centers",
        // url: "#",
        subtitle: t("header.new_menu.sport_entertainment.content.subtitle_4"),
        icon: SportGym,
      },
      {
        label: t("header.new_menu.sport_entertainment.content.label_5"),
        url: "/who-we-serve/entertainment-venues/arcades-and-game-centers",
        // url: "#",
        subtitle: t("header.new_menu.sport_entertainment.content.subtitle_5"),
        icon: SportArcadesAndGameCenters,
      },
      {
        label: t("header.new_menu.sport_entertainment.content.label_6"),
        url: "/who-we-serve/entertainment-venues/sports-arenas",
        // url: "#",
        subtitle: t("header.new_menu.sport_entertainment.content.subtitle_6"),
        icon: SportSportsComplexes,
      },
      {
        label: t("header.new_menu.sport_entertainment.content.label_7"),
        url: "/who-we-serve/entertainment-venues/concert-halls-and-theaters",
        // url: "#",
        subtitle: t("header.new_menu.sport_entertainment.content.subtitle_7"),
        icon: SportConcertVenuesAndTheaters,
      },
      {
        label: t("header.new_menu.sport_entertainment.content.label_8"),
        url: "/who-we-serve/entertainment-venues/amusement-parks",
        // url: "#",
        subtitle: t("header.new_menu.sport_entertainment.content.subtitle_8"),
        icon: SportAmusementParks,
      },
      {
        label: t("header.new_menu.sport_entertainment.content.label_9"),
        url: "/who-we-serve/entertainment-venues/ski-resorts",
        // url: "#",
        subtitle: t("header.new_menu.sport_entertainment.content.subtitle_9"),
        icon: SportSkiResorts,
      },
      {
        label: "Museums",
        url: "/who-we-serve/entertainment-venues/museum",
        // url: "#",
        subtitle: "Curate engaging exhibits and streamline ticketing",
        icon: NewMenuMuseum,
      },
      {
        label: "Cinemas",
        url: "/who-we-serve/entertainment-venues/cinema",
        // url: "#",
        subtitle: "Enhance movie experiences end-to-end",
        icon: NewMenuCinema,
      },
      {
        label: "Beach Bars",
        url: "/who-we-serve/entertainment-venues/beach-bars",
        // url: "#",
        subtitle: "Efficiently Manage Beach Bar Stock Levels\n",
        icon: NewMenuBeachBar,
      },
    ],
  },
  {
    label: t("header.new_menu.accommodation.label"),
    description: t("header.new_menu.accommodation.desc"),
    icon: AccommodationIcon,
    hoverIcon: AccommodationIcon,

    children: [
      {
        label: t("header.new_menu.accommodation.content.label_1"),
        url: "/who-we-serve/accommodation",
        subtitle: t("header.new_menu.accommodation.content.subtitle_1"),
        icon: AccommodationOverview,
        className: "accommodation-overview",
      },
      {
        label: t("header.new_menu.accommodation.content.label_2"),
        url: "/who-we-serve/accommodation/hotels",
        // url: "#",
        subtitle: t("header.new_menu.accommodation.content.subtitle_2"),
        icon: NewMenuHotel,
      },
      {
        label: t("header.new_menu.accommodation.content.label_3"),
        url: "/who-we-serve/accommodation/cozy-retreats",
        // url: "#",
        subtitle: t("header.new_menu.accommodation.content.subtitle_3"),
        icon: AccommodationCozy,
      },
      {
        label: t("header.new_menu.accommodation.content.label_4"),
        url: "/who-we-serve/accommodation/hostels",
        // url: "#",
        subtitle: t("header.new_menu.accommodation.content.subtitle_4"),
        icon: AccommodationHostel,
      },
      {
        label: t("header.new_menu.accommodation.content.label_5"),
        url: "/who-we-serve/accommodation/hotel-chains",
        // url: "#",
        subtitle: t("header.new_menu.accommodation.content.subtitle_5"),
        icon: AccommodationHotelchain,
      },
      {
        label: t("header.new_menu.accommodation.content.label_6"),
        url: "/who-we-serve/accommodation/vacation-rentals",
        // url: "#",
        subtitle: t("header.new_menu.accommodation.content.subtitle_6"),
        icon: AccommodationVacation,
      },
      {
        label: t("header.new_menu.accommodation.content.label_7"),
        url: "/who-we-serve/accommodation/luxury-resorts",
        // url: "#",
        subtitle: t("header.new_menu.accommodation.content.subtitle_7"),
        icon: AccommodationLuxuryResorts,
      },
      {
        label: t("header.new_menu.accommodation.content.label_8"),
        url: "/who-we-serve/accommodation/family-resorts",
        // url: "#",
        subtitle: t("header.new_menu.accommodation.content.subtitle_8"),
        icon: AccommodationHotel,
      },
      {
        label: t("header.new_menu.accommodation.content.label_9"),
        url: "/who-we-serve/accommodation/service-apartments",
        // url: "#",
        subtitle: t("header.new_menu.accommodation.content.subtitle_9"),
        icon: NewMenuApt,
      },
      {
        label: "Bed and Breakfasts",
        url: "/who-we-serve/accommodation/bed-and-breakfast",
        // url: "#",
        subtitle: "Create charming, personalized stays",
        icon: NewMenuBedAndBreakfast,
      },
      {
        label: "Motels",
        url: "/who-we-serve/accommodation/motel",
        // url: "#",
        subtitle: "Drive efficiency for budget-friendly roadside stays",
        icon: NewMenuMotel,
      },
      {
        label: "Capsule Hotels",
        url: "/who-we-serve/accommodation/capsule-hotel",
        // url: "#",
        subtitle: "Tech for compact hotels for solo travelers",
        icon: NewMenuCapsuleHotels,
      },
      {
        label: "Youth Hostels",
        url: "/who-we-serve/accommodation/youth-hostel",
        // url: "#",
        subtitle: "Engage student and backpacker customers",
        icon: NewMenuHostel,
      },
      {
        label: "Campgrounds",
        url: "/who-we-serve/accommodation/campground",
        // url: "#",
        subtitle: "Streamline reservations & optimize seasonal operations",
        icon: NewMenuCampGround,
      },
      {
        label: "RV Parks",
        url: "/who-we-serve/accommodation/rv-park",
        // url: "#",
        subtitle: "Manage spaces and amenities for mobile stays",
        icon: NewMenuRVParks,
      },
    ],
  },
  {
    label: t("header.new_menu.retail.label"),
    description: t("header.new_menu.retail.desc"),
    icon: RetailIcon,
    hoverIcon: RetailIcon,

    children: [
      {
        label: t("header.new_menu.retail.content.label_1"),
        url: "/who-we-serve/retail-management",
        subtitle: t("header.new_menu.retail.content.subtitle_1"),
        icon: RetailOverview,
        className: "retail-overview",
      },
      {
        label: t("header.new_menu.retail.content.label_2"),
        url: "/who-we-serve/retail-management/sporting-goods-stores",
        // url: "#",
        subtitle: t("header.new_menu.retail.content.subtitle_2"),
        icon: RetailSport,
      },
      {
        label: t("header.new_menu.retail.content.label_3"),
        url: "/who-we-serve/retail-management/home-decor",
        // url: "#",
        subtitle: t("header.new_menu.retail.content.subtitle_3"),
        icon: RetailHome,
      },
      {
        label: t("header.new_menu.retail.content.label_4"),
        url: "/who-we-serve/retail-management/fashion-boutiques",
        // url: "#",
        subtitle: t("header.new_menu.retail.content.subtitle_4"),
        icon: RetailFashion,
      },
      {
        label: t("header.new_menu.retail.content.label_5"),
        url: "/who-we-serve/retail-management/retail-chains",
        // url: "#",
        subtitle: t("header.new_menu.retail.content.subtitle_5"),
        icon: RetailRetailChains,
      },
      {
        label: t("header.new_menu.retail.content.label_6"),
        url: "/who-we-serve/retail-management/boutiques-and-specialty-stores",
        // url: "#",
        subtitle: t("header.new_menu.retail.content.subtitle_6"),
        icon: RetailBoutiquesAndSpecialtyStores,
      },
      {
        label: t("header.new_menu.retail.content.label_7"),
        url: "/who-we-serve/retail-management/online-shops",
        // url: "#",
        subtitle: t("header.new_menu.retail.content.subtitle_7"),
        icon: RetailOnlineRetailers,
      },
      {
        label: t("header.new_menu.retail.content.label_8"),
        url: "/who-we-serve/retail-management/grocery-stores",
        // url: "#",
        subtitle: t("header.new_menu.retail.content.subtitle_8"),
        icon: RetailGroceryStores,
      },
      {
        label: t("header.new_menu.retail.content.label_9"),
        url: "/who-we-serve/retail-management/electronics-stores",
        // url: "#",
        subtitle: t("header.new_menu.retail.content.subtitle_9"),
        icon: RetailElectronicsStores,
      },
      {
        label: t("header.new_menu.retail.content.label_10"),
        url: "/who-we-serve/retail-management/pharmacies",
        // url: "#",
        subtitle: t("header.new_menu.retail.content.subtitle_10"),
        icon: RetailPharmacies,
      },
      {
        label: t("header.new_menu.retail.content.label_11"),
        url: "/who-we-serve/retail-management/auto-parts-stores",
        // url: "#",
        subtitle: t("header.new_menu.retail.content.subtitle_11"),
        icon: RetailAutoPartsStores,
      },
      {
        label: t("header.new_menu.retail.content.label_12"),
        url: "/who-we-serve/retail-management/liquor-stores",
        // url: "#",
        subtitle: t("header.new_menu.retail.content.subtitle_12"),
        icon: RetailLiquorStores,
      },
      {
        label: t("header.new_menu.retail.content.label_13"),
        url: "/who-we-serve/retail-management/pet-supply-stores",
        // url: "#",
        subtitle: t("header.new_menu.retail.content.subtitle_13"),
        icon: RetailPetSupplyStores,
      },
    ],
  },
];
