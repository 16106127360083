import React, { useEffect, useState } from "react";
import FormTextInput from "../../components/FormTextInput";
import "./index.css";
import FormTextarea from "../../components/FormTextarea";
import { Seo } from "../../components/Seo";
import { t } from "i18next";
import { CircularProgress, FormControlLabel } from "@mui/material";
import { Checkbox } from "antd";
import { NotificationManager } from "react-notifications";
import { sendFormSubmission } from "../../redux/actions/onboarding";
import InfoCard from "../../components/Contact/InfoCard";
import { FiCheck } from "react-icons/fi";
import FreePlanCard from "../../components/FreePlanCard";
// import { handleTiktokService } from "../../services/tiktok-service";
// import { createUUID } from "../../utils/common";

const cuText = (label) => t(`contact_us.${label}`);
const isRequiredText = (label) => cuText(label) + " " + cuText(`isRequired`);
const emailRequired = cuText(`email`) + " " + cuText(`isRequired`);

const Contact = () => {
  const [data, setData] = useState({
    subscribe: false,
  });
  const [submitting, setSubmitting] = useState(false);

  // const external_id = createUUID();
  // const eventId = createUUID();

  const handleChange = (e) => {
    setData((info) => ({ ...info, [e.target.name]: e.target.value }));
  };

  const handleCheckboxChange = (e) => {
    setData((info) => ({ ...info, subscribe: e.target.checked }));
  };

  const [captchaVerified, setCaptchaVerified] = useState(false);

  const handleCustomRecaptchaExecution = () => {
    window.grecaptcha
      .execute("6LfMHVgpAAAAAMsm_3myP1DkwC2R4TGlBZuoi8eA", { action: "submit" })
      .then(
        (token) => {
          setCaptchaVerified(true);
          // Proceed with your custom logic here, like calling onSubmit()
          onSubmit();
        },
        (error) => {
          // Handle the error here
          console.error("CAPTCHA verification failed:", error);
          // Optionally, you can set captchaVerified to false if the verification fails
          setCaptchaVerified(false);
          // Handle the error as needed
          // For example, you can show an error message to the user
        }
      );
  };

  const onSubmit = () => {
    if (!data.first_name) {
      NotificationManager.warning(
        isRequiredText("firstNameField"),
        "Warning",
        3000
      );
      return;
    }

    if (!data.last_name) {
      NotificationManager.warning(
        isRequiredText("lastNameField"),
        "Warning",
        3000
      );
      return;
    }

    if (!data["email"]) {
      NotificationManager.warning(emailRequired, "Warning", 3000);
      return;
    }

    // Email validation
    if (!data.email || !/\S+@\S+\.\S+/.test(data.email)) {
      NotificationManager.warning(cuText("invalidEmail"), "Warning", 3000);
      return;
    }

    if (!data.email) {
      NotificationManager.warning(emailRequired, "Warning", 3000);
      return;
    }

    if (!data.message) {
      NotificationManager.warning(
        isRequiredText("contactReason"),
        "Warning",
        3000
      );
      return;
    }

    if (!captchaVerified) {
      NotificationManager.warning(
        cuText("captchaVerificationFailed"),
        "Warning",
        3000
      );
      return;
    }
    setSubmitting(true);
    sendFormSubmission(data)
      .then((data) => {
        setSubmitting(false);
        setData(null);
        NotificationManager.success(
          `${cuText("applicationSubmittedSuccess")}\n`,
          "Success",
          5000
        );
      })
      .catch((err) => {
        setSubmitting(false);
        const errorKeys = Object.keys(err?.error || {});
        if (errorKeys.length > 0) {
          const firstErrorKey = errorKeys[0];
          const firstErrorMessage = err?.error[firstErrorKey][0];
          NotificationManager.error(
            firstErrorMessage || cuText("somethingWentWrong")
          );
        } else {
          NotificationManager.error(
            t("who_we_serve.food_industry.error_msg_oops")
          );
        }

        throw new Error(cuText("errorJoiningVenueBoost"));
      });
    // .finally(() => {
    //   // Manually execute reCAPTCHA verification
    //   handleTiktokService({
    //     email: data.email ?? "",
    //     event: "VenueBoost Contact Form Submission",
    //     external_id,
    //     eventId,
    //   });
    // });
  };

  useEffect(() => {
    // Initialize reCAPTCHA when the component mounts
    // Wait for the script to be loaded and ready
    const recaptchaScript = document.createElement("script");
    recaptchaScript.src =
      "https://www.google.com/recaptcha/api.js?render=6LfMHVgpAAAAAMsm_3myP1DkwC2R4TGlBZuoi8eA";
    recaptchaScript.async = true;
    recaptchaScript.defer = true;

    // Append the script to the document body
    document.body.appendChild(recaptchaScript);
  }, []);

  const currentUrl = "https://venueboost.io/contact-us";
  const seoTitle = "Get in Touch with VenueBoost | Contact Us";
  const seoDescription = cuText("contactVenueBoost");

  return (
    <div className="align-col-middle">
      <Seo
        title={seoTitle + " | VenueBoost"}
        description={seoDescription}
        url={currentUrl}
      />
      <div className="align-col-middle">
        <div className="w-[100vw]">
          <div className="w-full bg-[#F3F3F3] pb-[60px] md:pb-[180px]">
            <div className="max-w-screen-main mx-auto ">
              <div className="flex flex-col px-4 md:px-0">
                <div className="w-full my-[32px] md:my-[40px] flex align-middle">
                  <h1 className="text-20 md:text-24 font-semibold text-[#000000] mt-8">
                    {t("components.contact.contact_venueBoost_desc")}
                  </h1>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6">
                  {[
                    {
                      title: cuText("reachOutByPhone"),
                      desc: (
                        <>
                          Dial{" "}
                          <a
                            href="tel:(844) 248-1465"
                            className="text-[#240B3B]"
                          >
                            (844) 248-1465
                          </a>{" "}
                          {cuText("businessHoursSupport")}
                        </>
                      ),
                    },
                    {
                      title: `${cuText("dropUsALine")}\n`,
                      desc: (
                        <>
                          Email us at{" "}
                          <a
                            href="mailto:support@venueboost.io"
                            className="text-[#240B3B]"
                          >
                            {cuText("emailSupport")}
                          </a>{" "}
                          {cuText("expectPromptReply")}
                        </>
                      ),
                    },
                    {
                      title: `${cuText("liveChatSupport")}\n`,
                      desc: (
                        <>
                          {cuText("instantConnectLiveChat")}{" "}
                          <a href="/" className="text-[#240B3B]">
                            {cuText("ourWebsite")}
                          </a>{" "}
                          {cuText("immediateResponses")}
                        </>
                      ),
                    },
                  ].map((item, index) => (
                    <InfoCard key={index} title={item.title} desc={item.desc} />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full bg-[#FFFFFF] flex">
            <div className="w-full max-w-screen-main px-[20px] md:px-0 md:mx-auto md:mt-[-140px]">
              <div className="w-full contact-box flex flex-col-reverse md:flex-row rounded-[16px] overflow-hidden bg-[#f9f7f7]  mt-4 mb-8  md:mb-20">
                <div className=" hidden md:flex flex-col justify-center md:w-[40%] p-10 rounded-tl-[16px] rounded-bl-[16px] bg-gradient-to-r from-[#240B3B] via-[#3D1E59] to-[#BC14FE]">
                  <h1 className=" font-extrabold text-[42px] text-white">
                    Connect With Us
                  </h1>
                  <div className="bg-[#BC14FE] w-[94px] h-[6px] mt-[48px] mb-[22px]" />
                  <h3 className=" font-extrabold text-[24px] text-white">
                    {cuText("boostYourBusinessTogether")}
                  </h3>
                  <p className=" font-normal text-[20px] text-white mt-3">
                    {cuText("venueBoostTransformation")}
                  </p>
                </div>
                <div className="w-full md:w-[60%] z-10 mt-0 rounded-tr-[16px] rounded-br-[16px]">
                  <div className="py-3 px-6 md:px-8 text-16 font-medium text-[#5C1C81] bg-[#E3E3E3]">
                    {cuText("sendUsMessage")}
                  </div>
                  <div className="grid grid-cols-1 gap-7 px-6 md:px-8 pt-6 bg-white">
                    <div className="grid grid-cols-2 gap-7">
                      <FormTextInput
                        name="first_name"
                        placeholder={t("components.contact.first_name")}
                        value={data?.first_name || ""}
                        onChange={handleChange}
                      />
                      <FormTextInput
                        name="last_name"
                        placeholder={t("components.contact.last_name")}
                        value={data?.last_name || ""}
                        onChange={handleChange}
                      />
                    </div>
                    <FormTextInput
                      name="email"
                      type={"email"}
                      placeholder={t("components.contact.email")}
                      value={data?.email || ""}
                      onChange={handleChange}
                    />

                    <FormTextarea
                      name="message"
                      rows={10}
                      placeholder={t("components.contact.message")}
                      value={data?.message || ""}
                      onChange={handleChange}
                      inputClassName="w-full h-[100px]"
                    />
                  </div>
                  <div className="flex flex-row md:justify-between w-full bg-white px-6 md:px-8 py-4">
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="subscribe"
                          checked={data?.subscribe}
                          onChange={handleCheckboxChange}
                          style={{
                            fontSize: "17px",
                            marginRight: "10px",
                            marginLeft: "10px",
                            marginTop: "5px",
                          }}
                        />
                      }
                      label={
                        "\n" +
                        "Sign up to get the latest updates and insights from VenueBoost.\n" +
                        "\n"
                      }
                      style={{ fontSize: "17px" }}
                    />
                    <button
                      disabled={submitting}
                      className="submit-button font-normal bg-gradient-to-r from-[#240B3B] via-[#3D1E59] to-[#BC14FE] mt-4 mb-3  md:w-max h-auto py-2 md:py-3 md:px-6 text-[13px] md:text-16 rounded-[5px] text-white transition ease-in-out hover:scale-101 "
                      onClick={handleCustomRecaptchaExecution}
                    >
                      {submitting ? (
                        <CircularProgress size={16} />
                      ) : (
                        t("components.contact.submit_now")
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <div className="w-full mt-8 mb-8">
                <h2 className="text-[20px] md:text-[24px] font-bold mb-4">
                  {cuText("venueBoostAdvantage")}
                </h2>
                <div className="flex flex-row w-full items-start">
                  <FiCheck color="#E39DFF" size={24} className="mt-1 mr-2" />
                  <p
                    className={
                      "flex-1 text-14 md:text-18 font-semibold text-[#666A73] leading-4 md:leading-7 "
                    }
                  >
                    {cuText("exploreVenueBoostTechnology")}
                  </p>
                </div>
                <div className="flex flex-row w-full items-start mt-4">
                  <FiCheck color="#E39DFF" size={24} className="mt-1 mr-2" />
                  <p
                    className={
                      "flex-1 text-14 md:text-18 font-semibold text-[#666A73] leading-6 md:leading-7  "
                    }
                  >
                    {cuText("signUpFreePlan")}
                  </p>
                </div>
                <div className="flex flex-row w-full items-start mt-4">
                  <FiCheck color="#E39DFF" size={24} className="mt-1 mr-2" />
                  <p
                    className={
                      "flex-1 text-14 md:text-18 font-semibold text-[#666A73] leading-6 md:leading-7 "
                    }
                  >
                    {cuText("teamReadyToGuide")}
                  </p>
                </div>
                <p
                  className={
                    "mt-4 text-14 md:text-18 font-semibold text-[#666A73] leading-6 md:leading-7 last:"
                  }
                >
                  {cuText("letsConnect")}{" "}
                  <a href="#" className="text-[#BC14FE]">
                    {cuText("startWithFreePlan")}
                  </a>
                </p>
              </div>
              <div className="w-full mt-16 mb-16">
                <FreePlanCard />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
