import React from "react";
import { motion } from "framer-motion";
import SwitchIcon1 from "../../assets/svgs/switch1.svg";
import SwitchIcon2 from "../../assets/svgs/switch2.svg";
import SwitchIcon3 from "../../assets/svgs/switch3.svg";
import SwitchIcon4 from "../../assets/svgs/switch4.svg";

import "./index.css";
import { t } from "i18next";

const Switch = () => {
  return (
    <div className="align-col-middle">
      <div className="w-[100vw] bg-[#F9F7F7]">
        <div className="mx-auto max-w-screen-main px-4 sm:px-6 md:px-8 lg:px-16">
          <div className="flex flex-col align-middle">
            <p className="text-26 md:text-[42px] font-extrabold text-[#222222] mt-4 md:mt-10">
              {t("components.switch.title1")} <span className="text-[#240b3b]">{t("components.switch.title2")}</span> {t("components.switch.title3")}
            </p>
            <div className="border-[#240b3b] border-[2px] md:border-[3px] px-[18px] md:px-[44px] rounded-[1.5px] my-4 md:my-8 "></div>
            <p className="text-[#666A73] text-14 md:text-20 w-[70%] text-center">
              {t("components.switch.subtitle")}
            </p>
          </div>
          <div className="mt-8 md:mt-20 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-5 md:gap-[76px] mb-4 md:mb-32">
            <motion.div
              className="flex-1"
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ amount: 0.8, once: true }}
            >
              <motion.div
                className="h-full flex"
                variants={{
                  offscreen: { opacity: 0, y: 100 },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      type: "spring",
                      bounce: 0,
                      duration: 1,
                    },
                  },
                }}
              >
                <div className="flex flex-col items-center  border-[1px] rounded-[13px] border-[#E0E0E0] bg-white p-11 md:p-6">
                  <img
                    src={SwitchIcon1}
                    className=" w-[50px] h-[50px] mb-8 mt-5"
                  />
                  <p className="text-[#333333] text-18 md:text-22 font-bold text-center mb-5">
                    {t("components.switch.platform")}
                    
                  </p>
                  <p className="text-[#666666] font-normal text-center text-16">
                    {t("components.switch.platform_sub")}
                  </p>
                </div>
              </motion.div>
            </motion.div>
            <motion.div
              className="flex-1"
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ amount: 0.8, once: true }}
            >
              <motion.div
                className="h-full flex"
                variants={{
                  offscreen: { opacity: 0, y: 100 },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      type: "spring",
                      bounce: 0,
                      duration: 1,
                      delay: 0.1,
                    },
                  },
                }}
              >
                <div className="flex flex-col items-center  border-[1px] rounded-[13px] border-[#E0E0E0] bg-white p-11 md:p-6">
                  <img
                    src={SwitchIcon2}
                    className=" w-[50px] h-[50px]  mb-8 mt-5"
                  />
                  <p className="text-[#333333] text-18 md:text-22 font-bold text-center  mb-5">
                  {t("components.switch.trading_required")}
                  </p>
                  <p className="text-[#666666] font-normal text-center text-16">
                  {t("components.switch.trading_required_sub")}
                  </p>
                </div>
              </motion.div>
            </motion.div>
            <motion.div
              className="flex-1"
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ amount: 0.8, once: true }}
            >
              <motion.div
                className="h-full flex"
                variants={{
                  offscreen: { opacity: 0, y: 100 },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      type: "spring",
                      bounce: 0,
                      duration: 1,
                      delay: 0.2,
                    },
                  },
                }}
              >
                <div className="flex flex-col  border-[1px] items-center rounded-[13px] border-[#E0E0E0] bg-white p-11 md:p-6">
                  <img
                    src={SwitchIcon3}
                    className=" w-[50px] h-[50px]  mb-8 mt-5"
                  />
                  <p className="text-[#333333] text-18 md:text-22 font-bold text-center  mb-5">
                  {t("components.switch.transition")}
                  </p>
                  <p className="text-[#666666] font-normal text-center text-16">
                  {t("components.switch.transition_sub")}
                  </p>
                </div>
              </motion.div>
            </motion.div>
            <motion.div
              className="flex-1 "
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ amount: 0.8, once: true }}
            >
              <motion.div
                className="w-full align-middle flex"
                variants={{
                  offscreen: { opacity: 0, y: 100 },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      type: "spring",
                      bounce: 0,
                      duration: 1,
                      delay: 0.3,
                    },
                  },
                }}
              >
                <div className="flex flex-col  border-[1px] items-center rounded-[13px] border-[#E0E0E0] bg-white p-11 md:p-6">
                  <img
                    src={SwitchIcon4}
                    className=" w-[50px] h-[50px]  mb-8 mt-5"
                  />
                  <p className="text-[#333333] text-18 md:text-22 font-bold text-center  mb-5">
                  {t("components.switch.payment")}{" "}
                  </p>
                  <p className="text-[#666666] font-normal text-center text-16">
                  {t("components.switch.payment_sub")}
                  </p>
                </div>
              </motion.div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Switch;
