import React, {useEffect, useState} from "react";
import "./index.css";
// import Carousel from "react-multi-carousel";
import {Pagination, Select} from "antd";
import "react-multi-carousel/lib/styles.css";
// import BannerImg from "../../../assets/images/sitemap.png";
// import BlogImg1 from "../../../assets/images/blog/vb-blog.jpg";
// import BlogImg2 from "../../../assets/images/blog/vb-blog-2.jpg";
// import BlogImg3 from "../../../assets/images/blog/blog_venueboost_venue_operations.jpg";
// import BlogImg4 from "../../../assets/images/blog/charting-success-exploring-venueboost-s-cost-free-journey.jpg";
// import BlogImg5 from "../../../assets/images/blog/join-our-exclusive-soft-launch-and-be-part-of-the-community.png";
// import BlogImg6 from "../../../assets/images/blog/copy-ai-vs-chatgpt.png";
// import BlogImg62 from "../../../assets/images/blog/blog-6-january-3.jpg";
import BlogImg7 from "../../../assets/images/blog/blog7-primary.webp";
// import BlogImg8 from "../../../assets/images/blog/blog8-primary.jpg";
// import BlogImg9 from "../../../assets/images/blog/blog-9.jpg";
// import BlogImg10 from "../../../assets/images/blog/blog-10.png";
// import BlogImg11 from "../../../assets/images/blog/blog-11.png";
// import BlogImg12 from "../../../assets/images/blog/blog-12.png";
// import BlogImg13 from "../../../assets/images/blog/blog-13.png";
// import BlogImg14 from "../../../assets/images/blog/blog-14.png";
// import BlogImg15 from "../../../assets/images/blog/blog-15.png";
// import BlogImg16 from "../../../assets/images/blog/blog-16.png";
// import BlogImg17 from "../../../assets/images/blog/blog-17.png";
// import BlogImg18 from "../../../assets/images/blog/blog-18.png";

import UseCaseCard from "../../../components/UseCaseCard";
import {Seo} from "../../../components/Seo";
// import {isMobile} from "react-device-detect";
import EntertainmentVenues from "../../../components/Icons/EntertainmentVenues";
import Increase from "../../../components/Icons/Increase";
import DoubleCheck from "../../../components/Icons/DoubleCheck";
import RetailManagement from "../../../components/Icons/RetailManagement";
import Accommodation from "../../../components/Icons/Accommodation";
import FoodBeverage from "../../../components/Icons/FoodBeverage";

const caseData = [
  {
    id: 1,
    title: "Automation: Streamlining Marketing Efforts",
    category: "Entertainment Venues",
    slug: "automation-streamlining-marketing-efforts",
    date: "January 22, 2024",
    description:
      "Harnessing the power of automation, VenueBoost revolutionizes the way businesses connect with their audience. By automating repetitive tasks such as scheduling social media posts, sending personalized promotional emails, and managing customer engagement campaigns, businesses can focus their energy on strategic planning and creative endeavors. ",
    image: BlogImg7,
  },
  {
    id: 2,
    title: "Automation: Streamlining Marketing Efforts",
    category: "Accommodation",
    slug: "automation-streamlining-marketing-efforts",
    date: "January 26, 2024",
    description:
      "Harnessing the power of automation, VenueBoost revolutionizes the way businesses connect with their audience. By automating repetitive tasks such as scheduling social media posts, sending personalized promotional emails, and managing customer engagement campaigns, businesses can focus their energy on strategic planning and creative endeavors.",

    image: BlogImg7,
  },
  {
    id: 3,
    title: "Automation: Streamlining Marketing Efforts",
    category: "Food & Beverage",
    slug: "automation-streamlining-marketing-efforts",
    date: "February 2, 2024",
    description:
      "Harnessing the power of automation, VenueBoost revolutionizes the way businesses connect with their audience. By automating repetitive tasks such as scheduling social media posts, sending personalized promotional emails, and managing customer engagement campaigns, businesses can focus their energy on strategic planning and creative endeavors.",

    image: BlogImg7,
  },
];

const MyIcon = props => {
  switch (props.category) {
    case "Entertainment Venues":
      return <EntertainmentVenues />;
    case "Accommodation":
      return <Accommodation />;
    case "Retail Management":
      return <RetailManagement />;
    case "Food & Beverage":
      return <FoodBeverage />;
    default:
      return null;
  }
};

const UseCases = () => {
  const [tag, setTag] = useState(0);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(window.innerWidth > 640 ? 15 : 5);
  const onPaginationChange = (page, pageSize) => {
    setMinValue((page - 1) * pageSize);
    setMaxValue(page * pageSize);
  };

  const currentUrl = "https://venueboost.io/resources/use-cases";
  const seoTitle =
    "Discover Powerful Use Cases for VenueBoost | Boost Your Business";
  const seoDescription =
    "Explore the diverse range of use cases for VenueBoost and discover how it can boost your business. From streamlining operations to engaging customers, VenueBoost offers solutions tailored to your industry needs.";

  return (
    <div className='flex flex-col align-col-middle view-terms'>
      <Seo
        title={seoTitle + " | VenueBoost"}
        description={seoDescription}
        url={currentUrl}
      />

      <div className='w-[100vw]'>
        <div className='max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 py-8'>
          <div className='hidden md:block'>
            <div className='flex flex-col md:flex-row rounded-[13px] border-[1px] border-[#E0E0E0] overflow-hidden'>
              <img
                src={caseData[0].image}
                className='w-full md:w-[40%] object-cover flex rounded-l-[13px]'
                alt={caseData[0].title}
              />
              <div className='w-full md:w-[60%] py-[76px] px-[83px]'>
                <div className='flex flex-row  items-center justify-start gap-4'>
                  <div class='h-[40px] w-[214px] rounded-[60px] bg-gradient-to-r from-[#F4D35E] via-[#F4D35E] to-[#F4D35E] p-[1px]'>
                    <div class='flex h-full w-full items-center justify-center bg-white rounded-[60px]'>
                      <MyIcon category={caseData[0].category} />
                      <h1 class='text-14 font-semibold text-[#373941]'>
                        {caseData[0].category}
                      </h1>
                    </div>
                  </div>
                  <p className='text-14 font-normal text-[#717484]'>
                    {caseData[0].date}
                  </p>
                </div>
                <h2 className='text-[#240B3B] text-[32px] leading-[44px] font-semibold mt-6'>
                  {caseData[0].title}
                </h2>
                <p className='py-4 text-[#667085]'>{caseData[0].description}</p>
                <div className='flex items-center mb-4 text-[#585965] text-[18px]'>
                  <span className='block'>Potential impact:</span>
                  <span className='block mx-2'>10% Increased Conversions</span>
                  <Increase />
                </div>
                <a
                  href={"/resources/use-cases/" + caseData[0].slug}
                  className='text-white inline-block rounded-[6px] text-16 leading-[22px] py-4 px-[25px] bg-gradient-to-r from-[#240B3B] via-[#5D1C82] to-[#BC14FE]'
                >
                  Read More
                </a>
              </div>
            </div>
          </div>
          <div className='block md:hidden'>
            <UseCaseCard
              img={caseData[0].image}
              tag={caseData[0].category}
              title={caseData[0].title}
              description={caseData[0].description}
              date={caseData[0].date}
              slug={caseData[0].slug}
            />
          </div>
        </div>
      </div>

      <div className='w-full flex-wrap flex items-center pb-8 justify-start gap-4'>
        <div>Filter</div>
        <div class='h-[40px] rounded-[60px] border border-[#240B3B]'>
          <div class='flex h-full  px-4  items-center justify-center bg-[rgba(36,11,59,.2)] rounded-[60px]'>
            <DoubleCheck />
            <h1 class='text-14 font-semibold text-[#240B3B]'>All</h1>
          </div>
        </div>
        <div class='h-[40px] cursor-pointer  rounded-[60px] border border-[#F4D35E] p-[1px]'>
          <div class='flex h-full px-4 items-center justify-center bg-white rounded-[60px]'>
            <MyIcon category={"Entertainment Venues"} />
            <h1 class='text-14 font-semibold text-[#373941] ml-1.5'>
              Entertainment Venues
            </h1>
          </div>
        </div>
        <div class='h-[40px] cursor-pointer  rounded-[60px] border border-[#6DDFE2] p-[1px]'>
          <div class='flex h-full px-4 items-center justify-center bg-white rounded-[60px]'>
            <MyIcon category={"Accommodation"} />
            <h1 class='text-14 font-semibold text-[#373941] ml-2'>
              Accommodation
            </h1>
          </div>
        </div>
        <div class='h-[40px] cursor-pointer rounded-[60px] border border-[#E14848] p-[1px]'>
          <div class='flex h-full px-4 items-center justify-center bg-white rounded-[60px]'>
            <MyIcon category={"Retail Management"} />
            <h1 class='text-14 font-semibold text-[#373941] ml-2'>
              Retail Management
            </h1>
          </div>
        </div>
        <div class='h-[40px] cursor-pointer  rounded-[60px] border border-[#17CE8C] p-[1px]'>
          <div class='flex h-full px-4 items-center justify-center bg-white rounded-[60px]'>
            <MyIcon category={"Food & Beverage"} />
            <h1 class='text-14 font-semibold text-[#373941] ml-2'>
              Food & Beverage
            </h1>
          </div>
        </div>
      </div>
      <h1 className='text-[#240B3B] text-[32px] w-full mb-8 font-bold'>
        Top Use Cases
      </h1>
      <div className='grid grid-cols-1 mt-0 sm:grid-cols-3 gap-[38px]'>
        {caseData &&
          caseData.length > 0 &&
          caseData
            .slice(minValue, maxValue)
            .map((card, index) => (
              <UseCaseCard
                img={card.image}
                tag={card.category}
                title={card.title}
                description={card.description}
                date={card.date}
                slug={card.slug}
              />
            ))}
      </div>
      <div className='mx-auto my-[56px] w-full align-middle blog-pagination'>
        <Pagination
          total={caseData.length}
          defaultCurrent={1}
          pageSize={window.innerWidth > 640 ? 15 : 5}
          onChange={onPaginationChange}
        />
      </div>
    </div>
  );
};

export default UseCases;
