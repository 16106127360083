import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import PropTypes from "prop-types";

export const Seo = ({ title, description, url, image }) => {
    return (
        <HelmetProvider>
            <Helmet>
                <title>
                    {title
                        ? title
                        : "VenueBoost | Simplify Venue Management with Streamlined Solutions"}
                </title>
                <meta
                    name='description'
                    content={
                        description
                            ? description
                            : "VenueBoost | Simplify venue management with streamlined solutions for reservations, operations, and guest experiences."
                    }
                />
                <link rel='canonical' href={url ? url : "https://venueboost.io"} />
                <meta property='og:locale' content='en_US' />
                <meta property='og:type' content='website' />
                <meta
                    property='og:title'
                    content={
                        title
                            ? title
                            : "VenueBoost | Simplify Venue Management with Streamlined Solutions"
                    }
                />
                <meta
                    property='og:description'
                    content={
                        description
                            ? description
                            : "VenueBoost | Simplify venue management with streamlined solutions for reservations, operations, and guest experiences."
                    }
                />
                <meta property='og:url' content={url ? url : "https://venueboost.io"} />
                <meta property='og:site_name' content='VenueBoost' />
                <meta
                    property='og:image'
                    content={image ? image : "https://venueboost.io/venueboost-saas.png"}
                />
                <meta
                    property='og:image:secure_url'
                    content={image ? image : "https://venueboost.io/venueboost-saas.png"}
                />
                <meta property='og:image:width' content='1200' />
                <meta property='og:image:height' content='628' />
                <meta
                    property='og:image:alt'
                    content='Simplify. Optimize. Grow. with VenueBoost'
                />

                <meta name='twitter:card' content='summary' />
                <meta
                    name='twitter:title'
                    content={
                        title
                            ? title
                            : "VenueBoost | Simplify Venue Management with Streamlined Solutions"
                    }
                />
                <meta
                    name='twitter:description'
                    content={
                        description
                            ? description
                            : "VenueBoost | Simplify venue management with streamlined solutions for reservations, operations, and guest experiences."
                    }
                />
                <meta name='twitter:creator' content='@VenueBoostInc' />
                <meta
                    name='twitter:image'
                    content='https://venueboost.io/venueboost-saas.png'
                />
            </Helmet>
        </HelmetProvider>
    );
};

function arePropsEqual(prevProps, nextProps) {
    return (
        prevProps.title === nextProps.title &&
        prevProps.description === nextProps.description &&
        prevProps.url === nextProps.url &&
        prevProps.image === nextProps.image
    );
}

Seo.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    url: PropTypes.string,
    image: PropTypes.string,
};

export default React.memo(Seo, arePropsEqual);