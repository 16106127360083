import React from "react";
import EmilyAvatar from "../../assets/svgs/emily-vb-testimonial.png";

export const BlogHeader = ({
  currentBlog,
  isMobile,
  handleTwitterShare,
  handleLinkedInShare,
  handleFacebookShare,
  handleCopyLink,
}) => {
  return (
    <div
      style={{
        background: "#f9f5ff",
        paddingBottom: "20px",
        paddingTop: "20px",
        paddingLeft: "16px",
        paddingRight: "16px",
      }}
      className="flex items-center gap-[40px] flex-col md:flex-row md:rounded-[13px] overflow-hidden"
    >
      <div className="w-full md:w-[50%]">
        <img
          className="object-cover w-auto rounded-md"
          src={
            currentBlog?.image ??
            "/static/media/blog-38.f19f4c0670508468bb36.webp"
          }
          alt={
            currentBlog?.id === 27
              ? "Event Manager checking Event Management Software"
              : ""
          }
        />
      </div>
      <div className="w-full md:w-[50%] flex flex-col">
        <a href="/blog" className="text-[#bc14fe] text-16 md:text-20 font-bold">
          {currentBlog?.category ?? currentBlog?.category_text}
        </a>
        <h1 className="text-[#373941] text-26 md:text-[36px] font-semibold leading-[40px] my-2">
          {currentBlog?.title}
        </h1>
        {!isMobile && (
          <div
            className="flex justify-between items-center my-4"
            style={{ paddingRight: "25px" }}
          >
            <div className="flex items-center">
              <img
                className="rounded-full"
                src={currentBlog?.author_avatar ?? EmilyAvatar}
                width="40"
                height="40"
                alt=""
              />
              <div className="ml-3">
                <h2 className="font-semibold">{currentBlog?.author_name}</h2>
                {currentBlog?.author_designation && (
                  <p className="text-s">{currentBlog?.author_designation}</p>
                )}
              </div>
            </div>
            <div style={{ display: "contents" }}>
              <div>{currentBlog?.date}</div>
              <div>
                {currentBlog?.time_read ?? currentBlog?.read_time} min read
              </div>
            </div>
          </div>
        )}

        {isMobile && (
          <div
            className="flex justify-between items-center my-4"
            style={{ display: "block" }}
          >
            <div className="flex items-center">
              <img
                className="rounded-full"
                src={currentBlog?.author_avatar ?? EmilyAvatar}
                width="40"
                height="40"
                alt=""
              />
              <div className="ml-3">
                <h2 className="font-semibold">{currentBlog?.author_name}</h2>
                <p className="text-s">{currentBlog?.author_designation}</p>
              </div>
            </div>
            <div
              className="gap-4"
              style={{
                display: "flex",
                fontWeight: "bold",
                marginTop: "25px",
              }}
            >
              <div>{currentBlog?.date}</div>|
              <div>
                {currentBlog?.time_read ?? currentBlog?.read_time} min read
              </div>
            </div>
          </div>
        )}
        <div className="flex items-center mt-3">
          <div
            onClick={handleTwitterShare}
            className="cursor-pointer  mr-2 w-[60px] h-[60px] bg-gradient-to-r from-[#240B3B] via-[#5D1C82] to-[#BC14FE] rounded-full flex items-center justify-center"
          >
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.533 7.59759C21.5482 7.81078 21.5482 8.02401 21.5482 8.2372C21.5482 14.7397 16.599 22.2321 7.5533 22.2321C4.76648 22.2321 2.17767 21.425 0 20.024C0.395953 20.0697 0.776625 20.0849 1.18781 20.0849C3.48727 20.0849 5.60405 19.3083 7.29441 17.9834C5.13197 17.9377 3.31978 16.5215 2.69541 14.5722C3 14.6179 3.30455 14.6483 3.62437 14.6483C4.06598 14.6483 4.50764 14.5874 4.91878 14.4809C2.66498 14.024 0.974578 12.0443 0.974578 9.65343V9.59254C1.62937 9.95803 2.39086 10.1864 3.19791 10.2169C1.87303 9.33361 1.00505 7.82601 1.00505 6.12042C1.00505 5.20673 1.24866 4.36917 1.67508 3.6382C4.09641 6.62296 7.73602 8.57217 11.8172 8.7854C11.7411 8.41992 11.6954 8.03925 11.6954 7.65853C11.6954 4.94784 13.8883 2.73975 16.6141 2.73975C18.0304 2.73975 19.3095 3.33365 20.208 4.29304C21.3197 4.07986 22.3857 3.66867 23.3299 3.10523C22.9643 4.24739 22.1877 5.20678 21.1674 5.81587C22.1573 5.70932 23.1167 5.43515 23.9999 5.05448C23.33 6.02906 22.4924 6.89704 21.533 7.59759Z"
                fill="white"
              />
            </svg>
          </div>
          <div
            onClick={handleLinkedInShare}
            className="cursor-pointer mx-2 w-[60px] h-[60px] bg-gradient-to-r from-[#240B3B] via-[#5D1C82] to-[#BC14FE] rounded-full flex items-center justify-center"
          >
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_123_1524)">
                <path
                  d="M6.20062 21.4859H1.84688V7.46555H6.20062V21.4859ZM4.02141 5.55305C2.62922 5.55305 1.5 4.39992 1.5 3.00773C1.5 2.33902 1.76565 1.69769 2.2385 1.22483C2.71136 0.751975 3.35269 0.486328 4.02141 0.486328C4.69012 0.486328 5.33145 0.751975 5.80431 1.22483C6.27716 1.69769 6.54281 2.33902 6.54281 3.00773C6.54281 4.39992 5.41313 5.55305 4.02141 5.55305ZM22.4953 21.4859H18.1509V14.6609C18.1509 13.0343 18.1181 10.9484 15.8873 10.9484C13.6237 10.9484 13.2769 12.7155 13.2769 14.5437V21.4859H8.92781V7.46555H13.1034V9.37805H13.1644C13.7456 8.27648 15.1655 7.11398 17.2838 7.11398C21.69 7.11398 22.5 10.0155 22.5 13.7843V21.4859H22.4953Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_123_1524">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(0 0.48584)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div
            className="cursor-pointer mx-2 w-[60px] h-[60px] bg-gradient-to-r from-[#240B3B] via-[#5D1C82] to-[#BC14FE] rounded-full flex items-center justify-center"
            onClick={handleFacebookShare}
          >
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_123_1527)">
                <path
                  d="M17.584 13.9858L18.2505 9.6424H14.0829V6.82381C14.0829 5.63553 14.6651 4.47725 16.5316 4.47725H18.4263V0.779277C18.4263 0.779277 16.707 0.48584 15.063 0.48584C11.6309 0.48584 9.38742 2.56615 9.38742 6.33209V9.6424H5.57227V13.9858H9.38742V24.4858H14.0829V13.9858H17.584Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_123_1527">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(0 0.48584)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div
            onClick={handleCopyLink}
            className="cursor-pointer mx-2 w-[60px] h-[60px] bg-gradient-to-r from-[#240B3B] via-[#5D1C82] to-[#BC14FE] rounded-full flex items-center justify-center"
          >
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.9999 16.2358C17.5959 16.2357 17.196 16.3174 16.8244 16.4762C16.4528 16.6349 16.1173 16.8673 15.838 17.1593L8.90054 13.256C9.03492 12.7514 9.03492 12.2203 8.90054 11.7157L15.838 7.81241C16.3432 8.33616 17.022 8.65776 17.7473 8.71695C18.4726 8.77614 19.1946 8.56886 19.778 8.13394C20.3614 7.69903 20.7662 7.06632 20.9166 6.35434C21.067 5.64237 20.9527 4.89999 20.595 4.26627C20.2373 3.63256 19.6609 3.15099 18.9736 2.91179C18.2864 2.6726 17.5355 2.69218 16.8616 2.96687C16.1878 3.24156 15.6372 3.75251 15.3131 4.40401C14.9889 5.0555 14.9134 5.80284 15.1007 6.506L8.1632 10.4093C7.7488 9.97721 7.2146 9.679 6.62933 9.55302C6.04405 9.42704 5.43447 9.47905 4.879 9.70237C4.32353 9.92569 3.84758 10.3101 3.51237 10.8061C3.17717 11.3022 2.99805 11.8872 2.99805 12.4858C2.99805 13.0845 3.17717 13.6695 3.51237 14.1656C3.84758 14.6616 4.32353 15.046 4.879 15.2693C5.43447 15.4926 6.04405 15.5447 6.62933 15.4187C7.2146 15.2927 7.7488 14.9945 8.1632 14.5624L15.1007 18.4657C14.9398 19.0713 14.9726 19.712 15.1945 20.298C15.4163 20.8839 15.816 21.3858 16.3376 21.733C16.8591 22.0802 17.4763 22.2555 18.1025 22.2341C18.7287 22.2127 19.3325 21.9958 19.8291 21.6138C20.3258 21.2319 20.6904 20.704 20.8717 20.1042C21.0531 19.5045 21.0421 18.863 20.8404 18.2698C20.6386 17.6766 20.2562 17.1615 19.7468 16.7967C19.2373 16.4319 18.6265 16.2358 17.9999 16.2358Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};
