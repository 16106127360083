import React, { useEffect, useState } from "react";
import FormTextInput from "../../../components/FormTextInput";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import { chevronDown } from "react-icons-kit/feather/chevronDown";
import { Country } from "country-state-city";
import { registerAffiliate } from "../../../redux/actions/auth";
import { NotificationManager } from "react-notifications";
import * as yup from "yup";
import { useFormik } from "formik";
import { isEmpty } from "../../../utils/common";
import "./index.css";
import { useNavigate, useParams } from "react-router-dom";
import RouteNames from "../../../constants/route_names";
import WhiteLabelService from "../../../services/whiteLabelService";
import { identifyDevice, trackEvent } from "../../../utils/mixpanelUtil";

const validator = yup.object().shape({
  first_name: yup
    .string()
    .trim()
    .required("First name is required")
    .max(72, "Max exceeded")
    .min(2, "Min not met"),
  last_name: yup
    .string()
    .trim()
    .required("Last name is required")
    .max(72, "Max exceeded")
    .min(2, "Min not met"),
  email: yup
    .string()
    .trim()
    .required("Email is required")
    .max(72, "Max exceeded")
    .min(5, "Min not met")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email"
    ),
  country: yup.string().trim().required("Country is required"),
  password: yup
    .string()
    .trim()
    .required("Password is required")
    .max(250, "Max exceeded")
    .min(6, "Min not met"),
});

const AffiliatesRegister = () => {
  const navigate = useNavigate();
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);
  const { app_key } = useParams();

  const formikProps = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      website: "",
      country: "",
      password: "",
      acceptTerm: false,
      affiliate_type_id: null,
    },
    validationSchema: validator,
    onSubmit: (values) => onSubmit(values),
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
  });

  const [affiliateTypes, setAffiliateTypes] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    window.sessionStorage.clear();
    GetAffiliateTypes();
  }, []);

  const GetAffiliateTypes = () => {
    WhiteLabelService.getAffiliateTypes()
      .then((res) => {
        setAffiliateTypes(res.affiliate_types); //
        identifyDevice();
        trackEvent("Affiliates - Register", {
          action_category: "register_affiliate",
          action_outcome: "success",
          interaction_element: "screen",
          source: "vb_web",
        });
      })
      .catch((error) => {
        NotificationManager.error(
          error?.message ||
            "Oops! Something went wrong. Please try again or contact us directly at contact@venueboost.io.",
          "Error",
          3000
        );
        identifyDevice();
        trackEvent("Affiliates - Register", {
          action_category: "register_affiliate",
          action_outcome: "fail",
          interaction_element: "screen",
          source: "vb_web",
        });
      });
  };

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  const handleChange = (e) => {
    formikProps.setFieldValue([e.target.name], e.target.value);
  };

  const onSubmit = (values) => {
    registerAffiliate(values)
      .then((data) => {
        formikProps.resetForm();
        NotificationManager.success(
          "Your account was successfully created. You will receive an email upon verification.\n" +
            "\n" +
            "\n" +
            "\n" +
            "\n" +
            "\n",
          "Success",
          5000
        );
      })
      .catch((error) => {
        NotificationManager.error(
          error.message ||
            "Oops! Something went wrong. Please try again or contact us directly at contact@venueboost.io.",
          "Error",
          3000
        );
        throw new Error("Error on register affiliate");
      });
  };

  const onLogin = () => {
    navigate(RouteNames.affiliates_login);
  };

  return (
    <div className="flex flex-col align-col-middle view-terms">
      <div className="py-10 w-full  md:w-[40%]">
        <h1 className="text-24 md:text-28 font-bold leading-3 md:leading-[34px] text-[#240b3b]">
          Sign Up
        </h1>
        <p className="mt-5 text-18 md:text-20 font-medium leading-7 md:leading-[34px] text-[#240b3b]">
          Join our Affiliate Program, introduce VenueBoost to your audience, and
          earn up to <span className="font-bold"> 40%</span> commission payouts
          when your referred audiences
          <span className="font-bold"> subscribe to VenueBoost.</span>
        </p>
        <div className="mt-6 md:mt-10">
          <p className=" mt-3 md:mt-5 mb-2 md:mb-3 text-[#240b3b] text-20 leading-[34px] font-semibold">
            Email Address
          </p>
          <FormTextInput
            enableLabel={false}
            name="email"
            type={"email"}
            placeholder="Enter email address"
            value={formikProps.values.email}
            error={formikProps.errors.email}
            onChange={handleChange}
          />
          <p className="mt-3 md:mt-5 mb-2 md:mb-3 text-[#240b3b] text-20 leading-[34px] font-semibold">
            First Name
          </p>
          <FormTextInput
            enableLabel={false}
            name="first_name"
            placeholder="Enter first Name"
            value={formikProps.values.first_name}
            error={formikProps.errors.first_name}
            onChange={handleChange}
          />
          <p className="mt-3 md:mt-5 mb-2 md:mb-3 text-[#240b3b] text-20 leading-[34px] font-semibold">
            Last Name
          </p>

          <FormTextInput
            enableLabel={false}
            name="last_name"
            placeholder="Enter last Name"
            value={formikProps.values.last_name}
            error={formikProps.errors.last_name}
            onChange={handleChange}
          />
          <p className="mt-3 md:mt-5 mb-2 md:mb-3 text-[#240b3b] text-20 leading-[34px] font-semibold">
            Website
          </p>

          <FormTextInput
            enableLabel={false}
            name="website"
            placeholder="Enter your website"
            value={formikProps.values.website}
            error={formikProps.errors.website}
            onChange={handleChange}
          />
          <p className="mt-3 md:mt-5 mb-2 md:mb-3 text-[#240b3b] text-20 leading-[34px] font-semibold">
            Country
          </p>
          <div className="w-full relative h-[48px]  border-[#CBD5E1] border-[1px] rounded-[5px] flex align-middle focus:border-blue-400 ">
            <select
              name="country"
              value={formikProps.values.country}
              onChange={handleChange}
              placeholder={"Enter your country"}
              className="flex-1 appearance-none h-[46px] rounded-[5px]"
              //   className={`w-full bg-white h-[48px] border-[1px] mt-2 rounded-[5px] border-[#CBD5E1] px-2 focus:border-[1px] outline-none focus:border-blue-400 appearance-none`}
            >
              <option value={""}>Choose Country</option>
              {Country.getAllCountries().map((item, index) => (
                <option key={index} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select>

            <span
              className="flex absolute justify-around items-center right-5 "
              style={{ color: "#CBD5E1" }}
            >
              <Icon class="absolute " icon={chevronDown} size={18} />
            </span>
          </div>
          {!isEmpty(formikProps.errors.country) && (
            <div className={"text-red-600 text-12 mt-2"}>
              {formikProps.errors.country}
            </div>
          )}
          <p className="mt-3 md:mt-5 mb-2 md:mb-3 text-[#240b3b] text-20 leading-[34px] font-semibold">
            Affiliate Type
          </p>
          <div className="w-full relative h-[48px]  border-[#CBD5E1] border-[1px] rounded-[5px] flex align-middle focus:border-blue-400 ">
            <select
              name="affiliate_type_id" // You can choose a different name as needed
              value={formikProps.values.affiliate_type_id}
              onChange={handleChange}
              placeholder="Select Affiliate Type"
              className="w-full border-[1px] border-[#CBD5E1] rounded-[5px] h-[48px] px-3 focus:border-blue-400"
            >
              <option value="">Select Affiliate Type</option>
              {affiliateTypes.map((type) => (
                <option key={type.id} value={parseInt(type.id)}>
                  {type.name}
                </option>
              ))}
            </select>
          </div>
          {!isEmpty(formikProps.errors.affiliate_type_id) && (
            <div className={"text-red-600 text-12 mt-2"}>
              {formikProps.errors.affiliate_type_id}
            </div>
          )}
          <p className="mt-3 md:mt-5 mb-2 md:mb-3 text-[#240b3b] text-20 leading-[34px] font-semibold">
            Password
          </p>
          <div className="w-full h-[48px] px-5 border-[#CBD5E1] border-[1px] rounded-[5px] flex align-middle focus:border-blue-400 ">
            <input
              type={type}
              name="password"
              placeholder="Enter your password"
              value={formikProps.values.password}
              error={formikProps.errors.password}
              onChange={handleChange}
              autoComplete="current-password"
              className="flex-1 border-none focus-visible:border-none focus-visible:outline-none"
            />
            <span
              className="flex justify-around items-center"
              style={{ color: "#CBD5E1" }}
              onClick={handleToggle}
            >
              <Icon class="absolute " icon={icon} size={18} />
            </span>
          </div>
          {!isEmpty(formikProps.errors.password) && (
            <div className={"text-red-600 text-12 mt-2"}>
              {formikProps.errors.password}
            </div>
          )}
          <div className="flex gap-[8px] md:gap-[18px] mt-10 justify-center items-start">
            <input
              type="checkbox"
              name="acceptTerm"
              className={`w-[28px] md:w-[38px] h-[28px] md:h-[38px] align-top bg-white  border-[1px] rounded-[5px] border-[#CBD5E1] focus:border-[1px] outline-none  `}
              checked={formikProps.values.acceptTerm}
              onChange={(e) => {
                formikProps.setFieldValue("acceptTerm", e.target.checked);
              }}
            />
            <p className="text-[#240b3b] flex-1 text-16 md:text-20 font-medium leading-8 md:leading-[34px]">
              I agree to receive email notifications (like when I earn a
              commission) and other important emails regarding the affiliate
              program
            </p>
          </div>
          {!isEmpty(formikProps.errors.acceptTerm) && (
            <div className={"text-red-600 text-12 mt-2"}>
              {formikProps.errors.acceptTerm}
            </div>
          )}
          <button
            className="mt-6 md:mt-10 text-white text-20 font-bold bg-[#240b3b] border-[1px] border-[#CBD5E1] rounded-[5px] h-[60px] md:h-[70px] w-full"
            type="button"
            onClick={formikProps.handleSubmit}
          >
            Sign Up
          </button>
          <p className="text-[#707070] font-medium text-[18px] text-center my-5">
            Do you already have a affilate account?
          </p>
          <button
            className="text-[#240b3b] text-20 font-bold bg-white border-[1px] border-[#240b3b] rounded-[5px] h-[60px] md:h-[70px] w-full"
            onClick={onLogin}
          >
            Log In
          </button>
        </div>
      </div>
    </div>
  );
};

export default AffiliatesRegister;
