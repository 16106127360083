import WhoWeServeSportsAndEntertainmentOverview from "../../views/whoweserve/sport_entertainment_overview";
import Bowling from "../../views/whoweserve/sport_entertainment_overview/bowling";
import GolfCourses from "../../views/whoweserve/sport_entertainment_overview/golf_courses";
import GymsAndFitnessCenters from "../../views/whoweserve/sport_entertainment_overview/gyms_and_fitness_centers";
import AmusementParks from "../../views/whoweserve/sport_entertainment_overview/amusement_parks";
import ArcadesAndGameCenters from "../../views/whoweserve/sport_entertainment_overview/arcades_and_game_centers";
import ConcertVenuesAndTheaters from "../../views/whoweserve/sport_entertainment_overview/concert_venues_and_theaters";
import SkiResorts from "../../views/whoweserve/sport_entertainment_overview/ski_resorts";
import SportsComplexes from "../../views/whoweserve/sport_entertainment_overview/sports_complexes";
import Museum from "../../views/whoweserve/sport_entertainment_overview/museum";
import BeachBars from "../../views/whoweserve/sport_entertainment_overview/beach_bars";
import Cinema from "../../views/whoweserve/sport_entertainment_overview/cinema";
import { Route } from "react-router-dom";
import RouteNames from "../../constants/route_names";

const sportsEntertainmentRoutes = [
  <Route
    exact
    path={RouteNames.whoweserve_sports_entertainment_overview}
    element={<WhoWeServeSportsAndEntertainmentOverview />}
  />,
  <Route exact path={RouteNames.whoweserve_bowling} element={<Bowling />} />,
  <Route
    exact
    path={RouteNames.whoweserve_golf_courses}
    element={<GolfCourses />}
  />,
  <Route
    exact
    path={RouteNames.whoweserve_gyms_and_fitness_centers}
    element={<GymsAndFitnessCenters />}
  />,
  <Route
    exact
    path={RouteNames.whoweserve_sports_complexes}
    element={<SportsComplexes />}
  />,
  <Route
    exact
    path={RouteNames.whoweserve_arcades_and_game_centers}
    element={<ArcadesAndGameCenters />}
  />,
  <Route
    exact
    path={RouteNames.whoweserve_concert_venues_and_theaters}
    element={<ConcertVenuesAndTheaters />}
  />,
  <Route
    exact
    path={RouteNames.whoweserve_ski_resorts}
    element={<SkiResorts />}
  />,
  <Route
    exact
    path={RouteNames.whoweserve_amusement_parks}
    element={<AmusementParks />}
  />,
  <Route exact path={RouteNames.whoweserve_museum} element={<Museum />} />,
  <Route
    exact
    path={RouteNames.whoweserve_beach_bars}
    element={<BeachBars />}
  />,
  <Route exact path={RouteNames.whoweserve_cinema} element={<Cinema />} />,
];

export default sportsEntertainmentRoutes;
