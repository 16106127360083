import React, { useState } from "react";
import "./index.css";
const DropdownSubMenu = ({ menu }) => {
  const maxItems = 6;
  const [toggle, setToggle] = useState(false);
  return (
    <>
      <ul className={`grid grid-cols-2 gap-x-8 w-70 text-18 `} style={{ rowGap: "20px" }}>
        {menu.map((item, i) => {
          if (!toggle && i >= maxItems) {
            return null
          }
          return (
            <li
              key={i}
              className="px-2 py-1 lg:py-2 text-xl hover:bg-gray-100 rounded-lg transition-all duration-100 delay-100"
            >
              <a href={item.url} className="flex" style={{ alignItems: "center" }}>
                <img src={item.icon} className={"w-7 h-7 object-fit"} alt="icon" />
                <div className="ml-5">
                  <span className="text-20  text-[#111B29] font-bold leading-[150%] ">
                    {item.label}
                  </span>
                  {item.subtitle && (
                    <p
                      className=" lg:text-[14px] font-medium text-[#677489] leading-[150%] "
                      key={item.key}
                    >
                      {item.subtitle}
                    </p>
                  )}
                </div>
              </a>
            </li>
          )
        }

        )}
      </ul>
      {
        !toggle && (

          <div className="flex items-center cursor-pointer mx-2 mt-4 lg:text-[14px] font-medium text-[#677489] " onClick={() => {
            setToggle(!toggle)
          }}>
            <div className='underline leading-none' style={{marginTop:-2}}>See more business types</div>
            <div className='text-gray-500 ml-1'>
              <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.854043 0.642955C0.713736 0.783304 0.634916 0.973632 0.634916 1.17209C0.634916 1.37054 0.713736 1.56087 0.854043 1.70122L4.55871 5.40589L0.854043 9.11056C0.717712 9.25171 0.642276 9.44076 0.643981 9.637C0.645686 9.83323 0.724396 10.0209 0.863159 10.1597C1.00192 10.2985 1.18963 10.3772 1.38587 10.3789C1.5821 10.3806 1.77115 10.3052 1.91231 10.1688L6.14611 5.93502C6.28641 5.79467 6.36523 5.60434 6.36523 5.40589C6.36523 5.20743 6.28641 5.01711 6.14611 4.87676L1.91231 0.642955C1.77196 0.502648 1.58163 0.423828 1.38317 0.423828C1.18472 0.423828 0.994392 0.502648 0.854043 0.642955Z" fill="currentColor" />
              </svg>
            </div>
          </div>)
      }
    </>
  );
};

export default DropdownSubMenu;
