export default function Increase(props) {
  return (
    <svg
      width="31"
      height="32"
      viewBox="0 0 31 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_527_3625)">
        <path
          d="M20.4512 8.47467H29.0628V17.0863"
          stroke="#17CE8C"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.0639 8.47467L16.9 20.6386C16.6988 20.8358 16.4282 20.9463 16.1465 20.9463C15.8647 20.9463 15.5942 20.8358 15.393 20.6386L10.4413 15.6869C10.2401 15.4897 9.96954 15.3792 9.68778 15.3792C9.40602 15.3792 9.13548 15.4897 8.93426 15.6869L1.07617 23.545"
          stroke="#17CE8C"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_527_3625">
          <rect
            width="30.1406"
            height="30.1406"
            fill="white"
            transform="translate(0 0.939514)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
