import React, { useEffect, useState } from "react";

import "./index.css";
import Dropdown from "../Dropdown";
import FaqDropdown from "../FaqDropdown";

const Faq = ({ faqData = [], className = "", isFaq = false, background = "bg-[#5D1C82]" }) => {
  const [faqList, setFaqList] = useState(faqData);

  useEffect(() => {
    setFaqList(faqData);
  }, [faqData]);

  const handleAction = (id) => {
    setFaqList((list) =>
      list.map((item) => {
        if (item.id === id) {
          return { ...item, status: !item.status };
        } else {
          return { ...item, status: false };
        }
      })
    );
  };

  return (
    <div className={`flex flex-col w-full ${className} rounded-[18px] p-6 md:p-[12px]`}>
      {faqList.length > 0 &&
        faqList.map((item, index) => (
          !isFaq ? 
          <FaqDropdown
            className="w-full bg-transparent hover:cursor-pointer"
            key={index}
            title={item.title}
            content={item.content}
            status={item.status}
            onAction={() => handleAction(item.id)}
          />
          :
          <div>
            {
              index > 0 && 
              <div className={`w-full ${background} h-[1px]`}></div>
            }
            <Dropdown
              className="w-full bg-transparent hover:cursor-pointer"
              key={index}
              title={item.title}
              content={item.content}
              status={item.status}
              ulItems={item.ulItems}
              ulItems2={item.ulItems2}
              extraItem={item.extraItem}
              introItem={item.introItem}
              onAction={() => handleAction(item.id)}
            />
          </div>
        ))}
    </div>
  );
};

export default Faq;
