import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import FormTextInput from "../../components/FormTextInput";
import FormSelect from "../../components/FormSelect";
import {
  create as createContactSale,
  getRegisterConfig,
} from "../../redux/actions/contactsales";
import "./index.css";
import FormImg from "../../assets/images/landing/form.png";
import { getStorageKey, KEYS, removeStorageKey } from "../../utils/storage";
import {
  CircularProgress,
  FormControlLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { Checkbox } from "antd";
import { isMobile } from "react-device-detect";

const contactReason = [
  { value: "", title: "Please select" },
  { value: "Access Free Plan", title: "Access Free Plan" },
  {
    value: "Request Personalized Consultation",
    title: "Request Personalized Consultation",
  },
  { value: "Subscribe to Updates", title: "Subscribe to Updates" },
];

const howDidYouHearOptions = [
  { value: "", title: "Please select" },
  { value: "Email", title: "Email" },
  { value: "Social media", title: "Social media" },
  { value: "Search engines", title: "Search engines" },
  { value: "Ads", title: "Ads" },
  { value: "Referral", title: "Referral" },
  { value: "From a friend", title: "From a friend" },
  { value: "Other", title: "Other" },
];

const businessChallenges = [
  { value: "", title: "Please select" },
  { value: "Staffing Issues", title: "Staffing Issues" },
  { value: "Sales and Marketing", title: "Sales and Marketing" },
  { value: "Inventory Management", title: "Inventory Management" },
  { value: "Operational Efficiency", title: "Operational Efficiency" },
  {
    value: "Brand Development and Positioning",
    title: "Brand Development and Positioning",
  },
  {
    value: "Customer Engagement and Retention",
    title: "Customer Engagement and Retention",
  },
  { value: "Other", title: "Other" },
];

const socialMediaOptions = [
  { label: "Facebook", value: "facebook" },
  { label: "Twitter", value: "Twitter" },
  { label: "Instagram", value: "Instagram" },
  { label: "TikTok", value: "TikTok" },
];

const industryList = [
  { value: "", title: "Please select" },
  { value: "Food", title: "Food & Beverage" },
  { value: "Sport & Entertainment", title: "Entertainment Venues" },
  { value: "Accommodation", title: "Accommodation" },
  { value: "Retail", title: "Retail Management" },
  // { value: "Healthcare", title: "Healthcare" },
];

const categoryList = {
  Food: [
    { value: "", title: "Please select" },
    { value: "restaurant", title: "Restaurant" },
    { value: "bistro", title: "Bistro" },
    { value: "cafe", title: "Cafe" },
    { value: "bar", title: "Bar" },
    { value: "pub_club", title: "Pub & Club" },
    { value: "food_truck", title: "Food Truck" },
    { value: "fine_dining", title: "Fine Dining" },
    { value: "bakery", title: "Bakery" },
    { value: "catering_service", title: "Catering Service" },
    { value: "fast_food", title: "Fast Food" },
    { value: "ice_cream_parlor", title: "Ice Cream Parlor" },
    { value: "food_stall", title: "Food Stall" },
    { value: "buffet", title: "Buffet" },
    { value: "pop_up_dining", title: "Pop-Up Dining" },
    { value: "juice_bar", title: "Juice Bar" },
    { value: "food_hall", title: "Food Hall" },
    { value: "supper_club", title: "Supper Club" },
    { value: "pizzeria", title: "Pizzeria" },
    { value: "coffee_shop", title: "Coffee Shop" },
    { value: "delis", title: "Delis" },
    { value: "food_court", title: "Food Court" },
  ],
  "Sport & Entertainment": [
    { value: "", title: "Please select" },
    { value: "golf_venue", title: "Golf Course" },
    { value: "bowling", title: " Bowling Alley" },
    { value: "gym", title: "Gym & Fitness Center" },
    { value: "arcade_and_game_center", title: "Arcade & Game Center" },
    { value: "sports_arena", title: "Sports Arena" },
    { value: "concert_hall_and_theater", title: "Concert Hall & Theater" },
    { value: "amusement_and_theme_park", title: "Amusement & Theme Park" },
    { value: "ski_resort", title: "Ski Resort" },
    { value: "museum", title: "Museum" },
    { value: "cinema", title: "Cinema" },
  ],
  Accommodation: [
    { value: "", title: "Please select" },
    { value: "hotel", title: "Hotel" },
    { value: "cozy_retreat", title: "Cozy Retreat" },
    { value: "hostel", title: "Hostel" },
    { value: "hotel_chain", title: "Hotel Chain" },
    { value: "vacation_rental", title: "Vacation Rental" },
    { value: "luxury_resort", title: " Luxury Resort" },
    { value: "family_resort", title: "Family Resorts" },
    { value: "service_apartment", title: "Service Apartment" },
    { value: "bed_and_breakfast", title: "Bed and Breakfast" },
    { value: "motel", title: "Motel" },
    { value: "capsule_hotel", title: "Capsule Hotel" },
    { value: "youth_hostel", title: "Youth Hostel" },
    { value: "campground", title: "Campground" },
    { value: "rv_park", title: "RV Park" },
  ],
  Retail: [
    { value: "", title: "Please select" },
    { value: "sport_essentials", title: "Sporting Goods Store" },
    { value: "home_decor", title: "Home Décor Store" },
    { value: "fashion_threads", title: "Fashion Boutique" },
    { value: "retail_chain", title: "Retail Chain" },
    { value: "specialty_store", title: "Specialty Store" },
    { value: "online_shop", title: "Online Shop" },
    { value: "grocery_store", title: "Grocery Store" },
    { value: "electronics_store", title: "Electronics Store" },
    { value: "pharmacy", title: "Pharmacy" },
    { value: "auto_motorcycle_parts", title: "Auto Parts Store" },
    { value: "liquor_store", title: "Liquor Store" },
    { value: "pet_store", title: "Pet Store" },
  ],
  // Healthcare: [
  //   { value: "", title: "Choose category" },
  //   { value: "hospital", title: "Hospital" },
  //   { value: "dental_clinic", title: "Dental Clinic" },
  // ],
};

const GetStarted = () => {
  const [data, setData] = useState({
    is_demo: true,
  });

  const [allCountries, setAllCountries] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [allStates, setAllStates] = useState([]);
  const [countries, setCountries] = useState([
    { value: "", title: "Please select" },
  ]);
  const [states, setStates] = useState([{ value: "", title: "Please select" }]);
  const [cities, setCities] = useState([{ value: "", title: "Please select" }]);

  useEffect(() => {
    loadRegisterConfig();
  }, []);

  const loadRegisterConfig = () => {
    getRegisterConfig()
      .then((data) => {
        setAllCountries(data?.countries || []);
        // setCountries(data?.countries.map(country => ({
        //   value: country.name,
        //   title: country.name
        // })) || []);
        setCountries([
          { value: "", title: "Please select" },
          {
            value: "USA",
            title: "USA",
          },
        ]);
        setAllStates(data?.states || []);
        // setCities(data?.cities || []);
      })
      .catch((error) => {});
  };

  const affiliate_code = getStorageKey(KEYS.AFFILIATE_CODE);

  const radioStyle = {
    marginLeft: "20px", // Adjust the margin between radio buttons
  };

  const customRadioStyle = {
    width: "20px", // Adjust the width of the radio button
    height: "20px", // Adjust the height of the radio button
    cursor: "pointer",
  };

  const customCheckedRadioStyle = {};

  const handleCheckboxChangeSubscribe = (e) => {
    setData((info) => ({ ...info, subscribe: e.target.checked }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update the data state as usual
    setData((data) => ({
      ...data,
      [name]: value,
    }));

    if (name === "business_challenge" && !isMobile) {
      if (value.includes("") && value.length > 1) {
        // Remove the empty string value if other options are selected
        const filteredValues = value.filter((val) => val !== "");
        setData((data) => ({
          ...data,
          business_challenge: filteredValues,
        }));
      }
    }

    if (name === "restaurant_country") {
      // Get states for the selected country
      const selectedCountry = allCountries.find(
        (country) => country.name === value
      );
      const statesForSelectedCountry =
        selectedCountry && selectedCountry.id
          ? [
              { value: "", title: "Please select" },
              ...selectedCountry.states?.map((state) => ({
                value: state.name,
                title: state.name,
              })),
            ]
          : [{ value: "", title: "Please select" }];

      setStates(statesForSelectedCountry);

      // Reset state and city selections in form data
      setData((data) => ({
        ...data,
        restaurant_state: "",
        restaurant_city: "",
      }));
    } else if (name === "restaurant_state") {
      // Get cities for the selected state
      const selectedState = allStates.find((state) => state.name === value);
      const citiesForSelectedState =
        selectedState && selectedState.id
          ? [
              { value: "", title: "Please select" },
              ...selectedState.cities?.map((city) => ({
                value: city.name,
                title: city.name,
              })),
            ]
          : [{ value: "", title: "Please select" }];

      setCities(citiesForSelectedState);
      setCities(citiesForSelectedState);

      // Reset city selection in form data
      setData((data) => ({
        ...data,
        restaurant_city: "",
      }));
    }
  };

  const handleCheckboxChange = (e, value) => {
    const isChecked = e.target.checked;
    setData((data) => {
      // Get the current array of selected social media, or initialize it as an empty array
      const selectedSocialMedia = data.social_media || [];

      if (isChecked) {
        // Add the value to the array if it's checked and not already present
        return {
          ...data,
          social_media: [...selectedSocialMedia, value],
        };
      } else {
        // Remove the value from the array if it's unchecked
        return {
          ...data,
          social_media: selectedSocialMedia.filter((item) => item !== value),
        };
      }
    });
  };

  const onSubmit = () => {
    // Business Challenge validation
    if (
      (data?.business_challenge === "Other" ||
        (Array.isArray(data?.business_challenge) &&
          data?.business_challenge.includes("Other"))) &&
      !data?.other_business_challenge
    ) {
      NotificationManager.warning(
        `Please specify your challenge`,
        "Warning",
        3000
      );
      return false;
    }

    if (
      data?.how_did_you_hear_about_us === "Other" &&
      !data?.how_did_you_hear_about_us_other
    ) {
      NotificationManager.warning(
        `Please specify Other reason`,
        "Warning",
        3000
      );
      return false;
    }

    if (!data["first_name"]) {
      NotificationManager.warning(
        `Business representative First name field is required`,
        "Warning",
        3000
      );
      return;
    }

    if (!data["last_name"]) {
      NotificationManager.warning(
        `Business representative Last name field is required`,
        "Warning",
        3000
      );
      return;
    }

    if (!data["email"]) {
      NotificationManager.warning(`Email is required`, "Warning", 3000);
      return;
    }

    // Email validation
    if (!data.email || !/\S+@\S+\.\S+/.test(data.email)) {
      NotificationManager.warning(`Invalid email format`, "Warning", 3000);
      return;
    }

    // if (!data['mobile']) {
    //   NotificationManager.warning(`Business Phone number field is required`,'Warning', 3000);
    //   return;
    // }

    if (!data["restaurant_name"]) {
      NotificationManager.warning(
        `Business name field is required`,
        "Warning",
        3000
      );
      return;
    }

    if (!data["restaurant_country"]) {
      NotificationManager.warning(
        `Business country field is required`,
        "Warning",
        3000
      );
      return;
    }

    if (!data["restaurant_city"]) {
      NotificationManager.warning(
        `Business city field is required`,
        "Warning",
        3000
      );
      return;
    }

    if (!data["restaurant_state"]) {
      NotificationManager.warning(
        `Business state field is required`,
        "Warning",
        3000
      );
      return;
    }
    if (!data["restaurant_zipcode"]) {
      NotificationManager.warning(
        `Business postal code field is required`,
        "Warning",
        3000
      );
      return;
    }

    if (!data["industry"]) {
      NotificationManager.warning(
        `Business industry is required`,
        "Warning",
        3000
      );
      return;
    }

    if (!data["category"]) {
      NotificationManager.warning(`Business type is required`, "Warning", 3000);
      return;
    }

    if (!data["years_in_business"]) {
      NotificationManager.warning(
        `Years in business field is required`,
        "Warning",
        3000
      );
      return;
    }

    if (!data["number_of_employees"]) {
      NotificationManager.warning(
        `Nr of employees field is required`,
        "Warning",
        3000
      );
      return;
    }

    if (!data["business_challenge"]) {
      NotificationManager.warning(
        `Business challenge field is required`,
        "Warning",
        3000
      );
      return;
    }

    if (!data["annual_revenue"]) {
      NotificationManager.warning(
        `Annual Revenue field is required`,
        "Warning",
        3000
      );
      return;
    }
    if (!data["social_media"]) {
      NotificationManager.warning(
        `Active Social Media Presence field is required`,
        "Warning",
        3000
      );
      return;
    }

    if (!data["contact_reason"]) {
      NotificationManager.warning(
        `Interest reason field is required`,
        "Warning",
        3000
      );
      return;
    }

    if (affiliate_code) {
      data.affiliate_code = affiliate_code;
    }

    // Create a copy of data for submission
    let submissionData = {
      ...data,
    };

    // Check if it's mobile
    if (!isMobile) {
      submissionData = {
        ...data,
        business_challenge: data?.business_challenge?.join(", "),
      };
    }

    setSubmitting(true);
    createContactSale(submissionData)
      .then((data) => {
        setSubmitting(false);
        setData(null);
        removeStorageKey(KEYS.AFFILIATE_CODE);
        NotificationManager.success(
          data?.message ||
            "Thank you for submitting your request to join VenueBoost! We have received your information and will review it shortly. We will notify you regarding the status of your request through phone or email.",
          "Success",
          5000
        );
      })
      .catch((error) => {
        setSubmitting(false);
        NotificationManager.error(
          error?.message ||
            "Oops! Something went wrong. Please try again or contact us directly at contact@venueboost.io.",
          "Error",
          3000
        );
        throw new Error(
          "An error occurred while contacting VB sales from Get Started Form"
        );
      });
  };

  return (
    <div className={"align-col-middle"}>
      <div className="flex flex-col align-middle">
        <p className="text-26 md:text-[42px] font-extrabold text-[#222222] mt-4 md:mt-16">
          Revitalize Your Business with VenueBoost
        </p>
        <div className="border-[#240b3b] border-[2px] md:border-[3px] px-[18px] md:px-[44px] rounded-[1.5px] my-4 md:my-4 "></div>
        <p className="text-[#666A73] text-14 md:text-20 w-[70%] text-center">
          Experience Tailored Support and Growth. We Value Each Client – No
          Generic Demos, Just Personalized, Hands-On Guidance. Discover the
          Difference with Your Free Plan Today!
        </p>
      </div>
      <div className="align-middle block md:hidden text-26 text-[#222222] font-semibold mt-10 mb-10">
        Get Started Now
      </div>
      <div className="flex flex-row md:rounded-24 overflow-hidden  w-[100vw] md:w-full bg-[#f9f7f7] md:border-[1px] my-0 md:my-12 md:border-[#E0E0E0]">
        <div
          className=" w-[100%] md:w-[60%] z-10 mt-0  rounded-[20px] p-4 md:p-10 py-6 gap-y-4 gap-x-8"
          style={{
            height: "831px",
            overflowX: "auto",
          }}
        >
          <p className="text-[24px] font-extrabold text-[#222222] mb-4">
            Basic Information
          </p>
          <div className="grid grid-cols-2 gap-7  ">
            <FormTextInput
              name="first_name"
              placeholder="First Name"
              value={data?.first_name || ""}
              onChange={handleChange}
              required={true}
            />
            <FormTextInput
              name="last_name"
              placeholder="Last Name"
              value={data?.last_name || ""}
              onChange={handleChange}
              required={true}
            />
            <FormTextInput
              name="email"
              type={"email"}
              placeholder="Email"
              value={data?.email || ""}
              onChange={handleChange}
              required={true}
            />
            <FormTextInput
              name="mobile"
              placeholder="Phone"
              value={data?.mobile || ""}
              onChange={handleChange}
              // required={true}
            />
          </div>
          <p className="text-[24px] font-extrabold text-[#222222] mt-10 mb-4">
            Business Details
          </p>
          <div className="grid grid-cols-2 gap-7  ">
            <FormTextInput
              name="restaurant_name"
              placeholder="Business Name"
              value={data?.restaurant_name || ""}
              onChange={handleChange}
              required={true}
            />
            <FormSelect
              name={"restaurant_country"}
              placeholder="Country"
              value={data?.restaurant_country}
              dataList={countries}
              onChanage={handleChange}
              required={true}
            />
            <FormSelect
              name={"restaurant_state"}
              placeholder="State"
              value={data?.restaurant_state}
              dataList={states}
              onChanage={handleChange}
              required={true}
            />
            <FormSelect
              name={"restaurant_city"}
              placeholder="City"
              value={data?.restaurant_city}
              dataList={cities}
              onChanage={handleChange}
              required={true}
            />
            <FormTextInput
              name="restaurant_zipcode"
              placeholder="Zip/Postal Code"
              value={data?.restaurant_zipcode || ""}
              onChange={handleChange}
              required={true}
            />
            <FormSelect
              name={"industry"}
              placeholder="Industry"
              value={data?.industry || industryList[0].value}
              dataList={industryList}
              onChanage={handleChange}
              required={true}
            />
            <FormSelect
              name={"category"}
              placeholder="Business Type"
              value={data?.category || ""}
              dataList={
                data?.industry
                  ? categoryList[data?.industry]
                  : [{ value: "", title: "Please select" }]
              }
              onChanage={handleChange}
              required={true}
            />
            <FormTextInput
              name="years_in_business"
              placeholder="Years in business"
              value={data?.years_in_business || ""}
              onChange={handleChange}
              required={true}
              tooltipText={
                "Help us understand your business's journey and experience."
              }
            />
          </div>

          <div className={"md:mt-6"}>
            <FormTextInput
              name="number_of_employees"
              placeholder="Number of employees"
              value={data?.number_of_employees || ""}
              onChange={handleChange}
              required={true}
            />
          </div>

          <div className="grid mt-7 grid-cols-2 gap-7  ">
            <FormTextInput
              name="annual_revenue"
              placeholder="Annual Revenue"
              value={data?.annual_revenue || ""}
              onChange={handleChange}
              required={true}
            />
            <div className={"md:mt-1"}>
              <label>Do You Have a Website?</label>
              <div className={"flex"} style={{ marginTop: "10px" }}>
                <input
                  type="radio"
                  name="website"
                  value="yes"
                  checked={data?.website === "yes"}
                  onChange={handleChange}
                  style={{
                    ...customRadioStyle,
                    ...(data?.website === "no" ? customCheckedRadioStyle : {}),
                  }}
                />
                <span style={{ marginLeft: "5px", marginTop: "-2px" }}>
                  Yes
                </span>
                <input
                  type="radio"
                  name="website"
                  value="no"
                  checked={data?.website === "no"}
                  onChange={handleChange}
                  style={{
                    ...radioStyle,
                    ...customRadioStyle,
                    ...(data?.website === "no" ? customCheckedRadioStyle : {}),
                  }}
                />
                <span style={{ marginLeft: "5px", marginTop: "-2px" }}>No</span>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-6 mt-6">
            <div style={{ display: "grid" }}>
              <div className={"flex"}>
                <label className={"text-16 mb-2 text-[#232323]"}>
                  Active Social Media Presence?
                </label>
                <span style={{ color: "#F44336", marginLeft: "2px" }}>*</span>
              </div>

              <div style={{ marginLeft: "10px" }}>
                {socialMediaOptions.map((option, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={data?.social_media?.includes(option.value)}
                        onChange={(e) => handleCheckboxChange(e, option.value)}
                        name="social_media"
                        style={{ marginRight: "4px" }}
                      />
                    }
                    label={option.label}
                    style={{ marginRight: "20px" }}
                  />
                ))}
              </div>
            </div>

            {!isMobile && (
              <div className={"flex flex-col"}>
                <div className={"flex"}>
                  <p className="text-[#232323] text-14 md:text-16 font-medium mb-2">
                    Business Challenges
                  </p>
                  <span style={{ color: "#F44336", marginLeft: "2px" }}>*</span>
                </div>
                <Select
                  name={"business_challenge"}
                  multiple
                  id="business-challenges-select"
                  value={data?.business_challenge || [""]}
                  // error={!isEmpty(errors.business_challenge)}
                  onChange={(e) => {
                    handleChange(e); // Call your form's handleChange function
                  }}
                  sx={{ height: "50px" }}
                >
                  {businessChallenges.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      disabled={option.value === ""}
                    >
                      {option.title}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            )}

            {isMobile && (
              <FormSelect
                name={"business_challenge"}
                placeholder="Business Challenges"
                value={data?.business_challenge || ""}
                dataList={businessChallenges}
                onChanage={handleChange}
                required={true}
              />
            )}

            {/* Conditional rendering for 'Other' option */}
            {(data?.business_challenge === "Other" ||
              (Array.isArray(data?.business_challenge) &&
                data?.business_challenge.includes("Other"))) && (
              <FormTextInput
                name="other_business_challenge"
                placeholder="Please specify your challenge"
                value={data?.other_business_challenge || ""}
                onChange={handleChange}
                required={true}
              />
            )}
            {/*<FormCheckbox*/}
            {/*  name={"demo_account"}*/}
            {/*  placeholder="Demo Account"*/}
            {/*  className=""*/}
            {/*  checked={data?.is_demo}*/}
            {/*  onChange={handleCheckboxChange}*/}
            {/*/>*/}

            <div>
              <div className={"flex"}>
                <p className="text-[#232323] cursor-pointer text-14 md:text-16 font-medium">
                  <Tooltip
                    arrow
                    placement="top"
                    title={
                      <React.Fragment>
                        {/*<Typography color="inherit">Tooltip with HTML</Typography>*/}
                        <p>
                          <b>{"Access Free Plan:"}</b> Explore VenueBoost's
                          features at no cost.
                        </p>
                        <p>
                          <b>{"Request Personalized Consultation:"}</b> Get in
                          touch with our team for a tailored service plan.
                        </p>
                        <p>
                          <b>{"Subscribe to Updates:"}</b> Stay informed with
                          the latest news and updates from VenueBoost.
                        </p>
                      </React.Fragment>
                    }
                  >
                    Interested in
                  </Tooltip>
                </p>
                <span style={{ color: "#F44336", marginLeft: "2px" }}>*</span>
              </div>
              <FormSelect
                name={"contact_reason"}
                enableLabel={false}
                // placeholder="Interested in"
                value={data?.contact_reason || contactReason[0].value}
                dataList={contactReason}
                onChanage={handleChange}
                required={true}
              />
            </div>

            <FormSelect
              name={"how_did_you_hear_about_us"}
              placeholder="How did you hear about us?"
              value={data?.how_did_you_hear_about_us || ""}
              dataList={howDidYouHearOptions}
              onChanage={handleChange}
            />

            {/* Conditional rendering for 'Other' option */}
            {data?.how_did_you_hear_about_us === "Other" && (
              <FormTextInput
                name="how_did_you_hear_about_us_other"
                placeholder="Please specify"
                value={data?.how_did_you_hear_about_us_other || ""}
                onChange={handleChange}
                required={true}
              />
            )}

            <FormTextInput
              name="biggest_additional_change"
              placeholder="What’s your biggest operational challenge?"
              value={data?.biggest_additional_change || ""}
              onChange={handleChange}
            />
            <p className="text-small">
              By clicking <b>Submit now</b> you agree to our
              <a
                style={{ textDecoration: "underline" }}
                className={"text-[#240b3b]"}
                href="https://venueboost.io/privacy-policy/"
              >
                {" "}
                Privacy Policy
              </a>
              .
            </p>
            <div className={""}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="subscribe"
                    checked={data?.subscribe}
                    onChange={handleCheckboxChangeSubscribe}
                    style={{
                      fontSize: "17px",
                      marginRight: "10px",
                      marginLeft: "10px",
                      marginTop: "5px",
                    }}
                  />
                }
                label={
                  "\n" +
                  "I would like to subscribe to emails from VenueBoost. You will receive marketing communications from VenueBoost about news, events, promotions and\n" +
                  "              monthly newsletters. You can unsubscribe from VenueBoost emails at any time."
                }
                style={{ fontSize: "17px" }}
              />
            </div>
          </div>
          <div className="flex flex-col md:items-end w-full ">
            <button
              disabled={submitting}
              className="submit-button font-normal bg-primary1 mt-8  md:w-max h-auto py-2 md:py-4 md:px-6 text-[13px] md:text-16 rounded-[5px] text-white transition ease-in-out hover:scale-101 "
              onClick={onSubmit}
              style={{
                background:
                  "linear-gradient(128deg, #240b3b 3.95%, #3d1e59 34%, #33055E 67.3%, #bc14fe 94.28%)",
              }}
            >
              {submitting ? <CircularProgress size={16} /> : "Submit now"}
            </button>
          </div>
        </div>

        <div className="w-0 md:w-[40%]">
          <img className="w-full h-full object-cover" src={FormImg} />
        </div>
      </div>
    </div>
  );
};

export default GetStarted;
