import apiFactory from "../../utils/apiFactory";
import ImgLogo from "../../assets/images/logo-avatar.png";
import { KEYS, getStorageKey, setStorageKey } from "../../utils/storage";
import { SET_CHAT_MESSAGES, SET_CHAT_LAST_MSG } from "./types";
import { API_CHAT } from "../../constants/apiConfig";

const SYS_MSG = {
  id: new Date().getTime(),
  text: "Welcome to VenueBoost! How can we help you today?",
  options: [
    "I am thinking about using VenueBoost at my venue",
    "What are the key benefits of using VenueBoost for my venue?",
  ],
  option_required: true,
  answered: false,
  greetings: true,
  user: {
    id: "system",
    avatar: ImgLogo,
    full_name: "VenueBoost",
  },
  createdAt: new Date(),
  created_at: new Date().getTime(),
};

export const loadChatHistory = () => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    setStorageKey(KEYS.CHAT_HISTORY, [SYS_MSG]);
    let messages = [SYS_MSG];
    let lastMsg = getStorageKey(KEYS.CHAT_LAST_MSG);
    if (messages != null) {
      let cpy = messages.slice(0);
      cpy.sort((a, b) => a.created_at - b.created_at);
      dispatch({
        type: SET_CHAT_MESSAGES,
        payload: cpy.reverse(),
      });
    } else {
      setStorageKey(KEYS.CHAT_HISTORY, [SYS_MSG]);
      dispatch({
        type: SET_CHAT_MESSAGES,
        payload: [SYS_MSG],
      });
    }
    if (lastMsg) {
      dispatch({
        type: SET_CHAT_LAST_MSG,
        payload: lastMsg,
      });
    } else {
      setStorageKey(KEYS.CHAT_LAST_MSG, SYS_MSG);
      dispatch({
        type: SET_CHAT_LAST_MSG,
        payload: SYS_MSG,
      });
    }
    resolve(messages);
  });
};

export const sendMessage = (answer) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    const messages = getStorageKey(KEYS.CHAT_HISTORY);
    const lastMessage = getStorageKey(KEYS.CHAT_LAST_MSG);
    const chatPredifend = getStorageKey(KEYS.CHAT_PREDEFINED);
    let predefined = 1;
    if (chatPredifend) {
      predefined = parseInt(chatPredifend) + 1;
      setStorageKey(KEYS.CHAT_PREDEFINED, predefined);
    } else {
      setStorageKey(KEYS.CHAT_PREDEFINED, 1);
    }
    if (predefined > 3) {
      setStorageKey(KEYS.CHAT_PREDEFINED, 1);
    }
    let tmpCpy = messages.slice(0);
    const index = tmpCpy.findIndex((m) => m.id === lastMessage?.id);
    if (index !== -1) {
      tmpCpy[index] = {
        ...tmpCpy[index],
        answered: true,
      };
    }
    tmpCpy.push({
      ...answer,
    });

    setStorageKey(KEYS.CHAT_HISTORY, tmpCpy);
    setStorageKey(KEYS.CHAT_LAST_MSG, {
      ...answer,
      id: "system_" + new Date().getTime(),
      createdAt: new Date(),
      created_at: new Date().getTime(),
      user: {
        id: "system",
        avatar: ImgLogo,
        full_name: "VenueBoost",
      },
    });
    dispatch({
      type: SET_CHAT_MESSAGES,
      payload: tmpCpy.reverse(),
    });
    dispatch({
      type: SET_CHAT_LAST_MSG,
      payload: {
        ...answer,
        id: "system_" + new Date().getTime(),
        createdAt: new Date(),
        created_at: new Date().getTime(),
        user: {
          id: "system",
          avatar: ImgLogo,
          full_name: "VenueBoost",
        },
      },
    });
    try {
      let question = await sendChatQuestion({ question: answer.text });
      const chatAnswer = {
        id: "system_" + new Date().getTime(),
        text: question.response,
        question: {
          answers: predefined === 3 ? question.suggested_questions : null,
        },
        option_required:
          predefined === 3
            ? question?.suggested_questions?.length > 0
              ? true
              : false
            : true,
        answered:
          predefined === 3
            ? question?.suggested_questions?.length > 0
              ? false
              : true
            : false,
      };
      const lastMsg = {
        ...chatAnswer,
        id: "system_" + new Date().getTime(),
        createdAt: new Date(),
        created_at: new Date().getTime(),
        user: {
          id: "system",
          avatar: ImgLogo,
          full_name: "VenueBoost",
        },
      };
      tmpCpy.push(lastMsg);
      tmpCpy.sort((a, b) => a.created_at - b.created_at);

      setStorageKey(KEYS.CHAT_HISTORY, tmpCpy);
      setStorageKey(KEYS.CHAT_LAST_MSG, lastMsg);
      dispatch({
        type: SET_CHAT_MESSAGES,
        payload: tmpCpy.reverse(),
      });
      dispatch({
        type: SET_CHAT_LAST_MSG,
        payload: lastMsg,
      });
    } catch (error) {
      resolve();
    }
    resolve();
  });
};

export const clearChatHistory = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    setStorageKey(KEYS.CHAT_HISTORY, [SYS_MSG]);
    setStorageKey(KEYS.CHAT_LAST_MSG, SYS_MSG);
    dispatch({
      type: SET_CHAT_MESSAGES,
      payload: [SYS_MSG],
    });
    dispatch({
      type: SET_CHAT_LAST_MSG,
      payload: SYS_MSG,
    });
  });
};

export const sendChatQuestion = (payload) => {
  return new Promise(async (resolve, reject) => {
    apiFactory({ API_CHAT, path: API_CHAT.path, method: "post" }, payload)
      .then(async (data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
