import React, {useEffect, useState} from "react";
// import Avatar from "../../../assets/svgs/alex_r_avatar.svg";
// import GiftIcon from "../../../assets/svgs/gift-outline.svg";
import CameraIcon from "../../../assets/svgs/camera-outline.svg";
// import Icon1 from "../../../assets/svgs/facebook.svg";
// import Icon2 from "../../../assets/svgs/whatsapp.svg";
// import Icon3 from "../../../assets/svgs/twitter.svg";
import "./index.css";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {Bar} from "react-chartjs-2";
import {useSelector} from "react-redux";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      display: true, // show/ hide x-axis
      grid: {
        display: false, // show/hide grid line in x-axis
      },
    },
    y: {
      display: true, // same as x-axis
      grid: {
        display: false,
      },
    },
  },
};

const labels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const values = [0, 0, 0, 0, 0, 0, 0];
export const data = {
  labels,
  datasets: [
    {
      data: values,
      backgroundColor: "#240b3b",
      barThickness: 6,
    },
  ],
};

const AffiliatesDashboard = () => {
  const {user} = useSelector(state => state.auth);
  const [isCopiedURL, setCopiedURL] = useState(false);
  const [isCopiedCode, setCopiedCode] = useState(false);

  const onCopyURL = () => {
    setCopiedURL(true);
    try {
      const fullUrl =
        user?.affiliate_link.startsWith("http://") ||
        user?.affiliate_link.startsWith("https://")
          ? user?.affiliate_link
          : `https://${user?.affiliate_link}`;
      navigator.clipboard.writeText(fullUrl);
    } catch (e) {
      console.log(e);
    }
    setTimeout(() => {
      setCopiedURL(false);
    }, 2000);
  };

  const walletHistoryData = {
    labels: user?.affiliate_wallet_history.map(item => item.date),
    datasets: [
      {
        label: "Earning ($)",
        data: user?.affiliate_wallet_history.map(item =>
          (item.amount / 100).toFixed(2)
        ),
        backgroundColor: "#240b3b",
        barThickness: 6,
      },
    ],
  };

  const registeredCount = user?.affiliate_usage_links.filter(
    link => link.affiliate_status === "registered"
  ).length;
  const pendingCount = user?.affiliate_usage_links.filter(
    link => link.affiliate_status === "pending"
  ).length;

  const onCopyCode = () => {
    setCopiedCode(true);
    try {
      navigator.clipboard.writeText(user?.affiliate_code);
    } catch (e) {
      console.log(e);
    }
    setTimeout(() => {
      setCopiedCode(false);
    }, 2000);
  };

  const fullUrl =
    user?.affiliate_link.startsWith("http://") ||
    user?.affiliate_link.startsWith("https://")
      ? user?.affiliate_link
      : `https://${user?.affiliate_link}`;
  return (
    <div className='flex flex-col align-col-middle view-terms'>
      <div className='py-[62px] md:px-10 w-full flex flex-col gap-5 '>
        <p className={"text-[#240b3b] text-20 font-bold leading-[34px]"}>
          As a valued member of our Affiliate Program, this dashboard is your
          one-stop destination for managing and tracking your affiliate
          activities. It's designed to give you complete visibility and control
          over your referrals and earnings.
        </p>
        <div className='flex flex-col md:flex-row w-full gap-3 '>
          <div className='border-[1px] border-[#CBD5E1] rounded-[5px] w-full md:w-[70%] p-[18px] flex flex-col md:flex-row'>
            <div className='w-full  md:w-[30%] flex flex-col align-middle mb-5 md:mb-0'>
              {/*<img src={Avatar} className="w-[86px] h-[86px]" />*/}
              <div className='align-middle w-[86px] h-[86px] bg-[#F5F5F5] rounded-[200px]'>
                <img src={CameraIcon} className='w-[48px] h-[48px]' />
              </div>
              <p className='text-[#240b3b] text-20 font-bold leading-[34px] mt-[9px]'>
                {user?.name}
              </p>
              {/*<p className="text-[#666A73] text-14 ">Edit profile</p>*/}
            </div>
            <div className='w-full md:w-[70%] grid grid-cols-2 gap-[18px] flex-1'>
              <div className='flex-1 px-6 py-4 border-[#CBD5E1] border-[1px] rounded-[5px]'>
                <p className='text-[#000000B2] text-14 font-medium '>
                  Registered
                </p>
                <p className='text-[#000000] text-24 font-bold'>
                  {registeredCount}
                </p>
              </div>
              <div className='flex-1 px-6 py-4 border-[#CBD5E1] border-[1px] rounded-[5px]'>
                <p className='text-[#000000B2] text-14 font-medium '>Pending</p>
                <p className='text-[#050404] text-24 font-bold'>
                  {pendingCount}
                </p>
              </div>
              <div className='flex-1 px-6 py-4 border-[#CBD5E1] border-[1px] rounded-[5px]'>
                <p className='text-[#000000B2] text-14 font-medium '>Balance</p>
                <p className='text-[#000000] text-24 font-bold'>
                  {(user?.balance / 100).toFixed(2)} $
                </p>
              </div>
              <div className='flex-1 px-6 py-4 border-[#CBD5E1] border-[1px] rounded-[5px]'>
                <p className='text-[#000000B2] text-14 font-medium '>
                  Awaiting Payout
                </p>
                <p className='text-[#000000] text-24 font-bold'>
                  {(user?.balance / 100).toFixed(2)} $
                </p>
              </div>
            </div>
          </div>
          <div className='border-[1px] border-[#CBD5E1] rounded-[5px] w-full min-h-[200px] md:w-[30%] p-1 align-middle'>
            <Bar options={options} data={walletHistoryData} />
          </div>
        </div>
        <div className='border-[1px] border-[#CBD5E1] rounded-[5px] px-8 flex flex-col md:flex-row'>
          <div className='align-middle flex-col py-8 w-full'>
            <div className='align-middle w-[86px] h-[86px] bg-[#F5F5F5] rounded-[200px]'>
              <img src={CameraIcon} className='w-[48px] h-[48px]' />
            </div>
            <p className='mt-5 text-18 font-bold leading-[34px] text-[#240b3b] text-center'>
              Your reward for referring new customers
            </p>
            <p className='text-18 font-medium leading-[34px] text-[#240b3b] w-full md:w-[80%] text-center'>
              {user?.affiliate_plan?.preferred_method === "Fixed Percentage" &&
                `You get up to ${user?.affiliate_plan.percentage}% commission for each referred customer to VenueBoost.`}
              {user?.affiliate_plan?.preferred_method === "Fixed Amount" &&
                `You earn a fixed amount of ${user?.affiliate_plan.fixed_value} for each referred customer to VenueBoost.`}
              {user?.affiliate_plan?.preferred_method === "Sliding Scale" &&
                `Your commission varies and is based on the number of customers you refer to VenueBoost.`}
            </p>
          </div>
        </div>

        {user?.affiliate_usage_links && (
          <div className=''>
            <p className='text-[#240b3b] text-20 font-bold leading-[34px] mt-4 mb-2 text-left'>
              Your Affiliate Links Used
            </p>
            <div className='border-[1px] border-[#CBD5E1] rounded-[5px] px-8 flex flex-col md:flex-row '>
              <table className='w-full text-left mt-4 mb-4'>
                <thead>
                  <tr>
                    <th className='p-2'>Venue Name</th>
                    <th className='p-2'>Amount</th>
                    <th className='p-2'>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {user?.affiliate_usage_links.map(link => (
                    <tr key={link.id}>
                      <td className='p-2'>{link.venue_name}</td>
                      <td className='p-2'>
                        {(link.total_amount / 100).toFixed(2)} $
                      </td>

                      <td className='p-2'>
                        {link.affiliate_status === "registered" && (
                          <span
                            className='font-bold'
                            style={{color: "#009688"}}
                          >
                            {link.affiliate_status.toUpperCase()}
                          </span>
                        )}

                        {link.affiliate_status !== "registered" && (
                          <span
                            className='font-bold'
                            style={{color: "#607D8B"}}
                          >
                            {link.affiliate_status.toUpperCase()}
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        <div className='border-[1px] border-[#CBD5E1] rounded-[5px] px-4 md:px-[52px] py-9 flex-col align-middle '>
          <div
            style={{justifyContent: "center"}}
            className='flex flex-col md:flex-row w-full gap-5 md:gap-[104px] '
          >
            <div className='flex flex-col w-full md:w-[55%]'>
              <p className='text-[#240b3b] text-18 font-bold leading-[34px] text-center  mb-[18px] md:mb-[18px]'>
                Share this link to your friends and followers
              </p>
              <div className='relative overflow-hidden w-full h-[50px] align-middle border-[#CBD5E1] border-[1px] rounded-[5px] px-2 md:pl-3'>
                <p className=' flex-1 text-[#240b3b] text-16 font-medium leading-[34px] px-2 w-full'>
                  {fullUrl}
                </p>
                {isCopiedURL ? (
                  <button className='absolute right-0  w-[120px] h-[50px] border-[1px] border-[#CBD5E1] bg-[#240b3b] rounded-[5px] text-white text-16 font-bold '>
                    Copied
                  </button>
                ) : (
                  <button
                    onClick={onCopyURL}
                    className='absolute right-0  w-[120px] h-[50px] border-[1px] border-[#CBD5E1] bg-[#240b3b] rounded-[5px] text-white text-16 font-bold '
                  >
                    Copy
                  </button>
                )}
              </div>
            </div>
            {/*<div className="flex flex-col w-full md:w-[45%]">*/}
            {/*  <p className="text-[#240b3b] text-20 font-bold leading-[34px] text-center mb-[18px] md:mb-[30px]">*/}
            {/*    {user?.affiliate_code}*/}
            {/*  </p>*/}

            {/*  {*/}
            {/*    isCopiedCode ?*/}
            {/*        <button className="w-full h-[50px] border-[1px] border-[#CBD5E1] bg-[#240b3b] rounded-[5px] text-white text-16 font-bold ">*/}
            {/*          Copied*/}
            {/*        </button>*/}
            {/*        :*/}
            {/*        <button onClick={onCopyCode} className="w-full h-[50px] border-[1px] border-[#CBD5E1] bg-[#240b3b] rounded-[5px] text-white text-16 font-bold ">*/}
            {/*          Copy Affiliate Code*/}
            {/*        </button>*/}
            {/*  }*/}

            {/*</div>*/}
          </div>
          {/*<p className="text-[#240b3b] text-16 font-medium leading-6 py-[30px]">*/}
          {/*  or use:*/}
          {/*</p>*/}
          {/*<div className="flex justify-between w-full px-6  md:w-[35%]">*/}
          {/*  <img src={Icon1} className="w-[44px] h-[44px]" />*/}
          {/*  <img src={Icon3} className="w-[44px] h-[44px]" />*/}
          {/*  <img src={Icon2} className="w-[44px] h-[44px]" />*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  );
};

export default AffiliatesDashboard;
