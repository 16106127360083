import PropTypes from "prop-types";
import Shapes from "../../../assets/images/beachbarorder/shapes.svg";
import "../index.css";
import { beachBarOrderText } from "..";
import { FaChevronDown } from "react-icons/fa";
import IconSocial2 from "../../../assets/images/beachbarorder/social-icon2.svg";
import LoaderIcon2 from "../../../assets/images/beachbarorder/iconsloader2.svg";
import IconChevronUp from "../../../assets/images/beachbarorder/iconschevronup.svg";
import ProductList from "../order-details/product-list";
import { useState } from "react";

const Content1 = ({ className = "", products = [] }) => {
  const [showProducts, setShowProducts] = useState(false);

  const filteredProducts = products.filter((item) => Number(item.value) > 0);

  return (
    <div
      className={`success-section self-stretch flex flex-col items-center justify-center pt-4 px-6 pb-0 !gap-4 text-center text-base text-lightslategray font-manrope ${className}`}
    >
      <div className="bg-white flex flex-col rounded-[12px] justify-center">
        <label className="cursor-pointer" for="file-437:1652">
          <div className="rounded-2xl bg-bg overflow-hidden flex flex-col items-center justify-center py-[30px] px-0 gap-[21px]">
            <img
              className="self-stretch relative max-w-full overflow-hidden max-h-full"
              loading="lazy"
              alt=""
              src={Shapes}
            />
            <div className="self-stretch flex flex-col items-center justify-start py-0 px-5 gap-[4px]">
              <div className="relative leading-[28px] font-semibold whitespace-nowrap">
                {beachBarOrderText("thankYouOrder")}
              </div>
              <div className="text-[#17CE8C] self-stretch relative text-lg tracking-[-0.1px] leading-[28px] font-extrabold text-mediumseagreen">
                {beachBarOrderText("youWillReceive")}
              </div>
            </div>
          </div>
        </label>
        <input className="hidden" type="file" id="file-437:1652" />
      </div>

      <div className="bg-white self-stretch rounded-2xl flex flex-col justify-center items-center p-4 gap-[1px]">
        <div className="self-stretch flex flex-wrap gap-4">
          <div className="flex-1 flex flex-col justify-center items-center px-0 pb-0 min-w-[153px]">
            <div className="self-stretch relative leading-[22px] font-semibold text-[#8E8EA9]">
              {beachBarOrderText("Products")} ({filteredProducts.length}{" "}
              {beachBarOrderText("products")})
            </div>
          </div>
          <div className="rounded-xl flex p-3 gap-[8px] text-center text-secondary-color-yellow-1 font-button-medium-extrabold">
            <img
              className="h-5 w-5 relative overflow-hidden shrink-0 hidden min-h-[20px]"
              alt=""
              src={IconSocial2}
            />
            <img
              className="h-5 w-5 relative overflow-hidden shrink-0 hidden min-h-[20px]"
              alt=""
              src={LoaderIcon2}
            />

            {showProducts ? (
              <img
                className="cursor-pointer h-5 w-5 relative overflow-hidden shrink-0"
                loading="lazy"
                alt=""
                src={showProducts ? IconChevronUp : <FaChevronDown />}
                onClick={() => setShowProducts(!showProducts)}
              />
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="cursor-pointer h-6 w-6 relative overflow-hidden shrink-0 font-light"
                onClick={() => setShowProducts(!showProducts)}
              >
                <path
                  fillRule="evenodd"
                  d="M12 15.5l6-6-1.5-1.5L12 12.5 7.5 8 6 9.5l6 6z"
                  clipRule="evenodd"
                />
              </svg>
            )}
          </div>
        </div>
        <div className="self-stretch product-list-wrapper flex flex-col gap-[15.5px] text-neutral-neutral-800">
          {showProducts && (
            <div className="mt-2">
              <ProductList products={filteredProducts} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Content1.propTypes = {
  className: PropTypes.string,
};

export default Content1;
