export const drinkProducts = [
  {
    imgSrc: "https://test-locofy-repo.vercel.app/pngwingcom-18-1@2x.png",
    name: "Coca - Cola Classic",
    value: "0",
    id: 1,
    price: "200",
    currency: "ALL",
    description:
      "Nothing beats the taste of classic Coca‑Cola. Is it designed to go with everything.",
    size: "330ml",
  },
  {
    imgSrc:
      "https://test-locofy-repo.vercel.app/10640177eacheckers515wx515h-1@2x.png",
    name: "Fanta Orange",
    value: "0",
    id: 2,
    price: "200",
    currency: "ALL",
    description: "Description",
    size: "330ml",
  },
  {
    imgSrc:
      "https://test-locofy-repo.vercel.app/gl00463311fantaexotic-1920x1920-1@2x.png",
    name: "Fanta Exotic",
    description: "Description",
    price: "200",
    id: 3,
    currency: "ALL",
    value: "0",
    size: "330ml",
  },
  {
    imgSrc: "https://test-locofy-repo.vercel.app/image-37@2x.png",
    name: "Orange Soda",
    description: "Description",
    price: "200",
    currency: "ALL",
    id: 4,
    value: "0",
    size: "330ml",
  },
  {
    imgSrc: "https://test-locofy-repo.vercel.app/image-38@2x.png",
    name: "Lemon Soda",
    description: "Description",
    price: "200",
    currency: "ALL",
    id: 5,
    value: "0",
    size: "330ml",
  },
  {
    name: "Sprite",
    description: "Refreshingly clear lemon-lime soda.",
    price: "200",
    currency: "ALL",
    id: 6,
    value: "0",
    size: "330ml",
  },
  {
    name: "Pepsi",
    description: "The bold, refreshing, robust cola.",
    price: "200",
    currency: "ALL",
    id: 7,
    value: "0",
    size: "330ml",
  },
  {
    name: "Dr. Pepper",
    description: "A unique blend of 23 flavors.",
    price: "200",
    currency: "ALL",
    id: 8,
    value: "0",
    size: "330ml",
  },
  {
    name: "Mountain Dew",
    description: "The original instigator, refreshingly citrus.",
    price: "250",
    currency: "ALL",
    id: 9,
    value: "0",
    size: "500ml",
  },
  {
    name: "Ginger Ale",
    description: "Crisp, refreshing ginger flavor.",
    price: "200",
    currency: "ALL",
    id: 10,
    value: "0",
    size: "330ml",
  },
  {
    name: "Tonic Water",
    description: "Quinine-flavored soda, perfect for cocktails.",
    price: "200",
    currency: "ALL",
    id: 11,
    value: "0",
    size: "330ml",
  },
  {
    name: "Root Beer",
    description: "A classic American favorite.",
    price: "250",
    currency: "ALL",
    id: 12,
    value: "0",
    size: "500ml",
  },
  {
    name: "Club Soda",
    description: "Plain soda water with a hint of salt.",
    price: "200",
    currency: "ALL",
    id: 13,
    value: "0",
    size: "330ml",
  },
  {
    name: "Energy Drink",
    description: "Boost your energy levels instantly.",
    price: "250",
    currency: "ALL",
    id: 14,
    value: "0",
    size: "500ml",
  },
  {
    name: "Iced Tea",
    description: "Refreshing and cool, perfect for summer.",
    price: "250",
    currency: "ALL",
    id: 15,
    value: "0",
    size: "500ml",
  },
  {
    name: "Sparkling Water",
    description: "Bubbly and refreshing water.",
    price: "200",
    currency: "ALL",
    id: 16,
    value: "0",
    size: "330ml",
  },
];

export const sx = {
  borderTopWidth: "1px",
  borderRightWidth: "1px",
  borderBottomWidth: "1px",
  borderLeftWidth: "1px",
  backgroundColor: "#fff",
  borderRadius: "16px",
  width: "87.2%",
  height: "72px",
  m: 0,
  p: 0,
  "& .MuiInputBase-root": {
    m: 0,
    p: 0,
    minHeight: "72px",
    justifyContent: "center",
    display: "inline-flex",
  },
  "& .MuiInputLabel-root": {
    m: 0,
    p: 0,
    minHeight: "72px",
    display: "inline-flex",
  },
  "& .MuiMenuItem-root": {
    m: 0,
    p: 0,
    height: "72px",
    display: "inline-flex",
  },
  "& .MuiSelect-select": {
    m: 0,
    p: 0,
    height: "72px",
    alignItems: "center",
    display: "inline-flex",
  },
  "& .MuiInput-input": { m: 0, p: 0 },
  "& .MuiInputBase-input": {
    color: "#8e8ea9",
    fontSize: 14,
    fontWeight: "SemiBold",
    fontFamily: "Yellix",
    textAlign: "left",
    p: "0 !important",
    marginLeft: "16px",
  },
};

export const productOptions = [
  { size: "330ml", price: 200, currency: "ALL", quantity: 1 },
  { size: "500ml", price: 250, currency: "ALL", quantity: 1 },
];

export const plusIcon = (
  <svg
    width="25"
    height="26"
    viewBox="0 0 25 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.289062"
      y="0.899902"
      width="24.7109"
      height="24.7109"
      rx="12.3555"
      fill="#F2FFFA"
    />
    <path
      d="M12.6445 9.20117V17.3094"
      stroke="#17CE8C"
      strokeWidth="1.54443"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.58984 13.2554H16.6981"
      stroke="#17CE8C"
      strokeWidth="1.54443"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const plusGrayIcon = (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5 4.16675V15.8334"
      stroke="#8E8EA9"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.66797 10H16.3346"
      stroke="#8E8EA9"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const minusIcon = (
  <svg
    width="25"
    height="26"
    viewBox="0 0 25 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.289062"
      y="0.38916"
      width="24.7109"
      height="24.7109"
      rx="12.3555"
      fill="#F2FFFA"
    />
    <path
      d="M8.58984 12.7446H16.6981"
      stroke="#17CE8C"
      strokeWidth="1.54443"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const minusGrayIcon = (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.66797 10H16.3346"
      stroke="#8E8EA9"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
