import React, {useEffect} from "react";
import "./index.css";
// import ScrollToUpIcon from "../../assets/svgs/scroll-up.svg";
import {useDispatch, useSelector} from "react-redux";
import {setShowTopBtn} from "../../redux/actions/app";
import {useNavigate} from "react-router";

const BlogSubscribeFloatButton = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showTopBtn = useSelector(state => state.app.showTopBtn);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 200) {
        dispatch(setShowTopBtn(true));
      } else {
        dispatch(setShowTopBtn(false));
      }
    });
    // eslint-disable-next-line
  }, []);

  const goGetStarted = () => {
    navigate(`/get-started`);
  };

  return (
    <div className=' sm:hidden relative z-999'>
      {showTopBtn === true && (
        <div
          className='rounded-[50%] fixed bottom-[85px] right-8 z-[1000]'
          onClick={() => goGetStarted()}
        >
          <div className='subscribe-btn-trigger-container'>
            <svg
              width='26'
              height='26'
              viewBox='0 0 26 26'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M4.87498 17.8751C3.24998 19.2401 2.70831 23.2918 2.70831 23.2918C2.70831 23.2918 6.75998 22.7501 8.12498 21.1251C8.89415 20.2151 8.88331 18.8176 8.02748 17.9726C7.6064 17.5707 7.05171 17.3385 6.46989 17.3205C5.88807 17.3025 5.3201 17.5 4.87498 17.8751ZM13 16.2501L9.74998 13.0001C10.3265 11.5045 11.0524 10.0708 11.9166 8.72094C13.1789 6.70267 14.9366 5.04091 17.0224 3.8937C19.1082 2.7465 21.4529 2.15201 23.8333 2.16677C23.8333 5.11344 22.9883 10.2918 17.3333 14.0834C15.9649 14.9487 14.5132 15.6746 13 16.2501Z'
                stroke='white'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M9.74998 13.0003H4.33331C4.33331 13.0003 4.92915 9.71781 6.49998 8.66697C8.25498 7.49697 11.9166 8.66697 11.9166 8.66697M13 16.2503V21.667C13 21.667 16.2825 21.0711 17.3333 19.5003C18.5033 17.7453 17.3333 14.0836 17.3333 14.0836'
                stroke='white'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>

            <span className='subscribe-btn-trigger'> Get Started </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default BlogSubscribeFloatButton;
