import PropTypes from "prop-types";
import { minusGrayIcon, plusGrayIcon } from "../data";

const AddRemovalStructureOrder = ({
  className = "",
  setProducts,
  index = 0,
  products = [],
  selectedProduct,
  quantity,
  setQuantity,
}) => {
  const updateQuantity = (value) => {
    setQuantity(true);
    setProducts((prevProducts) =>
      prevProducts.map((p, i) =>
        i === index
          ? { ...p, value: p.value < 1 ? 1 : `${parseInt(p.value) + value}` }
          : p
      )
    );
  };
  return (
    <div
      className={`plus-minus-actions rounded-2xl bg-neutral-neutral-100 flex py-4 px-5 gap-[14px] text-center text-sm text-midnightblue font-button-medium-extrabold ${className}`}
    >
      <div
        className="cursor-pointer w-5 flex flex-col pt-px px-0 pb-0 box-border"
        onClick={() => updateQuantity(-1)}
      >
        <span className="w-5 h-5 relative overflow-hidden shrink-0">
          {minusGrayIcon}
        </span>
      </div>
      <b className="relative leading-[22px] inline-block min-w-[9px] text-[#240B3B]">
        {quantity ? products[index]?.value : selectedProduct?.value ?? "1"}
      </b>
      <div
        className="w-5 flex flex-col items-start justify-start pt-px px-0 pb-0 box-border"
        onClick={() => updateQuantity(1)}
      >
        <span className="w-5 h-5 relative overflow-hidden shrink-0">
          {plusGrayIcon}
        </span>
      </div>
    </div>
  );
};

AddRemovalStructureOrder.propTypes = {
  className: PropTypes.string,
};

export default AddRemovalStructureOrder;
