import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import confirmed_success from '../../assets/svgs/confirmed_success.svg';
import { useEffect, useState } from "react";
import moment from "moment";

const OrderSuccessScreen = ({fullName}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [date, setDate] = useState('');

    useEffect(()=> {
        setDate(moment(new Date()).format('MMMM Do YYYY, h:mm:ss a'))
    }, [])

    return (
        <div className='flex flex-col justify-center rounded-[13px] py-8'>
            <div className='flex flex-col w-full justify-center items-center'>
                <img src={confirmed_success} alt='Confirmation Icon' className='w-[100px] h-[100px] py-4' />
                <p className='text-black1 text-[16px] font-bold'>Order Successfully Placed</p>
                {/*<div className='flex flex-column justify-center items-center gap-8 mt-4 mb-4'>*/}
                    {/*<div className='flex flex-column justify-center items-center gap-8'>*/}
                        <p className='text-black1 font-bold mt-4 mb-2'>Order Date</p>
                        <p className='mb-4'>
                            <span className='text-gray1'>{date}</span>
                        </p>
                    {/*</div>*/}
                {/*</div>*/}
                <div className='flex flex-col justify-center items-center text-[13px] text-cyan1 bg-[#ECFDF3] border-[#ECFDF3] border align-center rounded-[10px] px-12 py-4 mb-4'>
                    <div className="flex justify-center items-center text-[12px] font-normal text-black1">
                        A confirmation email will be sent to you shortly, including any remarks from the venue.
                    </div>
                </div>
            </div>
        </div>
    )

}

export default OrderSuccessScreen;