import WhoWeServeAccommodationOverview from "../../views/whoweserve/accommodation_overview";
import Hostels from "../../views/whoweserve/accommodation_overview/hostels";
import CozyRetreats from "../../views/whoweserve/accommodation_overview/cozy_retreats";
import HotelChains from "../../views/whoweserve/accommodation_overview/hotel_chains";
import VacationRentals from "../../views/whoweserve/accommodation_overview/vacation_rentals";
import Hotels from "../../views/whoweserve/accommodation_overview/hotels";
import ServiceApartments from "../../views/whoweserve/accommodation_overview/service_apartments";
import Resorts from "../../views/whoweserve/accommodation_overview/resorts";
import LuxuryResorts from "../../views/whoweserve/accommodation_overview/luxury_resorts";
import BedAndBreakfast from "../../views/whoweserve/accommodation_overview/bed_and_breakfast";
import Campground from "../../views/whoweserve/accommodation_overview/campground";
import CapsuleHotel from "../../views/whoweserve/accommodation_overview/capsule_hotel";
import YouthHostel from "../../views/whoweserve/accommodation_overview/youth_hostel";
import RvPark from "../../views/whoweserve/accommodation_overview/rv_park";
import Motel from "../../views/whoweserve/accommodation_overview/motel";
import { Route } from "react-router-dom";
import RouteNames from "../../constants/route_names";

const accommodationRoutes = [
  <Route
    exact
    path={RouteNames.whoweserve_accommodation_overview}
    element={<WhoWeServeAccommodationOverview />}
  />,
  <Route exact path={RouteNames.whoweserve_hotels} element={<Hotels />} />,
  <Route
    exact
    path={RouteNames.whoweserve_cozy_retreats}
    element={<CozyRetreats />}
  />,
  <Route exact path={RouteNames.whoweserve_hostels} element={<Hostels />} />,
  <Route
    exact
    path={RouteNames.whoweserve_hotel_chains}
    element={<HotelChains />}
  />,
  <Route
    exact
    path={RouteNames.whoweserve_vacation_rental}
    element={<VacationRentals />}
  />,
  <Route exact path={RouteNames.whoweserve_resorts} element={<Resorts />} />,
  <Route
    exact
    path={RouteNames.whoweserve_luxury_resorts}
    element={<LuxuryResorts />}
  />,
  <Route
    exact
    path={RouteNames.whoweserve_service_apartments}
    element={<ServiceApartments />}
  />,
  <Route
    exact
    path={RouteNames.whoweserve_bed_and_breakfast}
    element={<BedAndBreakfast />}
  />,
  <Route
    exact
    path={RouteNames.whoweserve_campground}
    element={<Campground />}
  />,
  <Route
    exact
    path={RouteNames.whoweserve_capsule_hotel}
    element={<CapsuleHotel />}
  />,
  <Route
    exact
    path={RouteNames.whoweserve_youth_hostel}
    element={<YouthHostel />}
  />,
  <Route exact path={RouteNames.whoweserve_rv_park} element={<RvPark />} />,
  <Route exact path={RouteNames.whoweserve_motel} element={<Motel />} />,
];

export default accommodationRoutes;
