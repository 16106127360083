import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import WhiteLabelService from "../../services/whiteLabelService";
import { NotificationManager } from "react-notifications";
import { useEffect } from "react";
import ProductHeader from "./product-header";
import RelatedProducts from "./related-products";
import ProductForm from "./product-form";
import { identifyVenue, trackEvent } from "../../utils/mixpanelUtil";
import { SET_VENUE_DATA } from "../../redux/actions/types";
import { useDispatch } from "react-redux";
import productImg from "../../assets/images/productupdate/kindpng_375186 1.png";
import image1 from "../../assets/images/productupdate/image1.png";
import image2 from "../../assets/images/productupdate/image2.png";
import image3 from "../../assets/images/productupdate/image3.png";
import image4 from "../../assets/images/productupdate/image4.png";
import "./index.css";
import ReviewStars from "./review-stars";
import { colorData, dummyData } from "../cart/checkout/data";
import { FavoriteIcon, minus, plus, viewIcon } from "../venue-retail/icons";
import { Tabs } from "antd";
import ProductItems from "../venue-retail/retail-shop/product-items";

const ProductDetailsScreen = () => {
  const [details, setDetails] = useState({});
  const { id, app_key } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isFavorite, setIsFavorite] = useState(false);
  const [tab, setTab] = useState("1");

  const [quantities, setQuantities] = useState(1);

  useEffect(() => {
    getDetails();
    getVenueDetails();
  }, [id, app_key]);

  const getVenueDetails = () => {
    localStorage.removeItem("venueData");
    WhiteLabelService.getDetails(app_key).then(
      (res) => {
        if (res.allowed_only_from !== "retail") {
          navigate("/");
        } else {
          dispatch({ type: SET_VENUE_DATA, payload: res.venue });
          setDetails(res.venue);

          localStorage.setItem("venueData", JSON.stringify(res.venue));

          identifyVenue(app_key);
          trackEvent("White Label - Product details", {
            action_category: "Page Load",
            action_outcome: "success",
            interaction_element: "screen",
            industry: "product_details",
            source: "vb_web",
          });
        }
      },
      (error) => {
        NotificationManager.error(
          error?.message ||
            "Oops! Something went wrong. Please try again or contact us directly at contact@venueboost.io.",
          "Error",
          3000
        );

        identifyVenue(app_key);
        trackEvent("White Label - Product details", {
          action_category: "Page Load",
          action_outcome: "fail",
          interaction_element: "screen",
          industry: "product_details",
          source: "vb_web",
        });
      }
    );
  };

  const getDetails = () => {
    WhiteLabelService.getProductDetails(id, app_key)
      .then((res) => {
        setDetails(res.product);
        identifyVenue(app_key);
        trackEvent("Product Details", {
          action_category: "Page Load",
          action_outcome: "success",
          interaction_element: "screen",
          source: "vb_web",
        });
      })
      .catch((error) => {
        NotificationManager.error(
          error?.message ||
            "Oops! Something went wrong. Please try again or contact us directly at contact@venueboost.io.",
          "Error",
          3000
        );
        identifyVenue(app_key);
        trackEvent("Product Details", {
          action_category: "Page Load",
          action_outcome: "success",
          interaction_element: "screen",
          source: "vb_web",
        });
      });
  };

  const getProductDetails = (
    <div className="w-full rounded-4xl md:p-14 md:pl-0 md:pr-0">
      <div className="flex flex-col">
        <ProductHeader data={details} app_key={app_key} />
        <div className="bg-white mt-4">
          {/* <ProductDetails data={details} /> */}
          <ProductForm
            data={details}
            app_key={app_key}
            currency={details?.currency}
          />
          <RelatedProducts
            data={details?.related_products ?? []}
            app_key={app_key}
            currency={details?.currency}
          />
        </div>
      </div>
    </div>
  );

  const images = [image1, image2, image3, image4];

  const children1 = (
    <div className="children1 flex flex-col gap-5">
      <span>
        These radiant earrings are the perfect representation of the iconic
        Swarovski Swan in motion. To create the shimmering silhouette, each
        piece features all-over pavé in white crystals set on high-shine rhodium
        plating. Wear them as a daily reminder to spread your wings.
      </span>

      <span>
        Swarovski crystal is a delicate material that must be handled with
        special care. To ensure that your Swarovski product remains in the best
        possible condition over an extended period of time, please observe the
        advice below to avoid damage:
      </span>
    </div>
  );

  const items = [
    {
      key: "1",
      label: "Description",
      children: children1,
    },
    {
      key: "2",
      label: "Additional Information",
      children: children1,
    },
  ];

  return (
    <>
      <div className=" bg-opacity-5 h-[50px] w-full bg-[#000000] absolute left-0 top-[112px] items-center hidden md:flex">
        <div className="large-width mx-auto px-4 sm:px-6 md:px-8 lg:px-16 w-full flex flex-row items-center">
          <div>{ `Shop` }</div>
          <span className="w-[20px] h-[20px] flex justify-center items-center">
            <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0.943573 0.345232C0.674034 0.560863 0.630333 0.954171 0.845964 1.22371L3.86695 4.99994L0.845964 8.77617C0.630333 9.04571 0.674034 9.43902 0.943573 9.65465C1.21311 9.87028 1.60642 9.82658 1.82205 9.55704L5.15538 5.39038C5.33799 5.16211 5.33799 4.83777 5.15538 4.60951L1.82205 0.442841C1.60642 0.173302 1.21311 0.129601 0.943573 0.345232Z" fill="#131118"/>
            </svg>
          </span>
          <div>{ `Swarovski` }</div>
          <span className="w-[20px] h-[20px] flex justify-center items-center">
            <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0.943573 0.345232C0.674034 0.560863 0.630333 0.954171 0.845964 1.22371L3.86695 4.99994L0.845964 8.77617C0.630333 9.04571 0.674034 9.43902 0.943573 9.65465C1.21311 9.87028 1.60642 9.82658 1.82205 9.55704L5.15538 5.39038C5.33799 5.16211 5.33799 4.83777 5.15538 4.60951L1.82205 0.442841C1.60642 0.173302 1.21311 0.129601 0.943573 0.345232Z" fill="#131118"/>
            </svg>
          </span>
          <div>{ `Iconic Swan Drop Earrings` }</div>
        </div>
      </div>


      <div className="flex flex-col gap-5 my-2 mb-5">
        <div className="grid md:grid-cols-2 gap-10 product-details mt-8">
          <div className="w-full flex flex-col gap-3 pt-6">
            <img alt="" src={productImg} className="h-[335px] w-[320px] lg:w-[555px] rounded-xl lg:rounded-none lg:h-auto object-cover lg:object-contain" />
              <div className="flex gap-1 items-center overflow-x-auto whitespace-nowrap">
                {images.map((item, index) => {
                  return (
                    <img className="cursor-pointer inline-block" key={index} alt="" src={item} />
                  );
                })}
              </div>
          </div>
          <div className="flex flex-col pt-4 gap-5 w-full">
            <div className="flex flex-col gap-2">
              <div className="flex justify-between w-full">
                <span className="text-[#131118] text-20 font-semibold leading-7 lg:text-24 lg:font-bold lg:leading-8">Iconic Swan Drop Earrings</span>
                <div className="badge !hidden lg:block">
                  <span>In Stock</span>
                </div>
              </div>
              <span className="text-[#131118] text-16 font-normal leading-7 lg:text-18 lg:font-medium">Swan, White, Rhodium plated</span>
            </div>

            <div className="flex items-center gap-1">
              <ReviewStars rating={5} />
              <span className="reviews">5.0 (121 Reviews)</span>
            </div>
            <div className="lg:flex items-center gap-2 hidden">
              <span className="price">{"$80"}</span>
              <span className="previous-price">{"$100"}</span>
            </div>
            <div className="text-[#131118] text-14 font-medium lg:text-16">
              These radiant earrings are the perfect representation of the iconic Swarovski Swan in motion.{" "}
            </div>

            <div className="flex flex-col gap-2">
              <span className="color">Color</span>
              <div className="color-section">
                {colorData.map((item, index) => {
                  return (
                    <div
                      className={`h-9 w-9 border cursor-pointer ${item.bg_color} rounded`}
                      key={index}
                    />
                  );
                })}
              </div>
            </div>

            <div className="hidden lg:flex items-center gap-2">
              <div className="flex justify-center items-center border gap-4 rectangle px-2 !h-14">
                <span
                  className="cursor-pointer"
                  onClick={() =>
                    setQuantities((prev) => (prev <= 1 ? prev : prev - 1))
                  }
                >
                  {minus}
                </span>
                <span className="text-price">{quantities}</span>
                <span
                  className="cursor-pointer"
                  onClick={() => setQuantities((prev) => prev + 1)}
                >
                  {plus}
                </span>
              </div>

              <div className="add-to-cart-layout cursor-pointer w-[340px] h-14">
                <span>Add to Cart</span>
              </div>
              <div
                className="favorite h-14 cursor-pointer"
                onClick={() => setIsFavorite(!isFavorite)}
              >
                {FavoriteIcon(isFavorite)}
              </div>
            </div>
          </div>
        </div>

        <div className="description-section !hidden lg:!block">
          <Tabs
            onChange={setTab}
            items={items}
            defaultActiveKey="1"
            activeKey={tab}
            tabBarStyle={{ color: '#131118', fontSize: '18px', fontWeight: 600 }}
          />
        </div>

        <div className="w-full lg:hidden flex flex-col gap-3">
          <div className="text-[#2A3563] text-16 font-semibold">Description</div>
          <div className="text-[#131118] text-12 font-normal">These radiant earrings are the perfect representation of the iconic Swarovski Swan in motion. To create the shimmering silhouette, each piece features all-over pavé in white crystals set on high-shine rhodium plating. Wear them as a daily reminder to spread your wings.
                  <br />
                  <br />
Swarovski crystal is a delicate material that must be handled with special care. To ensure that your Swarovski product remains in the best possible condition over an extended period of time, please observe the advice below to avoid damage:</div>
        </div>

        <div className="related-products flex flex-col gap-5">
          <div className="text-[#131118] text-16 font-semibold lg:text-[32px] lg:font-medium">Related products</div>
          <ProductItems
            className="md:grid-cols-4 gap-[30px] overflow-x-auto  whitespace-nowrap"
            topIcon={viewIcon}
            buttonLabel="Add to Cart"
            data={dummyData.filter((item, index) => index <= 3)}
          />
        </div>

        <div className="flex lg:hidden flex-row justify-between">
            <div className="flex flex-col">
              <div className="text-[#848FAC] text-12 font-medium">Price</div>
              <div className="text-[#131118] text-20 font-bold">$80.00</div>
            </div>
          
          <a className="bg-[#131118] text-white text-16 font-semibold rounded-lg flex justify-between items-center px-10">Add to Cart</a>
        </div>
      </div>
    </>
  );
};

export default ProductDetailsScreen;
