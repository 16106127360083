import React from "react";
import HubBlog from "../../../assets/svgs/hub_blog.svg";
import HubFaq from "../../../assets/svgs/hub_faq.svg";
import "./index.css";
import { Seo } from "../../../components/Seo";
import GetStartedIcon from "../../../assets/svgs/resources/getstarted.svg";
import TutorialsIcon from "../../../assets/svgs/resources/tutorials.svg";
import PracticeIcon from "../../../assets/svgs/resources/practice.svg";
import GuidesIcon from "../../../assets/svgs/resources/guides.svg";
import { t } from "i18next";

const tabText = (label) => t(`resources.tabs.${label}`);
const resourceText = (label) => t(`resources.component.${label}`);

const features = [
  {
    title: tabText("Case"),
    desc: tabText("CaseDescription"),
    link: "/resources/success-stories",
    linkname: tabText("Read"),
  },
  {
    title: tabText("Product"),
    desc: tabText("ProductDescription"),
    link: "/product-updates",
    linkname: tabText("View"),
  },
  {
    title: tabText("Integrations"),
    desc: tabText("IntegrationsDescription"),
    link: "/integrations",
    linkname: tabText("Discover"),
  },
];

const resources = [
  {
    title: tabText("GetStarted"),
    icon: GetStartedIcon,
    desc: tabText("GetStartedDesc"),
    link: "/resources/getting-started",
    linkname: tabText("ExploreGuides"),
  },
  {
    title: tabText("FeatureTutorials"),
    icon: TutorialsIcon,
    desc: tabText("FeatureTutorialsDesc"),
    link: "/resources/feature-tutorials",
    linkname: tabText("ExploreTutorials"),
  },
  {
    title: tabText("BestPractices"),
    icon: PracticeIcon,
    desc: tabText("BestPracticesDesc"),
    link: "/resources/best-practices",
    linkname: tabText("CheckBestPractices"),
  },
  {
    title: tabText("GuidesTemplates"),
    icon: GuidesIcon,
    desc: tabText("GuidesTemplatesDesc"),
    link: "/resources/guides-and-templates",
    linkname: tabText("ExploreArticles"),
  },
  {
    title: tabText("Blog"),
    icon: HubBlog,
    desc: tabText("BlogDesc"),
    link: "/blog",
    linkname: tabText("ExploreBlog"),
  },
  {
    title: tabText("FAQs"),
    icon: HubFaq,
    desc: tabText("FAQsDesc"),
    link: "/resources/faqs",
    linkname: tabText("ExploreFAQs"),
  },
];

const CustomerHub = () => {
  const currentUrl = "https://venueboost.io/resources";
  const seoTitle = "VenueBoost Resources | Stay Connected with Success";
  const seoDescription =
    'Discover the VenueBoost Resources and stay connected with your success. Access resources, insights, and support to optimize your VenueBoost experience and drive your business forward."';

  return (
    <div className="flex flex-col w-full customerhub">
      <Seo
        title={seoTitle + " | VenueBoost"}
        description={seoDescription}
        url={currentUrl}
      />
      <div className="w-full max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 ">
        <div className="flex flex-col ">
          <h1 className="title text-[32px] md:text-48 leading-[60px] font-extrabold text-center mt-10 mb-1 md:mt-20 md:mb-3">
            {resourceText("ResourceCenter")}
          </h1>
          <p className="text-[#666A73] text-14 md:text-20 leading-5 md:leading-7 font-medium text-center ">
            {resourceText("YourGateway")}
          </p>
        </div>
      </div>
      <div className="w-full py-6 mt-8 md:mt-20 md:py-10 bg-[#F4F4F4]">
        <div className="w-full max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 flex flex-col">
          <h1 className="text-[#373941] text-[24px] leading-[32px] md:text-[32px] md:leading-[44px] font-semibold ">
            {resourceText("Resources")}
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-y-[17px] md:gap-y-8 gap-x-[23px] md:gap-x-[79px] mt-4 mb-14">
            {resources.map((res, index) => (
              <div
                key={index}
                className="bg-white flex flex-col border-[#E0E0E0] rounded-[13px] border-[1px] p-8"
              >
                <img src={res.icon} alt="hubicon1" className="h-8 w-8 mb-5" />
                <p className="text-[#2D2E2E] font-bold text-22 leading-8 mb-2 ">
                  {res.title}
                </p>
                <p className="flex-1 text-[#2D2E2E] text-18 leading-6 font-normal w-full ">
                  {res.desc}
                </p>
                <a
                  className="text-[#240B3B] font-normal text-[17px] mt-5"
                  href={res.link}
                >
                  {res.linkname}
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="w-full max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 ">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-[23px] md:gap-[79px] mt-8 md:mt-20 mb-8 md:mb-14">
          {features.map((feature, index) => (
            <div
              key={index}
              className="feature-item flex flex-col bg-white  border-[#E0E0E0] rounded-[13px] p-[16px]
                shadow-[rgba(50,50,93,0.25)_0px_6px_12px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px]
                "
            >
              <p className="text-[#240B3B] font-bold text-20 md:text-[19px] leading-7 mb-3 ">
                {feature.title}
              </p>
              <p className="flex-1 text-[#535968]  text-14 md:text-16 leading-4 md:leading-6 font-normal">
                {feature.desc}
              </p>
              <a
                className="text-[#240B3B] font-normal text-14 md:text-16 mt-3"
                href={feature.link}
              >
                {feature.linkname}
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CustomerHub;
