import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "../../utils/common";
import { getStorageKey, KEYS } from "../../utils/storage";
import { useLocation, useNavigate } from "react-router-dom";
import {
  FavoriteIcon,
  X,
  barDown,
  searchIcon,
  searchLogo,
  shopCart,
} from "../../views/venue-retail/icons";
import { SET_SEARCH } from "../../redux/actions/types";
import { changeBybestLanguage, changeLanguage } from "../../redux/actions/app";
import { getAccommodationText } from "../../views/venue-accommodation";

const VenueBrandHeader = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const language = useSelector((state) => state.app.language);
  const bybestLanguage = useSelector((state) => state.app.bybestLanguage);

  const venueDataLS = localStorage.getItem("venueData");
  const venueSelector = useSelector((state) => state.venue);
  const searchSelector = useSelector((state) => state.search);

  const dataKey = localStorage.getItem("venue_key1");

  const venueData =
    Object.keys(venueSelector).length > 0
      ? venueSelector
      : JSON.parse(venueDataLS);

  const { products, venueLogo } = useSelector((state) => state.cart);

  const [isNavOpen, setIsNavOpen] = useState(false);
  const [currentWLRoute, setCurrentWLRoute] = useState("#");
  const [isOpen, setIsOpen] = useState(false);
  const [results, setResults] = useState([]);

  const pathParts = pathname.split("/");
  const retailIndex = pathParts.indexOf("retail");
  const venueKey =
    retailIndex !== -1 && pathParts.length > retailIndex + 1
      ? pathParts[retailIndex + 1]
      : null;

  const goToCart = () => {
    let hospitalRoomKey = getStorageKey(KEYS.HOSPITAL_ROOM_KEY);
    if (hospitalRoomKey) {
      let route = `/food-checkout`;
      navigate(route);
    }
    if (venueKey) {
      let route = `/venue/retail/${venueKey}/cart`;
      navigate(route);
    }
  };

  const isRoute = (path = "contact") => pathname.includes(path);

  const redirectTo = (url = "shop") => {
    if (pathname.includes("/rental/") || pathname.includes("/booking")) {
      const id = dataKey ?? "";

      return `/venue/accommodation/${id}/${url}`;
    } else {
      const newSlug = pathname.replace(
        /\/(?:contact|shop|about|wishlist)/g,
        ""
      );
      const cleanUrl = newSlug.endsWith("/") ? newSlug.slice(0, -1) : newSlug;
      return `${cleanUrl}/${url}`;
    }
  };

  useEffect(() => {
    if (pathname.includes("/rental/") || pathname.includes("/booking")) {
      const id = dataKey ?? "";

      setCurrentWLRoute(`/venue/accommodation/${id}}`);
    }
    // build the whitelabel route
    else {
      const currentWhiteLabelRoute =
        pathname.split("/")[0] +
        "/" +
        pathname.split("/")[1] +
        "/" +
        pathname.split("/")[2] +
        "/" +
        pathname.split("/")[3];
      setCurrentWLRoute(currentWhiteLabelRoute);
    }
  }, [pathname, dataKey]);

  const isRetail = useMemo(() => {
    if (pathname.startsWith("/venue/retail")) {
      return true;
    }
    return false;
  }, [pathname]);

  const isRestaurant = useMemo(() => {
    if (pathname.startsWith("/venue/restaurant")) {
      return true;
    }
    return false;
  }, [pathname]);

  const shoppingCart = (
    <div onClick={() => goToCart()} className="relative cursor-pointer pt-1">
      <span>{shopCart}</span>
      <div
        style={{
          position: "absolute",
          color: "white",
          borderRadius: "50%",
          fontSize: "12px",
          textAlign: "center",
        }}
        className="shop-cart-circle"
      >
        {products?.length ?? 0}
      </div>
    </div>
  );

  const onInputChange = (e) => {
    const value = e.target.value;
    if (value.length > 0) {
      setResults([
        "Swarovski Watch",
        "Swarovski Jewelry",
        "Swarovski Matrix Braclet",
        "Swarovski Necklace",
        "Swarovski Luna Rose",
      ]);
    } else {
      setResults([]);
    }
  };

  const allResults = (results) => {
    return results.map((item) => {
      return (
        <div className="search-record  flex gap-2 justify-between items-center w-full">
          <div
            className="flex items-center gap-2"
            onClick={() => {
              setIsOpen(false);
              dispatch({
                type: SET_SEARCH,
                payload: item,
              });
            }}
          >
            <span>{searchLogo(true)}</span>
            <span className="flex items-center gap-2">{item}</span>
          </div>
          <span
            onClick={() => setResults(results.filter((res) => res !== item))}
          >
            {X}
          </span>
        </div>
      );
    });
  };

  const isRetailHome =
    pathname === `/venue/retail/${venueKey}` ||
    pathname === `/venue/retail/${venueKey}/`;

  const isRestaurantView = pathname.includes("/venue/restaurant");
  const isAccommodation =
    pathname.includes("/venue/accommodation") || pathname.includes("rental/");

  const isCustomer = pathname.includes("/customer/");
  const isBetaShop = window.hostname === "beta.bybest.shop" || isCustomer;

  const bybestStarts = isBetaShop || isAccommodation;

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    if (bybestStarts) {
      dispatch(changeBybestLanguage(newLanguage));
    } else {
      dispatch(changeLanguage(newLanguage));
    }
    window.location.reload();
  };

  const languageComponent = (
    <li>
      <div className="menu-header">
        <div className="left-side flex items-center bg-white justify-center w-full">
          <select
            className="choose-lang cursor-pointer border-none outline-none"
            value={bybestStarts ? bybestLanguage : language}
            onChange={handleLanguageChange}
          >
            <option value="al">AL</option>
            <option value="en">EN</option>
          </select>
        </div>
      </div>
    </li>
  );

  return (
    <header
      aria-label="Site Header"
      className={`bg-white sticky w-full z-[1000] ${
        isRetailHome ? "large-width" : "header"
      }`}
      style={{ boxShadow: "0px 1px 2px 0px #00000040" }}
    >
      {!isRetail && !isAccommodation && (
        <div className="hidden lg:block" style={{ backgroundColor: "#d9cde4" }}>
          <div className="flex h-8 items-center justify-end mx-auto max-w-screen-main px-4 sm:px-6 md:px-8 lg:px-16">
            <ul className="flex items-center gap-6 text-sm">
              {products && products.length > 0 && shoppingCart}
            </ul>
          </div>
        </div>
      )}

      <div className={`${isRetailHome ? "" : "px-4 lg:pl-[10%] lg:pr-[5%]"}`}>
        <div
          className={`${
            isRestaurantView ? "hidden" : "flex"
          } h-20 items-center justify-between`}
        >
          <div className="flex md:items-center md:gap-12">
            {!isEmpty(venueData?.overview?.logo) && (
              <a
                className="text-teal-600 flex items-center  gap-4 logo"
                href={currentWLRoute}
              >
                <img
                  alt=""
                  src={venueLogo ? venueLogo : venueData?.overview?.logo}
                  style={{ height: "56px" }}
                />
              </a>
            )}
            {!isEmpty(venueLogo) && isEmpty(venueData?.overview?.logo) && (
              <a
                className="text-teal-600 flex items-center  gap-4 logo"
                href={`${currentWLRoute}`}
              >
                <img alt="" src={venueLogo} style={{ height: "56px" }} />
              </a>
            )}
          </div>
          {!isRestaurant && (
            <div className="pr-[5%]">
              <button
                data-collapse-toggle="navbar-default"
                type="button"
                className="inline-flex items-center p-2 ml-3 text-sm text-primary1 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
                aria-controls="navbar-default"
                onClick={() => setIsNavOpen((prev) => !prev)}
              >
                <span className="sr-only" />
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
              <div className="nav flex-col lg:flex-row gap-12 w-full hidden lg:flex">
                <nav aria-label="Site Nav">
                  <ul className="flex flex-col items-center lg:flex-row text-sm w-full">
                    {isRetail && (
                      <li className="flex w-full items-center text-16 lg:h-20 align-middle nav-item-header font-medium text-[#666A73] transition gap-4 cursor-pointer px-4 hover:text-black1/75">
                        <a
                          href={redirectTo()}
                          className="text-16 h-0 font-medium align-middle nav-link transition hover:text-black1/75"
                        >
                          {getAccommodationText("shop")}
                        </a>
                      </li>
                    )}
                    <li className="flex w-full items-center text-16 lg:h-20 align-middle nav-item-header font-medium text-[#666A73] transition gap-4 cursor-pointer px-4 hover:text-black1/75">
                      <a
                        href={redirectTo("about")}
                        className="text-16 h-0 font-medium align-middle nav-link transition hover:text-black1/75"
                      >
                        {getAccommodationText("about")}
                      </a>
                    </li>

                    <li className="flex w-full items-center text-16 lg:h-20 align-middle nav-item-header font-medium text-[#666A73] transition gap-4 cursor-pointer px-4 hover:text-black1/75">
                      <a
                        href={redirectTo("contact")}
                        className={`text-16 h-0 font-medium align-middle ${
                          isRoute() ? "text-[#8b1dbf]" : ""
                        } nav-link transition hover:text-black1/75`}
                      >
                        {getAccommodationText("contact")}
                      </a>
                    </li>
                    {languageComponent}
                    {isRetail && (
                      <>
                        <li className="flex w-full items-center text-16 lg:h-20 align-middle nav-item-header font-medium text-[#666A73] transition gap-4 cursor-pointer px-4 hover:text-black1/75">
                          <a href={redirectTo("wishlist")}>{FavoriteIcon()}</a>
                        </li>
                        <li className="flex w-full items-center text-16 lg:h-20 align-middle nav-item-header font-medium text-[#666A73] transition gap-4 cursor-pointer px-4 hover:text-black1/75">
                          <div
                            className="flex flex-col bg-white gap-0 relative rounded-lg"
                            onFocus={() => setIsOpen(true)}
                          >
                            {!isOpen ? (
                              <span onClick={() => setIsOpen(!isOpen)}>
                                {searchIcon}
                              </span>
                            ) : (
                              <div className="input-search relative bg-white">
                                <div className="flex items-center">
                                  <input
                                    defaultValue={searchSelector}
                                    type="search"
                                    className="pl-5"
                                    placeholder="search..."
                                    onChange={onInputChange}
                                  />
                                  <span onClick={() => setIsOpen(false)}>
                                    {barDown}
                                  </span>
                                </div>
                                <span className="absolute left-5">
                                  {searchLogo()}
                                </span>
                                {results.length > 0 && (
                                  <div className="search-result justify-between w-full">
                                    {allResults(results)}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </li>
                      </>
                    )}

                    {isRetail && products && products.length > 0 && (
                      <li className="flex w-full items-center text-16 lg:h-20 align-middle nav-item-header font-medium text-[#666A73] transition gap-4 cursor-pointer px-4 hover:text-black1/75">
                        <span className="text-16 h-0 font-medium align-middle nav-link transition hover:text-black1/75">
                          {shoppingCart}
                        </span>
                      </li>
                    )}
                  </ul>
                </nav>
              </div>
            </div>
          )}
          {isNavOpen && !isRestaurant && (
            <div className="flex flex-col gap-12 w-full header-menu-mobile p-0 m-0 bg-gray-100">
              <nav aria-label="Site Nav" className=" mt-8">
                <ul className="flex flex-col gap-8 text-sm">
                  {isRetail && (
                    <li>
                      <a
                        href={redirectTo("shop")}
                        className={`text-16 h-0 font-medium align-middle ${
                          isRoute("shop") ? "text-[#8b1dbf]" : ""
                        } nav-link transition hover:text-black1/75`}
                      >
                        {getAccommodationText("shop")}
                      </a>
                    </li>
                  )}

                  <li>
                    <a
                      className={`text-16 h-0 font-medium align-middle ${
                        isRoute("about") ? "text-[#8b1dbf]" : ""
                      } nav-link transition hover:text-black1/75`}
                      href={redirectTo("about")}
                    >
                      {getAccommodationText("about")}
                    </a>
                  </li>
                  <li>
                    <a
                      href={redirectTo("contact")}
                      className={`text-16 h-0 font-medium align-middle ${
                        isRoute() ? "text-[#8b1dbf]" : ""
                      } nav-link transition hover:text-black1/75`}
                    >
                      {getAccommodationText("contact")}
                    </a>
                  </li>
                  {languageComponent}

                  {isRetail && (
                    <li>
                      <div
                        className={`text-16 h-0 font-medium align-middle ${
                          isRoute("wishlist") ? "text-[#8b1dbf]" : ""
                        } nav-link transition hover:text-black1/75`}
                      >
                        Wishlist
                      </div>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default VenueBrandHeader;
