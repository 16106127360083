import React from "react";
import { Button } from "@mui/material";
import "../index.css";
import { useNavigate, useParams } from "react-router-dom";
import { beachBarOrderText } from "..";
import { drinkProducts } from "../data";

const SendOrderBtn = ({ setIsDetailsOpen, setProducts, products = [] }) => {
  const navigate = useNavigate();
  const { app_key, umbrellaNo } = useParams();

  const openInnerDrawer = () => {
    setProducts(drinkProducts);
    setIsDetailsOpen(false);
    navigate(`/beach-bar-order/${app_key}/umbrella/${umbrellaNo}/success`, {
      state: products,
    });
  };

  return (
    <>
      <Button
        onClick={openInnerDrawer}
        className="self-stretch h-[54px]"
        disableElevation
        variant="contained"
        sx={{
          textTransform: "none",
          color: "#fff",
          fontSize: "16",
          background: "#17ce8c",
          borderRadius: "16px",
          "&:hover": { background: "#17ce8c" },
          height: 54,
        }}
      >
        <span className="send-order-text">
          {beachBarOrderText("sendOrder")}
        </span>
      </Button>
    </>
  );
};

export default SendOrderBtn;
