import React from "react";
// import FooterImg from "../../../assets/images/sitemap.png";
import StoryCard from "./StoryCard";
import SuccessStoryImg1 from "../../../assets/images/stories/nohu_rooftop_restaurant_story.webp";
import SuccessStoryImg2 from "../../../assets/images/stories/skinners_loft_restaurant_story.webp";
import SuccessStoryImg3 from "../../../assets/images/stories/maplewood_golf_course_story.webp";
import SuccessStoryImg4 from "../../../assets/images/stories/mgm_hotel_story.webp";
import SuccessStoryImgDetails1 from "../../../assets/images/stories/nohu_rooftop_story_1.webp";
import SuccessStoryImgDetails2 from "../../../assets/images/stories/nohu_rooftop_story_2.webp";
import SuccessStoryImgDetailsMGM2 from "../../../assets/images/stories/mgm_resort_story_1.webp";
import SuccessStoryImgDetailsMGM1 from "../../../assets/images/stories/mgm_resort_story_2.webp";
import SuccessStoryImgDetailsSL1 from "../../../assets/images/stories/skinloft_story_1.webp";
import SuccessStoryImgDetailsSL2 from "../../../assets/images/stories/skinloft_story_2.webp";
import SuccessStoryImgDetailsMW1 from "../../../assets/images/stories/mapleewood_story_1.webp";
import SuccessStoryImgDetailsMW2 from "../../../assets/images/stories/mapleewood_story_2.webp";
import AvatarIcon from "../../../assets/images/stories/avatar.webp";
import {Seo} from "../../../components/Seo";
import FreePlanCard from "../../../components/FreePlanCard";

const detailsSlug = window.location.pathname;
const successStories = [
  {
    id: 1,
    image: SuccessStoryImg1,
    category: "Restaurant Success Story",
    title: "NOHU Rooftop Restaurant",
    title1: "Transforming Restaurant Operations:",
    title2: "NOHU Rooftop Restaurant Success Story",
    desc: "Discover How VenueBoost Revolutionized NOHU Rooftop Restaurant Operations, elevating their business to new heights",
    date: "June 6, 2023",
    giver_name: "Erik P.",
    giver_role: "RESTAURANT OWNER",
    detail_image: SuccessStoryImgDetails2,
    detail_image_1: SuccessStoryImgDetails1,
    slug: "transforming-restaurant-operations-nohu-rooftop-restaurant-success-story",
    slug_related:
      "/resources/success-stories/transforming-restaurant-operations-nohu-rooftop-restaurant-success-story",
    introduction:
      "In the fast-paced and competitive restaurant industry, staying ahead requires innovative solutions and reliable partners. Join us as we delve into the success story of Erik, the owner of NOHU Rooftop Restaurant, and how VenueBoost has transformed their operations, elevating their business to new heights",
    section_1_title: "Understanding the Challenges",
    section_1_content:
      "As a restaurant owner, Erik faced numerous challenges in managing his establishment. Keeping up with industry trends, optimizing operations, and maintaining the delicate balance between customer satisfaction and efficiency were tasks that demanded constant attention and effort.",
    section_1_point_1_title: "Discovering VenueBoost",
    section_1_point_1_content:
      "In the midst of these challenges, Erik came across VenueBoost and found a game-changing solution. VenueBoost's innovative software, specifically designed for the hospitality industry, resonated with Erik. The platform's understanding of the unique needs of restaurants and its commitment to providing reliable support were key factors that sparked Erik's interest.",
    section_1_point_2_title: "The Game-Changing Impact",
    section_1_point_2_content:
      "Once Erik implemented VenueBoost at NOHU Rooftop Restaurant, the impact was undeniable. The software's intuitive interface and comprehensive features empowered Erik and his team to streamline their operations, optimize processes, and make informed decisions. With VenueBoost, they could focus more on what they do best - serving delightful experiences to their customers.",
    section_1_point_3_title: "Partnership and Support",
    section_1_point_3_content:
      "What sets VenueBoost apart is the strong partnership and support it offers its customers. Erik experienced firsthand the dedication and commitment of the VenueBoost team. From the initial onboarding process to ongoing assistance, VenueBoost went above and beyond to ensure Erik's success. Their prompt responses, personalized guidance, and willingness to understand NOHU Rooftop Restaurant's unique requirements have been instrumental in Erik's satisfaction as a customer.",
    section_1_point_4_title: "Conclusion",
    section_1_point_4_content:
      "Erik's success story showcases the transformative power of VenueBoost in the hospitality industry. With VenueBoost, restaurant owners like Erik can revolutionize their operations, enhance customer experiences, and stay ahead of the competition. If you're looking to take your restaurant to new heights, it's time to explore VenueBoost and unlock its potential for your business.",
  },
  {
    id: 2,
    image: SuccessStoryImg2,
    category: "Restaurant Success Story",
    title: "Unlocking the Potential of Hospitality",
    title1: "Unlocking the Potential of Hospitality:",
    title2: "Skinner's Loft Success Story",
    desc: "How Skinner's Loft collaboration with VenueBoost has revolutionized their operations, making them a trailblazer in the hospitality industry",
    date: "June 2, 2023",
    giver_name: "Alex R.",
    detail_image: SuccessStoryImgDetailsSL1,
    detail_image_1: SuccessStoryImgDetailsSL2,
    giver_role: "RESTAURANT MANAGER",
    slug: "unlocking-hospitality-potential-skinners-loft-success-story",
    slug_related:
      "/resources/success-stories/unlocking-hospitality-potential-skinners-loft-success-story",
    introduction:
      "In the dynamic and competitive world of hospitality, finding the right tools and partners is crucial for success. Join us as we explore the success story of Alex, the Restaurant Manager at Skinner's Loft, and how their collaboration with VenueBoost has revolutionized their operations, making them a trailblazer in the hospitality industry.",
    section_1_title: "Embracing Professionalism and Dedication",
    section_1_content:
      "As a Restaurant Manager, Alex has had experience with various SaaS providers throughout the years. However, none have matched the level of professionalism and dedication exhibited by VenueBoost. From the very beginning, it was evident that VenueBoost was committed to helping Skinner's Loft excel in their endeavors.",
    section_1_point_1_title: "Transforming Restaurant Operations",
    section_1_point_1_content:
      "With VenueBoost, Skinner's Loft experienced a remarkable transformation in their restaurant operations. The comprehensive suite of tools and features provided by VenueBoost empowered Alex and his team to enhance their efficiency and connectivity with customers. The software acted as a catalyst, propelling Skinner's Loft to new heights of success.",
    section_1_point_2_title: "Staying on the Cutting Edge",
    section_1_point_2_content:
      "One of the standout features of VenueBoost is its dedication to staying on the cutting edge of the hospitality industry. By leveraging VenueBoost, Skinner's Loft gained access to innovative technologies and forward-thinking solutions. This not only kept them ahead of the competition but also allowed them to deliver exceptional experiences to their customers.\n" +
      "\n",
    section_1_point_3_title: "A Partnership Built on Trust",
    section_1_point_3_content:
      "What truly sets VenueBoost apart is the deep sense of partnership and trust it fosters with its customers. Alex experienced firsthand the unwavering support and guidance provided by the VenueBoost team. From seamless onboarding to ongoing assistance, VenueBoost ensured that Skinner's Loft received personalized attention and solutions tailored to their unique requirements.",
    section_1_point_4_title: "Conclusion",
    section_1_point_4_content:
      "The success story of Alex and Skinner's Loft is a testament to the transformative power of VenueBoost in the hospitality industry. Through their collaboration with VenueBoost, Skinner's Loft has become a beacon of excellence, redefining the standards of restaurant operations. If you're seeking to unlock the true potential of your hospitality business, it's time to embark on a journey with VenueBoost and experience the remarkable results for yourself.",
  },
  {
    id: 3,
    image: SuccessStoryImg3,
    category: "Golf Course Story",
    title: "Elevating Golf Experiences",
    title1: "Elevating Golf Experiences:",
    title2: "Maplewood Country Club Success Story",
    desc: "Discover how VenueBoost revolutionized their golf operations, enabling them to deliver exceptional experiences and solidify their position as one of the premier golf courses in the U.S.",
    date: "June 11, 2023",
    giver_name: "Cameron S.",
    giver_role: "COURSE SUPERINTENDENT",
    slug: "elevating-golf-experiences-maplewood-country-club-success-story",
    detail_image: SuccessStoryImgDetailsMW2,
    detail_image_1: SuccessStoryImgDetailsMW1,
    slug_related:
      "/resources/success-stories/elevating-golf-experiences-maplewood-country-club-success-story",
    introduction:
      "Join us on a journey of excellence as we dive into the success story of Cameron, the Course Superintendent at Maplewood Country Club, and their partnership with VenueBoost. Discover how VenueBoost revolutionized their golf operations, enabling them to deliver exceptional experiences and solidify their position as one of the premier golf courses in the U.S.",
    section_1_title: "Delivering Top-Notch Experiences",
    section_1_content:
      "Maplewood Country Club takes pride in providing unforgettable experiences to their clients. With VenueBoost as their trusted ally, Cameron and his team witnessed a significant elevation in the quality of their services. The user-friendly software streamlined their operations and enabled them to offer highly personalized and tailored experiences to their valued customers.\n" +
      "\n",
    section_1_point_1_title: "Empowering Efficiency and Personalization",
    section_1_point_1_content:
      "VenueBoost's powerful and intuitive software proved to be a game-changer for Maplewood Country Club. By leveraging its features, Cameron and his team operated more efficiently, maximizing profits while simplifying operations across their properties. The comprehensive suite of tools provided by VenueBoost empowered them to enhance every aspect of their golf operations.\n" +
      "\n",
    section_1_point_2_title: "Maximizing Profits, Minimizing Effort",
    section_1_point_2_content:
      "One of the key advantages of VenueBoost is its ability to drive profitability while minimizing effort. By utilizing VenueBoost's innovative solutions, Maplewood Country Club experienced a significant boost in their revenue streams. The software's advanced features, such as intelligent booking management and data-driven insights, helped them make informed decisions and optimize their resources.",
    section_1_point_3_title: "A Trustworthy Partner",
    section_1_point_3_content:
      "Maplewood Country Club found in VenueBoost a reliable and trustworthy partner. From the initial implementation to ongoing support, VenueBoost's team demonstrated their commitment to ensuring the success of their partnership. Cameron experienced firsthand the dedicated assistance and guidance provided by VenueBoost, which further strengthened their confidence in the platform.",
    section_1_point_4_title: "Conclusion",
    section_1_point_4_content:
      "The success story of Cameron and Maplewood Country Club showcases the transformative impact of VenueBoost on golf operations. Through their collaboration, Maplewood Country Club achieved new heights of excellence, delivering unrivaled experiences to their clients. If you're seeking to elevate your golf operations and maximize profitability, VenueBoost is the trusted ally you need to unlock the full potential of your golf course.",
  },
  {
    id: 4,
    image: SuccessStoryImg4,
    category: "Hotels Story",
    title: "Simplifying Hotel Operations",
    title1: "Simplifying Hotel Operations:",
    title2: "MGM Resorts International Success Story",
    desc: "Discover how VenueBoost transformed their hospitality landscape, empowering them to streamline operations, deliver customized experiences, and create unforgettable dining events across their diverse range of service areas",
    date: "June 23, 2023",
    giver_name: "Sara M.",
    giver_role: "DIRECTOR OF FOOD & BEVERAGE DEPARTMENT",
    detail_image: SuccessStoryImgDetailsMGM2,
    detail_image_1: SuccessStoryImgDetailsMGM1,
    slug: "simplifying-hotel-operations-mgm-resorts-international-success-story",
    slug_related:
      "/resources/success-stories/simplifying-hotel-operations-mgm-resorts-international-success-story",
    introduction:
      "Embark on a journey of seamless hotel operations as we delve into the success story of Sara, the Director of the Food & Beverage Department at MGM Resorts International. Discover how VenueBoost transformed their hospitality landscape, empowering them to streamline operations, deliver customized experiences, and create unforgettable dining events across their diverse range of service areas.",
    section_1_title: "Streamlining Operations Across Multiple Outlets",
    section_1_content:
      "Managing a hotel with various service areas, including poolside cabanas, bars, clubs, and restaurants, comes with its own set of challenges. However, with VenueBoost as their trusted companion, Sara and her team experienced a newfound sense of manageability. The innovative software seamlessly integrated and streamlined operations across all these service areas, ensuring efficient and synchronized processes.\n",
    section_1_point_1_title:
      "Anticipating Guest Needs, Delivering Customized Experiences",
    section_1_point_1_content:
      "VenueBoost proved to be a game-changer for MGM Resorts International, allowing them to gain deep insights into their guests' preferences and needs. Armed with this knowledge, Sara and her team were able to anticipate guest expectations and create tailored experiences that left a lasting impression. The software's robust features enabled them to offer customized dining events and special experiences across their wide range of food and beverage outlets.",
    section_1_point_2_title:
      "Maximizing Efficiency, Enhancing Guest Satisfaction:",
    section_1_point_2_content:
      "VenueBoost's comprehensive suite of tools empowered and her team to maximize efficiency while enhancing guest satisfaction. By leveraging the software's advanced capabilities, they were able to optimize staffing, manage inventory effectively, and streamline order management processes. This resulted in smooth operations, reduced wait times, and ultimately, increased guest satisfaction.",
    section_1_point_3_title: "A Partner in Success",
    section_1_point_3_content:
      "MGM Resorts International found in VenueBoost a reliable and dedicated partner. Throughout their journey, VenueBoost's team provided exceptional support and guidance, ensuring a seamless implementation process and ongoing assistance. Sara and her team experienced firsthand the commitment of VenueBoost in their success, solidifying their trust in the platform.",
    section_1_point_4_title: "Conclusion",
    section_1_point_4_content:
      "Sara's success story at MGM Resorts International showcases the transformative power of VenueBoost in simplifying hotel operations and enhancing guest experiences. With VenueBoost as their ally, they achieved new levels of efficiency, personalization, and guest satisfaction across their diverse food and beverage outlets. If you're seeking to streamline your hotel operations and create unforgettable experiences for your guests, VenueBoost is the solution you need to unlock your hotel's true potential.",
  },
];

const currentSuccessStory = successStories.find(
  bd => bd.slug_related === detailsSlug
);

const SuccessStory = () => {
  const currentUrl = "https://venueboost.io" + currentSuccessStory.slug_related;
  return (
    <div className='align-col-middle  '>
      <Seo
        title={currentSuccessStory.title + " - Success Story " + "| VenueBoost"}
        description={currentSuccessStory.introduction}
        image={currentSuccessStory.detail_image}
        url={currentUrl}
      />
      {/* <div className="w-[100vw]   min-h-[80px] md:min-h-[393px] ">
        <img
          src={currentSuccessStory.image}
          alt="img"
          className="min-h-[277px] md:h-[500px]  w-[100vw] object-cover "
        />
      </div> */}
      <div className='w-[100vw] '>
        <div className='max-w-screen-main mx-auto px-6 md:px-8 lg:px-16'>
          <div className='flex flex-col  relative mb-0 '>
            <div className='bg-white absolute w-full h-[258px] scale-x-105 origin-bottom-right rounded-t-[13px]'></div>
            <div className='pt-11 z-10 flex flex-col gap-6 '>
              {/* <a href="/resources/success-stories" className="text-primary1 text-[17px] font-medium leading-5">
                {"< All Success Stories"}
              </a> */}

              <div class='flex items-center justify-center md:justify-start md:items-start '>
                <div class='h-[34px] w-[180px] rounded-md bg-gradient-to-r from-[#240B3B] via-[#3D1E59] to-[#BC14FE] p-[1px]'>
                  <div class='flex h-full w-full items-center justify-center bg-[#F9F9F9] rounded-md'>
                    <h1 class='text-xl font-normal text-[#454545]'>
                      Case studies
                    </h1>
                  </div>
                </div>
              </div>

              <div className='flex flex-col'>
                <h1 className=' text-[32px] md:text-[60px] text-center font-bold leading-9 md:leading-[78px] md:text-start md:pr-10 text-transparent bg-clip-text bg-gradient-to-r from-[#240B3B] via-[#5F1B85] to-[#BC14FE]'>
                  {currentSuccessStory.title1}
                </h1>
                <h1 className=' text-[32px] md:text-[60px] text-center font-bold leading-9 md:leading-[78px] md:text-start md:pr-10 '>
                  {currentSuccessStory.title2}
                </h1>
              </div>
              {/* <div className="mt-6 md:mb-12">
                <p className="text-[#222222] text-[17px] font-medium leading-5 md:inline mr-3">
                  {currentSuccessStory.giver_name}
                </p>
                <p className="text-[#333333] text-[16px] font-normal leading-5 md:inline mr-3">
                  {currentSuccessStory.giver_role}
                </p>
              </div> */}
              <div className='flex flex-col md:flex-row gap-6 md:gap-16 items-center'>
                <img
                  src={currentSuccessStory.image}
                  className='w-[315px] h-[213px] md:w-[473px] md:h-[350px] rounded-[10px]'
                />
                <div className='flex flex-col gap-5 justify-center items-start w-full'>
                  <div className='text-[24px] md:text-[48px] font-bold'>
                    {currentSuccessStory.title}
                  </div>
                  <div className='text-[18px] font-normal md:w-[650px]'>
                    {currentSuccessStory.introduction}
                  </div>
                  <div className='flex gap-5'>
                    <img src={AvatarIcon} className='w-[58px] h-[58px]' />
                    <div className='flex flex-col'>
                      <div className='text-[#151515] text-20 font-semibold'>
                        {currentSuccessStory.giver_name}
                      </div>
                      <div className='text-[#999999] text-14 font-medium'>
                        {currentSuccessStory.giver_role}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='flex flex-col gap-0'>
                <div>
                  <h3 className='text-18 md:text-[28px] leading-[34px] font-bold mt-10 my-2 text-black'>
                    {currentSuccessStory.section_1_title}
                  </h3>
                  <p className='text-18 leading-[34px]  text-[#666666] font-medium '>
                    {currentSuccessStory.section_1_content}
                  </p>
                </div>

                <div>
                  <h3 className='text-18 md:text-[28px] leading-[34px] font-bold mt-10 my-2 text-black'>
                    {currentSuccessStory.section_1_point_1_title}
                  </h3>
                  <p className='text-18 leading-[34px] text-[#666666] font-medium '>
                    {currentSuccessStory.section_1_point_1_content}
                  </p>
                </div>

                <img
                  src={currentSuccessStory.detail_image}
                  className='rounded-[13px] min-h-[277px] md:w-[790px] md:h-[500px]  mx-auto mt-10'
                />

                <div>
                  <h3 className='text-18 md:text-[28px] leading-[34px] font-bold mt-10 my-2 text-black'>
                    {currentSuccessStory.section_1_point_2_title}{" "}
                  </h3>
                  <p className='text-18 leading-[34px] text-[#666666]font-medium my-2'>
                    {currentSuccessStory.section_1_point_2_content}
                  </p>
                </div>

                <img
                  src={currentSuccessStory.detail_image_1}
                  className='rounded-[13px] min-h-[277px] md:w-[937px] md:h-[500px] mx-auto mt-10 '
                />

                <div>
                  <h3 className='text-18 md:text-[28px] leading-[34px] font-bold mt-10 my-2 text-black'>
                    {currentSuccessStory.section_1_point_3_title}{" "}
                  </h3>
                  <p className='text-18 leading-[34px]  text-[#666666] font-medium mt-0'>
                    {currentSuccessStory.section_1_point_3_content}
                  </p>
                </div>

                <div>
                  <h3 className='text-18 md:text-[28px] leading-[34px] font-bold mt-10 my-2 text-black'>
                    {currentSuccessStory.section_1_point_4_title}{" "}
                  </h3>
                  <p className='text-18 leading-[34px] text-[#666666] font-medium mt-0'>
                    {currentSuccessStory.section_1_point_4_content}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='mx-auto flex items-center justify-center my-16'>
        <FreePlanCard />
      </div>

      <div className='mb-8 md:mb-20 align-middle flex flex-col w-full'>
        <h1 className='text-20 md:text-40 font-semibold text-[#333333] leading-[44px] mb-8 md:mb-[60px]'>
          More stories
        </h1>
        <div className='grid grid-cols-1 md:grid-cols-3 gap-[38px] w-full place-items-center md:place-items-start'>
          {currentSuccessStory.id === 1 && (
            <>
              <StoryCard
                img={successStories[1].image}
                tag={successStories[1].category}
                title={successStories[1].title1 + successStories[1].title2}
                date={successStories[1].date}
                slug={successStories[1].slug}
              />
              <StoryCard
                img={successStories[2].image}
                tag={successStories[2].category}
                title={successStories[2].title1 + successStories[2].title2}
                date={successStories[2].date}
                slug={successStories[2].slug}
              />
              <StoryCard
                img={successStories[3].image}
                tag={successStories[3].category}
                title={successStories[3].title1 + successStories[3].title2}
                date={successStories[3].date}
                slug={successStories[3].slug}
              />
            </>
          )}

          {currentSuccessStory.id === 2 && (
            <>
              <StoryCard
                img={successStories[0].image}
                tag={successStories[0].category}
                title={successStories[0].title1 + successStories[0].title2}
                date={successStories[0].date}
                slug={successStories[0].slug}
              />
              <StoryCard
                img={successStories[2].image}
                tag={successStories[2].category}
                title={successStories[2].title1 + successStories[2].title2}
                date={successStories[2].date}
                slug={successStories[2].slug}
              />
              <StoryCard
                img={successStories[3].image}
                tag={successStories[3].category}
                title={successStories[3].title1 + successStories[3].title2}
                date={successStories[3].date}
                slug={successStories[3].slug}
              />
            </>
          )}

          {currentSuccessStory.id === 3 && (
            <>
              <StoryCard
                img={successStories[0].image}
                tag={successStories[0].category}
                title={successStories[0].title1 + successStories[0].title2}
                date={successStories[0].date}
                slug={successStories[0].slug}
              />
              <StoryCard
                img={successStories[1].image}
                tag={successStories[1].category}
                title={successStories[1].title1 + successStories[1].title2}
                date={successStories[1].date}
                slug={successStories[1].slug}
              />
              <StoryCard
                img={successStories[3].image}
                tag={successStories[3].category}
                title={successStories[3].title1 + successStories[3].title2}
                date={successStories[3].date}
                slug={successStories[3].slug}
              />
            </>
          )}

          {currentSuccessStory.id === 4 && (
            <>
              <StoryCard
                img={successStories[0].image}
                tag={successStories[0].category}
                title={successStories[0].title1 + successStories[0].title2}
                date={successStories[0].date}
                slug={successStories[0].slug}
              />
              <StoryCard
                img={successStories[1].image}
                tag={successStories[1].category}
                title={successStories[1].title1 + successStories[1].title2}
                date={successStories[1].date}
                slug={successStories[1].slug}
              />
              <StoryCard
                img={successStories[2].image}
                tag={successStories[2].category}
                title={successStories[2].title1 + successStories[2].title2}
                date={successStories[2].date}
                slug={successStories[2].slug}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SuccessStory;
