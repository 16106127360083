import React, { useState, useEffect } from "react";
import { Box, Badge } from "@mui/material";

const SlideDots = ({ instanceRef, currentSlide }) => {
  const [start, setStart] = useState(0);
  const dotsToShow = 5;

  useEffect(() => {
    if (instanceRef.current) {
      const totalDots = instanceRef.current.track.details.slides.length;
      let newStart = currentSlide - Math.floor(dotsToShow / 2);

      if (newStart < 0) {
        newStart = 0;
      } else if (newStart > totalDots - dotsToShow) {
        newStart = totalDots - dotsToShow;
      }

      setStart(newStart);
    }
  }, [currentSlide, instanceRef, dotsToShow]);

  return (
    <Box className="swiper-dots bg-gray-500 mt-6">
      {Array.from({ length: dotsToShow }, (_, idx) => {
        const dotIndex = start + idx;
        return (
          <Badge
            key={dotIndex}
            variant="dot"
            component="div"
            className={currentSlide === dotIndex ? "active" : ""}
            onClick={() => instanceRef.current?.moveToIdx(dotIndex)}
          />
        );
      })}
    </Box>
  );
};

export default SlideDots;
