import React, {useState} from "react";
import "./index.css";
import {Seo} from "../../components/Seo";
import FormTextInput from "../../components/FormTextInput";
import {NotificationManager} from "react-notifications";
import {CircularProgress} from "@mui/material";
import {requestPrivacyRightsForm} from "../../redux/actions/marketing";

const PrivacyRightRequestForm = () => {
  const [data, setData] = useState({});

  const [errorRequestContactName, setErrorRequestContactName] = useState("");
  const [errorRequestContactEmail, setErrorRequestContactEmail] = useState("");
  const [errorRequestContactPhone, setErrorRequestContactPhone] = useState("");
  const [errorRequestContactOption, setErrorRequestContactOption] =
    useState("");

  const [submitting, setSubmitting] = useState(false);

  const customRadioStyle = {
    width: "20px", // Adjust the width of the radio button
    height: "20px", // Adjust the height of the radio button
    cursor: "pointer",
    verticalAlign: "text-bottom",
  };

  const customCheckedRadioStyle = {};

  const handleChange = e => {
    const {name, value} = e.target;

    // Update the data state as usual
    setData(data => ({
      ...data,
      [name]: value,
    }));
  };

  const onSendRequestForm = () => {
    if (!data?.privacy_request) {
      setErrorRequestContactOption("Please select an option");
      return;
    }

    if (!data?.request_contact_name) {
      setErrorRequestContactName("Please enter your name");
      return;
    }

    if (!data?.request_contact_email) {
      setErrorRequestContactEmail("Please enter your email");
      return;
    }

    if (!data?.request_contact_phone) {
      setErrorRequestContactPhone("Please enter your phone number");
      return;
    }

    // Email validation
    if (
      !data.request_contact_email ||
      !/\S+@\S+\.\S+/.test(data.request_contact_email)
    ) {
      setErrorRequestContactEmail("Please enter a valid email address");
      return;
    }

    // Create a copy of data for submission
    let submissionData = {
      ...data,
    };

    setSubmitting(true);
    requestPrivacyRightsForm(submissionData)
      .then(data => {
        setSubmitting(false);
        NotificationManager.success(
          data?.message ||
            "Your privacy request has been successfully submitted.",
          "Success",
          5000
        );
      })
      .catch(error => {
        setSubmitting(false);
        NotificationManager.error(
          error?.message ||
            "Oops! Something went wrong. Please try again or contact us directly at contact@venueboost.io.",
          "Error",
          3000
        );
        throw new Error("An error occurred while submitting the form.");
      });
  };

  return (
    <div className={"align-col-middle "}>
      <Seo title={"Privacy Rights Request Form | VenueBoost"} />
      <div className=' w-[100vw] getstarted-bg'>
        <div className='mx-auto max-w-screen-main px-4 sm:px-6 md:px-8 lg:px-8 '>
          <div className='flex md:flex-row justify-center flex-col pt-4 pb-11 h-full'>
            <div className=' w-full lg:w-[70%] bg-[#F9F7F7] border-[1px] border-[#E0E0E0] rounded-[13px] p-4 md:p-8 py-6 gap-y-4 gap-x-8'>
              <div>
                <div className='  bg-white rounded-[31px] p-[24px] md:p-[33px] relative'>
                  <div className='flex justify-start  items-center'>
                    <span className='text-[26px] md:text-26 font-extrabold'>
                      Privacy Rights Request Form
                    </span>
                  </div>
                  <p className='text-18 font-semibold text-[#240b3b] my-4'>
                    At VenueBoost, we are committed to protecting your privacy
                    and ensuring that you have full control over your personal
                    information. Depending on your location, you may have
                    specific rights regarding the personal data we collect and
                    maintain. These rights may include:
                  </p>
                  <p className='text-17 font-normal mt-4 my-4'>
                    <ul style={{paddingLeft: "15px", listStyle: "circle"}}>
                      <li>
                        <b>Right to Know:</b> Understanding how we process your
                        personal information.
                      </li>
                      <li>
                        <b>Right to Access/Download:</b> Accessing or
                        downloading the specific pieces of personal information
                        we hold about you.
                      </li>
                      <li>
                        <b>Right to Deletion:</b> Requesting the deletion of
                        your personal information.
                      </li>
                      <li>
                        <b>Right to Correction:</b> Requesting correction of
                        inaccuracies in your personal information. You can also
                        update your personal information directly through your
                        VenueBoost account.
                      </li>
                      <li>
                        <b>Right to Opt-Out:</b> Opting out of the "selling" or
                        "sharing" of your personal information as defined under
                        applicable laws.
                      </li>
                    </ul>
                  </p>
                  <p className='text-17 font-normal mt-4 my-4'>
                    To submit a request, please complete the form below. Upon
                    submission, you will receive a confirmation email , which
                    you need to verify to process your request. We may need
                    additional information to confirm your identity before we
                    can fulfill your request.
                  </p>

                  <div className=''>
                    <div className={"mt-4 mb-6"}>
                      <b>Select an Option:</b>
                      {errorRequestContactOption && (
                        <div className='text-red-600 text-14'>
                          {errorRequestContactOption}
                        </div>
                      )}

                      {/* Radio Button 1 */}
                      <div style={{marginTop: "10px"}}>
                        <input
                          type='radio'
                          name='privacy_request'
                          value='know_personal_info'
                          checked={
                            data?.privacy_request === "know_personal_info"
                          }
                          onChange={handleChange}
                          style={{
                            ...customRadioStyle,
                            ...(data?.privacy_request === "know_personal_info"
                              ? customCheckedRadioStyle
                              : {}),
                          }}
                        />
                        <span style={{marginLeft: "8px", marginTop: "-2px"}}>
                          Request to know/learn about the personal information
                          we collect.
                        </span>
                      </div>

                      {/* Radio Button 2 */}
                      <div style={{marginTop: "10px"}}>
                        <input
                          type='radio'
                          name='privacy_request'
                          value='access_personal_info'
                          checked={
                            data?.privacy_request === "access_personal_info"
                          }
                          onChange={handleChange}
                          style={{
                            ...customRadioStyle,
                            ...(data?.privacy_request === "access_personal_info"
                              ? customCheckedRadioStyle
                              : {}),
                          }}
                        />
                        <span style={{marginLeft: "8px", marginTop: "-2px"}}>
                          Request to access/download my personal information.
                        </span>
                      </div>

                      {/* Radio Button 3 */}
                      <div style={{marginTop: "10px"}}>
                        <input
                          type='radio'
                          name='privacy_request'
                          value='delete_personal_info'
                          checked={
                            data?.privacy_request === "delete_personal_info"
                          }
                          onChange={handleChange}
                          style={{
                            ...customRadioStyle,
                            ...(data?.privacy_request === "delete_personal_info"
                              ? customCheckedRadioStyle
                              : {}),
                          }}
                        />
                        <span style={{marginLeft: "8px", marginTop: "-2px"}}>
                          Request to delete my personal information.
                        </span>
                      </div>

                      {/* Radio Button 4 */}
                      <div style={{marginTop: "10px"}}>
                        <input
                          type='radio'
                          name='privacy_request'
                          value='correct_personal_info'
                          checked={
                            data?.privacy_request === "correct_personal_info"
                          }
                          onChange={handleChange}
                          style={{
                            ...customRadioStyle,
                            ...(data?.privacy_request ===
                            "correct_personal_info"
                              ? customCheckedRadioStyle
                              : {}),
                          }}
                        />
                        <span style={{marginLeft: "8px", marginTop: "-2px"}}>
                          Request to correct inaccurate personal information.
                        </span>
                      </div>

                      {/* Radio Button 5 */}
                      <div style={{marginTop: "10px"}}>
                        <input
                          type='radio'
                          name='privacy_request'
                          value='opt_out_personal_info'
                          checked={
                            data?.privacy_request === "opt_out_personal_info"
                          }
                          onChange={handleChange}
                          style={{
                            ...customRadioStyle,
                            ...(data?.privacy_request ===
                            "opt_out_personal_info"
                              ? customCheckedRadioStyle
                              : {}),
                            width: "20px", // Set width
                            height: "20px", // Set height
                          }}
                        />
                        <span style={{marginLeft: "8px", marginTop: "-2px"}}>
                          Request to opt-out of the “sale” or “sharing” of my
                          personal information. (You can also opt-out by
                          clicking this link:
                          <a
                            style={{
                              color: "#607D8B",
                              textDecoration: "underline",
                              fontWeight: "bold",
                            }}
                            href='javascript: Cookiebot.renew()'
                          >
                            Do Not Sell or Share My Personal Information
                          </a>
                          )
                        </span>
                      </div>
                    </div>
                    <div>
                      <FormTextInput
                        name='request_contact_name'
                        placeholder='Name'
                        value={data?.request_contact_name || ""}
                        error={errorRequestContactName}
                        onChange={handleChange}
                        required={true}
                      />
                    </div>
                    <div>
                      <FormTextInput
                        className={"mt-6"}
                        name='request_contact_email'
                        type={"email"}
                        placeholder='Email'
                        error={errorRequestContactEmail}
                        value={data?.request_contact_email || ""}
                        onChange={handleChange}
                        required={true}
                      />
                    </div>
                    <div>
                      <FormTextInput
                        className={"mt-6"}
                        name='request_contact_phone'
                        placeholder='Phone number'
                        error={errorRequestContactPhone}
                        value={data?.request_contact_phone || ""}
                        onChange={handleChange}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className='w-full justify-end flex bottom-[58px] left-0'>
                    <button
                      disabled={submitting}
                      className='submit-button font-normal bg-primary1 mt-8  md:w-max h-auto py-2 md:py-4 md:px-6 text-[13px] md:text-18 rounded-[5px] text-white transition ease-in-out hover:scale-101 '
                      style={{
                        width: "100%",
                        background:
                          "linear-gradient(100.8deg, #240B3B 3.95%, #3D1E59 34%, #BC14FE 94.28%)",
                      }}
                      onClick={onSendRequestForm}
                    >
                      {submitting ? (
                        <CircularProgress size={16} />
                      ) : (
                        "SUBMIT REQUEST"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyRightRequestForm;
