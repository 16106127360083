import PropTypes from "prop-types";
import { useMemo } from "react";
import { beachBarOrderText } from "..";

const Button = ({
  className = "",
  setProducts,
  index = 0,
  products = [],
  selectedItem,
  setIsOpen,
  selectedProduct,
  quantity,
  currency = "",
  setPreviewProducts,
}) => {
  const value = quantity
    ? products[index]?.value
    : selectedProduct?.value ?? "1";

  const orderPrice = useMemo(() => {
    return Number(selectedItem?.price ?? 0) * (value ?? 1);
  }, [selectedItem, value]);

  const handleAddToOrder = () => {
    setProducts((prev) =>
      prev.map((obj) =>
        obj.id === selectedProduct?.id
          ? {
              ...obj,
              price: selectedItem?.price,
              value: products[index]?.value,
            }
          : obj
      )
    );
    setIsOpen(false);
  };

  return (
    <button
      onClick={handleAddToOrder}
      className={`bg-[#17CE8C] text-white cursor-pointer [border:none] py-4 px-5 bg-mediumseagreen items-center flex-1 rounded-2xl flex box-border gap-[4px] ${className}`}
    >
      <div className="relative text-base leading-[22px] font-semibold font-manrope text-bg text-center inline-block min-w-[94px]">
        {beachBarOrderText("addToOrder")}
      </div>
      <b className="relative text-base leading-[22px] flex gap-1 font-manrope text-bg">
        <span>{orderPrice ?? ""}</span> <span>{currency}</span>
      </b>
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  setProducts: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  products: PropTypes.array.isRequired,
  selectedItem: PropTypes.object.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default Button;
