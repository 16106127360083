import ProductImage from "./product-image";

const RetailProductCard = ({
  product,
  topIcon,
  onClickView,
  buttonLabel,
  onAddCard,
  showBottom,
  showDescription = true,
  cartProp,
}) => {
  return (
    <div className={`inline-block md:flex md:flex-col gap-y-5 cursor-pointer md:rounded-none w-[145px] ${ showDescription ? 'h-[333px]': 'h-[200px]' } w-full md:h-full md:mr-0 overflow-hidden`}>
      <ProductImage
        buttonLabel={buttonLabel}
        onAddCard={onAddCard}
        onClickView={onClickView}
        product={product}
        showBottom={showBottom}
        topIcon={topIcon}
        cartProp={cartProp}
      />

      {showDescription && (
        <div className="description-section mt-10 md:none">
          <span className="title whitespace-pre-wrap">{product.title}</span>
          <span className="description whitespace-pre-wrap">{product.description}</span>
          <div className="flex items-center gap-2">
            <span className="price">{product.price}</span>
            {product.previous_price && product.previous_price.length > 0 && (
              <span className="previous-price ">{product.previous_price}</span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default RetailProductCard;
