import React, { useState } from 'react';

import affiliateImg from '../../../assets/images/affiliate_main.png'
import affiliateImgNew from '../../../assets/images/affiliate-new-body.png'

export default function Benefits() {
    const tabList = [
        {
            title: 'Earn high commissions',
            subtitle: 'Highest Commission Rate in the Industry',
            content: 'VenueBoost offers the highest commission rate in the industry and pays you up to 40% of each sale.\n' +
                'Referring customers ensures continuous income for you.',
        },
        {
            title: 'Dedicated Affiliate Support',
            subtitle: 'Rely on Your Personal Affiliate Manager',
            content: 'Our dedicated affiliate support team will make your program effortless and profitable. Your personal\n' +
                'affiliate manager will provide expert assistance and guidance every step of the way.',
        },
        {
            title: 'Transparent Performance Reports',
            subtitle: 'Track Your Progress',
            content: 'Get access to transparent and in-depth performance reports on your dashboard, making it easy to\n' +
                'evaluate your campaign results. Trust our reliable tracking and reporting for accurate commission\n' +
                'calculations.',
        },
        {
            title: 'Real-Time Dashboard Insights',
            subtitle: 'Detailed Performance Metrics',
            content: 'Effortlessly monitor your referrals, customers, earnings, custom affiliate links, and more with our live\n' +
                'dashboard. Access real-time data, track referrals, and view performance reports at your convenience.',
        },
    ];


    const [activeTab, setActiveTab] = useState(0);

  const TabItem = ({ subtitle, content }) => (
    <div className="flex flex-col-reverse items-center md:flex-row mt-0 md:mt-[58px]">
      <div className="flex flex-col w-full md:w-[50%] md:p-0 md:pr-0 gap-4">
        <span className='text-26 font-black'>
          {subtitle}
        </span>
        <div className='w-[112px] h-[6px] bg-gradient-to-r from-[#280B3B] via-[#5D1C82] to-[#BC14FE] my-3'></div>
        <span className='text-xl leading-9'>
          {content}
        </span>
      </div>
      <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0">
        <img src={affiliateImgNew} alt='img' />
      </div>
    </div>
  )

  return (
    <div className='bg-gradient-to-r from-[#280B3B] via-[#5D1C82] to-[#BC14FE] pb-[2px] rounded-xl'>
      <div className={"flex flex-col p-8 xl:p-12 bg-white rounded-xl"}>
        <span className='text-2xl xl:text-4xl font-black mb-8 bg-clip-text bg-gradient-to-r from-[#280B3B] via-[#5D1C82] to-[#BC14FE] text-transparent'>
          VenueBoost Affiliate Program benefits
        </span>

        <div className="tabs-container !px-2 flex flex-col md:flex-row justify-between" role="tablist" aria-label="Products" data-tracking-container="Product Tabs">
          {tabList.map((tab, index) => (
            <button
              key={index}
              className={`tab js-tab ${activeTab === index ? '!bg-gradient-to-r from-[#280B3B] via-[#5D1C82] to-[#BC14FE] rounded-[40px] text-white' : ''}`}
              role="tab"
              aria-selected={activeTab === index}
              aria-controls={`tab-content-${index}`}
              tabIndex="0"
              onClick={() => setActiveTab(index)}
            >
              {tab.title}
            </button>
          ))}
        </div>
        <div className="tabs-content" data-tracking-container="Tab Content">
          {tabList.map((tab, index) => (
            <div
              key={index}
              className={`tab tab-content__panel js-tab-panel ${activeTab === index ? 'is-active' : ''}`}
              role="tabpanel"
              id={`tab-content-${index}`}
              aria-labelledby={`tab-${index}`}
              aria-hidden={activeTab !== index}
              tabIndex="0"
            >
              <TabItem {...tab} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
