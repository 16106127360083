import React from 'react';

import './index.css';
import {useNavigate, useSearchParams} from "react-router-dom";
import {KEYS, setStorageKey} from "../../../utils/storage";

export default function Intro () {

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    if (searchParams.get('referral_code')) {
        setStorageKey(KEYS.REFERRAL_CODE, searchParams.get('referral_code'));
    }

    const navigateToDemoUrl = (url) => {
        navigate(url);
    };

  return (
    <div className='grid grid-cols-1 md:grid-cols-1  align-center items-center'>
      <div className='flex flex-col gap-8'>
        <span className='text-3xl text-center mt-8 xl:text-5xl font-bold text-black1 font-black pr-0 xl:pr-10' style={{lineHeight: "1.3"}}>
         Exclusive Invitation to VenueBoost: Unlock Your Rewards and Join Our Thriving Community!
        </span>

        <div style={{ maxWidth: '80%', margin: '0 auto', textAlign: 'center' }}>
          <p className='text-lg xl:text-22 leading-7'>
              Step Into a World of Opportunities: Engage, Earn, and Grow with Us
          </p>
        </div>
        <div className='flex justify-center'  onClick={() => navigateToDemoUrl("/get-started")}>
          <span className='bg-primary1 text-white rounded-lg px-9 py-4 text-sm font-bold cursor-pointer'>
             Get Started
          </span>
        </div>
      </div>
    </div>
  )
}
