import {useNavigate} from "react-router-dom";

const FreePlanCard = () => {
  const navigate = useNavigate();
  const navigateToDemoUrl = url => {
    navigate(url);
  };
  return (
    <div className='flex flex-col md:flex-row justify-center p-5 md:p-8 rounded-[16px] bg-gradient-to-r from-[#240B3B] via-[#3D1E59] to-[#BC14FE]'>
      <div className='w-full md:w-[75%] flex flex-col justify-center'>
        <h2 className=' font-extrabold text-[20px] md:text-[26px] text-white '>
          Refine your processes, boost your growth, and rediscover the thrill of
          leading your business.{" "}
        </h2>
        <p className='mt-3 font-normal text-[14px] md:text-[20px] text-white'>
          Embark on your pathway to success with Our Free Plan.
        </p>
      </div>
      <div className='w-full md:w-[25%] flex flex-col justify-center mt-4 md:mt-0 md:items-end items-center'>
        <button
          className='bg-white w-[189px] font-semibold text-[#131313] py-2 md:py-3 text-[13px] md:text-16 rounded-[5px] transition ease-in-out hover:scale-101 '
          onClick={() => navigateToDemoUrl("/get-started")}
        >
          UNLOCK FREE PLAN
        </button>
      </div>
    </div>
  );
};

export default FreePlanCard;
