import React, { useEffect, useState } from "react";
import FeatureImg from "../../assets/images/see-it-in-action.png";
import Blockquote from "../../assets/svgs/Blockquote.svg";
import { Seo } from "../../components/Seo";
import { FiX } from "react-icons/fi";
import event1Img from "../../assets/images/blog/blog-26.webp";
import event1RightSideImg from "../../assets/images/who-we-serve/overviewimg1.webp";
import event1RightSideImg2 from "../../assets/images/who-we-serve/overviewimg8.webp";
import event6Img from "../../assets/images/blog/blog-25.webp";
import event6RightSideImg from "../../assets/images/who-we-serve/retail-loyalty.webp";
import event3Img from "../../assets/images/blog/blog-10.webp";
import event3RightSideImg from "../../assets/images/who-we-serve/accomodation-analytics.webp";
import event4Img from "../../assets/images/blog/blog-29.webp";
import event4RightSideImg from "../../assets/images/who-we-serve/ev-analytics.webp";
import event5Img from "../../assets/images/blog/blog-18.webp";
import event5RightSideImg from "../../assets/images/who-we-serve/retail-store-settings.webp";
import event5RightSideImg2 from "../../assets/images/who-we-serve/retail-inventory.webp";
import event5RightSideImg3 from "../../assets/images/who-we-serve/retail-orders.webp";
import event2Img from "../../assets/images/blog/blog-22.webp";
import event2RightSideImg from "../../assets/images/who-we-serve/retail-dashboard-overview.webp";
import EntertainmentVenues from "../../components/Icons/EntertainmentVenues";
import Accommodation from "../../components/Icons/Accommodation";
import RetailManagement from "../../components/Icons/RetailManagement";
import FoodBeverage from "../../components/Icons/FoodBeverage";
import {Checkbox, Modal} from "antd";
import { isMobile } from "react-device-detect";
import SwitchIcon3 from "../../assets/svgs/logo-png-header.webp";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import FormTextInput from "../../components/FormTextInput";
import {FormControlLabel} from "@mui/material";

const MyIcon = (props) => {
  switch (props.category) {
    case "Entertainment Venues":
      return <EntertainmentVenues />;
    case "Accommodation":
      return <Accommodation />;
    case "Retail Management":
      return <RetailManagement />;
    case "Food & Beverage":
      return <FoodBeverage />;
    default:
      return null;
  }
};

const allData = [
  {
    tagId: 1,
    showEmail: true,
    emailTitle: "10 Proven Strategies to Boost Your Restaurant's Online Sales\n" +
        "\n",
    emailDesc1: `Are you struggling to increase your restaurant's online sales? We understand the challenges you face in the competitive food and beverage industry. That's why we've curated a comprehensive guide to help you overcome these hurdles and achieve sustainable growth.`,
    emailDesc2: `Key Takeaways from the Guide:`,
    lead_magnet_title: `Download the guide`,
    emailPlaceholder: `Ready to take your restaurant's online sales to new heights? Enter your email below to download the guide and unlock the secrets to success:`,
    emailAfterPlaceholder: `With our proven strategies and VenueBoost's powerful tools, you'll be well-equipped to navigate the digital realm and achieve remarkable results.`,
    key_takeaways: [
      {
        title: "Increase in Online Conversions",
        label:
            "Implementing these strategies can lead to a significant boost in online conversions, helping you turn website visitors into paying customers.",
      },
      {
        title: "Uplift in Online Orders Made:",
        label:
            "By optimizing your menu offerings and leveraging technology for online orders, you can experience a substantial uplift in the number of online orders placed.",
      },
      {
        title: "Rise in Online Customer Retention: ",
        label:
            "Building customer loyalty through loyalty programs and providing contactless delivery options can result in a significant increase in online customer retention rates.\n" +
            "\n",
      }
    ],
    emailButton: "Download now",
    type_color: "#17CE8C",
    date: "March 20, 2024",
    category: "Food & Beverage",
    title: "10 Proven Strategies to Boost Your Restaurant's Online Sales",
    desc: "From optimizing your menu offerings to leveraging technology and social media marketing, these strategies are tailored to help you navigate the digital realm and achieve sustainable growth in your online sales.",
    img: event1Img,
    rightSideImg: event1RightSideImg,
    rightSideImg2: event1RightSideImg2,
    slug: "10-proven-strategies-boost-restaurant-sales",
    slug_related:
      "/resources/best-practices/10-proven-strategies-boost-restaurant-sales",
    content:
      "In the digital age, online sales have become increasingly vital for restaurants to thrive in a competitive market. Leveraging effective strategies tailored for the online landscape can significantly boost revenue and attract new customers. Here are 10 proven strategies to elevate your restaurant's online sales with the power of VenueBoost.",
    values: [
      { val: "15%", label: "increase in online conversions" },
      { val: "60%", label: "uplift in online orders made" },
      { val: "90%", label: "rise in online customer retention" },
    ],
    strategies: [
      {
        title: "1. Optimizing Your Menu Offerings",
        label:
          "Analyze customer preferences and sales data to optimize your menu offerings and promote high-margin items.",
      },
      {
        title: "2. Leveraging Technology for Online Orders",
        label:
          "Implement an online ordering system to streamline the ordering process and enhance customer convenience.",
      },
      {
        title: "3. Enhancing Social Media Marketing",
        label:
          "Leverage social media platforms to engage with customers, promote special offers, and drive traffic to your online ordering platform.",
      },
      {
        title: "4. Implementing Customer Loyalty Programs",
        label:
          "Reward repeat customers with loyalty programs to encourage repeat business and foster customer loyalty.",
      },
      {
        title: "5. Offering Online Promotions and Discounts",
        label:
          "Attract new customers and drive sales by offering online promotions, discounts, and special deals.",
      },
      {
        title: "6. Providing Contactless Delivery Options",
        label:
          "Offer contactless delivery options to cater to customer preferences and ensure a safe and convenient dining experience.",
      },
      {
        title: "7. Enhancing Your Online Presence",
        label:
          "Optimize your website and online listings to improve visibility, attract new customers, and drive online sales.",
      },
      {
        title: "8. Implementing Online Reservation Systems",
        label:
          "Streamline the reservation process and improve customer experience by implementing an online reservation system.",
      },
      {
        title: "9. Leveraging Customer Reviews and Testimonials",
        label:
          "Encourage satisfied customers to leave positive reviews and testimonials to build trust and credibility with potential customers.",
      },
      {
        title: "10. Analyzing Data and Performance Metrics",
        label:
          "Track and analyze key performance metrics to identify trends, optimize strategies, and drive continuous improvement.",
      },
    ],
    reasons: null,
    quote:
      "“\"Thanks to VenueBoost's guidance, our restaurant saw a significant increase in online sales within weeks. The strategies provided were practical and easy to implement, resulting in a noticeable boost in revenue”",
    feature_title: "Staff Management\n",
    features: [
      {
        title: "Create and update schedules with ease",
        desc: "Effortlessly generate and modify staff schedules using VenueBoost's intuitive interface, ensuring optimal coverage and flexibility.",
      },
      {
        title: "Automate wage and tip calculations\n",
        desc: "VenueBoost streamlines the process of calculating wages and tips, reducing errors and saving time for your administrative tasks.",
      },
      {
        title: "Track hours and staff performance\n",
        desc: "Monitor employee hours and performance metrics to identify top performers, areas for improvement, and ensure compliance with labor regulations.",
      },
      {
        title: "Ensure smooth operations and coordination\n",
        desc: "VenueBoost's comprehensive analytics dashboard offers real-time insights into customer behavior, sales performance, and marketing campaign effectiveness.",
      },
    ],
  },
  {
    tagId: 2,
    showEmail: true,
    emailTitle: "Get the Complete Checklist for Launching a Successful E-commerce Store\n\n",
    emailDesc1: `In today's digital age, launching an e-commerce store is more accessible than ever. However, success requires careful planning and execution. In this guide, we'll walk you through the essential steps and strategies to ensure your e-commerce venture thrives from the start.`,
    emailDesc2: "Key Takeaways from the Checklist:",
    lead_magnet_title: "Get the checklist",
    emailPlaceholder: `Ready to launch your e-commerce store with confidence? Enter your email below to get the complete checklist and ensure your online venture thrives from the start:`,
    emailAfterPlaceholder: `With our proven strategies and VenueBoost's powerful tools, you'll be well-equipped to navigate the digital realm and achieve remarkable results.`,
    key_takeaways: [
      {
        title: "Increase in Online Conversions",
        label: "Implementing these strategies can lead to a significant boost in online conversions, helping you turn website visitors into paying customers.",
      },
      {
        title: "Uplift in Online Orders Made",
        label: "By optimizing your menu offerings and leveraging technology for online orders, you can experience a substantial uplift in the number of online orders placed.",
      },
      {
        title: "Rise in Online Customer Retention",
        label: "Building customer loyalty through loyalty programs and providing contactless delivery options can result in a significant increase in online customer retention rates.",
      },
    ],
    emailButton: "Get the checklist",
    type_color: "#E14848",
    date: "March 23, 2024",
    category: "Retail Management",
    title: "The Complete Checklist for Launching a Successful E-commerce Store",
    desc: "Unlock the secrets to a thriving online business with our comprehensive checklist for launching your e-commerce store. From optimizing your website to mastering customer engagement, this guide covers all aspects essential for success in the digital marketplace.",
    img: event2Img,
    rightSideImg: event2RightSideImg,
    slug: "complete-checklist-launch-successful-ecommerce-store",
    slug_related:
      "/resources/best-practices/complete-checklist-launch-successful-ecommerce-store",
    content:
      "In today's digital age, launching an e-commerce store is more accessible than ever. However, success requires careful planning and execution. In this guide, we'll walk you through the essential steps and strategies to ensure your e-commerce venture thrives from the start.",
    values: [
      { val: "40%", label: "growth in online sales" },
      { val: "35%", label: "rise in customer acquisition" },
      { val: "25%", label: "improvement in conversion rate" },
    ],
    strategies: [
      {
        title: "1. Establishing Your Online Presence",
        label:
          "Build a visually appealing and user-friendly website that showcases your brand and products effectively.",
      },
      {
        title: "2. Driving Traffic and Conversions:",
        label:
          "Implement proven techniques to attract visitors to your site and convert them into paying customers.",
      },
      {
        title: "3. Optimizing the Customer Experience: ",
        label:
          "Enhance user experience and satisfaction through personalized interactions and seamless transactions..",
      },
      {
        title: "4. Leveraging Marketing Channels:",
        label:
          "Utilize a mix of digital marketing channels, including social media, email, and SEO, to reach and engage your target audience.",
      },
      {
        title: "5. Ensuring Smooth Operations:",
        label:
          "Streamline backend processes such as inventory management, order fulfillment, and customer support to deliver a seamless shopping experience.",
      },
    ],
    reasons: null,
    quote:
      "“Thanks to VenueBoost's e-commerce checklist, launching our online store was a breeze. We saw a significant increase in website traffic and sales within the first few weeks, and our customers love the improved shopping experience.",
    feature_title: null,
    features: null,
  },
  {
    tagId: 3,
    showEmail: false,
    emailTitle: "Get the 5 Essential Metrics Every Hotel Manager Should Track\n\n",
    emailDesc1: `Tracking essential metrics is fundamental for hotel managers to gain actionable insights into their business performance. With VenueBoost, you have access to comprehensive data analysis tools that empower you to set goals, monitor progress, and make informed decisions. Let us guide you through the five key metrics every hotel manager should track to optimize operations and drive success.`,
    emailDesc2: "Key Metrics to Track:",
    lead_magnet_title: "Get the metrics",
    emailPlaceholder: `Ready to optimize your hotel's performance? Enter your email below to get the essential metrics guide and unlock the secrets to success:`,
    emailAfterPlaceholder: `With our proven strategies and VenueBoost's powerful tools, you'll be well-equipped to navigate the hospitality industry and achieve remarkable results.`,
    key_takeaways: [
      {
        title: "Increase in Occupancy Rate",
        label: "Implementing strategies to boost occupancy rates can lead to increased revenue and improved overall performance for your hotel.",
      },
      {
        title: "Growth in Average Daily Rate (ADR)",
        label: "By optimizing your pricing strategies and enhancing the value proposition for guests, you can achieve growth in your average daily rate, resulting in higher revenue per room.",
      },
      {
        title: "Enhancement in Customer Satisfaction Score (CSAT)",
        label: "Focusing on improving customer satisfaction levels can lead to higher retention rates, positive reviews, and increased referrals, ultimately driving revenue growth.",
      }
    ],
    emailButton: "Get the metrics",
    type_color: "#6DDFE2",
    date: "March 25, 2024",
    category: "Accommodation",
    title: "5 Essential Metrics Every Hotel Manager Should Track",
    desc: " Discover the essential metrics that provide valuable insights into your venue's performance and empower you to optimize operations, enhance customer experiences, and achieve your business goals.",
    img: event3Img,
    rightSideImg: event3RightSideImg,
    slug: "essential-metrics-hotel-manager-track",
    slug_related:
      "/resources/best-practices/essential-metrics-hotel-manager-track",
    content:
      "Tracking essential metrics is fundamental for venue managers to gain actionable insights into their business performance. With VenueBoost, you have access to comprehensive data analysis tools that empower you to set goals, monitor progress, and make informed decisions. Let us guide you through the five key metrics every venue manager should track to optimize operations and drive success.\n" +
      "\n",
    values: [
      { val: "30%", label: "increase in occupancy rate" },
      { val: "15%", label: "growth in average daily rate" },
      { val: "30%", label: "enhancement in customer satisfaction score" },
    ],
    strategies: [
      {
        title: "1. Revenue per Available Room (RevPAR)",
        label:
          "RevPAR is a key performance indicator that measures the revenue generated per available room in your venue. By calculating RevPAR, you can evaluate the effectiveness of your pricing strategies, occupancy rates, and overall revenue generation.",
      },
      {
        title: "2. Average Daily Rate (ADR)",
        label:
          "ADR provides insights into the average rate charged per room in your venue. By monitoring ADR, you can assess the performance of your pricing strategies, identify opportunities for revenue growth, and optimize room rates to maximize profitability.",
      },
      {
        title: "3. Occupancy Rate",
        label:
          "Occupancy rate measures the percentage of rooms occupied in your venue over a specific period. By tracking occupancy rate, you can evaluate demand trends, optimize room availability, and forecast revenue potential based on occupancy levels.",
      },
      {
        title: "4. Customer Satisfaction Score (CSAT)",
        label:
          "CSAT is a metric that gauges customer satisfaction levels based on feedback and ratings. By monitoring CSAT, you can identify areas for improvement, enhance guest experiences, and build customer loyalty through exceptional service.",
      },
      {
        title: "5. Return on Investment (ROI)",
        label:
          "ROI measures the profitability of your venue's investments and marketing initiatives. By calculating ROI, you can assess the effectiveness of your strategies, allocate resources efficiently, and optimize marketing campaigns to drive revenue growth.",
      },
    ],
    reasons: null,
    quote:
      "Thanks to VenueBoost, we've optimized our operations, increased revenue, and elevated the overall guest experience. It's truly a game-changer for venue managers",
    feature_title:
      "5 Essential Tips for Small Business Owners to Grow Their Business",
    features: [
      {
        title: "Recognizing Your Growth Potential",
        desc: "Understanding where your business stands in the market is key. Start by evaluating your current performance and comparing it to industry benchmarks. Ask yourself: Are there customer segments or markets I haven't tapped into yet? Analyzing your unique selling proposition can reveal new avenues for growth. Keep an eye on industry trends and competitor movements, too. These insights can lead you to exciting growth opportunities.",
      },
      {
        title: "The Power of Business Analytics",
        desc: "Data is your best friend when it comes to business growth. Using tools like VenueBoost, you can dive deep into customer data, understand market trends, and make informed decisions. This isn't just about numbers; it's about understanding what your customers love and how you can serve them in a better way. VenueBoost's analytics can help you anticipate trends and adapt your strategy accordingly.",
      },
      {
        title: "Prioritizing Customer Satisfaction",
        desc: "Your customers are the heart of your business. It's not just about meeting their expectations, but exceeding them. Are your products and services up to par? How responsive is your customer service team? VenueBoost can help with customer engagement, making it easier to connect with your audience. Adopt strategies like loyalty programs or exclusive offers in order to gain customer retention. Remember, happy customers are your best advocates.",
      },
      {
        title: "Adopting Intelligent Tools\n",
        desc: "In today's world, technology is a game changer for small businesses. Tools like VenueBoost can automate routine tasks, centralize your operations, and give you insights that would take days to compile manually. From managing bookings to inventory administration, these tools free up your time so you can focus on what really matters – growing your business.",
      },
      {
        title: "Importance of Networking",
        desc: "Never underestimate the power of a strong network. Attend industry events, join business groups, and get involved in your local business community. These connections can lead to new opportunities and provide a wealth of knowledge. Networking is about building and maintaining relationships that support your business growth.",
      },

      {
        title: "How VenueBoost's Freemium Plan Supports Growth\n",
        desc: "Growth in the small business sector can be a hard challenge and VenueBoost's Freemium Plan was created with this pain point in mind. With access to extended features at no initial cost, businesses can use professional-grade tools that were traditionally only available to larger entities with bigger budgets.",
      },
    ],
  },
  {
    tagId: 4,
    showEmail: true,
    emailTitle: "How to Create a Loyalty Program That Keeps Customers Coming Back\n\n",
    emailDesc1: `Unlock the power of customer loyalty with VenueBoost's innovative solutions. Learn how to craft tailored loyalty programs that inspire repeat purchases and foster long-term relationships with your customers.`,
    emailDesc2: `Key Takeaways from the Guide:`,
    lead_magnet_title: `Get the guide`,
    emailPlaceholder: `Ready to create a loyalty program that keeps your customers coming back for more? Enter your email below to get the guide and start building lasting relationships with your customers:`,
    emailAfterPlaceholder: `With VenueBoost's innovative solutions and tailored strategies, you'll be well-equipped to create a loyalty program that drives customer retention and boosts engagement.`,
    key_takeaways: [
      {
        title: "Increase in Customer Retention",
        label: "Implementing a well-crafted loyalty program can lead to a significant increase in customer retention rates. By offering rewards and incentives, businesses can encourage customers to return and make repeat purchases.",
      },
      {
        title: "Growth in Repeat Purchases",
        label: "A successful loyalty program can foster loyalty among customers, leading to a substantial growth in repeat purchases. By providing exclusive benefits and personalized rewards, businesses can incentivize customers to choose their brand over competitors.",
      },
      {
        title: "Improvement in Customer Engagement",
        label: "Effective loyalty programs enhance customer engagement by providing personalized experiences and rewards. By analyzing customer data and preferences, businesses can tailor their loyalty programs to meet the needs and expectations of their target audience.",
      }
    ],
    emailButton: "Get the guide",
    type_color: "#240b3b",
    date: "March 28, 2024",
    category: "All Industries",
    title: "How to Create a Loyalty Program That Keeps Customers Coming Back",
    desc: "Unlock the power of customer loyalty with VenueBoost's innovative solutions. Learn how to craft tailored loyalty programs that inspire repeat purchases and foster long-term relationships with your customers. ",
    img: event6Img,
    rightSideImg: event6RightSideImg,
    slug: "loyalty-program-keeps-customers-coming-back",
    slug_related:
      "/resources/best-practices/loyalty-program-keeps-customers-coming-back",
    content:
      "VenueBoost empowers businesses across industries to establish robust loyalty programs that drive customer retention and engagement. By segmenting members into personalized tiers, delivering targeted rewards and offers, and analyzing member engagement, VenueBoost equips businesses with the tools needed to strengthen customer relationships and boost loyalty.",
    values: [
      { val: "15%", label: "increase in customer retention" },
      { val: "60%", label: "growth in repeat purchases" },
      { val: "95%", label: "improvement in customer engagement" },
    ],
    reasons: [
      "Understand your customers' preferences, shopping habits, and expectations. Use VenueBoost's analytics tools to gain insights into customer behavior and preferences",
      "Determine the goals of your loyalty program, whether it's increasing customer retention, boosting sales, or encouraging repeat visits. ",
      "\n" +
        "Enhance Membership Engagement: Utilize VenueBoost's robust analytics tools to delve deeper into membership engagement metrics. Gain valuable insights into member interactions, preferences, and behavior patterns to tailor your loyalty program effectively.\n" +
        "\n",
      "Forge Stronger Relationships with Loyal Customers: With VenueBoost, nurturing relationships with your most loyal customers becomes effortless. ",
    ],
    quote:
      "“VenueBoost has truly transformed the way we engage with our customers, and we couldn't be happier with the results.\"\n" +
      "\n”",
    // feature_title: "So how does marketing automation work?",
    // features: [
    //   {
    //     title: "Step 1",
    //     desc: "VenueBoost's automation capabilities capture and analyze customer engagement data across various channels, including social media and email campaigns.",
    //   },
    //   {
    //     title: "Step 2",
    //     desc: "VenueBoost breaks down marketing statistics and customer interactions to identify areas for improvement and strategic focus.",
    //   },
    //   {
    //     title: "Step 3",
    //     desc: "VenueBoost's marketing automation tools provide data-driven recommendations to optimize promotional strategies and enhance audience engagement.",
    //   },
    //   {
    //     title: "Step 4",
    //     desc: "VenueBoost stores valuable insights from marketing campaigns, empowering entertainment venues to refine future marketing efforts for greater success.",
    //   },
    // ],
  },
  {
    tagId: 5,
    showEmail: true,
    emailTitle: "The Ultimate Guide to Streamlining Your Retail Operations\n\n",
    emailDesc1: `Harnessing the power of automation, VenueBoost revolutionizes the way businesses in the entertainment industry connect with their audience. Automating repetitive tasks such as scheduling social media posts.`,
    emailDesc2: `Key Takeaways from the Guide:`,
    lead_magnet_title: `Get the guide`,
    emailPlaceholder: `Ready to streamline your retail operations and enhance customer satisfaction? Enter your email below to get the guide and start optimizing your processes:`,
    emailAfterPlaceholder: `With VenueBoost's innovative solutions and tailored strategies, you'll be well-equipped to streamline your retail operations, reduce costs, and stay competitive in today's dynamic market.`,
    key_takeaways: [
      {
        title: "Efficiency Boost in Fulfillment Processes",
        label: "Implementing the strategies outlined in the guide can lead to a significant boost in efficiency in your fulfillment processes. By optimizing workflows and leveraging automation, businesses can streamline their operations and improve overall efficiency.",
      },
      {
        title: "Improvement in Customer Satisfaction",
        label: "A streamlined retail operation results in improved customer satisfaction. By delivering orders faster, minimizing errors, and providing better communication, businesses can enhance the shopping experience for their customers.",
      },
      {
        title: "Increase in Operational Speed",
        label: "Optimizing retail operations with VenueBoost leads to a noticeable increase in operational speed. By reducing manual tasks and automating processes, businesses can operate more efficiently and respond to customer demands more quickly.",
      }
    ],
    emailButton: "Get the guide",
    type_color: "#E14848",
    date: "March 31, 2024",
    category: "Retail Management",
    title: "The Ultimate Guide to Streamlining Your Retail Operations",
    desc: "Harnessing the power of automation, VenueBoost revolutionizes the way businesses in the entertainment industry connect with their audience. Automating repetitive tasks such as scheduling social media posts.",
    img: event5Img,
    rightSideImg: event5RightSideImg,
    rightSideImg2: event5RightSideImg2,
    rightSideImg3: event5RightSideImg3,
    slug: "ultimate-guide-streamlining-retail-operations",
    slug_related:
      "/resources/best-practices/ultimate-guide-streamlining-retail-operations",
    content:
      "In the dynamic world of retail, effective fulfillment strategies are crucial for success. This comprehensive guide, brought to you by VenueBoost, delves into the intricacies of retail fulfillment, offering insights into optimizing processes, overcoming challenges, and leveraging the latest trends. Whether you’re a small business owner or a manager at a large retail chain, understanding the nuances of retail fulfillment with VenueBoost is key to enhancing customer satisfaction, reducing costs, and staying competitive.",
    values: [
      { val: "15%", label: "efficiency boost in fulfillment processes" },
      { val: "60%", label: "improvement in customer satisfaction" },
      { val: "85%", label: "increase in operational speed" },
    ],
    reasons: null,
    strategies: [
      {
        title: "Understanding Order Management",
        label:
          "Order management is about more than just taking and shipping orders. It's a detailed process that involves tracking and fulfilling customer orders from start to finish. This means overseeing every step from the moment a customer places an order, through processing and packing, to shipment and delivery. It also includes managing customer service interactions, returns, and exchanges. Effective order management requires a system that can handle these complexities, ensuring that the right product reaches the right customer at the right time. It's a crucial component of customer satisfaction and business success in ecommerce and retail management. ",
      },
      {
        title: "The Importance of Order Management",
        label:
          "Effective order management plays a vital role in nurturing customer relationships. Imagine a customer who receives their order on time, exactly as expected — that's a happy customer likely to return. ",
      },
      {
        title: "VenueBoost: Your Key to Successful Order Management",
        label:
          "Dive into the world of seamless order management with VenueBoost's Freemium Plan. Getting started is simple and risk-free, allowing you to explore the rich features of our order management system without any upfront investment. With VenueBoost, you can immediately begin to optimize your retail, ecommerce, or venue management operations. Our intuitive platform is designed to support businesses of all sizes, ensuring you have the tools you need to manage orders, track inventory, and engage customers effectively. Whether you're a small boutique or a large retail chain, VenueBoost adapts to your unique requirements. By signing up for our free plan today, you'll gain access to essential features that can transform the way you do business. Take the first step towards streamlined order processing and enhanced customer satisfaction with VenueBoost. Join us now and experience the difference for yourself!",
      },
      {
        title: "Transitioning from Reactive to Proactive Management\n",
        label:
          "Adopting VenueBoost means shifting from a reactive to a proactive approach in inventory management. You transition from guesswork to data-driven decisions, a vital change in today's competitive retail environment. Customer satisfaction largely depends on product availability, and VenueBoost places this control in your hands.",
      },
      {
        title: "Anticipating Customer Needs with VenueBoost",
        label:
          "With VenueBoost, inventory management is not just about balancing stock levels; it's about foreseeing customer needs and market shifts. Our real-time data and predictive insights offer an unprecedented level of control and foresight. This capability significantly reduces the instances of low stock, ensuring your shelves are always customer-ready. VenueBoost goes beyond being a mere tool; it's a partnership aimed at bolstering your growth and enhancing customer satisfaction.",
      },
      {
        title: "Overcoming the Low Stock Challenge",
        label:
          "Low stock need not be a recurring nightmare in your retail operation. With the right tools like VenueBoost, combined with strategic planning, you can effectively conquer this challenge. VenueBoost is more than just software; it’s a comprehensive solution designed to keep your shelves stocked and your customers content.",
      },
    ],
    quote:
      "“VenueBoost has transformed our retail venue, streamlining operations and enhancing customer satisfaction. With their innovative solutions, we've seen a remarkable increase in efficiency and productivity. Thanks to VenueBoost, we're delivering a seamless shopping experience that keeps our customers coming back for more.”",
    feature_title: "Managing Inventory Alerts with VenueBoost",
    features: [
      {
        title: "Real-time Monitoring",
        desc: "Stay ahead of inventory shortages with VenueBoost's Low Stock Inventory Alert feature. Receive real-time notifications when stock levels drop below predefined thresholds, ensuring you never run out of essential items.",
      },
      {
        title: "Customized Thresholds",
        desc: "Tailor alerts to your specific needs by setting customized threshold levels for each product in your inventory. Whether it's a best-selling item or a seasonal favorite, VenueBoost allows you to adjust thresholds to match demand patterns and minimize stockouts.",
      },
      {
        title: "Automated Notifications",
        desc: "Eliminate the need for manual stock monitoring with automated notifications from VenueBoost. Receive alerts via email as inventory levels reach or fall below your set thresholds, allowing you to take immediate action to replenish stock.",
      },
      {
        title: "Strategic Reordering",
        desc: "Empower your purchasing decisions with data-driven insights from VenueBoost. Use low stock alerts to identify trends, forecast demand, and strategically reorder inventory to optimize stock levels and minimize carrying costs. With VenueBoost, maintaining optimal inventory levels has never been easier.",
      },
    ],
  },
  {
    tagId: 6,
    showEmail: true,
    emailTitle: "Unlocking Success: 7 Strategies to Maximize Revenue and Delight Guests at Your Entertainment Venue\n\n",
    emailDesc1: `Explore innovative techniques tailored for entertainment venues to amplify revenue streams and enhance guest experiences. With VenueBoost's guidance, discover how to implement strategic initiatives that drive success.`,
    emailDesc2: `Key Takeaways from the Guide:`,
    lead_magnet_title: `Get the guide`,
    emailPlaceholder: `Ready to unlock success at your entertainment venue? Enter your email below to get the guide and start implementing strategic initiatives to maximize revenue and delight guests:`,
    emailAfterPlaceholder: `With VenueBoost's expert guidance and tailored strategies, you'll be well-equipped to elevate your venue to new heights of profitability and guest satisfaction.`,
    key_takeaways: [
      {
        title: "Revenue Amplification",
        label: "Implementing the strategies outlined in the guide can lead to a significant amplification of revenue streams for your entertainment venue. By optimizing pricing strategies and leveraging targeted marketing campaigns, venues can maximize revenue opportunities.",
      },
      {
        title: "Guest Experience Enhancement",
        label: "Enhancing the guest experience is key to driving success at your entertainment venue. By personalizing guest experiences, implementing dynamic pricing strategies, and offering loyalty programs, venues can foster long-term relationships with their guests and increase customer satisfaction.",
      },
      {
        title: "Success Drive",
        label: "With VenueBoost's innovative solutions and strategic initiatives, success is within reach for your entertainment venue. By implementing the recommended strategies, venues can drive success, amplify revenue streams, and delight guests, setting the stage for long-term growth and profitability.",
      }
    ],
    emailButton: "Get the guide",
    type_color: "#F4D35E",
    date: "April 2, 2024",
    category: "Entertainment Venues",
    title:
      "Unlocking Success: 7 Strategies to Maximize Revenue and Delight Guests at Your Entertainment Venue",
    desc: "Explore innovative techniques tailored for entertainment venues to amplify revenue streams and enhance guest experiences. With VenueBoost's guidance, discover how to implement strategic initiatives that drive success.",
    img: event4Img,
    rightSideImg: event4RightSideImg,
    slug: "strategies-maximize-revenue-delight-guests",
    slug_related:
      "/resources/best-practices/strategies-maximize-revenue-delight-guests",
    content:
      "VenueBoost's guide to unlocking success for your entertainment venue! In this comprehensive overview, we'll explore innovative techniques tailored specifically for venues like yours to amplify revenue streams and enhance guest experiences. With VenueBoost's expert guidance, discover how to implement strategic initiatives that drive success and elevate your venue to new heights of profitability and guest satisfaction.",
    values: [
      { val: "25%", label: "Revenue Amplification" },
      { val: "50%", label: "Guest Experience Enhancement" },
      { val: "98%", label: "Success Drive" },
    ],

    strategies: [
      {
        title: "1. Automated Marketing Campaigns",
        label:
          "Utilize VenueBoost's automation features to streamline marketing campaigns, ensuring timely execution and personalized engagement with guests, leading to increased revenue opportunities.",
      },
      {
        title: "2. Dynamic Pricing Optimization",
        label:
          "Implement dynamic pricing strategies supported by VenueBoost's data analytics, enabling venues to adjust prices in real-time based on demand, events, and guest preferences, optimizing revenue generation.",
      },
      {
        title: "3. Personalized Guest Experiences",
        label:
          "Leverage VenueBoost's customer relationship management tools to create personalized experiences for guests, from tailored promotions to customized event recommendations, enhancing guest satisfaction and loyalty.",
      },
      {
        title: "4. Enhanced Event Management\n",
        label:
          "Utilize VenueBoost's event management capabilities to streamline operations, from ticket sales to venue logistics, ensuring seamless event execution and maximizing revenue potential.",
      },
      {
        title: "5. Targeted Marketing Campaigns\n",
        label:
          "Leverage VenueBoost's data-driven insights to create targeted marketing campaigns tailored to specific guest demographics and preferences, driving engagement and conversion rates.",
      },
      {
        title: "6. Loyalty Program Implementation\n",
        label:
          "Implement a loyalty program supported by VenueBoost to reward repeat guests, encourage return visits, and foster long-term relationships, ultimately increasing revenue and guest satisfaction.",
      },
      {
        title: "7. Streamlined Operations and Staff Training\n",
        label:
          "Utilize VenueBoost's operational tools to streamline venue operations and optimize staff training, ensuring efficient service delivery and maintaining high standards of guest experience, leading to increased revenue and guest retention.",
      },
    ],
    reasons: null,
    quote:
      "“VenueBoost personalized strategies have amplified our revenue streams and elevated guest experiences. The automation features have streamlined our marketing campaigns, maximizing revenue opportunities.”",
    feature_title: "Essential Tips for Improved Event Management",
    features: [
      {
        title: "Enhancing Your Organizational Skills",
        desc: "Strong organizational skills are the backbone of effective event management. To enhance these skills, start by developing comprehensive checklists and timelines for each stage of the event planning process. Use tools like spreadsheets or event management software to keep track of tasks, deadlines, and budgets. Organize all event information in one central location that can be accessed by your team, so everyone is on the same page.",
      },
      {
        title: "Start As Soon As Possible",
        desc:
          "The sooner you can confirm the details of an event, the better. Effective time management is a critical skill for any event manager, ensuring that every aspect of the event is delivered on schedule. Start by breaking down the event into tasks and allocate a specific time frame for each. Prioritize tasks based on their importance and deadlines, and use a calendar to visualize the timeline leading up to the event day.\n" +
          "\n",
      },
      {
        title: "Developing Flexibility",
        desc:
          "In the realm of event planning, developing flexibility is as crucial as being organized. Flexibility means being prepared to handle last-minute changes or unforeseen circumstances with a calm and resourceful demeanor. To cultivate this skill, consider creating contingency plans for various scenarios, like a change in weather for outdoor events or a speaker cancellation.\n" +
          "\n",
      },
      {
        title: "Utilizing The Right Tools",
        desc: "Don’t shy away from new event technology. Event management platforms provide a unified system to handle event timelines, streamline duties, and ensure detailed control over the event components. Within this realm, VenueBoost stands out as an exceptional tool designed for the essential needs of time-sensitive management.",
      },
      {
        title: "Embrace Networking",
        desc: "Networking is an integral part of effective event management, providing opportunities for valuable collaborations, sponsorships, and partnerships. It`s about building relationships that enrich your event experiences, foster new ideas, and expand your knowledge base.",
      },
    ],
  },
];

const detailsSlug = window.location.pathname;

const data = allData.find((ad) => ad.slug_related === detailsSlug);

const UseCaseDetail = () => {
  const navigate = useNavigate();
  const navigateToUrl = (url) => {
    navigate(url);
  };
  const [visibleLeadMagnetModal, setVisibleLeadMagnetModal] = useState(false);
  const [email, setEmail] = useState("");

  const FeatureBlock = ({ title, description }) => (
    <motion.div
      className="flex-1"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ amount: 0.8, once: true }}
    >
      <motion.div
        className="h-full flex"
        variants={{
          offscreen: { opacity: 0, y: 100 },
          onscreen: {
            y: 0,
            opacity: 1,
            transition: {
              type: "spring",
              bounce: 0,
              duration: 1,
            },
          },
        }}
      >
        <div className="flex flex-col rounded-[13px] bg-white">
          {/*<img src={iconSrc} style={{width: "60%"}}/>*/}
          <p className="text-[#333333] text-18 md:text-22 font-bold mb-2">
            {title}
          </p>
          <p
            className="text-[#666666] font-normal text-16"
            style={{ lineHeight: "30px" }}
          >
            {description}
          </p>
          <ul
            className="text-[#666666] font-normal text-16"
            style={{ lineHeight: "30px", marginTop: "15px" }}
          >
            <li>
              <b>&#x27A4; </b> Discover our Freemium plan
            </li>
            <li>
              <b>&#x27A4; </b> Start a free trial
            </li>
            <li>
              <b>&#x27A4; </b> Elevate your business with VenueBoost
            </li>
          </ul>
          <p
            className="text-[#666666] font-normal text-16"
            style={{ lineHeight: "20px", marginTop: "15px" }}
          >
            It’s time for smarter management and brighter days
          </p>
        </div>
      </motion.div>
    </motion.div>
  );

  useEffect(() => {
    const showModalTimeout = setTimeout(() => {
      // Check if 'showModal' is not in localStorage
      if (!localStorage.getItem("showLeadMagnetModal")) {
        setVisibleLeadMagnetModal(true);
        // Set 'showModal' in localStorage
        localStorage.setItem("showLeadMagnetModal", "true");
      }
    }, 19000); // 19000 milliseconds = 19 seconds

    return () => clearTimeout(showModalTimeout); // Clear timeout if the component unmounts
  }, []);

  const getTemplate = (
    <div className="grid grid-cols-1 md:grid-cols-7 gap-10 my-12">
      <div className="md:col-span-4 flex flex-col gap-5">
        <span className="font-bold text-28 leading-[34px]">
          {data?.emailTitle}
        </span>
        <span className="text-lg pr-5">
          {data?.emailDesc1}
          <br />


        </span>
        <h2 className={"text-[32px] md:text-24 font-extrabold"}> {data?.emailDesc2}</h2>

        {data.key_takeaways &&
            data.key_takeaways.map((v, index) => (
                <div
                    key={index}
                    className={`flex flex-col mt-${index === 0 ? '0' : '2'} md:mt-${index === 0 ? '0' : '2'}`}
                >
                  <h2
                      className={`text-[${data.type_color}] text-[20px] font-bold`}
                  >
                    {v.title}
                  </h2>
                  <p
                      className="font-medium text-14 md:text-18 text-[#585965] leading-[23
                px] md:leading-[29px] mt-2"
                  >
                    {v.label}
                  </p>
                </div>
            ))}
      </div>
      <div className="md:col-span-3">
        <div className="flex flex-col items-center rounded-[37px] border-[#0000001A] px-12 py-12 bg-[#F4F5F7]">
          <span className="font-bold text-40 text-center">
            {data?.lead_magnet_title}
          </span>
          <span className="text-base text-center">
            {data?.emailPlaceholder}
          </span>
          <FormTextInput
            name="email"
            placeholder="Email Address"
            enableLabel={false}
            value={email}
            className="mt-2"
            onChange={(e) => setEmail(e.target.value)}
          />
          <a href="#">
            <button className="font-semibold text-[#ffffff] text-[16px] mb-6 mt-5 px-[80px] py-[4px] bg-gradient-to-r from-[#240B3B] via-[#3D1E59] to-[#BC14FE]">
              {data?.emailButton}
            </button>
          </a>

          <FormControlLabel
              control={
                <Checkbox
                    name='subscribe'
                    // checked={data?.subscribe}
                    // onChange={handleCheckboxChange}
                    style={{
                      fontSize: "17px",
                      marginRight: "10px",
                      marginLeft: "10px",
                      marginTop: "15px",
                    }}
                />
              }
              label={
                <>
                  {"\n"}
                  I agree to the&nbsp;
                  <a href="https://venueboost.io/privacy-policy" target="_blank" rel="noopener noreferrer">
                    <b>
                    privacy policy
                    </b>
                  </a>
                  &nbsp;and to VenueBoost using my contact details to contact me for marketing purposes.
                  {"\n"}
                </>
              }

              style={{fontSize: "17px"}}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className="align-col-middle w-full pt-8 ">
      <Seo
        title={data.title + " - Use Case " + "| VenueBoost"}
        description={data.introduction}
        image={data.detail_image}
        url={"https://venueboost.io" + data.slug_related}
      />
      <div className="w-full max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 ">
        <div className="flex flex-col md:flex-row rounded-[13px] md:rounded-[36px] border-[#E0E0E0] border-[1px] overflow-hidden w-full">
          <div className="w-full md:w-[60%] flex flex-col">
            <div className="w-full py-5 px-7">
              <div className="w-full flex flex-row items-center">
                <div
                  className={`flex flex-row items-center px-4 py-2 rounded-full border-[1px] border-[${data.type_color}]`}
                >
                  <MyIcon category={`${data.category}`} />
                  <p className=" text-[#240B3B] font-semibold text-[13px] ml-2  ">
                    {data.category}
                  </p>
                </div>
                <p className=" font-normal text-14 text-[#717484] ml-4">
                  {data.date}
                </p>
              </div>
              <h1
                className="text-[#240B3B] text-[24px] md:text-[32px] font-extrabold leading-[35
              px] md:leading-[44px] mt-4 md:mt-6"
              >
                {data.title}
              </h1>
              <p
                className=" font-medium text-14 md:text-18 text-[#667085] leading-[20
              px] md:leading-[24px] mt-4 md:mt-6"
              >
                {data.desc}
              </p>
            </div>
            <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-2 px-4 md:px-8 py-3 md:py-6 rounded-[13px] md:rounded-tl-[36px] md:rounded-tr-[0px] md:rounded-b-[0px] border-[#E0E0E0] border-t-[1px]">
              {data.values.map((v, index) => (
                <div key={index} className="flex flex-col ">
                  <h2
                    className={`pl-3 text-[${data.type_color}] text-[30px] font-bold`}
                  >
                    {v.val}
                  </h2>
                  <p className=" mt-2 text-[#656D72] text-[16px] font-normal">
                    {v.label}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="w-full md:w-[40%] flex flex-col">
            <img
              className="rounded-[13px] md:rounded-[36px] object-cover w-full max-h-[427px] "
              src={data.img}
              alt=""
            />
          </div>
        </div>

        <div className="flex flex-col md:flex-row w-full mt-8">
          <div className="flex flex-col w-full md:w-[60%] pr-0 md:pr-12">
            <h1 className=" text-[24px] md:text-[32px] text-[#240B3B] font-bold">
              Overview
            </h1>
            <p
              className="font-medium text-14 md:text-18 text-[#585965] leading-[23
                px] md:leading-[29px] mt-4 md:mt-6"
            >
              {data.content}
            </p>
            {data.reasons &&
              data.reasons.map((v, index) => (
                <div key={index} className="flex flex-col mt-4 md:mt-6">
                  <h2
                    className={`text-[${data.type_color}] text-[20px] font-bold`}
                  >
                    {data.tagId === 4 ? "Hint" : "Reason"} {index + 1}
                  </h2>
                  <p
                    className="font-medium text-14 md:text-18 text-[#585965] leading-[23
                px] md:leading-[29px] mt-2"
                  >
                    {v}
                  </p>
                </div>
              ))}

            {data.strategies &&
              data.strategies.map((v, index) => (
                <div key={index} className="flex flex-col mt-4 md:mt-6">
                  <h2
                    className={`text-[${data.type_color}] text-[20px] font-bold`}
                  >
                    {v.title}
                  </h2>
                  <p
                    className="font-medium text-14 md:text-18 text-[#585965] leading-[23
                px] md:leading-[29px] mt-2"
                  >
                    {v.label}
                  </p>
                </div>
              ))}
            <div
              className={`w-full border-l-4 border-[${data.type_color}] py-2 px-4 mt-4 md:mt-6`}
            >
              <img
                src={Blockquote}
                className="w-[55px] md:w-[70px] object-contain"
                alt=""
              />
              <p
                className="font-medium text-12 md:text-16 text-[#585965] leading-[20
                px] md:leading-[28px] mt-4"
              >
                {data.quote}
              </p>
            </div>
          </div>

          <div className="w-full md:w-[40%] flex flex-col">
            <div className=" relative mt-6 md:mt-0 flex flex-col justify-center items-center p-6 bg-[#F4F5F7] rounded-[13px] md:rounded-[36px] w-full ">
              <h3 className="text-center text-[24px] font-bold text-[#232E35]">
                Try VenueBoost today
              </h3>
              <p className="mt-4 text-center text-[16px] font-normal text-[#656D72]">
                Getting started is easy. No credit card required. <br />
                Cancel anytime.
              </p>
              <a href={"/get-started"}>
                <button className="font-semibold text-[#ffffff] text-[16px] mt-5 px-[80px] py-[4px] bg-gradient-to-r from-[#240B3B] via-[#3D1E59] to-[#BC14FE]">
                  UNLOCK FREE TRIAL
                </button>
              </a>
            </div>
            <div className="flex flex-col w-full  mt-4 md:mt-6">
              <h1 className=" text-[24px] md:text-[32px] text-[#240B3B] font-bold">
                Experience the VenueBoost Difference in Action
              </h1>
              <p
                className="font-medium text-14 md:text-18 text-[#585965] leading-[23
                px] md:leading-[29px] mt-3 md:mt-4"
              >
                Witness the seamless synergy of VenueBoost's cutting-edge
                marketing automation tools as they transform your promotional
                efforts. Gain unparalleled insights into your marketing
                campaigns and understand their profound impact on audience
                engagement. Our platform not only streamlines your marketing
                processes but also empowers you to make data-driven decisions
                that drive meaningful results.
              </p>
              <img
                src={data.rightSideImg}
                alt=""
                className="w-full rounded-xl object-cover mt-4 md:mt-5"
              />
              <p
                className="font-medium text-14 md:text-18 text-[#585965] leading-[23
                px] md:leading-[29px] mt-3 md:mt-4"
              >
                Discover how VenueBoost can elevate your venue's marketing to
                new heights and unlock its true potential. Embrace the future of
                venue management and experience the VenueBoost difference today.
              </p>
              {data.rightSideImg2 && (
                <img
                  src={data.rightSideImg2}
                  alt=""
                  className="w-full rounded-xl object-cover mt-4 md:mt-5"
                />
              )}

              {data.rightSideImg3 && (
                <img
                  src={data.rightSideImg3}
                  alt=""
                  className="w-full rounded-xl object-cover mt-4 md:mt-5"
                />
              )}
            </div>
          </div>
        </div>
        {getTemplate}

        <div className="flex flex-col w-full mt-8 md:mt-12">
          <h1 className=" text-[24px] md:text-[32px] text-[#240B3B] font-bold">
            {data.features && data.feature_title}
          </h1>
          <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4 py-6 md:py-12 ">
            {data.features &&
              data.features.map((v, index) => (
                <div
                  key={index}
                  className={`flex flex-col rounded-xl px-4 py-6
                shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)]
                border-b-[1px] border-b-[${data.type_color}]
                `}
                >
                  <h3 className="text-[#000000] text-[18px] font-bold">
                    {v.title}
                  </h3>
                  <p className=" mt-4 text-[#000000] text-[16px] font-normal">
                    {v.desc}
                  </p>
                </div>
              ))}
          </div>
        </div>
      </div>
      <Modal
        centered
        open={visibleLeadMagnetModal}
        onCancel={() => setVisibleLeadMagnetModal(false)}
        onOk={() => navigateToUrl("/get-started")}
        maskClosable={false}
        okText={"Start My VenueBoost Journey"}
        cancelText={"I'm not ready to revolutionize my venue"}
        className="freeJourneyModal"
        okButtonProps={{
          style: {
            background:
              "linear-gradient(128deg, rgb(36, 11, 59) 3.95%, rgb(61, 30, 89) 34%, rgb(51, 5, 94) 67.3%, rgb(188, 20, 254) 94.28%)",
            color: "white",
            height: "40px",
            width: isMobile ? "100%" : "50%",
            marginLeft: isMobile ? "0px" : "8px",
            marginTop: isMobile ? "15px" : "0",
          },
        }}
        cancelButtonProps={{
          style: {
            backgroundColor: "transparent",
            color: "#240b3b",
            borderColor: "#CBD5E1",
            height: "40px",
            width: isMobile ? "100%" : "50%",
          },
        }}
        bodyStyle={{ height: isMobile ? "391px" : "302px" }}
        width={600}
      >
        <div
          className={
            "flex flex-col py-4 w-full h-max md:h-[302px] overflow-auto gap-5"
          }
        >
          <FeatureBlock
            iconSrc={SwitchIcon3}
            title={"Revolutionize Your Venue with VenueBoost\n"}
            description={
              "" +
              "Say goodbye to outdated venue management. VenueBoost empowers you to optimize operations, enhance guest experiences, and achieve unprecedented growth."
            }
          />
        </div>
      </Modal>
    </div>
  );
};

export default UseCaseDetail;
