import React from 'react';
import bookingIcon from '../../../assets/svgs/booking.svg'

const ToolItem = ({ title }) => {
  return (
    <div className='flex flex-col justify-between bg-[#F3E7FF] border border-[#240B3B80] rounded-[20px] py-6 px-6 h-[310px]'>
      <span className='text-3xl text-[#5C1C81] font-bold pr-10'>
        {title}
      </span>
      <div className='flex flex-row justify-between items-center'>
        <span className='text-sm text-[#E14848] px-4 py-2 bg-white rounded-[4px]'>
          Retail Management
        </span>
        <img src={bookingIcon} alt='icon' className='p-2 rounded-full bg-white' />
      </div>
    </div>
  )
}

export default ToolItem;