import { Step, StepConnector, StepLabel, Stepper, stepConnectorClasses } from "@mui/material";
import React, { useEffect, useState } from "react";
import CheckoutScreen from "./checkout";
import { Box, styled } from "@mui/system";
import {
  addressHome,
  paymentMethod,
  review,
  successIcon,
} from "../../venue-retail/icons";
import "../index.css";
import PaymentMethod from "./payment-method/payment-method";
import CheckoutReview from "./review";
import { Cart } from "../layout/cart";
import { useDispatch, useSelector } from "react-redux";
import { verifyCoupon } from "../../../redux/actions/cart";
import Modal from "@mui/material/Modal";
import "./index.css";

export const CheckoutStepper = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const { products, coupon_detail, shippingMethod } = useSelector(
    (state) => state.cart
  );
  const dispatch = useDispatch();
  const [discount, setDiscount] = useState(0);
  const [tableProducts, setTableProducts] = useState([]);
  const [selectedShipping, setSelectedShipping] = useState(0);
  const [quantities, setQuantities] = useState([]);

  const steps = [
    { label: "Address", icon: addressHome(activeStep >= 0) },
    { label: "Payment Method", icon: paymentMethod(activeStep >= 1) },
    { label: "Review", icon: review(activeStep >= 2) },
  ];

  const CustomConnector = styled(StepConnector)(() => ({
    "& .MuiStepConnector-line": {
      borderTopWidth: 1,
      borderRadius: 0,
      borderStyle: "dashed"
    },
  }));

  const CustomConnectorMobile = styled(StepConnector)(() => ({
    "& .MuiStepConnector-line": {
      border: "none"
    },
  }));


  useEffect(() => {
    if (products) {
      let discount = 0;
      let shipping = 0;

      const initialQuantities = products.map((element, index) => {
        if (index < quantities.length) {
          return quantities[index] < 1 ? 1 : quantities[index];
        } else {
          return element.quantity < 1 ? 1 : element.quantity;
        }
      });

      setQuantities(initialQuantities);

      setTableProducts(products);
      if (coupon_detail) {
        dispatch(verifyCoupon(null));
        if (coupon_detail?.discount_value) {
          discount = coupon_detail?.discount_value;
        }
      }
      if (shippingMethod) {
        shipping = shippingMethod;
      }
      setSelectedShipping(shipping);
      setDiscount(discount);
    }
  }, [products, coupon_detail]);

  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-8 px-4 md:px-6 lg:px-8 lg:gap-20 mb-8">
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: 492,
            border: "1px solid rgba(164, 161, 170, 0.2)",
            boxShadow:
              "0px 10px 40px -4px rgba(16, 24, 40, 0.02), 0px 8px 8px -4px rgba(16, 24, 40, 0.02)",
            borderRadius: "20px",
            p: 4,
            background: "white",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            gap: "30px",
          }}
        >
          <div className="flex flex-col justify-center items-center">
            {successIcon}
          </div>
          <h1 className="order-confirmed text-lg md:text-xl lg:text-2xl">
            Your order is confirmed
          </h1>
          <span className="order-description text-sm md:text-base lg:text-lg">
            Thanks for shopping! Your order hasn’t shipped yet, but we will send
            you an email when it does.
          </span>

          <div className="w-full flex flex-col gap-2">
            <button
              className="proceed w-full py-2 bg-blue-500 text-white rounded-md"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <span>View Order</span>
            </button>
            <button
              className="back-btn w-full py-2 bg-gray-500 text-[#131118] rounded-md"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <span>Back to Home</span>
            </button>
          </div>
        </Box>
      </Modal>
      <div className="col-span-1 lg:col-span-2 flex flex-col gap-8 pt-8">
        <Stepper activeStep={activeStep} connector={<CustomConnector />} className="!hidden lg:!flex">
          {steps.map((step, index) => (
            <Step key={index} className="max-w-[72px] !px-0">
              <div
                className="flex flex-col items-center gap-2 bg-transparent cursor-pointer"
                onClick={() => {
                  activeStep >= index && setActiveStep(index);
                }}
              >
                <StepLabel icon={step.icon} className="rounded-lg overflow-hidden [&>span]:pr-0" />
                <span className="stepper-label text-xs md:text-sm lg:text-base absolute mt-12">
                  {step.label}
                </span>
              </div>
            </Step>
          ))}
        </Stepper>

        <Stepper activeStep={activeStep} connector={<CustomConnectorMobile />} className="!flex lg:!hidden">
          {steps.map((step, index) => (
            <Step key={index} className="max-w-[72px] !px-0">
              <div
                className="flex flex-col items-center gap-2 bg-transparent cursor-pointer"
                onClick={() => {
                  activeStep >= index && setActiveStep(index);
                }}
              >
                <StepLabel icon={step.icon} className="rounded-lg overflow-hidden [&>span]:pr-0" />
                <span className="stepper-label text-14 lg:text-base absolute mt-12">
                  {step.label}
                </span>
              </div>
            </Step>
          ))}
        </Stepper>

        <div>
          {activeStep === 0 ? (
            <CheckoutScreen setActiveStep={setActiveStep} />
          ) : activeStep === 1 ? (
            <PaymentMethod setActiveStep={setActiveStep} />
          ) : (
            <CheckoutReview />
          )}
        </div>
      </div>
      <div className="">
        <Cart
          isCheckout={activeStep === 2}
          selectedShipping={selectedShipping}
          discount={discount}
          quantities={quantities}
          tableProducts={tableProducts}
          buttonLabel={activeStep === 2 && "Place Order"}
          onClick={() => setIsOpen(true)}
          isProceed={activeStep !== 2}
        />
      </div>
    </div>
  );
};
//   ownerState: { completed?: boolean; active?: boolean };
// }>(({ theme, ownerState }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
//   zIndex: 1,
//   color: '#fff',
//   width: 50,
//   height: 50,
//   display: 'flex',
//   borderRadius: '50%',
//   justifyContent: 'center',
//   alignItems: 'center',
//   ...(ownerState.active && {
//     backgroundImage:
//       'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
//     boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
//   }),
//   ...(ownerState.completed && {
//     backgroundImage:
//       'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
//   }),
// }));


// function ColorlibStepIcon(props) {
//   const { active, completed, className } = props;

//   const icons: { [index] } = {
//     1: <SettingsIcon />,
//     2: <GroupAddIcon />,
//     3: <VideoLabelIcon />,
//   };

//   return (
//     <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
//       {icons[String(props.icon)]}
//     </ColorlibStepIconRoot>
//   );
// }


// const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
//   [`&.${stepConnectorClasses.alternativeLabel}`]: {
//     top: 22,
//   },
//   [`&.${stepConnectorClasses.active}`]: {
//     [`& .${stepConnectorClasses.line}`]: {
//       backgroundImage:
//         'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
//     },
//   },
//   [`&.${stepConnectorClasses.completed}`]: {
//     [`& .${stepConnectorClasses.line}`]: {
//       backgroundImage:
//         'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
//     },
//   },
//   [`& .${stepConnectorClasses.line}`]: {
//     height: 3,
//     border: 0,
//     backgroundColor:
//       theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
//     borderRadius: 1,
//   },
// }));