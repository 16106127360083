import React from "react";

import Seo from "../../components/Seo";
import DoubleCheck from "../../components/Icons/DoubleCheck";
import EntertainmentVenues from "../../components/Icons/EntertainmentVenues";
import Accommodation from "../../components/Icons/Accommodation";
import RetailManagement from "../../components/Icons/RetailManagement";
import FoodBeverage from "../../components/Icons/FoodBeverage";
import GuideItem from "./guide-item";
import ToolItem from "./tool-item";

import './index.css';
import speedImg from '../../assets/images/resources/speed.png';
import eventImg from '../../assets/images/resources/event.png';
import conferenceImg from '../../assets/images/resources/conference.png';
import discoverImg from '../../assets/images/resources/discover.png';
import searchIcon from '../../assets/svgs/search.svg';

const GuideList = [
  { image: speedImg, title: 'Speed Networking: A New Kind of Professional Event' },
  { image: eventImg, title: '20 Types of Corporate Events You Should Know About' },
  { image: conferenceImg, title: 'Unique Conference Venues: Your Complete Guide' },
];

const ToolList = [
  { title: 'Build an effective operating rhythm: Weekly, monthly, and quarterly action items' },
  { title: 'Transform your revenue workflows: The ultimate playbook for AI-drivengrowth' },
  { title: 'Fixing the Fallout from Missed Forecasts' },
  { title: '5 Capabilities You Need for Reliable Growth' },
  { title: 'Power Up Productivity' },
  { title: 'Email Optimization Guide for Bulk Senders' },
  { title: 'Win More Mega-Deals' },
  { title: 'Achieve revenue predictability with AI-powered forecasting' },
]

const SearchList = [
  { title: 'VENUE', counts: 2 },
  { title: 'BOOST', counts: 2 },
  { title: 'REVENUE INTELLIGENCE', counts: 2 },
  { title: 'SELLING SKILLS', counts: 2 },
  { title: 'COMPANY', counts: 1 },
  { title: 'BOOKING', counts: 1 },
  { title: 'SALES LEADERSHIP', counts: 1 },
  { title: 'SALES MANAGEMENT', counts: 1 },
]

const MyIcon = props => {
  switch (props.category) {
    case "Entertainment Venues":
      return <EntertainmentVenues />;
    case "Accommodation":
      return <Accommodation />;
    case "Retail Management":
      return <RetailManagement />;
    case "Food & Beverage":
      return <FoodBeverage />;
    default:
      return null;
  }
};

const Guides = () => {
  return (
    <div className='flex flex-col max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 relative'>
      <div className="flex flex-col py-16">
        <Seo title={"Resources - Guides and Templates | VenueBoost"} />
        <span className="guides-marker w-max text-white px-3 rounded-[40px] text-base font-semibold">
          Guides
        </span>
        <span className="gradient-title text-30 md:text-48 font-extrabold">
          Discover the latest insights, industry trends<br /> and expert recommendations
        </span>

        <div className="flex flex-col gap-6 md:hidden">
          <div className='flex flex-row items-center gap-5'>
            <div>Filter</div>
            <div class='flex flex-row items-center px-6 w-max h-12 rounded-3xl border border-[#240B3B] bg-[rgba(36,11,59,.2)]'>
              <DoubleCheck />
              <h1 class='text-14 font-semibold text-[#240B3B]'>All</h1>
            </div>
          </div>
          <div class='flex flex-row items-center px-6 h-12 w-max cursor-pointer rounded-3xl border border-[#F4D35E] p-[1px]'>
            <MyIcon category={"Entertainment Venues"} />
            <h1 class='text-14 font-semibold text-[#373941] ml-1.5'>
              Entertainment Venues
            </h1>
          </div>
          <div class='flex flex-row items-center px-6 h-12 w-max cursor-pointer rounded-3xl border border-[#6DDFE2] p-[1px]'>
            <MyIcon category={"Accommodation"} />
            <h1 class='text-14 font-semibold text-[#373941] ml-2'>
              Accommodation
            </h1>
          </div>
          <div class='flex flex-row items-center px-6 h-12 w-max cursor-pointer rounded-3xl border border-[#E14848] p-[1px]'>
            <MyIcon category={"Retail Management"} />
            <h1 class='text-14 font-semibold text-[#373941] ml-2'>
              Retail Management
            </h1>
          </div>
          <div class='flex flex-row items-center px-6 h-12 w-max cursor-pointer rounded-3xl border border-[#17CE8C] p-[1px]'>
            <MyIcon category={"Food & Beverage"} />
            <h1 class='text-14 font-semibold text-[#373941] ml-2'>
              Food & Beverage
            </h1>
          </div>
        </div>
        
        <div className="flex flex-col gap-6 mt-4">
          <span className="text-sm font-semibold">
            FEATURED
          </span>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
            {GuideList.map((item, index) => (
              <GuideItem key={index} {...item} />
            ))}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-0">
            <div className="md:col-span-2 grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-14">
              {ToolList.map((item, index) => (
                <ToolItem key={index} {...item} />
              ))}
            </div>
            <div className="flex flex-col md:pl-[20%] gap-y-14">
              <div className="md:flex flex-col gap-6 hidden">
                <div className='flex flex-row items-center gap-5'>
                  <div>Filter</div>
                  <div class='flex flex-row items-center px-6 w-max h-12 rounded-3xl border border-[#240B3B] bg-[rgba(36,11,59,.2)]'>
                    <DoubleCheck />
                    <h1 class='text-14 font-semibold text-[#240B3B]'>All</h1>
                  </div>
                </div>
                <div class='flex flex-row items-center px-6 h-12 w-max cursor-pointer rounded-3xl border border-[#F4D35E] p-[1px]'>
                  <MyIcon category={"Entertainment Venues"} />
                  <h1 class='text-14 font-semibold text-[#373941] ml-1.5'>
                    Entertainment Venues
                  </h1>
                </div>
                <div class='flex flex-row items-center px-6 h-12 w-max cursor-pointer rounded-3xl border border-[#6DDFE2] p-[1px]'>
                  <MyIcon category={"Accommodation"} />
                  <h1 class='text-14 font-semibold text-[#373941] ml-2'>
                    Accommodation
                  </h1>
                </div>
                <div class='flex flex-row items-center px-6 h-12 w-max cursor-pointer rounded-3xl border border-[#E14848] p-[1px]'>
                  <MyIcon category={"Retail Management"} />
                  <h1 class='text-14 font-semibold text-[#373941] ml-2'>
                    Retail Management
                  </h1>
                </div>
                <div class='flex flex-row items-center px-6 h-12 w-max cursor-pointer rounded-3xl border border-[#17CE8C] p-[1px]'>
                  <MyIcon category={"Food & Beverage"} />
                  <h1 class='text-14 font-semibold text-[#373941] ml-2'>
                    Food & Beverage
                  </h1>
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <div className="flex flex-row items-center gap-2 h-12 px-6 border border-[#D3D3D3] rounded-[40px] bg-[#F2F2F2]">
                  <img src={searchIcon} alt="icon" />
                  <input type="text" placeholder="Search" className="font-semibold text-base bg-transparent outline-none" />
                </div>
                <div className="flex flex-wrap gap-4">
                  {SearchList.map((item, index) => (
                    <div key={index} className="flex items-center justify-center font-semibold text-sm h-7 px-3 border border-[#E0E0E0] rounded-xl">
                      {item.title} ({item.counts})
                    </div>
                  ))}
                </div>
              </div>
              <div className=' relative mt-6 md:mt-0 flex flex-col justify-center items-center p-6 bg-[#F4F5F7] rounded-[13px] md:rounded-[36px] w-full '>
                <h3 className='text-center text-[24px] font-bold text-[#232E35]'>
                  Try VenueBoost today
                </h3>
                <p className='mt-4 text-center text-[16px] font-normal text-[#656D72]'>
                  Getting started is easy. No credit card required. Cancel anytime.
                </p>
                <a href={"/get-started"}>
                  <button className='font-semibold text-[#ffffff] text-[16px] mt-5 px-[80px] py-[4px] bg-gradient-to-r from-[#240B3B] via-[#3D1E59] to-[#BC14FE]'>
                    UNLOCK FREE TRIAL
                  </button>
                </a>
                <p className='mt-5 text-center text-[16px] font-normal text-[#240B3B]'>
                  See how it works
                </p>
              </div>
              <div className="flex flex-col gap-6">
                <span className="font-bold text-[32px] leading-[48px]">
                  Discover VenueBoost in Action
                </span>
                <span className="text-[#585965] text-lg font-medium">
                  Explore the seamless integration of VenueBoost's marketing automation tools, providing a comprehensive view of your promotional activities and their impact on audience engagement.
                </span>
                <img src={discoverImg} alt="discover" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Guides;
