import React, {useEffect, useState, useRef} from "react";
import "./index.css";
import {Seo} from "../../components/Seo";
import {useTranslation} from "react-i18next";
import CircleCheckSvg from "../../assets/svgs/circle-tick.svg";
import FormTextInput from "../../components/FormTextInput";
import FormSelect from "../../components/FormSelect";
import WavesIcon from "../../assets/svgs/wave.svg";
import {joinWaitlist as joinWaitlist} from "../../redux/actions/marketing";
import {generateMarketingWaitlistLink as generateMarketingWaitlistLink} from "../../redux/actions/marketing";
import {NotificationManager} from "react-notifications";
import HubIcon4 from "../../assets/svgs/hubicon4.svg";
// import BannerImg from "../../assets/images/landing/banner.png";
import {getStorageKey, KEYS} from "../../utils/storage";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Modal} from "antd";
import EvImg from "../../assets/images/waitlist/entertainment_venue_admin_bg.webp";
import AcmImg from "../../assets/images/waitlist/accommodation_venue_admin_bg.webp";
import FbImg from "../../assets/images/waitlist/login_2.webp";
import RmImg from "../../assets/images/waitlist/retail_management_venue_admin_bg.webp";
import {CircularProgress} from "@mui/material";
import {t} from "i18next";

const countryList = [{value: "US", title: "United States"}];

export const industryImages = {
  fb: FbImg,
  accommodation: AcmImg,
  entertainment_venue: EvImg,
  retail_management: RmImg,
};

const WaitlistMarketing = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState({
    email: null,
    phone_number: null,
    venue_name: null,
    country_code: countryList[0].value, // Preselect the first option
    full_name: null,
  });

  const [category, setCategory] = useState("fb");
  const categories = [
    "fb",
    "accommodation",
    "entertainment_venue",
    "retail_management",
  ];

  const [visibleLoginModal, setVisibleLoginSaveModal] = useState(false);
  const [isJoined, setIsJoined] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [emailVerification, setEmailVerification] = useState("");
  const [promoCode, setPromoCode] = useState(null);

  const isJoinedRef = useRef(null);

  useEffect(() => {
    // check if it has waitlist storage
    const WAITLIST_AUTH_KEY = getStorageKey(KEYS.WAITLIST_AUTH_KEY);

    if (WAITLIST_AUTH_KEY) {
      setTimeout(() => {
        navigate("/waitlist/1");
      }, 2000);
    }

    if (searchParams.get("promo_code")) {
      setPromoCode(searchParams.get("promo_code"));
    }
  }, []);

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % categories.length);
    }, 5000); // Change image every 3 seconds (adjust as needed)

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCategory(categories[currentIndex]);
    }, 1000); // Delay to change the category (adjust as needed)

    return () => clearTimeout(timer);
  }, [currentIndex, categories]);

  useEffect(() => {
    if (isJoined) {
      // Scroll to the isJoined block with a smooth animation
      isJoinedRef.current.scrollIntoView({behavior: "smooth"});
    }
  }, [isJoined]);

  const handleChangeCountry = e => {
    setData(info => ({...info, restaurant_country: "US"}));
  };

  const handleChange = e => {
    setData(info => ({...info, [e.target.name]: e.target.value}));
  };

  const handleVerifyChangeEmail = e => {
    setEmailVerification(e.target.value);
  };

  const waitlistVerifyEmail = () => {
    if (emailVerification === "") {
      NotificationManager.warning("Email required", "Warning", 3000);

      return false;
    }

    if (!validateEmail(emailVerification)) {
      NotificationManager.warning(
        "Please enter a valid email address.",
        "Warning",
        3000
      );
      return;
    }

    const sendData = {
      email: emailVerification,
    };

    generateMarketingWaitlistLink(sendData)
      .then(data => {
        setEmailVerification("");
        NotificationManager.success(
          "A verification email has been sent to your email address. Please check your inbox to proceed with login.",
          "Sent",
          5000
        );
        setVisibleLoginSaveModal(false);
      })
      .catch(err => {
        setVisibleLoginSaveModal(false);
        const errorKeys = Object.keys(err?.error || {});
        if (errorKeys.length > 0) {
          const firstErrorKey = errorKeys[0];
          const firstErrorMessage = err?.error[firstErrorKey][0];
          NotificationManager.error(
            firstErrorMessage || "Something went wrong!"
          );
        } else {
          NotificationManager.error("Something went wrong!");
        }

        throw new Error("An error occurred while joining waitlist");
      });
  };

  const validateEmail = email => {
    const regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  };

  const onSubmit = () => {
    if (data.email === null) {
      NotificationManager.warning(
        "Your email is needed for you to join the Waitlist. Other fields are optional",
        "Warning",
        3000
      );

      return false;
    }

    if (!validateEmail(data.email)) {
      NotificationManager.warning(
        "Please enter a valid email address.",
        "Warning",
        3000
      );
      return;
    }

    if (promoCode) {
      data.promo_code = promoCode;
    }

    setSubmitting(true);
    joinWaitlist(data)
      .then(data => {
        setData(null);
        setIsJoined(true);
        setSubmitting(false);
        NotificationManager.success(
          "We've sent a confirmation email to your inbox. Please click the link to confirm your email and secure your spot on the VenueBoost waitlist.",
          "Almost there! ",
          5000
        );
      })
      .catch(err => {
        setSubmitting(false);
        const errorKeys = Object.keys(err?.error || {});
        if (errorKeys.length > 0) {
          const firstErrorKey = errorKeys[0];
          const firstErrorMessage = err?.error[firstErrorKey][0];
          NotificationManager.error(
            firstErrorMessage || "Something went wrong!"
          );
        } else {
          NotificationManager.error("Something went wrong!");
        }

        throw new Error("An error occurred while joining waitlist");
      });
  };

  const {t} = useTranslation();
  return (
    <div className={"align-col-middle "}>
      <Seo title={"Waitlist | VenueBoost"} />

      <div className=''>
        <div
          className={
            "sub-container view-sales overflow-auto py-0 px-4 h-full flex flex-col gap-8 md:gap-20 xl:gap-32"
          }
        >
          <div className='grid grid-cols-1 gap-6 md:grid-cols-2 items-center'>
            <div className='flex flex-col gap-6'>
              <div className='floating-wave-container'>
                <img style={{width: "225px"}} src={WavesIcon} />
                <div className='coming-soon-badge'>Coming Soon</div>
              </div>

              <span className='text-2xl xl:text-3xl text-black1 font-black pr-0 xl:pr-10'>
                Join Our Waitlist for Exclusive Early Access and Benefits!
              </span>
              <div className='text-20'>
                <p className='leading-9'>
                  Get ready for VenueBoost, the revolutionary platform
                  transforming the hospitality industry! Secure your spot at the
                  forefront of this exciting journey. By joining our waitlist,
                  you're not just signing up for early access; you're becoming a
                  pivotal part of our community.
                </p>
              </div>
            </div>
            <div className='image-container'>
              <img
                src={industryImages[category]}
                alt='login'
                className='w-full h-full object-cover'
              />
            </div>
            {/*<img*/}
            {/*    src={BannerImg}*/}
            {/*    alt="banner"*/}
            {/*    className="rounded-tl-[344px]"*/}

            {/*/>*/}
          </div>
        </div>
        <div className='mx-auto max-w-screen-main mt-10 px-4'>
          <div className='flex md:flex-row flex-col pt-4 pb-11 h-full'>
            <div className=' w-full md:w-[40%] mt-6 bg-[#F9F7F7] md:mr-11 border-[1px] border-[#E0E0E0] rounded-[5px] p-4 md:p-10 py-6 gap-y-4 gap-x-8'>
              <div className='grid grid-cols-1 gap-7  '>
                <FormTextInput
                  name='email'
                  type={"email"}
                  placeholder='Email *'
                  value={data?.email || ""}
                  onChange={handleChange}
                />
                <FormTextInput
                  name='full_name'
                  placeholder='Full Name'
                  value={data?.full_name || ""}
                  onChange={handleChange}
                />
                <FormTextInput
                  name='venue_name'
                  placeholder='Venue Name'
                  value={data?.venue_name || ""}
                  onChange={handleChange}
                />
              </div>
              <div className='flex flex-col gap-6 mt-6'>
                <FormSelect
                  name={"country_code"}
                  placeholder='Country'
                  value={countryList[0].value}
                  dataList={countryList}
                  onChanage={handleChangeCountry}
                />
              </div>
              <div>
                <button
                  disabled={submitting}
                  className='submit-button font-normal bg-primary1 mt-8  md:w-max h-auto py-2 md:py-4 md:px-6 text-[13px] md:text-18 rounded-[5px] text-white transition ease-in-out hover:scale-101 '
                  style={{width: "100%"}}
                  onClick={onSubmit}
                >
                  {submitting ? (
                    <CircularProgress size={16} />
                  ) : (
                    "Join waitlist"
                  )}
                </button>
              </div>
              <p className='text-[#707070] font-medium text-[18px] text-center my-5'>
                Already on the Waitlist?
              </p>
              <button
                className='submit-button bg-primary1 font-normal  md:w-max h-auto py-2 md:py-4 md:px-6 text-[13px] md:text-16 rounded-[5px] text-white transition ease-in-out hover:scale-101 '
                style={{
                  width: "100%",
                  background: "white",
                  color: "#240b3b",
                  border: "1px solid #240b3b",
                }}
                onClick={() => setVisibleLoginSaveModal(true)}
              >
                Login Now
              </button>
              {isJoined && (
                <div ref={isJoinedRef} className='joined-block mt-6'>
                  <img src={HubIcon4} alt='hubicon1' className='h-8 w-8 mb-2' />
                  <p className='text-[#2D2E2E] font-bold text-22 leading-8 mb-2 '>
                    You're Almost There!{" "}
                  </p>
                  <p className='text-[#2D2E2E] text-18 leading-6 font-normal w-full md:w-[100%] '>
                    We've just sent a confirmation email to your inbox. Click
                    the link provided to confirm your email and lock in your
                    place on the VenueBoost waitlist. Get ready to experience
                    the future of hospitality management with exclusive benefits
                    reserved for our early members.
                  </p>
                </div>
              )}
              <p className={"mt-3"}>
                By joining the waitlist, you agree to our &nbsp;
                <a
                  href='/terms-conditions'
                  style={{
                    color: "#607D8B",
                    textDecoration: "underline",
                    fontWeight: "bold",
                  }}
                >
                  Terms of Service
                </a>{" "}
                and &nbsp;
                <a
                  href='/privacy-policy'
                  style={{
                    color: "#607D8B",
                    textDecoration: "underline",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  &nbsp;Privacy Policy
                </a>
              </p>
            </div>
            <>
              <div className='w-full md:w-[60%] mt-6 flex flex-col '>
                <div className='mb-4'>
                  <h2 className='text-[32px] md:text-38 font-extrabold leading-[32px] md:leading-[32px] mb-6 my-0'>
                    Don't miss out on the unique perks of being an early
                    VenueBoost adopter:
                  </h2>
                </div>

                <div className='border-[1px] border-[#E0E0E0] rounded-[5px] py-6 pl-4 pr-8 md:pr-20'>
                  <div className='flex flex-row mb-6 items-center  '>
                    <img
                      src={CircleCheckSvg}
                      className='w-5 h-5 md:w-[25px] md:h-[25px] mr-4'
                    />
                    <p className='text-16 md:text-18 text-[#666666] font-medium leading-7'>
                      <b>Be the first</b> to try new features and updates.
                    </p>
                  </div>
                  <div className='flex flex-row mb-6 items-center  '>
                    <img
                      src={CircleCheckSvg}
                      className='w-5 h-5 md:w-[25px] md:h-[25px] mr-4'
                    />
                    <p className='text-16 md:text-18 text-[#666666] font-medium leading-7'>
                      <b>Enjoy enhanced</b> customer support and personalized
                      onboarding sessions.
                    </p>
                  </div>
                  <div className='flex flex-row mb-6 items-center'>
                    <img
                      src={CircleCheckSvg}
                      className='w-5 h-5 md:w-[25px] md:h-[25px] mr-4'
                    />
                    <p className='text-16 md:text-18 text-[#666666] font-medium leading-7'>
                      <b>Gain access</b> to exclusive resources, including
                      in-depth guides, industry insights, and webinars.
                    </p>
                  </div>
                  <div className='flex flex-row mb-6 items-center'>
                    <img
                      src={CircleCheckSvg}
                      className='w-5 h-5 md:w-[25px] md:h-[25px] mr-4'
                    />
                    <p className='text-16 md:text-18 text-[#666666] font-medium leading-7'>
                      <b>Have the chance</b> to be featured in our customer
                      spotlight on our website and social media platforms.
                    </p>
                  </div>
                </div>
                <div className='flex flex-col align-left'>
                  <p className='text-26 md:text-[42px] font-extrabold text-[#222222] mt-4 md:mt-10'>
                    <ul className='flex flex-row gap-4 '>
                      <a
                        href='https://www.facebook.com/venueboost'
                        target='_blank'
                      >
                        <span
                          id='hs_cos_wrapper_Footer_'
                          data-hs-cos-general-type='widget'
                          data-hs-cos-type='icon'
                        >
                          <svg
                            className='w-8 h-8 '
                            version='1.0'
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 264 512'
                            aria-hidden='true'
                          >
                            <g id='facebook-f1_layer'>
                              <path d='M76.7 512V283H0v-91h76.7v-71.7C76.7 42.4 124.3 0 193.8 0c33.3 0 61.9 2.5 70.2 3.6V85h-48.2c-37.8 0-45.1 18-45.1 44.3V192H256l-11.7 91h-73.6v229'></path>
                            </g>
                          </svg>
                        </span>
                      </a>

                      <a
                        href='https://twitter.com/venueboostinc'
                        target='_blank'
                      >
                        <span
                          id='hs_cos_wrapper_Footer_'
                          data-hs-cos-general-type='widget'
                          data-hs-cos-type='icon'
                        >
                          <svg
                            className='w-8 h-8 '
                            version='1.0'
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 512 512'
                            aria-hidden='true'
                          >
                            <g id='twitter2_layer'>
                              <path d='M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z'></path>
                            </g>
                          </svg>
                        </span>
                      </a>

                      <a
                        href='https://www.youtube.com/@venueboostinc'
                        target='_blank'
                      >
                        <span
                          id='hs_cos_wrapper_Footer_'
                          data-hs-cos-general-type='widget'
                          data-hs-cos-type='icon'
                        >
                          <svg
                            className='w-8 h-8 '
                            version='1.0'
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 576 512'
                            aria-hidden='true'
                          >
                            <g id='youtube3_layer'>
                              <path d='M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z'></path>
                            </g>
                          </svg>
                        </span>
                      </a>

                      <a
                        href='https://www.linkedin.com/company/venue-boost-inc/'
                        target='_blank'
                      >
                        <span
                          id='hs_cos_wrapper_Footer_'
                          data-hs-cos-general-type='widget'
                          data-hs-cos-type='icon'
                        >
                          <svg
                            className='w-8 h-8 '
                            version='1.0'
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 448 512'
                            aria-hidden='true'
                          >
                            <g id='linkedin-in4_layer'>
                              <path d='M100.3 480H7.4V180.9h92.9V480zM53.8 140.1C24.1 140.1 0 115.5 0 85.8 0 56.1 24.1 32 53.8 32c29.7 0 53.8 24.1 53.8 53.8 0 29.7-24.1 54.3-53.8 54.3zM448 480h-92.7V334.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V480h-92.8V180.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V480z'></path>
                            </g>
                          </svg>
                        </span>
                      </a>

                      <a
                        href='https://www.instagram.com/venueboost.io?igshid=OGQ5ZDc2ODk2ZA=='
                        target='_blank'
                      >
                        <span
                          id='hs_cos_wrapper_Footer_'
                          data-hs-cos-general-type='widget'
                          data-hs-cos-type='icon'
                        >
                          <svg
                            className='w-8 h-8 '
                            version='1.0'
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 448 512'
                            aria-hidden='true'
                          >
                            <g id='instagram5_layer'>
                              <path d='M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z'></path>
                            </g>
                          </svg>
                        </span>
                      </a>
                    </ul>
                  </p>
                  <div className='border-[#240b3b] border-[2px] md:border-[3px] px-[18px] md:px-[44px] rounded-[1.5px] my-4 md:my-8 '></div>
                  <p className='text-[#666A73] text-14 md:text-20 w-[100%] text-center'>
                    <div className='w-full lg:w-[80%] flex md:flex-row flex-col md:flex-wrap text-center md:text-left justify-center md:align-top md:justify-start text-18 '>
                      <a href='/terms-conditions' className='mr-5'>
                        {t("footer.terms")}
                      </a>
                      <a href='/privacy-policy' className='mr-5'>
                        {t("footer.privacy_policy")}
                      </a>
                      <a href='/cookie-notice' className='mr-5'>
                        {t("footer.cookie_notice")}
                      </a>
                      <p className='my-5 text-center'>{t("footer.address")}</p>
                    </div>
                  </p>
                </div>
              </div>
            </>
          </div>
        </div>
      </div>

      <Modal
        centered
        open={visibleLoginModal}
        onCancel={() => setVisibleLoginSaveModal(false)}
        onOk={() => waitlistVerifyEmail()}
        maskClosable={false}
        okText={"Verify"}
        okButtonProps={{
          style: {backgroundColor: "#240b3b", color: "white", height: "40px"},
        }}
        cancelButtonProps={{
          style: {
            backgroundColor: "transparent",
            color: "#240b3b",
            borderColor: "#CBD5E1",
            height: "40px",
          },
        }}
        bodyStyle={{height: "200px"}}
        width={450}
      >
        <div
          className={
            "flex flex-col py-4 w-full h-max md:h-[450px] overflow-auto gap-5"
          }
        >
          <span style={{fontSize: "16px"}}>
            Please enter the email address you used to join the waitlist. We'll
            send you a verification email to enable your login access
          </span>
          <FormTextInput
            name='verify_email'
            type={"email"}
            placeholder='Email *'
            value={emailVerification || ""}
            onChange={handleVerifyChangeEmail}
          />
        </div>
      </Modal>
    </div>
  );
};

export default WaitlistMarketing;
