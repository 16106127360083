import React, { useEffect, useState } from "react";

import "./index.css";
import IconUp from "../../assets/svgs/drop-up.svg";
import IconDown from "../../assets/svgs/drop-down.svg";

const Dropdown = ({ title, content, status = false, onAction = () => {} }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    setIsCollapsed(status);
  }, [status]);

  return (
    <div
      className={`flex cursor-pointer flex-col w-full  my-2 px-6 py-[18px]  ${
        isCollapsed ? "dropdown-opened bg-[#D069FF] text-black" : "dropdown-closed  bg-transparent border-b border-[#505050] text-white"
      }`}
      onClick={() => onAction()}
    >
      <div className={` flex flex-row justify-between  `}>
        <span className={`text-16 md:text-22 font-extrabold `}>{title}</span>
        <img src={isCollapsed ? IconUp : IconDown} className="!fill-white" />
      </div>
      {
        <div className={` ${isCollapsed ? "block" : "hidden"} mt-4`}>
          <span className="text-14 md:text-18 text-black font-medium ">
            {content}
          </span>
        </div>
      }
    </div>
  );
};

export default Dropdown;
