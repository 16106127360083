import React from "react";
import { Modal } from "antd";
import { modalButtonProps } from "../../../utils/common";
import "../index.css";

const QuizTermsCTA = ({ handleClose, open }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      onCancel={handleClose}
      {...modalButtonProps}
      cancelText="Close"
      className="freeJourneyModal modal-props"
      width={500}
      maskClosable={false}
      onOk={handleClose}
      centered
    >
      <>
        <p className="text-[#333333] text-20 font-extrabold leading-[22px]">
          Quiz Participation Terms
        </p>

        <ul
          style={{
            fontSize: 15,
            color: "rgb(89, 89, 89)",
            paddingLeft: "15px",
            listStyle: "auto",
          }}
          className="flex flex-col gap-1 mt-3"
        >
          <li>
            Follow our simple process to redeem your quiz rewards: Navigate to
            ‘My Account,’ select ‘Rewards,’ and apply your credits towards any
            eligible VenueBoost service or plan.
          </li>
          <li>
            Your quiz rewards can be applied to any VenueBoost subscription
            plan, including upgrades or add-ons, enhancing your venue management
            experience.
          </li>
          <li>
            All quiz rewards are valid for 6 months from the date of issue,
            encouraging timely use to boost your VenueBoost journey.
          </li>
          <li>
            Rewards earned from the quiz are exclusively yours, meant to enhance
            your VenueBoost experience and cannot be shared or transferred.
          </li>
        </ul>
      </>
    </Modal>
  );
};

export default QuizTermsCTA;
