import React, { useEffect, useState } from "react";
import "./index.css";
import { getRegisterConfig } from "../../redux/actions/contactsales";
import { NotificationManager } from "react-notifications";
import { Seo } from "../../components/Seo";
import { useNavigate, useParams } from "react-router-dom";
import {
  verifyOnboardingLink,
  createCheckoutSession,
  confirmOnboarding,
  confirmTrialSubscription,
} from "../../redux/actions/onboarding";
import confirmed_success from "../../assets/svgs/confirmed_success_brand.svg";
import { featuresByIndustryAndPlan, pricingData } from "./data";
import StepOne from "./step-one";
import StepTwo from "./step-two";
import StepThree from "./step-three";
import StepFour from "./step-four";
import { transformArray } from "./common-functions";

const initialObj = { value: "", title: "Please select" };

const Onboarding = () => {
  const navigate = useNavigate();
  const { token } = useParams();

  const [billingMethod, setBillingMethod] = useState("monthly");
  const [data, setData] = useState({});

  const [allCountries, setAllCountries] = useState([]);
  const [countries, setCountries] = useState([initialObj]);
  const [states, setStates] = useState([initialObj]);
  const [cities, setCities] = useState([initialObj]);

  const [onboardingEmail, setOnboardingEmail] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [whichSelected, setWhichSelected] = useState("Discover");
  const [startingTrial1, setStartingTrial1] = useState(false);
  const [startingTrial2, setStartingTrial2] = useState(false);
  const [startingTrial3, setStartingTrial3] = useState(false);

  const [step, setStep] = useState(1);
  const [venueType, setVenueType] = useState("food");

  const [suggestedPlan, setSuggestedPlan] = useState("");
  const [onboardingPricingPlans, setOnboardingPricingPlans] = useState([]);
  const [suggestedPlanFeatures, setSuggestedPlanFeatures] = useState([]);

  const currentPricing = pricingData[venueType][billingMethod];

  // For the free plan
  const freePrice = currentPricing ? currentPricing.free : 0;
  const freeSubtitle = currentPricing ? currentPricing.freeSubtitle : 0;

  // For the launch plan
  const launchPrice = currentPricing ? currentPricing.launch : 0;
  const launchSubtitle = currentPricing ? currentPricing.launchSubtitle : "";

  // For the elevate plan
  const elevatePrice = currentPricing ? currentPricing.elevate : 0;
  const elevateSubtitle = currentPricing ? currentPricing.elevateSubtitle : "";

  // For the optimize plan
  const optimizePrice = currentPricing ? currentPricing.optimize : 0;
  const optimizeSubtitle = currentPricing
    ? currentPricing.optimizeSubtitle
    : "";

  useEffect(() => {
    checkOnboardingLink();
    loadRegisterConfig();

    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      confirmSubscription(query.get("session_id"));
    } else if (query.get("canceled")) {
      NotificationManager.error(
        "Subscription to trial not continued",
        "Error",
        3000
      );
    }
  }, []);

  useEffect(() => {
    if (!data?.restaurant_state && states.length > 0) {
      setData((prevData) => ({
        ...prevData,
        restaurant_state: states[0].value,
        state: states[0].value,
      }));

      const selectedState = allCountries
        .find((c) => c.id === country)
        ?.states.find((s) => s.id.toString() === states[0].value);

      if (selectedState && selectedState.cities.length > 0) {
        setCities(
          selectedState.cities.map((city) => ({
            value: city.id.toString(),
            title: city.name,
          }))
        );
      }
    }

    if (!data?.restaurant_city && cities.length > 0) {
      setData((prevData) => ({
        ...prevData,
        restaurant_city: cities[0].value,
        city: cities[0].value,
      }));
    }
  }, [
    data?.restaurant_state,
    states.length,
    cities.length,
    country,
    allCountries.length,
    data?.restaurant_city,
  ]);

  const loadRegisterConfig = () => {
    getRegisterConfig()
      .then((data) => {
        setAllCountries(data?.countries || []);

        setCountries([
          initialObj,
          {
            value: "USA",
            title: "USA",
          },
        ]);
        setStates(transformArray(data?.states) || []);
        setCities(transformArray(data?.cities) || []);
      })
      .catch(() => {});
  };

  const getPricingPlanId = (planName, billingMethod) => {
    const plan = onboardingPricingPlans.find((p) => p.name === planName);
    if (!plan) return null;

    const priceInfo = plan.prices.find((price) =>
      billingMethod === "monthly"
        ? price.recurring.interval === "month"
        : price.recurring.interval === "year"
    );

    return priceInfo ? priceInfo.stripe_id : null;
  };

  const onBillingMethodChange = (method) => {
    setBillingMethod(method);
  };

  const confirmSubscription = (session_id) => {
    confirmTrialSubscription({
      session_id: session_id,
    })
      .then((data) => {
        setStep(4);
        NotificationManager.success(
          "Your trial period has been activated successfully!",
          "Success",
          3000
        );
        const finalData = {
          email: data.email,
          freemium: false,
          session_id: session_id,
        };
        confirmOnboarding(finalData)
          .then((data) => {
            // do nothing
          })
          .catch((err) => {
            // do nothing
          });
      })
      .catch((error) => {
        const message = error.message || "Something went wrong!";
        NotificationManager.error(message, "Error", 3000);
      });
  };

  const checkOnboardingLink = () => {
    verifyOnboardingLink({ token })
      .then((data) => {
        setOnboardingEmail(data?.email);

        setData((prevData) => ({
          ...prevData,
          ...(data?.business_details || {}),
          ...(data?.interest_engagement || {}),
        }));

        if (data?.business_details?.address) {
          const address = data?.business_details?.address;

          setCountry(address.country_id);
          setState(address.state_id);
          setCity(address.city_id);

          setData((prevData) => ({
            ...prevData,
            restaurant_country: address.country,
            restaurant_state: address.state,
            restaurant_city: address.city,
            restaurant_zipcode: address.postcode,
          }));

          const selectedCountry = allCountries.find(
            (c) => c.id === address.country_id
          );
          if (selectedCountry) {
            setStates(transformArray(selectedCountry.states));

            // Populate cities for the selected state
            const selectedState = selectedCountry.states.find(
              (s) => s.id === address.state_id
            );
            if (selectedState) {
              setCities(
                selectedState.cities.map((city) => ({
                  value: city.id.toString(),
                  title: city.name,
                }))
              );
            }
          }
        }

        // Set other details
        if (data?.recommended_pricing_plan) {
          setSuggestedPlan(data.recommended_pricing_plan);
        }

        if (data?.onboarding_pricing_plans) {
          setOnboardingPricingPlans(data.onboarding_pricing_plans);
        }

        if (data?.industry) {
          setVenueType(data.industry);
        }

        // Determine the current step
        if (data?.current_onboarding_step) {
          const currentOnboardingStep = data.current_onboarding_step;
          let newStep = 1; // Default to step 1

          switch (currentOnboardingStep) {
            case "email_verified":
              newStep = 1;
              break;
            case "business_details":
              newStep = 2;
              break;
            case "interest_engagement":
              newStep = 3;
              const industryFeatures =
                featuresByIndustryAndPlan[data.industry] || {};
              const planFeatures =
                industryFeatures[data.recommended_pricing_plan] || [];
              setSuggestedPlanFeatures(planFeatures);
              break;
            case "subscription_plan_selection":
              newStep = 4;
              break;
            default:
              newStep = 1;
          }

          setStep(newStep);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          navigate("/");
        }, 2000);
      });
  };

  const radioStyle = {
    marginLeft: "20px",
  };

  const customRadioStyle = {
    width: "20px",
    height: "20px",
    cursor: "pointer",
  };

  const customCheckedRadioStyle = {};

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "restaurant_country") {
      const selectedCountry = allCountries.find(
        (country) => country.name === value
      );
      if (selectedCountry) {
        setCountry(selectedCountry.id);
        setStates(
          selectedCountry.states.map((state) => ({
            value: state.id.toString(),
            title: state.name,
          }))
        );
        // Clear state and city
        setState("");
        setCity("");
        setData((prevData) => ({
          ...prevData,
          restaurant_state: "",
          restaurant_city: "",
        }));
      }
    }
    // When state changes
    else if (name === "restaurant_state") {
      const selectedCountry = allCountries.find((c) => c.id === country);
      const selectedState = selectedCountry?.states.find(
        (state) => state.id.toString() === value
      );
      if (selectedState) {
        setState(selectedState.id);
        setCities(
          selectedState.cities.map((city) => ({
            value: city.id.toString(),
            title: city.name,
          }))
        );
        // Clear city
        setCity("");
        setData((prevData) => ({
          ...prevData,
          restaurant_city: "",
        }));
      }
    }
    // When city changes
    else if (name === "restaurant_city") {
      const selectedCountry = allCountries.find((c) => c.id === country);
      const selectedState = selectedCountry?.states.find((s) => s.id === state);
      const selectedCity = selectedState?.cities.find(
        (city) => city.id.toString() === value
      );
      if (selectedCity) {
        setCity(selectedCity.id);
      }
    }
  };

  const onStartTrial = (product) => {
    // Assuming 'billingMethod' is either "monthly" or "annual"
    const priceId = getPricingPlanId(product, billingMethod);

    const stripeData = {
      email: onboardingEmail,
      price_id: priceId,
    };

    if (product === "Launch") {
      setStartingTrial1(true);
    }

    if (product === "Elevate") {
      setStartingTrial2(true);
    }

    if (product === "Optimize") {
      setStartingTrial3(true);
    }

    createCheckoutSession(stripeData)
      .then((data) => {
        document.location.href = data?.url;

        if (product === "Launch") {
          setStartingTrial1(false);
        }

        if (product === "Elevate") {
          setStartingTrial2(false);
        }

        if (product === "Optimize") {
          setStartingTrial3(false);
        }
      })
      .catch((err) => {
        if (product === "Launch") {
          setStartingTrial1(false);
        }

        if (product === "Elevate") {
          setStartingTrial2(false);
        }

        if (product === "Optimize") {
          setStartingTrial3(false);
        }

        if (typeof err?.error === "string") {
          NotificationManager.error(err?.error);
        } else {
          const errorKeys = Object.keys(err?.error || {});
          if (errorKeys.length > 0) {
            const firstErrorKey = errorKeys[0];
            const firstErrorMessage = err?.error[firstErrorKey][0];
            NotificationManager.error(
              firstErrorMessage || "Something went wrong!"
            );
          } else {
            NotificationManager.error("Something went wrong!");
          }
        }

        throw new Error("An error occurred while subscribing with trial");
      });
  };

  return (
    <div className={"align-col-middle "}>
      <Seo title={"Get Started | VenueBoost"} />
      <div className=" w-[100vw] getstarted-bg">
        <div className="mx-auto max-w-screen-main px-4 sm:px-6 md:px-0 lg:px-0 ">
          <div className="flex md:flex-row justify-center flex-col pt-4 pb-11 h-full">
            <div className=" w-full md:w-[100%] bg-[#F9F7F7] border-[1px] border-[#E0E0E0] rounded-[13px] p-4 md:p-4 py-6 gap-y-4 gap-x-8">
              <div>
                {step === 1 && (
                  <StepOne
                    {...{
                      data,
                      handleChange,
                      cities,
                      countries,
                      states,
                      onboardingEmail,
                      setStep,
                      city,
                      country,
                      state,
                    }}
                  />
                )}
                {step === 2 && (
                  <StepTwo
                    {...{
                      customCheckedRadioStyle,
                      customRadioStyle,
                      data,
                      handleChange,
                      radioStyle,
                      setStep,
                      onboardingEmail,
                      setData,
                      setOnboardingPricingPlans,
                      setSuggestedPlan,
                      setSuggestedPlanFeatures,
                      setVenueType,
                    }}
                  />
                )}
                {step === 3 && (
                  <StepThree
                    {...{
                      billingMethod,
                      elevatePrice,
                      elevateSubtitle,
                      freePrice,
                      freeSubtitle,
                      launchPrice,
                      launchSubtitle,
                      onBillingMethodChange,
                      onStartTrial,
                      optimizePrice,
                      optimizeSubtitle,
                      startingTrial1,
                      startingTrial2,
                      startingTrial3,
                      suggestedPlan,
                      venueType,
                      suggestedPlanFeatures,
                      setStep,
                      setWhichSelected,
                      whichSelected,
                      onboardingEmail,
                    }}
                  />
                )}

                {step === 4 && <StepFour {...{ confirmed_success, setStep }} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
