import React, { useState } from "react";
import { Modal } from "antd";

import './index.css';
import playImg from '../../assets/images/resources/play.png'
import sportIcon from '../../assets/svgs/sport.svg';
import foodIcon from '../../assets/svgs/food.svg';
import accommodationIcon from '../../assets/svgs/accommodation.svg';
import retailIcon from '../../assets/svgs/retail-2.svg';
import featuresIcon from '../../assets/svgs/features.svg';
import startedIcon from '../../assets/svgs/started.svg';
import closeIcon from '../../assets/svgs/close_gray.svg';
import infoIcon from '../../assets/svgs/info-circle.png';

const Categories = [
  { icon: startedIcon, title: 'Getting Started', active: true },
  { icon: featuresIcon, title: 'Features', active: false },
  { icon: foodIcon, title: 'Food & Beverage', active: false },
  { icon: sportIcon, title: 'Entertainment Venues', active: false },
  { icon: accommodationIcon, title: 'Accommodation', active: false },
  { icon: retailIcon, title: 'Retail Management', active: false },
];

const GuideList = [
  {
    title: 'Setting Up Your VenueBoost Account',
    description: 'Learn VenueBoost hierarchy and how to organize your work into different levels.',
  },
  {
    title: 'Setting Up Your VenueBoost Account',
    description: 'Learn VenueBoost hierarchy and how to organize your work into different levels.',
  },
  {
    title: 'Setting Up Your VenueBoost Account',
    description: 'Learn VenueBoost hierarchy and how to organize your work into different levels.',
  },
  {
    title: 'Setting Up Your VenueBoost Account',
    description: 'Learn VenueBoost hierarchy and how to organize your work into different levels.',
  },
  {
    title: 'Setting Up Your VenueBoost Account',
    description: 'Learn VenueBoost hierarchy and how to organize your work into different levels.',
  },
  {
    title: 'Setting Up Your VenueBoost Account',
    description: 'Learn VenueBoost hierarchy and how to organize your work into different levels.',
  },
]

const FeaturesList = [
  {
    title: 'Setting Up Your VenueBoost Account',
    description: 'Learn VenueBoost hierarchy and how to organize your work into different levels.',
  },
  {
    title: 'Setting Up Your VenueBoost Account',
    description: 'Learn VenueBoost hierarchy and how to organize your work into different levels.',
  },
  {
    title: 'Setting Up Your VenueBoost Account',
    description: 'Learn VenueBoost hierarchy and how to organize your work into different levels.',
  },
  {
    title: 'Setting Up Your VenueBoost Account',
    description: 'Learn VenueBoost hierarchy and how to organize your work into different levels.',
  },
  {
    title: 'Setting Up Your VenueBoost Account',
    description: 'Learn VenueBoost hierarchy and how to organize your work into different levels.',
  },
  {
    title: 'Setting Up Your VenueBoost Account',
    description: 'Learn VenueBoost hierarchy and how to organize your work into different levels.',
  },
]

const FoodList = [
  {
    title: 'Setting Up Your VenueBoost Account',
    description: 'Learn VenueBoost hierarchy and how to organize your work into different levels.',
  },
  {
    title: 'Setting Up Your VenueBoost Account',
    description: 'Learn VenueBoost hierarchy and how to organize your work into different levels.',
  },
  {
    title: 'Setting Up Your VenueBoost Account',
    description: 'Learn VenueBoost hierarchy and how to organize your work into different levels.',
  },
  {
    title: 'Setting Up Your VenueBoost Account',
    description: 'Learn VenueBoost hierarchy and how to organize your work into different levels.',
  },
  {
    title: 'Setting Up Your VenueBoost Account',
    description: 'Learn VenueBoost hierarchy and how to organize your work into different levels.',
  },
  {
    title: 'Setting Up Your VenueBoost Account',
    description: 'Learn VenueBoost hierarchy and how to organize your work into different levels.',
  },
]

const EntertainmenList = [
  {
    title: 'Setting Up Your VenueBoost Account',
    description: 'Learn VenueBoost hierarchy and how to organize your work into different levels.',
  },
  {
    title: 'Setting Up Your VenueBoost Account',
    description: 'Learn VenueBoost hierarchy and how to organize your work into different levels.',
  },
  {
    title: 'Setting Up Your VenueBoost Account',
    description: 'Learn VenueBoost hierarchy and how to organize your work into different levels.',
  },
  {
    title: 'Setting Up Your VenueBoost Account',
    description: 'Learn VenueBoost hierarchy and how to organize your work into different levels.',
  },
  {
    title: 'Setting Up Your VenueBoost Account',
    description: 'Learn VenueBoost hierarchy and how to organize your work into different levels.',
  },
  {
    title: 'Setting Up Your VenueBoost Account',
    description: 'Learn VenueBoost hierarchy and how to organize your work into different levels.',
  },
]

const AccommodationList = [
  {
    title: 'Setting Up Your VenueBoost Account',
    description: 'Learn VenueBoost hierarchy and how to organize your work into different levels.',
  },
  {
    title: 'Setting Up Your VenueBoost Account',
    description: 'Learn VenueBoost hierarchy and how to organize your work into different levels.',
  },
  {
    title: 'Setting Up Your VenueBoost Account',
    description: 'Learn VenueBoost hierarchy and how to organize your work into different levels.',
  },
  {
    title: 'Setting Up Your VenueBoost Account',
    description: 'Learn VenueBoost hierarchy and how to organize your work into different levels.',
  },
  {
    title: 'Setting Up Your VenueBoost Account',
    description: 'Learn VenueBoost hierarchy and how to organize your work into different levels.',
  },
  {
    title: 'Setting Up Your VenueBoost Account',
    description: 'Learn VenueBoost hierarchy and how to organize your work into different levels.',
  },
]

const RetailList = [
  {
    title: 'Setting Up Your VenueBoost Account',
    description: 'Learn VenueBoost hierarchy and how to organize your work into different levels.',
  },
  {
    title: 'Setting Up Your VenueBoost Account',
    description: 'Learn VenueBoost hierarchy and how to organize your work into different levels.',
  },
  {
    title: 'Setting Up Your VenueBoost Account',
    description: 'Learn VenueBoost hierarchy and how to organize your work into different levels.',
  },
  {
    title: 'Setting Up Your VenueBoost Account',
    description: 'Learn VenueBoost hierarchy and how to organize your work into different levels.',
  },
  {
    title: 'Setting Up Your VenueBoost Account',
    description: 'Learn VenueBoost hierarchy and how to organize your work into different levels.',
  },
  {
    title: 'Setting Up Your VenueBoost Account',
    description: 'Learn VenueBoost hierarchy and how to organize your work into different levels.',
  },
]

const GettingStarted = () => {
  const [visible, setVisible] = useState(false);
  const [ selectedItem, setSelectedItem ] = useState(Categories[0]);

  
  return (
    <div className='flex flex-col w-full'>
      <div className="bg-[#F9F7F7]">
        <div className='flex flex-col items-center max-w-screen-main mx-auto py-16 gap-4 px-4 sm:px-6 md:px-8 lg:px-16 relative'>
          <span className="library-marker flex justify-center items-center h-[34px] px-6">
            Video Library
          </span>
          <span className="gradient-title font-extrabold text-48 leading-[60px]">
            Getting Started Guides
          </span> 
          <span className="text-xl font-medium">
            A collection of our favorite videos to help you get started, customize your setup, and rap about project management.
          </span>
        </div>
      </div>
      <div className="flex flex-col max-w-screen-main mx-auto pb-16 px-4 sm:px-6 md:px-8 lg:px-16 relative gap-8 -mt-12 w-full">
        <div className="flex flex-row justify-between w-full rounded-[20px] guide-header px-6 pt-6 bg-white overflow-x-auto">
          <div className="flex gap-5 w-full">
            {Categories.map((item, index) => (
              <div className="flex flex-col relative pb-6 min-w-[300px] group hover:cursor-pointer" onClick={() => setSelectedItem(item)}>
                <div key={index} className="flex flex-row items-center gap-4">
                  <img src={item.icon} alt="icon" />
                  <span className="text-xl font-bold">{item.title}</span>
                </div>
                <div className={`bottom-line absolute bottom-0 w-full h-1.5 rounded-t-3xl group-hover:bg-primary2 ${selectedItem.title === item.title && 'bg-primary2'}`}>
                </div>
              </div>
            ))}
          </div>

        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-12 gap-y-14">
          {selectedItem.title === "Getting Started" && GuideList.map((item, index) => (
            <div key={index} className="flex flex-col p-6 guide-item rounded-[20px] gap-2">
              <img src={playImg} alt="play" className="rounded-2xl" />
              <span className="text-xl font-extrabold">{item.title}</span>
              <span className="text-sm">{item.description}</span>
              <div onClick={() => setVisible(true)}>
                <span className="library-marker w-max py-1 px-4 text-sm font-semibold mt-4 cursor-pointer">
                  Check Steps →
                </span>
              </div>
            </div>
          ))}

        {selectedItem.title === "Features" && FeaturesList.map((item, index) => (
            <div key={index} className="flex flex-col p-6 guide-item rounded-[20px] gap-2">
              <img src={playImg} alt="play" className="rounded-2xl" />
              <span className="text-xl font-extrabold">{item.title}</span>
              <span className="text-sm">{item.description}</span>
              <div onClick={() => setVisible(true)}>
                <span className="library-marker w-max py-1 px-4 text-sm font-semibold mt-4 cursor-pointer">
                  Check Steps →
                </span>
              </div>
            </div>
          ))}

          {selectedItem.title === "Food & Beverage" && FoodList.map((item, index) => (
            <div key={index} className="flex flex-col p-6 guide-item rounded-[20px] gap-2">
              <img src={playImg} alt="play" className="rounded-2xl" />
              <span className="text-xl font-extrabold">{item.title}</span>
              <span className="text-sm">{item.description}</span>
              <div onClick={() => setVisible(true)}>
                <span className="library-marker w-max py-1 px-4 text-sm font-semibold mt-4 cursor-pointer">
                  Check Steps →
                </span>
              </div>
            </div>
          ))}

          {selectedItem.title === "Entertainment Venues" && EntertainmenList.map((item, index) => (
            <div key={index} className="flex flex-col p-6 guide-item rounded-[20px] gap-2">
              <img src={playImg} alt="play" className="rounded-2xl" />
              <span className="text-xl font-extrabold">{item.title}</span>
              <span className="text-sm">{item.description}</span>
              <div onClick={() => setVisible(true)}>
                <span className="library-marker w-max py-1 px-4 text-sm font-semibold mt-4 cursor-pointer">
                  Check Steps →
                </span>
              </div>
            </div>
          ))}

          {selectedItem.title === "Accommodation" && AccommodationList.map((item, index) => (
            <div key={index} className="flex flex-col p-6 guide-item rounded-[20px] gap-2">
              <img src={playImg} alt="play" className="rounded-2xl" />
              <span className="text-xl font-extrabold">{item.title}</span>
              <span className="text-sm">{item.description}</span>
              <div onClick={() => setVisible(true)}>
                <span className="library-marker w-max py-1 px-4 text-sm font-semibold mt-4 cursor-pointer">
                  Check Steps →
                </span>
              </div>
            </div>
          ))}

          {selectedItem.title === "Retail Management" && RetailList.map((item, index) => (
            <div key={index} className="flex flex-col p-6 guide-item rounded-[20px] gap-2">
              <img src={playImg} alt="play" className="rounded-2xl" />
              <span className="text-xl font-extrabold">{item.title}</span>
              <span className="text-sm">{item.description}</span>
              <div onClick={() => setVisible(true)}>
                <span className="library-marker w-max py-1 px-4 text-sm font-semibold mt-4 cursor-pointer">
                  Check Steps →
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal
        centered
        open={visible}
        onCancel={() => setVisible(false)}
        maskClosable={false}
        closable={false}
        footer={null}
        className='overflow-auto'
        width={900}
      >
        <div className="flex flex-col p-6 gap-4">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col items-start gap-4">
              <span className="text-2xl font-semibold">
                1. Setting Up Your VenueBoost Account
              </span>
              <span className="text-base font-medium text-[#6B7280] max-w-[510px]">
                Here are some industry-specific best practices that you can include in the VenueBoost resources section:
              </span>
            </div>
            <div
              className="bg-[#F2F5FF] flex flex-row items-center px-3 py-2 gap-1.5 rounded-md h-max cursor-pointer"
              onClick={() => setVisible(false)}
            >
              <img src={closeIcon} alt="icon" />
              <span className="text-[#909090] font-semibold">Close</span>
            </div>
          </div>
          <div className="flex flex-col border-2 border-[#CCCCCC] rounded-xl">
            <div className="flex flex-col p-5 border-b-2 border-[#CCCCCC]">
              <span className="font-semibold text-[#6B7280] text-base">Step 1</span>
              <span className="text-base font-semibold text-primary1">
                Navigate to the VenueBoost website and click on the "Sign Up" button.
              </span>
            </div>
            <div className="flex flex-col p-5 border-b-2 border-[#CCCCCC]">
              <span className="font-semibold text-[#6B7280] text-base">Step 2</span>
              <span className="text-base font-semibold text-primary1">
                Fill in your venue's details, including name, type, location, and contact information.
              </span>
            </div>
            <div className="flex flex-col p-5 border-b-2 border-[#CCCCCC]">
              <span className="font-semibold text-[#6B7280] text-base">Step 3</span>
              <span className="text-base font-semibold text-primary1">
                Create your account by providing your email address and choosing a secure password.
              </span>
            </div>
            <div className="flex flex-col p-5 border-b-2 border-[#CCCCCC]">
              <span className="font-semibold text-[#6B7280] text-base">Step 4</span>
              <span className="text-base font-semibold text-primary1">
                Verify your email address by clicking on the confirmation link sent to your inbox.
              </span>
            </div>
            <div className="flex flex-col p-5">
              <span className="font-semibold text-[#6B7280] text-base">Step 5</span>
              <span className="text-base font-semibold text-primary1">
                Log in to your VenueBoost account and complete your venue profile.
              </span>
            </div>
          </div>
          <div className="flex flex-row items-center gap-2">
            <img src={infoIcon} alt="icon" className="w-6 h-6" />
            <span className="font-semibold">Open Video Tutorial</span>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default GettingStarted;
