import React from "react";
import "./index.css";
import "react-multi-carousel/lib/styles.css";

import BlogCard from "../../components/BlogCard";
import {Seo} from "../../components/Seo";
import HomeIntegration from "../home/integration";
import {motion} from "framer-motion";

import BusinessWorldwide from "../home/business-worldwide";
import FreePlanCard from "../../components/FreePlanCard";
import ConnectSocial from "./connect-social";
import CustomCarouselAbout from "../../components/CustomCarouselAbout";
import BlogImg15 from "../../assets/images/blog/blog-15.webp";
import BlogImg16 from "../../assets/images/blog/blog-16.webp";
import BlogImg17 from "../../assets/images/blog/blog-17.webp";

const blogList = [
  {
    id: 14,
    title:
      "Leveraging Technology\n" +
      "to Minimize Waste in the Hospitality Industry\n",
    category: "Pro Tips and Best Practices\n",
    slug: "leveraging-technology-to-minimize-waste-in-the-hospitality-industry",
    date: "January 30, 2024",
    image: BlogImg15,
  },
  {
    id: 15,
    title: "5 Essential Tips for Small Business Owners to Grow Their Business",
    category: "Pro Tips and Best Practices\n",
    slug: "5-essential-tips-small-business-owners-grow-their-business",
    date: "February 3, 2024",
    image: BlogImg16,
  },
  {
    id: 16,
    title: "The Ultimate Hospitality Guide to Valentine’s Day",
    category: "Pro Tips and Best Practices\n",
    slug: "ultimate-hospitality-guide-valentines-day-venueboost",
    date: "February 6, 2024",
    image: BlogImg17,
  },
];

const FeatureBlock = ({iconSrc, title, description}) => (
  <motion.div
    className='flex-1'
    initial='offscreen'
    whileInView='onscreen'
    viewport={{amount: 0.8, once: true}}
  >
    <motion.div
      className='h-full flex'
      variants={{
        offscreen: {opacity: 0, y: 100},
        onscreen: {
          y: 0,
          opacity: 1,
          transition: {
            type: "spring",
            bounce: 0,
            duration: 1,
          },
        },
      }}
    >
      <div className='flex flex-col items-center  border-[1px] rounded-[13px] border-[#E0E0E0] bg-white p-11 md:p-6'>
        <img src={iconSrc} className=' w-[50px] h-[50px] mb-8 mt-5' />
        <p className='text-[#333333] text-18 md:text-22 font-bold text-center mb-5'>
          {title}
        </p>
        <p className='text-[#666666] font-normal text-center text-16'>
          {description}
        </p>
      </div>
    </motion.div>
  </motion.div>
);

const AboutUs = () => {
  const currentUrl = "https://venueboost.io/about";
  const seoTitle = "Discover VenueBoost: Transforming Venue Management";
  const seoDescription =
    "Learn how VenueBoost revolutionizes venue management. Tailored strategies, innovative solutions, and personalized support drive growth and success for businesses in the Food & Beverage, Accommodation, Retail, and Entertainment sectors.";

  return (
    <div className='flex flex-col align-col-middle view-terms'>
      <Seo
        title={seoTitle + " | VenueBoost"}
        description={seoDescription}
        url={currentUrl}
      />

      <div className='align-col-middle'>
        <div className='w-[100vw] bg-[#f3f3f3]'>
          <div className='max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 '>
            <div className='py-16 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 md:gap-8'>
              {/*<div className="hidden md:block w-[900px] h-[900px] absolute left-[400px] top-[100px]" style={{background: 'radial-gradient(circle at center, rgba(227, 157, 255, 0.7), transparent, transparent)'}}></div>*/}

              <div className=' col-span-1 md:col-span-2 flex flex-col gap-5 z-10'>
                <div className='text-center md:text-left text-[32px] md:text-[64px] font-extrabold leading-[40px] md:leading-[76px] text-[#312A37] xl:w-[595px]'>
                  Simplify.Optimize. Grow.
                </div>
                <div className=' text-[#666A73] text-center md:text-left font-medium text-16 leading-[23px] md:text-20 md:leading-[28px] z-10'>
                  Discover VenueBoost: Your Key to Streamlined Venue Management.
                  We specialize in transforming venue operations with our
                  innovative platform, tailored for the unique needs of Food &
                  Beverage, Accommodation, Retail, and Entertainment sectors.
                  Driven by technology and a commitment to operational
                  excellence, VenueBoost equips you with the tools to elevate
                  guest experiences and accelerate business growth. Join us on a
                  journey to success and see why venues everywhere are choosing
                  VenueBoost for a smarter, more efficient future.”
                </div>
                <a
                  className='flex flex-row gap-4 items-center justify-center md:justify-start'
                  target='_blank'
                  href='https://www.youtube.com/watch?v=N9NqhQmEsuc'
                >
                  <div className='w-[50px] h-[50px] bg-gradient-to-r from-[#280B3B] via-[#5D1C82] to-[#BC14FE] flex justify-center items-center rounded-full'>
                    <svg
                      width='12'
                      height='14'
                      viewBox='0 0 12 14'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g clip-path='url(#clip0_6_7744)'>
                        <path
                          d='M0.0844727 2.737C0.0844727 1.13961 1.86476 0.186834 3.19387 1.0729L9.58832 5.33587C10.7758 6.12751 10.7758 7.87243 9.58832 8.66407L3.19387 12.9271C1.86476 13.8131 0.0844727 12.8604 0.0844727 11.263V2.737Z'
                          fill='white'
                        />
                      </g>
                    </svg>
                  </div>

                  <div className=' mb-4 md:mb-0 text-14 font-bold text-[#312A37] leading-[20px]'>
                    Watch the video to get to
                    <br />
                    know us better.
                  </div>
                </a>
              </div>

              <div className='flex flex-col rounded-[20px] bg-gradient-to-r from-[#280B3B] via-[#5D1C82] to-[#BC14FE] pb-[2px] z-10'>
                <div className='flex flex-col rounded-[20px] bg-white w-full h-full px-[35px] pt-[12px] pb-8 gap-0 md:gap-4'>
                  <div className=' text-[#292D34] text-24 md:text-[32px] font-extrabold leading-[58px]'>
                    Our Mission
                  </div>
                  <div className=' text-[#6A6A6A] text-sm md:text-18 font-medium leading-[23px]'>
                    At VenueBoost, we're dedicated to revolutionizing venue
                    management through personalized support and innovative
                    solutions. Our mission is to understand the unique story of
                    each venue, offering tailored strategies that drive growth
                    and success. With a focus on bespoke service over
                    one-size-fits-all, we're here to upgrade your venue to its
                    fullest potential. Welcome to the future of venue
                    management—crafted just for you.
                  </div>
                </div>
              </div>

              <div className='flex flex-col rounded-[20px] bg-gradient-to-r from-[#280B3B] via-[#5D1C82] to-[#BC14FE] pb-[2px] z-10'>
                <div className='flex flex-col rounded-[20px] bg-white w-full h-full px-[35px] pt-[12px] pb-8 gap-0 md:gap-4'>
                  <div className=' text-[#292D34] text-24 md:text-[32px] font-extrabold leading-[58px]'>
                    Our Vision
                  </div>
                  <div className=' text-[#6A6A6A] text-sm md:text-18 font-medium leading-[23px]'>
                    We envision a future where technology effortlessly empowers
                    businesses to captivate customers, foster loyalty, and drive
                    sustainable success, all without the need for extensive
                    technical expertise. It's a future where venues are equipped
                    with intuitive tools crafted precisely for their unique
                    challenges and opportunities. This is the future VenueBoost
                    is dedicated to creating, ensuring every venue thrives with
                    solutions designed just for them.
                  </div>
                </div>
              </div>

              <div className='flex flex-col rounded-[20px] bg-gradient-to-r from-[#280B3B] via-[#5D1C82] to-[#BC14FE] pb-[2px] z-10'>
                <div className='flex flex-col rounded-[20px] bg-white w-full h-full px-[35px] pt-[12px] pb-8 gap-0 md:gap-4'>
                  <div className=' text-[#292D34] text-24 md:text-[32px] font-extrabold leading-[58px]'>
                    Our Advantages
                  </div>
                  <div className=' text-[#6A6A6A] text-sm md:text-18 font-medium leading-[23px]'>
                    VenueBoost excels in delivering solutions that effortlessly
                    blend technology with the unique demands of your business.
                    Gain access to intuitive platforms that not only simplify
                    operations but also ensure a seamless integration into your
                    existing workflows. We provide the keys to unlock a world of
                    efficiency.
                  </div>
                </div>
              </div>

              <div className='flex flex-col rounded-[20px] bg-gradient-to-r from-[#280B3B] via-[#5D1C82] to-[#BC14FE] pb-[2px] z-10'>
                <div className='flex flex-col rounded-[20px] bg-white w-full h-full px-[35px] pt-[12px] pb-8 gap-0 md:gap-4'>
                  <div className=' text-[#292D34] text-24 md:text-[32px] font-extrabold leading-[58px]'>
                    Our Success
                  </div>
                  <div className=' text-[#6A6A6A] text-sm md:text-18 font-medium leading-[23px]'>
                    Our journey is defined by the achievements of the businesses
                    we support. Through innovation and a keen understanding of
                    venue management challenges, we've helped clients achieve
                    remarkable efficiency and customer satisfaction. Our success
                    stories underscore our commitment to excellence, reflecting
                    the trust and partnership we share with venues worldwide.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <HomeIntegration />
      <BusinessWorldwide />

      <div className='2xl:w-[1435px] w-full'>
        <CustomCarouselAbout />
      </div>

      <div className=' align-col-middle'>
        <div className='w-[100vw] bg-[#f3f3f3]'>
          <div className='max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 '>
            <div
              style={{marginBottom: 0}}
              className='flex align-middle flex-col py-12'
            >
              <p className='text-26 md:text-[42px] leading-[28px] md:leading-[50px] font-extrabold text-[#240b3b] text-center mb-[15px]'>
                VenueBoost Blog: Elevating Hospitality Experiences
              </p>
              <p className='text-14 md:text-20 leading-5 md:leading-[27px] font-medium text-center text-[#666A73] w-[80%] md:w-full'>
                Discover the Latest Insights, Tips, and Trends in the
                Hospitality Industry
              </p>
              <div className='grid grid-cols-1 md:grid-cols-3 gap-[38px] mt-9 md:mt-12'>
                {blogList.map((item, index) => (
                  <BlogCard
                    img={item.image}
                    tag={item.category}
                    title={item.title}
                    date={item.date}
                    slug={item.slug}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>{" "}
      </div>

      <div className='w-full flex justify-center items-center'>
        <ConnectSocial />
      </div>

      <div className='flex flex-col pb-12'>
        <FreePlanCard />
      </div>
    </div>
  );
};

export default AboutUs;
