import React, {useEffect, useState} from "react";
import ProductCard from "../../components/ProductCard";
// import BannerImg from "../../assets/images/sitemap.png";
import {Pagination, Select} from "antd";
import ProductUpdateImg1 from "../../assets/images/productupdate/venueboost_software_release.webp";

import "./index.css";
import {Seo} from "../../components/Seo";
import {isMobile} from "react-device-detect";

const ProductUpdates = () => {
  const data = [
    {
      tagId: 0,
      date: "June 20, 2023",
      title:
        "Introducing VenueBoost: The Ultimate Solution for Streamlining Hospitality Operations",
      desc: "VenueBoost empowers businesses across various sectors, including Restaurants, Bars, Bistros, Pub & Clubs, Golf Courses, Gyms, Sports & Entertainment, Cafes, and Hotels.",
      img: ProductUpdateImg1,
      slug: "introducing-venueboost-the-ultimate-solution-for-streamlining-hospitality-operations",
    },
  ];

  const tags = [
    {
      value: "Software Release",
      label: "Software Release",
      title: "Software Release",
      url: "/product-updates",
    },
  ];

  const [tag, setTag] = useState(0);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(window.innerWidth > 640 ? 15 : 5);
  const onPaginationChange = (page, pageSize) => {
    setMinValue((page - 1) * pageSize);
    setMaxValue(page * pageSize);
  };

  return (
    <div className='flex flex-col align-col-middle view-terms'>
      <Seo title={"Product Updates | VenueBoost"} />
      <div className='flex flex-col align-middle'>
        <div className='flex flex-col align-middle'>
          <div className='mx-auto flex items-center justify-center mt-16 mb-8'>
            <div className='h-[34px] w-[180px] rounded-md bg-gradient-to-r from-[#240B3B] via-[#3D1E59] to-[#BC14FE] p-[1px]'>
              <div className='flex h-full w-full items-center justify-center bg-white rounded-md'>
                <h1 className='text-xl font-normal text-[#454545]'>
                  What's new
                </h1>
              </div>
            </div>
          </div>

          <div className='text-[#252525] text-[36px] md:text-[48px] font-normal text-center leading-[48px]'>
            Latest Updates
          </div>
          <h1
            style={{paddingBottom: "15px"}}
            className='text-[36px] md:text-[48px] font-extrabold text-center text-transparent bg-clip-text leading-[48px] bg-gradient-to-br from-[#240B3B] via-[#3D1E59] via-[#8319B4] to-[#BC14FE]'
          >
            Recent Developments at VenueBoost
          </h1>

          <p className='text-[#252525] text-16 md:text-18 leading-5 md:leading-7 font-medium text-center w-[80%] xl:w-[742px] mt-4 mb-6 '>
            Keep up with the newest enhancements and features from VenueBoost.
            Stay ahead in the game by leveraging the latest innovations,
            ensuring your business maximizes its potential and remains at the
            cutting edge.{" "}
          </p>
        </div>
      </div>
      <div className='w-full mt-4 md:mt-10'>
        {/* <h1 className="text-[#373941] text-[32px] leading-[44px] font-semibold mb-2 md:mb-9">
          Filters
        </h1> */}
        <div
          className={
            isMobile
              ? "flex flex-col gap-[10px] md:gap-5 mt-10 mb-10"
              : "flex flex-wrap gap-[10px] md:gap-5 mt-10 mb-10"
          }
        >
          <a
            href={"#"}
            className={`text-14  md:text-16 leading-4 px-[27px] py-[15px] h-[48px] rounded-md text-white bg-gradient-to-r from-[#240B3B] via-[#601B86] to-[#BC14FE]`}
          >
            All
          </a>

          <div className='h-[48px] w-[100%] md:w-[180px] rounded-md bg-gradient-to-r from-[#240B3B] via-[#3D1E59] to-[#BC14FE] p-[1px]'>
            <Select
              defaultValue='Select topic'
              className='w-full h-full [&>div]:!h-full [&>div]:!items-center [&>div>span:last-child]:!text-[20px]'
              options={tags}
            />
          </div>
        </div>
      </div>

      <div className='grid grid-cols-1 sm:grid-cols-3 gap-x-[62px] gap-y-[29px] md:gap-y-[37px]'>
        {data &&
          data.length > 0 &&
          data
            .slice(minValue, maxValue)
            .map((card, index) => (
              <ProductCard
                key={index}
                img={card.img}
                tag={tags[card.tagId].title}
                title={card.title}
                desc={card.desc}
                slug={card.slug}
              />
            ))}
      </div>
      <div className='mx-auto my-[56px] w-full align-middle blog-pagination'>
        <Pagination
          total={data.length}
          defaultCurrent={1}
          pageSize={window.innerWidth > 640 ? 15 : 5}
          onChange={onPaginationChange}
        />
      </div>
      {/* <div className="w-[100vw]  relative translate-y-[1px]">
        <img src={BannerImg} className="min-w-full h-[240px] object-cover" />
        <div className="absolute top-0  w-full h-full align-middle flex flex-col md:flex-row">
          <h1 className="text-white text-24 md:text-[48px] leading-[41px] font-extrabold text-center md:mr-[95px] mb-8 md:mb-0 px-[25%]  md:px-0">
            Elevate Your Business with VenueBoost
          </h1>
          <a
            className="text-[#240b3b] bg-white rounded-md text-16 md:text-18 leading-8 font-extrabold text-center px-9 md:px-20 py-1 md:py-[10px] whitespace-nowrap"
            href="/get-started"
          >
            ACCESS FREE PLAN
          </a>
        </div>
      </div> */}
    </div>
  );
};

export default ProductUpdates;
