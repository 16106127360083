import React from "react";

const ToolCard = ({ img, tag, title, date, slug }) => {
  return (
    <div className="relative flex flex-col rounded-[13px] border-[1px] border-[#E0E0E0] bg-white blog-card overflow-hidden pb-[60px]">
      <img
        className="w-full h-[160px] md:h-[284px] object-cover"
        src={img}
        alt="aboutus"
      />
      <div className="py-6 md:py-9 px-5 md:px-6  ">
        {/* <div> */}

        <p className="text-[#717484] text-14  md:text-16 font-bold leading-5  inline  mr-5">
          {tag}
        </p>
        <p className="text-[#717484] text-14 leading-5 font-normal mt-0 md:mt-6 py-2 md:py-0 inline">
          {date}
        </p>
        {/* </div> */}

        <p className="text-[#373941] text-20  md:text-24  font-semibold leading-7 mt-4">
          {title}
        </p>

        <a
          className="absolute bottom-7 left-6 text-12 md:text-14 leading-4 text-[#373941] font-semibold py-2 md:py-3 pl-4 pr-7 border-[#DEDFE3] border-[1px] rounded-[60px] bg-white"
          href={`/resources/tools-guides/${slug}`}
        >
          Read the guide <span> →</span>
        </a>
      </div>
    </div>
  );
};

export default ToolCard;
