import React, { useState } from 'react';
import icon from '../../assets/svgs/price_tag.svg';

const Enroll = (props) => {
    const {
        completed = false,
        title = null,
        description = null,
        onSubmit = () => { }
    } = props;
    return (
        <div className="max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 w-full my-0">
            <div className="flex flex-col justify-between border-[#E0E0E0] rounded-[13px] border-[1px] p-8 text-start">
                <div className='flex flex-col w-full justify-center items-center'>
                    <img
                        src={icon}
                        alt="icon"
                        className="h-8 w-8 mb-5"
                    />
                    <p className="text-[#2D2E2E] font-bold text-22 leading-8 mb-2">
                        {title ?? "Plato Lounge Bar Loyalty Program"}
                    </p>
                    <p className="text-[#2D2E2E] text-16 text-center leading-6 font-normal w-full md:w-[50%] ">
                        {description ?? "Our expert team are dedicated to helping you enjoy better golf. We offer an extensive range of services in our newly refurbished Golf Studio equipped with TrackMan Launch Monitor."}
                        </p>
                </div>
                <div className="flex flex-row justify-center items-center">
                  <button
                    className={`submit-button font-normal ${completed ? 'bg-gray-600' : 'bg-primary1'} min-w-[30%] mt-8 md:w-max h-auto py-2 md:py-3 md:px-6 text-[13px] md:text-16 rounded-[5px] text-white transition ease-in-out hover:scale-101`}
                    onClick={onSubmit} disabled={completed}
                  >
                    {completed ? 'Enrolled'  : 'Enroll'}
                  </button>
                </div>

            </div>
        </div>
    )
};
export default Enroll
