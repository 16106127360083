import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import {
  addCartBillingDetails,
  addOrderSucessDetails,
  clearCheckoutForm,
  removeFromCartBillingDetails,
} from "../../../redux/actions/cart";
import { NotificationManager } from "react-notifications";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeCheckoutForm from "../../../components/StripeCheckout";
import WhiteLabelService from "../../../services/whiteLabelService";
import OrderSuccessScreen from "../order-sucess";
import Enroll from "../../../components/venue/enroll";
import { STRIPE_PUB_KEY } from "../../../constants/config";
import { identifyVenue, trackEvent } from "../../../utils/mixpanelUtil";
import "./index.css";
import CheckoutShipping from "./shipping/shipping";

const stripePromise = loadStripe(STRIPE_PUB_KEY);

const CheckoutScreen = ({ setActiveStep }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { venue_app_key } = useSelector((state) => state.cart);
  const [clientSecret, setClientSecret] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [canEnroll, setCanEnroll] = useState(false);
  const [enrolledCompleted, setEnrolledCompleted] = useState(false);
  const [enrollEmail, setEnrollEmail] = useState(null);
  const [appKey, setAppKey] = useState("");

  // const handlePaymentMethodChange = async (e) => {
  //   const method = e.target.value;
  //   setPaymentMethod(method);

  //   if (method === "Card") {
  //     try {
  //       setIsLoading(true); // indicate loading

  //       const stripeTotal = Math.round(total * 100);

  //       const payload = {
  //         amount: stripeTotal,
  //         description: "Retail Order Payment",
  //       };
  //       const response = await WhiteLabelService.createPaymentIntent(
  //         payload,
  //         appKey
  //       );
  //       setClientSecret(response.data.client_secret);
  //     } catch (err) {
  //       NotificationManager.error(
  //         err?.error ?? "Something went wrong while setting up payment!"
  //       );
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   }
  // };

  useEffect(() => {
    window.scrollTo(0, 0);
    // Create PaymentIntent as soon as the page loads
    setAppKey(venue_app_key);
  }, []);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  // const onSubmit = (values, paymentIntentId) => {
  //   window.scrollTo(0, 0);

  //   dispatch(addCartBillingDetails(values));

  //   const orderProducts = products.map((item) => {
  //     return {
  //       product_id: item.id,
  //       product_total_price: parseFloat(item.price),
  //       product_quantity: item.quantity,
  //       product_discount_price: 0,
  //     };
  //   });
  //   const payload = {
  //     order_products: orderProducts,
  //     customer: {
  //       first_name: values?.first_name,
  //       last_name: values?.last_name,
  //       email: values?.email,
  //       phone: values?.phone,
  //     },
  //     shipping_address: {
  //       line1: values?.address_line1,
  //       line2: values?.address_line2,
  //       state: values?.state,
  //       city: values?.city,
  //       postal_code: values?.postcode,
  //     },
  //     notes: values?.additional_notes,
  //     payment_method: paymentMethod,
  //     total_amount: total,
  //     discount_value: discount,
  //     subtotal: subtotal,
  //     coupon: coupon_detail?.coupon_code ?? null,
  //     stripe_payment_id: paymentMethod === "Card" ? clientSecret : null,
  //   };
  //   setEnrollEmail(values?.email);
  //   WhiteLabelService.checkoutOrder(payload, venue_app_key)
  //     .then((data) => {
  //       setLoading(false);
  //       dispatch(
  //         addOrderSucessDetails({
  //           email: values?.email,
  //           can_enroll: data?.can_enroll,
  //           type: "retail",
  //           appKey: appKey,
  //         })
  //       );
  //       dispatch(clearCheckoutForm());
  //       dispatch(removeFromCartBillingDetails());
  //       NotificationManager.success(
  //         "Order was placed successfully",
  //         "Success",
  //         3000
  //       );
  //       setSubmitted(true);
  //       if (data?.can_enroll) {
  //         setCanEnroll(true);
  //       }

  //       identifyVenue(venue_app_key);
  //       trackEvent("Checkout Order Retail", {
  //         action_category: "order",
  //         action_outcome: "success",
  //         interaction_element: "button",
  //         source: "vb_web",
  //       });
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       NotificationManager.error(err?.error ?? "Something went wrong!");
  //       identifyVenue(venue_app_key);
  //       trackEvent("Checkout Order Retail", {
  //         action_category: "order",
  //         action_outcome: "fail",
  //         interaction_element: "button",
  //         source: "vb_web",
  //       });
  //     });
  // };

  const onEnrollSubmit = () => {
    const payload = {
      email: enrollEmail,
    };
    WhiteLabelService.guestEnroll(payload, appKey)
      .then((res) => {
        NotificationManager.success(
          res?.message ?? "Guest enrolled successfully"
        );
        setEnrolledCompleted(true);
      })
      .catch((err) => {
        NotificationManager.error(err?.error ?? "Something went wrong!");
      });
  };

  if (submitted) {
    return (
      <div>
        <OrderSuccessScreen fullName={""} />
        {canEnroll ? (
          <Enroll
            onSubmit={onEnrollSubmit}
            completed={enrolledCompleted}
            title={"Unlock Rewards with Our Loyalty Program"}
            description={
              "Every order brings you closer to exclusive discounts. Earn points with each order and use them to save on your next visit. Your loyalty matters to us!"
            }
          />
        ) : (
          ""
        )}
      </div>
    );
  }

  return (
    <Spin spinning={loading}>
      <div className="md:grid md:grid-cols-3 gap-2">
        <CheckoutShipping setActiveStep={setActiveStep} />
      </div>
    </Spin>
  );
};

export default CheckoutScreen;
