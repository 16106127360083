import Details from "./components/Details";
import AddRemovalStructureOrder from "./components/AddRemovalStructureOrder";
import Button from "./components/Button";
import { useState } from "react";
import "./index.css";

const Product = (props) => {
  const { selectedProduct, products = [] } = props;
  const [previewProducts, setPreviewProducts] = useState(products);
  const [quantity, setQuantity] = useState(false);
  const [selectedItem, setSelectedItem] = useState();

  return (
    <div className="w-full relative flex px-0 pb-0">
      <div
        onClick={(e) => e.stopPropagation()}
        className="w-full relative flex pb-0 box-border bg-cover bg-no-repeat bg-[top] leading-[normal] tracking-[normal]"
      >
        <main className="flex-1 w-full rounded-t-7xl rounded-b-none flex flex-col items-start z-[2] px-0">
          <section className="px-5 justify-center w-full flex pt-0 pb-6 box-border">
            {selectedProduct?.imgSrc && (
              <div className="h-[156px] flex-1 flex pt-[0.5px] px-0 pb-0 box-border">
                <div className="h-[219px] flex-1 flex justify-center relative overflow-hidden">
                  <img
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    className="absolute top-[16px] w-[67px] h-[124.2px] object-contain z-[1]"
                    loading="lazy"
                    alt=""
                    src={selectedProduct.imgSrc}
                  />
                </div>
              </div>
            )}
          </section>
          <section className="px-5 w-full justify-center flex pt-0 pb-6 box-border">
            <Details
              products={products}
              product={selectedProduct}
              setSelectedItem={setSelectedItem}
              setPreviewProducts={setPreviewProducts}
              {...props}
              selectedItem={selectedItem}
            />
          </section>
          <section className="product-add-to-order bg-white justify-center rounded-7xl bg-bg flex py-4 gap-2 px-2 w-full">
            <AddRemovalStructureOrder
              {...props}
              setQuantity={setQuantity}
              quantity={quantity}
            />
            <Button
              {...props}
              {...{
                quantity,
                setQuantity,
                selectedItem,
                setPreviewProducts,
              }}
            />
          </section>
        </main>
      </div>
    </div>
  );
};

export default Product;
