import React from "react";
import BeachOrderIcon2 from "../../../assets/images/beachbarorder/icon.svg";
import BeachVector from "../../../assets/images/beachbarorder/vector.svg";
import IconMenu2 from "../../../assets/images/beachbarorder/iconsmenu-2.svg";
import { beachBarOrderText } from "..";

const ProductHeader = ({ setIsOpen }) => {
  return (
    <div className="self-stretch flex flex-row items-start justify-between py-px px-0 gap-[20px]">
      <div className="flex flex-row items-start justify-start gap-[12.2px]">
        <div
          onClick={() => setIsOpen(false)}
          className="bg-white shadow-[0px_0px_2px_rgba(12,_26,_75,_0.05),_0px_4px_20px_-2px_rgba(50,_50,_71,_0.02)] rounded-xl bg-bg flex flex-row items-center justify-start p-3 gap-[8px]"
        >
          <img
            className="h-5 w-5 relative overflow-hidden shrink-0"
            loading="lazy"
            alt=""
            src={BeachOrderIcon2}
          />
        </div>
        <div className="flex flex-col items-start justify-start pt-[13px] px-0 pb-0">
          <img
            className="w-[18px] h-[18px] relative"
            loading="lazy"
            alt=""
            src={BeachVector}
          />
        </div>
        <div className="flex flex-col items-start justify-start gap-[2px] text-left text-lg text-neutral-neutral-800 font-manrope">
          <div className="relative leading-[22px] font-semibold inline-block min-w-[126px]">
            {beachBarOrderText("umbrellaNumber")} 12
          </div>
          <div className="relative text-xs leading-[20px] font-medium text-lightslategray whitespace-nowrap text-[#8E8EA9]">
            {beachBarOrderText("orderingFromUmbrella")}
          </div>
        </div>
      </div>
      <div className="flex flex-col items-start justify-start pt-2.5 px-0 pb-0">
        <img
          className="w-6 h-6 relative overflow-hidden shrink-0"
          loading="lazy"
          alt=""
          src={IconMenu2}
        />
      </div>
    </div>
  );
};

export default ProductHeader;
