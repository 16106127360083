import React, { useEffect } from "react";
import "./index.css";
import { Seo } from "../../../components/Seo";
import Faq from "../../../components/Faq";
import Advantage from "../../../components/Advantage";
import Grow from "../grow";

import CircleTick from "../../../assets/svgs/checkfull.svg";
import OverviewAccommodation from "../../../assets/images/who-we-serve/accomodation.webp";
import AccommodationStaffManagement from "../../../assets/images/who-we-serve/accomodation-staff-management.webp";
import AccommodationStaffLoyalty from "../../../assets/images/who-we-serve/accomodation-staff-loyalty.webp";
import AccommodationStaffMarketing from "../../../assets/images/who-we-serve/accomodation-staff-marketing.webp";
import AccomodationBooking from "../../../assets/images/who-we-serve/accomodation-booking.webp";
import AccomodationInventory from "../../../assets/images/who-we-serve/accomodation-inventory.webp";
import AccomodationAnalytics from "../../../assets/images/who-we-serve/accomodation-analytics.webp";
import AccomodationPayment from "../../../assets/images/who-we-serve/accomodation-payment.webp";
import AccomodationAffiliate from "../../../assets/images/who-we-serve/accomodation-affiliate.webp";
import AccomodationGuestsManagement from "../../../assets/images/who-we-serve/accomodation-guests-management.webp";
import AccommodationGuestsManagement from "../../../assets/images/who-we-serve/accomodation-guests.webp";
import { t } from "i18next";
import OverviewFoodGuestsManagement from "../../../assets/images/who-we-serve/food-guests-management.webp";
import { CheckLine } from "../../../components/CheckLine";
import FreeTrial from "../../../components/FreeTrial";
import FreePlanCard from "../../../components/FreePlanCard";

export const accText = (label) =>
  t(`who_we_serve.accommodation_overview.${label}`);
const genFaqText = (label) =>
  t(`who_we_serve.accommodation_overview.generalFaq.${label}`);

export const tabsText = (label) =>
  t(`who_we_serve.accommodation_overview.tabs.${label}`);

export const whoTabText = (label) =>
  t(`who_we_serve.food_overview.tabs.${label}`);

const generalFaq = [
  {
    id: 1,
    status: false,
    title: genFaqText("typeOfAccomodation"),
    content: genFaqText("vbIdealPlace"),
  },
  {
    id: 2,
    status: false,
    title: genFaqText("streamlineBookingManagement"),
    content: genFaqText("vbBookingManagementTools"),
  },
  {
    id: 3,
    status: false,
    title: genFaqText("inventoryManagementAssistance"),
    content: genFaqText("vbInventoryManagement"),
  },
  {
    id: 4,
    status: false,
    title: genFaqText("staffManagementFacilitation"),
    content: genFaqText("vbStaffManagement"),
  },
  {
    id: 5,
    status: false,
    title: genFaqText("marketingToolsProvided"),
    content: genFaqText("vbMarketingTools"),
  },
  {
    id: 6,
    status: false,
    title: genFaqText("loyaltyRetentionPrograms"),
    content: genFaqText("vbLoyaltyRetention"),
  },
  {
    id: 7,
    status: false,
    title: genFaqText("analyticsReportingFeatures"),
    content: genFaqText("vbAnalyticsReporting"),
  },
  {
    id: 8,
    status: false,
    title: genFaqText("paymentProcessingHandling"),
    content: genFaqText("vbPaymentProcessing"),
  },
  {
    id: 9,
    status: false,
    title: genFaqText("guestManagementEnhancement"),
    content: genFaqText("vbGuestManagement"),
  },
  {
    id: 10,
    status: false,
    title: genFaqText("onboardingSupportProvided"),
    content: genFaqText("vbOnboardingSupport"),
  },
];

const AccommodationIndustry = () => {
  const currentUrl = "https://venueboost.io/who-we-serve/accommodation";
  const seoTitle = accText("seo.title");
  const seoDescription = accText("seo.description");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const tabs = [
    {
      title: accText("iCalIntegration"),
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-0 md:mt-[58px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0">
            <img alt="" src={OverviewFoodGuestsManagement} />
          </div>
          <div className="w-full md:w-[50%] md:p-0 md:pr-0  flex-col">
            <h1
              className="text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
              style={{ alignSelf: "left" }}
            >
              {accText("streamlinedAvailabilityManagement")}
            </h1>

            <p className="text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {accText("venueBoost")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {accText("availabilitySync")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {accText("doubleBookings")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {accText("autoUpdate")}
                </span>
              </div>
            </div>
            <p className="text-[#666666] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px] mb-8">
              {accText("smoothBookings")}
            </p>
          </div>
        </div>
      ),
      title_modal: tabsText("icalIntegrationTitleModal"),
      badge: tabsText("icalIntegrationTitleModal"),
      content1: tabsText("streamlinedAvailabilityManagementContent1"),
      content2: tabsText("icalIntegrationContent2"),
      content4: tabsText("icalIntegrationContent4"),
      content3: [
        tabsText("syncAvailabilityWithiCal"),
        tabsText("avoidDoubleBookings"),
        tabsText("automaticUpdateAcrossPlatforms"),
      ],
    },
    {
      title: accText("accommodationGuestLoyaltyProgram"),
      badge: accText("accommodationGuestLoyaltyProgram"),
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-0 md:mt-[58px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0">
            <img alt="" src={OverviewFoodGuestsManagement} />
          </div>
          <div className="w-full md:w-[50%] md:p-0 md:pr-0  flex-col">
            <h1
              className="text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
              style={{ alignSelf: "left" }}
            >
              {tabsText("forStreamlinedStays")}
            </h1>

            <p className="text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {tabsText("accommodationGuestLoyaltyProgramContent")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabsText("rewardGuestsWithPoints")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabsText("grantPerksLikeRoomUpgrades")}
                </span>
              </div>
            </div>

            <p className="text-[#666666] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px] mb-8">
              {accText("optimizeYourAccommodationBusiness")}
            </p>
          </div>
        </div>
      ),
      title_modal: tabsText("accommodationGuestLoyaltyProgram"),
      content1: tabsText("forStreamlinedStays"),
      content2: tabsText("accommodationGuestLoyaltyProgramContent"),
      content3: [
        tabsText("rewardGuestsWithPoints"),
        tabsText("grantPerksLikeRoomUpgrades"),
      ],
      content4: tabsText("optimizeYourAccommodationBusiness"),
    },
    {
      title: tabsText("advancedGuestBehaviorAnalytics"),
      badge: tabsText("advancedGuestBehaviorAnalytics"),
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-0 md:mt-[58px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0">
            <img alt="" src={OverviewFoodGuestsManagement} />
          </div>
          <div className="w-full md:w-[50%] md:p-0 md:pr-0  flex-col">
            <h1
              className="text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
              style={{ alignSelf: "left" }}
            >
              {tabsText("dataDrivenGuestInsights")}
            </h1>

            <p className="text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {tabsText("advancedGuestBehaviorAnalyticsContent")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabsText("analyzePreferencesHistoryAndEngagementPatterns")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabsText("makeDataDrivenDecisionsToImproveExperiences")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabsText(
                    "effectivelyTargetMarketingBasedOnBehaviorInsights"
                  )}
                </span>
              </div>
            </div>
            <p className="text-[#666666] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px] mb-8">
              {tabsText("venueBoostEmpowersBusinessesToEnhanceGuestEngagement")}
            </p>
          </div>
        </div>
      ),
      title_modal: tabsText("advancedGuestBehaviorAnalytics"),
      content1: tabsText("dataDrivenGuestInsights"),
      content2: tabsText("advancedGuestBehaviorAnalyticsContent"),
      content3: [
        tabsText("analyzePreferencesHistoryAndEngagementPatterns"),
        tabsText("makeDataDrivenDecisionsToImproveExperiences"),
        tabsText("effectivelyTargetMarketingBasedOnBehaviorInsights"),
      ],
      content4: tabsText(
        "venueBoostEmpowersBusinessesToEnhanceGuestEngagement"
      ),
    },
    {
      title: tabsText("marketingAutomation"),
      badge: tabsText("marketingAutomation"),
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-0 md:mt-[58px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0">
            <img alt="" src={OverviewFoodGuestsManagement} />
          </div>
          <div className="w-full md:w-[50%] md:p-0 md:pr-0  flex-col">
            <h1
              className="text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
              style={{ alignSelf: "left" }}
            >
              {tabsText("boostEngagementAndSales")}
            </h1>

            <p className="text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {tabsText("takeYourMarketingToTheNextLevel")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabsText("createTargetedEmailCampaignsBasedOnGuestBehavior")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabsText("sendPersonalizedOffersAndRecommendations")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabsText("measureEffectivenessAndOptimizeStrategies")}
                </span>
              </div>
            </div>
            <p className="text-[#666666] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px] mb-8">
              {tabsText(
                "venueBoostStreamlinesYourMarketingAcrossIndustriesWithPowerfulAutomation"
              )}
            </p>
          </div>
        </div>
      ),
      title_modal: tabsText("marketingAutomation"),
      content1: tabsText("boostEngagementAndSales"),
      content2: tabsText("takeYourMarketingToTheNextLevel"),
      content3: [
        tabsText("createTargetedEmailCampaignsBasedOnGuestBehavior"),
        tabsText("sendPersonalizedOffersAndRecommendations"),
        tabsText("measureEffectivenessAndOptimizeStrategies"),
      ],
      content4: tabsText(
        "venueBoostStreamlinesYourMarketingAcrossIndustriesWithPowerfulAutomation"
      ),
    },
    {
      title: tabsText("paymentsClickSecure"),
      badge: tabsText("paymentsClickSecure"),
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-0 md:mt-[58px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0">
            <img alt="" src={OverviewFoodGuestsManagement} />
          </div>
          <div className="w-full md:w-[50%] md:p-0 md:pr-0  flex-col">
            <h1
              className="text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
              style={{ alignSelf: "left" }}
            >
              {tabsText("paymentsClickSecure")}
            </h1>

            <p className="text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {tabsText("venueBoostsInPersonPaymentsFeatureForTheFoodIndustry")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabsText(
                    "venuesCanAcceptPaymentsSecurelyInPersonAtTheirVenuePlace"
                  )}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabsText(
                    "securelyAcceptCreditCardPaymentsAndContactlessPayments"
                  )}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabsText("reduceTheRiskOfFraudWithSecurePaymentProcessing")}
                </span>
              </div>
            </div>
            <p className="text-[#666666] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px] mb-8">
              {tabsText("provideYourGuestsWithPeaceOfMind")}
            </p>
          </div>
        </div>
      ),
      title_modal: tabsText("paymentsClickSecure"),
      content1: tabsText("paymentsClickSecure"),
      content2: tabsText(
        "venueBoostsInPersonPaymentsFeatureForTheFoodIndustry"
      ),
      content3: [
        tabsText("venuesCanAcceptPaymentsSecurelyInPersonAtTheirVenuePlace"),
        tabsText("securelyAcceptCreditCardPaymentsAndContactlessPayments"),
        tabsText("reduceTheRiskOfFraudWithSecurePaymentProcessing"),
      ],
      content4: tabsText("provideYourGuestsWithPeaceOfMind"),
    },
    {
      title: tabsText("brandingYours"),
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-0 md:mt-[58px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0">
            <img alt="" src={OverviewFoodGuestsManagement} />
          </div>
          <div className="w-full md:w-[50%] md:p-0 md:pr-0  flex-col">
            <h1
              className="text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
              style={{ alignSelf: "left" }}
            >
              {tabsText("brandingYours")}
            </h1>

            <p className="text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {tabsText("platformLook")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              {[
                tabsText("brandConsistency"),
                tabsText("customerExperience"),
                tabsText("marketingSynergy"),
              ].map((text, index) => (
                <div key={index} className="flex flex-row items-center">
                  <img
                    alt=""
                    src={CircleTick}
                    className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                  />
                  <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                    {text}
                  </span>
                </div>
              ))}
            </div>
            <p className="text-[#666666] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px] mb-8">
              {tabsText("professionalism")}
            </p>
          </div>
        </div>
      ),
      title_modal: tabsText("brandingYours"),
      badge: tabsText("brandingYours"),
      content1: tabsText("brandingYours"),
      content2: tabsText("platformLook"),
      content3: [
        tabsText("brandConsistency"),
        tabsText("customerExperience"),
        tabsText("marketingSynergy"),
      ],
      content4: tabsText("professionalism"),
    },
    {
      title: tabsText("housekeeping"),
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-0 md:mt-[58px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0">
            <img alt="" src={OverviewFoodGuestsManagement} />
          </div>
          <div className="w-full md:w-[50%] md:p-0 md:pr-0  flex-col">
            <h1
              className="text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
              style={{ alignSelf: "left" }}
            >
              {tabsText("housekeeping")}
            </h1>

            <p className="text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {tabsText("housekeepingRevolution")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              {[
                tabsText("realTimeUpdates"),
                tabsText("workloadOverview"),
                tabsText("tasksManagement"),
              ].map((text, index) => (
                <div key={index} className="flex flex-row items-center">
                  <img
                    alt=""
                    src={CircleTick}
                    className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                  />
                  <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                    {text}
                  </span>
                </div>
              ))}
            </div>
            <p className="text-[#666666] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px] mb-8">
              -
            </p>
          </div>
        </div>
      ),
      title_modal: tabsText("housekeeping"),
      badge: tabsText("housekeeping"),
      content1: tabsText("housekeeping"),
      content2: tabsText("housekeepingRevolution"),
      content3: [
        tabsText("realTimeUpdates"),
        tabsText("workloadOverview"),
        tabsText("tasksManagement"),
      ],
      content4: "",
    },
    {
      title: tabsText("unitsRooms"),
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-0 md:mt-[58px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0">
            <img alt="" src={OverviewFoodGuestsManagement} />
          </div>
          <div className="w-full md:w-[50%] md:p-0 md:pr-0  flex-col">
            <h1
              className="text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
              style={{ alignSelf: "left" }}
            >
              {tabsText("unitsRooms")}
            </h1>

            <p className="text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {tabsText("unitsRoomsFeature")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              {[
                tabsText("sophisticatedTool"),
                tabsText("updateGeneralInfo"),
              ].map((text, index) => (
                <div key={index} className="flex flex-row items-center">
                  <img
                    alt=""
                    src={CircleTick}
                    className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                  />
                  <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                    {text}
                  </span>
                </div>
              ))}
            </div>
            <p className="text-[#666666] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px] mb-8">
              -
            </p>
          </div>
        </div>
      ),
      title_modal: tabsText("unitsRooms"),
      badge: tabsText("unitsRooms"),
      content1: tabsText("unitsRooms"),
      content2: tabsText("unitsRoomsFeature"),
      content3: tabsText(["sophisticatedTool", "updateGeneralInfo"]),
      content4: "-",
    },
  ];

  return (
    <div className={"align-col-middle accom-industry mt-0"}>
      <Seo
        title={seoTitle + " | VenueBoost"}
        description={seoDescription}
        image={OverviewAccommodation}
        url={currentUrl}
      />
      <div className="flex flex-col md:flex-row justify-center overflow-hidden bg-[#F3F3F3] px-4 sm:px-6 md:px-8 lg:px-16 pb-20 w-[100vw] relative md:min-h-[668px]">
        <div className="xl:!w-[1228px] flex flex-col md:flex-row">
          <div className="w-full md:w-[50%] flex flex-col md:pr-[58px] py-6 md:py-[44px] z-10 ">
            <span className=" text-[32px]  md:text-[60px] leading-[45px] md:leading-[60px] font-extrabold ">
              {t("who_we_serve.accommodation_industry.accommodation")}
            </span>

            <p className="hidden md:block text-[#000000] text-14 md:text-20 font-medium leading-5 md:leading-7 mt-2 md:mt-4 mb-[22px] md:mb-[44px]">
              {t("who_we_serve.accommodation_industry.industry_desc")}
            </p>
            <div className="hidden md:block mb-[15px] mt-10 md:mb-0">
              <a
                href="/get-started"
                className="rounded-md text-white font-bold text-[11px] md:text-18 leading-[11px] md:leading-[18px] px-[17px] md:px-[30px] py-[9px] md:py-[15px] uppercase bg-gradient-to-r from-[#240B3B] via-[#5E1B83] to-[#BC14FE]"
              >
                {t("who_we_serve.food_industry.unlock_free_plan")}
              </a>
            </div>
          </div>
          <div className=" md:block md:w-[50%] md:mt-12  relative align-middle">
            <img
              alt=""
              className="object-cover md:absolute rounded-[20px] right-[29px] max-h-full"
              src={OverviewAccommodation}
            />
          </div>
          <div className="flex flex-col md:hidden mt-6">
            <p className="text-[#000000] text-14 md:text-20 font-medium leading-5 md:leading-7 mt-2 md:mt-4 mb-[22px] md:mb-[44px]">
              {t("who_we_serve.accommodation_industry.industry_desc")}
            </p>
            <div className=" mt-6 md:mt-0">
              <a
                href="/get-started"
                className="flex w-full justify-center rounded-md text-white font-bold text-18 leading-[18px] md:px-[30px] py-[15px] uppercase bg-gradient-to-r from-[#240B3B] via-[#5E1B83] to-[#BC14FE]"
              >
                {t("who_we_serve.food_industry.unlock_free_plan")}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col-reverse md:flex-row mt-10 md:mt-[38px]">
        <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
          <img alt="" src={AccomodationBooking} />
        </div>

        <div className="w-full md:w-[50%] md:p-8 md:pr-0  flex-col">
          <h1 className="text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4">
            {accText("bookingsManagement")}
          </h1>
          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            {accText("accommodationBookings")}
          </p>

          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            {accText("accommodationReservationsStreamline")}
          </p>
          <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
            {[
              accText("bookingsCreate"),
              accText("reservationDashboard"),
              accText("rsvpFeatures"),
              accText("insightsOccupancyTraffic"),
            ].map((item) => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className="text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4">
            {accText("goodbyeIncohesiveSystems")}
          </p>
          <FreeTrial />
        </div>
      </div>
      <div className="flex flex-col-reverse md:flex-row-reverse mt-10 md:mt-[38px] ">
        <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
          <img alt="" src={AccomodationInventory} />
        </div>
        <div className="w-full md:w-[50%] md:p-4 md:pl-0  ">
          <h1 className="text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4">
            {t("who_we_serve.accommodation_industry.inventory_management")}{" "}
          </h1>
          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            {accText("optimizeRoomsAmenities")}
          </p>
          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            {t("who_we_serve.accommodation_industry.inventory_management_sub")}
          </p>
          <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
            {[
              t(
                "who_we_serve.accommodation_industry.inventory_management_tracking"
              ),
              t(
                "who_we_serve.accommodation_industry.inventory_management_update"
              ),
              t(
                "who_we_serve.accommodation_industry.inventory_management_monitoring"
              ),
              t(
                "who_we_serve.accommodation_industry.inventory_management_profile"
              ),
              t(
                "who_we_serve.accommodation_industry.inventory_management_pattern"
              ),
            ].map((item) => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className="text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4">
            {accText("intuitiveInventoryManagement")}
          </p>
          <FreeTrial />
        </div>
      </div>

      <div className="flex flex-col-reverse md:flex-row mt-10 md:mt-[38px]">
        <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
          <img alt="" src={AccommodationStaffManagement} />
        </div>
        <div className="w-full md:w-[50%] md:p-12 md:pr-0  flex-col">
          <h1
            className="text-[#240b3b] text-22 align-left md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
            style={{ alignSelf: "left" }}
          >
            {t("who_we_serve.accommodation_industry.staff_managment")}
          </h1>
          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            {accText("simplifiedSchedulingAndPayroll")}
          </p>
          <p className="text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
            {t("who_we_serve.accommodation_industry.staff_managment_sub")}
          </p>
          <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
            {[
              accText("automatedSchedulingBasedOnOccupancy"),
              accText("timeOffRequestsAndVacationDays"),
              accText("payrollProcessingWageCalculations"),
              accText("performanceMonitoringCoachingOpportunities"),
              accText("exceptionalServiceStaffCoordination"),
            ].map((item) => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className="text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4">
            {accText("forgetStaffSchedulingStress")}
          </p>
          <FreeTrial />
        </div>
      </div>
      <div className="flex flex-col-reverse md:flex-row-reverse mt-10 md:mt-[38px] ">
        <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
          <img alt="" src={AccommodationStaffMarketing} />
        </div>
        <div className="w-full md:w-[50%] md:p-4 md:pl-0   ">
          <h1 className="text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4">
            {t("who_we_serve.accommodation_industry.marketing")}{" "}
          </h1>
          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            {accText("expandedReachAndBrandAwareness")}
          </p>
          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            {t("who_we_serve.accommodation_industry.marketing_sub")}
          </p>
          <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              {[
                t("who_we_serve.accommodation_industry.marketing_email"),
                t("who_we_serve.accommodation_industry.marketing_promotion"),
                t("who_we_serve.accommodation_industry.marketing_campaigns"),
                t("who_we_serve.accommodation_industry.marketing_refferals"),
              ].map((item) => (
                <CheckLine text={item} />
              ))}
            </div>
          </div>
          <p className="text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4">
            {accText("venueBoostBringsTogetherMarketingTools")}
          </p>
          <FreeTrial />
        </div>
      </div>

      <div className="flex flex-col-reverse md:flex-row mt-10 md:mt-[28px]">
        <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
          <img alt="" src={AccommodationStaffLoyalty} />
        </div>
        <div className="w-full md:w-[50%] md:p-12 md:pr-0  flex-col">
          <h1
            className="text-[#240b3b] text-22 align-left md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
            style={{ alignSelf: "left" }}
          >
            {t("who_we_serve.accommodation_industry.loyalty")}
          </h1>
          <p className="text-[#666666] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
            {accText("BuildingGuestLoyaltyRetention")}
          </p>
          <p className="text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
            {t("who_we_serve.accommodation_industry.loyalty_sub")}
          </p>
          <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
            {[
              accText("BuildSpecialTiersLoyalMembers"),
              accText("ViewMemberProfilesTrackPurchaseHistory"),
              accText("SendTailoredRewardsOffers"),
              accText("AnalyzeProgramActivityEngagement"),
            ].map((item) => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className="text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4">
            {accText("StrengthenRelationshipsReturnGuests")}
          </p>
          <FreeTrial />
        </div>
      </div>
      <div className="flex flex-col-reverse md:flex-row-reverse mt-10 md:mt-[38px] ">
        <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
          <img alt="" src={AccomodationAnalytics} />
        </div>
        <div className="w-full md:w-[50%]">
          <h1 className="text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4">
            {t("who_we_serve.accommodation_industry.reporting")}{" "}
          </h1>
          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            {accText("ActionableAccommodationInsights")}
          </p>
          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            {t("who_we_serve.accommodation_industry.reporting_sub")}
          </p>
          <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
            {[
              accText("MonitorKPIs"),
              accText("GuestSegmentation"),
              accText("OptimizeLaborCosts"),
              accText("SourceOfBookingAnalysis"),
              accText("FinancialReporting"),
            ].map((item) => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className="text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4">
            {accText("StopRelyingOnAssumptions")}
          </p>
          <FreeTrial />
        </div>
      </div>

      <div className="flex flex-col-reverse md:flex-row mt-10 md:mt-[38px] ">
        <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
          <img alt="" src={AccomodationPayment} />
        </div>
        <div className="w-full md:w-[50%] md:p-4 md:pr-0  flex-col">
          <h1
            className="text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
            style={{ alignSelf: "left" }}
          >
            {t("who_we_serve.accommodation_industry.payment_links")}
          </h1>
          <p className="text-[#666666] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
            {accText("StreamlinedOnlineBookingPayments")}
          </p>
          <p className="text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
            {t("who_we_serve.accommodation_industry.payment_links_sub")}
          </p>
          <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
            {[
              accText("SecurelyAcceptDepositsPrepaymentsBalances"),
              accText("OrganizedLinkListTrackTransactions"),
              accText("InsightsBookingsRevenueFromLinks"),
              accText("SeamlessConvenientPaymentProcessGuests"),
            ].map((item) => (
              <CheckLine text={item} />
            ))}
          </div>
          <FreeTrial />
        </div>
      </div>
      <div className="flex flex-col-reverse md:flex-row-reverse mt-10 md:mt-[38px]">
        <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
          <img alt="" src={AccommodationGuestsManagement} />
        </div>
        <div className="w-full md:w-[50%] md:p-12 md:pr-0  flex-col">
          <h1
            className="text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
            style={{ alignSelf: "left" }}
          >
            {t("who_we_serve.accommodation_industry.guest_management")}
          </h1>
          <p className="text-[#666666] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
            {accText("PersonalizedStaysOptimizedExperiences")}
          </p>
          <p className="text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
            {t("who_we_serve.accommodation_industry.guest_management_sub")}
          </p>
          <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
            {[
              t("who_we_serve.accommodation_industry.guest_management_suite"),
              t(
                "who_we_serve.accommodation_industry.guest_management_preference"
              ),
              t("who_we_serve.accommodation_industry.guest_management_loyalty"),
              "Custom loyalty programs with special tiers",
            ].map((item) => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className="text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4">
            {accText("DeliverExceptionalStaysByUnderstandingEachGuest")}
          </p>
          <FreeTrial />
        </div>
      </div>

      <div className="flex flex-col-reverse md:flex-row mt-10 md:mt-[38px] ">
        <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
          <img alt="" src={AccomodationAffiliate} />
        </div>
        <div className="w-full md:w-[50%]  ">
          <h1 className="text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4">
            {whoTabText("affiliatePartnerships")}
          </h1>
          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            {accText("ExpandYourReachAndRevenue")}
          </p>

          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            {accText("withVenueBoostNewAffiliatesFeature")}
          </p>
          <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
            {[
              whoTabText("establishAffiliatePartnershipsSeamlessly"),
              whoTabText("trackReferralsAndCommissionsInRealTime"),
              whoTabText("accessValuableMarketingResources"),
              whoTabText("extendBrandReach"),
            ].map((item) => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className="text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4">
            {accText("joinForces")}
          </p>
          <FreeTrial />
        </div>
      </div>
      <div className="flex flex-col-reverse md:flex-row-reverse mt-10 md:mt-[38px]">
        <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0">
          <img alt="" src={AccomodationGuestsManagement} />
        </div>
        <div className="w-full md:w-[50%] md:p-0 md:pr-0  flex-col">
          <h1
            className="text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
            style={{ alignSelf: "left" }}
          >
            {accText("guestSurveysAndRatings")}
          </h1>

          <p className="text-[#666666] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
            Gather Valuable Feedback
          </p>
          <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
            {[
              whoTabText("venueBoostSurveysRatings"),
              whoTabText("customizedGuestSurveys"),
              whoTabText("collectRatingsReviews"),
              whoTabText("gainInsightsPreferencesOpinions"),
              whoTabText("dataDrivenImprovements"),
            ].map((item) => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className="text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4">
            {accText("listenToCustomersAndOptimizeOfferings")}
          </p>
          <FreeTrial />
        </div>
      </div>

      <Grow
        title={"Additional Accommodation Features"}
        subtitle={
          "Dive into VenueBoost's Expanded Capabilities for the Accommodation Industry"
        }
        tabs={tabs}
        venueColor={"#6DDFE2"}
      />

      <div className="align-col-middle ">
        <div className="w-[100vw] bg-[#240B3B]">
          <div className="max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 ">
            <div className="flex flex-col align-middle w-full">
              <h1 className="text-white  text-26 md:text-[42px] text-center font-extrabold leading-[60px] mt-10 md:mt-20 ">
                {t("who_we_serve.accommodation_industry.faq")}
                <span className="text-white">
                  {" "}
                  ({t("who_we_serve.accommodation_industry.faq_short")}){" "}
                </span>
              </h1>
              <p className="text-14 md:text-20 text-white px-7 leading-5 font-medium mt-4 mb:mt-20 mb-5 md:mb-[66px]">
                {t(
                  "who_we_serve.accommodation_industry.accommodation_industry"
                )}
              </p>
              <Faq faqData={generalFaq} className="mt-2 mb-4 md:mb-16" />
            </div>
          </div>
        </div>
      </div>
      <Advantage venueDefinition={"accommodation industry"} />
      <div className="align-col-middle">
        <div className="w-[100vw] bg-white">
          <div className="mx-auto max-w-screen-main px-4 sm:px-6 md:px-8 lg:px-16">
            <div className="flex flex-col align-middle mb-4 md:mb-20">
              <FreePlanCard />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccommodationIndustry;
