export default function DoubleCheck(props) {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 35 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_527_3562)">
        <g clip-path="url(#clip1_527_3562)">
          <path
            d="M19.4859 11.6766C19.3798 11.5703 19.2537 11.4859 19.1149 11.4283C18.9762 11.3708 18.8274 11.3411 18.6772 11.3411C18.5269 11.3411 18.3782 11.3708 18.2394 11.4283C18.1006 11.4859 17.9746 11.5703 17.8684 11.6766L11.3987 18.1464L13.0161 19.7639L19.4859 13.2826C19.9218 12.8467 19.9218 12.1125 19.4859 11.6766ZM24.3497 11.6651L13.0161 22.9988L9.0241 19.0182C8.80961 18.8037 8.5187 18.6832 8.21537 18.6832C7.91204 18.6832 7.62113 18.8037 7.40665 19.0182C7.19216 19.2327 7.07166 19.5236 7.07166 19.8269C7.07166 20.1303 7.19216 20.4212 7.40665 20.6357L12.2016 25.4307C12.649 25.878 13.3717 25.878 13.8191 25.4307L25.9672 13.2941C26.0735 13.1879 26.1579 13.0619 26.2154 12.9231C26.273 12.7843 26.3026 12.6356 26.3026 12.4853C26.3026 12.3351 26.273 12.1863 26.2154 12.0476C26.1579 11.9088 26.0735 11.7827 25.9672 11.6766H25.9557C25.8522 11.5683 25.728 11.4819 25.5905 11.4226C25.453 11.3632 25.3049 11.332 25.1551 11.331C25.0053 11.3299 24.8569 11.3589 24.7185 11.4163C24.5802 11.4737 24.4548 11.5583 24.3497 11.6651ZM0.925378 20.6471L5.72037 25.4421C6.16775 25.8895 6.89044 25.8895 7.33782 25.4421L8.14081 24.6391L2.54283 19.0182C2.4367 18.9119 2.31065 18.8275 2.17187 18.7699C2.0331 18.7124 1.88434 18.6828 1.7341 18.6828C1.58387 18.6828 1.4351 18.7124 1.29633 18.7699C1.15756 18.8275 1.0315 18.9119 0.925378 19.0182C0.477999 19.4656 0.477999 20.1998 0.925378 20.6471Z"
            fill="#240B3B"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_527_3562">
          <rect
            width="34.4138"
            height="34.4138"
            fill="white"
            transform="translate(0.212891 0.756348)"
          />
        </clipPath>
        <clipPath id="clip1_527_3562">
          <rect
            width="34.4138"
            height="34.4138"
            fill="white"
            transform="translate(-4.94922 0.756348)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
