import React from "react";
import "../index.css";
import { isEmpty } from "../../../../utils/common";
import { useSelector } from "react-redux";
import { editIcon } from "../../../venue-retail/icons";
import { productData } from "../data";

const CheckoutReview = () => {
  const cartProducts = useSelector((state) => state.cart.products) ?? [];
  const data = cartProducts.length > 0 ? cartProducts : productData;

  return (
    <div className="review flex flex-col gap-8 col-span-2">
      <h1 className="mt-5">Estimated delivery: 22 Feb 2022</h1>
      <div>
        {data.map((item, index) => (
          <div className="col-span-2" key={index}>
            <div className="flex items-center gap-4 mb-4">
              <div className="flex rounded-lg items-center gap-4 py-2 pr-2 w-full border-b">
                <img
                  className={`w-[80px] h-[80px] rounded-lg object-cover ${
                    isEmpty(item?.image_path) ? "hidden" : "block"
                  }`}
                  src={item?.image_path}
                  alt={item?.title}
                />
                <div className="flex flex-col justify-start w-full">
                  <div className="table-title">{item?.title}</div>
                  <div className="table-description">
                    {(item?.short_description ?? item?.description).length <= 80
                      ? item?.short_description ?? item?.description
                      : (item?.short_description ?? item?.description).slice(
                          0,
                          80
                        ) + "..."}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="address col-span-2">
        <h1>Shipping Address</h1>
        <div className="description flex justify-between items-center w-full">
          <div className="flex flex-col gap-3">
            <h1>Robert Fox</h1>
            <span>4517 Washington Ave. Manchester, Kentucky 39495</span>
          </div>
          <p>{editIcon}</p>
        </div>
      </div>

      <div className="theader py-4 border-b-[1px] gap-5" />

      <div className="payment-review w-full">
        <h1>Payment Method</h1>
        <div className="w-full description flex justify-between items-center">
          <p>Debit Card (.... .... .... ..89)</p>
          <span>{editIcon}</span>
        </div>
      </div>
      <div className="theader py-4 border-b-[1px] gap-5" />
    </div>
  );
};

export default CheckoutReview;
