import React from "react";
import FooterImg from "../../../assets/images/sitemap.webp";
import ProductCard from "../../../components/ProductCard";
// import ProductDetailImg from "../../../assets/images/productupdate/productupdatebanner.png";
// import FacebookIcon from "../../../assets/svgs/facebookicon.svg";
// import TwitterIcon from "../../../assets/svgs/facebookicon.svg";
// import InstagramIcon from "../../../assets/svgs/instagramicon.svg";
import ProductUpdateImg1 from "../../../assets/images/productupdate/venueboost_software_release.webp";
import BlackAvatar from "../../../assets/images/black-avatar.png";
import TextIcon from "../../../assets/images/text-icon.png";
import {Seo} from "../../../components/Seo";

const detailsSlug = window.location.pathname;
const data = [
  {
    tagId: 1,
    date: "June 20, 2023",
    author: "VB Dev Team",
    title:
      "Introducing VenueBoost: The Ultimate Solution for Streamlining Hospitality Operations",
    desc: "VenueBoost empowers businesses across various sectors, including Restaurants, Bars, Bistros, Pub & Clubs, Golf Courses, Gyms, Sports & Entertainment, Cafes, and Hotels.",
    img: ProductUpdateImg1,
    slug: "introducing-venueboost-the-ultimate-solution-for-streamlining-hospitality-operations",
    slug_related:
      "/product-updates/introducing-venueboost-the-ultimate-solution-for-streamlining-hospitality-operations",
    introduction:
      "We are thrilled to introduce you to the latest advancements in VenueBoost, designed to revolutionize the way you manage your hospitality business. Our team has been hard at work developing powerful enhancements and exciting new features that will take your operations to new heights. Get ready to discover the possibilities and unleash the full potential of VenueBoost in your daily operations.",
    feature_1_title: "Menu Management",
    feature_1_content:
      "With our enhanced Menu Management functionality, you can easily create and curate stunning menus that showcase your culinary creations in the most captivating way. From seasonal specials to daily offerings, you have complete control over your menu items, pricing, and descriptions. Say goodbye to manual updates and embrace the simplicity and efficiency of VenueBoost's streamlined menu management.\n" +
      "\n",
    feature_1_1_content:
      "With VenueBoost's advanced analytics and reporting capabilities, you gain valuable insights into your menu's performance. Track the popularity of different dishes, identify top-sellers, and make data-driven decisions to optimize your menu offerings and maximize profitability.",
    feature_2_title: "Reservations",
    feature_2_content:
      "At VenueBoost, we recognize the significance of seamless reservation management for your business. That's why we've taken our reservation functionality to the next level, offering you intuitive tools to simplify the entire booking process. Whether it's online reservations or phone bookings, our platform equips you with the necessary features to effortlessly manage availability, assign tables, and provide a seamless booking experience for your valued guests. ",
    feature_2_2_content:
      "Say goodbye to manual coordination and embrace the convenience and efficiency of VenueBoost's reservation management. Experience a streamlined booking process that enhances customer satisfaction and optimizes your operations.",
    feature_3_title: "Table Management",
    feature_3_content:
      "VenueBoost empowers you to make the most of your seating capacity and elevate guest satisfaction through our cutting-edge Table Management features. With our intuitive interface, you can effortlessly design and customize your venue's floor plan, ensuring efficient space utilization and seamless table assignments. Real-time updates and visual representations of your seating arrangements provide you with valuable insights to effectively manage the flow of your establishment. ",
    feature_3_3_content:
      "By leveraging VenueBoost's Table Management capabilities, you can deliver a flawless dining experience, maximizing guest comfort and optimizing your overall operations. Say goodbye to manual table coordination and welcome the efficiency and precision of VenueBoost's advanced Table Management.",
    feature_4_title: "Staff Management",
    feature_4_content:
      "At VenueBoost, we recognize that your staff is the key to your success. That's why we've introduced powerful Staff Management features designed to empower your team and enhance their performance. With VenueBoost's intuitive platform, you can effortlessly simplify staff scheduling, streamline communication, and monitor performance, all from a single centralized hub. ",
    feature_4_4_content:
      "Assign roles and responsibilities, track shifts, and easily communicate updates and changes, ensuring your team operates smoothly and efficiently. With VenueBoost's Staff Management functionality, you can optimize your workforce, foster collaboration, and create a harmonious work environment that drives success. Say goodbye to manual scheduling headaches and welcome the ease and efficiency of VenueBoost's streamlined Staff Management capabilities.",
    feature_6_title: "Waitlist Management",
    feature_6_content:
      "Efficiently managing waitlists during busy periods is crucial for ensuring a smooth and enjoyable dining experience. With VenueBoost's innovative Waitlist Management feature, you can effortlessly optimize table turnover and enhance guest flow. Our intelligent system automates waitlist notifications, allowing you to accurately predict table availability and seamlessly accommodate waiting guests.",
    feature_6_6_content:
      "By minimizing waiting times and maximizing table utilization, you can create a welcoming environment that exceeds customer expectations. With VenueBoost, say goodbye to waitlist chaos and embrace a streamlined process that elevates guest experiences to new heights.",
    feature_7_title: "Order and Pay",
    feature_7_content:
      "In today's fast-paced world, offering convenient and contactless dining options is essential. With VenueBoost's cutting-edge Order and Pay functionality, you can provide your customers with a seamless experience that puts convenience at their fingertips. Our intuitive platform enables guests to easily browse your menu, place orders, and make payments directly from their own devices.",
    feature_7_7_content:
      "By streamlining the ordering process, you can reduce wait times, improve order accuracy, and deliver a dining experience that exceeds customer expectations. Embrace the power of technology and elevate your establishment with VenueBoost's Order and Pay feature.",
    feature_end_title: "Unlock the Full Potential of VenueBoost",
    feature_end_content:
      "Are you ready to experience the power of VenueBoost? Contact us today to schedule a personalized demo and discover how VenueBoost can transform your operations. Embrace the future of hospitality management and elevate your business with VenueBoost.",
  },
];

const tags = [
  {title: "All", url: "/product-updates"},
  {
    title: "Software Release",
    url: "/product-updates",
  },
];

const currentProductDetails = data.find(bd => bd.slug_related === detailsSlug);

const ProductDetail = () => (
  <div className='align-col-middle  '>
    <Seo title={currentProductDetails.title + " | VenueBoost"} />
    <div className='flex flex-col md:flex-row md:rounded-[13px] overflow-hidden w-full'>
      <div className='w-full flex flex-col md:pr-[58px] mt-12 mb-6 md:pt-[24px] md:pb-[12px] items-start'>
        <a
          className='text-[#E39DFF] text-14 font-semibold leading-5 px-[10px] py-[2px] bg-[#240B3B] rounded-md w-[137px]'
          href='/product-updates'
        >
          Software Release
        </a>

        <h1
          className='text-[#0A0A0A] text-24 md:text-[34px] font-semibold leading-[35
          px] md:leading-[50px] mt-2 md:mt-6 xl:w-[1000px]'
        >
          {currentProductDetails.title}
        </h1>
        <div className='flex items-center mt-3 md:mt-7'>
          <div className='flex flex-row justify-center items-center gap-2'>
            <img src={BlackAvatar} className='w-[28px] h-[28px]' />
            <p className=' font-medium leading-5 text-14 text-[#696A75] mr-8'>
              {currentProductDetails.author}
            </p>
            <p className=' font-medium leading-5 text-14 text-[#696A75] mr-8'>
              {currentProductDetails.date}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className='w-full md:mt-4 '>
      <img
        className='rounded-2xl max-h-[462px] object-cover w-full'
        src={currentProductDetails.img}
      />
    </div>
    {/* <div className="w-full py-[1px] bg-[#AFAFAF] opacity-30 my-6 hidden md:block"></div> */}
    <div className='flex justify-start w-full'>
      <p className=' text-20 text-[#3B3C4A] leading-[32px] font-normal mt-8 md:mb-8 xl:w-[1022px]'>
        {currentProductDetails.introduction}
      </p>
    </div>
    <div className='content '>
      <h3 className='text-[24px] eading-[18px] font-semibold mb-5 mt-10 md:mt-0'>
        {currentProductDetails.feature_1_title}{" "}
      </h3>

      <div className='md:w-[100%] '>
        <p className='text-20 text-[#3B3C4A] leading-[32px] font-normal mt-6'>
          {currentProductDetails.feature_1_content}
        </p>
        <p className='text-20 text-[#3B3C4A] leading-[32px] font-normal mt-6'>
          {currentProductDetails.feature_1_1_content}
        </p>
      </div>
    </div>
    <div className='content mt-0 md:mt-6'>
      <h3 className='text-[24px] eading-[18px] font-semibold mb-5 mt-10 md:mt-0'>
        {currentProductDetails.feature_2_title}{" "}
      </h3>

      <div className='md:w-[100%] '>
        <p className='text-20 text-[#3B3C4A] leading-[32px] font-normal mt-6'>
          {currentProductDetails.feature_2_content}
        </p>
        <p className='text-20 text-[#3B3C4A] leading-[32px] font-normal mt-6'>
          {currentProductDetails.feature_2_2_content}
        </p>
      </div>
    </div>
    <div className='content mt-0 md:mt-6'>
      <h3 className='text-[24px] eading-[18px] font-semibold mb-5 mt-10 md:mt-0'>
        {currentProductDetails.feature_3_title}{" "}
      </h3>

      <div className='md:w-[100%] '>
        <p className='text-20 text-[#3B3C4A] leading-[32px] font-normal mt-6'>
          {currentProductDetails.feature_3_content}
        </p>
        <p className='text-20 text-[#3B3C4A] leading-[32px] font-normal mt-6'>
          {currentProductDetails.feature_3_3_content}
        </p>
      </div>
    </div>
    <div className='content mt-0 md:mt-6'>
      <h3 className='text-[24px] eading-[18px] font-semibold mb-5 mt-10 md:mt-0'>
        {currentProductDetails.feature_4_title}{" "}
      </h3>

      <div className='md:w-[100%] '>
        <p className='text-20 text-[#3B3C4A] leading-[32px] font-normal mt-6'>
          {currentProductDetails.feature_4_content}
        </p>
        <p className='text-20 text-[#3B3C4A] leading-[32px] font-normal mt-6'>
          {currentProductDetails.feature_4_4_content}
        </p>
      </div>
    </div>
    <div className='content mt-0 md:mt-6'>
      <h3 className='text-[24px] eading-[18px] font-semibold mb-5 mt-10 md:mt-0'>
        {currentProductDetails.feature_6_title}{" "}
      </h3>

      <div className='md:w-[100%] '>
        <p className='text-20 text-[#3B3C4A] leading-[32px] font-normal mt-6'>
          {currentProductDetails.feature_6_content}
        </p>
        <p className='text-20 text-[#3B3C4A] leading-[32px] font-normal mt-6'>
          {currentProductDetails.feature_6_6_content}
        </p>
      </div>
    </div>
    <div className='content mt-0 md:mt-6'>
      <h3 className='text-[24px] eading-[18px] font-semibold mb-5 mt-10 md:mt-0'>
        {currentProductDetails.feature_7_title}{" "}
      </h3>

      <div className='md:w-[100%] '>
        <p className='text-20 text-[#3B3C4A] leading-[32px] font-normal mt-6'>
          {currentProductDetails.feature_7_content}
        </p>
        <p className='text-20 text-[#3B3C4A] leading-[32px] font-normal mt-6'>
          {currentProductDetails.feature_7_7_content}
        </p>
      </div>
    </div>
    <div className='content mt-0 md:mt-6'>
      <h3 className='text-[24px] eading-[18px] font-semibold mb-5 mt-10 md:mt-0'>
        {currentProductDetails.feature_end_title}{" "}
      </h3>

      <div className='md:w-[100%] '>
        <p className='text-20 text-[#3B3C4A] leading-[32px] font-normal mt-6'>
          {currentProductDetails.feature_end_content}
        </p>
      </div>
    </div>

    <div className='flex flex-col w-full mt-20 mb-10 gap-6'>
      <div className='flex flex-row justify-start w-full gap-5'>
        <div className='w-[48px] h-[48px] bg-gradient-to-r from-[#240B3B] via-[#5D1C82] to-[#BC14FE] rounded-full flex items-center justify-center'>
          <svg
            width='24'
            height='25'
            viewBox='0 0 24 25'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M21.533 7.59759C21.5482 7.81078 21.5482 8.02401 21.5482 8.2372C21.5482 14.7397 16.599 22.2321 7.5533 22.2321C4.76648 22.2321 2.17767 21.425 0 20.024C0.395953 20.0697 0.776625 20.0849 1.18781 20.0849C3.48727 20.0849 5.60405 19.3083 7.29441 17.9834C5.13197 17.9377 3.31978 16.5215 2.69541 14.5722C3 14.6179 3.30455 14.6483 3.62437 14.6483C4.06598 14.6483 4.50764 14.5874 4.91878 14.4809C2.66498 14.024 0.974578 12.0443 0.974578 9.65343V9.59254C1.62937 9.95803 2.39086 10.1864 3.19791 10.2169C1.87303 9.33361 1.00505 7.82601 1.00505 6.12042C1.00505 5.20673 1.24866 4.36917 1.67508 3.6382C4.09641 6.62296 7.73602 8.57217 11.8172 8.7854C11.7411 8.41992 11.6954 8.03925 11.6954 7.65853C11.6954 4.94784 13.8883 2.73975 16.6141 2.73975C18.0304 2.73975 19.3095 3.33365 20.208 4.29304C21.3197 4.07986 22.3857 3.66867 23.3299 3.10523C22.9643 4.24739 22.1877 5.20678 21.1674 5.81587C22.1573 5.70932 23.1167 5.43515 23.9999 5.05448C23.33 6.02906 22.4924 6.89704 21.533 7.59759Z'
              fill='white'
            />
          </svg>
        </div>
        <div className='w-[48px] h-[48px] bg-gradient-to-r from-[#240B3B] via-[#5D1C82] to-[#BC14FE] rounded-full flex items-center justify-center'>
          <svg
            width='24'
            height='25'
            viewBox='0 0 24 25'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g clip-path='url(#clip0_123_1524)'>
              <path
                d='M6.20062 21.4859H1.84688V7.46555H6.20062V21.4859ZM4.02141 5.55305C2.62922 5.55305 1.5 4.39992 1.5 3.00773C1.5 2.33902 1.76565 1.69769 2.2385 1.22483C2.71136 0.751975 3.35269 0.486328 4.02141 0.486328C4.69012 0.486328 5.33145 0.751975 5.80431 1.22483C6.27716 1.69769 6.54281 2.33902 6.54281 3.00773C6.54281 4.39992 5.41313 5.55305 4.02141 5.55305ZM22.4953 21.4859H18.1509V14.6609C18.1509 13.0343 18.1181 10.9484 15.8873 10.9484C13.6237 10.9484 13.2769 12.7155 13.2769 14.5437V21.4859H8.92781V7.46555H13.1034V9.37805H13.1644C13.7456 8.27648 15.1655 7.11398 17.2838 7.11398C21.69 7.11398 22.5 10.0155 22.5 13.7843V21.4859H22.4953Z'
                fill='white'
              />
            </g>
            <defs>
              <clipPath id='clip0_123_1524'>
                <rect
                  width='24'
                  height='24'
                  fill='white'
                  transform='translate(0 0.48584)'
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div className='w-[48px] h-[48px] bg-gradient-to-r from-[#240B3B] via-[#5D1C82] to-[#BC14FE] rounded-full flex items-center justify-center'>
          <svg
            width='24'
            height='25'
            viewBox='0 0 24 25'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g clip-path='url(#clip0_123_1527)'>
              <path
                d='M17.584 13.9858L18.2505 9.6424H14.0829V6.82381C14.0829 5.63553 14.6651 4.47725 16.5316 4.47725H18.4263V0.779277C18.4263 0.779277 16.707 0.48584 15.063 0.48584C11.6309 0.48584 9.38742 2.56615 9.38742 6.33209V9.6424H5.57227V13.9858H9.38742V24.4858H14.0829V13.9858H17.584Z'
                fill='white'
              />
            </g>
            <defs>
              <clipPath id='clip0_123_1527'>
                <rect
                  width='24'
                  height='24'
                  fill='white'
                  transform='translate(0 0.48584)'
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div className='w-[48px] h-[48px] bg-gradient-to-r from-[#240B3B] via-[#5D1C82] to-[#BC14FE] rounded-full flex items-center justify-center'>
          <svg
            width='24'
            height='25'
            viewBox='0 0 24 25'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M17.9999 16.2358C17.5959 16.2357 17.196 16.3174 16.8244 16.4762C16.4528 16.6349 16.1173 16.8673 15.838 17.1593L8.90054 13.256C9.03492 12.7514 9.03492 12.2203 8.90054 11.7157L15.838 7.81241C16.3432 8.33616 17.022 8.65776 17.7473 8.71695C18.4726 8.77614 19.1946 8.56886 19.778 8.13394C20.3614 7.69903 20.7662 7.06632 20.9166 6.35434C21.067 5.64237 20.9527 4.89999 20.595 4.26627C20.2373 3.63256 19.6609 3.15099 18.9736 2.91179C18.2864 2.6726 17.5355 2.69218 16.8616 2.96687C16.1878 3.24156 15.6372 3.75251 15.3131 4.40401C14.9889 5.0555 14.9134 5.80284 15.1007 6.506L8.1632 10.4093C7.7488 9.97721 7.2146 9.679 6.62933 9.55302C6.04405 9.42704 5.43447 9.47905 4.879 9.70237C4.32353 9.92569 3.84758 10.3101 3.51237 10.8061C3.17717 11.3022 2.99805 11.8872 2.99805 12.4858C2.99805 13.0845 3.17717 13.6695 3.51237 14.1656C3.84758 14.6616 4.32353 15.046 4.879 15.2693C5.43447 15.4926 6.04405 15.5447 6.62933 15.4187C7.2146 15.2927 7.7488 14.9945 8.1632 14.5624L15.1007 18.4657C14.9398 19.0713 14.9726 19.712 15.1945 20.298C15.4163 20.8839 15.816 21.3858 16.3376 21.733C16.8591 22.0802 17.4763 22.2555 18.1025 22.2341C18.7287 22.2127 19.3325 21.9958 19.8291 21.6138C20.3258 21.2319 20.6904 20.704 20.8717 20.1042C21.0531 19.5045 21.0421 18.863 20.8404 18.2698C20.6386 17.6766 20.2562 17.1615 19.7468 16.7967C19.2373 16.4319 18.6265 16.2358 17.9999 16.2358Z'
              fill='white'
            />
          </svg>
        </div>
      </div>
      <div className='w-full md:w-[880px] bg-gradient-to-r from-[#240B3B] via-[#5D1C82] to-[#BC14FE] rounded-md gap-5 px-8 py-10 flex items-center justify-start'>
        <div className='min-w-[60px] h-[52px] rounded-xl bg-white flex justify-center items-center'>
          <svg
            width='29'
            height='30'
            viewBox='0 0 29 30'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M14.4008 2.28564C7.44266 2.28564 1.80078 7.92752 1.80078 14.8856C1.80078 21.8438 7.44266 27.4856 14.4008 27.4856C21.3589 27.4856 27.0008 21.8438 27.0008 14.8856C27.0008 7.92752 21.3589 2.28564 14.4008 2.28564ZM14.4008 25.3481C8.62391 25.3481 3.93828 20.6625 3.93828 14.8856C3.93828 9.10877 8.62391 4.42314 14.4008 4.42314C20.1777 4.42314 24.8633 9.10877 24.8633 14.8856C24.8633 20.6625 20.1777 25.3481 14.4008 25.3481Z'
              fill='#240B3B'
            />
            <path
              d='M17.5391 9.39322C16.6954 8.65354 15.5816 8.24854 14.4004 8.24854C13.2191 8.24854 12.1054 8.65635 11.2616 9.39322C10.3841 10.161 9.90039 11.1932 9.90039 12.2985V12.5123C9.90039 12.636 10.0016 12.7373 10.1254 12.7373H11.4754C11.5991 12.7373 11.7004 12.636 11.7004 12.5123V12.2985C11.7004 11.0582 12.9126 10.0485 14.4004 10.0485C15.8882 10.0485 17.1004 11.0582 17.1004 12.2985C17.1004 13.1732 16.4816 13.9748 15.5226 14.3432C14.9263 14.571 14.4201 14.9704 14.0573 15.4935C13.6888 16.0279 13.4976 16.6692 13.4976 17.3188V17.9235C13.4976 18.0473 13.5988 18.1485 13.7226 18.1485H15.0726C15.1963 18.1485 15.2976 18.0473 15.2976 17.9235V17.2851C15.299 17.0121 15.3827 16.7458 15.5378 16.521C15.6928 16.2963 15.9119 16.1235 16.1666 16.0251C17.826 15.3867 18.8976 13.9242 18.8976 12.2985C18.9004 11.1932 18.4166 10.161 17.5391 9.39322ZM13.2754 21.0735C13.2754 21.3719 13.3939 21.6581 13.6049 21.869C13.8159 22.08 14.102 22.1985 14.4004 22.1985C14.6988 22.1985 14.9849 22.08 15.1959 21.869C15.4069 21.6581 15.5254 21.3719 15.5254 21.0735C15.5254 20.7752 15.4069 20.489 15.1959 20.278C14.9849 20.0671 14.6988 19.9485 14.4004 19.9485C14.102 19.9485 13.8159 20.0671 13.6049 20.278C13.3939 20.489 13.2754 20.7752 13.2754 21.0735Z'
              fill='#240B3B'
            />
          </svg>
        </div>
        <div className='text-16 font-semibold text-white'>
          Questions? Comments? Visit our Contact Page for support.
        </div>
      </div>
    </div>

    <div className='my-8 md:mb-20 align-middle flex flex-col '>
      <div className='flex justify-start gap-4 w-full'>
        <img src={TextIcon} className='w-[35px] h-[35px]' />
        <h1 className='text-20 md:text-24 font-semibold text-[#000000] leading-[31px] mb-8 md:mb-[60px]'>
          More product updates
        </h1>
      </div>
      <div className='grid grid-cols-1 sm:grid-cols-3 gap-x-[60px] gap-y-[29px] md:gap-y-[37px]'>
        {data &&
          data.length > 0 &&
          data.map((card, index) => (
            <ProductCard
              key={index}
              img={card.img}
              tag={tags[card.tagId].title}
              title={card.title}
              desc={card.desc}
              slug={card.slug}
            />
          ))}
      </div>{" "}
    </div>

    {/* <div className="w-[100vw]  relative translate-y-[1px]">
      <img src={FooterImg} className="min-w-full h-[352px] object-cover" />
      <div className="absolute top-0  w-full h-full align-middle flex flex-col md:flex-row">
        <h1 className="text-white text-24 md:text-[48px] leading-[41px] font-extrabold text-center md:mr-[95px] mb-8 md:mb-0 px-[25%]  md:px-0">
          Elevate Your Business with VenueBoost
        </h1>
        <a
          className="text-[#240b3b] bg-white rounded-md text-16 md:text-18 leading-8 font-extrabold text-center px-9 md:px-20 py-1 md:py-[10px] whitespace-nowrap"
          href="/get-started"
        >
          ACCESS FREE PLAN
        </a>
      </div>
    </div> */}
  </div>
);

export default ProductDetail;
