import React, { useState } from "react";

const ProductImage = ({
  product,
  onAddCard,
  buttonLabel,
  onClickView,
  topIcon,
  showBottom,
  cartProp,
}) => {
  const [showCart, setShowCart] = useState(false);
  return (
    <div
      className="relative rounded-[11px] md:rounded-none overflow-hidden"
      onMouseEnter={() => setShowCart(true)}
      onMouseLeave={() => setShowCart(false)}
    >
      <img src={product.icon} alt="" className="w-full h-fit" />
      {product?.offer && product.offer.length > 0 && (
        <div className="offer-tag ">
          <span className="offer-label">{product.offer}</span>
        </div>
      )}
      {showCart && cartProp && (
        <>
          <div
            className="add-to-cart flex justify-center !py-[5px] px-5 md:!py-5 items-center !w-[80%] x-5 cursor-pointer rounded-[10px]  !h-[28px] md:!h-[50px]"
            onClick={onAddCard}
          >
            <span>{buttonLabel ?? product?.label}</span>
          </div>
          <div
            className="view-icon flex justify-center items-center cursor-pointer"
            onClick={onClickView}
          >
            <span>{topIcon}</span>
          </div>
        </>
      )}

      {showBottom && (
        <div
          className="add-to-cart flex justify-center !py-[5px] px-5 md:!py-5 items-center !w-[80%] x-5 cursor-pointer rounded-[10px]  !h-[28px] md:!h-[50px]"
          onClick={onAddCard}
        >
          <span className="!text-[13px] md:!text-16">{buttonLabel ?? product?.label}</span>
        </div>
      )}
    </div>
  );
};

export default ProductImage;
