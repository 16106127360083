import React, { useEffect, useState } from "react";
import { MdAnimation, MdDiscount, MdRepeat } from "react-icons/md";
import Advantage from "../../components/Advantage";
import Faq from "../../components/Faq";
import "react-multi-carousel/lib/styles.css";
import "./index.css";
import { Seo } from "../../components/Seo";
import { t } from "i18next";
import FoodImg from "../../assets/svgs/food&beverages.svg";
import EntertainmentImg from "../../assets/svgs/entertainment-pricing.svg";
import AccommodationImg from "../../assets/svgs/accommodations.svg";
import Retailimg from "../../assets/svgs/retail-management.svg";
import InformationIcon from "../../assets/svgs/info-icon.svg";
import TBDIcon from "../../assets/svgs/TBD.svg";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";

import FreePlanCard from "../../components/FreePlanCard";
import { text } from "../resources/faqs";

const priceText = (label) => t(`pricing.${label}`);

const generalFaq = [
  {
    id: 1,
    status: false,
    title: text("pricingPlansTitle"),
    content: text("pricingPlansContent"),
  },
  {
    id: 2,
    status: false,
    title: text("enterprisePlanTitle"),
    content: text("enterprisePlanContent"),
  },
  {
    id: 3,
    status: false,
    title: text("optimizePlanTitle"),
    content: text("optimizePlanContent"),
  },
  {
    id: 4,
    status: false,
    title: text("elevatePlanTitle"),
    content: text("elevatePlanContent"),
  },
  {
    id: 5,
    status: false,
    title: text("launchPlanTitle"),
    content: text("launchPlanContent"),
  },
  {
    id: 6,
    status: false,
    title: text("discoverPlanTitle"),
    content: text("discoverPlanContent"),
  },
  {
    id: 7,
    status: false,
    title: text("customizationOptionsTitle"),
    content: text("customizationOptionsContent"),
  },
  {
    id: 8,
    status: false,
    title: text("supportProvidedTitle"),
    content: text("supportProvidedContent"),
  },
];

const pricingData = {
  food: {
    monthly: {
      free: 0,
      freeSubtitle: priceText("AccessEssential"),
      launch: 49,
      launchSubtitle: priceText("IdealForSmallBusinesses"),
      elevate: 129,
      elevateSubtitle: priceText("ScaleYourBusiness"),
      optimize: 249,
      optimizeSubtitle: priceText("UnlockFullPotential"),
    },
    annual: {
      free: 0,
      freeSubtitle: priceText("AccessEssential"),
      launch: 490,
      launchSubtitle: priceText("IdealForSmallBusinesses"),
      elevate: 1290,
      elevateSubtitle: priceText("ScaleYourBusiness"),
      optimize: 2490,
      optimizeSubtitle: priceText("UnlockFullPotential"),
    },
  },
  sport_entertainment: {
    monthly: {
      free: 0,
      freeSubtitle: priceText("AccessEssential"),
      launch: 79,
      launchSubtitle: priceText("GetStartedEssentialTools"),
      elevate: 169,
      elevateSubtitle: priceText("ScaleYourBusiness"),
      optimize: 329,
      optimizeSubtitle: priceText("UnlockFullPotential"),
    },
    annual: {
      free: 0,
      freeSubtitle: priceText("AccessEssential"),
      launch: 790,
      launchSubtitle: priceText("GetStartedEssentialTools"),
      elevate: 1690,
      elevateSubtitle: priceText("ScaleYourBusiness"),
      optimize: 3290,
      optimizeSubtitle: priceText("UnlockFullPotential"),
    },
  },
  accommodation: {
    monthly: {
      free: 0,
      freeSubtitle: priceText("AccessEssential"),
      launch: 59,
      launchSubtitle: priceText("IdealForSmallBusinesses"),
      elevate: 149,
      elevateSubtitle: priceText("UnlockComprehensiveFeatures"),
      optimize: 299,
      optimizeSubtitle: priceText("AccessAdvancedCapabilities"),
    },
    annual: {
      free: 0,
      freeSubtitle: priceText("AccessEssential"),
      launch: 590,
      launchSubtitle: priceText("IdealForSmallBusinesses"),
      elevate: 1490,
      elevateSubtitle: priceText("UnlockComprehensiveFeatures"),
      optimize: 2990,
      optimizeSubtitle: priceText("AccessAdvancedCapabilities"),
    },
  },
  retail: {
    monthly: {
      free: 0,
      freeSubtitle: priceText("AccessEssential"),
      launch: 69,
      launchSubtitle: priceText("BeginRetailJourney"),
      elevate: 159,
      elevateSubtitle: priceText("GainAccessComprehensiveSuite"),
      optimize: 319,
      optimizeSubtitle: priceText("AccessAdvancedCapabilities"),
    },
    annual: {
      free: 0,
      freeSubtitle: priceText("AccessEssential"),
      launch: 690,
      launchSubtitle: priceText("BeginRetailJourney"),
      elevate: 1590,
      elevateSubtitle: priceText("GainAccessComprehensiveSuite"),
      optimize: 3190,
      optimizeSubtitle: priceText("UnlockAdvancedCapabilities"),
    },
  },
};

const PricingPlans = () => {
  const navigate = useNavigate();
  const [billingMethod, setBillingMethod] = useState("monthly");
  const [venueType, setVenueType] = useState("food");
  const [visibleVoucherModal, setVisibleVoucherModal] = useState(false);
  const [visibleSaveModal, setVisibleSaveModal] = useState(false);
  const [visibleFreemiumModal, setVisibleFreemiumModal] = useState(false);

  const [venueColor, setVenueColor] = useState("");

  const suggestedPlan = priceText("Elevate");

  const navigateToUrl = (url) => {
    navigate(url);
  };

  useEffect(() => {
    if (venueType === "food") setVenueColor("#17CE8C");
    else if (venueType === "sport_entertainment") setVenueColor("#F4D35E");
    else if (venueType === "accommodation") setVenueColor("#6DDFE2");
    else if (venueType === "retail") setVenueColor("#E14848");
  }, [venueType]);

  const currentPricing = pricingData[venueType][billingMethod];

  // For the free plan
  const freePrice = currentPricing ? currentPricing.free : 0;
  const freeSubtitle = currentPricing ? currentPricing.freeSubtitle : 0;

  // For the launch plan
  const launchPrice = currentPricing ? currentPricing.launch : 0;
  const launchSubtitle = currentPricing ? currentPricing.launchSubtitle : "";

  // For the elevate plan
  const elevatePrice = currentPricing ? currentPricing.elevate : 0;
  const elevateSubtitle = currentPricing ? currentPricing.elevateSubtitle : "";

  // For the optimize plan
  const optimizePrice = currentPricing ? currentPricing.optimize : 0;
  const optimizeSubtitle = currentPricing
    ? currentPricing.optimizeSubtitle
    : "";

  const currentUrl = "https://venueboost.io/pricing-plans";
  const seoTitle = priceText("ExploreVenueBoost");
  const seoDescription = priceText("DiscoverVenueBoostPricingPlans");

  return (
    <div className="align-col-middle">
      <Seo
        title={seoTitle + " | VenueBoost"}
        description={seoDescription}
        url={currentUrl}
      />

      <div className="  ">
        <div
          className={
            "pricing-plans mx-auto max-w-screen-main   align-middle flex-col "
          }
        >
          <div className="flex flex-col w-full my-8 md:my-16 gap-4">
            <div className="flex justify-center items-center bg-clip-text text-transparent bg-gradient-to-r from-[#280B3B] via-[#5D1C82] to-[#BC14FE] text-[28px] md:text-[48px] font-extrabold">
              {priceText("PricingPlans")}
            </div>
            <div className="flex justify-center text-[#161616] text-16 md:text-20 font-semibold px-4 md:px-0 text-center">
              {priceText("ExploreSubscriptionOptions")}
            </div>
          </div>
          <div className="flex flex-col md:flex-row align-middle mt-3 md:mt-12 mb-4 md:mb-4 w-full md:w-[90%] justify-center md:justify-between">
            {/*{!isMobile && (*/}
            <div className="flex flex-row md:gap-[14px] p-[6px] w-full md:w-auto justify-between md:justify-center mx-auto items-start md:items-center px-4 ">
              <div
                onClick={() => setVenueType("food")}
                className={`md:w-[183px] cursor-pointer flex-col text-[12px] md:leading-6 md:text-14  rounded-[16px] px-1 md:px-3 py-4 align-middle text-center justify-center items-center ${
                  venueType === "food"
                    ? `text-[#240b3b] md:border-[2px] md:border-[#17CE8C] font-bold md:bg-[#17CE8C] md:bg-opacity-10`
                    : "text-[#676879] font-medium"
                } `}
              >
                <img
                  alt=""
                  src={FoodImg}
                  className={`mb-2 md:w-[40px] md:h-[40px] mx-auto bg-[#17CE8C] rounded-md ${
                    venueType === "food"
                      ? "w-[42px] h-[42px]"
                      : "w-[33px] h-[33px]"
                  }`}
                />
                {t("components.pricing.industry_title1")}
              </div>
              <div
                onClick={() => setVenueType("sport_entertainment")}
                className={`md:w-[183px] cursor-pointer flex-col text-[12px] md:leading-6 md:text-14  rounded-[16px] px-1 md:px-3 py-4 align-middle text-center justify-center items-center ${
                  venueType === "sport_entertainment"
                    ? `text-[#240b3b] md:border-[2px] md:border-[#F4D35E] font-bold md:bg-[#F4D35E] md:bg-opacity-10`
                    : "text-[#676879] font-medium"
                } `}
              >
                <img
                  alt=""
                  src={EntertainmentImg}
                  className={`mb-2 md:w-[40px] md:h-[40px] mx-auto bg-[#F4D35E] rounded-md ${
                    venueType === "sport_entertainment"
                      ? "w-[42px] h-[42px]"
                      : "w-[33px] h-[33px]"
                  }`}
                />
                {t("components.pricing.industry_title2")}
              </div>
              <div
                onClick={() => setVenueType("accommodation")}
                className={`md:w-[183px] flex-col text-[12px] md:leading-6 md:text-14  rounded-[16px] px-1 md:px-3 py-4 align-middle cursor-pointer text-center justify-center items-center ${
                  venueType === "accommodation"
                    ? `text-[#240b3b] md:border-[2px] md:border-[#6DDFE2] font-bold md:bg-[#6DDFE2] md:bg-opacity-10`
                    : "text-[#676879] font-medium"
                } `}
              >
                <img
                  alt=""
                  src={AccommodationImg}
                  className={`mb-2 md:w-[40px] md:h-[40px] mx-auto bg-[#6DDFE2] rounded-md ${
                    venueType === "accommodation"
                      ? "w-[42px] h-[42px]"
                      : "w-[33px] h-[33px]"
                  }`}
                />
                {t("components.pricing.industry_title3")}
              </div>
              <div
                onClick={() => setVenueType("retail")}
                className={`md:w-[183px] flex-col text-[12px] cursor-pointer md:leading-6 md:text-14  rounded-[16px] px-1 md:px-3 py-4 align-middle text-center justify-center items-center ${
                  venueType === "retail"
                    ? `text-[#240b3b] md:border-[2px] md:border-[#E14848] font-bold md:bg-[#E14848] md:bg-opacity-10`
                    : "text-[#676879] font-medium"
                } `}
              >
                <img
                  alt=""
                  src={Retailimg}
                  className={`mb-2 md:w-[40px] md:h-[40px] mx-auto bg-[#E14848] rounded-md ${
                    venueType === "retail"
                      ? "w-[42px] h-[42px]"
                      : "w-[33px] h-[33px]"
                  }`}
                />{" "}
                {t("components.pricing.industry_title4")}
              </div>
            </div>

            <div className="flex h-full align-middle mt-4 md:mt-0 justify-center items-center">
              <div className="border-[1px] border-[#E3E3E3] flex flex-row rounded-[15px] align-middle ">
                <div
                  onClick={() => setBillingMethod("monthly")}
                  className={`text-[13px] leading-6 md:text-14  cursor-pointer font-bold px-4 py-3 ${
                    billingMethod === "monthly"
                      ? "text-white !bg-gradient-to-r from-[#280B3B] via-[#5D1C82] to-[#BC14FE] rounded-[15px]"
                      : ""
                  }`}
                >
                  {priceText("monthly")}
                </div>
                <div
                  onClick={() => setBillingMethod("annual")}
                  className={`text-[13px] leading-6 md:text-14 cursor-pointer font-bold px-4 py-3 ${
                    billingMethod === "annual"
                      ? "text-white !bg-gradient-to-r from-[#280B3B] via-[#5D1C82] to-[#BC14FE]  rounded-[15px]"
                      : ""
                  } `}
                >
                  {priceText("yearly")}
                </div>
              </div>
            </div>
          </div>

          {/*<Pricing className="block md:hidden " billingMethod={billingMethod} />*/}
          <div className="md:block mt-9">
            <div className="align-col-middle">
              <div className="w-[100vw]">
                <div className=" px-4 sm:px-6 md:px-8 lg:px-16 max-w-screen-main mx-auto flex flex-col">
                  <div
                    className={
                      "grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-5 gap-3 xl:gap-3 mb-20"
                    }
                  >
                    <div
                      className={`rounded-[13px] border-[#E0E0E0] relative border-[1px] overflow-hidden `}
                    >
                      <div className="text-14 font-bold leading-6 text-white py-[6px] align-middle  w-full rounded-t-[16px]   bg-[#808080] border-[#808080] border-[1px] box-content translate-x-[-1px]">
                        {priceText("welcomeAboard")}
                      </div>
                      <div className="bg-white h-full rounded-2xl w-full  flex flex-col ">
                        <div
                          className="bg-white  pt-10 px-6 flex flex-col justify-start border-[#E0E0E0]"
                          style={{ paddingBottom: "55px" }}
                        >
                          <div className="h-full flex flex-col items-start">
                            <div
                              className={`flex w-full justify-left items-left leading-[8px] mb-2`}
                            >
                              <p className="text-20 text-[#333333] font-extrabold leading-[22px] mb-[0px]">
                                {priceText("Discover")}
                              </p>
                            </div>
                            <p className="text-[48px] text-[#333333] font-bold  leading-[55px] align-middle flex mb-[16px]">
                              ${freePrice}
                              <span className="text-[15px] content-center ml-1">
                                {billingMethod === "monthly" ? "month" : "year"}
                              </span>
                            </p>
                            <p className="text-[#676879] text-14 font-medium leading-[22px] min-h-[88px] mb-[18px]">
                              {freeSubtitle}
                            </p>
                          </div>
                          <div className="flex">
                            <button
                              className={` xl:text-14 w-full text-white py-[11px]  rounded-[40px] transition ease-in-out hover:scale-105`}
                              style={{
                                background: "white",
                                color: "rgb(46, 39, 60)",
                                border: "1PX SOLID rgb(46, 39, 60)",
                              }}
                              onClick={() => {
                                navigateToUrl("/get-started");
                              }}
                            >
                              {priceText("GetStarted")}
                            </button>
                          </div>
                        </div>
                        <hr className="border-[#E0E0E0] border-[1px] mx-6" />
                        <div className="mt-6 px-6 w-full">
                          <p className="text-16 mb-6 font-bold">
                            {priceText("DiscoverIncludes")}
                          </p>
                          {venueType === "food" && (
                            <>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText(
                                    "ManageAndStreamlineBookingProcess"
                                  )}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-6"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("StreamlinedReservationsLimited")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText(
                                    "OrganizeAndUpdateMenuEfficiently"
                                  )}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-3"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("MenuManagementLimited")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("TrackStockLevels")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-6"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("BasicInventoryManagement")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("GainBusinessInsights")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-3"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("BasicInventoryManagement")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("ManageGuestProfiles")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-6"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("BasicGuestManagement")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("AccessMarketingTools")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-6"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("BasicMarketingStrategy")}
                                </p>
                              </div>
                            </>
                          )}

                          {venueType === "sport_entertainment" && (
                            <>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText(
                                    "ManageAndStreamlineBookingProcess"
                                  )}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-6"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("BookingsManagementLimit")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText(
                                    "OrganizeUpdateVenueItems40"
                                  )}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-3"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("ItemsManagementLimit")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("TrackStockLevelsLimit40")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-6"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("InventoryManagementLimit")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("GainBusinessInsights")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-3"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("AnalyticsReportingBasic")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("ManageCustomerProfiles")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-6"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("CustomerManagementLimit")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("AccessMarketingTools")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-3 "
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("MarketingToolsLimit")}
                                </p>
                              </div>
                            </>
                          )}
                          {venueType === "accommodation" && (
                            <>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText(
                                    "ManageStreamlineBookingProcess"
                                  )}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-6"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("BookingsManagementLimit30")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("OrganizeManageUnitsRooms")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-3"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("UnitsRoomsManagementLimit")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("TrackStockLevelsLimit30")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-6"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("InventoryManagementLimit30")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("OrganizeUpdateVenueItems")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-6"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("ItemsManagementLimit30")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("ManageGuestProfiles75")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-3"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("GuestManagementLimit")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("AccessMarketingTools")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-3"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("MarketingToolsLimit")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("GainBusinessInsights")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-3"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("AnalyticsReportingBasic")}
                                </p>
                              </div>
                            </>
                          )}

                          {venueType === "retail" && (
                            <>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("ManageStreamlineOrders")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-6"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("OrderManagementLimit")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("OrganizeUpdateStoreItems")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-3"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("ProductsManagementLimit")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("TrackStockLevels")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-6"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("InventoryManagementLimit50")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText(
                                    "GainInsightsBasicAnalytics"
                                  )}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-6"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("DashboardRevenueAnalyticsBasic")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("AccessMarketingTools")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-3"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("MarketingToolsLimit")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText(
                                    "ManageStoreEssentialOperations"
                                  )}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-1"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("BasicStoreManagement")}
                                </p>
                              </div>
                            </>
                          )}
                          <hr
                            className="border-[#E0E0E0] border-[2px] mb-4 mx-0"
                            style={{ borderColor: "#3d1e59" }}
                          />
                          <p className="text-16 mb-2 font-bold">
                            Customization:
                          </p>

                          <div className="flex flex-row-reverse justify-between gap-2">
                            <Tooltip
                              arrow
                              placement="top"
                              enterTouchDelay={0}
                              title={priceText("NoCustomizationDiscoverPlan")}
                            >
                              <img
                                alt=""
                                src={InformationIcon}
                                className="w-5 h-5 mt-3"
                              />
                            </Tooltip>
                            <p className="text-16 mb-6">
                              {priceText("NotAvailableInDiscoverPlan")}
                            </p>
                          </div>
                          <p className="text-16 mb-2 font-bold">Support:</p>
                          <div className="flex flex-row-reverse justify-between gap-2">
                            <Tooltip
                              arrow
                              placement="top"
                              enterTouchDelay={0}
                              title={priceText("UnlimitedAccessHelpCenter")}
                            >
                              <img
                                alt=""
                                src={InformationIcon}
                                className="w-5 h-5 mt-3"
                              />
                            </Tooltip>
                            <p
                              className="text-16 mb-6"
                              style={{ textWrap: "balance" }}
                            >
                              {priceText("NotAvailableInDiscoverPlan")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`rounded-[13px] ${
                        suggestedPlan === "Launch"
                          ? "border-[#333333] border-[2px] relative box-border"
                          : "border-[#E0E0E0] border-[1px] overflow-hidden"
                      }`}
                    >
                      <div
                        className={`text-14 font-bold leading-6 text-white py-[6px] align-middle  w-full rounded-t-[16px] ${
                          suggestedPlan === "Launch"
                            ? "absolute top-[-16px] price-gradient-class border-[#240b3b]"
                            : "bg-[#808080] border-[#808080]"
                        }`}
                      >
                        {priceText("GreatValue")}
                      </div>
                      <div className="bg-white h-full rounded-2xl w-full  flex flex-col ">
                        <div className="bg-white  pt-10 pb-6 px-6 flex flex-col justify-start border-[#E0E0E0]">
                          <div className="h-full flex flex-col items-start">
                            <div
                              className={`flex w-full justify-left ${
                                suggestedPlan === "Launch"
                                  ? "items-center leading-[22px]"
                                  : "items-left leading-[8px]"
                              } mb-2`}
                            >
                              <p
                                className={`text-20 text-[#333333] font-extrabold ${
                                  suggestedPlan === "Launch"
                                    ? ""
                                    : "leading-[22px] mb-[0px]"
                                }`}
                              >
                                Launch
                              </p>
                              {suggestedPlan === "Launch" && (
                                <div
                                  className={
                                    "flex justify-center items-center "
                                  }
                                  style={{ marginLeft: "10px" }}
                                >
                                  <div
                                    className={`
                                            w-0 h-0 border-t-[8px] border-t-transparent
                                            border-r-[8px] border-r-[#240b3b]
                                            border-b-[8px] border-b-transparent`}
                                  />
                                  <div
                                    className={`px-3 py-1 rounded-[8px] price-gradient-class text-white text-[12px]`}
                                  >
                                    {priceText("Suggested")}
                                    <br />
                                    {priceText("ForYou")}
                                  </div>
                                </div>
                              )}
                            </div>
                            <p className="text-[48px] text-[#333333] font-bold  leading-[55px] align-middle flex mb-[16px]">
                              ${launchPrice}
                              <span className="text-[15px] content-center ml-1">
                                {billingMethod === "monthly" ? "month" : "year"}
                              </span>
                            </p>
                            <p className="text-[#676879] text-14 font-medium leading-[22px] min-h-[88px] mb-[18px]">
                              {launchSubtitle}
                            </p>
                          </div>
                          <div className="flex">
                            <button
                              onClick={() => {
                                navigateToUrl("/get-started");
                              }}
                              className={` xl:text-14 w-full text-white py-[11px]  rounded-[40px] transition ease-in-out hover:scale-105`}
                              style={{
                                background: "white",
                                color: "rgb(46, 39, 60)",
                                border: "1PX SOLID rgb(46, 39, 60)",
                                marginTop: `${
                                  suggestedPlan === "Launch" ? "5px" : "0px"
                                }`,
                              }}
                            >
                              {priceText("StartFreeTrial")}
                            </button>
                          </div>
                          <Tooltip
                            arrow
                            placement="bottom"
                            enterTouchDelay={0}
                            title={priceText("ExperiencePremiumFeatures")}
                          >
                            <div
                              style={{
                                color: "#3d1e59",
                                textAlign: "center",
                                marginTop: "10px",
                                fontWeight: "bold",
                                cursor: "pointer",
                              }}
                            >
                              {priceText("FourteenDaysFreeTrial")}
                            </div>
                          </Tooltip>
                        </div>
                        <hr className="border-[#E0E0E0] border-[1px] mx-6" />
                        <div className="mt-6 px-6 w-full">
                          <p className="text-16 mb-6 font-bold">
                            {priceText("AllFromDiscoverPlus")}
                          </p>
                          {venueType === "food" && (
                            <>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText(
                                    "ManageAndStreamlineBookingProcess"
                                  )}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-3"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText(
                                    "UnlimitedStreamlinedReservations"
                                  )}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("TrackStockLevels")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-1"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("FullInventoryManagement")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("EfficientlyManageTeam")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-1"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("StaffManagement")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("AccessMarketingTools")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-3"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("EnhancedMarketingStrategyTools")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("BuildCustomerLoyalty")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-3"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("LoyaltyAndRetentionProgram")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("FacilitateEasyPayments")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("PaymentLinks")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("CollectValuableFeedback")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-3"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("BasicGuestSurveysRatings")}
                                </p>
                              </div>
                            </>
                          )}

                          {venueType === "sport_entertainment" && (
                            <>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText(
                                    "ManageAndStreamlineBookingProcess"
                                  )}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-3"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("UnlimitedBookingsManagement")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("TrackStockLevelsLimit40")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-1"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("FullInventoryManagement")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("EfficientlyManageTeam")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-1"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("StaffManagement")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("AccessMarketingTools")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-2"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("EnhancedMarketingTools")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("BuildCustomerLoyalty")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-3"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  Basic{" "}
                                  {priceText("LoyaltyAndRetentionProgram")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("FacilitateEasyPayments")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("BasicPaymentLinks")}
                                </p>
                              </div>
                            </>
                          )}
                          {venueType === "accommodation" && (
                            <>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText(
                                    "ManageStreamlineBookingProcess"
                                  )}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-3"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("UnlimitedBookingsManagement")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText(
                                    "ManageStreamlineUnitsRooms"
                                  )}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-3"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("UnlimitedUnitsRoomsManagement")}
                                </p>
                              </div>

                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("TrackStockLevelsLimit30")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-1"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("FullInventoryManagement")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("EfficientlyManageTeam")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-1"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("StaffManagement")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("AccessMarketingTools")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-2"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("EnhancedMarketingTools")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("BuildCustomerLoyalty")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-3"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  Basic{" "}
                                  {priceText("LoyaltyAndRetentionProgram")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("FacilitateEasyPayments")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("PaymentLinks")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("CollectValuableFeedback")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-3"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("BasicGuestSurveysRatings")}
                                </p>
                              </div>
                            </>
                          )}

                          {venueType === "retail" && (
                            <>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("ManageStreamlineOrders")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-3"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("UnlimitedOrderManagement")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("TrackStockLevelsLimit")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-1"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("FullInventoryManagement")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("EfficientlyManageTeam")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-1"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("StaffManagement")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("AccessMarketingTools")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-2"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("EnhancedMarketingTools")}
                                  {priceText("StaffManagement")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("BuildCustomerLoyalty")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-3"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  Basic{" "}
                                  {priceText("LoyaltyAndRetentionProgram")}
                                </p>
                              </div>
                            </>
                          )}

                          <hr
                            className="border-[#E0E0E0] border-[2px] mb-4 mx-0"
                            style={{ borderColor: "#3d1e59" }}
                          />
                          <p className="text-16 mb-2 font-bold">
                            Customization:
                          </p>

                          <div className="flex flex-row-reverse justify-between gap-2">
                            <Tooltip
                              arrow
                              placement="top"
                              enterTouchDelay={0}
                              title={priceText("CustomizePlatformLookFeel")}
                            >
                              <img
                                alt=""
                                src={InformationIcon}
                                className="cursor-pointer w-5 h-5 mt-1"
                              />
                            </Tooltip>
                            <p className="text-16 mb-6">
                              {venueType === "food" &&
                                "UI Changes: $50 - $100 one-time fee."}
                              {venueType === "accommodation" &&
                                "$75 - $150 one-time fee."}
                              {venueType === "sport_entertainment" &&
                                "$85 - $170 one-time fee."}
                              {venueType === "retail" &&
                                "$80 - $160 one-time fee."}
                            </p>
                          </div>
                          <p className="text-16 mb-2 font-bold">
                            Communication Templates:
                          </p>
                          <div className="flex flex-row-reverse justify-between gap-2">
                            <Tooltip
                              arrow
                              placement="top"
                              enterTouchDelay={0}
                              title={priceText(
                                "PersonalizeCommunicationTemplates"
                              )}
                            >
                              <img
                                alt=""
                                src={InformationIcon}
                                className="cursor-pointer w-5 h-5"
                              />
                            </Tooltip>
                            <p
                              className="text-16 mb-6"
                              style={{ textWrap: "balance" }}
                            >
                              {venueType === "food" && "$50 per template."}
                              {venueType === "accommodation" &&
                                priceText("OneTimeFee60")}
                              {venueType === "sport_entertainment" &&
                                priceText("OneTimeFee70")}
                              {venueType === "retail" && "$65 one-time fee."}
                            </p>
                          </div>
                          <p className="text-16 mb-2 font-bold">Support:</p>
                          <div className="flex flex-row-reverse justify-between gap-2">
                            <Tooltip
                              arrow
                              placement="top"
                              enterTouchDelay={0}
                              title={priceText("AssistanceViaEmail")}
                            >
                              <img
                                alt=""
                                src={InformationIcon}
                                className="cursor-pointer w-5 h-5 mt-3"
                              />
                            </Tooltip>
                            <p
                              className="text-16 mb-6"
                              style={{ textWrap: "balance" }}
                            >
                              {priceText("EmailSupport48Hours")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`rounded-[13px] ${
                        suggestedPlan === "Elevate"
                          ? `border-[${venueColor}] border-[2px] relative box-border`
                          : "border-[#E0E0E0] border-[1px] overflow-hidden"
                      }`}
                    >
                      <div
                        className={`text-14 font-bold leading-6 text-white py-[6px] align-middle  w-full rounded-t-[16px] ${
                          suggestedPlan === "Elevate"
                            ? `absolute top-[-16px] border-[#240b3b] bg-[${venueColor}]`
                            : "bg-[#808080] border-[#808080]"
                        }`}
                      >
                        Just Right
                      </div>
                      <div className="bg-white h-full w-full rounded-[13px]  flex flex-col ">
                        <div className="bg-white  pt-10 pb-6 px-6 flex flex-col justify-start">
                          <div className="h-full flex flex-col items-start">
                            <div
                              className={`flex w-full justify-left ${
                                suggestedPlan === "Elevate"
                                  ? "items-center leading-[22px]"
                                  : "items-left leading-[8px]"
                              } mb-2`}
                            >
                              <p
                                className={`text-20 text-[#333333] font-extrabold ${
                                  suggestedPlan === "Elevate"
                                    ? ""
                                    : "leading-[22px] mb-[0px]"
                                }`}
                              >
                                Elevate
                              </p>
                              {suggestedPlan === "Elevate" && (
                                <div
                                  className={
                                    "flex justify-center items-center "
                                  }
                                  style={{ marginLeft: "10px" }}
                                >
                                  <div
                                    className={`px-3 py-1 rounded-[8px] text-white text-[12px] bg-[${venueColor}]`}
                                  >
                                    {priceText("MostPopular")}
                                  </div>
                                </div>
                              )}
                            </div>
                            <p
                              className="text-[48px] text-[#333333] font-bold  leading-[55px] align-middle flex mb-[16px]"
                              style={{
                                marginBottom: `${
                                  suggestedPlan === "Elevate" ? "20px" : "15px"
                                }`,
                              }}
                            >
                              ${elevatePrice}
                              <span className="text-[15px] content-center ml-1">
                                {billingMethod === "monthly" ? "month" : "year"}
                              </span>
                            </p>
                            <p className="text-[#676879] text-14 font-medium leading-[22px] min-h-[88px] mb-[18px]">
                              {elevateSubtitle}
                            </p>
                          </div>
                          <div className="flex">
                            <button
                              onClick={() => {
                                navigateToUrl("/get-started");
                              }}
                              className={` xl:text-14 w-full text-white py-[11px]  rounded-[40px] transition ease-in-out hover:scale-105`}
                              style={{
                                background: "white",
                                color: "#240b3b",
                                border: "1PX SOLID #240b3b",
                              }}
                            >
                              {priceText("StartFreeTrial")}
                            </button>
                          </div>
                          <Tooltip
                            arrow
                            placement="bottom"
                            enterTouchDelay={0}
                            title={priceText("ExperiencePremiumFeatures")}
                          >
                            <div
                              style={{
                                color: "#3d1e59",
                                textAlign: "center",
                                marginTop: "10px",
                                fontWeight: "bold",
                                cursor: "pointer",
                              }}
                            >
                              {priceText("FourteenDaysFreeTrial")}
                            </div>
                          </Tooltip>
                        </div>
                        <hr className="border-[#E0E0E0] border-[1px] mx-6" />

                        <div className="mt-6 px-6 w-[100%]">
                          <p className="text-16 mb-6 font-bold">
                            {priceText("AllFromLaunchPlus")}
                          </p>
                          {venueType === "food" && (
                            <>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  enterTouchDelay={0}
                                  title={priceText(
                                    "AutomateMarketingActivities"
                                  )}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-1"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("MarketingAutomation")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  enterTouchDelay={0}
                                  title={priceText(
                                    "ExpandThroughAffiliatePartnerships"
                                  )}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-1"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("AffiliatePartnerships")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  enterTouchDelay={0}
                                  title={priceText("GainBusinessInsights")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-3"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("AdvancedAnalyticsReporting")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  enterTouchDelay={0}
                                  title={priceText("StreamlineDeliveryProcess")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-3"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("DeliveryOrdersManagement")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  enterTouchDelay={0}
                                  title={priceText("UnlockDeeperInsights")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-3"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("AdvancedGuestBehaviorAnalytics")}
                                </p>
                              </div>
                            </>
                          )}

                          {venueType === "sport_entertainment" && (
                            <>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  enterTouchDelay={0}
                                  title={priceText(
                                    "AutomateMarketingActivities"
                                  )}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-1"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("MarketingAutomation")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  enterTouchDelay={0}
                                  title={priceText(
                                    "ExpandThroughAffiliatePartnerships"
                                  )}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-1"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("BasicAffiliatePartnerships")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  enterTouchDelay={0}
                                  title={priceText("GainBusinessInsights")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-3"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("AdvancedAnalyticsReporting")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  enterTouchDelay={0}
                                  title={priceText("ElevateCustomerExperience")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-3"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("EntertainmentMembershipProgram")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  enterTouchDelay={0}
                                  title={priceText("AdvancedCustomerSurveys")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-3"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("AdvancedCustomerSurveysRatings")}
                                </p>
                              </div>
                            </>
                          )}
                          {venueType === "accommodation" && (
                            <>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  enterTouchDelay={0}
                                  title={priceText(
                                    "AutomateMarketingActivities"
                                  )}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-1"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("MarketingAutomation")}{" "}
                                  {priceText("IncludingGuestJourneySupport")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  enterTouchDelay={0}
                                  title={priceText(
                                    "ExpandThroughAffiliatePartnerships"
                                  )}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-1"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("BasicAffiliatePartnerships")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  enterTouchDelay={0}
                                  title={priceText("GainBusinessInsights")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-3"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("AdvancedAnalyticsReporting")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  enterTouchDelay={0}
                                  title={priceText(
                                    "iCalIntegrationDescription"
                                  )}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-1"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("icalIntegrationTitleModal")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  enterTouchDelay={0}
                                  title={priceText("UnlockDeeperInsights")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-3"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("AdvancedGuestBehaviorAnalytics")}
                                </p>
                              </div>
                            </>
                          )}

                          {venueType === "retail" && (
                            <>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  enterTouchDelay={0}
                                  title={priceText(
                                    "AutomateMarketingActivities"
                                  )}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-1"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("MarketingAutomation")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  enterTouchDelay={0}
                                  title={priceText(
                                    "ExpandThroughAffiliatePartnerships"
                                  )}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-1"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("BasicAffiliatePartnerships")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  enterTouchDelay={0}
                                  title={priceText("AdvancedDashboardInsights")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-3"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText(
                                    "AdvancedDashboardRevenueAnalytics"
                                  )}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  enterTouchDelay={0}
                                  title={priceText(
                                    "RetailCustomerLoyaltyProgram"
                                  )}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-3"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("RetailCustomerLoyaltyProgram1")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  enterTouchDelay={0}
                                  title={priceText("AdvancedCustomerSurveys")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-3"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("CustomerSurveysRatings")}
                                </p>
                              </div>

                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  enterTouchDelay={0}
                                  title={priceText("RealTimeInventorySync")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-1"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("ConsistentInventory")}
                                </p>
                              </div>
                            </>
                          )}

                          <hr
                            className="border-[#E0E0E0] border-[2px] mb-4 mx-0"
                            style={{ borderColor: "#3d1e59" }}
                          />
                          <p className="text-16 mb-2 font-bold">
                            {priceText("Customization")}:
                          </p>

                          <div className="flex flex-row-reverse justify-between gap-2">
                            <Tooltip
                              arrow
                              placement="top"
                              enterTouchDelay={0}
                              title={priceText(
                                "EnhancePlatformAdditionalFeatures"
                              )}
                            >
                              <img
                                alt=""
                                src={InformationIcon}
                                className="cursor-pointer w-5 h-5 mt-3"
                              />
                            </Tooltip>
                            <p className="text-16 mb-6">
                              {venueType === "food" &&
                                priceText("FeatureAdditions100")}
                              {venueType === "accommodation" &&
                                priceText("FeatureAdditions150")}
                              {venueType === "sport_entertainment" &&
                                priceText("FeatureAdditions170")}
                              {venueType === "retail" &&
                                priceText("FeatureAdditions160")}
                            </p>
                          </div>
                          <div className="flex flex-row-reverse justify-between gap-2">
                            <Tooltip
                              arrow
                              placement="top"
                              enterTouchDelay={0}
                              title={priceText("CustomizePlatformLookFeel")}
                            >
                              <img
                                alt=""
                                src={InformationIcon}
                                className="cursor-pointer w-5 h-5 mt-3"
                              />
                            </Tooltip>
                            <p className="text-16 mb-6">
                              {venueType === "food" &&
                                priceText("AdvancedUICustomization200")}
                              {venueType === "accommodation" &&
                                priceText("AdvancedUICustomization250")}
                              {venueType === "sport_entertainment" &&
                                priceText("AdvancedUICustomization270")}
                              {venueType === "retail" &&
                                priceText("AdvancedUICustomization260")}
                            </p>
                          </div>
                          <div className="flex flex-row-reverse justify-between gap-2">
                            <Tooltip
                              arrow
                              placement="top"
                              enterTouchDelay={0}
                              title={priceText("ElevatePlanDiscount")}
                            >
                              <img
                                alt=""
                                src={InformationIcon}
                                className="w-5 h-5 mt-3"
                              />
                            </Tooltip>
                            <p className="text-16 mb-6">
                              {priceText("ElevateDiscount")}
                            </p>
                          </div>
                          <p className="text-16 mb-2 font-bold">Support:</p>
                          <div className="flex flex-row-reverse justify-between gap-2">
                            <Tooltip
                              arrow
                              placement="top"
                              enterTouchDelay={0}
                              title={priceText("AssistanceViaEmail")}
                            >
                              <img
                                alt=""
                                src={InformationIcon}
                                className="cursor-pointer w-5 h-5 mt-3"
                              />
                            </Tooltip>
                            <p
                              className="text-16 mb-6"
                              style={{ textWrap: "balance" }}
                            >
                              {priceText("PriorityEmailSupport")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`rounded-[13px] ${
                        suggestedPlan === "Optimize"
                          ? "border-[#333333] border-[2px] relative box-border"
                          : "border-[#E0E0E0] border-[1px] overflow-hidden"
                      }`}
                    >
                      <div
                        className={`text-14 font-bold leading-6 text-white py-[6px] align-middle  w-full rounded-t-[16px] ${
                          suggestedPlan === "Optimize"
                            ? "absolute top-[-16px] price-gradient-class border-[#240b3b]"
                            : "bg-[#808080] border-[#808080]"
                        }`}
                      >
                        Worth It
                      </div>
                      <div className="bg-white h-full w-full rounded-[13px]  flex flex-col ">
                        <div className="bg-white  pt-10 pb-6 px-6 flex flex-col justify-start border-[#E0E0E0]">
                          <div className="h-full flex flex-col items-start">
                            <div
                              className={`flex w-full justify-left ${
                                suggestedPlan === "Optimize"
                                  ? "items-center leading-[22px]"
                                  : "items-left leading-[8px]"
                              } mb-2`}
                            >
                              <p
                                className={`text-20 text-[#333333] font-extrabold ${
                                  suggestedPlan === "Optimize"
                                    ? ""
                                    : "leading-[22px] mb-[0px]"
                                }`}
                              >
                                {priceText("Optimize")}
                              </p>
                              {suggestedPlan === "Optimize" && (
                                <div
                                  className={
                                    "flex justify-center items-center "
                                  }
                                  style={{ marginLeft: "10px" }}
                                >
                                  <div
                                    className={`
                                            w-0 h-0 border-t-[8px] border-t-transparent
                                            border-r-[8px] border-r-[#240b3b]
                                            border-b-[8px] border-b-transparent`}
                                  />
                                  <div
                                    className={`px-3 py-1 rounded-[8px] price-gradient-class text-white text-[12px]`}
                                  >
                                    {priceText("Suggested")}
                                    <br />
                                    {priceText("ForYou")}
                                  </div>
                                </div>
                              )}
                            </div>
                            <p
                              className="text-[48px] text-[#333333] font-bold  leading-[55px] align-middle flex mb-[16px]"
                              style={{
                                marginTop: `${
                                  suggestedPlan === "Optimize" ? "5px" : "0px"
                                }`,
                              }}
                            >
                              ${optimizePrice}
                              <span className="text-[15px] content-center ml-1">
                                {billingMethod === "monthly" ? "month" : "year"}
                              </span>
                            </p>
                            <p className="text-[#676879] text-14 font-medium leading-[22px] min-h-[88px] mb-[18px]">
                              {optimizeSubtitle}
                            </p>
                          </div>
                          <div className="flex">
                            <button
                              onClick={() => {
                                navigateToUrl("/get-started");
                              }}
                              className={` xl:text-14 w-full text-white py-[11px]  rounded-[40px] transition ease-in-out hover:scale-105`}
                              style={{
                                background: "white",
                                color: "#240b3b",
                                border: "1PX SOLID #240b3b",
                              }}
                            >
                              {priceText("StartFreeTrial")}
                            </button>
                          </div>
                          <Tooltip
                            arrow
                            placement="bottom"
                            enterTouchDelay={0}
                            title={priceText("Extended30DayTrial")}
                          >
                            <div
                              style={{
                                color: "#3d1e59",
                                textAlign: "center",
                                marginTop: "10px",
                                fontWeight: "bold",
                                cursor: "pointer",
                              }}
                            >
                              {priceText("ThirtyDaysFreeTrial")}
                            </div>
                          </Tooltip>
                        </div>
                        <hr className="border-[#E0E0E0] border-[1px] mx-6" />

                        <div className="mt-6 px-6 w-[100%]">
                          <p className="text-16 mb-6 font-bold">
                            {priceText("AllFromElevatePlus")}:{" "}
                            {/*Solutions include, but not limited to:*/}
                          </p>
                          {venueType === "food" && (
                            <>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  enterTouchDelay={0}
                                  title={priceText("PremiumDiningExperiences")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-5"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("PremiumTablesPricingBidding")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  enterTouchDelay={0}
                                  title={priceText("EnhanceCustomerRetention")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-3"
                                  />
                                </Tooltip>

                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("DiningGuestLoyaltyProgram")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  enterTouchDelay={0}
                                  title={priceText("PersonalizeInterface")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-1"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("CustomizableBrandProfile")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  enterTouchDelay={0}
                                  title={priceText("SecureInPersonPayments")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-1"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("InPersonPayments")}
                                </p>
                              </div>
                            </>
                          )}

                          {venueType === "sport_entertainment" && (
                            <>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  enterTouchDelay={0}
                                  title={priceText("UnlockDeepInsights")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-5"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText(
                                    "AdvancedCustomerBehaviorAnalytics"
                                  )}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  enterTouchDelay={0}
                                  title={priceText("ElevateMarketingEfforts")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-1"
                                  />
                                </Tooltip>

                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("AdvancedMarketingTools")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  enterTouchDelay={0}
                                  title={priceText("PersonalizeInterface")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-1"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("CustomizableBrandProfile")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  enterTouchDelay={0}
                                  title={priceText("SecureInPersonPayments")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-1"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("InPersonPayments")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  enterTouchDelay={0}
                                  title={priceText(
                                    "ExpandBusinessAffiliatePartnerships"
                                  )}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-3"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("AdvancedAffiliatePartnerships")}
                                </p>
                              </div>
                            </>
                          )}
                          {venueType === "accommodation" && (
                            <>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  enterTouchDelay={0}
                                  title={priceText("EnhanceGuestRetention")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-3"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText(
                                    "AccommodationGuestLoyaltyProgram"
                                  )}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  enterTouchDelay={0}
                                  title={priceText(
                                    "StreamlineHousekeepingOperations"
                                  )}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-5"
                                  />
                                </Tooltip>

                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText(
                                    "HousekeepingManagementRealTimeUpdates"
                                  )}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  enterTouchDelay={0}
                                  title={priceText(
                                    "ExpandBusinessAffiliatePartnerships"
                                  )}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-2"
                                  />
                                </Tooltip>

                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("AdvancedAffiliatePartnerships")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  enterTouchDelay={0}
                                  title={priceText("PersonalizeInterface")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-1"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("CustomizableBrandProfile")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  enterTouchDelay={0}
                                  title={priceText("SecureInPersonPayments")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-1"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("InPersonPayments")}
                                </p>
                              </div>
                            </>
                          )}

                          {venueType === "retail" && (
                            <>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  enterTouchDelay={0}
                                  title={priceText(
                                    "StreamlineOptimizeOrderProcessing"
                                  )}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-2"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("AdvancedOrderManagement")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  enterTouchDelay={0}
                                  title={priceText(
                                    "CentralizedAnalyticsMultiBrandRetailers1"
                                  )}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-3"
                                  />
                                </Tooltip>

                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText(
                                    "CentralizedAnalyticsMultiBrandRetailers"
                                  )}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  enterTouchDelay={0}
                                  title={priceText("PersonalizeInterface")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-1"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("CustomizableBrandProfile")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  enterTouchDelay={0}
                                  title={priceText("SecureInPersonPayments")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-1"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("InPersonPayments")}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  enterTouchDelay={0}
                                  title={priceText("DelveIntoCustomerInsights")}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-3"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText(
                                    "AdvancedCustomerBehaviorAnalytics"
                                  )}
                                </p>
                              </div>
                              <div className="flex flex-row-reverse justify-between gap-2">
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  enterTouchDelay={0}
                                  title={priceText(
                                    "ManageStoresEssentialOperations"
                                  )}
                                >
                                  <img
                                    alt=""
                                    src={InformationIcon}
                                    className="cursor-pointer w-5 h-5 mt-3"
                                  />
                                </Tooltip>
                                <p
                                  className="text-16 mb-6"
                                  style={{ textWrap: "balance" }}
                                >
                                  {priceText("AdvancedStoreManagement")}
                                </p>
                              </div>
                            </>
                          )}
                          <hr
                            className="border-[#E0E0E0] border-[2px] mb-4 mx-0"
                            style={{ borderColor: "#3d1e59" }}
                          />
                          <p className="text-16 mb-2 font-bold">
                            Customization:
                          </p>

                          <div className="flex flex-row-reverse justify-between gap-2">
                            <Tooltip
                              arrow
                              placement="top"
                              enterTouchDelay={0}
                              title={priceText(
                                "EnhancePlatformAdditionalFeatures"
                              )}
                            >
                              <img
                                alt=""
                                src={InformationIcon}
                                className="cursor-pointer w-5 h-5 mt-3"
                              />
                            </Tooltip>
                            <p className="text-16 mb-6">
                              {venueType === "food" &&
                                priceText("MajorFeatureIntegrationCost500")}
                              {venueType === "accommodation" &&
                                priceText("MajorFeatureIntegrationCost600")}
                              {venueType === "sport_entertainment" &&
                                priceText("MajorFeatureIntegrationCost700")}
                              {venueType === "retail" &&
                                priceText("MajorFeatureIntegrationCost650")}
                            </p>
                          </div>
                          <div className="flex flex-row-reverse justify-between gap-2">
                            <Tooltip
                              arrow
                              placement="top"
                              enterTouchDelay={0}
                              title={priceText("OptimizePlanDiscount")}
                            >
                              <img
                                alt=""
                                src={InformationIcon}
                                className="w-5 h-5 mt-3"
                              />
                            </Tooltip>
                            <p className="text-16 mb-6">
                              {priceText("OptimizeDiscount")}
                            </p>
                          </div>

                          <p className="text-16 mb-2 font-bold">Support:</p>
                          <div className="flex flex-row-reverse justify-between gap-2">
                            <Tooltip
                              arrow
                              placement="top"
                              enterTouchDelay={0}
                              title={priceText("DirectPhoneSupport")}
                            >
                              <img
                                alt=""
                                src={InformationIcon}
                                className="cursor-pointer w-5 h-5 mt-3"
                              />
                            </Tooltip>
                            <p
                              className="text-16 mb-6"
                              style={{ textWrap: "balance" }}
                            >
                              {priceText("PhoneEmailSupport")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className={`rounded-[13px] border-[#E0E0E0] border-[1px] overflow-hidden`}
                    >
                      <div className="text-14 font-bold leading-6 text-white py-[6px] align-middle  w-full rounded-t-[16px] bg-[#808080] border-[#808080] border-[1px] box-content translate-x-[-1px]">
                        {priceText("BuiltForYou")}
                      </div>
                      <div className="bg-white h-full rounded-2xl w-full  flex flex-col ">
                        <div className="bg-white pt-10 pb-6 px-6 flex flex-col justify-start border-[#E0E0E0]">
                          <div className="h-full flex flex-col items-start">
                            <p className="text-20 text-[#333333] font-extrabold leading-[22px] mb-[6px]">
                              {priceText("Enterprise")}
                            </p>
                            <img
                              alt=""
                              src={TBDIcon}
                              className="h-[55px] w-[55px]"
                              style={{ marginTop: "8px", marginBottom: "8px" }}
                            />
                            <p className="text-[#676879] text-14 font-medium leading-[22px] min-h-[88px] mb-[18px]">
                              {priceText("CustomQuoteLargeScaleOperations")}
                            </p>
                          </div>
                          <div className="flex">
                            <button
                              className={` xl:text-14 w-full text-white py-[11px]  rounded-[40px] transition ease-in-out hover:scale-105`}
                              style={{
                                background: "white",
                                color: "rgb(46, 39, 60)",
                                border: "1PX SOLID rgb(46, 39, 60)",
                              }}
                              onClick={() => {
                                navigateToUrl("/get-started");
                              }}
                            >
                              {t("footer.get_started")}
                            </button>
                          </div>
                          <Tooltip
                            arrow
                            placement="bottom"
                            enterTouchDelay={0}
                            title={priceText("Extended30DayTrialForYou")}
                          >
                            <div
                              style={{
                                color: "#3d1e59",
                                textAlign: "center",
                                marginTop: "10px",
                                fontWeight: "bold",
                                cursor: "pointer",
                              }}
                            >
                              {priceText("ThirtyDaysFreeTrial")}
                            </div>
                          </Tooltip>
                        </div>
                        <hr className="border-[#E0E0E0] border-[1px] mx-6" />

                        <div className="mt-6 px-6 w-[100%]">
                          <p className="text-16 mb-6 font-bold">
                            {priceText("AllFromOptimizePlus")}:
                            {/*Solutions include, but not limited to:*/}
                          </p>
                          <div className="flex flex-row-reverse justify-between gap-2">
                            <Tooltip
                              arrow
                              placement="top"
                              enterTouchDelay={0}
                              title={priceText(
                                "PersonalizedServiceEnterprisePlan"
                              )}
                            >
                              <img
                                alt=""
                                src={InformationIcon}
                                className="cursor-pointer w-5 h-5 mt-4"
                              />
                            </Tooltip>
                            <p className="text-16 mb-6">
                              {priceText("DedicatedAccountManager")}{" "}
                            </p>
                          </div>
                          <div className="flex flex-row-reverse justify-between gap-2">
                            <img
                              alt=""
                              src={InformationIcon}
                              className="w-5 h-5 mt-8"
                            />
                            <p className="text-16 mb-6">
                              {priceText(
                                "CustomIntegrationsFeatureDevelopment"
                              )}
                              .
                            </p>
                          </div>
                          <div className="flex flex-row-reverse justify-between gap-2">
                            <img
                              alt=""
                              src={InformationIcon}
                              className="w-5 h-5 mt-3"
                            />
                            <p className="text-16 mb-6">
                              {priceText("PremiumSupportTraining")}.
                            </p>
                          </div>

                          <hr
                            className="border-[#E0E0E0] border-[2px] mb-4 mx-0"
                            style={{ borderColor: "#3d1e59" }}
                          />
                          <p className="text-16 mb-2 font-bold">Price:</p>
                          <div className="flex flex-row-reverse justify-between gap-2">
                            <Tooltip
                              arrow
                              placement="top"
                              enterTouchDelay={0}
                              title={priceText("CustomPricingOptions")}
                            >
                              <img
                                alt=""
                                src={InformationIcon}
                                className="w-5 h-5 mt-5"
                              />
                            </Tooltip>
                            <p
                              className="text-16 mb-6"
                              style={{ textWrap: "balance" }}
                            >
                              {venueType === "accommodation" &&
                                priceText("CustomPricingStarting599")}
                              {venueType === "retail" &&
                                priceText("CustomPricingStarting649")}
                              {venueType === "sport_entertainment" &&
                                priceText("CustomPricingStarting699")}
                              {venueType === "food" &&
                                priceText("CustomPricingStarting499")}
                            </p>
                          </div>

                          <p className="text-16 mb-2 font-bold">
                            {priceText("Customization")}:
                          </p>

                          <div className="flex flex-row-reverse justify-between gap-2">
                            <Tooltip
                              arrow
                              placement="top"
                              enterTouchDelay={0}
                              title={priceText("TailoredSolutionsCustomQuote")}
                            >
                              <img
                                alt=""
                                src={InformationIcon}
                                className="w-5 h-5 mt-7"
                              />
                            </Tooltip>
                            <p className="text-16 mb-6">
                              {priceText("CustomFeatureDevelopment")}
                            </p>
                          </div>
                          <div className="flex flex-row-reverse justify-between gap-2">
                            <Tooltip
                              arrow
                              placement="top"
                              enterTouchDelay={0}
                              title={priceText("EnterprisePlanDiscount")}
                            >
                              <img
                                alt=""
                                src={InformationIcon}
                                className="w-5 h-5 mt-5"
                              />
                            </Tooltip>
                            <p className="text-16 mb-6">
                              {priceText("EnterpriseDiscount")}
                            </p>
                          </div>
                          <p className="text-16 mb-2 font-bold">Support:</p>
                          <div className="flex flex-row-reverse justify-between gap-2">
                            <Tooltip
                              arrow
                              placement="top"
                              enterTouchDelay={0}
                              title={priceText("AroundTheClockPrioritySupport")}
                            >
                              <img
                                alt=""
                                src={InformationIcon}
                                className="w-5 h-5 mt-3"
                              />
                            </Tooltip>
                            <p
                              className="text-16 mb-6"
                              style={{ textWrap: "balance" }}
                            >
                              {priceText("PrioritySupport247")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="align-col-middle">
            <div className="w-[100vw] bg-[#f9f9f9] ">
              <div className="max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 py-8">
                {/*{isMobile && (*/}
                {/*  <div*/}
                {/*      className={`rounded-[13px] mt-8 border-[#E0E0E0] border-[1px] overflow-hidden`}*/}
                {/*  >*/}
                {/*    <div className="bg-white h-full rounded-2xl w-full  flex  px-[34px] py-5 items-center">*/}
                {/*      <div className="">*/}
                {/*        <div className="flex flex-col sm:flex-row">*/}
                {/*          <div className="bg-white px-1 flex flex-col justify-start border-[#E0E0E0] mb-3 sm:mb-0">*/}
                {/*            <div className="h-full flex flex-col items-start">*/}
                {/*              <p className="text-20 text-[#333333] font-extrabold leading-[22px] mb-[6px]">*/}
                {/*                Switch and Save*/}
                {/*              </p>*/}

                {/*              <p className="text-[#676879] text-14 font-medium leading-[22px]  mb-[6px]">*/}
                {/*                Plans*/}
                {/*              </p>*/}
                {/*            </div>*/}
                {/*          </div>*/}

                {/*          <div className="md:px-6">*/}
                {/*            <div className="flex gap-2">*/}
                {/*              <Tooltip arrow placement="top" enterTouchDelay={0} title={"Kickstart your journey with VenueBoost and enjoy a significant 30% discount on your first-year subscription."}>*/}
                {/*                <img
                alt="" onClick={() => setVisibleSaveModal(true)} src={InformationIcon} className="cursor-pointer w-5 h-5 sm:order-2" />*/}
                {/*              </Tooltip>*/}
                {/*                <p className="text-14 mb-2 sm:mb-0">*/}
                {/*                  30% Off: First-Year Savings*/}
                {/*                </p>*/}
                {/*            </div>*/}
                {/*            <div className="flex gap-2">*/}
                {/*              <Tooltip arrow placement="top" enterTouchDelay={0} title={"Take advantage of a prolonged, 60-day free trial, giving you ample time to explore and integrate our diverse features."}>*/}
                {/*                <img
                alt="" onClick={() => setVisibleSaveModal(true)} src={InformationIcon} className="cursor-pointer w-5 h-5 sm:order-2" />*/}
                {/*              </Tooltip>*/}
                {/*                <p className="text-14 mb-2 sm:mb-0">*/}
                {/*                  {priceText("Extended60DayTrial")}*/}
                {/*                </p>*/}
                {/*            </div>*/}
                {/*            <div className="flex gap-2">*/}
                {/*              <Tooltip arrow placement="top" enterTouchDelay={0} title={"Receive a generous $200 credit for customization, allowing you to tailor VenueBoost to your specific business needs."}>*/}
                {/*              <img
                alt="" onClick={() => setVisibleSaveModal(true)} src={InformationIcon} className="cursor-pointer w-5 h-5 sm:order-2" />*/}
                {/*              </Tooltip>*/}
                {/*                <p className="text-14 mb-2 sm:mb-0">*/}
                {/*                  {priceText("CustomizationCredit200")}*/}
                {/*                </p>*/}
                {/*            </div>*/}
                {/*          </div>*/}
                {/*          <div className="md:px-6">*/}
                {/*            <div className="flex gap-2">*/}
                {/*              <Tooltip arrow placement="top" enterTouchDelay={0} title={"Transition smoothly with our free data migration assistance, ensuring a hassle-free switch without additional costs."}>*/}
                {/*              <img
                alt="" src={InformationIcon} className="cursor-pointer w-5 h-5 sm:order-2" />*/}
                {/*              </Tooltip>*/}
                {/*              <p onClick={() => setVisibleSaveModal(true)} className="text-14 mb-2 sm:mb-0">*/}
                {/*                {priceText("ComplimentaryDataMigration")}*/}
                {/*              </p>*/}
                {/*            </div>*/}
                {/*            <div className="flex gap-2">*/}
                {/*              <Tooltip arrow placement="top" enterTouchDelay={0} title={"Be among the first to experience our innovative 'Interactive Customer Feedback Tool,' available to you for an exclusive 3-month period."}>*/}
                {/*              <img
                alt="" src={InformationIcon} className="cursor-pointer w-5 h-5 sm:order-2" />*/}
                {/*                </Tooltip>*/}
                {/*              <p onClick={() => setVisibleSaveModal(true)} className="text-14 mb-2 sm:mb-0">*/}
                {/*                {priceText("EarlyAccessToNewTools")}*/}
                {/*              </p>*/}
                {/*            </div>*/}
                {/*            <div className="flex gap-2">*/}
                {/*              <Tooltip arrow placement="top" enterTouchDelay={0} title={"Our expert team is committed to guiding you through every step, making your switch to VenueBoost straightforward and beneficial."}>*/}
                {/*              <img
                alt="" src={InformationIcon} className="cursor-pointer w-5 h-5 sm:order-2" />*/}
                {/*                  </Tooltip>*/}
                {/*              <p onClick={() => setVisibleSaveModal(true)} className="text-14 mb-2 sm:mb-0 ">*/}
                {/*                {priceText("Dedicated Support")}*/}
                {/*              </p>*/}
                {/*            </div>*/}
                {/*          </div>*/}

                {/*        </div>*/}

                {/*      </div>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*)}*/}
                {/*{!isMobile && (*/}
                <div
                  className={`rounded-[13px] mt-8 border-[#E0E0E0] border-[1px] overflow-hidden`}
                >
                  <div className="bg-white h-full rounded-2xl w-full  flex flex-col md:flex-row items-center">
                    {/* <img
                    alt="" src={OverviewIcon} className="h-[80px] w-[80px] mr-9 cursor-pointer" onClick={() => setVisibleSaveModal(true)} /> */}
                    <div className="">
                      <div className="flex flex-col md:flex-row w-full gap-4">
                        <div className=" bg-white w-full md:w-1/2  px-6 flex flex-col justify-start border-[#E0E0E0] md:pl-10 py-4 md:py-8">
                          <div className="h-full flex flex-col items-start gap-2 md:gap-4">
                            <div class="flex flex-row gap-4">
                              <svg
                                width="36"
                                height="38"
                                viewBox="0 0 36 38"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0_340_8094)">
                                  <path
                                    d="M18 28.2718V12.8552M25.5 19.0218L18 11.3135L10.5 19.0218M18 35.9802C27.1125 35.9802 34.5 28.3875 34.5 19.0218C34.5 9.65622 27.1125 2.06351 18 2.06351C8.8875 2.06351 1.5 9.65622 1.5 19.0218C1.5 28.3875 8.8875 35.9802 18 35.9802Z"
                                    stroke="url(#paint0_linear_340_8094)"
                                    strokeWidth="3"
                                  />
                                </g>
                                <defs>
                                  <linearGradient
                                    id="paint0_linear_340_8094"
                                    x1="5.65909"
                                    y1="-18.8414"
                                    x2="43.8935"
                                    y2="-11.2346"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop
                                      offset="0.0395"
                                      stop-color="#240B3B"
                                    />
                                    <stop offset="0.34" stop-color="#3D1E59" />
                                    <stop offset="0.49" stop-color="#5D1C82" />
                                    <stop
                                      offset="0.671382"
                                      stop-color="#8319B4"
                                    />
                                    <stop
                                      offset="0.9428"
                                      stop-color="#BC14FE"
                                    />
                                  </linearGradient>
                                  <clipPath id="clip0_340_8094">
                                    <rect
                                      width="36"
                                      height="37"
                                      fill="white"
                                      transform="translate(0 0.521851)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>

                              <div
                                className="cursor-pointer"
                                onClick={() => setVisibleSaveModal(true)}
                              >
                                <p className="text-[#333333] text-20 font-extrabold leading-[22px]">
                                  {priceText("UpgradeAndProsper")}
                                </p>

                                <div className="text-[#333333] text-16 font-extrabold leading-[22px]">
                                  {priceText("SwitchAndSaveAdvantage")}
                                </div>
                              </div>
                            </div>

                            <p className="text-[#676879] text-14 font-medium leading-[22px]  mb-[6px]">
                              {priceText("SwitchAndSaveProgram")}
                            </p>

                            <a href={"/get-started"}>
                              <div className="w-[135px] h-[35px] bg-gradient-to-r from-[#280B3B] via-[#5D1C82] to-[#BC14FE] text-white text-[11px] font-bold flex justify-center items-center rounded-md">
                                {t("why_vb.UnlockFreePlan")}
                              </div>
                            </a>
                          </div>
                        </div>

                        <div className="relative overflow-hidden w-full flex justify-start md:w-1/2 items-end md:pl-4">
                          <div
                            className="md:block h-full w-full absolute "
                            style={{
                              backgroundImage:
                                "linear-gradient(to top left, #BC14FE 0%, #280B3B 45%, #FFFFFF 90%, #FFFFFF 100%",
                            }}
                          ></div>

                          <div
                            className=" rounded-t-2xl flex flex-col md:flex-row z-10 bg-white mt-2 md:mt-2 py-5 md:py-10 mx-auto md:mx-0"
                            style={{
                              boxShadow:
                                "10px 0 10px -10px #333, -10px 0 10px -10px #333, 0 -10px 10px -10px #333",
                            }}
                          >
                            <div className=" px-6  flex flex-col ga-2 md:gap-4">
                              <div className="flex justify-start gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("KickstartWithVenueBoost")}
                                >
                                  <div className="w-[19px] h-[19px] bg-gradient-to-r from-[#280B3B] via-[#5D1C82] to-[#BC14FE] rounded-full"></div>
                                  {/* <img
                                  alt=""  onClick={() => setVisibleSaveModal(true)} src={InformationIcon} className="cursor-pointer w-5 h-5" /> */}
                                </Tooltip>
                                <p className="text-14 mb-3">
                                  30% Off: First-Year Savings
                                </p>
                              </div>
                              <div className="flex justify-start gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("ProlongedFreeTrial")}
                                >
                                  <div className="w-[19px] h-[19px] bg-gradient-to-r from-[#280B3B] via-[#5D1C82] to-[#BC14FE] rounded-full"></div>
                                  {/* <img
                                  alt=""  onClick={() => setVisibleSaveModal(true)} src={InformationIcon} className="cursor-pointer w-5 h-5" /> */}
                                </Tooltip>
                                <p className="text-14 mb-3">
                                  {priceText("Extended60DayTrial")}
                                </p>
                              </div>
                              <div className="flex justify-start gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("CustomizationCredit")}
                                >
                                  <div className="w-[19px] h-[19px] bg-gradient-to-r from-[#280B3B] via-[#5D1C82] to-[#BC14FE] rounded-full"></div>
                                  {/* <img
                                  alt=""  onClick={() => setVisibleSaveModal(true)} src={InformationIcon} className="cursor-pointer w-5 h-5" /> */}
                                </Tooltip>
                                <p className="text-14 mb-3">
                                  {priceText("CustomizationCredit200")}
                                </p>
                              </div>
                            </div>
                            <div className=" px-6 flex flex-col ga-2 md:gap-4">
                              <div className="flex justify-start gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText(
                                    "FreeDataMigrationAssistance"
                                  )}
                                >
                                  <div className="w-[19px] h-[19px] bg-gradient-to-r from-[#280B3B] via-[#5D1C82] to-[#BC14FE] rounded-full"></div>
                                  {/* <img
                                  alt="" src={InformationIcon} className="cursor-pointer w-5 h-5" /> */}
                                </Tooltip>
                                <p
                                  onClick={() => setVisibleSaveModal(true)}
                                  className="text-14 mb-3"
                                >
                                  {priceText("ComplimentaryDataMigration")}
                                </p>
                              </div>
                              <div className="flex justify-start gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText(
                                    "InteractiveCustomerFeedbackTool"
                                  )}
                                >
                                  <div className="w-[19px] h-[19px] bg-gradient-to-r from-[#280B3B] via-[#5D1C82] to-[#BC14FE] rounded-full"></div>
                                  {/* <img
                                  alt="" src={InformationIcon} className="cursor-pointer w-5 h-5" /> */}
                                </Tooltip>
                                <p
                                  onClick={() => setVisibleSaveModal(true)}
                                  className="text-14 mb-3"
                                >
                                  {priceText("EarlyAccessToNewTools")}
                                </p>
                              </div>
                              <div className="flex justify-start gap-2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  enterTouchDelay={0}
                                  title={priceText("ExpertTeamEveryStep")}
                                >
                                  <div className="w-[19px] h-[19px] bg-gradient-to-r from-[#280B3B] via-[#5D1C82] to-[#BC14FE] rounded-full"></div>
                                  {/* <img
                                  alt="" src={InformationIcon} className="cursor-pointer w-5 h-5" /> */}
                                </Tooltip>
                                <p
                                  onClick={() => setVisibleSaveModal(true)}
                                  className="text-14 mb-3"
                                >
                                  {priceText("Dedicated Support")}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*// )}*/}

                <div className="flex flex-col items-center gap-10 py-10 w-full mx-auto">
                  <div
                    className="flex flex-row justify-center cursor-pointer gap-1"
                    onClick={() => setVisibleVoucherModal(true)}
                  >
                    <span className="font-bold">Have a promo code?</span>
                    <span className="underline font-bold">Learn more</span>
                  </div>
                  <div className="flex flex-row gap-5 px-6 md:px-10 border border-[#E0E0E0] rounded-[13px] py-4 w-full shadow-md">
                    {/* <RiLock2Fill className={`text-4xl text-[${venueColor}]`} />
                     */}
                    <svg
                      width="25"
                      height="30"
                      viewBox="0 0 25 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="min-w-[25px] min-h-[30px]"
                    >
                      <path
                        d="M20.7074 10.1933H23.3416C23.6909 10.1933 24.0259 10.3412 24.2729 10.6044C24.5199 10.8676 24.6587 11.2247 24.6587 11.5969V28.4407C24.6587 28.813 24.5199 29.17 24.2729 29.4332C24.0259 29.6964 23.6909 29.8443 23.3416 29.8443H2.26826C1.91894 29.8443 1.58394 29.6964 1.33694 29.4332C1.08994 29.17 0.951172 28.813 0.951172 28.4407V11.5969C0.951172 11.2247 1.08994 10.8676 1.33694 10.6044C1.58394 10.3412 1.91894 10.1933 2.26826 10.1933H4.90242V8.78964C4.90242 6.55602 5.735 4.41389 7.21701 2.83448C8.69902 1.25507 10.709 0.367767 12.8049 0.367767C14.9008 0.367767 16.9108 1.25507 18.3928 2.83448C19.8748 4.41389 20.7074 6.55602 20.7074 8.78964V10.1933ZM11.4878 21.0463V24.2297H14.122V21.0463C14.6242 20.7373 15.0167 20.2603 15.2386 19.6894C15.4605 19.1185 15.4994 18.4854 15.3493 17.8885C15.1993 17.2916 14.8685 16.7641 14.4085 16.3879C13.9485 16.0117 13.3848 15.8078 12.8049 15.8078C12.2251 15.8078 11.6614 16.0117 11.2013 16.3879C10.7413 16.7641 10.4106 17.2916 10.2605 17.8885C10.1104 18.4854 10.1494 19.1185 10.3713 19.6894C10.5932 20.2603 10.9857 20.7373 11.4878 21.0463ZM18.0733 10.1933V8.78964C18.0733 7.30056 17.5182 5.87247 16.5302 4.81953C15.5422 3.76659 14.2022 3.17506 12.8049 3.17506C11.4077 3.17506 10.0677 3.76659 9.07965 4.81953C8.09164 5.87247 7.53659 7.30056 7.53659 8.78964V10.1933H18.0733Z"
                        fill={`${venueColor}`}
                      />
                    </svg>

                    <div className="flex flex-col gap-2">
                      <span className="font-bold text-16 leading-6">
                        {priceText("SecurePayment")}
                      </span>
                      <span className="font-normal text-16 leading-6">
                        {priceText("PayEasilyChoicePaymentMethods")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="align-col-middle">
            <div className="w-[100vw] bg-[#240B3B] ">
              <div className="max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 ">
                <div className="flex flex-col align-middle w-full">
                  <p className="mt-6 md:mt-20 text-26 md:text-40 font-extrabold text-white">
                    {t("components.pricing.pricing_1")}{" "}
                    <span className="text-white">
                      {t("components.pricing.pricing_2")}
                    </span>
                  </p>
                  <p className="mt-2 px-4 text-14 md:text-20 font-medium text-white">
                    {t("components.pricing.pricing_sub")}{" "}
                  </p>
                  <Faq
                    faqData={generalFaq}
                    className="mt-6 md:mt-10 mb-6 md:mb-20"
                  />
                </div>
              </div>
            </div>
          </div>

          <Advantage />

          <div className="align-col-middle">
            <div className="w-[100vw] bg-white">
              <div className="mx-auto max-w-screen-main px-4 sm:px-6 md:px-8 lg:px-16">
                <div className="flex flex-col align-middle mb-4 md:mb-20">
                  <FreePlanCard />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        // title="Add a New Card"
        centered
        open={visibleVoucherModal}
        onCancel={() => setVisibleVoucherModal(false)}
        onOk={() => navigateToUrl("/get-started")}
        maskClosable={true}
        okText={"Get started"}
        okButtonProps={{
          style: {
            background:
              "linear-gradient(100.8deg, rgb(36, 11, 59) 3.95%, rgb(61, 30, 89) 34%, rgb(188, 20, 254) 94.28%)",
            color: "white",
            height: "40px",
          },
        }}
        cancelButtonProps={{
          style: {
            backgroundColor: "transparent",
            color: "#240b3b",
            borderColor: "#CBD5E1",
            height: "40px",
          },
        }}
        bodyStyle={{ height: "250px" }}
        width={450}
      >
        <div
          className={
            "flex flex-col py-4 w-full h-max md:h-[450px] overflow-auto gap-5"
          }
        >
          <div className="flex flex-row items-center gap-2">
            <MdDiscount className="text-2xl" />
            <span className="font-bold text-xl">
              {priceText("UnlockSpecialDiscounts")}
            </span>
          </div>
          <span style={{ fontSize: "16px" }}>
            {priceText("IfYouHavePromoCode")}
          </span>
        </div>
      </Modal>
      <Modal
        // title="Add a New Card"
        centered
        open={visibleFreemiumModal}
        onCancel={() => setVisibleFreemiumModal(false)}
        onOk={() => navigateToUrl("/get-started")}
        maskClosable={false}
        okText={"Contact us"}
        okButtonProps={{
          style: { backgroundColor: "#240b3b", color: "white", height: "40px" },
        }}
        cancelButtonProps={{
          style: {
            backgroundColor: "transparent",
            color: "#240b3b",
            borderColor: "#CBD5E1",
            height: "40px",
            marginTop: "20px",
          },
        }}
        bodyStyle={{ height: "250px" }}
        width={450}
      >
        <div
          className={
            "flex flex-col py-4 w-full h-max md:h-[450px] overflow-auto gap-5"
          }
        >
          <div className="flex flex-row items-center gap-2">
            <MdAnimation className="text-2xl" />
            <span className="font-bold text-xl">
              {priceText("TimelessChoiceForYourVenue")}
            </span>
          </div>
          <span style={{ fontSize: "16px" }}>
            {priceText("ExploreFreemiumPlan")}
          </span>
        </div>
      </Modal>
      <Modal
        // title="Add a New Card"
        centered
        open={visibleSaveModal}
        onCancel={() => setVisibleSaveModal(false)}
        onOk={() => navigateToUrl("/get-started")}
        maskClosable={false}
        okText={"Get Started"}
        okButtonProps={{
          style: {
            background:
              "linear-gradient(100.8deg, rgb(36, 11, 59) 3.95%, rgb(61, 30, 89) 34%, rgb(188, 20, 254) 94.28%)",
            color: "white",
            height: "40px",
          },
        }}
        cancelButtonProps={{
          style: {
            backgroundColor: "transparent",
            color: "#240b3b",
            borderColor: "#CBD5E1",
            height: "40px",
          },
        }}
        // bodyStyle={{height: '550px'}}
        width={650}
      >
        <div
          className={"flex flex-col py-4 w-full overflow-auto gap-5"}
          style={{ height: "500px" }}
        >
          <div className="flex flex-row items-center gap-2">
            <MdRepeat className="text-xl" />
            <span className="font-bold text-xl">
              {" "}
              {priceText("UpgradeAndProsper")}:{" "}
              {priceText("SwitchAndSaveAdvantage")}
            </span>
          </div>
          <span style={{ fontSize: "16px" }}>
            {priceText("SwitchAndSaveProgram")}
          </span>
          <ul
            style={{
              fontSize: 15,
              color: "rgb(89, 89, 89)",
              paddingLeft: "15px",
              listStyle: "auto",
            }}
          >
            <li>
              <b>{priceText("FirstYearSavings30PercentOff")}</b>
              {priceText("KickstartJourney30PercentOff")}
            </li>
            <li>
              <b>{priceText("Extended60DayTrial")}</b>
              {priceText("Prolonged60DayFreeTrial")}
            </li>
            <li>
              <b>{priceText("CustomizationCredit200")}</b>
              {priceText("ReceiveGenerousCustomizationCredit")}
            </li>
            <li>
              <b>{priceText("ComplimentaryDataMigration")}</b>
              {priceText("FreeDataMigrationAssistance")}
            </li>
            <li>
              <b>{priceText("EarlyAccessToNewTools")}</b>
              {priceText("FirstExperienceInteractiveFeedbackTool")}
            </li>
            <li>
              <b>{priceText("Dedicated Support")} </b>{" "}
              {priceText("ExpertTeamGuiding")}
            </li>
          </ul>
        </div>
      </Modal>
    </div>
  );
};

export default PricingPlans;
