import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import confirmed_success from "../../assets/svgs/confirmed_success.svg";
import { useEffect, useState } from "react";
import moment from "moment";
import Enroll from "../../components/venue/enroll";
import WhiteLabelService from "../../services/whiteLabelService";
import { NotificationManager } from "react-notifications";
import { removeOrderSucessDetails } from "../../redux/actions/cart";

const CartOrderSuccessScreen = () => {
  const { app_key } = useParams();
  const dispatch = useDispatch();
  const [date, setDate] = useState("");
  const [canEnroll, setCanEnroll] = useState(false);
  const [enrolledCompleted, setEnrolledCompleted] = useState(false);
  const [enrollEmail, setEnrollEmail] = useState(null);
  const { orderSucessData } = useSelector((state) => state.cart);
  const [appKey, setAppKey] = useState("");
  const [venueType, setVenueType] = useState("");

  useEffect(() => {
    setDate(moment(new Date()).format("MMMM Do YYYY, h:mm:ss a"));
    if (orderSucessData) {
      setCanEnroll(orderSucessData?.can_enroll);
      setEnrollEmail(orderSucessData.email);
      setAppKey(orderSucessData.appKey);
      // TODO Griseld, set enroll title and description based on venueType if needed
      setVenueType(orderSucessData.type); // food || retail
    }
  }, []);

  const onEnrollSubmit = () => {
    const payload = {
      email: enrollEmail,
    };
    WhiteLabelService.guestEnroll(payload, appKey)
      .then((res) => {
        NotificationManager.success(
          res?.message ?? "Guest enrolled successfully"
        );
        setEnrolledCompleted(true);
        dispatch(removeOrderSucessDetails());
      })
      .catch((err) => {
        NotificationManager.error(err?.error ?? "Something went wrong!");
      });
  };

  return (
    <>
      <div className="flex flex-col justify-center rounded-[13px] py-8">
        <div className="flex flex-col w-full justify-center items-center">
          <img
            src={confirmed_success}
            alt="Confirmation Icon"
            className="w-[100px] h-[100px] py-4"
          />
          <p className="text-black1 text-[16px] font-bold">
            Order Successfully Placed
          </p>
          {/*<div className='flex flex-column justify-center items-center gap-8 mt-4 mb-4'>*/}
          {/*<div className='flex flex-column justify-center items-center gap-8'>*/}
          <p className="text-black1 font-bold mt-4 mb-2">Order Date</p>
          <p className="mb-4">
            <span className="text-gray1">{date}</span>
          </p>
          {/*</div>*/}
          {/*</div>*/}
          <div className="flex flex-col justify-center items-center text-[13px] text-cyan1 bg-[#ECFDF3] border-[#ECFDF3] border align-center rounded-[10px] px-12 py-4 mb-4">
            <div className="flex justify-center items-center text-[12px] font-normal text-black1">
              A confirmation email will be sent to you shortly, including any
              remarks from the {venueType === "food" ? "restaurant" : "store"}
            </div>
          </div>
        </div>
      </div>
      {canEnroll ? (
        <Enroll
          onSubmit={onEnrollSubmit}
          completed={enrolledCompleted}
          title={"Unlock Rewards with Our Loyalty Program"}
          description={
            "Every order brings you closer to exclusive discounts. Earn points with each order and use them to save on your next visit. Your loyalty matters to us!"
          }
        />
      ) : (
        ""
      )}
    </>
  );
};

export default CartOrderSuccessScreen;
