import React from "react";
import Increase from "../Icons/Increase";

const Tag = ({ tag }) => {
  switch (tag) {
    case "Retail Management":
      return (
        <div className="inline-block px-2 py-1 rounded-[4px] bg-[rgba(225,72,72,.2)] text-[13px] text-[#E14848]">
          {tag}
        </div>
      );
    case "Accommodation":
      return (
        <div className="inline-block px-2 py-1 rounded-[4px] bg-[rgba(53,188,185,.2)] text-[13px] text-[#6DDFE2]">
          {tag}
        </div>
      );
    case "Food & Beverage":
      return (
        <div className="inline-block px-2 py-1 rounded-[4px] bg-[rgba(144,221,64,0.2)] text-[13px] text-[#17CE8C]">
          {tag}
        </div>
      );
    case "Entertainment Venues":
      return (
        <div className="inline-block px-2 py-1 rounded-[4px] bg-[rgba(205,186,40,0.2)] text-[13px] text-[#F4D35E]">
          {tag}
        </div>
      );
    default:
      return null;
  }
};

const UseCaseCard = ({ img, tag, title, slug, description }) => {
  return (
    <a href={`/resources/use-cases/${slug}`}>
      <div className="flex flex-col rounded-[13px] border-[1px] border-[#E0E0E0] bg-white use-case-card overflow-hidden">
        <img
          className="w-full h-[160px] md:h-[258px] object-cover rounded-[13px]"
          src={img}
          alt="aboutus"
        />
        <div className="pt-4 pb-2 mx-4 relative border-b border-gray-200">
          <div className="text-[#373941] text-[22px] leading-7 font-bold line-clamp-2">
            {title}
          </div>
          <p className="text-[#717484] text-14 leading-5 mt-4 font-normal line-clamp-3">
            {description}
          </p>
        </div>
        <div className="px-4 pb-4">
          <div className="flex items-center py-2">
            <span className="block text-[#666A73] text-[16px] mr-2">
              10% Increased Conversions
            </span>
            <Increase width={20} height={20} />
          </div>
          <Tag tag={tag} />
        </div>
      </div>
    </a>
  );
};

export default UseCaseCard;
