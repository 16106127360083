import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import "./index.css";
import {Seo} from "../../../../components/Seo";
import Faq from "../../../../components/Faq";
// import Pricing from "../../../../components/Pricing";
// import VideoPlayer from "../../../../components/VideoPlayer";
import Advantage from "../../../../components/Advantage";
// import ArrowWhiteSVG from "../../../../assets/svgs/arrow-white.svg";
import OverviewBannerImg from "../../../../assets/images/who-we-serve/retail.webp";
import OverviewRetailOrders from "../../../../assets/images/who-we-serve/retail-orders.webp";
import OverviewRetailInventory from "../../../../assets/images/who-we-serve/retail-inventory.webp";
import OverviewImg3 from "../../../../assets/images/who-we-serve/overviewimg3.webp";
import OverviewImg4 from "../../../../assets/images/who-we-serve/overviewimg4.webp";
import OverviewImg5 from "../../../../assets/images/who-we-serve/overviewimg5.webp";
import OverviewRetailDashboardRevenue from "../../../../assets/images/who-we-serve/retail-dashboard-overview.webp";
import OverviewRetailStoreSettings from "../../../../assets/images/who-we-serve/retail-store-settings.webp";

import {NotificationManager} from "react-notifications";
import {create as createContactSale} from "../../../../redux/actions/contactsales";
import {t} from "i18next";

// import RetailPharmacies from "../../../../assets/svgs/pharma.svg";
// import RetailHomeImg from "../../../../assets/svgs/retail-home.svg";
// import RetailSportImg from "../../../../assets/svgs/retail-sport.svg";
// import RetailFashionImg from "../../../../assets/svgs/retail-fashion.svg";
// import RetailBoutiquesAndSpecialtyStores from "../../../../assets/svgs/boutique.svg";
// import RetailRetailChains from "../../../../assets/svgs/chain.svg";
import OverviewFoodGuestsManagement from "../../../../assets/images/who-we-serve/food-guests-management.webp";
import OverviewImg8 from "../../../../assets/images/who-we-serve/overviewimg8.webp";
import {isMobile} from "react-device-detect";
import {CircleTick, CheckLine} from "../../../../components/CheckLine";
import FreeTrial from "../../../../components/FreeTrial";
import Grow from "../../grow";
import FreePlanCard from "../../../../components/FreePlanCard";

const generalFaq = [
  {
    id: 1,
    status: false,
    title: "What types of retail businesses can benefit from using VenueBoost?",
    content:
      "VenueBoost is versatile and suitable for a variety of retail establishments, including sporting goods stores, home décor stores, fashion boutiques, retail chains, specialty stores, online shops, grocery stores, electronics stores, pharmacies, auto parts stores, liquor stores, and pet stores.",
  },
  {
    id: 2,
    status: false,
    title:
      "How does VenueBoost streamline order management for retail businesses?",
    content:
      "VenueBoost offers a smooth order management system that allows retail and e-commerce businesses to effortlessly accept and process orders, providing a streamlined interface for both customers and management.",
  },
  {
    id: 3,
    status: false,
    title:
      "Can VenueBoost assist with inventory management in my retail store?",
    content:
      "Yes, VenueBoost provides robust, tailored inventory management capabilities, including alerts for low stock levels, to help you efficiently manage your inventory and avoid stockouts.",
  },
  {
    id: 4,
    status: false,
    title:
      "What staff management features does VenueBoost offer for retail venues?",
    content:
      "VenueBoost facilitates integrated staff scheduling and time tracking, enhancing operational efficiency and ensuring smooth coordination and management of staff duties and hours.",
  },
  {
    id: 5,
    status: false,
    title: "How can VenueBoost support my retail business's marketing efforts?",
    content:
      "VenueBoost includes integrated marketing tools to help promote your brand, attract new customers, and create effective marketing campaigns using promotions, emails, and more.",
  },
  {
    id: 6,
    status: false,
    title:
      "What types of loyalty and retention programs can I implement with VenueBoost for my retail business?",
    content:
      "VenueBoost enables retailers to create customized loyalty programs, encouraging repeat purchases and fostering long-term customer relationships.",
  },
  {
    id: 7,
    status: false,
    title:
      "Does VenueBoost provide analytics and reporting tools for retail businesses?",
    content:
      "Yes, VenueBoost offers robust analytics and reporting tailored for retail stores and e-commerce, helping businesses make smarter decisions based on insightful data analysis.",
  },
  {
    id: 8,
    status: false,
    title: "How does VenueBoost handle in-person payments for retail stores?",
    content:
      "VenueBoost's In-Person Payments feature offers a seamless and secure transaction experience, enabling retail venues to accept payments securely at their physical locations.",
  },
  {
    id: 9,
    status: false,
    title:
      "Can VenueBoost help with creating a customizable brand profile for my retail store?",
    content:
      "Yes, the Customizable Brand Profile feature allows retail stores to customize and brand their interface and customer-facing elements, personalizing their VenueBoost interface with their brand's unique identity.",
  },
  {
    id: 10,
    status: false,
    title:
      "What kind of onboarding support does VenueBoost provide for new retail clients?",
    content:
      "VenueBoost ensures a seamless onboarding process for retail clients, offering personalized support, training sessions, and dedicated customer service to facilitate smooth adoption and effective utilization of all features.",
  },
];

const HomeDecor = () => {
  const navigate = useNavigate();

  const currentUrl =
    "https://venueboost.io/who-we-serve/retail-management/home-decor";
  const seoTitle = "Engage Across Channels for Home Décor Stores";
  const seoDescription =
    "Grow reach by engaging across channels for home décor stores. Elevate operations with VenueBoost and enhance customer engagement.";

  const [step, setStep] = useState(1);
  const [venuetype, setVenuetype] = useState("fashion_threads");
  const [first_name, setFirstname] = useState("");
  const [last_name, setLastname] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [restaurant_name, setRestaurantName] = useState("");
  const [restaurant_city, setRestaurantCity] = useState("");
  const [restaurant_state, setRestaurantState] = useState("");
  const [restaurant_zipcode, setRestaurantZipcode] = useState("");
  const [restaurant_country, setRestaurantCountry] = useState("");
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      title: "Consistent Inventory",
      content: (
        <div className='flex flex-col-reverse md:flex-row mt-0 md:mt-[58px]'>
          <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0'>
            <img src={OverviewFoodGuestsManagement} />
          </div>
          <div className='w-full md:w-[50%] md:p-0 md:pr-0  flex-col'>
            <h1
              className='text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'
              style={{alignSelf: "left"}}
            >
              Streamlined Ecommerce Operations
            </h1>

            <p className='text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
              VenueBoost provides seamless integration with WooCommerce and
              Shopify for consistent inventory management.
            </p>
            <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Sync inventory levels across platforms to avoid discrepancies
                </span>
              </div>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Maintain real-time data to prevent over/understocking
                </span>
              </div>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Enhance customer satisfaction through accurate availability
                </span>
              </div>
            </div>
            <p className='text-[#666666] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px] mb-8'>
              VenueBoost simplifies inventory management by synchronizing levels
              between WooCommerce and Shopify for a smooth shopping experience.
            </p>
          </div>
        </div>
      ),
      title_modal: "Consistent Inventory",
      content1: "Streamlined Ecommerce Operations",
      content2:
        "VenueBoost provides seamless integration with WooCommerce and Shopify for consistent inventory management.",
      content3: [
        "Sync inventory levels across platforms to avoid discrepancies",
        "Maintain real-time data to prevent over/understocking",
        "Enhance customer satisfaction through accurate availability",
      ],
      content4:
        "VenueBoost simplifies inventory management by synchronizing levels between WooCommerce and Shopify for a smooth shopping experience.",
    },
    {
      title: "Retail Customer Loyalty Program",
      content: (
        <div className='flex flex-col-reverse md:flex-row mt-0 md:mt-[58px]'>
          <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0'>
            <img src={OverviewFoodGuestsManagement} />
          </div>
          <div className='w-full md:w-[50%] md:p-0 md:pr-0  flex-col'>
            <h1
              className='text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'
              style={{alignSelf: "left"}}
            >
              For Enhanced Shopping Experiences
            </h1>

            <p className='text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
              VenueBoost presents the Retail Customer Loyalty Program, a
              powerful tool to enhance shopping experiences and build loyalty.
            </p>
            <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Offer personalized discounts and promotions
                </span>
              </div>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Track customer preferences and purchase history
                </span>
              </div>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Drive repeat business with targeted marketing campaigns
                </span>
              </div>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Strengthen customer relationships and boost sales
                </span>
              </div>
            </div>

            <p className='text-[#666666] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px] mb-8'>
              Transform your retail business with VenueBoost's Retail Customer
              Loyalty Program.
            </p>
          </div>
        </div>
      ),
      title_modal: "Retail Customer Loyalty Program",
      content1: "For Enhanced Shopping Experiences",
      content2:
        "VenueBoost presents the Retail Customer Loyalty Program, a powerful tool to enhance shopping experiences and build loyalty.",
      content3: [
        "Offer personalized discounts and promotions",
        "Track customer preferences and purchase history",
        "Drive repeat business with targeted marketing campaigns",
        "Strengthen customer relationships and boost sales",
      ],
      content4:
        "Transform your retail business with VenueBoost's Retail Customer Loyalty Program.",
    },
    {
      title: "Advanced Customer Behavior Analytics",
      content: (
        <div className='flex flex-col-reverse md:flex-row mt-0 md:mt-[58px]'>
          <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0'>
            <img src={OverviewFoodGuestsManagement} />
          </div>
          <div className='w-full md:w-[50%] md:p-0 md:pr-0  flex-col'>
            <h1
              className='text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'
              style={{alignSelf: "left"}}
            >
              Data-Driven Customer Insights
            </h1>

            <p className='text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
              Gain deep insights into customer behavior with VenueBoost's
              Advanced Customer Behavior Analytics. Understand your customers
              better and tailor your services accordingly.
            </p>
            <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Analyze preferences, history, and engagement patterns
                </span>
              </div>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Make data-driven decisions to improve experiences
                </span>
              </div>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Effectively target marketing based on behavior insights
                </span>
              </div>
            </div>
            <p className='text-[#666666] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px] mb-8'>
              VenueBoost empowers businesses to enhance customer engagement
              through data-driven strategies.
            </p>
          </div>
        </div>
      ),
      title_modal: "Advanced Customer Behavior Analytics",
      content1: "Data-Driven Customer Insights",
      content2:
        "Gain deep insights into customer behavior with VenueBoost's Advanced Customer Behavior Analytics. Understand your customers better and tailor your services accordingly.",
      content3: [
        "Analyze preferences, history, and engagement patterns",
        "Make data-driven decisions to improve experiences",
        "Effectively target marketing based on behavior insights",
      ],
      content4:
        "VenueBoost empowers businesses to enhance customer engagement through data-driven strategies.",
    },
    {
      title: "Marketing Automation",
      content: (
        <div className='flex flex-col-reverse md:flex-row mt-0 md:mt-[58px]'>
          <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0'>
            <img src={OverviewFoodGuestsManagement} />
          </div>
          <div className='w-full md:w-[50%] md:p-0 md:pr-0  flex-col'>
            <h1
              className='text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'
              style={{alignSelf: "left"}}
            >
              Boost Engagement and Sales
            </h1>

            <p className='text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
              Take your marketing to the next level with VenueBoost's Marketing
              Automation. Seamlessly automate and personalize campaigns to drive
              engagement and sales.
            </p>
            <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Create targeted email campaigns based on customer behavior
                </span>
              </div>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Send personalized offers and recommendations
                </span>
              </div>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Measure effectiveness and optimize strategies
                </span>
              </div>
            </div>
            <p className='text-[#666666] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px] mb-8'>
              VenueBoost streamlines your marketing across industries with
              powerful automation.
            </p>
          </div>
        </div>
      ),
      title_modal: "Marketing Automation",
      content1: "Boost Engagement and Sales",
      content2:
        "Take your marketing to the next level with VenueBoost's Marketing Automation. Seamlessly automate and personalize campaigns to drive engagement and sales.",
      content3: [
        "Create targeted email campaigns based on customer behavior",
        "Send personalized offers and recommendations",
        "Measure effectiveness and optimize strategies",
      ],
      content4:
        "VenueBoost streamlines your marketing across industries with powerful automation.",
    },
  ];

  const handleSecondStepSelect = () => {
    if (!first_name || !last_name || !mobile || !email) {
      NotificationManager.error(
        t("who_we_serve.retail_industry.error_msg"),
        t("who_we_serve.retail_industry.error"),
        3000
      );
    } else {
      setStep(3);
    }
  };

  const navigateToUrl = url => {
    navigate(url);
  };

  const handleVenueZipCode = e => {
    setRestaurantZipcode(e.target.value);
  };

  const handleVenueState = e => {
    setRestaurantState(e.target.value);
  };

  const handleVenueName = e => {
    setRestaurantName(e.target.value);
  };

  const handleVenueCity = e => {
    setRestaurantCity(e.target.value);
  };

  const handleVenueCountry = e => {
    setRestaurantCountry(e.target.value);
  };

  const handleFirstname = e => {
    setFirstname(e.target.value);
  };

  const handleLastname = e => {
    setLastname(e.target.value);
  };

  const handleMobile = e => {
    setMobile(e.target.value);
  };

  const handleEmail = e => {
    setEmail(e.target.value);
  };

  const onSubmit = () => {
    if (
      !restaurant_country ||
      !restaurant_zipcode ||
      !restaurant_state ||
      !restaurant_city
    ) {
      NotificationManager.error(
        t("who_we_serve.retail_industry.error_msg"),
        t("who_we_serve.retail_industry.error"),
        3000
      );

      return false;
    }

    const data = {
      industry: "Retail",
      category: venuetype === "Any" ? "fashion_threads" : venuetype,
      is_demo: true,
      restaurant_country: restaurant_country,
      restaurant_zipcode: restaurant_zipcode,
      restaurant_state: restaurant_state,
      restaurant_city: restaurant_city,
      restaurant_name: restaurant_name,
      email: email,
      mobile: mobile,
      last_name: last_name,
      first_name: first_name,
      contact_reason: "I want to schedule a demo of VenueBoost",
    };
    createContactSale(data)
      .then(data => {
        setRestaurantZipcode(null);
        setRestaurantState(null);
        setRestaurantName(null);
        setRestaurantCountry(null);
        setRestaurantCity(null);
        setVenuetype(null);
        setFirstname(null);
        setLastname(null);
        setMobile(null);
        setEmail(null);
        NotificationManager.success(
          data?.message || t("who_we_serve.retail_industry.success_msg"),
          t("who_we_serve.retail_industry.success"),
          5000
        );

        setStep(1);
      })
      .catch(error => {
        NotificationManager.error(
          error.message || t("who_we_serve.retail_industry.error_msg"),
          t("who_we_serve.retail_industry.error"),
          3000
        );
      });
  };

  return (
    <div className={"align-col-middle retail-industry mt-0"}>
      <Seo
        title={seoTitle + " | VenueBoost"}
        description={seoDescription}
        image={OverviewBannerImg}
        url={currentUrl}
      />
      <div className='flex flex-col md:flex-row justify-center overflow-hidden bg-[#F3F3F3] px-4 sm:px-6 md:px-8 lg:px-16 pb-20 w-[100vw] relative'>
        {/*<div*/}
        {/*    className="hidden md:block w-[900px] h-[900px] absolute -left-[400px] -top-[100px]"*/}
        {/*    style={{*/}
        {/*        background:*/}
        {/*            "radial-gradient(circle at center, rgba(255, 157, 157, 0.7), transparent, transparent)",*/}
        {/*    }}*/}
        {/*></div>*/}

        <div className='xl:!w-[1228px] flex flex-col md:flex-row'>
          <div className='w-full md:w-[50%] flex flex-col md:pr-[58px] py-6 md:py-[44px] z-10'>
            <span className=' text-[32px]  md:text-[60px] leading-[45px] md:leading-[60px] font-extrabold '>
              Home Décor Stores
            </span>

            <p className='hidden md:block text-[#000000] text-14 md:text-20 font-medium leading-5 md:leading-7 mt-2 md:mt-4 mb-[22px] md:mb-[44px]'>
              Transforming the home décor shopping experience, VenueBoost
              simplifies operations for these stores. From managing inventory to
              engaging customers with personalized promotions, our platform
              ensures a delightful and efficient shopping experience. Valuable
              insights optimize the utilization of home décor stores'resources,
              creating an atmosphere where customers can explore and enhance
              their living spaces.
            </p>
            <div className='mb-[15px] md:mb-0 mt-10 hidden md:block'>
              <a
                href='/get-started'
                className='rounded-md text-white text-[11px] font-bold md:text-18 leading-[11px] md:leading-[18px] px-[17px] md:px-[30px] py-[9px] md:py-[15px] uppercase bg-gradient-to-r from-[#240B3B] via-[#5E1B83] to-[#BC14FE]'
              >
                {t("who_we_serve.food_industry.unlock_free_plan")}
              </a>
            </div>
          </div>
          <div className=' md:block w-full md:w-[50%] md:mt-12  relative align-middle'>
            <img
              className='object-cover md:absolute rounded-[20px] right-[29px] max-h-full'
              src={OverviewBannerImg}
            />
          </div>

          <div className='flex flex-col md:hidden mt-6'>
            <p className='text-[#000000] text-14 md:text-20 font-medium leading-5 md:leading-7 mt-2 md:mt-4 mb-[22px] md:mb-[44px]'>
              Transforming the home décor shopping experience, VenueBoost
              simplifies operations for these stores. From managing inventory to
              engaging customers with personalized promotions, our platform
              ensures a delightful and efficient shopping experience. Valuable
              insights optimize the utilization of home décor stores'resources,
              creating an atmosphere where customers can explore and enhance
              their living spaces.
            </p>

            <div className=' mt-6 md:mt-0'>
              <a
                href='/get-started'
                className='flex w-full justify-center rounded-md text-white font-bold text-18 leading-[18px] md:px-[30px] py-[15px] uppercase bg-gradient-to-r from-[#240B3B] via-[#5E1B83] to-[#BC14FE]'
              >
                {t("who_we_serve.food_industry.unlock_free_plan")}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col-reverse md:flex-row mt-6 md:mt-[38px]'>
        <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0'>
          <img src={OverviewRetailOrders} />
        </div>

        <div className='w-full md:w-[50%] md:p-4 md:pr-0  flex-col'>
          <h1 className='text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'>
            Order Management
          </h1>
          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            Streamlined Order Processing
          </p>

          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            VenueBoost enables smooth order management for retail and ecommerce
            businesses.
          </p>
          <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
            {[
              "Real-time order notifications",
              "Intuitive interface to view and update orders",
              "Track order history and customer details",
              "Ensure fast fulfillment and shipping",
              "Focus on customers, not paperwork",
            ].map(item => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className='text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4'>
            Say goodbye to fragmented processes. VenueBoost unifies order
            management workflows.
          </p>
          <FreeTrial />
        </div>
      </div>
      <div className='flex flex-col-reverse md:flex-row-reverse mt-10 md:mt-[38px] '>
        <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0'>
          <img src={OverviewRetailInventory} />
        </div>
        <div className='w-full md:w-[40%] md:p-4 md:pl-0  '>
          <h1 className='text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'>
            {t("who_we_serve.retail_industry.inventory_management")}{" "}
          </h1>
          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            Actionable Inventory Insights
          </p>

          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            {t("who_we_serve.retail_industry.inventory_management_sub")}{" "}
          </p>
          <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
            {[
              t("who_we_serve.retail_industry.inventory_tracking"),
              t("who_we_serve.retail_industry.inventory_update"),
              t("who_we_serve.retail_industry.inventory_insight"),
              t("who_we_serve.retail_industry.inventory_detail"),
              t("who_we_serve.retail_industry.inventory_history"),
            ].map(item => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className='text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4'>
            Forget spreadsheets and guesses. VenueBoost provides the data to
            optimize inventory.
          </p>
          <FreeTrial />
        </div>
      </div>

      <div className='flex flex-col-reverse md:flex-row mt-6 md:mt-[38px]'>
        <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0'>
          <img src={OverviewImg3} />
        </div>
        <div className='w-full md:w-[50%] md:p-4 md:pr-0  flex-col'>
          <h1
            className='text-[#240b3b] text-22 align-left md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'
            style={{alignSelf: "left"}}
          >
            {t("who_we_serve.retail_industry.staff_managment")}
          </h1>
          <p className='text-[#666666] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
            Simplified Retail Staff Management
          </p>

          <p className='text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
            {t("who_we_serve.retail_industry.staff_managment_sub")}
          </p>
          <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
            {[
              "Automated scheduling based on store needs",
              "Manage time off requests",
              "Payroll processing with wage calculations",
              "Track sales performance to inform commissions",
              "Identify coaching opportunities using metrics",
            ].map(item => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className='text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4'>
            Skip the hassles. VenueBoost simplifies retail staff management.
          </p>
          <FreeTrial />
        </div>
      </div>
      <div className='flex flex-col-reverse md:flex-row-reverse mt-10 md:mt-[38px] '>
        <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0'>
          <img src={OverviewImg4} />
        </div>
        <div className='w-full md:w-[40%] md:p-4 md:pl-0   '>
          <h1 className='text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'>
            {t("who_we_serve.retail_industry.marketing")}{" "}
          </h1>
          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            Expanded Reach and Brand Awareness
          </p>

          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            {t("who_we_serve.retail_industry.marketing_sub")}
          </p>
          <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
            {[
              t("who_we_serve.retail_industry.marketing_email"),
              t("who_we_serve.retail_industry.marketing_coupons"),
              t("who_we_serve.retail_industry.marketing_campaigns"),
              t("who_we_serve.retail_industry.marketing_refferals"),
            ].map(item => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className='text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4'>
            Consolidate activities. VenueBoost brings together the capabilities
            retailers need to grow.
          </p>
          <FreeTrial />
        </div>
      </div>

      <div className='flex flex-col-reverse md:flex-row mt-6 md:mt-[38px]'>
        <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0'>
          <img src={OverviewImg5} />
        </div>
        <div className='w-full md:w-[50%] md:p-4 md:pr-0  flex-col'>
          <h1
            className='text-[#240b3b] text-22 align-left md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'
            style={{alignSelf: "left"}}
          >
            {t("who_we_serve.retail_industry.loyalty")}
          </h1>
          <p className='text-[#666666] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
            Building Customer Loyalty and Retention
          </p>
          <p className='text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
            {t("who_we_serve.retail_industry.loyalty_sub")}
          </p>
          <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
            {[
              "Segment members into tailored loyalty tiers",
              "Send personalized rewards and offers",
              "Track purchase history and shopping habits",
              "Analyze membership engagement",
            ].map(item => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className='text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4'>
            Strengthen relationships with regular buyers. VenueBoost provides
            the tools to boost loyalty, retention, and repeat visits.
          </p>
          <FreeTrial />
        </div>
      </div>
      <div className='flex flex-col-reverse md:flex-row-reverse mt-10 md:mt-[38px] '>
        <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0'>
          <img src={OverviewRetailDashboardRevenue} />
        </div>
        <div className='w-full md:w-[40%] md:p-4 md:pl-0   '>
          <h1 className='text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'>
            {t("who_we_serve.retail_industry.dashboard")}{" "}
          </h1>
          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            Actionable Retail Insights
          </p>
          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            {t("who_we_serve.retail_industry.dashboard_sub")}
          </p>
          <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
            {[
              t("who_we_serve.retail_industry.dashboard_sale"),
              t("who_we_serve.retail_industry.dashboard_coupons"),
              t("who_we_serve.retail_industry.dashboard_categories"),
              t("who_we_serve.retail_industry.dashboard_sold"),
              t("who_we_serve.retail_industry.dashboard_order"),
            ].map(item => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className='text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4'>
            Optimize your retail business with VenueBoost's comprehensive data.
          </p>
          <FreeTrial />
        </div>
      </div>

      <div className='flex flex-col-reverse md:flex-row mt-10 md:mt-[78px] '>
        <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0'>
          <img src={OverviewRetailStoreSettings} />
        </div>
        <div className='w-full md:w-[40%] md:p-4 md:pl-0   '>
          <h1 className='text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'>
            {t("who_we_serve.retail_industry.store_managment")}{" "}
          </h1>
          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            Customized Online Store Presence
          </p>
          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            {t("who_we_serve.retail_industry.store_managment_sub")}
          </p>
          <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
            {[
              t("who_we_serve.retail_industry.store_managment_profile"),
              t("who_we_serve.retail_industry.store_managment_selling"),
              t("who_we_serve.retail_industry.store_managment_gallery"),
              t("who_we_serve.retail_industry.store_managment_notifi"),
              t("who_we_serve.retail_industry.store_managment_payment"),
            ].map(item => (
              <CheckLine text={item} />
            ))}
          </div>
          <FreeTrial />
        </div>
      </div>
      <div className='flex flex-col-reverse md:flex-row-reverse mt-0 md:mt-[38px]'>
        <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0'>
          <img src={OverviewFoodGuestsManagement} />
        </div>
        <div className='w-full md:w-[50%] md:p-0 md:pr-0  flex-col'>
          <h1
            className='text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'
            style={{alignSelf: "left"}}
          >
            Customer Surveys and Ratings
          </h1>

          <p className='text-[#666666] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
            Gather Valuable Feedback
          </p>
          <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
            {[
              "VenueBoost now offers powerful Surveys and Ratings to help venues enhance customer experiences and gather crucial feedback.",
              "Create customized customer surveys",
              "Collect ratings and reviews to gauge satisfaction",
              "Gain insights into preferences and opinions",
              "Make informed improvements using data-driven decisions",
            ].map(item => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className='text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4'>
            Listen to your customers and optimize offerings with VenueBoost's
            Surveys and Ratings.
          </p>
          <FreeTrial />
        </div>
      </div>
      <div className='flex flex-col-reverse md:flex-row mt-10 md:mt-[38px] '>
        <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0'>
          <img src={OverviewImg8} />
        </div>
        <div className='w-full md:w-[40%] md:p-12 md:pl-0   '>
          <h1 className='text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'>
            Affiliate Partnerships{" "}
          </h1>
          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            Expand Your Reach and Revenue
          </p>

          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            With VenueBoost's new Affiliates feature, you can grow your business
            and revenue by partnering with like-minded organizations.
          </p>
          <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
            {[
              "Establish affiliate partnerships seamlessly",
              "Track referrals and commissions in real-time",
              "Access valuable marketing resources",
              "Extend your brand's reach through strategic collaborations",
            ].map(item => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className='text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4'>
            Join forces with affiliates and unlock new opportunities with
            VenueBoost's Affiliates.
          </p>
          <FreeTrial />
        </div>
      </div>

      <div className='flex flex-col-reverse md:flex-row-reverse mt-0 md:mt-[38px]'>
        <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0'>
          <img src={OverviewFoodGuestsManagement} />
        </div>
        <div className='w-full md:w-[50%] md:p-0 md:pr-0  flex-col'>
          <h1
            className='text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'
            style={{alignSelf: "left"}}
          >
            Centralized Analytics for Multi-Brand Retailers
          </h1>

          <p className='text-[#666666] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
            Maximize Success of Every Brand
          </p>
          <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
            {[
              "VenueBoost introduces Centralized Analytics, designed for multi-brand retailers to optimize performance across all brands.",
              "Access comprehensive analytics for each brand from one dashboard",
              "Make data-driven decisions to enhance individual brand strategies",
              "Simplify reporting and analytics management across your portfolio",
            ].map(item => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className='text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4'>
            VenueBoost empowers multi-brand retailers with a centralized system
            to oversee analytics and maximize the success of every brand.
          </p>
          <FreeTrial />
        </div>
      </div>

      <Grow
        venueColor={"#E14848"}
        title={"Additional Retail Management Features"}
        subtitle={
          "Dive into VenueBoost's Expanded Capabilities for the Retail Management Industry"
        }
        tabs={tabs}
      />

      <div className='align-col-middle '>
        <div className='w-[100vw] bg-[#240B3B]'>
          <div className='max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 '>
            <div className='flex flex-col align-middle w-full'>
              <h1 className=' text-white text-26 md:text-[42px] text-center font-extrabold leading-[60px] mt-10 md:mt-20 '>
                {t("who_we_serve.retail_industry.faq")}
                <span className=' text-white '> (FAQ) </span>
              </h1>
              <p className='text-14 md:text-20 text-[#fff] px-7 leading-5 font-medium mt-4 mb:mt-20 mb-5 md:mb-[66px]'>
                {t("who_we_serve.retail_industry.retail_industry")}
              </p>
              <Faq faqData={generalFaq} className='mt-2 mb-4 md:mb-16' />
            </div>
          </div>
        </div>
      </div>

      <Advantage venueDefinition={"retail industry"} />
      <div className='align-col-middle'>
        <div className='w-[100vw] bg-white'>
          <div className='mx-auto max-w-screen-main px-4 sm:px-6 md:px-8 lg:px-16'>
            <div className='flex flex-col align-middle mb-4 md:mb-20'>
              <FreePlanCard />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeDecor;
