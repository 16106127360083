import React, { useEffect, useState } from "react";
import { loadFacebookSDK } from "../../utils/facebook-login";

export const LoginWithFacebook = () => {
  const [sdkLoaded, setSdkLoaded] = useState(false);

  const onLogin = (accessToken) => {
    localStorage.setItem("fbToken", accessToken);
  };

  useEffect(() => {
    loadFacebookSDK()
      .then(() => setSdkLoaded(true))
      .catch(console.error);
  }, []);

  const handleLogin = () => {
    if (!sdkLoaded || !window.FB) {
      console.error("Facebook SDK not loaded.");
      return;
    }

    window.FB.login(
      (response) => {
        if (response.authResponse) {
          const accessToken = response.authResponse.accessToken;
          const code = response.authResponse.code;

          if (code || accessToken) {
            console.log(response, "response");
            onLogin(code);
          }
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      {
        config_id: "428521389983918",
        response_type: "code",
        override_default_response_type: true,
        extras: {
          setup: {
            // Prefilled data can go here
          },
        },
      }
    );
  };

  return (
    <div className="align-col-middle">
      <div className="w-full">
        <div
          className={
            "mx-auto flex justify-center items-center px-7 py-12 md:py-24 sm:px-6 md:px-12 lg:px-24"
          }
        >
          <button
            disabled={!sdkLoaded}
            className="w-1/3 p-10 submit-button font-normal h-auto py-2 md:py-4 md:px-6 text-[13px] md:text-18 rounded-[5px] text-white transition ease-in-out hover:scale-101 bg-gradient-to-r from-[#240B3B] via-[#601B86] to-[#BC14FE]"
            onClick={handleLogin}
          >
            Login with Facebook
          </button>
        </div>
      </div>
    </div>
  );
};
