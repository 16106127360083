import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout, logoutAffiliate } from "../../redux/actions/auth";
import { Icon } from "react-icons-kit";
import { user_circle } from "react-icons-kit/ikons/user_circle";
import { chevronDown } from "react-icons-kit/feather/chevronDown";
import { chevronUp } from "react-icons-kit/feather/chevronUp";
import { chevronRight } from "react-icons-kit/feather/chevronRight";
import DropdownMenu from "../../components/DropdownMenu";
import DropdownMenuMobile from "../../components/DropdownMenuMobile";
import DropdownMenuMobile2 from "../../components/DropdownMenuMobile2";
import LangSelectorMobile from "../../components/LangSelectorMobile";
import LangSelector from "../../components/LangSelector";
import DropdownsNewMenuMobile from "../../components/DropdownsNewMenuMobile";
import { useTranslation } from "react-i18next";

import Avatar from "../../assets/svgs/alex_r_avatar.svg";
import DashboardIcon from "../../assets/svgs/dashboard_icon.svg";
import PersonalIcon from "../../assets/svgs/personalinfo_icon.svg";
import ReservationIcon from "../../assets/svgs/reservation_icon.svg";
import PromotionIcon from "../../assets/svgs/promotions_icon.svg";
import PaymentIcon from "../../assets/svgs/payment_icon.svg";
import WalletIcon from "../../assets/svgs/wallet_icon.svg";
import SecurityIcon from "../../assets/svgs/security_icon.svg";
import LogoutIcon from "../../assets/svgs/logout_icon.svg";
import { NotificationManager } from "react-notifications";
import { useLocation, useNavigate } from "react-router-dom";
import RouteNames from "../../constants/route_names";
import { MdShoppingCart } from "react-icons/md";
import { getStorageKey, KEYS } from "../../utils/storage";
import Logo from "../../assets/svgs/logo-png-header.png";
import {
  resource_menus,
  whoweserve_new_menu,
  why_venueboost_menu,
} from "./header-data";

const Header = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isPromotionOpen, setIsPromotionOpen] = useState(false);
  const [affiliatesHeader, setAffiliatesHeader] = useState(false);
  const { products } = useSelector((state) => state.cart);

  useEffect(() => {
    pathname === "/affiliates/dashboard"
      ? setAffiliatesHeader(true)
      : setAffiliatesHeader(false);
  }, [pathname]);

  // Determine if the current route is the pricing route
  const isPricingRoute = pathname === "/pricing-plans";

  const isVenue =
    pathname.startsWith("/venue") || pathname.startsWith("/retail");

  const hideHeader =
    pathname.includes("/beach-bar-order") ||
    pathname.includes("/venue/restaurant");

  const onLogoutAffilate = () => {
    dispatch(logoutAffiliate())
      .then((data) => {
        navigate(RouteNames.affiliates_login);
        NotificationManager.success(
          data?.message || "Logout Success",
          "Success",
          5000
        );
      })
      .catch((error) => {
        NotificationManager.error(
          error.message ||
            "Oops! Something went wrong. Please try again or contact us directly at contact@venueboost.io.",
          "Error",
          3000
        );
        throw new Error("Error with logout affiliate");
      });
  };

  const onLogout = () => {
    dispatch(logout())
      .then((data) => {
        NotificationManager.success(
          data?.message || "Logout Success",
          "Success",
          5000
        );
        localStorage.removeItem("vbToken");
      })
      .catch((error) => {
        NotificationManager.error(
          error.message ||
            "Oops! Something went wrong. Please try again or contact us directly at contact@venueboost.io.",
          "Error",
          3000
        );
        throw new Error("Error with logout");
      });
  };

  const [isNavOpen, setIsNavOpen] = useState(false);

  const goToCart = () => {
    let hospitalRoomKey = getStorageKey(KEYS.HOSPITAL_ROOM_KEY);
    if (hospitalRoomKey) {
      let route = `/food-checkout`;
      navigate(route);
    }
    let venueKey = getStorageKey(KEYS.CART_VENUE_KEY);
    if (venueKey?.venue_app_key) {
      let route = `/venue/retail/${venueKey?.venue_app_key}/cart`;
      navigate(route);
    }
  };

  const isRestaurantView = pathname.includes("/venue/restaurant");

  const isLoggedInUser = pathname.includes("/customer/");

  return isLoggedInUser ? (
    <div className={"h-5 border"}></div>
  ) : (
    <>
      <header
        aria-label="Site Header"
        className={`bg-white header ${hideHeader ? "hidden" : ""}`}
        style={{ boxShadow: "0px 1px 2px 0px #00000040" }}
      >
        <div className="hidden lg:block" style={{ backgroundColor: "#d9cde4" }}>
          <div className="flex h-8 items-center justify-end mx-auto max-w-screen-main px-4 sm:px-6 md:px-8 lg:px-16">
            <ul className="flex items-center gap-6 text-sm">
              {!props?.fromWaitlist && (
                <li>
                  <a
                    className={
                      "font-medium text-[#666A73] text-14 transition hover:text-black1/75"
                    }
                    href="/contact-us"
                  >
                    {t("topbar.contact_us1")}
                  </a>
                </li>
              )}
              {products && products.length > 0 && (
                <div
                  onClick={() => goToCart()}
                  style={{ position: "relative", cursor: "pointer" }}
                >
                  <MdShoppingCart color="#666A73" />
                  <div
                    style={{
                      position: "absolute",
                      top: "-5px", // Adjust this value to position the badge properly
                      right: "-10px", // Adjust this value to position the badge properly
                      backgroundColor: "#240b3b", // Customize badge background color
                      color: "white", // Customize badge text color
                      borderRadius: "50%", // Create a circular badge
                      fontSize: "8px", // Adjust font size as needed
                      height: "14px",
                      lineHeight: "14px",
                      textAlign: "center",
                      width: "14px",
                    }}
                  >
                    {products.length}
                  </div>
                </div>
              )}
              <li>
                <LangSelector />
              </li>
            </ul>
          </div>
        </div>
        {!affiliatesHeader ? (
          <div className="mx-auto max-w-screen-main px-4 sm:px-6 md:px-8 lg:px-16">
            <div
              className={`${
                isRestaurantView ? "hidden" : "flex"
              } h-20 items-center justify-between`}
            >
              <div className="flex md:items-center md:gap-12">
                <a
                  className="text-teal-600 flex items-center  gap-4 logo"
                  href="/"
                  style={{ marginLeft: "15px" }}
                >
                  <img src={Logo} alt="VB Logo" style={{ width: "225px" }} />
                </a>
              </div>

              {!props?.fromWaitlist && (
                <div className={`hidden ${isVenue ? "" : "lg:block"}`}>
                  <div className="nav flex flex-col lg:flex-row   gap-12 w-full">
                    <nav aria-label="Site Nav">
                      <ul className="flex flex-col justify-start lg:flex-row text-sm">
                        <li>
                          <DropdownsNewMenuMobile
                            menu={whoweserve_new_menu}
                            title="Who we serve"
                          />
                        </li>
                        <li className="flex w-full text-16 lg:h-20 align-middle nav-item-header font-medium text-[#666A73] transition gap-1 cursor-pointer px-4 hover:text-black1/75">
                          <a
                            className={`text-16 h-0 font-medium align-middle ${
                              isPricingRoute ? "text-[#8b1dbf]" : ""
                            } nav-link transition hover:text-black1/75`}
                            href="/pricing-plans"
                          >
                            {t("header.pricing")}
                          </a>
                        </li>

                        <li>
                          <DropdownMenu
                            menu={why_venueboost_menu}
                            title="Why VenueBoost"
                          />
                        </li>

                        <li>
                          <DropdownMenu
                            menu={resource_menus}
                            title="Resources"
                          />
                        </li>
                      </ul>
                    </nav>

                    {user && !user.affiliate_code ? (
                      <div className="flex items-center gap-4 w-full justify-center">
                        <div
                          className="relative flex flex-col xs:flex-row gap-4 justify-center"
                          onMouseOver={() => setIsDropdownOpen(true)}
                          onMouseLeave={() => setIsDropdownOpen(false)}
                        >
                          <div className="flex ">
                            <div
                              className="w-full rounded-md bg-primary1 px-2 lg:px-4 py-2 text-center text-16 font-medium text-white shadow gap-2 flex"
                              style={{ lineHeight: "190%" }}
                            >
                              <Icon
                                icon={user_circle}
                                style={{ color: "#DDDDDD" }}
                                size={20}
                              />
                              {user?.name}
                              <Icon
                                icon={chevronDown}
                                style={{ color: "#DDDDDD" }}
                                size={20}
                              />
                            </div>
                          </div>
                          {isDropdownOpen && (
                            <div className="absolute top-[48px] right-0 bg-white boxshadow border-[1px] border-[#DBDBDB] rounded-[4px] p-6 w-[300px] flex flex-col gap-5">
                              <a
                                className="rounded-[4px] p-3 hover:bg-[#E7EAEE] flex-1 flex gap-2"
                                href="/dashboard"
                              >
                                <img
                                  alt=""
                                  src={DashboardIcon}
                                  className="w-[24px] h-[24px]"
                                />
                                <p className="text-[#191D23] text-16 font-semibold">
                                  Dashboard
                                </p>
                              </a>
                              <a
                                className="rounded-[4px] p-3 hover:bg-[#E7EAEE] flex-1 flex gap-2"
                                href="/personal-information"
                              >
                                <img
                                  alt=""
                                  src={PersonalIcon}
                                  className="w-[24px] h-[24px]"
                                />
                                <p className="text-[#191D23] text-16 font-semibold">
                                  Personal Information
                                </p>
                              </a>
                              <a
                                className="rounded-[4px] p-3 hover:bg-[#E7EAEE] flex-1 flex gap-2"
                                href="/reservations"
                              >
                                <img
                                  alt=""
                                  src={ReservationIcon}
                                  className="w-[24px] h-[24px]"
                                />
                                <p className="text-[#191D23] text-16 flex-1 font-semibold">
                                  Reservations
                                </p>
                                <p className="bg-[#240b3b] text-16 text-white px-[10px] rounded-[4px]">
                                  32
                                </p>
                              </a>
                              <div
                                className="rounded-[4px] p-3 hover:bg-[#E7EAEE] flex-1 flex gap-2"
                                onClick={() =>
                                  setIsPromotionOpen(!isPromotionOpen)
                                }
                              >
                                <img
                                  alt=""
                                  src={PromotionIcon}
                                  className="w-[24px] h-[24px]"
                                />
                                <p className="text-[#191D23] flex-1 text-16 font-semibold">
                                  Promotions
                                </p>
                                {isPromotionOpen ? (
                                  <Icon
                                    icon={chevronUp}
                                    style={{ color: "#64748B" }}
                                    size={20}
                                  />
                                ) : (
                                  <Icon
                                    icon={chevronDown}
                                    style={{ color: "#64748B" }}
                                    size={20}
                                  />
                                )}
                              </div>
                              {isPromotionOpen && (
                                <div className="ml-6 border-l-[1px] pl-4 mt-[-12px]">
                                  <div className="rounded-[4px] p-3 hover:bg-[#E7EAEE] flex-1 flex gap-2">
                                    <p className="text-[#191D23] text-16 font-semibold">
                                      Yours
                                    </p>
                                  </div>
                                  <div className="rounded-[4px] p-3 hover:bg-[#E7EAEE] flex-1 flex gap-2">
                                    <p className="text-[#191D23] flex-1 text-16 font-semibold">
                                      Calendar
                                    </p>
                                    <Icon
                                      icon={chevronRight}
                                      style={{ color: "#64748B" }}
                                      size={20}
                                    />
                                  </div>
                                </div>
                              )}
                              <a
                                className="rounded-[4px] p-3 hover:bg-[#E7EAEE] flex-1 flex gap-2"
                                href="/payments"
                              >
                                <img
                                  alt=""
                                  src={PaymentIcon}
                                  className="w-[24px] h-[24px]"
                                />
                                <p className="text-[#191D23] text-16 font-semibold">
                                  Payments
                                </p>
                              </a>
                              <a
                                className="rounded-[4px] p-3 hover:bg-[#E7EAEE] flex-1 flex gap-2"
                                href="/wallet"
                              >
                                <img
                                  alt=""
                                  src={WalletIcon}
                                  className="w-[24px] h-[24px]"
                                />
                                <p className="text-[#191D23] text-16 font-semibold">
                                  Wallet
                                </p>
                              </a>
                              <a
                                className="rounded-[4px] p-3 hover:bg-[#E7EAEE] flex-1 flex gap-2"
                                href="/login-security"
                              >
                                <img
                                  alt=""
                                  src={SecurityIcon}
                                  className="w-[24px] h-[24px]"
                                />
                                <p className="text-[#191D23] text-16 font-semibold">
                                  Login and Security
                                </p>
                              </a>
                              <hr className="border-[#D0D5DD] border-t-[1px] " />
                              <p className="text-16 text-[#64748B] font-semibold">
                                Profile
                              </p>
                              <div className="flex  gap-2">
                                <img
                                  alt=""
                                  src={Avatar}
                                  className="rounded-100 w-[42px] h-[42px]"
                                />
                                <div className="justify-between">
                                  <p className="text-16 text-[#191D23] font-semibold">
                                    {user?.name}
                                  </p>
                                  <p className="text-16 text-[#A0ABBB] font-semibold">
                                    {user.email}
                                  </p>
                                </div>
                              </div>
                              <div
                                className="rounded-[4px] p-3 cursor-pointer bg-[#F7F8F9] flex-1 flex gap-1 align-middle"
                                onClick={onLogout}
                              >
                                <img
                                  alt=""
                                  src={LogoutIcon}
                                  className="w-[24px] h-[24px]"
                                />
                                <p className="text-[#191D23]  text-16 font-semibold">
                                  Log out
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="flex items-center gap-4 w-full justify-center">
                        <div className="flex flex-col xs:flex-row gap-4 justify-center">
                          <div className="flex">
                            <a
                              className="w-full rounded-md bg-primary1 px-2 lg:px-4 py-2 text-center text-16 font-medium text-white shadow"
                              href="/get-started"
                              style={{
                                lineHeight: "190%",
                                background:
                                  "linear-gradient(100.8deg, #240B3B 3.95%, #3D1E59 34%, #BC14FE 94.28%)",
                              }}
                            >
                              {t("header.get_started")}
                            </a>
                          </div>
                          <div className="flex">
                            <a
                              className="w-full rounded-md border-2 border-primary1 bg-white text-center px-2 lg:px-4 py-2 text-16 font-medium  text-primary1"
                              href="https://admin.venueboost.io/"
                              style={{ lineHeight: "160%" }}
                              // onClick={() => handleLogin()}
                            >
                              {t("header.login")}
                            </a>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {isNavOpen && (
                <div className="flex flex-col gap-12 w-full header-menu-mobile p-0 m-0 bg-gray-100">
                  <nav aria-label="Site Nav">
                    <ul className="flex flex-col gap-4 text-sm">
                      <li>
                        <DropdownMenuMobile2
                          menu={whoweserve_new_menu}
                          title={t("header.dropdown_serve")}
                        />
                      </li>
                      <li>
                        <a
                          className={
                            "text-18 m-4 mx-6 font-medium align-start text-black1 transition hover:text-black1/75"
                          }
                          href="/pricing-plans"
                        >
                          {t("topbar.pricing")}
                        </a>
                      </li>

                      <li>
                        <DropdownMenuMobile
                          menu={why_venueboost_menu}
                          title={t("header.dropdown_venueboost")}
                        />
                      </li>

                      <li>
                        <DropdownMenuMobile
                          menu={resource_menus}
                          title={t("header.dropdown_resource")}
                        />
                      </li>

                      <li>
                        <LangSelectorMobile
                          title={t("header.dropdown_language")}
                          closeNav={setIsNavOpen}
                        />
                      </li>
                    </ul>
                  </nav>

                  <div className="flex flex-col xs:flex-row gap-4 justify-center">
                    <a
                      className=" mx-2 rounded-md bg-primary1 px-4 py-2 text-center text-16 font-medium text-white shadow"
                      href="/get-started"
                      style={{
                        lineHeight: "190%",
                        background:
                          "linear-gradient(100.8deg, #240B3B 3.95%, #3D1E59 34%, #BC14FE 94.28%)",
                      }}
                    >
                      {t("topbar.get_started")}
                    </a>
                    <a
                      className=" mx-2 rounded-md border-2 border-primary1 bg-white text-center px-4 py-2 text-16 font-medium  text-primary1"
                      href="https://admin.venueboost.io/"
                      style={{ lineHeight: "160%" }}
                      // onClick={() => handleLogin()}
                    >
                      {t("topbar.login")}
                    </a>
                  </div>
                </div>
              )}

              {!props?.fromWaitlist && (
                <button
                  data-collapse-toggle="navbar-default"
                  type="button"
                  className="inline-flex items-center p-2 ml-3 text-sm text-primary1 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
                  aria-controls="navbar-default"
                  onClick={() => setIsNavOpen((prev) => !prev)}
                >
                  <span className="sr-only">{t("topbar.open_menu")}</span>
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              )}
            </div>
          </div>
        ) : (
          <div className="mx-auto max-w-screen-main px-4 sm:px-6 md:px-8 lg:px-16">
            <div
              className={`${
                isRestaurantView ? "hidden" : "flex"
              } h-20 items-center justify-between`}
            >
              <div className="flex md:items-center md:gap-12">
                <a
                  className="text-teal-600 flex items-center  gap-4 logo"
                  href="/"
                >
                  <img src={Logo} alt="VB Logo" style={{ width: "225px" }} />
                </a>
              </div>

              <div className={`hidden ${isVenue ? "" : "lg:block"}`}>
                <div className="nav flex flex-col lg:flex-row   gap-12 w-full">
                  <nav aria-label="Site Nav">
                    <ul className="flex flex-col justify-start lg:flex-row text-sm">
                      <li className="flex w-full text-16 lg:h-20 align-middle font-medium text-[#666A73] transition gap-1 cursor-pointer px-4 hover:text-black1/75">
                        <a
                          className={
                            "text-16 h-20 font-medium align-middle text-[#666A73] transition hover:text-black1/75"
                          }
                          href="/affiliates/dashboard"
                        >
                          Dashboard
                        </a>
                      </li>

                      <li className="flex w-full text-16 lg:h-20 align-middle font-medium text-[#666A73] transition gap-1 cursor-pointer px-4 hover:text-black1/75">
                        <div
                          className={
                            "text-16 h-20 font-medium align-middle text-[#666A73] transition hover:text-black1/75 whitespace-nowrap"
                          }
                        >
                          {user?.name}
                        </div>
                      </li>
                    </ul>
                  </nav>

                  <div className="flex items-center gap-4 w-full justify-center">
                    <div className="flex flex-col xs:flex-row gap-4 justify-center">
                      <div className="flex">
                        <div
                          className="cursor-pointer w-full rounded-md border-2 border-primary1 bg-white text-center px-2 lg:px-4 py-2 text-16 font-medium  text-primary1"
                          style={{ lineHeight: "160%" }}
                          onClick={onLogoutAffilate}
                        >
                          Log out
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <button
                data-collapse-toggle="navbar-default"
                type="button"
                className="inline-flex items-center p-2 ml-3 text-sm text-primary1 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
                aria-controls="navbar-default"
                onClick={() => setIsNavOpen((prev) => !prev)}
              >
                <span className="sr-only">{t("topbar.open_menu")}</span>
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        )}
      </header>
      <div className=" h-[80px] lg:h-[112px]" />
    </>
  );
};

export default Header;
