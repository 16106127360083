import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";

import "./index.css";

import {Seo} from "../../../../components/Seo";
import Faq from "../../../../components/Faq";
// import Pricing from "../../../../components/Pricing";
// import VideoPlayer from "../../../../components/VideoPlayer";
import Advantage from "../../../../components/Advantage";
import Grow from "../../grow";

import CircleTick from "../../../../assets/svgs/checkfull.svg";
// import ArrowWhiteSVG from "../../../../assets/svgs/arrow-white.svg";
import OverviewAccommodation from "../../../../assets/images/who-we-serve/accomodation.webp";
import OverviewImg1 from "../../../../assets/images/who-we-serve/overviewimg1.webp";
import OverviewImg2 from "../../../../assets/images/who-we-serve/overviewimg2.webp";
import OverviewImg3 from "../../../../assets/images/who-we-serve/overviewimg3.webp";
import OverviewImg4 from "../../../../assets/images/who-we-serve/overviewimg4.webp";
import OverviewImg5 from "../../../../assets/images/who-we-serve/overviewimg5.webp";
import OverviewImg6 from "../../../../assets/images/who-we-serve/overviewimg6.webp";
import OverviewImg7 from "../../../../assets/images/who-we-serve/overviewimg7.webp";
// import CheckOurlineIcon from "../../../../assets/svgs/checkoutline.svg";
// import Avatar1 from "../../../../assets/svgs/eric_p_avatar.svg";
// import Avatar2 from "../../../../assets/svgs/alex_r_avatar.svg";
// import Avatar3 from "../../../../assets/svgs/sara_m_avatar.svg";
// import AnyVenue from "../../../../assets/svgs/anyvenue.svg";
// import Dot1 from "../../../../assets/svgs/dot1.svg";
// import Dot2 from "../../../../assets/svgs/dot2.svg";
// import Dot3 from "../../../../assets/svgs/dot3.svg";

// import AccommodationCozy from "../../../../assets/svgs/accommodation-cozy.svg";
// import AccommodationHotelchain from "../../../../assets/svgs/accommodation-hotelchain.svg";
// import AccommodationHotel from "../../../../assets/svgs/accommodation-hotel.svg";
// import AccommodationHostel from "../../../../assets/svgs/accommodation-hostel.svg";
// import AccommodationVacation from "../../../../assets/svgs/accommodation-vacation.svg";
import AccommodationGuestsManagement from "../../../../assets/images/who-we-serve/accomodation-guests.webp";
import {NotificationManager} from "react-notifications";
import {create as createContactSale} from "../../../../redux/actions/contactsales";
import {t} from "i18next";
// import FoodWhoWeServe2 from "../../../../assets/svgs/food-who-serve-2.svg";
// import FoodWhoWeServe3 from "../../../../assets/svgs/food-who-serve-3.svg";
// import AccommodationResorts from "../../../../assets/svgs/resort.svg";
import OverviewImg8 from "../../../../assets/images/who-we-serve/overviewimg8.webp";
import OverviewFoodGuestsManagement from "../../../../assets/images/who-we-serve/food-guests-management.webp";
// import AccommodationLuxuryResorts from "../../../../assets/svgs/luxuryresort.svg";
// import NewMenuHotel from "../../../../assets/svgs/new-menu-hotel.svg";
// import NewMenuApt from "../../../../assets/svgs/new-menu-apt.svg";
// import NewMenuBedAndBreakfast from "../../../../assets/svgs/new-menu-bedandbreakfast.svg";
// import NewMenuMotel from "../../../../assets/svgs/new-menu-motel.svg";
// import NewMenuCapsuleHotels from "../../../../assets/svgs/new-menu-capsule-hotels.svg";
// import NewMenuHostel from "../../../../assets/svgs/new-menu-hostel.svg";
// import NewMenuCampGround from "../../../../assets/svgs/new-menu-campground.svg";
// import NewMenuRVParks from "../../../../assets/svgs/new-menu-rvparks.svg";
import {CheckLine} from "../../../../components/CheckLine";
import FreeTrial from "../../../../components/FreeTrial";
import FreePlanCard from "../../../../components/FreePlanCard";

const generalFaq = [
  {
    id: 1,
    status: false,
    title: "What types of accommodation businesses can use VenueBoost?",
    content:
      "VenueBoost is ideal for a diverse range of accommodations, including hotels, cozy retreats, hostels, hotel chains, vacation rentals, luxury and family resorts, serviced apartments, bed and breakfasts, motels, capsule hotels, youth hostels, campgrounds, and RV parks.",
  },
  {
    id: 2,
    status: false,
    title:
      "How does VenueBoost streamline the bookings management process for accommodation venues?",
    content:
      "VenueBoost equips accommodation businesses with tools to efficiently manage and streamline their reservation systems, enhancing guest experience and simplifying the booking process.",
  },
  {
    id: 3,
    status: false,
    title:
      "Can VenueBoost assist with inventory management for my accommodation business?",
    content:
      "Yes, VenueBoost enables hotels, resorts, and rentals to categorize and track rooms, amenities, and facilities effectively, optimizing resource management and improving the overall guest experience.",
  },
  {
    id: 4,
    status: false,
    title:
      "How does VenueBoost facilitate staff management in the accommodation industry?",
    content:
      "VenueBoost offers integrated staff scheduling and time tracking capabilities, enhancing operational efficiency and ensuring seamless coordination of staff duties and hours.",
  },
  {
    id: 5,
    status: false,
    title:
      "What marketing tools does VenueBoost provide for accommodation businesses?",
    content:
      "VenueBoost includes integrated marketing tools to help promote your brand, attract new guests, and create effective campaigns, utilizing promotions, emails, and referral programs.",
  },
  {
    id: 6,
    status: false,
    title:
      "How can accommodation businesses benefit from VenueBoost's loyalty and retention programs?",
    content:
      "VenueBoost enables the creation of customized loyalty programs, helping to keep guests engaged and encouraging repeat stays, thereby fostering long-term customer relationships.",
  },
  {
    id: 7,
    status: false,
    title:
      "What analytics and reporting features does VenueBoost offer for accommodation venues?",
    content:
      "VenueBoost provides robust analytics and reporting tailored for the accommodation industry, allowing businesses to gain insights into their performance and make data-driven decisions.",
  },
  {
    id: 8,
    status: false,
    title:
      "How does VenueBoost handle payment processing for accommodation businesses?",
    content:
      "The platform enables the easy generation and management of tailored payment links for online reservations, facilitating secure and hassle-free financial transactions.",
  },
  {
    id: 9,
    status: false,
    title:
      "Can VenueBoost enhance guest management for hotels and other accommodations?",
    content:
      "Yes, VenueBoost equips accommodation venues with capabilities to engage guests, manage bookings, and provide personalized stays, enhancing the overall guest experience.",
  },
  {
    id: 10,
    status: false,
    title:
      "What kind of onboarding support does VenueBoost provide for new accommodation clients?",
    content:
      "VenueBoost offers a comprehensive onboarding process, including training, resources, and dedicated customer support to ensure a smooth setup and efficient use of our platform.",
  },
];

const ServiceApartments = () => {
  const navigate = useNavigate();
  const currentUrl =
    "https://venueboost.io/who-we-serve/accommodation/service-apartments";
  const seoTitle =
    "Convenience and Comfort with Technology at Service Apartments";
  const seoDescription =
    "Deliver convenience and comfort through technology at our service apartments. Enjoy a seamless stay with modern amenities and personalized services.";

  const [step, setStep] = useState(1);
  const [venuetype, setVenuetype] = useState("hotel");
  const [first_name, setFirstname] = useState("");
  const [last_name, setLastname] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [restaurant_name, setRestaurantName] = useState("");
  const [restaurant_city, setRestaurantCity] = useState("");
  const [restaurant_state, setRestaurantState] = useState("");
  const [restaurant_zipcode, setRestaurantZipcode] = useState("");
  const [restaurant_country, setRestaurantCountry] = useState("");
  const [showMore, setShowMore] = useState(false);

  const handleSecondStepSelect = () => {
    if (!first_name || !last_name || !mobile || !email) {
      NotificationManager.error(
        t("who_we_serve.accommodation_industry.error_msg"),
        t("who_we_serve.accommodation_industry.error"),
        3000
      );
    } else {
      setStep(3);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigateToUrl = url => {
    navigate(url);
  };

  const handleVenueZipCode = e => {
    setRestaurantZipcode(e.target.value);
  };

  const handleVenueState = e => {
    setRestaurantState(e.target.value);
  };

  const handleVenueName = e => {
    setRestaurantName(e.target.value);
  };

  const handleVenueCity = e => {
    setRestaurantCity(e.target.value);
  };

  const handleVenueCountry = e => {
    setRestaurantCountry(e.target.value);
  };

  const handleFirstname = e => {
    setFirstname(e.target.value);
  };

  const handleLastname = e => {
    setLastname(e.target.value);
  };

  const handleMobile = e => {
    setMobile(e.target.value);
  };

  const handleEmail = e => {
    setEmail(e.target.value);
  };

  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      title: "iCal Integration",
      content: (
        <div className='flex flex-col-reverse md:flex-row mt-0 md:mt-[58px]'>
          <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0'>
            <img src={OverviewFoodGuestsManagement} />
          </div>
          <div className='w-full md:w-[50%] md:p-0 md:pr-0  flex-col'>
            <h1
              className='text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'
              style={{alignSelf: "left"}}
            >
              Streamlined Availability Management
            </h1>

            <p className='text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
              VenueBoost offers seamless iCal Integration designed exclusively
              for accommodations. Easily manage booking calendars and prevent
              overlapping reservations.
            </p>
            <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Sync your availability with iCal-supported booking platforms
                </span>
              </div>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Avoid double bookings and ensure a smooth process
                </span>
              </div>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Automatically update changes across all platforms
                </span>
              </div>
            </div>
            <p className='text-[#666666] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px] mb-8'>
              With VenueBoost's iCal Integration, your accommodation bookings
              will run smoothly.
            </p>
          </div>
        </div>
      ),
      title_modal: "iCal Integration",
      content1: "Streamlined Availability Management",
      content2:
        "VenueBoost offers seamless iCal Integration designed exclusively for accommodations. Easily manage booking calendars and prevent overlapping reservations.",
      content3: [
        "Sync your availability with iCal-supported booking platforms",
        "Avoid double bookings and ensure a smooth process",
        "Automatically update changes across all platforms",
      ],
      content4:
        "With VenueBoost's iCal Integration, your accommodation bookings will run smoothly.",
    },
    {
      title: "Accommodation Guest Loyalty Program",
      content: (
        <div className='flex flex-col-reverse md:flex-row mt-0 md:mt-[58px]'>
          <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0'>
            <img src={OverviewFoodGuestsManagement} />
          </div>
          <div className='w-full md:w-[50%] md:p-0 md:pr-0  flex-col'>
            <h1
              className='text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'
              style={{alignSelf: "left"}}
            >
              For Streamlined Stays
            </h1>

            <p className='text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
              VenueBoost's Accommodation Guest Loyalty Program streamlines
              reservations and guest interactions.
            </p>
            <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Reward guests with points for each stay or booking
                </span>
              </div>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Grant perks like room upgrades as guests collect points
                </span>
              </div>
            </div>

            <p className='text-[#666666] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px] mb-8'>
              Optimize your accommodation business with VenueBoost's
              Accommodation Guest Loyalty Program.
            </p>
          </div>
        </div>
      ),
      title_modal: "Accommodation Guest Loyalty Program",
      content1: "For Streamlined Stays",
      content2:
        "VenueBoost's Accommodation Guest Loyalty Program streamlines reservations and guest interactions.",
      content3: [
        "Reward guests with points for each stay or booking",
        "Grant perks like room upgrades as guests collect points",
      ],
      content4:
        "Optimize your accommodation business with VenueBoost's Accommodation Guest Loyalty Program.",
    },
    {
      title: "Advanced Guest Behavior Analytics",
      content: (
        <div className='flex flex-col-reverse md:flex-row mt-0 md:mt-[58px]'>
          <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0'>
            <img src={OverviewFoodGuestsManagement} />
          </div>
          <div className='w-full md:w-[50%] md:p-0 md:pr-0  flex-col'>
            <h1
              className='text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'
              style={{alignSelf: "left"}}
            >
              Data-Driven Guest Insights
            </h1>

            <p className='text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
              Gain deep insights into guest behavior with VenueBoost's Advanced
              Guest Behavior Analytics. Understand your guests better and tailor
              your services accordingly.
            </p>
            <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Analyze preferences, history, and engagement patterns
                </span>
              </div>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Make data-driven decisions to improve experiences
                </span>
              </div>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Effectively target marketing based on behavior insights
                </span>
              </div>
            </div>
            <p className='text-[#666666] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px] mb-8'>
              VenueBoost empowers businesses to enhance guest engagement through
              data-driven strategies.
            </p>
          </div>
        </div>
      ),
      title_modal: "Advanced Guest Behavior Analytics",
      content1: "Data-Driven Guest Insights",
      content2:
        "Gain deep insights into guest behavior with VenueBoost's Advanced Guest Behavior Analytics. Understand your guests better and tailor your services accordingly.",
      content3: [
        "Analyze preferences, history, and engagement patterns",
        "Make data-driven decisions to improve experiences",
        "Effectively target marketing based on behavior insights",
      ],
      content4:
        "VenueBoost empowers businesses to enhance guest engagement through data-driven strategies.",
    },
    {
      title: "Marketing Automation",
      content: (
        <div className='flex flex-col-reverse md:flex-row mt-0 md:mt-[58px]'>
          <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0'>
            <img src={OverviewFoodGuestsManagement} />
          </div>
          <div className='w-full md:w-[50%] md:p-0 md:pr-0  flex-col'>
            <h1
              className='text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'
              style={{alignSelf: "left"}}
            >
              Boost Engagement and Sales
            </h1>

            <p className='text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
              Take your marketing to the next level with VenueBoost's Marketing
              Automation. Seamlessly automate and personalize campaigns to drive
              engagement and sales.
            </p>
            <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Create targeted email campaigns based on guest behavior
                </span>
              </div>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Send personalized offers and recommendations
                </span>
              </div>
              <div className='flex flex-row items-center'>
                <img
                  src={CircleTick}
                  className='w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]'
                />
                <span className='text-12  md:text-18 font-medium text-[#666666] inline'>
                  Measure effectiveness and optimize strategies
                </span>
              </div>
            </div>
            <p className='text-[#666666] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px] mb-8'>
              VenueBoost streamlines your marketing across industries with
              powerful automation.
            </p>
          </div>
        </div>
      ),
      title_modal: "Marketing Automation",
      content1: "Boost Engagement and Sales",
      content2:
        "Take your marketing to the next level with VenueBoost's Marketing Automation. Seamlessly automate and personalize campaigns to drive engagement and sales.",
      content3: [
        "Create targeted email campaigns based on guest behavior",
        "Send personalized offers and recommendations",
        "Measure effectiveness and optimize strategies",
      ],
      content4:
        "VenueBoost streamlines your marketing across industries with powerful automation.",
    },
  ];

  const onSubmit = () => {
    if (
      !restaurant_country ||
      !restaurant_zipcode ||
      !restaurant_state ||
      !restaurant_city
    ) {
      NotificationManager.error(
        t("who_we_serve.accommodation_industry.error_msg"),
        t("who_we_serve.accommodation_industry.error"),
        3000
      );

      return false;
    }

    const data = {
      industry: "Accommodation",
      category: venuetype === "Any" ? "hotel" : venuetype,
      is_demo: true,
      restaurant_country: restaurant_country,
      restaurant_zipcode: restaurant_zipcode,
      restaurant_state: restaurant_state,
      restaurant_city: restaurant_city,
      restaurant_name: restaurant_name,
      email: email,
      mobile: mobile,
      last_name: last_name,
      first_name: first_name,
      contact_reason: "I want to schedule a demo of VenueBoost",
    };
    createContactSale(data)
      .then(data => {
        setRestaurantZipcode(null);
        setRestaurantState(null);
        setRestaurantName(null);
        setRestaurantCountry(null);
        setRestaurantCity(null);
        setVenuetype(null);
        setFirstname(null);
        setLastname(null);
        setMobile(null);
        setEmail(null);
        NotificationManager.success(
          data?.message || t("who_we_serve.accommodation_industry.success_msg"),
          t("who_we_serve.accommodation_industry.success"),
          5000
        );

        setStep(1);
      })
      .catch(error => {
        NotificationManager.error(
          error.message || t("who_we_serve.accommodation_industry.error_msg"),
          t("who_we_serve.accommodation_industry.error"),
          3000
        );
      });
  };

  return (
    <div className={"align-col-middle accom-industry mt-0"}>
      <Seo
        title={seoTitle + " | VenueBoost"}
        description={seoDescription}
        image={OverviewAccommodation}
        url={currentUrl}
      />
      <div className='flex flex-col md:flex-row justify-center overflow-hidden bg-[#F3F3F3] px-4 sm:px-6 md:px-8 lg:px-16 pb-20 w-[100vw] relative md:min-h-[668px]'>
        {/*<div*/}
        {/*    className="hidden md:block w-[900px] h-[900px] absolute -left-[400px] -top-[100px]"*/}
        {/*    style={{*/}
        {/*        background:*/}
        {/*            "radial-gradient(circle at center, rgba(157, 255, 208, 0.7), transparent, transparent)",*/}
        {/*    }}*/}
        {/*></div>*/}

        <div className='xl:!w-[1228px] flex flex-col md:flex-row'>
          <div className='w-full md:w-[50%] flex flex-col md:pr-[58px] py-6 md:py-[44px] z-10 '>
            <span className=' text-[32px]  md:text-[60px] leading-[45px] md:leading-[60px] font-extrabold '>
              Service Apartments
            </span>

            <p className='hidden md:block text-[#000000] text-14 md:text-20 font-medium leading-5 md:leading-7 mt-2 md:mt-4 mb-[22px] md:mb-[44px]'>
              Delivering convenience and comfort, VenueBoost is ideal for
              service apartments. Simplifying bookings, automating guest
              services, and engaging residents with personalized promotions, our
              platform provides insights to optimize service apartment
              operations, ensuring a seamless and enjoyable stay.
            </p>
            <div className='hidden md:block mb-[15px] mt-10 md:mb-0'>
              <a
                href='/get-started'
                className='rounded-md text-white font-bold text-[11px] md:text-18 leading-[11px] md:leading-[18px] px-[17px] md:px-[30px] py-[9px] md:py-[15px] uppercase bg-gradient-to-r from-[#240B3B] via-[#5E1B83] to-[#BC14FE]'
              >
                {t("who_we_serve.food_industry.unlock_free_plan")}
              </a>
            </div>
          </div>
          <div className=' md:block md:w-[50%] md:mt-12  relative align-middle'>
            <img
              className='object-cover md:absolute rounded-[20px] right-[29px] max-h-full'
              src={OverviewAccommodation}
            />
          </div>
          <div className='flex flex-col md:hidden mt-6'>
            <p className='text-[#000000] text-14 md:text-20 font-medium leading-5 md:leading-7 mt-2 md:mt-4 mb-[22px] md:mb-[44px]'>
              Delivering convenience and comfort, VenueBoost is ideal for
              service apartments. Simplifying bookings, automating guest
              services, and engaging residents with personalized promotions, our
              platform provides insights to optimize service apartment
              operations, ensuring a seamless and enjoyable stay.
            </p>
            <div className=' mt-6 md:mt-0'>
              <a
                href='/get-started'
                className='flex w-full justify-center rounded-md text-white font-bold text-18 leading-[18px] md:px-[30px] py-[15px] uppercase bg-gradient-to-r from-[#240B3B] via-[#5E1B83] to-[#BC14FE]'
              >
                {t("who_we_serve.food_industry.unlock_free_plan")}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col-reverse md:flex-row mt-6 md:mt-[38px]'>
        <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0'>
          <img src={OverviewImg1} />
        </div>

        <div className='w-full md:w-[50%] md:p-8 md:pr-0  flex-col'>
          <h1 className='text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'>
            Bookings Management
          </h1>
          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            Optimized Accommodation Bookings
          </p>

          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            VenueBoost equips hotels, resorts, rentals, and more with robust
            tools to streamline reservations for delighted guests.
          </p>
          <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
            {[
              "Easily create bookings specifying dates, rooms, guests",
              "Reservation dashboard to track, modify, organize",
              "RSVP features for smooth communication",
              "Gain insights into occupancy and traffic patterns",
            ].map(item => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className='text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4'>
            Say goodbye to incohesive systems. VenueBoost provides complete,
            tailored reservations management.
          </p>
          <FreeTrial />
        </div>
      </div>
      <div className='flex flex-col-reverse md:flex-row-reverse mt-10 md:mt-[38px] '>
        <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0'>
          <img src={OverviewImg2} />
        </div>
        <div className='w-full md:w-[40%] md:p-4 md:pl-0  '>
          <h1 className='text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'>
            {t("who_we_serve.accommodation_industry.inventory_management")}{" "}
          </h1>
          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            Insights to Optimize Rooms and Amenities
          </p>
          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            {t("who_we_serve.accommodation_industry.inventory_management_sub")}
          </p>
          <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
            {[
              t(
                "who_we_serve.accommodation_industry.inventory_management_tracking"
              ),
              t(
                "who_we_serve.accommodation_industry.inventory_management_update"
              ),
              t(
                "who_we_serve.accommodation_industry.inventory_management_monitoring"
              ),
              t(
                "who_we_serve.accommodation_industry.inventory_management_profile"
              ),
              t(
                "who_we_serve.accommodation_industry.inventory_management_pattern"
              ),
            ].map(item => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className='text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4'>
            No more guessing. Our intuitive inventory management delivers the
            visibility accommodations need.
          </p>
          <FreeTrial />
        </div>
      </div>

      <div className='flex flex-col-reverse md:flex-row mt-6 md:mt-[38px]'>
        <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0'>
          <img src={OverviewImg3} />
        </div>
        <div className='w-full md:w-[50%] md:p-12 md:pr-0  flex-col'>
          <h1
            className='text-[#240b3b] text-22 align-left md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'
            style={{alignSelf: "left"}}
          >
            {t("who_we_serve.accommodation_industry.staff_managment")}
          </h1>
          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            Simplified Scheduling and Payroll
          </p>
          <p className='text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
            {t("who_we_serve.accommodation_industry.staff_managment_sub")}
          </p>
          <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
            {[
              "Automated scheduling based on occupancy",
              "Track time-off requests and vacation days",
              "Streamlined payroll processing with easy wage calculations",
              "Performance monitoring to identify coaching opportunities",
              "Ensure exceptional service through staff coordination",
            ].map(item => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className='text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4'>
            Forget staff scheduling stress. VenueBoost simplifies team
            management for optimal operations.
          </p>
          <FreeTrial />
        </div>
      </div>
      <div className='flex flex-col-reverse md:flex-row-reverse mt-10 md:mt-[38px] '>
        <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0'>
          <img src={OverviewImg4} />
        </div>
        <div className='w-full md:w-[40%] md:p-4 md:pl-0   '>
          <h1 className='text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'>
            {t("who_we_serve.accommodation_industry.marketing")}{" "}
          </h1>
          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            Expanded Reach and Brand Awareness
          </p>
          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            {t("who_we_serve.accommodation_industry.marketing_sub")}
          </p>
          <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
            <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
              {[
                t("who_we_serve.accommodation_industry.marketing_email"),
                t("who_we_serve.accommodation_industry.marketing_promotion"),
                t("who_we_serve.accommodation_industry.marketing_campaigns"),
                t("who_we_serve.accommodation_industry.marketing_refferals"),
              ].map(item => (
                <CheckLine text={item} />
              ))}
            </div>
          </div>
          <p className='text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4'>
            VenueBoost brings together the marketing tools your accommodation
            needs.
          </p>
          <FreeTrial />
        </div>
      </div>

      <div className='flex flex-col-reverse md:flex-row mt-6 md:mt-[28px]'>
        <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0'>
          <img src={OverviewImg5} />
        </div>
        <div className='w-full md:w-[50%] md:p-12 md:pr-0  flex-col'>
          <h1
            className='text-[#240b3b] text-22 align-left md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'
            style={{alignSelf: "left"}}
          >
            {t("who_we_serve.accommodation_industry.loyalty")}
          </h1>
          <p className='text-[#666666] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
            Building Guest Loyalty and Retention
          </p>
          <p className='text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
            {t("who_we_serve.accommodation_industry.loyalty_sub")}
          </p>
          <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
            {[
              "Build special tiers and benefits for loyal members",
              "View member profiles and track purchase history",
              "Send tailored rewards and offers",
              "Analyze program activity and engagement",
            ].map(item => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className='text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4'>
            Strengthen relationships with return guests. VenueBoost provides the
            tools to boost loyalty, retention, and repeat visits.
          </p>
          <FreeTrial />
        </div>
      </div>
      <div className='flex flex-col-reverse md:flex-row-reverse mt-10 md:mt-[38px] '>
        <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0'>
          <img src={OverviewImg6} />
        </div>
        <div className='w-full md:w-[40%] md:p-12 md:pl-0   '>
          <h1 className='text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'>
            {t("who_we_serve.accommodation_industry.reporting")}{" "}
          </h1>
          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            Actionable Accommodation Insights
          </p>
          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            {t("who_we_serve.accommodation_industry.reporting_sub")}
          </p>
          <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
            {[
              "Monitor KPIs like occupancy rates and RevPAR",
              "Guest segmentation provides spending insights",
              "Optimize labor costs with staffing insights",
              "Source of booking analysis informs marketing",
              "Financial reporting provides clear profit visibility",
            ].map(item => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className='text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4'>
            Stop relying on assumptions. VenueBoost reporting provides the
            clarity to run a smarter accommodation business.
          </p>
          <FreeTrial />
        </div>
      </div>

      <div className='flex flex-col-reverse md:flex-row-reverse mt-10 md:mt-[38px] '>
        <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0'>
          <img src={OverviewImg7} />
        </div>
        <div className='w-full md:w-[50%] md:p-4 md:pr-0  flex-col'>
          <h1
            className='text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'
            style={{alignSelf: "left"}}
          >
            {t("who_we_serve.accommodation_industry.payment_links")}
          </h1>
          <p className='text-[#666666] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
            Streamlined Online Booking Payments
          </p>
          <p className='text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
            {t("who_we_serve.accommodation_industry.payment_links_sub")}
          </p>
          <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
            {[
              "Securely accept deposits, prepayments, and balances",
              "Organized link list to track transactions",
              "Insights into bookings and revenue from links",
              "Seamless, convenient payment process for guests",
            ].map(item => (
              <CheckLine text={item} />
            ))}
          </div>
          <FreeTrial />
        </div>
      </div>
      <div className='flex flex-col-reverse md:flex-row mt-6 md:mt-[78px]'>
        <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0'>
          <img src={AccommodationGuestsManagement} />
        </div>
        <div className='w-full md:w-[50%] md:p-12 md:pr-0  flex-col'>
          <h1
            className='text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'
            style={{alignSelf: "left"}}
          >
            {t("who_we_serve.accommodation_industry.guest_management")}
          </h1>
          <p className='text-[#666666] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
            Personalized Stays, Optimized Experiences
          </p>
          <p className='text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
            {t("who_we_serve.accommodation_industry.guest_management_sub")}
          </p>
          <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
            {[
              t("who_we_serve.accommodation_industry.guest_management_suite"),
              t(
                "who_we_serve.accommodation_industry.guest_management_preference"
              ),
              t("who_we_serve.accommodation_industry.guest_management_loyalty"),
              "Custom loyalty programs with special tiers",
            ].map(item => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className='text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4'>
            Deliver exceptional stays by truly understanding each guest.
            VenueBoost provides the tools to attract, engage, and retain guests.
          </p>
          <FreeTrial />
        </div>
      </div>

      <div className='flex flex-col-reverse md:flex-row-reverse mt-10 md:mt-[38px] '>
        <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0'>
          <img src={OverviewImg8} />
        </div>
        <div className='w-full md:w-[40%] md:p-12 md:pl-0   '>
          <h1 className='text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'>
            Affiliate Partnerships{" "}
          </h1>
          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            Expand Your Reach and Revenue
          </p>

          <p className='text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4'>
            With VenueBoost's new Affiliates feature, you can grow your business
            and revenue by partnering with like-minded organizations.
          </p>
          <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
            {[
              "Establish affiliate partnerships seamlessly",
              "Track referrals and commissions in real-time",
              "Access valuable marketing resources",
              "Extend your brand's reach through strategic collaborations",
            ].map(item => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className='text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4'>
            Join forces with affiliates and unlock new opportunities with
            VenueBoost's Affiliates.
          </p>
          <FreeTrial />
        </div>
      </div>
      <div className='flex flex-col-reverse md:flex-row mt-0 md:mt-[38px]'>
        <div className='w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0'>
          <img src={OverviewFoodGuestsManagement} />
        </div>
        <div className='w-full md:w-[50%] md:p-0 md:pr-0  flex-col'>
          <h1
            className='text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4'
            style={{alignSelf: "left"}}
          >
            Guest Surveys and Ratings
          </h1>

          <p className='text-[#666666] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4'>
            Gather Valuable Feedback
          </p>
          <div className='grid grid-cols-1 gap-[16px] md:gap-[25px]'>
            {[
              "VenueBoost now offers powerful Surveys and Ratings to help venues enhance guest experiences and gather crucial feedback.",
              "Create customized guest surveys",
              "Collect ratings and reviews to gauge satisfaction",
              "Gain insights into preferences and opinions",
              "Make informed improvements using data-driven decisions",
            ].map(item => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className='text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4'>
            Listen to your customers and optimize offerings with VenueBoost`s
            Surveys and Ratings.
          </p>
          <FreeTrial />
        </div>
      </div>

      {/*<div className={"md:px-8 px-4 mt-10 md:mt-10"}>*/}
      {/*  <div*/}
      {/*    className="tabs-container"*/}
      {/*    role="tablist"*/}
      {/*    aria-label="Products"*/}
      {/*    data-tracking-container="Product Tabs"*/}
      {/*  >*/}
      {/*    {tabs.map((tab, index) => (*/}
      {/*      <button*/}
      {/*        key={index}*/}
      {/*        className={`tab js-tab ${activeTab === index ? "is-active" : ""}`}*/}
      {/*        role="tab"*/}
      {/*        aria-selected={activeTab === index}*/}
      {/*        aria-controls={`tab-content-${index}`}*/}
      {/*        tabIndex="0"*/}
      {/*        onClick={() => setActiveTab(index)}*/}
      {/*      >*/}
      {/*        {tab.title}*/}
      {/*      </button>*/}
      {/*    ))}*/}
      {/*  </div>*/}
      {/*  <div className="tabs-content" data-tracking-container="Tab Content">*/}
      {/*    {tabs.map((tab, index) => (*/}
      {/*      <div*/}
      {/*        key={index}*/}
      {/*        className={`tab tab-content__panel js-tab-panel ${*/}
      {/*          activeTab === index ? "is-active" : ""*/}
      {/*        }`}*/}
      {/*        role="tabpanel"*/}
      {/*        id={`tab-content-${index}`}*/}
      {/*        aria-labelledby={`tab-${index}`}*/}
      {/*        aria-hidden={activeTab !== index}*/}
      {/*        tabIndex="0"*/}
      {/*      >*/}
      {/*        {tab.content}*/}
      {/*      </div>*/}
      {/*    ))}*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<div className="w-full">*/}
      {/*  <VideoPlayer venueText={"Accommodation"} />*/}
      {/*</div>*/}

      {/*<div className="flex   bg-[#F9F7F7] flex-col mt-10 md:mt-10">*/}
      {/*  <p className="text-20 md:text-[39px] leading-[26px] md:leading-[48px] font-bold text-center my-10  md:mb-20 md:mt-20 w-[80%] md:w-full mx-auto">*/}
      {/*    {t("who_we_serve.accommodation_industry.right_price")}*/}
      {/*  </p>*/}
      {/*  <Pricing hasMoreBtn={true} />*/}
      {/*</div>*/}

      <Grow
        title={"Additional Accommodation Features"}
        subtitle={
          "Dive into VenueBoost's Expanded Capabilities for the Accommodation Industry"
        }
        tabs={tabs}
        venueColor={"#6DDFE2"}
      />

      <div className='align-col-middle '>
        <div className='w-[100vw] bg-[#240B3B]'>
          <div className='max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 '>
            <div className='flex flex-col align-middle w-full'>
              <h1 className='text-white  text-26 md:text-[42px] text-center font-extrabold leading-[60px] mt-10 md:mt-20 '>
                {t("who_we_serve.accommodation_industry.faq")}
                <span className='text-white'>
                  {" "}
                  ({t("who_we_serve.accommodation_industry.faq_short")}){" "}
                </span>
              </h1>
              <p className='text-14 md:text-20 text-white px-7 leading-5 font-medium mt-4 mb:mt-20 mb-5 md:mb-[66px]'>
                {t(
                  "who_we_serve.accommodation_industry.accommodation_industry"
                )}
              </p>
              <Faq faqData={generalFaq} className='mt-2 mb-4 md:mb-16' />
            </div>
          </div>
        </div>
      </div>
      <Advantage venueDefinition={"accommodation industry"} />
      <div className='align-col-middle'>
        <div className='w-[100vw] bg-white'>
          <div className='mx-auto max-w-screen-main px-4 sm:px-6 md:px-8 lg:px-16'>
            <div className='flex flex-col align-middle mb-4 md:mb-20'>
              <FreePlanCard />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceApartments;
