import cs from 'classnames'
export default function CircleTick(props){
    return (
        <div className={cs(props.className,'rounded-full flex-shrink-0 circle-tick')}>
            <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Group 427319155">
            <circle id="Ellipse 963" cx="12.5" cy="12.5" r="12.5" fill="none"/>
            <path id="Vector" d="M18.2375 7L10 15.2375L6.7625 12.0125L5 13.775L10 18.775L20 8.775L18.2375 7Z" fill="white"/>
            </g>
            </svg>
        </div>
    )
}