import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { loadChatHistory } from "../../redux/actions/chatbot";
import "./index.css";
import { Seo } from "../../components/Seo";
import { t } from "i18next";
import { Modal } from "antd";
import SwitchIcon3 from "../../assets/svgs/logo-png-header.webp";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import Header from "./header";
import Learning from "./learning";
import Growth from "./growth";
import Potential from "./potential";
import Grow from "./grow";
import Switching from "./switching";
import HomeIntegration from "./integration";
import Business from "./business";
import BusinessWorldwide from "./business-worldwide";
import MainHomeHead from './MainHomeHead';


const Home = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigateToUrl = (url) => {
    navigate(url);
  };

  const [visibleGetStartedModal, setVisibleGetStartedModal] = useState(false);
  const FeatureBlock = ({ title, description }) => (
    <motion.div
      className="flex-1"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ amount: 0.8, once: true }}
    >
      <motion.div
        className="h-full flex"
        variants={{
          offscreen: { opacity: 0, y: 100 },
          onscreen: {
            y: 0,
            opacity: 1,
            transition: {
              type: "spring",
              bounce: 0,
              duration: 1,
            },
          },
        }}
      >
        <div className="flex flex-col rounded-[13px] bg-white">
          {/*<img src={iconSrc} style={{width: "60%"}}/>*/}
          <p className="text-[#333333] text-18 md:text-22 font-bold mb-2">
            {title}
          </p>
          <p
            className="text-[#666666] font-normal text-16"
            style={{ lineHeight: "30px" }}
          >
            {description}
          </p>
          <ul
            className="text-[#666666] font-normal text-16"
            style={{ lineHeight: "30px", marginTop: "15px" }}
          >
            <li>
              <b>&#x27A4; </b>
              {t("home.dive_into")}
              {t("home.free_premium")}
            </li>
            <li>
              <b>&#x27A4; </b> {t("home.test_waiters")} {t("home.free_trial")}
            </li>
            <li>
              <b>&#x27A4; </b> {t("home.switch_and_watch_bussines")}
            </li>
          </ul>
          <p
            className="text-[#666666] font-normal text-16"
            style={{ lineHeight: "30px", marginTop: "15px" }}
          >
            {t("home.timeForManagement")}
          </p>
        </div>
      </motion.div>
    </motion.div>
  );
  useEffect(() => {
    const showModalTimeout = setTimeout(() => {
      // Check if 'showModal' is not in localStorage
      if (!localStorage.getItem("showGetStartedModal")) {
        setVisibleGetStartedModal(true);
        // Set 'showModal' in localStorage
        localStorage.setItem("showGetStartedModal", "true");
      }
    }, 38000); // 38000 milliseconds = 38 seconds
    dispatch(loadChatHistory());

    return () => clearTimeout(showModalTimeout); // Clear timeout if the component unmounts
  }, []);

  return (
      <>
        <MainHomeHead />
    <div className={"view-home"}>
      <Seo title={t("components.seo.title")} />
      <div className="align-col-middle">
        <div className="relative w-[100vw] bg-[#F3F3F3] overflow-hidden">
          <Header />
          <Learning />
        </div>
      </div>
      {/* <Customize /> */}
      <Potential />
      <Grow />
      <Growth />
      <Switching />
      <HomeIntegration />
      <BusinessWorldwide />
      <Business />

      <Modal
        centered
        open={visibleGetStartedModal}
        onCancel={() => setVisibleGetStartedModal(false)}
        onOk={() => navigateToUrl("/get-started")}
        maskClosable={false}
        okText={"Start My Free Journey"}
        cancelText={"No thanks, I prefer challenges"}
        className="freeJourneyModal"
        okButtonProps={{
          style: {
            background:
              "linear-gradient(128deg, rgb(36, 11, 59) 3.95%, rgb(61, 30, 89) 34%, rgb(51, 5, 94) 67.3%, rgb(188, 20, 254) 94.28%)",
            color: "white",
            height: "40px",
            width: isMobile ? "100%" : "50%",
            marginLeft: isMobile ? "0px" : "8px",
            marginTop: isMobile ? "15px" : "0",
          },
        }}
        cancelButtonProps={{
          style: {
            backgroundColor: "transparent",
            color: "#240b3b",
            borderColor: "#CBD5E1",
            height: "40px",
            width: isMobile ? "100%" : "50%",
          },
        }}
        bodyStyle={{ height: isMobile ? "351px" : "302px" }}
        width={500}
      >
        <div
          className={
            "flex flex-col py-4 w-full h-max md:h-[302px] overflow-auto gap-5"
          }
        >
          <FeatureBlock
            iconSrc={SwitchIcon3}
            title={"Ready to Revolutionize Your Venue?"}
            description={
              "" +
              "Wave goodbye to the old-school hassles. 🌊 VenueBoost is here to skyrocket your venue’s success! "
            }
          />
        </div>
      </Modal>
    </div>
      </>
  );
};

export default Home;
