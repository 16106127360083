import React, {useEffect, useState} from "react";

import "react-multi-carousel/lib/styles.css";
import SuccessStoryImg1 from "../../../assets/images/stories/nohu_rooftop_restaurant_story.webp";
import SuccessStoryImg2 from "../../../assets/images/stories/skinners_loft_restaurant_story.webp";
import SuccessStoryImg3 from "../../../assets/images/stories/maplewood_golf_course_story.webp";
import SuccessStoryImg4 from "../../../assets/images/stories/mgm_hotel_story.webp";
import StoryCard from "../../../components/StoryCard";
import "./index.css";
import {Seo} from "../../../components/Seo";
import FreePlanCard from "../../../components/FreePlanCard";

const responsive = {
  superLargeDesktop: {
    breakpoint: {max: 4000, min: 3000},
    items: 6,
  },
  largeDesktop: {
    breakpoint: {max: 3000, min: 2050},
    items: 6,
  },
  desktop: {
    breakpoint: {max: 2050, min: 1240},
    items: 6,
  },
  tablet: {
    breakpoint: {max: 1240, min: 768},
    items: 5,
  },
  mobile1: {
    breakpoint: {max: 768, min: 425},
    items: 1,
  },
  mobile2: {
    breakpoint: {max: 425, min: 0},
    items: 1,
  },
};

const successStories = [
  {
    img: SuccessStoryImg1,
    tag: "Restaurant Success Story",
    title:
      "Transforming Restaurant Operations: NOHU Rooftop Restaurant Success Story",
    desc: "Discover How VenueBoost Revolutionized NOHU Rooftop Restaurant Operations, elevating their business to new heights",
    date: "June 6, 2023",
    slug: "transforming-restaurant-operations-nohu-rooftop-restaurant-success-story",
  },
  {
    img: SuccessStoryImg2,
    tag: "Restaurant Success Story",
    title:
      "Unlocking the Potential of Hospitality: Skinner's Loft Success Story",
    desc: "How Skinner's Loft collaboration with VenueBoost has revolutionized their operations, making them a trailblazer in the hospitality industry",
    date: "June 2, 2023",
    slug: "unlocking-hospitality-potential-skinners-loft-success-story",
  },
  {
    img: SuccessStoryImg3,
    tag: "Golf Course Story",
    title: "Elevating Golf Experiences: Maplewood Country Club Success Story",
    desc: "Discover how VenueBoost revolutionized their golf operations, enabling them to deliver exceptional experiences and solidify their position as one of the premier golf courses in the U.S.",
    date: "June 11, 2023",
    slug: "elevating-golf-experiences-maplewood-country-club-success-story",
  },
  {
    img: SuccessStoryImg4,
    tag: "Hotels Story",
    title:
      "Simplifying Hotel Operations: MGM Resorts International Success Story",
    desc: "Discover how VenueBoost transformed their hospitality landscape, empowering them to streamline operations, deliver customized experiences, and create unforgettable dining events across their diverse range of service areas",
    date: "June 23, 2023",
    slug: "simplifying-hotel-operations-mgm-resorts-international-success-story",
  },
];

const learnList = [
  {
    title: "Rapid Enhancements\n",
    content:
      "Revitalize Your Venue Overnight: Ignite your venue's transformation with VenueBoost's comprehensive toolkit. Witness swift improvements in booking processes and payment systems, enhancing both efficiency and customer delight.",
  },
  {
    title: "Simplified Operations\n",
    content:
      "Navigate Management with Ease: Consolidate your venue's administrative tasks into one dynamic platform. VenueBoost merges bookings, workforce planning, and stock control, making management smoother and freeing up your time for what matters.",
  },
  {
    title: "Strategic Expansion\n",
    content:
      "Expand With Insight: Use VenueBoost to harness actionable analytics and versatile marketing strategies, growing your business thoughtfully in response to the evolving market and consumer preferences.",
  },
];

const SuccessStories = () => {
  const currentUrl = "https://venueboost.io/resources/success-stories";
  const seoTitle = "Explore VenueBoost Success Stories | Real Results";
  const seoDescription =
    "Discover how VenueBoost has helped businesses achieve real results and success. Explore our success stories to see how our platform can elevate your venue management experience.";

  return (
    <div className={"align-col-middle w-full xl:min-w-[1230px] px-3"}>
      <Seo
        title={seoTitle + " | VenueBoost"}
        description={seoDescription}
        url={currentUrl}
      />
      <div className='w-full mx-auto'>
        <div className='flex flex-col align-middle'>
          <div class='mx-auto flex items-center justify-center mt-16 mb-8'>
            <div class='h-[34px] w-[180px] rounded-md bg-gradient-to-r from-[#240B3B] via-[#3D1E59] to-[#BC14FE] p-[1px]'>
              <div class='flex h-full w-full items-center justify-center bg-white rounded-md'>
                <h1 class='text-xl font-normal text-[#454545]'>Case studies</h1>
              </div>
            </div>
          </div>

          <div className='text-[#252525] text-[36px] md:text-[48px] font-normal text-center leading-[48px]'>
            Success
          </div>
          <h1 className='text-[36px] md:text-[48px] font-extrabold text-center text-transparent bg-clip-text leading-[48px] bg-gradient-to-br from-[#240B3B] via-[#3D1E59] via-[#8319B4] to-[#BC14FE]'>
            Unveiled
          </h1>

          <p className='text-[#252525] text-16 md:text-18 leading-5 md:leading-7 font-medium text-center w-[80%] xl:w-[742px] mt-6 '>
            Dive into the journeys of VenueBoost users who turned challenges
            into victories. These stories showcase the power of VenueBoost in
            overcoming complex issues and unlocking new opportunities. Discover
            how venues like yours are achieving remarkable results.
          </p>
        </div>
        <div className='w-full flex align-middle flex-col'>
          <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-10 mt-9 md:mt-[59px]'>
            {successStories.map((item, index) => (
              <StoryCard
                key={index}
                tag={item.tag}
                title={item.title}
                desc={item.desc}
                img={item.img}
                date={item.date}
                slug={item.slug}
              />
            ))}
          </div>
        </div>
      </div>

      <div class='mx-auto flex items-center justify-center my-14'>
        <div class='h-[30px] w-[180px] md:h-[40px] md:w-[240px] rounded-md bg-gradient-to-r from-[#240B3B] via-[#3D1E59] to-[#BC14FE] p-[1px]'>
          <div class='flex h-full w-full items-center justify-center bg-transparent rounded-md'>
            <a class=' text-12 font-medium text-white' href='#'>
              Discover More Successes
            </a>
          </div>
        </div>
      </div>

      <div className='flex justify-between flex-col md:flex-row w-full gap-6 md:gap-0'>
        {learnList.map((item, index) => (
          <div
            key={index}
            className='bg-gradient-to-br from-[#240B3B] via-[#8319B4] to-[#BC14FE] p-[1px] rounded-[20px] max-w-[100%] md:max-w-[340px]'
          >
            <div className='flex flex-col rounded-[20px] px-8 py-8 bg-white gap-2 w-full h-full'>
              <span className='text-pink1 text-2xl md:text-3xl font-bold leading-9'>
                {item.title}
              </span>
              <span className='text-[#333333] text-sm md:text-base font-medium'>
                {item.content}
              </span>
            </div>
          </div>
        ))}
      </div>

      <div className='mx-auto flex items-center justify-center my-6'>
        <FreePlanCard />
      </div>
    </div>
  );
};

export default SuccessStories;
