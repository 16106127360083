import React, { useState, useEffect } from 'react';

import "./index.css";
import FormText from "../../components/FormText";
import {Seo} from "../../components/Seo";

const Cookie = () => {

  const [consentStatus, setConsentStatus] = useState(''); // 'consented', 'denied', or ''
  useEffect(() => {
    const updateConsentStatus = () => {
      if (window.Cookiebot) {
        if (window.Cookiebot.consented) {
          setConsentStatus('consented');
        } else {
          setConsentStatus('denied');
        }
      }
    };

    // Initial check
    updateConsentStatus();

    // Setup event listener for consent updates
    window.addEventListener('CookieConsentChanged', updateConsentStatus);

    // Cleanup event listener
    return () => {
      window.removeEventListener('CookieConsentChanged', updateConsentStatus);
    };
  }, []);

  const handleWithdraw = () => {
    window.Cookiebot.withdraw();
    setConsentStatus('denied'); // Manually update status on withdraw
  };

  return (
    <div className="flex flex-col align-center view-terms my-10">
      <Seo title={"Cookie Notice | VenueBoost"} />
      <div className="flex flex-col">
        <FormText
          title="Cookie Notice"
          type="title"
        />
        <FormText
          title="This Cookie Notice describes how VenueBoost uses cookies and similar technologies to provide, improve, promote and protect the Services."
          customClass="my-2"
        />
        <FormText
          title="Last updated 15 December 2023"
          type="itemtitle-bold"
        />
      </div>
      <div className="divide-y h-[1px] bg-slate-200 divide-dashed my-2"/>
      <div className="flex flex-col py-3">
        <FormText
          title="What are cookies?"
          type="pretitle"
        />



        <FormText
          title="Cookies and similar tracking technologies, such as beacons, scripts, and tags, collectively known as 'cookies', are tiny data files stored on your device's hard drive or within your browser. They help VenueBoost’s website and admin panel ('VB Sites') remember your visits and preferences, enhancing your experience.
        The law states that we can store cookies on your device if they are strictly necessary for the operation of this platform. For all other types of cookies we need your permission."
          customClass="my-2"
        />

        <FormText
            title="VenueBoost platform uses different types of cookies. Some cookies are placed by third party services that appear on our pages."
            customClass="my-2"
        />
        <span>
           <FormText
               title="You can at any time change or withdraw your consent from the"
               customClass="my-2"
           />

          <a href="https://venueboost.io/cookie-notice">
            <FormText
                title="  Cookie Notice  "
                customClass="my-2"
                customStyle={{
                  color: "#607D8B",
                  textDecoration: "underline",
                  fontWeight: "bold",
                }}
            />
          </a>


          <FormText
              title="on our website."
              customClass="my-2"
          />
        </span>
        <span>
          <FormText
              title="Learn more about who we are, how you can contact us and how we process personal data in our "
              customClass="my-2"
          />
          <a href="https://venueboost.io/privacy-policy">
            <FormText
                title=" Privacy Policy."
                customClass="my-2"
                customStyle={{
                  color: "#607D8B",
                  textDecoration: "underline",
                  fontWeight: "bold",
                }}
            />
          </a>

        </span>
        <FormText
            title="Please state your consent ID and date when you contact us regarding your consent."
            customClass="my-2"
        />

        <FormText
            title="Your consent applies to the following domains: admin.venueboost.io, venueboost.io"
            customClass="my-2"
        />

        <div className={"color-dark text-lg font-normal"} style={{fontWeight: "bold", color: "#607D8B"}}>
          <a href="javascript: Cookiebot.renew()">Change your cookie consent</a>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          {consentStatus === 'consented' && (
              <a href="#" onClick={handleWithdraw}>Withdraw your cookie consent</a>
          )}
          {consentStatus === 'denied' && <span>Your current state: Denied</span>}
        </div>

      </div>
      <div className="flex flex-col py-3">
        <FormText
          title="Why do we use cookies?"
          type="pretitle"
        />
        <FormText
            title="You have the right to decide whether to accept or reject cookies. You can exercise your cookie rights by
            setting your preferences in the: "
            customClass="my-2"
        />
        <a href="javascript: Cookiebot.renew()">
          <FormText
              title=" Cookie Consent Manager"
              customClass="my-2"
              customStyle={{
                color: "#607D8B",
                textDecoration: "underline",
                fontWeight: "bold",
              }}
          />
        </a>
        <FormText
          title=" The Cookie Consent Manager allows you to select
            which categories of cookies you accept or reject. Essential cookies cannot be rejected as they are strictly
            necessary to provide you with services."
          customClass="my-2"
        />
        <FormText
          title="The Cookie Consent Manager can be found in the notification banner and in this Cookie nOTICE. If you choose to
            reject cookies, you may still use our website though your access to some functionality and areas of our
            website may be restricted. You may also set or amend your web browser controls to accept or refuse cookies."
          customClass="my-2"
        />
      </div>
      <div className="flex flex-col py-3">
        <div>
          <FormText
              title="Necessary cookies:"
              type="itemtitle"
          />
        </div>
        <FormText
          title="Necessary cookies help make a website usable by enabling basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies."
          customClass="my-2"
        />
        <div className="flex flex-col my-3 p-3 border border-slate-300 rounded-sm">
          <div className="flex gap-3 my-1">
            <FormText
              title="Name:"
              customClass="text-right w-24"
              type="subtitle-gray"
            />
            <FormText
              title="venue-boost-app-token"
              type="subtitle-gray"
              customClass="flex-1"
            />
          </div>
          <div className="flex m gap-3 my-1">
            <FormText
              title="Purpose:"
              customClass="text-right w-24"
              type="subtitle-gray"
            />
            <FormText
              title="This cookie is used to store the user authentication token for the VenueBoost application. The token serves as a unique identifier for users who are authenticated to access the venue's services. It helps maintain user sessions and ensures secure access to the VenueBoost platform. This cookie is essential for user authentication and the proper functioning of the application"
                customClass="flex-1"
              type="subtitle-gray"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
              title="Provider:"
              customClass="text-right w-24"
              type="subtitle-gray"
            />
            <FormText
              title="venueboost.io"
              type="subtitle-gray"
              customClass="flex-1"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Sub-domain:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="admin.venueboost.io"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>

          <div className="flex gap-3 my-1">
            <FormText
              title="Type:"
              customClass="text-right w-24"
              type="subtitle-gray"
            />
            <FormText
              title="HTTP"
              type="subtitle-gray"
              customClass="flex-1"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
              title="Expiry:"
              customClass="text-right w-24"
              type="subtitle-gray"
            />
            <FormText
              title="Session"
              type="subtitle-gray"
              customClass="flex-1"
            />
          </div>
        </div>
        <div className="flex flex-col my-3 p-3 border border-slate-300 rounded-sm">
          <div className="flex gap-3 my-1">
            <FormText
                title="Name:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="vb-venue-short-code"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
          <div className="flex m gap-3 my-1">
            <FormText
                title="Purpose:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="The vb-venue-short-code cookie stores a short code that uniquely identifies a venue within the administrative system. This code is used for quick and efficient identification of the venue within the administrative interface."
                customClass="flex-1"
                type="subtitle-gray"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Provider:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="venueboost.io"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Sub-domain:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="admin.venueboost.io"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>

          <div className="flex gap-3 my-1">
            <FormText
                title="Type:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="HTTP"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Expiry:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="Session"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
        </div>
        <div className="flex flex-col my-3 p-3 border border-slate-300 rounded-sm">
          <div className="flex gap-3 my-1">
            <FormText
                title="Name:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="vb-basic-info"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
          <div className="flex m gap-3 my-1">
            <FormText
                title="Purpose:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="The vb-basic-info cookie is used to store basic information about the user's interaction with the VenueBoost platform. This information includes the user's ID, name, role, reservation start and end times, and plan ID. It serves the purpose of maintaining user session and providing essential data for the user's session on the VenueBoost platform."
                customClass="flex-1"
                type="subtitle-gray"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Provider:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="venueboost.io"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Sub-domain:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="admin.venueboost.io"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>

          <div className="flex gap-3 my-1">
            <FormText
                title="Type:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="HTTP"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Expiry:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="Session"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
        </div>
        <div className="flex flex-col my-3 p-3 border border-slate-300 rounded-sm">
          <div className="flex gap-3 my-1">
            <FormText
                title="Name:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="venue-type"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
          <div className="flex m gap-3 my-1">
            <FormText
                title="Purpose:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="The venue-type cookie is used to store information about the type of venue that the user has access to when logged into the VenueBoost platform. It serves the purpose of customizing the user's experience based on the type of venue they are associated with. For example, it helps in presenting relevant features, options, and content tailored to the specific type of venue, whether it's a restaurant, accommodation, golf venue, bistro, or another category."
                customClass="flex-1"
                type="subtitle-gray"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Provider:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="venueboost.io"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Sub-domain:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="admin.venueboost.io"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>

          <div className="flex gap-3 my-1">
            <FormText
                title="Type:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="HTTP"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Expiry:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="Session"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
        </div>
        <div className="flex flex-col my-3 p-3 border border-slate-300 rounded-sm">
          <div className="flex gap-3 my-1">
            <FormText
                title="Name:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="loyalty-program-id"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
          <div className="flex m gap-3 my-1">
            <FormText
                title="Purpose:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="The loyalty-program-id cookie is designed to store the unique identifier (ID) of the loyalty program that a user has created for their venue within the VenueBoost platform. Its purpose is to associate the user with their specific loyalty program, enabling them to manage and track loyalty-related activities and rewards effectively. This cookie helps ensure that users have access to the correct loyalty program and its associated data when they log in."
                customClass="flex-1"
                type="subtitle-gray"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Provider:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="venueboost.io"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Sub-domain:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="admin.venueboost.io"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>

          <div className="flex gap-3 my-1">
            <FormText
                title="Type:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="HTTP"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Expiry:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="Session"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col py-3">
        <FormText
            title="Preferences cookies:"
            type="itemtitle"
            customClass="underline"
        />
        <FormText
            title="Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in."
            customClass="my-2"
        />
        <div className="flex flex-col my-3 p-3 border border-slate-300 rounded-sm">
          <div className="flex gap-3 my-1">
            <FormText
                title="Name:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="COUNTRY_CODE"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
          <div className="flex m gap-3 my-1">
            <FormText
                title="Purpose:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="This cookie is used to determine the preferred country setting selected by the visitor."
                customClass="flex-1"
                type="subtitle-gray"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Provider:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="venueboost.io"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Type:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="HTML"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Expiry:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="Persistent"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
        </div>
        <div className="flex flex-col my-3 p-3 border border-slate-300 rounded-sm">
          <div className="flex gap-3 my-1">
            <FormText
                title="Name:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="LANGUAGE"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
          <div className="flex m gap-3 my-1">
            <FormText
                title="Purpose:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="Determines the preferred language of the visitor. Allows the website to set the preferred language upon the visitor's re-entry. "
                customClass="flex-1"
                type="subtitle-gray"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Provider:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="venueboost.io"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Type:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="HTML"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Expiry:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="Persistent"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col py-3">
        <FormText
            title="Statistics cookies:"
            type="itemtitle"
            customClass="underline"
        />
        <FormText
            title="Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously."
            customClass="my-2"
        />
        {/*Google*/}
        <div className="flex flex-col my-3 p-3 border border-slate-300 rounded-sm">
          <div className="flex gap-3 my-1">
            <FormText
                title="Name:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="td"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
          <div className="flex m gap-3 my-1">
            <FormText
                title="Purpose:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="Registers statistical data on users' behaviour on the website. Used for internal analytics by the website operator."
                customClass="flex-1"
                type="subtitle-gray"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Provider:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <div>
              <FormText
                  title="Google"
                  type="subtitle-gray"
                  customClass="flex-1"
              />
              <a href="https://policies.google.com/privacy" className="text-blue">
                <FormText
                    title=" (Learn more about this provider)"
                    type="subtitle-link"
                    customClass="flex-1"
                />
              </a>
            </div>
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Type:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="Pixel"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Expiry:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="Session"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
        </div>


        {/*Mouseflow*/}
        <div className="flex flex-col my-3 p-3 border border-slate-300 rounded-sm">
          <div className="flex gap-3 my-1">
            <FormText
                title="Name:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="mf_initialDomQueue"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
          <div className="flex m gap-3 my-1">
            <FormText
                title="Purpose:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="Registers data on visitors' website-behaviour. This is used for internal analysis and website optimization. "
                customClass="flex-1"
                type="subtitle-gray"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Provider:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <div>
              <FormText
                  title="Mouseflow"
                  type="subtitle-gray"
                  customClass="flex-1"
              />
              <a href="https://mouseflow.com/privacy/" className="text-blue">
                <FormText
                    title=" (Learn more about this provider)"
                    type="subtitle-link"
                    customClass="flex-1"
                />
              </a>
            </div>
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Type:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="HTML"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Expiry:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="Session"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
        </div>
        <div className="flex flex-col my-3 p-3 border border-slate-300 rounded-sm">
          <div className="flex gap-3 my-1">
            <FormText
                title="Name:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="mf_transmitQueue"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
          <div className="flex m gap-3 my-1">
            <FormText
                title="Purpose:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="Collects data on the user’s navigation and behavior on the website. This is used to compile statistical reports and heatmaps for the website owner."
                customClass="flex-1"
                type="subtitle-gray"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Provider:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <div>
              <FormText
                  title="Mouseflow"
                  type="subtitle-gray"
                  customClass="flex-1"
              />
              <a href="https://mouseflow.com/privacy/" className="text-blue">
                <FormText
                    title=" (Learn more about this provider)"
                    type="subtitle-link"
                    customClass="flex-1"
                />
              </a>
            </div>
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Type:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="HTML"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Expiry"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="Session"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
        </div>
        <div className="flex flex-col my-3 p-3 border border-slate-300 rounded-sm">
          <div className="flex gap-3 my-1">
            <FormText
                title="Name:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="1"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
          <div className="flex m gap-3 my-1">
            <FormText
                title="Purpose:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="Registers data on visitors' website-behaviour. This is used for internal analysis and website optimization. "
                customClass="flex-1"
                type="subtitle-gray"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Provider:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <div>
              <FormText
                  title="m.stripe.com"
                  type="subtitle-gray"
                  customClass="flex-1"
              />
              <a href="https://stripe.com/privacy" className="text-blue">
                <FormText
                    title=" (Learn more about this provider)"
                    type="subtitle-link"
                    customClass="flex-1"
                />
              </a>
            </div>
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Type:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="HTML"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Expiry:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="Session"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
        </div>
        <div className="flex flex-col my-3 p-3 border border-slate-300 rounded-sm">
          <div className="flex gap-3 my-1">
            <FormText
                title="Name:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="sentryReplaySession"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
          <div className="flex m gap-3 my-1">
            <FormText
                title="Purpose:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="Registers data on visitors' website-behaviour. This is used for internal analysis and website optimization. "
                customClass="flex-1"
                type="subtitle-gray"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Provider:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <div>
              <FormText
                  title="venueboost.io"
                  type="subtitle-gray"
                  customClass="flex-1"
              />
            </div>
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Type:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="HTML"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Expiry"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="Session"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col py-3">
        <FormText
          title="Marketing cookies:"
          type="itemtitle"
          customClass="underline"
        />
        <FormText
          title="Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers."
          customClass="my-2"
        />

        {/*Google*/}
        <div className="flex flex-col my-3 p-3 border border-slate-300 rounded-sm">
          <div className="flex gap-3 my-1">
            <FormText
              title="Name:"
              customClass="text-right w-24"
              type="subtitle-gray"
            />
            <FormText
              title="_ga"
              type="subtitle-gray"
              customClass="flex-1"
            />
          </div>
          <div className="flex m gap-3 my-1">
            <FormText
              title="Purpose:"
              customClass="text-right w-24"
              type="subtitle-gray"
            />
            <FormText
              title="Used to send data to Google Analytics about the visitor's device and behavior. Tracks the visitor across devices and marketing channels."
                customClass="flex-1"
              type="subtitle-gray"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
              title="Provider:"
              customClass="text-right w-24"
              type="subtitle-gray"
            />
            <div>
              <FormText
                title="Google"
                type="subtitle-gray"
                customClass="flex-1"
              />
              <a href="https://policies.google.com/privacy" className="text-blue">
                <FormText
                    title=" (Learn more about this provider)"
                    type="subtitle-link"
                    customClass="flex-1"
                />
              </a>
            </div>
          </div>
          <div className="flex gap-3 my-1">
            <FormText
              title="Type:"
              customClass="text-right w-24"
              type="subtitle-gray"
            />
            <FormText
              title="HTTP"
              type="subtitle-gray"
              customClass="flex-1"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
              title="Expiry:"
              customClass="text-right w-24"
              type="subtitle-gray"
            />
            <FormText
              title="2 years"
              type="subtitle-gray"
              customClass="flex-1"
            />
          </div>
        </div>
        <div className="flex flex-col my-3 p-3 border border-slate-300 rounded-sm">
          <div className="flex gap-3 my-1">
            <FormText
              title="Name:"
              customClass="text-right w-24"
              type="subtitle-gray"
            />
            <FormText
              title="_ga_#"
              type="subtitle-gray"
              customClass="flex-1"
            />
          </div>
          <div className="flex m gap-3 my-1">
            <FormText
              title="Purpose:"
              customClass="text-right w-24"
              type="subtitle-gray"
            />
            <FormText
              title="Used to send data to Google Analytics about the visitor's device and behavior. Tracks the visitor across devices and marketing channels."
                customClass="flex-1"
              type="subtitle-gray"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Provider:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <div>
              <FormText
                  title="Google"
                  type="subtitle-gray"
                  customClass="flex-1"
              />
              <a href="https://policies.google.com/privacy" className="text-blue">
                <FormText
                    title=" (Learn more about this provider)"
                    type="subtitle-link"
                    customClass="flex-1"
                />
              </a>
            </div>
          </div>
          <div className="flex gap-3 my-1">
            <FormText
              title="Type:"
              customClass="text-right w-24"
              type="subtitle-gray"
            />
            <FormText
              title="HTTP"
              type="subtitle-gray"
              customClass="flex-1"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
              title="Expiry"
              customClass="text-right w-24"
              type="subtitle-gray"
            />
            <FormText
              title="2 years"
              type="subtitle-gray"
              customClass="flex-1"
            />
          </div>
        </div>

        {/*Mouseflow*/}
        <div className="flex flex-col my-3 p-3 border border-slate-300 rounded-sm">
          <div className="flex gap-3 my-1">
            <FormText
                title="Name:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="mf_#"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
          <div className="flex m gap-3 my-1">
            <FormText
                title="Purpose:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="Collects data of the user's navigation and interaction on the website in order to personalise the purchasing experience."
                customClass="flex-1"
                type="subtitle-gray"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Provider:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <div>
              <FormText
                  title="Mouseflow"
                  type="subtitle-gray"
                  customClass="flex-1"
              />
              <a href="https://mouseflow.com/privacy/" className="text-blue">
                <FormText
                    title=" (Learn more about this provider)"
                    type="subtitle-link"
                    customClass="flex-1"
                />
              </a>
            </div>
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Type:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="HTTP"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Expiry:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="3 months"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
        </div>
        <div className="flex flex-col my-3 p-3 border border-slate-300 rounded-sm">
          <div className="flex gap-3 my-1">
            <FormText
                title="Name:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="__mpq_#_#"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
          <div className="flex m gap-3 my-1">
            <FormText
                title="Purpose:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="Collects data on user behaviour and interaction in order to optimize the website and make advertisement on the website more relevant. "
                customClass="flex-1"
                type="subtitle-gray"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Provider:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <div>
              <FormText
                  title="venueboost.io"
                  type="subtitle-gray"
                  customClass="flex-1"
              />
            </div>
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Type:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="HTML"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Expiry"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="Session"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
        </div>
        <div className="flex flex-col my-3 p-3 border border-slate-300 rounded-sm">
          <div className="flex gap-3 my-1">
            <FormText
                title="Name:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="mp_#_mixpanel"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
          <div className="flex m gap-3 my-1">
            <FormText
                title="Purpose:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="Pending"
                customClass="flex-1"
                type="subtitle-gray"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Provider:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <div>
              <FormText
                  title="venueboost.io"
                  type="subtitle-gray"
                  customClass="flex-1"
              />
            </div>
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Type:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="HTTP"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Expiry"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="Session"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col py-3">
        <FormText
            title="Unclassified cookies:"
            type="itemtitle"
            customClass="underline"
        />
        <FormText
            title="Unclassified cookies are cookies that we are in the process of classifying, together with the providers of individual cookies."
            customClass="my-2"
        />
        <div className="flex flex-col my-3 p-3 border border-slate-300 rounded-sm">
          <div className="flex gap-3 my-1">
            <FormText
                title="Name:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="__mp_opt_in_out_2653f4432b0f2db3f4cc418b82d3464b"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
          <div className="flex m gap-3 my-1">
            <FormText
                title="Purpose:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="Pending"
                customClass="flex-1"
                type="subtitle-gray"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Provider:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="venueboost.io"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Type:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="HTTP"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
          <div className="flex gap-3 my-1">
            <FormText
                title="Expiry:"
                customClass="text-right w-24"
                type="subtitle-gray"
            />
            <FormText
                title="Session"
                type="subtitle-gray"
                customClass="flex-1"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col py-3">
        <FormText
          title="How often will you update this Cookie Notice?"
          type="pretitle"
        />
        <FormText
          title="We may update this Cookie Notice from time to time in order to reflect, for example, changes to the cookies
            we use or for other operational, legal, or regulatory reasons. Please therefore revisit this Cookie Notice
            regularly to stay informed about our use of cookies and related technologies."
          customClass="my-2"
        />
        <FormText
          title="The date at the top of this Cookie Notice indicates when it was last updated."
        />
      </div>

    </div>
  );
};

export default Cookie;
