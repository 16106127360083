import React, { useEffect } from "react";
import "./index.css";
import { Seo } from "../../../components/Seo";
import Faq from "../../../components/Faq";
import Advantage from "../../../components/Advantage";
import OverviewBannerImg from "../../../assets/images/who-we-serve/food.webp";
import OverviewImg1 from "../../../assets/images/who-we-serve/overviewimg1.webp";
import OverviewImg2 from "../../../assets/images/who-we-serve/overviewimg2.webp";
import OverviewImg3 from "../../../assets/images/who-we-serve/overviewimg3.webp";
import OverviewImg4 from "../../../assets/images/who-we-serve/overviewimg4.webp";
import OverviewImg5 from "../../../assets/images/who-we-serve/overviewimg5.webp";
import OverviewImg6 from "../../../assets/images/who-we-serve/overviewimg6.webp";
import OverviewImg7 from "../../../assets/images/who-we-serve/overviewimg7.webp";
import OverviewImg8 from "../../../assets/images/who-we-serve/overviewimg8.webp";
import OverviewImg9 from "../../../assets/images/who-we-serve/overviewimg9.webp";
import OverviewImg10 from "../../../assets/images/who-we-serve/overviewimg10.webp";

import OverviewFoodGuestsManagement from "../../../assets/images/who-we-serve/food-guests-management.webp";
import { isMobile } from "react-device-detect";
import { CircleTick, CheckLine } from "../../../components/CheckLine";
import FreeTrial from "../../../components/FreeTrial";
import { t } from "i18next";

import Grow from "../grow";
import FreePlanCard from "../../../components/FreePlanCard";

const genGeneralFaqText = (label) =>
  t(`who_we_serve.food_overview.generalFaq.${label}`);

const tabText = (label) => t(`who_we_serve.food_overview.tabs.${label}`);

const generalFaq = [
  {
    id: 1,
    status: false,
    title: genGeneralFaqText("foodBeverageBenefitTypes"),
    content: genGeneralFaqText("foodBeverageBenefitDescription"),
  },
  {
    id: 2,
    status: false,
    title: genGeneralFaqText("onboardingProcess"),
    content: genGeneralFaqText("onboardingProcessDescription"),
  },
  {
    id: 3,
    status: false,
    title: genGeneralFaqText("reservationsStreamline"),
    content: genGeneralFaqText("reservationsStreamlineDescription"),
  },
  {
    id: 4,
    status: false,
    title: genGeneralFaqText("inventoryManagementCapabilities"),
    content: genGeneralFaqText("inventoryManagementCapabilitiesDescription"),
  },
  {
    id: 5,
    status: false,
    title: genGeneralFaqText("staffSchedulingManagement"),
    content: genGeneralFaqText("staffSchedulingManagementDescription"),
  },
  {
    id: 6,
    status: false,
    title: genGeneralFaqText("marketingSupport"),
    content: genGeneralFaqText("marketingSupportDescription"),
  },
  {
    id: 7,
    status: false,
    title: genGeneralFaqText("loyaltyRetentionPrograms"),
    content: genGeneralFaqText("loyaltyRetentionProgramsDescription"),
  },
  {
    id: 8,
    status: false,
    title: genGeneralFaqText("businessPerformanceTracking"),
    content: genGeneralFaqText("businessPerformanceTrackingDescription"),
  },
  {
    id: 9,
    status: false,
    title: genGeneralFaqText("onlinePaymentsManagement"),
    content: genGeneralFaqText("onlinePaymentsManagementDescription"),
  },
  {
    id: 10,
    status: false,
    title: genGeneralFaqText("guestManagementStrategies"),
    content: genGeneralFaqText("guestManagementStrategiesDescription"),
  },
];

const FoodIndustry = () => {
  const currentUrl = "https://venueboost.io/who-we-serve/food-and-beverage";
  const seoTitle = t("who_we_serve.seo.title");
  const seoDescription = t("who_we_serve.seo.description");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const tabs = [
    {
      title: tabText("premiumTablesWithBidding"),
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-0 md:mt-[58px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0">
            <img src={OverviewFoodGuestsManagement} />
          </div>
          <div className="w-full md:w-[50%] md:p-0 md:pr-0  flex-col">
            <h1
              className="text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
              style={{ alignSelf: "left" }}
            >
              {tabText("newDimensionReservations")}
            </h1>

            <p className="text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {tabText("premiumTablesBiddingDescription")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("allowBidOnPremiumTables")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("setDynamicPricing")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("increaseRevenueOptimizingTables")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("enhanceGuestExperience")}
                </span>
              </div>
            </div>
            <p className="text-[#666666] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px] mb-8">
              {tabText("maximizeRevenue")}
            </p>
          </div>
        </div>
      ),
      title_modal: tabText("premiumTablesBidding"),
      badge: tabText("premiumTablesBidding"),
      content1: tabText("newDimensionReservations"),
      content2: tabText("premiumTablesBiddingDescription"),
      content3: [
        tabText("allowBidOnPremiumTables"),
        tabText("setDynamicPricing"),
        tabText("increaseRevenueOptimizingTables"),
        tabText("enhanceGuestExperience"),
      ],
      content4: tabText("maximizeRevenue"),
    },
    {
      title: tabText("diningGuestLoyaltyProgram"),
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-0 md:mt-[58px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0">
            <img src={OverviewFoodGuestsManagement} />
          </div>
          <div className="w-full md:w-[50%] md:p-0 md:pr-0  flex-col">
            <h1
              className="text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
              style={{ alignSelf: "left" }}
            >
              {tabText("forStreamlinedOperations")}
            </h1>

            <p className="text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {tabText("inDiningVenueBoostIntroducesLoyaltyProgram")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("allowGuestsEarnPoints")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("offerRewards")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("enhanceGuestExperiences")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("manageOperationsEfficiently")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("driveLoyaltyThroughOffers")}
                </span>
              </div>
            </div>

            <p className="text-[#666666] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px] mb-8">
              {tabText("elevateDiningBusiness")}
            </p>
          </div>
        </div>
      ),
      title_modal: tabText("diningGuestLoyaltyProgram"),
      badge: tabText("diningGuestLoyaltyProgram"),
      content1: tabText("forStreamlinedOperations"),
      content2: tabText("inDiningVenueBoostIntroducesLoyaltyProgram"),
      content3: [
        tabText("allowGuestsEarnPoints"),
        tabText("offerRewards"),
        tabText("enhanceGuestExperiences"),
        tabText("manageOperationsEfficiently"),
        tabText("driveLoyaltyThroughOffers"),
      ],
      content4: tabText("elevateDiningBusiness"),
    },

    {
      title: tabText("advancedGuestBehaviorAnalytics"),
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-0 md:mt-[58px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0">
            <img alt="" src={OverviewFoodGuestsManagement} />
          </div>
          <div className="w-full md:w-[50%] md:p-0 md:pr-0  flex-col">
            <h1
              className="text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
              style={{ alignSelf: "left" }}
            >
              {tabText("dataDrivenGuestInsights")}
            </h1>

            <p className="text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {tabText("gainDeepInsights")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("analyzePreferences")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("dataDrivenDecisions")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12  md:text-18 font-medium text-[#666666] inline">
                  {tabText("targetMarketing")}
                </span>
              </div>
            </div>
            <p className="text-[#666666] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px] mb-8">
              {tabText("dataDrivenStrategies")}
            </p>
          </div>
        </div>
      ),
      title_modal: tabText("advancedGuestBehaviorAnalytics"),
      badge: tabText("advancedGuestBehaviorAnalytics"),
      content1: tabText("dataDrivenGuestInsights"),
      content2: tabText("gainDeepInsights"),
      content3: [
        tabText("analyzePreferences"),
        tabText("dataDrivenDecisions"),
        tabText("targetMarketing"),
      ],
      content4: tabText("dataDrivenStrategies"),
    },
    {
      title: tabText("Marketing Automation"),
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-0 md:mt-[58px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0">
            <img alt="" src={OverviewFoodGuestsManagement} />
          </div>
          <div className="w-full md:w-[50%] md:p-0 md:pr-0  flex-col">
            <h1
              className="text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
              style={{ alignSelf: "left" }}
            >
              {tabText("boostEngagementAndSales")}
            </h1>

            <p className="text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {tabText("takeYourMarketingToTheNextLevel")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12 md:text-18 font-medium text-[#666666] inline">
                  {tabText("createTargetedEmailCampaigns")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12 md:text-18 font-medium text-[#666666] inline">
                  {tabText("sendPersonalizedOffersAndRecommendations")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12 md:text-18 font-medium text-[#666666] inline">
                  {tabText("measureEffectivenessAndOptimizeStrategies")}
                </span>
              </div>
            </div>
            <p className="text-[#666666] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px] mb-8">
              {tabText("streamlineMarketingAcrossIndustries")}
            </p>
          </div>
        </div>
      ),
      title_modal: tabText("Marketing Automation"),
      badge: tabText("marketingAutomation"),
      content1: tabText("boostEngagementAndSales"),
      content2: tabText("takeYourMarketingToTheNextLevel"),
      content3: [
        tabText("createTargetedEmailCampaigns"),
        tabText("sendPersonalizedOffersAndRecommendations"),
        tabText("measureEffectivenessAndOptimizeStrategies"),
      ],
      content4: tabText("streamlineMarketingAcrossIndustries"),
    },
    {
      title: tabText("paymentsClickAndSecure"),
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-0 md:mt-[58px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0">
            <img alt="" src={OverviewFoodGuestsManagement} />
          </div>
          <div className="w-full md:w-[50%] md:p-0 md:pr-0  flex-col">
            <h1
              className="text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
              style={{ alignSelf: "left" }}
            >
              {tabText("paymentsClickAndSecure")}
            </h1>

            <p className="text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {tabText("inPersonPaymentsFeature")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              <div className="flex flex-row items-center">
                <img
                  alt=""
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12 md:text-18 font-medium text-[#666666] inline">
                  {tabText("venuesCanAcceptPaymentsSecurely")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12 md:text-18 font-medium text-[#666666] inline">
                  {tabText("venueBoostPosEnsures")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12 md:text-18 font-medium text-[#666666] inline">
                  {tabText("accessibleUserFriendlyInterface")}
                </span>
              </div>
            </div>
            <p className="text-[#666666] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px] mb-8">
              {tabText("streamlineMarketingAcrossIndustries")}
            </p>
          </div>
        </div>
      ),
      title_modal: tabText("paymentsClickAndSecure"),
      badge: tabText("paymentsClickAndSecure"),
      content1: tabText("paymentsClickAndSecure"),
      content2: tabText("inPersonPaymentsFeature"),
      content3: [
        tabText("venuesCanAcceptPaymentsSecurely"),
        tabText("venueBoostPosEnsures"),
        tabText("accessibleUserFriendlyInterface"),
      ],
      content4: tabText("streamlineMarketingAcrossIndustries"),
    },
    {
      title: tabText("brandingUniquelyYours"),
      content: (
        <div className="flex flex-col-reverse md:flex-row mt-0 md:mt-[58px]">
          <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0">
            <img src={OverviewFoodGuestsManagement} />
          </div>
          <div className="w-full md:w-[50%] md:p-0 md:pr-0  flex-col">
            <h1
              className="text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
              style={{ alignSelf: "left" }}
            >
              {tabText("brandingUniquelyYours")}
            </h1>

            <p className="text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
              {tabText("tailorThePlatformsLook")}
            </p>
            <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
              <div className="flex flex-row items-center">
                <img
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12 md:text-18 font-medium text-[#666666] inline">
                  {tabText("brandConsistency")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12 md:text-18 font-medium text-[#666666] inline">
                  {tabText("enhancedCustomerExperience")}
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img
                  src={CircleTick}
                  className="w-[16px] h-[16px] md:w-[25px] md:h-[25px] mr-3 md:mr-[19px]"
                />
                <span className="text-12 md:text-18 font-medium text-[#666666] inline">
                  {tabText("marketingSynergy")}
                </span>
              </div>
            </div>
            <p className="text-[#666666] align-left text-12 md:text-18 mt-8 font-medium leading-[18px] md:leading-[30px] mb-8">
              {tabText("professionalism")}
            </p>
          </div>
        </div>
      ),
      title_modal: tabText("brandingUniquelyYours"),
      badge: tabText("brandingUniquelyYours"),
      content1: tabText("brandingUniquelyYours"),
      content2: tabText("tailorThePlatformsLook"),
      content3: [
        tabText("brandConsistency"),
        tabText("enhancedCustomerExperience"),
        tabText("marketingSynergy"),
      ],
      content4: tabText("professionalism"),
    },
  ];

  return (
    <div className={"align-col-middle food-industry mt-0"}>
      <Seo
        title={seoTitle + " | VenueBoost"}
        description={seoDescription}
        image={OverviewBannerImg}
        url={currentUrl}
      />
      <div className="flex flex-col md:flex-row justify-center overflow-hidden bg-[#F3F3F3] px-4 sm:px-6 md:px-8 lg:px-16 pb-20 w-[100vw] relative">
        <div className="xl:!w-[1228px] flex flex-col md:flex-row">
          <div className="w-full md:w-[50%] flex flex-col md:pr-[58px] py-6 md:py-[44px] z-10 ">
            {!isMobile && (
              <span className=" text-[32px]  md:text-[60px] leading-[45px] md:leading-[60px] font-extrabold ">
                {t("who_we_serve.food_industry.title_part1")}&nbsp;
                <br />
                {t("who_we_serve.food_industry.title_part2")}
              </span>
            )}

            {isMobile && (
              <span className=" text-[32px]  md:text-[60px] leading-[45px] md:leading-[60px] font-extrabold ">
                {t("who_we_serve.food_industry.title_part1")}&nbsp;
                {t("who_we_serve.food_industry.title_part2")}
              </span>
            )}

            <p className="hidden md:block text-[#000000] text-14 md:text-20 font-medium leading-5 md:leading-7 mt-2 md:mt-4 mb-[22px] md:mb-[44px]">
              {t("who_we_serve.food_industry.content")}
            </p>

            <div className="hidden md:block mb-[15px] mt-10 md:mb-0">
              <a
                href="/get-started"
                className="rounded-md text-white text-[11px] md:text-18 leading-[11px] md:leading-[18px] px-[17px] md:px-[30px] py-[9px] md:py-[15px] uppercase bg-gradient-to-r from-[#240B3B] via-[#5E1B83] to-[#BC14FE]"
              >
                {t("who_we_serve.food_industry.unlock_free_plan")}
              </a>
            </div>
          </div>
          <div className=" md:block md:w-[50%] md:mt-12  relative align-middle">
            <img
              alt=""
              className="object-cover md:absolute rounded-[20px] right-[29px] max-h-full"
              src={OverviewBannerImg}
            />
          </div>

          <div className="flex flex-col md:hidden mt-6">
            <p className="text-[#000000] text-14 md:text-20 font-medium leading-5 md:leading-7 mt-2 md:mt-4 mb-[22px] md:mb-[44px]">
              {t("who_we_serve.food_industry.content")}
            </p>

            <div className=" mt-6 md:mt-0">
              <a
                href="/get-started"
                className="flex w-full justify-center rounded-md text-white font-bold text-18 leading-[18px] md:px-[30px] py-[15px] uppercase bg-gradient-to-r from-[#240B3B] via-[#5E1B83] to-[#BC14FE]"
              >
                {t("who_we_serve.food_industry.unlock_free_plan")}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col-reverse md:flex-row mt-10 md:mt-[38px]">
        <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
          <img alt="" src={OverviewImg1} />
        </div>

        <div className="w-full md:w-[50%] md:p-12 md:pr-0  flex-col">
          <h1 className="text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-8">
            {t("who_we_serve.food_industry.streamlined_reservation")}
          </h1>
          <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
            <div className="flex flex-col w-full md:w-[80%]">
              <CheckLine
                text={t("who_we_serve.food_industry.streamlined_reservation")}
              />
              <p className="mt-2 text-14 md:text-16 font-medium text-[#161C2D]  ">
                {t("who_we_serve.food_industry.streamlined_reservation_sub")}{" "}
              </p>
            </div>
            <div className="flex flex-col w-full md:w-[80%]">
              <CheckLine
                text={t("who_we_serve.food_industry.rservation_booking")}
              />
              <p className="mt-2 text-14 md:text-16 font-medium text-[#161C2D]  ">
                {t("who_we_serve.food_industry.rservation_booking_sub")}
              </p>
            </div>
            <div className="flex flex-col w-full md:w-[80%]">
              <CheckLine text={"Data-Driven Insights"} />
              <p className="mt-2 text-14 md:text-16 font-medium text-[#161C2D]  ">
                {tabText("gainVisibilityIntoBookingTrends")}
              </p>
            </div>
          </div>
          <p className="text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4">
            {tabText("stopJugglingFragmentedSystems")}
          </p>
          <FreeTrial />
        </div>
      </div>
      <div className="flex flex-col-reverse md:flex-row-reverse mt-10 md:mt-[38px] ">
        <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
          <img src={OverviewImg2} alt="" />
        </div>
        <div className="w-full md:w-[50%] md:p-0 md:pl-0  ">
          <h1 className="text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4">
            {t("who_we_serve.food_industry.inventory_management")}{" "}
          </h1>

          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            {tabText("trackingAndInsightsSimplified")}
          </p>
          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            {t("who_we_serve.food_industry.inventory_management_sub")}
          </p>
          <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
            {[
              t("who_we_serve.food_industry.inventory_management_tracking"),
              t("who_we_serve.food_industry.inventory_management_update"),
              t("who_we_serve.food_industry.inventory_management_insight"),
              t("who_we_serve.food_industry.inventory_management_view"),
              t("who_we_serve.food_industry.inventory_management_history"),
            ].map((item) => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className="text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4">
            {tabText("stockManagementSimplified")}
          </p>
          <FreeTrial />
        </div>
      </div>

      <div className="flex flex-col-reverse md:flex-row mt-10 md:mt-[38px]">
        <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
          <img alt="" src={OverviewImg3} />
        </div>
        <div className="w-full md:w-[50%] md:p-12 md:pr-0  flex-col">
          <h1
            className="text-[#240b3b] text-22 align-left md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
            style={{ alignSelf: "left" }}
          >
            {t("who_we_serve.food_industry.staff_managment")}
          </h1>
          <p className="text-[#666666] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
            {tabText("easierSchedulingPayroll")}
          </p>

          <p className="text-[#666666] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
            {tabText("venueBoostEmpowers")}
          </p>
          <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
            {[
              tabText("createAndUpdateSchedules"),
              tabText("automateWageAndTipCalculations"),
              tabText("trackHoursAndStaffPerformance"),
              tabText("identifySchedulingGapsAndBottlenecks"),
              tabText("ensureSmoothOperationsAndCoordination"),
            ].map((item) => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className="text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4">
            {tabText("centralizeStaffManagement")}
          </p>
          <FreeTrial />
        </div>
      </div>
      <div className="flex flex-col-reverse md:flex-row-reverse mt-10 md:mt-[38px] ">
        <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
          <img src={OverviewImg4} alt="" />
        </div>
        <div className="w-full md:w-[50%] md:p-0 md:pl-0   ">
          <h1 className="text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4">
            {t("who_we_serve.food_industry.marketing")}{" "}
          </h1>
          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            {tabText("expandedReachAndBrandPromotion")}
          </p>

          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            {t("who_we_serve.food_industry.marketing_sub")}
          </p>
          <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
            {[
              t("who_we_serve.food_industry.marketing_email"),
              t("who_we_serve.food_industry.marketing_promotion"),
              t("who_we_serve.food_industry.marketing_campaigns"),
              t("who_we_serve.food_industry.marketing_refferals"),
            ].map((item) => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className="text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4">
            {tabText("consolidateMarketingActivities")}
          </p>
          <FreeTrial />
        </div>
      </div>

      <div className="flex flex-col-reverse md:flex-row mt-10 md:mt-[38px]">
        <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
          <img src={OverviewImg5} alt="" />
        </div>
        <div className="w-full md:w-[50%] md:p-12 md:pr-0  flex-col">
          <h1
            className="text-[#240b3b] text-22 align-left md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
            style={{ alignSelf: "left" }}
          >
            {t("who_we_serve.food_industry.loyalty")}
          </h1>

          <p className="text-[#666666] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
            {tabText("rewardYourGuests")}
          </p>
          <p className="text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
            {t("who_we_serve.food_industry.loyalty_sub")}
          </p>
          <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
            {[
              "Tailor tiers and benefits",
              "Track member profiles and purchase history",
              "Send personalized rewards and offers",
              "Analyze engagement and activity",
            ].map((item) => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className="text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4">
            {tabText("strengthenRelationships")}
          </p>
          <FreeTrial />
        </div>
      </div>
      <div className="flex flex-col-reverse md:flex-row-reverse mt-10 md:mt-[38px] ">
        <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
          <img src={OverviewImg6} alt="" />
        </div>
        <div className="w-full md:w-[50%]   ">
          <h1 className="text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4">
            {t("who_we_serve.food_industry.reporting")}{" "}
          </h1>
          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            {tabText("actionableDiningInsights")}
          </p>

          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            {t("who_we_serve.food_industry.reporting_sub")}
          </p>
          <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
            {[
              t("who_we_serve.food_industry.reporting_sales"),
              t("who_we_serve.food_industry.reporting_staff"),
              t("who_we_serve.food_industry.reporting_table"),
              t("who_we_serve.food_industry.reporting_insight"),
              t("who_we_serve.food_industry.reporting_waitlist"),
            ].map((item) => (
              <CheckLine text={item} />
            ))}
          </div>

          <p className="text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4">
            {tabText("stopRelyingOnGuesswork")}
          </p>
          <FreeTrial />
        </div>
      </div>

      <div className="flex flex-col-reverse md:flex-row mt-10 md:mt-[68px]">
        <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-2 md:mt-0">
          <img src={OverviewImg7} alt="" />
        </div>
        <div className="w-full md:w-[50%] md:p-0 md:pr-0  flex-col">
          <h1
            className="text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
            style={{ alignSelf: "left" }}
          >
            {t("who_we_serve.food_industry.payment_links")}
          </h1>

          <p className="text-[#666666] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
            {tabText("simplifiedOnlineReservationsPayments")}
          </p>

          <p className="text-[#666666] align-middle text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
            {t("who_we_serve.food_industry.payment_links_sub")}
          </p>
          <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
            {[
              "Create secure online reservation payment links",
              "Organized list to track payment links",
              "Insights into transaction volumes and revenue",
              "Smooth, convenient payment process for guests",
            ].map((item) => (
              <CheckLine text={item} />
            ))}
          </div>
          <FreeTrial />
        </div>
      </div>
      <div className="flex flex-col-reverse md:flex-row-reverse mt-10 md:mt-[68px] ">
        <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
          <img src={OverviewImg8} alt="" />
        </div>
        <div className="w-full md:w-[50%]  ">
          <h1 className="text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4">
            {t("who_we_serve.food_industry.order")}{" "}
          </h1>
          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            {tabText("streamlinedDiningDelivery")}
          </p>

          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            {t("who_we_serve.food_industry.order_sub")}
          </p>
          <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
            {[
              t("who_we_serve.food_industry.order_house"),
              t("who_we_serve.food_industry.order_provider"),
              t("who_we_serve.food_industry.order_management"),
              t("who_we_serve.food_industry.order_detail"),
              t("who_we_serve.food_industry.order_history"),
            ].map((item) => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className="text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4">
            {tabText("deliveryWithoutHeadaches")}
          </p>
          <FreeTrial />
        </div>
      </div>
      <div className="flex flex-col-reverse md:flex-row mt-10 md:mt-[68px]">
        <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0">
          <img src={OverviewFoodGuestsManagement} alt="" />
        </div>
        <div className="w-full md:w-[50%] md:p-0 md:pr-0  flex-col">
          <h1
            className="text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
            style={{ alignSelf: "left" }}
          >
            {t("who_we_serve.food_industry.guest_management")}
          </h1>

          <p className="text-[#666666] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
            {tabText("streamlineReservationsLoyaltyCommunication")}
          </p>
          <p className="text-[#666666] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
            {t("who_we_serve.food_industry.guest_management_sub")}
          </p>

          <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
            {[
              t("who_we_serve.food_industry.guest_management_suite"),
              t("who_we_serve.food_industry.guest_management_preference"),
              t("who_we_serve.food_industry.guest_management_loyalty"),
              "Custom loyalty programs with special rewards",
              t("who_we_serve.food_industry.order_history"),
            ].map((item) => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className="text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4">
            {tabText("understandGuestsDeliverExceptionalExperiences")}
          </p>
          <FreeTrial />
        </div>
      </div>

      <div className="flex flex-col-reverse md:flex-row-reverse mt-10 md:mt-[68px] ">
        <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-6 md:mt-0">
          <img src={OverviewImg9} alt="" />
        </div>
        <div className="w-full md:w-[50%]  ">
          <h1 className="text-[#240b3b] text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4">
            {tabText("affiliatePartnerships")}{" "}
          </h1>
          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            Expand Your Reach and Revenue
          </p>
          <p className="text-[#666666] text-12 md:text-18 font-medium  leading-[21px] md:leading-[30px] mb-4">
            {tabText("affiliatesFeature")}
          </p>
          <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
            {[
              tabText("establishAffiliatePartnershipsSeamlessly"),
              tabText("trackReferralsAndCommissionsInRealTime"),
              tabText("accessValuableMarketingResources"),
              tabText("customLoyaltyPrograms"),
              tabText("extendBrandReach"),
            ].map((item) => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className="text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4">
            {tabText("unlockNewOpportunitiesWithVenueBoostAffiliates")}
          </p>
          <FreeTrial />
        </div>
      </div>
      <div className="flex flex-col-reverse md:flex-row mt-10 md:mt-[68px]">
        <div className="w-full md:w-[50%] rounded-[12px] md:rounded-[27px] align-middle mt-0 md:mt-0">
          <img src={OverviewImg10} alt="" />
        </div>
        <div className="w-full md:w-[50%] md:p-0 md:pr-0  flex-col">
          <h1
            className="text-[#240b3b] align-left text-22 md:text-[32px] font-bold leading-[25px] md:leading-[35px] mb-4"
            style={{ alignSelf: "left" }}
          >
            {tabText("guestSurveysAndRatings")}
          </h1>
          <p className="text-[#666666] align-left text-12 md:text-18 font-medium leading-[18px] md:leading-[30px] mb-4">
            {tabText("gatherValuableFeedback")}
          </p>
          <div className="grid grid-cols-1 gap-[16px] md:gap-[25px]">
            {[
              tabText("venueBoostSurveysRatings"),
              tabText("customizedGuestSurveys"),
              tabText("collectRatingsReviews"),
              tabText("gainInsightsPreferencesOpinions"),
              tabText("dataDrivenImprovements"),
            ].map((item) => (
              <CheckLine text={item} />
            ))}
          </div>
          <p className="text-[#161C2D] text-14 md:text-16 font-medium mt-6 mb-4">
            {tabText("optimizeOfferingsWithSurveysAndRatings")}
          </p>
          <FreeTrial />
        </div>
      </div>

      <Grow
        venueColor={"#17CE8C"}
        title={tabText("additionalFoodBeverageFeatures")}
        subtitle={tabText("expandedCapabilitiesFoodBeverageIndustry")}
        tabs={tabs}
      />

      <div className="align-col-middle ">
        <div className="w-[100vw] bg-[#240B3B]">
          <div className="max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 ">
            <div className="flex flex-col align-middle w-full">
              <h1 className="text-white  text-26 md:text-[42px] text-center font-extrabold leading-[60px] mt-10 md:mt-20 ">
                {t("who_we_serve.food_industry.faq")}
                <span className="text-white">
                  {" "}
                  ({t("who_we_serve.food_industry.faq_short")}){" "}
                </span>
              </h1>
              <p className="text-14 md:text-20 text-white px-7 leading-5 font-medium mt-4 mb:mt-20 mb-5 md:mb-[66px]">
                {t("who_we_serve.food_industry.food_industry")}
              </p>
              <Faq faqData={generalFaq} className="mt-2 mb-4 md:mb-16" />
            </div>
          </div>
        </div>
      </div>

      <Advantage venueDefinition={"food industry"} />
      <div className="align-col-middle">
        <div className="w-[100vw] bg-white">
          <div className="mx-auto max-w-screen-main px-4 sm:px-6 md:px-8 lg:px-16">
            <div className="flex flex-col align-middle mb-4 md:mb-20">
              <FreePlanCard />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FoodIndustry;
