import React from "react";
import { Outlet, Route } from "react-router-dom";
import AboutAp from "../../views/about-ap/index.js";
import VenueAccommodation from "../../views/venue-accommodation/index.js";
import AccommodationContact from "../../views/venue-accommodation/accommodation-contact/index.js";

export const venueRoutes = [
  //   <Route path="restaurant" element={<Outlet />}>
  //     <Route exact path={":app_key"} element={<RestaurantCard />} />
  //     <Route
  //       exact
  //       path={":app_key/reserve/availability"}
  //       element={<MakeReservation />}
  //     />
  //     <Route exact path={":app_key/reserve"} element={<RestaurantReserve />} />
  //     <Route
  //       exact
  //       path={":app_key/reserve/complete"}
  //       element={<ReserveComplete />}
  //     />
  //     <Route
  //       exact
  //       path={":app_key/reserve/contact"}
  //       element={<RestaurantContact />}
  //     />
  //     <Route exact path={":app_key/cart"} element={<CartScreen />} />
  //   </Route>,
  //   <Route path="pizzeria" element={<Outlet />}>
  //     <Route exact path={":app_key"} element={<VenueRestaurant />} />
  //     <Route exact path={":app_key/contact"} element={<PizzeriaContact />} />
  //   </Route>,
  //   <Route path="hotel" element={<Outlet />}>
  //     <Route exact path={":app_key"} element={<VenueHotel />} />
  //     <Route exact path={":app_key/contact"} element={<HotelContact />} />
  //     <Route exact path={":app_key/cart"} element={<CartScreen />} />
  //   </Route>,
  //   <Route path="pharmacy" element={<Outlet />}>
  //     <Route exact path={":app_key"} element={<VenuePharmacy />} />
  //     <Route exact path={":app_key/contact"} element={<PharmacyContact />} />
  //   </Route>,
  //   <Route path="beach-bar" element={<Outlet />}>
  //     <Route exact path={":app_key"} element={<VenueBeachBar />} />
  //     <Route exact path={":app_key/contact"} element={<BeachbarContact />} />
  //   </Route>,
  //   <Route path="retail" element={<Outlet />}>
  //     <Route exact path={":app_key"} element={<VenueRetailHome />} />
  //     <Route exact path={":app_key/about"} element={<VenueRetailAbout />} />
  //     <Route exact path={":app_key/shop"} element={<VenueRetailShop />} />
  //     <Route exact path={":app_key/wishlist"} element={<Wishlist />} />
  //     <Route exact path={":app_key/contact"} element={<RetailContact />} />
  //     <Route exact path={":app_key/cart"} element={<CartScreen />} />
  //   </Route>,
  <Route path="accommodation" element={<Outlet />}>
    <Route exact path={":app_key"} element={<VenueAccommodation />} />
    <Route exact path={":app_key/about"} element={<AboutAp />} />
    <Route exact path={":app_key/contact"} element={<AccommodationContact />} />
  </Route>,
  //   <Route path="gym" element={<Outlet />}>
  //     <Route exact path={":app_key"} element={<VenueGym />} />
  //     <Route exact path={":app_key/contact"} element={<GymContact />} />
  //     <Route exact path={":app_key/book"} element={<VenueGymBook />} />
  //     <Route exact path={":app_key/book-contact"} element={<GymBookContact />} />
  //   </Route>,
  //   <Route path="bowling" element={<Outlet />}>
  //     <Route exact path={":app_key"} element={<VenueBowling />} />
  //     <Route exact path={":app_key/contact"} element={<BowlingContact />} />
  //     <Route exact path={":app_key/book"} element={<VenueBowlingBook />} />
  //     <Route
  //       exact
  //       path={":app_key/book-contact"}
  //       element={<BowlingBookContact />}
  //     />
  //   </Route>,
  //   <Route path="golf" element={<Outlet />}>
  //     <Route exact path={":app_key"} element={<VenueGolf />} />
  //     <Route exact path={":app_key/contact"} element={<GolfContact />} />
  //     <Route exact path={":app_key/book"} element={<VenueGolfBook />} />
  //   </Route>,
];
