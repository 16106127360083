import React from 'react';
import { FaAngleRight } from 'react-icons/fa';
import './index.css';
import FoodHoverIcon from "../../../assets/svgs/food-hover.svg";
import SportHoverIcon from "../../../assets/svgs/sport-hover.svg";
import AccommodationHoverIcon from "../../../assets/svgs/accommodation-hover.svg";
import RetailHoverIcon from "../../../assets/svgs/retail-hover.svg";
import {useNavigate} from "react-router";

const AudienceItem = ({ icon, title1, title2, content, background, url }) => {
    const navigate = useNavigate(); // Initialize navigate using useNavigate hook

    return (
        <div className={`${background} pb-[2px] rounded-2xl`}>
            <div className='audience-item h-full flex flex-col relative p-5 !pb-14 md:!pb-20 rounded-2xl gap-2 md:gap-5 bg-white'>
                <div className={`flex justify-end`}>
                    <img src={icon} alt='icon' className={`${background} rounded`} />
                </div>
                <div className='flex flex-col gap-2'>
          <span className='text-lg font-bold'>
            {title1} <br />
              {title2}
          </span>
                    <span className='text-base leading-7'>
            {content}
          </span>
                </div>
                {/* Use onClick to trigger navigation */}
                <div onClick={() => {
                    // Navigate to the specified URL
                    navigate(url);
                }} className='absolute cursor-pointer right-5 bottom-5'>
                    <div className={`audience-more ${background}`}>
                        <FaAngleRight className={`text-white font-bold`} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default function Audience() {
    const audienceList = [
        {
            icon: FoodHoverIcon,
            title1: 'Food & Beverage',
            content: 'Elevate the Food & Beverage industry with our platform. VenueBoost empowers restaurants, cafes,\n' +
                'bars, and food enterprises to craft exceptional guest experiences by streamlining operations and\n' +
                'unlocking new capabilities.',
            background: 'bg-[#17CE8C]',
            url: "/who-we-serve/food-and-beverage",
        },
        {
            icon: SportHoverIcon,
            title1: 'Entertainment Venues',
            content: 'Give golf courses, museums, cinemas, bowling alleys, amusement parks, and other entertainment\n' +
                'venues the chance to optimize ticketing, streamline concessions, manage equipment rentals, and gain\n' +
                'insights to engage visitors, reduce costs, and create memorable experiences.',
            background: 'bg-[#F4D35E]',
            url: "/who-we-serve/entertainment-venues",
        },
        {
            icon: AccommodationHoverIcon,
            title1: 'Accommodation',
            content: 'Help hotels, resorts, hostels, and other accommodations providers effortlessly manage\n' +
                'reservations, housekeeping, staff schedules, amenities, and reporting to deliver exceptional guest\n' +
                'stays. Share VenueBoost’s tailored accommodation solutions.',
            background: 'bg-[#6DDFE2]',
            url: "/who-we-serve/accommodation",
        },
        {
            icon: RetailHoverIcon,
            title1: 'Retail Management',
            content: 'Introduce VenueBoost to fashion boutiques, bookstores, electronic stores, home décor stores and other\n' +
                'retailers. Help them unify in-store and online, optimize inventory, build loyalty programs, and gain\n' +
                'insights to boost sales.',
            background: 'bg-[#E14848]',
            url: "/who-we-serve/retail-management",
        },
    ];

    return (
        <div className='flex flex-col gap-6 md:gap-10'>
      <span className='text-2xl md:text-4xl font-black text-center'>
        Why refer VenueBoost to your audience?
      </span>
            <span className='text-lg md:text-22 text-center'>
        Earn commissions, deliver value to your audience, share valuable technology and amplify your influence
        by partnering with VenueBoost as an affiliate. Join our referral program and share the benefits with your
        audience.
      </span>

            <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-5'>
                {audienceList.map((item, index) => (
                    <AudienceItem key={index} {...item} />
                ))}
            </div>
        </div>
    )
}
