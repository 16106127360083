import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import { addCartBillingDetails } from "../../../../redux/actions/cart";
import { useDispatch } from "react-redux";
import FormTextInput from "../../../../components/FormTextInput";
import FormSelect from "../../../../components/FormSelect";
import { Checkbox } from "@mui/material";
import "../../checkout/index.css";

const ShippingForm = ({ setActiveStep }) => {
  const dispatch = useDispatch();
  const validator = yup.object().shape({
    first_name: yup
      .string()
      .trim()
      .required("First name is required")
      .max(72, "Max exceeded")
      .min(2, "Min not met"),
    last_name: yup
      .string()
      .trim()
      .required("Last name is required")
      .max(72, "Max exceeded")
      .min(2, "Min not met"),
    email: yup
      .string()
      .trim()
      .required("Email is required")
      .max(72, "Max exceeded")
      .min(5, "Min not met")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Invalid email"
      ),
    phone: yup.string().trim().required("Phone number is required"),

    state: yup.string().trim().required("State is required"),
    address_line1: yup
      .string()
      .trim()
      .required("Address Line 1 is required")
      .max(250, "Max exceeded")
      .min(6, "Min not met"),
    address_line2: yup.string().trim(),
    city: yup.string().required("City is required"),
    postcode: yup.string().required("Postal code is required"),
    company_name: yup.string(),
    additional_notes: yup.string(),
  });

  const [isChecked, setIsChecked] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);

  const [formFields, setFormFields] = useState({
    first_name: undefined,
    last_name: undefined,
    email: undefined,
    phone: undefined,
    address_line1: undefined,
    address_line2: undefined,
    state: true,
  });

  const stateList = [
    { value: "New York", title: "New York" },
    { value: "Tirana", title: "Tirana" },
  ];

  const formikProps = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      address_line1: "",
      address_line2: "",
      state: "New York",
      city: "",
      postcode: "",
      company_name: "",
      additional_notes: "",
    },
    validationSchema: validator,
    onSubmit: (values) => onSubmit(values),
    validateOnChange: true,
    validateOnBlur: false,
    validateOnMount: true,
  });

  const onSubmit = (values) => {
    window.scrollTo(0, 0);
    dispatch(addCartBillingDetails(values));
  };

  const errorValue = (name) => {
    const isFalse = formFields[name] === false;

    if (isSubmit && formFields[name] === undefined) {
      setFormFields({
        ...formFields,
        [name]: false,
      });
    }

    return isFalse ? formikProps.errors[name] : false;
  };

  const allFieldsValidated = Object.values(formFields).every(
    (value) => value === true
  );

  return (
    <div className="shipping-form">
      <h1>Add a new address</h1>

      <form>
        <FormTextInput
          placeholder="First Name"
          value={formikProps.values.first_name}
          error={errorValue("first_name")}
          onChange={(e) => {
            setFormFields({
              ...formFields,
              first_name: e.target.value.length > 0,
            });
            formikProps.setFieldValue("first_name", e.target.value);
          }}
          enableLabel={false}
        />
        <FormTextInput
          placeholder="Last Name"
          value={formikProps.values.last_name}
          error={errorValue("last_name")}
          onChange={(e) => {
            formikProps.setFieldValue("last_name", e.target.value);
            setFormFields({
              ...formFields,
              last_name: e.target.value.length > 0,
            });
          }}
          enableLabel={false}
        />
        <FormTextInput
          placeholder="Email"
          type={"email"}
          value={formikProps.values.email}
          error={errorValue("email")}
          onChange={(e) => {
            formikProps.setFieldValue("email", e.target.value);
            setFormFields({
              ...formFields,
              email: e.target.value.length > 0,
            });
          }}
          enableLabel={false}
        />
        <FormTextInput
          placeholder="Phone Number"
          value={formikProps.values.phone}
          error={errorValue("phone")}
          onChange={(e) => {
            formikProps.setFieldValue("phone", e.target.value);
            setFormFields({
              ...formFields,
              phone: e.target.value.length > 0,
            });
          }}
          enableLabel={false}
        />

        <FormTextInput
          placeholder="Address Line 1"
          value={formikProps.values.address_line1}
          error={errorValue("address_line1")}
          onChange={(e) => {
            formikProps.setFieldValue("address_line1", e.target.value);
            setFormFields({
              ...formFields,
              address_line1: e.target.value.length > 0,
            });
          }}
          enableLabel={false}
        />
        <FormTextInput
          placeholder="Address Line 2"
          type={"email"}
          value={formikProps.values.address_line2}
          error={errorValue("address_line1")}
          onChange={(e) => {
            formikProps.setFieldValue("address_line2", e.target.value);
            setFormFields({
              ...formFields,
              address_line2: e.target.value.length > 0,
            });
          }}
          enableLabel={false}
        />
        <FormSelect
          name={"state"}
          placeholder="State"
          value={formikProps.values.state}
          error={errorValue("state")}
          dataList={stateList}
          onChanage={(e) => {
            formikProps.setFieldValue("state", e.target.value);
            setFormFields({
              ...formFields,
              state: e.target.value.length > 0,
            });
          }}
          enableLabel={false}
        />
        <FormTextInput
          placeholder="Town / City"
          type={"email"}
          value={formikProps.values.city}
          error={errorValue("city")}
          onChange={(e) => {
            formikProps.setFieldValue("city", e.target.value);
            setFormFields({
              ...formFields,
              city: e.target.value.length > 0,
            });
          }}
          enableLabel={false}
        />
        <FormTextInput
          placeholder="Postal Code"
          value={formikProps.values.postcode}
          error={errorValue("postcode")}
          onChange={(e) => {
            formikProps.setFieldValue("postcode", e.target.value);
            setFormFields({
              ...formFields,
              postcode: e.target.value.length > 0,
            });
          }}
          enableLabel={false}
        />
        <FormTextInput
          placeholder="Company Name (optional)"
          value={formikProps.values.company_name}
          error={false}
          onChange={(e) => {
            formikProps.setFieldValue("company_name", e.target.value);
          }}
          enableLabel={false}
        />
        <div className="flex items-center">
          <Checkbox
            label="Use as my default address"
            defaultChecked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
            checked={isChecked}
            className="cursor-pointer"
            id={"defaultAddress"}
            sx={{
              "& .MuiSvgIcon-root": {
                fill: "#131118",
              },
            }}
          />
          <label
            htmlFor={"defaultAddress"}
            className="cursor-pointer default-address"
          >
            Use as my default address
          </label>
        </div>
        <button
          type="button"
          className="deliver"
          onClick={() => {
            if (allFieldsValidated) {
              setActiveStep(1);
            } else {
              setIsSubmit(true);
            }
          }}
        >
          <span>Add New Address</span>
        </button>
      </form>
    </div>
  );
};

export default ShippingForm;
