import React, { useState } from "react";
import "./index.css";
import FormTextInput from "../../components/FormTextInput";
import FormSelect from "../../components/FormSelect";
import Dot2 from "../../assets/svgs/dot2-getstarted.svg";
import { FormControlLabel, MenuItem, Select, Tooltip } from "@mui/material";
import { Checkbox } from "antd";
import { isMobile } from "react-device-detect";
import {
  businessChallenges,
  contactReason,
  featuresByIndustryAndPlan,
  howDidYouHearOptions,
  socialMediaOptions,
} from "./data";
import FooterButtons from "./footer-buttons";
import { NotificationManager } from "react-notifications";
import { recommendPlan, trackOnboarding } from "../../redux/actions/onboarding";
import { handleError } from "./common-functions";

const StepTwo = ({
  data,
  handleChange,
  customRadioStyle,
  customCheckedRadioStyle,
  radioStyle,
  setStep,
  onboardingEmail,
  setData,
  setOnboardingPricingPlans,
  setSuggestedPlanFeatures,
  setSuggestedPlan,
  setVenueType,
}) => {
  const [loadingSecondStep, setLoadingSecondStep] = useState(false);

  const onSubmitInterestStep = () => {
    // Business Challenge validation
    if (
      (data?.business_challenge === "Other" ||
        (Array.isArray(data?.business_challenge) &&
          data?.business_challenge?.includes("Other"))) &&
      !data?.other_business_challenge
    ) {
      NotificationManager.warning(
        `Please specify your challenge`,
        "Warning",
        3000
      );
      return false;
    }

    if (
      data?.how_did_you_hear_about_us === "Other" &&
      !data?.how_did_you_hear_about_us_other
    ) {
      NotificationManager.warning(
        `Please specify Other reason`,
        "Warning",
        3000
      );
      return false;
    }

    if (!data["number_of_employees"]) {
      NotificationManager.warning(
        `Nr of employees field is required`,
        "Warning",
        3000
      );
      return;
    }

    if (!data["business_challenge"]) {
      NotificationManager.warning(
        `Business challenge field is required`,
        "Warning",
        3000
      );
      return;
    }

    if (!data["annual_revenue"]) {
      NotificationManager.warning(
        `Annual Revenue field is required`,
        "Warning",
        3000
      );
      return;
    }

    if (!data["social_media"]) {
      NotificationManager.warning(
        `Active Social Media Presence field is required`,
        "Warning",
        3000
      );
      return;
    }

    if (!data["contact_reason"]) {
      NotificationManager.warning(
        `Interest reason field is required`,
        "Warning",
        3000
      );
      return;
    }

    data["email"] = onboardingEmail;
    data["step"] = "interest_engagement";

    let socialMediaArray = Array.isArray(data.social_media)
      ? data.social_media
      : JSON.parse(data.social_media || "[]");

    let submissionData = {
      ...data,
      social_media: socialMediaArray,
    };

    if (!isMobile && Array.isArray(data.business_challenge)) {
      submissionData = {
        ...submissionData,
        business_challenge: data.business_challenge.join(", "),
      };
    }

    setLoadingSecondStep(true);
    trackOnboarding(submissionData)
      .then((data) => {
        const newData = {
          email: data?.potentialVenueLead?.email,
        };
        recommendPlan(newData)
          .then((data) => {
            const industryFeatures =
              featuresByIndustryAndPlan[data?.industry] || {};
            const planFeatures =
              industryFeatures[data?.recommended_pricing_plan] || [];
            setSuggestedPlan(data?.recommended_pricing_plan);
            setOnboardingPricingPlans(data?.onboarding_pricing_plans);
            setVenueType(data?.industry);
            setSuggestedPlanFeatures(planFeatures);
            setStep(3);
            window.scrollTo(0, 0);
            setLoadingSecondStep(false);
          })
          .catch((err) => {
            setLoadingSecondStep(false);
            handleError(err);
          });
      })
      .catch((err) => {
        setLoadingSecondStep(false);
        handleError(err);
      });
  };

  const handleCheckboxChange = (e, value) => {
    const isChecked = e.target.checked;
    setData((data) => {
      // Get the current array of selected social media, or initialize it as an empty array
      const selectedSocialMedia = data.social_media || [];

      if (isChecked) {
        // Add the value to the array if it's checked and not already present
        return {
          ...data,
          social_media: [...selectedSocialMedia, value],
        };
      } else {
        // Remove the value from the array if it's unchecked
        return {
          ...data,
          social_media: selectedSocialMedia.filter((item) => item !== value),
        };
      }
    });
  };

  return (
    <div
      className="  bg-white rounded-[31px] p-[24px] md:p-[33px] relative"
      style={{
        height: isMobile
          ? data?.business_challenge === "Other" &&
            data?.how_did_you_hear_about_us === "Other"
            ? "1415px"
            : data?.business_challenge === "Other" ||
              data?.how_did_you_hear_about_us === "Other"
            ? "1315px"
            : "1215px"
          : (data?.business_challenge === "Other" ||
              (Array.isArray(data?.business_challenge) &&
                data?.business_challenge.includes("Other"))) &&
            data?.how_did_you_hear_about_us === "Other"
          ? "950px"
          : data?.business_challenge === "Other" ||
            (Array.isArray(data?.business_challenge) &&
              data?.business_challenge.includes("Other")) ||
            data?.how_did_you_hear_about_us === "Other"
          ? "860px"
          : "760px",
      }}
    >
      <div className="flex justify-start  items-center">
        <span className="text-[32px] md:text-32 font-extrabold leading-[50px] my-6]">
          Interest and Engagement
        </span>
      </div>
      <p className="text-18 font-semibold text-[#240b3b] my-4">
        Let's dive a bit deeper to align our services with your business
        priorities. Your insights here are crucial for us to deliver solutions
        that truly make a difference.
      </p>

      <div className="">
        <div className="grid md:grid-cols-2 gap-[14px]">
          <div>
            <FormTextInput
              name="number_of_employees"
              placeholder="Number of employees"
              value={data?.number_of_employees || ""}
              onChange={handleChange}
              required={true}
            />
          </div>
          <div>
            <FormTextInput
              name="annual_revenue"
              placeholder="Annual Revenue"
              value={data?.annual_revenue || ""}
              onChange={handleChange}
              required={true}
            />
          </div>
        </div>
        <div className="grid md:grid-cols-2 gap-[14px]">
          <div className={"mt-6"}>
            <div className={"flex"}>
              <p className="text-[#232323] text-14 md:text-16 font-medium mb-2">
                Active Social Media Presence?
              </p>
              <span style={{ color: "#F44336", marginLeft: "2px" }}>*</span>
            </div>
            <div style={{ marginLeft: "10px" }}>
              {socialMediaOptions.map((option, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={data?.social_media?.includes(option.value)}
                      onChange={(e) => handleCheckboxChange(e, option.value)}
                      name="social_media"
                      style={{ marginRight: "4px" }}
                    />
                  }
                  label={option.label}
                  style={{ marginRight: "20px" }}
                />
              ))}
            </div>
          </div>

          <div className={"md:mt-6"}>
            <label>Do You Have a Website?</label>
            <div className={"flex"} style={{ marginTop: "10px" }}>
              <input
                type="radio"
                name="website"
                value="yes"
                checked={data?.website === "yes"}
                onChange={handleChange}
                style={{
                  ...customRadioStyle,
                  ...(data?.website === "no" ? customCheckedRadioStyle : {}),
                }}
              />
              <span style={{ marginLeft: "5px", marginTop: "-2px" }}>Yes</span>
              <input
                type="radio"
                name="website"
                value="no"
                checked={data?.website === "no"}
                onChange={handleChange}
                style={{
                  ...radioStyle,
                  ...customRadioStyle,
                  ...(data?.website === "no" ? customCheckedRadioStyle : {}),
                }}
              />
              <span style={{ marginLeft: "5px", marginTop: "-2px" }}>No</span>
            </div>
          </div>
        </div>
        <div className="grid md:grid-cols-1 gap-[14px]">
          <div className={"mt-5"}>
            {!isMobile && (
              <div className={"flex flex-col"}>
                <div className={"flex"}>
                  <p className="text-[#232323] text-14 md:text-16 font-medium mb-2">
                    Business Challenges
                  </p>
                  <span
                    style={{
                      color: "#F44336",
                      marginLeft: "2px",
                    }}
                  >
                    *
                  </span>
                </div>
                <Select
                  name="business_challenge"
                  multiple
                  id="business-challenges-select"
                  value={
                    Array.isArray(data?.business_challenge)
                      ? data.business_challenge
                      : data?.business_challenge
                      ? data.business_challenge.split(", ")
                      : []
                  }
                  onChange={handleChange}
                  sx={{ height: "50px" }}
                >
                  {businessChallenges.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      disabled={option.value === ""}
                    >
                      {option.title}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            )}

            {isMobile && (
              <FormSelect
                name={"business_challenge"}
                placeholder="Business Challenges"
                value={data?.business_challenge || ""}
                dataList={businessChallenges}
                onChanage={handleChange}
                required={true}
              />
            )}
            {/* Conditional rendering for 'Other' option */}
            {(data?.business_challenge === "Other" ||
              (Array.isArray(data?.business_challenge) &&
                data?.business_challenge.includes("Other"))) && (
              <FormTextInput
                className={"mt-5"}
                name="other_business_challenge"
                placeholder="Please specify your challenge"
                value={data?.other_business_challenge || ""}
                onChange={handleChange}
                required={true}
              />
            )}
          </div>
        </div>
        <div className="grid md:grid-cols-2 gap-[14px]">
          <div className={"mt-5"}>
            <div className={"flex"}>
              <p className="text-[#232323] cursor-pointer text-14 md:text-16 font-medium">
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    <React.Fragment>
                      {/*<Typography color="inherit">Tooltip with HTML</Typography>*/}
                      <p>
                        <b>{"Access Free Plan:"}</b> Explore VenueBoost's
                        features at no cost.
                      </p>
                      <p>
                        <b>{"Request Personalized Consultation:"}</b> Get in
                        touch with our team for a tailored service plan.
                      </p>
                      <p>
                        <b>{"Subscribe to Updates:"}</b> Stay informed with the
                        latest news and updates from VenueBoost.
                      </p>
                    </React.Fragment>
                  }
                >
                  Interested in
                </Tooltip>
              </p>
              <span style={{ color: "#F44336", marginLeft: "2px" }}>*</span>
            </div>
            <FormSelect
              name={"contact_reason"}
              enableLabel={false}
              // placeholder="Interested in"
              value={data?.contact_reason || contactReason[0].value}
              dataList={contactReason}
              onChanage={handleChange}
              required={true}
            />
          </div>
          <div className={"mt-5"}>
            <FormSelect
              name={"how_did_you_hear_about_us"}
              placeholder="How did you hear about us?"
              value={data?.how_did_you_hear_about_us || ""}
              dataList={howDidYouHearOptions}
              onChanage={handleChange}
            />

            {/* Conditional rendering for 'Other' option */}
            {data?.how_did_you_hear_about_us === "Other" && (
              <FormTextInput
                className={"mt-5"}
                name="how_did_you_hear_about_us_other"
                placeholder="Please specify"
                value={data?.how_did_you_hear_about_us_other || ""}
                onChange={handleChange}
                required={true}
              />
            )}
          </div>
        </div>

        <div className={"mt-5"}>
          <FormTextInput
            name="biggest_additional_change"
            placeholder="What’s your biggest operational challenge?"
            value={data?.biggest_additional_change || ""}
            onChange={handleChange}
          />
        </div>
      </div>

      <FooterButtons
        {...{
          loading: loadingSecondStep,
          onSubmit: onSubmitInterestStep,
          setStep,
          step: 2,
        }}
      />
      <div className="w-full align-middle absolute bottom-[30px] left-0">
        <img alt="" src={Dot2} />
      </div>
    </div>
  );
};

export default StepTwo;
