import React  from 'react';
import './index.css';
import {Seo} from "../../components/Seo";

const PrivacyPolicy = () => {
  return (
    <div className='flex flex-col align-center view-terms mb-10'>
      <Seo title={"Privacy Policy | VenueBoost"} />
      <div>
        <div>
          <strong>
      <span style={{ fontSize: 26 }}>
        <span data-custom-class="title">
          <bdt className="block-component" />
          <bdt className="question">PRIVACY POLICY</bdt>
          <bdt className="statement-end-if-in-editor" />
        </span>
      </span>
          </strong>
        </div>
        <div>
          <br />
        </div>
        <div>
    <span style={{ color: "rgb(127, 127, 127)" }}>
      <strong>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="subtitle">
            Last updated <bdt className="question">September 17, 2023</bdt>
          </span>
        </span>
      </strong>
    </span>
        </div>
        <div>
          <br />
        </div>
        <div>
          <br />
        </div>
        <div>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
    <span style={{ color: "rgb(127, 127, 127)" }}>
      <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
        <span data-custom-class="body_text">
          This privacy notice for{" "}
          <bdt className="question">
            VenueBoost Inc.
            <bdt className="block-component" />
          </bdt>{" "}
          (<bdt className="block-component" />"<strong>we</strong>," "
          <strong>us</strong>," or "<strong>our</strong>"
          <bdt className="statement-end-if-in-editor" />
        </span>
        <span data-custom-class="body_text">
          ), describes how and why we might collect, store, use, and/or share (
          <bdt className="block-component" />"<strong>process</strong>"
          <bdt className="statement-end-if-in-editor" />) your information when
          you use our services (<bdt className="block-component" />"
          <strong>Services</strong>"
          <bdt className="statement-end-if-in-editor" />
          ), such as when you:
        </span>
      </span>
    </span>
          <span style={{ fontSize: 15 }}>
      <span style={{ color: "rgb(127, 127, 127)" }}>
        <span data-custom-class="body_text">
          <span style={{ color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <bdt className="block-component" />
            </span>
          </span>
        </span>
      </span>
    </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            Visit our website
            <bdt className="block-component" /> at{" "}
            <bdt className="question">
              <a
                  href="https://venueboost.io"
                  target="_blank"
                  data-custom-class="link"
              >
                https://venueboost.io
              </a>
            </bdt>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <bdt className="statement-end-if-in-editor">
                        , or any website of ours that links to this privacy
                        notice
                      </bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </span>
          </li>
        </ul>
        <div>
          <bdt className="block-component">
      <span style={{ fontSize: 15 }}>
        <span style={{ fontSize: 15 }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span data-custom-class="body_text">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
          </span>
        </span>
      </span>
          </bdt>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span data-custom-class="body_text">
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
              </span>
            </span>
          </span>
        </span>
      </span>
          </div>
          <ul>
            <li style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              Engage with us in other related ways, including any sales,
              marketing, or events
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <bdt className="statement-end-if-in-editor" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
            </li>
          </ul>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span data-custom-class="body_text">
            <strong>Questions or concerns?&nbsp;</strong>Reading this privacy
            notice will help you understand your privacy rights and choices. If
            you do not agree with our policies and practices, please do not use
            our Services. If you still have any questions or concerns, please
            contact us at <bdt className="question">contact@venueboost.io</bdt>.
          </span>
        </span>
      </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <strong>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="heading_1">SUMMARY OF KEY POINTS</span>
        </span>
            </strong>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <span data-custom-class="body_text">
          <strong>
            <em>
              This summary provides key points from our privacy notice, but you
              can find out more details about any of these topics by clicking
              the link following each key point or by using our&nbsp;
            </em>
          </strong>
        </span>
      </span>
            <a data-custom-class="link" href="#toc">
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>
              <em>table of contents</em>
            </strong>
          </span>
        </span>
            </a>
            <span style={{ fontSize: 15 }}>
        <span data-custom-class="body_text">
          <strong>
            <em>&nbsp;below to find the section you are looking for.</em>
          </strong>
        </span>
      </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <span data-custom-class="body_text">
          <strong>What personal information do we process?</strong> When you
          visit, use, or navigate our Services, we may process personal
          information depending on how you interact with us and the Services,
          the choices you make, and the products and features you use. Learn
          more about&nbsp;
        </span>
      </span>
            <a data-custom-class="link" href="#personalinfo">
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            personal information you disclose to us
          </span>
        </span>
            </a>
            <span data-custom-class="body_text">.</span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <span data-custom-class="body_text">
          <strong>Do we process any sensitive personal information?</strong>{" "}
          <bdt className="block-component" />
          We do not process sensitive personal information.
          <bdt className="else-block" />
        </span>
      </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <span data-custom-class="body_text">
          <strong>Do we receive any information from third parties?</strong>{" "}
          <bdt className="block-component" />
          We do not receive any information from third parties.
          <bdt className="else-block" />
        </span>
      </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <span data-custom-class="body_text">
          <strong>How do we process your information?</strong> We process your
          information to provide, improve, and administer our Services,
          communicate with you, for security and fraud prevention, and to comply
          with law. We may also process your information for other purposes with
          your consent. We process your information only when we have a valid
          legal reason to do so. Learn more about&nbsp;
        </span>
      </span>
            <a data-custom-class="link" href="#infouse">
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            how we process your information
          </span>
        </span>
            </a>
            <span data-custom-class="body_text">.</span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <span data-custom-class="body_text">
          <strong>
            In what situations and with which{" "}
            <bdt className="block-component" />
            parties do we share personal information?
          </strong>{" "}
          We may share information in specific situations and with specific{" "}
          <bdt className="block-component" />
          third parties. Learn more about&nbsp;
        </span>
      </span>
            <a data-custom-class="link" href="#whoshare">
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            when and with whom we share your personal information
          </span>
        </span>
            </a>
            <span style={{ fontSize: 15 }}>
        <span data-custom-class="body_text">
          .<bdt className="block-component" />
        </span>
      </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <span data-custom-class="body_text">
          <strong>How do we keep your information safe?</strong> We have{" "}
          <bdt className="block-component" />
          organizational
          <bdt className="statement-end-if-in-editor" /> and technical processes
          and procedures in place to protect your personal information. However,
          no electronic transmission over the internet or information storage
          technology can be guaranteed to be 100% secure, so we cannot promise
          or guarantee that hackers, cybercriminals, or other{" "}
          <bdt className="block-component" />
          unauthorized
          <bdt className="statement-end-if-in-editor" /> third parties will not
          be able to defeat our security and improperly collect, access, steal,
          or modify your information. Learn more about&nbsp;
        </span>
      </span>
            <a data-custom-class="link" href="#infosafe">
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            how we keep your information safe
          </span>
        </span>
            </a>
            <span data-custom-class="body_text">.</span>
            <span style={{ fontSize: 15 }}>
        <span data-custom-class="body_text">
          <bdt className="statement-end-if-in-editor" />
        </span>
      </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <span data-custom-class="body_text">
          <strong>What are your rights?</strong> Depending on where you are
          located geographically, the applicable privacy law may mean you have
          certain rights regarding your personal information. Learn more
          about&nbsp;
        </span>
      </span>
            <a data-custom-class="link" href="#privacyrights">
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">your privacy rights</span>
        </span>
            </a>
            <span data-custom-class="body_text">.</span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <span data-custom-class="body_text">
          <strong>How do you exercise your rights?</strong> The easiest way to
          exercise your rights is by{" "}
          <bdt className="block-component">submitting a&nbsp;</bdt>
        </span>
      </span>
            <a
                data-custom-class="link"
                href="#"
                rel="noopener noreferrer"
                target="_blank"
            >
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">data subject access request</span>
        </span>
            </a>
            <span style={{ fontSize: 15 }}>
        <span data-custom-class="body_text">
          <bdt className="block-component" />, or by contacting us. We will
          consider and act upon any request in accordance with applicable data
          protection laws.
        </span>
      </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <span data-custom-class="body_text">
          Want to learn more about what we do with any information we
          collect?&nbsp;
        </span>
      </span>
            <a data-custom-class="link" href="#toc">
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            Review the privacy notice in full
          </span>
        </span>
            </a>
            <span style={{ fontSize: 15 }}>
        <span data-custom-class="body_text">.</span>
      </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div id="toc" style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(0, 0, 0)" }}>
            <strong>
              <span data-custom-class="heading_1">TABLE OF CONTENTS</span>
            </strong>
          </span>
        </span>
      </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <a data-custom-class="link" href="#infocollect">
          <span style={{ color: "rgb(89, 89, 89)" }}>
            1. WHAT INFORMATION DO WE COLLECT?
          </span>
        </a>
      </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <a data-custom-class="link" href="#infouse">
          <span style={{ color: "rgb(89, 89, 89)" }}>
            2. HOW DO WE PROCESS YOUR INFORMATION?
            <bdt className="block-component" />
          </span>
        </a>
      </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <span style={{ color: "rgb(89, 89, 89)" }}>
          <a data-custom-class="link" href="#whoshare">
            3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          </a>
        </span>
        <span data-custom-class="body_text">
          <bdt className="block-component" />
        </span>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <bdt className="block-component" />
              </span>
            </span>
          </span>
        </span>
      </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <a data-custom-class="link" href="#cookies">
          <span style={{ color: "rgb(89, 89, 89)" }}>
            4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
          </span>
        </a>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <bdt className="statement-end-if-in-editor" />
              </span>
            </span>
            <span data-custom-class="body_text">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <bdt className="block-component" />
                  </span>
                </span>
                <bdt className="block-component" />
              </span>
            </span>
          </span>
        </span>
      </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <a data-custom-class="link" href="#inforetain">
          <span style={{ color: "rgb(89, 89, 89)" }}>
            5. HOW LONG DO WE KEEP YOUR INFORMATION?
          </span>
        </a>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
          </span>
        </span>
      </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <a data-custom-class="link" href="#infosafe">
          <span style={{ color: "rgb(89, 89, 89)" }}>
            6. HOW DO WE KEEP YOUR INFORMATION SAFE?
          </span>
        </a>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <bdt className="statement-end-if-in-editor" />
                <bdt className="block-component" />
              </span>
            </span>
          </span>
        </span>
      </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <a data-custom-class="link" href="#infominors">
          <span style={{ color: "rgb(89, 89, 89)" }}>
            7. DO WE COLLECT INFORMATION FROM MINORS?
          </span>
        </a>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <bdt className="statement-end-if-in-editor" />
              </span>
            </span>
          </span>
        </span>
      </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <span style={{ color: "rgb(89, 89, 89)" }}>
          <a data-custom-class="link" href="#privacyrights">
            8. WHAT ARE YOUR PRIVACY RIGHTS?
          </a>
        </span>
      </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <a data-custom-class="link" href="#DNT">
          <span style={{ color: "rgb(89, 89, 89)" }}>
            9. CONTROLS FOR DO-NOT-TRACK FEATURES
          </span>
        </a>
      </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <a data-custom-class="link" href="#caresidents">
          <span style={{ color: "rgb(89, 89, 89)" }}>
            10. PRIVACY STATEMENT FOR CALIFORNIA RESIDENTS
          </span>
        </a>
      </span>
            <bdt className="block-component">
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text" />
        </span>
            </bdt>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <bdt className="block-component">
              <span style={{ fontSize: 15 }} />
            </bdt>
            <bdt className="block-component" />
            <bdt className="block-component" />
            <bdt className="block-component" />
            <bdt className="block-component" />
            <bdt className="block-component" />
            <bdt className="block-component" />
            <bdt className="block-component" />
            <bdt className="block-component" />
            <bdt className="block-component" />
          </div>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <a data-custom-class="link" href="#policyupdates">
          <span style={{ color: "rgb(89, 89, 89)" }}>
            11. DO WE MAKE UPDATES TO THIS NOTICE?
          </span>
        </a>
      </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <a data-custom-class="link" href="#contact">
        <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
          12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
        </span>
            </a>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <a data-custom-class="link" href="#request">
        <span style={{ color: "rgb(89, 89, 89)" }}>
          13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?
        </span>
            </a>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div id="infocollect" style={{ lineHeight: "1.5" }}>
      <span style={{ color: "rgb(127, 127, 127)" }}>
        <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                <strong>
                  <span data-custom-class="heading_1">
                    1. WHAT INFORMATION DO WE COLLECT?
                  </span>
                </strong>
              </span>
            </span>
          </span>
        </span>
      </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div id="personalinfo" style={{ lineHeight: "1.5" }}>
      <span data-custom-class="heading_2" style={{ color: "rgb(0, 0, 0)" }}>
        <span style={{ fontSize: 15 }}>
          <strong>Personal information you disclose to us</strong>
        </span>
      </span>
          </div>
          <div>
            <div>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <strong>
                      <em>In Short:</em>
                    </strong>
                  </span>
                </span>
              </span>
            </span>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <strong>
                      <em>&nbsp;</em>
                    </strong>
                    <em>
                      We collect personal information that you provide to us.
                    </em>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
            </div>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            We collect personal information that you voluntarily provide to us
            when you{" "}
            <span style={{ fontSize: 15 }}>
              <bdt className="block-component" />
            </span>
            register on the Services,&nbsp;
          </span>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <bdt className="statement-end-if-in-editor" />
              </span>
            </span>
            <span data-custom-class="body_text">
              express an interest in obtaining information about us or our
              products and Services, when you participate in activities on the
              Services, or otherwise when you contact us.
            </span>
          </span>
        </span>
      </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <span style={{ fontSize: 15 }}>
              <bdt className="block-component" />
            </span>
          </span>
        </span>
      </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <strong>Personal Information Provided by You.</strong> The personal
            information that we collect depends on the context of your
            interactions with us and the Services, the choices you make, and the
            products and features you use. The personal information we collect
            may include the following:
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <bdt className="forloop-component" />
              </span>
            </span>
          </span>
        </span>
      </span>
          </div>
          <ul>
            <li style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <bdt className="question">names</bdt>
                </span>
              </span>
            </span>
          </span>
        </span>
            </li>
          </ul>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <bdt className="forloop-component" />
              </span>
            </span>
          </span>
        </span>
      </span>
          </div>
          <ul>
            <li style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <bdt className="question">phone numbers</bdt>
                </span>
              </span>
            </span>
          </span>
        </span>
            </li>
          </ul>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <bdt className="forloop-component" />
              </span>
            </span>
          </span>
        </span>
      </span>
          </div>
          <ul>
            <li style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <bdt className="question">email addresses</bdt>
                </span>
              </span>
            </span>
          </span>
        </span>
            </li>
          </ul>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <bdt className="forloop-component" />
              </span>
            </span>
          </span>
        </span>
      </span>
          </div>
          <ul>
            <li style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <bdt className="question">mailing addresses</bdt>
                </span>
              </span>
            </span>
          </span>
        </span>
            </li>
          </ul>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <bdt className="forloop-component" />
              </span>
            </span>
          </span>
        </span>
      </span>
          </div>
          <ul>
            <li style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <bdt className="question">job titles</bdt>
                </span>
              </span>
            </span>
          </span>
        </span>
            </li>
          </ul>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <bdt className="forloop-component" />
              </span>
            </span>
          </span>
        </span>
      </span>
          </div>
          <ul>
            <li style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <bdt className="question">contact preferences</bdt>
                </span>
              </span>
            </span>
          </span>
        </span>
            </li>
          </ul>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <bdt className="forloop-component" />
              </span>
            </span>
          </span>
        </span>
      </span>
          </div>
          <ul>
            <li style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <bdt className="question">contact or authentication data</bdt>
                </span>
              </span>
            </span>
          </span>
        </span>
            </li>
          </ul>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <bdt className="forloop-component" />
              </span>
            </span>
          </span>
        </span>
      </span>
          </div>
          <ul>
            <li style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <bdt className="question">billing addresses</bdt>
                </span>
              </span>
            </span>
          </span>
        </span>
            </li>
          </ul>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <bdt className="forloop-component" />
              </span>
              <span data-custom-class="body_text">
                <bdt className="statement-end-if-in-editor" />
              </span>
            </span>
          </span>
        </span>
      </span>
          </div>
          <div id="sensitiveinfo" style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <span data-custom-class="body_text">
          <strong>Sensitive Information.</strong>{" "}
          <bdt className="block-component" />
          We do not process sensitive information.
        </span>
      </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <span data-custom-class="body_text">
          <bdt className="else-block" />
        </span>
      </span>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
              </span>
            </span>
          </span>
        </span>
      </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <strong>Payment Data.</strong> We may collect data necessary to
            process your payment if you make purchases, such as your payment
            instrument number, and the security code associated with your
            payment instrument. All payment data is stored by
            <bdt className="forloop-component" />
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
              </span>
            </span>{" "}
            <bdt className="question">Stripe</bdt>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: 15 }}>
                          <span data-custom-class="body_text">
                            <bdt className="block-component" />
                          </span>
                        </span>
                      </span>
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span data-custom-class="body_text">
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="body_text">
                                          <bdt className="forloop-component" />
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                  . You may find their privacy notice link(s) here:
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <bdt className="forloop-component" />
                        <span
                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          <span
                              style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                          >
                            <span data-custom-class="body_text">
                              <span style={{ fontSize: 15 }}>
                                <span data-custom-class="body_text">
                                  <bdt className="block-component" />
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>{" "}
                  <bdt className="question">
                    <a
                        href="https://stripe.com/en-gb-us/privacy"
                        target="_blank"
                        data-custom-class="link"
                    >
                      https://stripe.com/en-gb-us/privacy
                    </a>
                  </bdt>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span
                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          <span
                              style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                          >
                            <span data-custom-class="body_text">
                              <span
                                  style={{
                                    fontSize: 15,
                                    color: "rgb(89, 89, 89)"
                                  }}
                              >
                                <span
                                    style={{
                                      fontSize: 15,
                                      color: "rgb(89, 89, 89)"
                                    }}
                                >
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: 15 }}>
                                      <span data-custom-class="body_text">
                                        <bdt className="block-component" />
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                        <bdt className="forloop-component" />
                        <span style={{ fontSize: 15 }}>
                          <span data-custom-class="body_text">
                            .<bdt className="block-component" />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <bdt className="statement-end-if-in-editor">
                  <bdt className="block-component" />
                </bdt>
              </span>
            </span>
          </span>
        </span>
        <bdt className="block-component" />
      </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            All personal information that you provide to us must be true,
            complete, and accurate, and you must notify us of any changes to
            such personal information.
          </span>
        </span>
      </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <bdt className="block-component" />
          </span>
        </span>
      </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
      <span data-custom-class="heading_2" style={{ color: "rgb(0, 0, 0)" }}>
        <span style={{ fontSize: 15 }}>
          <strong>Information automatically collected</strong>
        </span>
      </span>
          </div>
          <div>
            <div>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <strong>
                      <em>In Short:</em>
                    </strong>
                  </span>
                </span>
              </span>
            </span>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <strong>
                      <em>&nbsp;</em>
                    </strong>
                    <em>
                      Some information — such as your Internet Protocol (IP)
                      address and/or browser and device characteristics — is
                      collected automatically when you visit our Services.
                    </em>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
            </div>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            We automatically collect certain information when you visit, use, or
            navigate the Services. This information does not reveal your
            specific identity (like your name or contact information) but may
            include device and usage information, such as your IP address,
            browser and device characteristics, operating system, language
            preferences, referring URLs, device name, country, location,
            information about how and when you use our Services, and other
            technical information. This information is primarily needed to
            maintain the security and operation of our Services, and for our
            internal analytics and reporting purposes.
          </span>
        </span>
      </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <bdt className="block-component" />
          </span>
        </span>
      </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            Like many businesses, we also collect information through cookies
            and similar technologies. <bdt className="block-component" />
            You can find out more about this in our Cookie Notice:{" "}
            <bdt className="statement-end-if-in-editor" />
            <bdt className="block-component" />
            <bdt className="question">
              <a
                  href="https://venueboost.io/cookie-notice"
                  target="_blank"
                  data-custom-class="link"
              >
                https://venueboost.io/cookie-notice
              </a>
            </bdt>
            .<bdt className="block-component" />
          </span>
        </span>
      </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <bdt className="statement-end-if-in-editor">
              <span data-custom-class="body_text" />
            </bdt>
          </span>
          <span data-custom-class="body_text">
            <bdt className="block-component" />
          </span>
        </span>
      </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            The information we collect includes:
            <bdt className="block-component" />
          </span>
        </span>
      </span>
          </div>
          <ul>
            <li style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <em>Log and Usage Data.</em> Log and usage data is
              service-related, diagnostic, usage, and performance information
              our servers automatically collect when you access or use our
              Services and which we record in log files. Depending on how you
              interact with us, this log data may include your IP address,
              device information, browser type, and settings and information
              about your activity in the Services
              <span style={{ fontSize: 15 }}>&nbsp;</span>(such as the date/time
              stamps associated with your usage, pages and files viewed,
              searches, and other actions you take such as which features you
              use), device event information (such as system activity, error
              reports (sometimes called <bdt className="block-component" />
              "crash dumps"
              <bdt className="statement-end-if-in-editor" />
              ), and hardware settings).
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <bdt className="statement-end-if-in-editor" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
            </li>
          </ul>
          <div style={{ lineHeight: "1.5" }}>
            <bdt className="block-component">
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text" />
        </span>
            </bdt>
            <div style={{ lineHeight: "1.5" }}>
              <bdt className="block-component">
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text" />
          </span>
              </bdt>
              <div>
                <bdt className="block-component">
                  <span style={{ fontSize: 15 }} />
                </bdt>
                <bdt className="statement-end-if-in-editor" />
                <span data-custom-class="body_text">
            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="statement-end-if-in-editor">
                      <bdt className="block-component" />
                    </bdt>
                  </span>
                </span>
              </span>
            </span>
          </span>
                <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              <bdt className="block-component" />
            </span>
          </span>
              </div>
              <div id="infouse" style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                    <strong>
                      <span data-custom-class="heading_1">
                        2. HOW DO WE PROCESS YOUR INFORMATION?
                      </span>
                    </strong>
                  </span>
                </span>
              </span>
            </span>
          </span>
              </div>
              <div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <strong>
                          <em>In Short:&nbsp;</em>
                        </strong>
                        <em>
                          We process your information to provide, improve, and
                          administer our Services, communicate with you, for
                          security and fraud prevention, and to comply with law.
                          We may also process your information for other
                          purposes with your consent.
                        </em>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
                </div>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <strong>
                  We process your personal information for a variety of reasons,
                  depending on how you interact with our Services, including:
                </strong>
                <bdt className="block-component" />
              </span>
            </span>
          </span>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <strong>
                    To facilitate account creation and authentication and
                    otherwise manage user accounts.&nbsp;
                  </strong>
                  We may process your information so you can create and log in
                  to your account, as well as keep your account in working
                  order.
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span data-custom-class="body_text">
                                    <bdt className="statement-end-if-in-editor" />
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
              </span>
            </span>
          </span>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <strong>
                    To deliver and facilitate delivery of services to the
                    user.&nbsp;
                  </strong>
                  We may process your information to provide you with the
                  requested service.
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span
                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          <span
                              style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                          >
                            <span data-custom-class="body_text">
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: 15 }}>
                                      <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span data-custom-class="body_text">
                                          <bdt className="statement-end-if-in-editor" />
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
              </span>
            </span>
          </span>
                <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
                </div>
                <ul>
                  <li style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <strong>
                      To respond to user inquiries/offer support to users.&nbsp;
                    </strong>
                    We may process your information to respond to your inquiries
                    and solve any potential issues you might have with the
                    requested service.
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <span style={{ fontSize: 15 }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      <bdt className="statement-end-if-in-editor" />
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
                  </li>
                </ul>
                <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
                  <div style={{ lineHeight: "1.5" }}>
                    <bdt className="block-component">
                      <span style={{ fontSize: 15 }} />
                    </bdt>
                  </div>
                  <ul>
                    <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>
                        To send administrative information to you.&nbsp;
                      </strong>
                      We may process your information to send you details about
                      our products and services, changes to our terms and
                      policies, and other similar information.
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span
                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          <span data-custom-class="body_text">
                            <bdt className="statement-end-if-in-editor" />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
                    </li>
                  </ul>
                  <div style={{ lineHeight: "1.5" }}>
                    <bdt className="block-component">
                      <span style={{ fontSize: 15 }} />
                    </bdt>
                    <div style={{ lineHeight: "1.5" }}>
                      <bdt className="block-component">
                        <span style={{ fontSize: 15 }} />
                      </bdt>
                    </div>
                    <ul>
                      <li style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <strong>
                          To <bdt className="block-component" />
                          fulfill
                          <bdt className="statement-end-if-in-editor" /> and
                          manage your orders.&nbsp;
                        </strong>
                        We may process your information to{" "}
                        <bdt className="block-component" />
                        fulfill
                        <bdt className="statement-end-if-in-editor" /> and
                        manage your orders, payments, returns, and exchanges
                        made through the Services.
                        <span
                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          <span
                              style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                          >
                            <span data-custom-class="body_text">
                              <span
                                  style={{
                                    fontSize: 15,
                                    color: "rgb(89, 89, 89)"
                                  }}
                              >
                                <span
                                    style={{
                                      fontSize: 15,
                                      color: "rgb(89, 89, 89)"
                                    }}
                                >
                                  <span data-custom-class="body_text">
                                    <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)"
                                        }}
                                    >
                                      <span
                                          style={{
                                            fontSize: 15,
                                            color: "rgb(89, 89, 89)"
                                          }}
                                      >
                                        <span data-custom-class="body_text">
                                          <bdt className="statement-end-if-in-editor" />
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                      </li>
                    </ul>
                    <div style={{ lineHeight: "1.5" }}>
                      <bdt className="block-component">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text" />
                  </span>
                      </bdt>
                      <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                        <bdt className="block-component">
                          <span style={{ fontSize: 15 }} />
                        </bdt>
                      </p>
                      <ul>
                        <li style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <strong>
                              To enable user-to-user communications.&nbsp;
                            </strong>
                            We may process your information if you choose to use
                            any of our offerings that allow for communication
                            with another user.
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span data-custom-class="body_text">
                                          <bdt className="statement-end-if-in-editor" />
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                        </li>
                      </ul>
                      <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                        <bdt className="block-component">
                          <span style={{ fontSize: 15 }} />
                        </bdt>
                      </p>
                      <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                        <bdt className="block-component" />
                      </p>
                      <ul>
                        <li style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <strong>To request feedback.&nbsp;</strong>We may
                            process your information when necessary to request
                            feedback and to contact you about your use of our
                            Services.
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span data-custom-class="body_text">
                                          <bdt className="statement-end-if-in-editor" />
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                        </li>
                      </ul>
                      <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                        <bdt className="block-component" />
                      </p>
                      <div style={{ lineHeight: "1.5" }}>
                        <bdt className="block-component">
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text" />
                    </span>
                        </bdt>
                      </div>
                      <ul>
                        <li style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <strong>
                          To send you marketing and promotional
                          communications.&nbsp;
                        </strong>
                        We may process the personal information you send to us
                        for our marketing purposes, if this is in accordance
                        with your marketing preferences. You can opt out of our
                        marketing emails at any time. For more information, see{" "}
                        <bdt className="block-component" />"
                        <bdt className="statement-end-if-in-editor" />
                      </span>
                    </span>
                          <a href="#privacyrights">
                      <span data-custom-class="link">
                        <span style={{ fontSize: 15 }}>
                          <span data-custom-class="link">
                            WHAT ARE YOUR PRIVACY RIGHTS?
                          </span>
                        </span>
                      </span>
                          </a>
                          <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />"
                        <bdt className="statement-end-if-in-editor" /> below.
                      </span>
                      <bdt className="statement-end-if-in-editor">
                        <span data-custom-class="body_text" />
                      </bdt>
                    </span>
                        </li>
                      </ul>
                      <div style={{ lineHeight: "1.5" }}>
                        <bdt className="block-component">
                          <span style={{ fontSize: 15 }} />
                        </bdt>
                        <div style={{ lineHeight: "1.5" }}>
                          <bdt className="block-component">
                            <span style={{ fontSize: 15 }} />
                          </bdt>
                          <div style={{ lineHeight: "1.5" }}>
                      <span style={{ fontSize: 15 }}>
                        <bdt className="block-component">
                          <span data-custom-class="body_text" />
                        </bdt>
                      </span>
                            <div style={{ lineHeight: "1.5" }}>
                              <bdt className="block-component">
                          <span style={{ fontSize: 15 }}>
                            <span data-custom-class="body_text" />
                          </span>
                              </bdt>
                              <div style={{ lineHeight: "1.5" }}>
                                <bdt className="block-component">
                            <span style={{ fontSize: 15 }}>
                              <span data-custom-class="body_text" />
                            </span>
                                </bdt>
                                <div style={{ lineHeight: "1.5" }}>
                                  <bdt className="block-component">
                              <span style={{ fontSize: 15 }}>
                                <span data-custom-class="body_text" />
                              </span>
                                  </bdt>
                                  <div style={{ lineHeight: "1.5" }}>
                                    <bdt className="block-component">
                                <span style={{ fontSize: 15 }}>
                                  <span data-custom-class="body_text" />
                                </span>
                                    </bdt>
                                    <div style={{ lineHeight: "1.5" }}>
                                      <bdt className="block-component">
                                  <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text" />
                                  </span>
                                      </bdt>
                                    </div>
                                    <ul>
                                      <li style={{ lineHeight: "1.5" }}>
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: 15 }}>
                                      <strong>
                                        To evaluate and improve our Services,
                                        products, marketing, and your
                                        experience.
                                      </strong>{" "}
                                      We may process your information when we
                                      believe it is necessary to identify usage
                                      trends, determine the effectiveness of our
                                      promotional campaigns, and to evaluate and
                                      improve our Services, products, marketing,
                                      and your experience.
                                    </span>
                                  </span>
                                        <bdt className="statement-end-if-in-editor">
                                    <span style={{ fontSize: 15 }}>
                                      <span data-custom-class="body_text" />
                                    </span>
                                        </bdt>
                                      </li>
                                    </ul>
                                    <div style={{ lineHeight: "1.5" }}>
                                      <bdt className="block-component">
                                  <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text" />
                                  </span>
                                      </bdt>
                                    </div>
                                    <ul>
                                      <li style={{ lineHeight: "1.5" }}>
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: 15 }}>
                                      <strong>To identify usage trends.</strong>{" "}
                                      We may process information about how you
                                      use our Services to better understand how
                                      they are being used so we can improve
                                      them.
                                    </span>
                                  </span>
                                        <bdt className="statement-end-if-in-editor">
                                    <span style={{ fontSize: 15 }}>
                                      <span data-custom-class="body_text" />
                                    </span>
                                        </bdt>
                                      </li>
                                    </ul>
                                    <div style={{ lineHeight: "1.5" }}>
                                      <bdt className="block-component">
                                  <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text" />
                                  </span>
                                      </bdt>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <bdt className="block-component">
                                    <span style={{ fontSize: 15 }}>
                                      <span data-custom-class="body_text" />
                                    </span>
                                        </bdt>
                                      </div>
                                      <ul>
                                        <li style={{ lineHeight: "1.5" }}>
                                    <span style={{ fontSize: 15 }}>
                                      <span data-custom-class="body_text">
                                        <strong>
                                          To determine the effectiveness of our
                                          marketing and promotional campaigns.
                                        </strong>{" "}
                                        We may process your information to
                                        better understand how to provide
                                        marketing and promotional campaigns that
                                        are most relevant to you.
                                        <bdt className="statement-end-if-in-editor" />
                                      </span>
                                    </span>
                                        </li>
                                      </ul>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <bdt className="block-component">
                                    <span style={{ fontSize: 15 }}>
                                      <span data-custom-class="body_text" />
                                    </span>
                                        </bdt>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <bdt className="block-component">
                                      <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="body_text" />
                                      </span>
                                          </bdt>
                                          <div style={{ lineHeight: "1.5" }}>
                                            <bdt className="block-component">
                                        <span style={{ fontSize: 15 }}>
                                          <span data-custom-class="body_text" />
                                        </span>
                                            </bdt>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <bdt className="block-component">
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text" />
                                          </span>
                                              </bdt>
                                              <bdt className="block-component">
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text" />
                                          </span>
                                              </bdt>
                                              <bdt className="block-component">
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text" />
                                          </span>
                                              </bdt>
                                              <bdt className="block-component">
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text" />
                                          </span>
                                              </bdt>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div
                                                id="whoshare"
                                                style={{ lineHeight: "1.5" }}
                                            >
                                        <span
                                            style={{
                                              color: "rgb(127, 127, 127)"
                                            }}
                                        >
                                          <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                                fontSize: 15
                                              }}
                                          >
                                            <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)"
                                                }}
                                            >
                                              <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                              >
                                                <span
                                                    id="control"
                                                    style={{
                                                      color: "rgb(0, 0, 0)"
                                                    }}
                                                >
                                                  <strong>
                                                    <span data-custom-class="heading_1">
                                                      3. WHEN AND WITH WHOM DO
                                                      WE SHARE YOUR PERSONAL
                                                      INFORMATION?
                                                    </span>
                                                  </strong>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                        <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)"
                                            }}
                                        >
                                          <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)"
                                              }}
                                          >
                                            <span data-custom-class="body_text">
                                              <strong>
                                                <em>In Short:</em>
                                              </strong>
                                              <em>
                                                &nbsp;We may share information
                                                in specific situations described
                                                in this section and/or with the
                                                following{" "}
                                                <bdt className="block-component" />
                                                third parties.
                                              </em>
                                            </span>
                                          </span>
                                        </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                        <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)"
                                            }}
                                        >
                                          <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)"
                                              }}
                                          >
                                            <span data-custom-class="body_text">
                                              <bdt className="block-component" />
                                            </span>
                                          </span>
                                        </span>
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <br />
                                            </div>
                                            <div style={{ lineHeight: "1.5" }}>
                                        <span style={{ fontSize: 15 }}>
                                          <span data-custom-class="body_text">
                                            We{" "}
                                            <bdt className="block-component" />
                                            may need to share your personal
                                            information in the following
                                            situations:
                                          </span>
                                        </span>
                                            </div>
                                            <ul>
                                              <li style={{ lineHeight: "1.5" }}>
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text">
                                              <strong>
                                                Business Transfers.
                                              </strong>{" "}
                                              We may share or transfer your
                                              information in connection with, or
                                              during negotiations of, any
                                              merger, sale of company assets,
                                              financing, or acquisition of all
                                              or a portion of our business to
                                              another company.
                                            </span>
                                          </span>
                                              </li>
                                            </ul>
                                            <div style={{ lineHeight: "1.5" }}>
                                        <span style={{ fontSize: 15 }}>
                                          <span data-custom-class="body_text">
                                            <bdt className="block-component" />
                                          </span>
                                        </span>
                                              <div style={{ lineHeight: "1.5" }}>
                                          <span style={{ fontSize: 15 }}>
                                            <bdt className="block-component">
                                              <span data-custom-class="body_text" />
                                            </bdt>
                                          </span>
                                                <div style={{ lineHeight: "1.5" }}>
                                                  <bdt className="block-component">
                                              <span style={{ fontSize: 15 }}>
                                                <span data-custom-class="body_text" />
                                              </span>
                                                  </bdt>
                                                  <div style={{ lineHeight: "1.5" }}>
                                                    <bdt className="block-component">
                                                <span style={{ fontSize: 15 }}>
                                                  <span data-custom-class="body_text" />
                                                </span>
                                                    </bdt>
                                                    <div
                                                        style={{ lineHeight: "1.5" }}
                                                    >
                                                      <bdt className="block-component">
                                                  <span
                                                      style={{ fontSize: 15 }}
                                                  >
                                                    <span data-custom-class="body_text" />
                                                  </span>
                                                      </bdt>
                                                      <div
                                                          style={{ lineHeight: "1.5" }}
                                                      >
                                                        <bdt className="block-component">
                                                    <span
                                                        style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text" />
                                                    </span>
                                                        </bdt>
                                                        <span
                                                            style={{
                                                              color: "rgb(89, 89, 89)",
                                                              fontSize: 15
                                                            }}
                                                        >
                                                    <span
                                                        style={{ fontSize: 15 }}
                                                    >
                                                      <span
                                                          style={{
                                                            color:
                                                                "rgb(89, 89, 89)"
                                                          }}
                                                      >
                                                        <span
                                                            style={{
                                                              fontSize: 15
                                                            }}
                                                        >
                                                          <span
                                                              style={{
                                                                color:
                                                                    "rgb(89, 89, 89)"
                                                              }}
                                                          >
                                                            <bdt className="block-component">
                                                              <span data-custom-class="heading_1" />
                                                            </bdt>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                      </div>
                                                      <div
                                                          style={{ lineHeight: "1.5" }}
                                                      >
                                                        <br />
                                                      </div>
                                                      <div
                                                          id="cookies"
                                                          style={{ lineHeight: "1.5" }}
                                                      >
                                                  <span
                                                      style={{
                                                        color:
                                                            "rgb(127, 127, 127)"
                                                      }}
                                                  >
                                                    <span
                                                        style={{
                                                          color:
                                                              "rgb(89, 89, 89)",
                                                          fontSize: 15
                                                        }}
                                                    >
                                                      <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                                "rgb(89, 89, 89)"
                                                          }}
                                                      >
                                                        <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                  "rgb(89, 89, 89)"
                                                            }}
                                                        >
                                                          <span
                                                              id="control"
                                                              style={{
                                                                color:
                                                                    "rgb(0, 0, 0)"
                                                              }}
                                                          >
                                                            <strong>
                                                              <span data-custom-class="heading_1">
                                                                4. DO WE USE
                                                                COOKIES AND
                                                                OTHER TRACKING
                                                                TECHNOLOGIES?
                                                              </span>
                                                            </strong>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                      </div>
                                                      <div
                                                          style={{ lineHeight: "1.5" }}
                                                      >
                                                        <br />
                                                      </div>
                                                      <div
                                                          style={{ lineHeight: "1.5" }}
                                                      >
                                                  <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                  >
                                                    <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: "rgb(89, 89, 89)"
                                                        }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <strong>
                                                          <em>In Short:</em>
                                                        </strong>
                                                        <em>
                                                          &nbsp;We may use
                                                          cookies and other
                                                          tracking technologies
                                                          to collect and store
                                                          your information.
                                                        </em>
                                                      </span>
                                                    </span>
                                                  </span>
                                                      </div>
                                                      <div
                                                          style={{ lineHeight: "1.5" }}
                                                      >
                                                        <br />
                                                      </div>
                                                      <div
                                                          style={{ lineHeight: "1.5" }}
                                                      >
                                                  <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                  >
                                                    <span
                                                        style={{
                                                          fontSize: 15,
                                                          color: "rgb(89, 89, 89)"
                                                        }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        We may use cookies and
                                                        similar tracking
                                                        technologies (like web
                                                        beacons and pixels) to
                                                        access or store
                                                        information. Specific
                                                        information about how we
                                                        use such technologies
                                                        and how you can refuse
                                                        certain cookies is set
                                                        out in our Cookie Notice
                                                        <span
                                                            style={{
                                                              color:
                                                                  "rgb(89, 89, 89)",
                                                              fontSize: 15
                                                            }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <bdt className="block-component" />
                                                          </span>
                                                        </span>
                                                        :{" "}
                                                        <span
                                                            style={{
                                                              color:
                                                                  "rgb(89, 89, 89)",
                                                              fontSize: 15
                                                            }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <bdt className="question">
                                                              <a
                                                                  href="https://venueboost.io/cookie-notice"
                                                                  target="_blank"
                                                                  data-custom-class="link"
                                                              >
                                                                https://venueboost.io/cookie-notice
                                                              </a>
                                                            </bdt>
                                                          </span>
                                                        </span>
                                                        <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <bdt className="block-component" />
                                                          .
                                                        </span>
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <bdt className="statement-end-if-in-editor" />
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <span data-custom-class="body_text">
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                          fontSize: 15
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: 15
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <bdt className="block-component" />
                                                                    </span>
                                                                    <bdt className="block-component">
                                                                      <span data-custom-class="body_text">
                                                                        <bdt className="block-component" />
                                                                      </span>
                                                                    </bdt>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  id="inforetain"
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        "rgb(127, 127, 127)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                        fontSize: 15
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)"
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span
                                                            id="control"
                                                            style={{
                                                              color:
                                                                "rgb(0, 0, 0)"
                                                            }}
                                                          >
                                                            <strong>
                                                              <span data-custom-class="heading_1">
                                                                5. HOW LONG DO
                                                                WE KEEP YOUR
                                                                INFORMATION?
                                                              </span>
                                                            </strong>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <strong>
                                                          <em>
                                                            In Short:&nbsp;
                                                          </em>
                                                        </strong>
                                                        <em>
                                                          We keep your
                                                          information for as
                                                          long as necessary to{" "}
                                                          <bdt className="block-component" />
                                                          fulfill
                                                          <bdt className="statement-end-if-in-editor" />{" "}
                                                          the purposes outlined
                                                          in this privacy notice
                                                          unless otherwise
                                                          required by law.
                                                        </em>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        We will only keep your
                                                        personal information for
                                                        as long as it is
                                                        necessary for the
                                                        purposes set out in this
                                                        privacy notice, unless a
                                                        longer retention period
                                                        is required or permitted
                                                        by law (such as tax,
                                                        accounting, or other
                                                        legal requirements).
                                                        <bdt className="block-component" />{" "}
                                                        No purpose in this
                                                        notice will require us
                                                        keeping your personal
                                                        information for longer
                                                        than{" "}
                                                        <span
                                                          style={{
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <bdt className="block-component" />
                                                            </span>
                                                          </span>
                                                        </span>
                                                        <bdt className="block-component" />
                                                        <bdt className="question">
                                                          one (1)
                                                        </bdt>{" "}
                                                        months past the
                                                        termination of the
                                                        user's account
                                                        <bdt className="block-component" />
                                                        <span
                                                          style={{
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <bdt className="else-block" />
                                                            </span>
                                                          </span>
                                                        </span>
                                                        .
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        When we have no ongoing
                                                        legitimate business need
                                                        to process your personal
                                                        information, we will
                                                        either delete or{" "}
                                                        <bdt className="block-component" />
                                                        anonymize
                                                        <bdt className="statement-end-if-in-editor" />{" "}
                                                        such information, or, if
                                                        this is not possible
                                                        (for example, because
                                                        your personal
                                                        information has been
                                                        stored in backup
                                                        archives), then we will
                                                        securely store your
                                                        personal information and
                                                        isolate it from any
                                                        further processing until
                                                        deletion is possible.
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <bdt className="block-component" />
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  id="infosafe"
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        "rgb(127, 127, 127)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                        fontSize: 15
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)"
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span
                                                            id="control"
                                                            style={{
                                                              color:
                                                                "rgb(0, 0, 0)"
                                                            }}
                                                          >
                                                            <strong>
                                                              <span data-custom-class="heading_1">
                                                                6. HOW DO WE
                                                                KEEP YOUR
                                                                INFORMATION
                                                                SAFE?
                                                              </span>
                                                            </strong>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <strong>
                                                          <em>
                                                            In Short:&nbsp;
                                                          </em>
                                                        </strong>
                                                        <em>
                                                          We aim to protect your
                                                          personal information
                                                          through a system of{" "}
                                                          <bdt className="block-component" />
                                                          organizational
                                                          <bdt className="statement-end-if-in-editor" />{" "}
                                                          and technical security
                                                          measures.
                                                        </em>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        We have implemented
                                                        appropriate and
                                                        reasonable technical and{" "}
                                                        <bdt className="block-component" />
                                                        organizational
                                                        <bdt className="statement-end-if-in-editor" />{" "}
                                                        security measures
                                                        designed to protect the
                                                        security of any personal
                                                        information we process.
                                                        However, despite our
                                                        safeguards and efforts
                                                        to secure your
                                                        information, no
                                                        electronic transmission
                                                        over the Internet or
                                                        information storage
                                                        technology can be
                                                        guaranteed to be 100%
                                                        secure, so we cannot
                                                        promise or guarantee
                                                        that hackers,
                                                        cybercriminals, or other{" "}
                                                        <bdt className="block-component" />
                                                        unauthorized
                                                        <bdt className="statement-end-if-in-editor" />{" "}
                                                        third parties will not
                                                        be able to defeat our
                                                        security and improperly
                                                        collect, access, steal,
                                                        or modify your
                                                        information. Although we
                                                        will do our best to
                                                        protect your personal
                                                        information,
                                                        transmission of personal
                                                        information to and from
                                                        our Services is at your
                                                        own risk. You should
                                                        only access the Services
                                                        within a secure
                                                        environment.
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <bdt className="statement-end-if-in-editor" />
                                                        </span>
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <bdt className="block-component" />
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  id="infominors"
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        "rgb(127, 127, 127)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                        fontSize: 15
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)"
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span
                                                            id="control"
                                                            style={{
                                                              color:
                                                                "rgb(0, 0, 0)"
                                                            }}
                                                          >
                                                            <strong>
                                                              <span data-custom-class="heading_1">
                                                                7. DO WE COLLECT
                                                                INFORMATION FROM
                                                                MINORS?
                                                              </span>
                                                            </strong>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <strong>
                                                          <em>In Short:</em>
                                                        </strong>
                                                        <em>
                                                          &nbsp;We do not
                                                          knowingly collect data
                                                          from or market to{" "}
                                                          <bdt className="block-component" />
                                                          children under 18
                                                          years of age
                                                          <bdt className="else-block" />
                                                          .
                                                        </em>
                                                        <bdt className="block-component" />
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        We do not knowingly
                                                        solicit data from or
                                                        market to children under
                                                        18 years of age. By
                                                        using the Services, you
                                                        represent that you are
                                                        at least 18 or that you
                                                        are the parent or
                                                        guardian of such a minor
                                                        and consent to such
                                                        minor dependent’s use of
                                                        the Services. If we
                                                        learn that personal
                                                        information from users
                                                        less than 18 years of
                                                        age has been collected,
                                                        we will deactivate the
                                                        account and take
                                                        reasonable measures to
                                                        promptly delete such
                                                        data from our records.
                                                        If you become aware of
                                                        any data we may have
                                                        collected from children
                                                        under age 18, please
                                                        contact us at{" "}
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <bdt className="block-component" />
                                                            <bdt className="question">
                                                              contact@venueboost.io
                                                            </bdt>
                                                            <bdt className="else-block" />
                                                          </span>
                                                        </span>
                                                        .
                                                      </span>
                                                      <span data-custom-class="body_text">
                                                        <bdt className="else-block">
                                                          <bdt className="block-component" />
                                                        </bdt>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  id="privacyrights"
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        "rgb(127, 127, 127)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                        fontSize: 15
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)"
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span
                                                            id="control"
                                                            style={{
                                                              color:
                                                                "rgb(0, 0, 0)"
                                                            }}
                                                          >
                                                            <strong>
                                                              <span data-custom-class="heading_1">
                                                                8. WHAT ARE YOUR
                                                                PRIVACY RIGHTS?
                                                              </span>
                                                            </strong>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <strong>
                                                          <em>In Short:</em>
                                                        </strong>
                                                        <em>
                                                          &nbsp;
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <em>
                                                                  <bdt className="block-component" />
                                                                </em>
                                                              </span>
                                                            </span>
                                                            &nbsp;
                                                          </span>
                                                          You may review,
                                                          change, or terminate
                                                          your account at any
                                                          time.
                                                        </em>
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15
                                                            }}
                                                          >
                                                            <bdt className="block-component">
                                                              <bdt className="block-component" />
                                                            </bdt>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  id="withdrawconsent"
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <strong>
                                                          <u>
                                                            Withdrawing your
                                                            consent:
                                                          </u>
                                                        </strong>{" "}
                                                        If we are relying on
                                                        your consent to process
                                                        your personal
                                                        information,
                                                        <bdt className="block-component" />{" "}
                                                        which may be express
                                                        and/or implied consent
                                                        depending on the
                                                        applicable law,
                                                        <bdt className="statement-end-if-in-editor" />{" "}
                                                        you have the right to
                                                        withdraw your consent at
                                                        any time. You can
                                                        withdraw your consent at
                                                        any time by contacting
                                                        us by using the contact
                                                        details provided in the
                                                        section{" "}
                                                        <bdt className="block-component" />
                                                        "
                                                        <bdt className="statement-end-if-in-editor" />
                                                      </span>
                                                    </span>
                                                  </span>
                                                  <a
                                                    data-custom-class="link"
                                                    href="#contact"
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)"
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          HOW CAN YOU CONTACT US
                                                          ABOUT THIS NOTICE?
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </a>
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <bdt className="block-component" />
                                                        "
                                                        <bdt className="statement-end-if-in-editor" />{" "}
                                                        below
                                                        <bdt className="block-component" />
                                                        .
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      However, please note that
                                                      this will not affect the
                                                      lawfulness of the
                                                      processing before its
                                                      withdrawal nor,
                                                      <bdt className="block-component" />{" "}
                                                      when applicable law
                                                      allows,
                                                      <bdt className="statement-end-if-in-editor" />{" "}
                                                      will it affect the
                                                      processing of your
                                                      personal information
                                                      conducted in reliance on
                                                      lawful processing grounds
                                                      other than consent.
                                                      <bdt className="block-component" />
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <strong>
                                                        <u>
                                                          Opting out of
                                                          marketing and
                                                          promotional
                                                          communications:
                                                        </u>
                                                      </strong>
                                                      <strong>
                                                        <u>&nbsp;</u>
                                                      </strong>
                                                      You can unsubscribe from
                                                      our marketing and
                                                      promotional communications
                                                      at any time by
                                                      <bdt className="block-component" />{" "}
                                                      clicking on the
                                                      unsubscribe link in the
                                                      emails that we send,
                                                      <bdt className="statement-end-if-in-editor" />
                                                      <bdt className="block-component" />{" "}
                                                      replying{" "}
                                                      <bdt className="block-component" />
                                                      "STOP" or "UNSUBSCRIBE"
                                                      <bdt className="statement-end-if-in-editor" />{" "}
                                                      to the SMS messages that
                                                      we send,
                                                      <bdt className="statement-end-if-in-editor" />
                                                      <bdt className="block-component" />{" "}
                                                      or by contacting us using
                                                      the details provided in
                                                      the section{" "}
                                                      <bdt className="block-component" />
                                                      "
                                                      <bdt className="statement-end-if-in-editor" />
                                                    </span>
                                                  </span>
                                                  <a
                                                    data-custom-class="link"
                                                    href="#contact"
                                                  >
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        HOW CAN YOU CONTACT US
                                                        ABOUT THIS NOTICE?
                                                      </span>
                                                    </span>
                                                  </a>
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <bdt className="block-component" />
                                                      "
                                                      <bdt className="statement-end-if-in-editor" />{" "}
                                                      below. You will then be
                                                      removed from the marketing
                                                      lists. However, we may
                                                      still communicate with you
                                                      — for example, to send you
                                                      service-related messages
                                                      that are necessary for the
                                                      administration and use of
                                                      your account, to respond
                                                      to service requests, or
                                                      for other non-marketing
                                                      purposes.
                                                    </span>
                                                    <span data-custom-class="body_text">
                                                      <bdt className="statement-end-if-in-editor" />
                                                    </span>
                                                  </span>
                                                  <bdt className="block-component">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text" />
                                                    </span>
                                                  </bdt>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span data-custom-class="heading_2">
                                                      <strong>
                                                        Account Information
                                                      </strong>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      If you would at any time
                                                      like to review or change
                                                      the information in your
                                                      account or terminate your
                                                      account, you can:
                                                      <bdt className="forloop-component" />
                                                    </span>
                                                  </span>
                                                </div>
                                                <ul>
                                                  <li
                                                    style={{
                                                      lineHeight: "1.5"
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <bdt className="question">
                                                          Log in to your account
                                                          settings and update
                                                          your user account.
                                                        </bdt>
                                                      </span>
                                                    </span>
                                                  </li>
                                                </ul>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <bdt className="forloop-component" />
                                                    </span>
                                                  </span>
                                                </div>
                                                <ul>
                                                  <li
                                                    style={{
                                                      lineHeight: "1.5"
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <bdt className="question">
                                                          Contact us using the
                                                          contact information
                                                          provided.
                                                        </bdt>
                                                      </span>
                                                    </span>
                                                  </li>
                                                </ul>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <bdt className="forloop-component" />
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      Upon your request to
                                                      terminate your account, we
                                                      will deactivate or delete
                                                      your account and
                                                      information from our
                                                      active databases. However,
                                                      we may retain some
                                                      information in our files
                                                      to prevent fraud,
                                                      troubleshoot problems,
                                                      assist with any
                                                      investigations, enforce
                                                      our legal terms and/or
                                                      comply with applicable
                                                      legal requirements.
                                                    </span>
                                                  </span>
                                                  <bdt className="statement-end-if-in-editor">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text" />
                                                    </span>
                                                  </bdt>
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <bdt className="block-component" />
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <strong>
                                                          <u>
                                                            Cookies and similar
                                                            technologies:
                                                          </u>
                                                        </strong>{" "}
                                                        Most Web browsers are
                                                        set to accept cookies by
                                                        default. If you prefer,
                                                        you can usually choose
                                                        to set your browser to
                                                        remove cookies and to
                                                        reject cookies. If you
                                                        choose to remove cookies
                                                        or reject cookies, this
                                                        could affect certain
                                                        features or services of
                                                        our Services. You may
                                                        also{" "}
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(48, 48, 241)"
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <a
                                                              data-custom-class="link"
                                                              href="http://www.aboutads.info/choices/"
                                                              rel="noopener noreferrer"
                                                              target="_blank"
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                opt out of
                                                                interest-based
                                                                advertising by
                                                                advertisers
                                                              </span>
                                                            </a>
                                                          </span>
                                                        </span>{" "}
                                                        on our Services.{" "}
                                                        <span
                                                          style={{
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <bdt className="block-component" />
                                                            </span>
                                                          </span>
                                                        </span>
                                                        For further information,
                                                        please see our Cookie
                                                        Notice:{" "}
                                                        <span
                                                          style={{
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <bdt className="question">
                                                                <a
                                                                  href="https://venueboost.io/cookie-notice"
                                                                  target="_blank"
                                                                  data-custom-class="link"
                                                                >
                                                                  https://venueboost.io/cookie-notice
                                                                </a>
                                                              </bdt>
                                                              .
                                                              <bdt className="block-component" />
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <bdt className="statement-end-if-in-editor" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                  <bdt className="block-component">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text" />
                                                    </span>
                                                  </bdt>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      If you have questions or
                                                      comments about your
                                                      privacy rights, you may
                                                      email us at{" "}
                                                      <bdt className="question">
                                                        contact@venueboost.io
                                                      </bdt>
                                                      .
                                                    </span>
                                                  </span>
                                                  <bdt className="statement-end-if-in-editor">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text" />
                                                    </span>
                                                  </bdt>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  id="DNT"
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        "rgb(127, 127, 127)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                        fontSize: 15
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)"
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span
                                                            id="control"
                                                            style={{
                                                              color:
                                                                "rgb(0, 0, 0)"
                                                            }}
                                                          >
                                                            <strong>
                                                              <span data-custom-class="heading_1">
                                                                9. CONTROLS FOR
                                                                DO-NOT-TRACK
                                                                FEATURES
                                                              </span>
                                                            </strong>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        Most web browsers and
                                                        some mobile operating
                                                        systems and mobile
                                                        applications include a
                                                        Do-Not-Track (
                                                        <bdt className="block-component" />
                                                        "DNT"
                                                        <bdt className="statement-end-if-in-editor" />
                                                        ) feature or setting you
                                                        can activate to signal
                                                        your privacy preference
                                                        not to have data about
                                                        your online browsing
                                                        activities monitored and
                                                        collected. At this stage
                                                        no uniform technology
                                                        standard for{" "}
                                                        <bdt className="block-component" />
                                                        recognizing
                                                        <bdt className="statement-end-if-in-editor" />{" "}
                                                        and implementing DNT
                                                        signals has been{" "}
                                                        <bdt className="block-component" />
                                                        finalized
                                                        <bdt className="statement-end-if-in-editor" />
                                                        . As such, we do not
                                                        currently respond to DNT
                                                        browser signals or any
                                                        other mechanism that
                                                        automatically
                                                        communicates your choice
                                                        not to be tracked
                                                        online. If a standard
                                                        for online tracking is
                                                        adopted that we must
                                                        follow in the future, we
                                                        will inform you about
                                                        that practice in a
                                                        revised version of this
                                                        privacy notice.
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                                   <div>
                                                        <div id="caresidents" style={{ lineHeight: "1.5" }}>
    <span style={{ color: "rgb(127, 127, 127)" }}>
      <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
        <strong>
            <span data-custom-class="heading_1">10. PRIVACY STATEMENT FOR CALIFORNIA RESIDENTS</span>
        </strong>
      </span>
    </span>
                                                        </div>
                                                        <div style={{ lineHeight: "1.5" }}><br /></div>
                                                        <div style={{ lineHeight: "1.5" }}>
    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
      <span data-custom-class="body_text">
        This statement is required by the California Consumer Privacy Act of 2018 (including as amended by the California Privacy Rights Act of 2020) (“CCPA”).
      </span>
    </span>
                                                        </div>
                                                        <div style={{ lineHeight: "1.5" }}><br /></div>
                                                        <div style={{ lineHeight: "1.5" }}>
    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
      <span data-custom-class="body_text">
        The provisions below supplement the information provided in the generally applicable portion of our Privacy Statement and apply solely to individuals that are residents of California and qualify as a “Consumer” under the CCPA.
      </span>
    </span>
                                                        </div>
                                                        <div style={{ lineHeight: "1.5" }}><br /></div>
                                                        <div style={{ lineHeight: "1.5" }}>
    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
      <span data-custom-class="body_text">
        <strong>CCPA Personal Information Table:</strong>
        <p>The below table summarizes:</p>
      </span>
    </span>
                                                        </div>
                                                        <div style={{ lineHeight: "1.5" }}>
                                                          <ul style={{ fontSize: 15, color: "rgb(89, 89, 89)", paddingLeft: "15px", listStyle: "auto"}}>
                                                            <li>The categories of personal information collected by VenueBoost in the past 12 months.</li>
                                                            <li>The sources of collection of the personal information.</li>
                                                            <li>How we use your personal information.</li>
                                                            <li>The categories of personal information disclosed for business purposes by VenueBoost (including to third parties) in the past 12 months.</li>
                                                          </ul>
                                                        </div>
                                                        <div style={{ lineHeight: "1.5" }}><br /></div>
                                                        <div style={{ lineHeight: "1.5" }}>
                                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                          <span data-custom-class="body_text">
                                                            Please see the generally applicable section of this Privacy Statement for additional information on VenueBoost’s information practices, including more detail on how we use and disclose your personal information.
                                                          </span>
                                                        </span>
                                                                                                            </div>
                                                                     <div style={{ lineHeight: "1.5" }}>
                                                                       <br/>
                                                                       <div>
                                                                         <table className="pp-bordered-table" border="1" style={{ width: '100%' }}>
                                                                           <thead>
                                                                           <tr>
                                                                             <th>Category of personal information</th>
                                                                             <th>Collected?</th>
                                                                             <th>Examples of personal information collected</th>
                                                                             <th>Categories of sources</th>
                                                                             <th>Commercial or business purpose</th>
                                                                             <th>How we disclose your personal information</th>
                                                                           </tr>
                                                                           </thead>
                                                                           <tbody>
                                                                           <tr>
                                                                             <td>Identifiers</td>
                                                                             <td>Yes</td>
                                                                             <td>Venues: Name, unique personal identifiers, IP address, email address<br/>Guests: Name, unique personal identifiers, email address<br/>Venue Employees: Name, unique personal identifiers, email address</td>
                                                                             <td>Provided directly to VenueBoost<br/>Automatically collected<br/>Provided to VenueBoost by our service providers<br/>Provided to VenueBoost by our Venues</td>
                                                                             <td>To provide, maintain, and support our Services<br/>To manage our business and for internal operational purposes</td>
                                                                             <td>With our Venues and our Venue Employees<br/>With our service providers</td>
                                                                           </tr>
                                                                           <tr>
                                                                             <td>California Customer Records (Cal. Civ. Code § 1798.80(e))</td>
                                                                             <td>Yes</td>
                                                                             <td>Venues: Name, telephone number, address<br/>Guests: Name, telephone number, address</td>
                                                                             <td>Provided directly to VenueBoost<br/>Provided to VenueBoost by our service providers<br/>Provided to VenueBoost by our Venues</td>
                                                                             <td>To provide, maintain, and support our Services<br/>To manage our business and for internal operational purposes</td>
                                                                             <td>With our Venues and our Venue Employees<br/>With our service providers</td>
                                                                           </tr>
                                                                           <tr>
                                                                             <td>Protected Classification Characteristics</td>
                                                                             <td>No</td>
                                                                             <td>N/A</td>
                                                                             <td>N/A</td>
                                                                             <td>N/A</td>
                                                                             <td>N/A</td>
                                                                           </tr>
                                                                           <tr>
                                                                             <td>Commercial Information</td>
                                                                             <td>Yes</td>
                                                                             <td>Venues: Records of products or services purchased<br/>Guests: Records of products or services purchased</td>
                                                                             <td>Provided directly to VenueBoost<br/>Provided to VenueBoost by our Venues</td>
                                                                             <td>To provide, maintain, and support our Services<br/>To manage our business and for internal operational purposes<br/>To personalize your experience</td>
                                                                             <td>With our Venues and our Venue Employees<br/>With our service providers<br/>With legal and other regulatory authorities</td>
                                                                           </tr>
                                                                           <tr>
                                                                             <td>Biometric Information</td>
                                                                             <td>No</td>
                                                                             <td>N/A</td>
                                                                             <td>N/A</td>
                                                                             <td>N/A</td>
                                                                             <td>N/A</td>
                                                                           </tr>
                                                                           <tr>
                                                                             <td>Internet/Network Information</td>
                                                                             <td>Yes</td>
                                                                             <td>Website browsing activity and interactions</td>
                                                                             <td>Provided directly to VenueBoost<br/>Automatically collected<br/>Provided to VenueBoost by our service providers</td>
                                                                             <td>To provide, maintain, and support our Services<br/>To manage our business and for internal operational purposes<br/>To personalize your experience<br/>To advertise and market to you</td>
                                                                             <td>With our Venues and our Venue Employees<br/>With our service providers</td>
                                                                           </tr>
                                                                           <tr>
                                                                             <td>Geolocation Data</td>
                                                                             <td>Yes</td>
                                                                             <td>Location when Venue Provides it</td>
                                                                             <td>Provided directly to VenueBoost</td>
                                                                             <td>To provide, maintain, and support our Services<br/>To personalize your experience</td>
                                                                             <td>With our Venues and our Venue Employees<br/>With our service providers</td>
                                                                           </tr>
                                                                           <tr>
                                                                             <td>Sensory Information</td>
                                                                             <td>No</td>
                                                                             <td>N/A</td>
                                                                             <td>N/A</td>
                                                                             <td>N/A</td>
                                                                             <td>N/A</td>
                                                                           </tr>
                                                                           <tr>
                                                                             <td>Profession/Employment Information</td>
                                                                             <td>Yes</td>
                                                                             <td>Venue Employees: Employment salary and frequency</td>
                                                                             <td>Provided directly to VenueBoost</td>
                                                                             <td>To provide, maintain, and support our Services</td>
                                                                             <td>With our Venues and our Venue Employees</td>
                                                                           </tr>
                                                                           <tr>
                                                                             <td>Non-Public Education Information (20 U.S.C. § 1232g, 34 C.F.R. Part 99)</td>
                                                                             <td>No</td>
                                                                             <td>N/A</td>
                                                                             <td>N/A</td>
                                                                             <td>N/A</td>
                                                                             <td>N/A</td>
                                                                           </tr>
                                                                           <tr>
                                                                             <td>Inferences</td>
                                                                             <td>Yes</td>
                                                                             <td>Preferences and behaviors based on website interaction</td>
                                                                             <td>Provided directly to VenueBoost<br/>Automatically collected</td>
                                                                             <td>To personalize your experience<br/>To advertise and market to you</td>
                                                                             <td>With our Venues and our Venue Employees<br/>With our service providers</td>
                                                                           </tr>
                                                                           </tbody>
                                                                         </table>
                                                                       </div>
                                                                       <br/>

                                                                     </div>


                                                                     <div style={{ lineHeight: "1.5" }}>
                                                                         <div style={{ lineHeight: "1.5" }}><br /></div>
  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
    <strong><span data-custom-class="body_text">Categories of Personal Information Sold or Shared</span></strong>
  </span>
                                                                     </div>

                                                                     <div style={{ lineHeight: "1.5" }}>
  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
    <span data-custom-class="body_text">
      VenueBoost does not engage in the sale of personal information in the traditional sense. However, we may share personal information with third parties for implementation purposes to enhance and support our services. This sharing is strictly for operational and service optimization purposes and never for targeted advertising or cross-context behavioral advertising. Over the past 12 months, the categories of personal information that might have been impacted by such sharing include:
    </span>
  </span>
                                                                     </div>
                                                                     <div style={{ lineHeight: "1.5" }}>
                                                                       <ul style={{ fontSize: 15, color: "rgb(89, 89, 89)", paddingLeft: "15px", listStyle: "auto" }}>
                                                                         <li>Identifiers;</li>
                                                                         <li>Internet/Network Information; and</li>
                                                                         <li>Inferences.</li>
                                                                       </ul>
                                                                     </div>
                                                                     <div style={{ lineHeight: "1.5" }}>
  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
    <span data-custom-class="body_text">
      Each of the above categories of information may be disclosed to third-parties, which may include our business partners depending on the nature of a user’s interactions.
    </span>
  </span>
                                                                     </div>
                                                                     <div style={{ lineHeight: "1.5" }}><br /></div>
                                                                     <div style={{ lineHeight: "1.5" }}>
                                                                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                                      <span data-custom-class="body_text">
                                                                        Consumers can exercise their right to opt out of these sales or sharing through our cookie management tool that can be accessed by clicking on our “Do not sell or share my personal information” link at the bottom of
                                                                        <a
                                                                          href="https://venueboost.io"
                                                                          style={{
                                                                            color: "#607D8B",
                                                                            textDecoration: "underline",
                                                                            fontWeight: "bold",
                                                                          }}
                                                                      > https://venueboost.io</a>. You can also opt out by submitting a

                                                                        <a
                                                                           href="/privacy-rights-request-form"
                                                                           style={{
                                                                             color: "#607D8B",
                                                                             textDecoration: "underline",
                                                                             fontWeight: "bold",
                                                                           }}
                                                                       > Privacy Rights Request Form</a>.

                                                                      </span>
                                                                    </span>
                                                                 </div>
                                                                     <div style={{ lineHeight: "1.5" }}>
                                                                       <div>
                                                                         <div style={{ lineHeight: "1.5" }}><br /></div>
                                                                         <strong>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        Description of Rights Available to Consumers
      </span>
                                                                         </strong>
                                                                         <p style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                                           If you are a resident of the state of California and subject to certain legal limitations and exceptions, you may be able to exercise some or all of the following rights:
                                                                         </p>
                                                                         <ul style={{ fontSize: 15, color: "rgb(89, 89, 89)", paddingLeft: "15px", listStyle: "auto" }}>
                                                                           <li><b style={{textDecoration: "underline"}}>The right to know/access:</b> you have the right to request that an in-scope business that collects personal information from you, disclose the following in relation to the preceding 12 month period, upon verification of your identity: the categories of personal information collected about you, the categories of sources where the personal information was collected, the business or commercial purposes for collecting (or where applicable, selling or sharing) the personal information, the categories of personal information that we have disclosed to third parties for a business purpose along with the corresponding recipients, the categories of personal information we have sold or shared along with the corresponding recipients, and the specific pieces of personal information collected about you.</li>
                                                                           <li><b style={{textDecoration: "underline"}}>The right of deletion:</b> you have the right to request that an in-scope business delete personal information that it has collected from you, subject to certain exceptions.</li>
                                                                           <li><b style={{textDecoration: "underline"}}>The right of correction:</b> you have the right to request that an in-scope business correct inaccurate personal information, subject to certain conditions.</li>
                                                                           <li><b style={{textDecoration: "underline"}}>The right to opt out of the sale or sharing of personal information:</b> you have the right to request that an in-scope business refrain from selling or sharing personal information it has collected about you to third parties now or in the future. If you are under the age of 16, you have the right to opt in, or to have a parent or guardian opt in on your behalf, to such sales or sharing.</li>
                                                                           <li><b style={{textDecoration: "underline"}}>The right to limit the use and disclosure of sensitive personal information:</b> to the extent that we use sensitive personal information for purposes beyond those set forth in subdivision (a) of Section 1798.121, you have the right to limit the use or disclosure of that sensitive personal information subject to the exceptions within the CCPA.</li>
                                                                           <li><b style={{textDecoration: "underline"}}>The right of access to and to the ability to opt-out of automated decision-making technology:</b> subject to further regulations being issued, you have the right to access information pertaining to automated decision-making technologies and the ability to opt out.</li>
                                                                           <li><b style={{textDecoration: "underline"}}>The right against discrimination and retaliation:</b> you have the right to not be discriminated or retaliated against as a result of exercising any of the above rights.</li>
                                                                         </ul>
                                                                         <p style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                                           However, please note that if the exercise of these rights limits our ability to process personal information (such as in the case of a deletion request), we may no longer be able to provide you with our Services or engage with you in the same manner. In addition, the exercise of the rights described above may result in a different price, rate, or quality level of product or service where that difference is reasonably related to the impact the right has on our relationship or is otherwise permitted by law.
                                                                         </p>
                                                                         <p style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                                           Please note that your ability to invoke the rights above are limited pursuant to the scope and limitations of the CCPA, including any available exceptions. For example, an access request can only be made twice by a Consumer within a 12-month period.
                                                                         </p>
                                                                       </div>

                                                                       <div>
                                                                         <div style={{ lineHeight: "1.5" }}><br /></div>
                                                                         <strong>
                                                                           <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>Accessibility</span>
                                                                         </strong>
                                                                         <p style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                                           In the event you are a user with a disability, or are supporting an individual with a disability, and are having difficulty navigating this Statement or the information linked within this Statement, please contact us at contact@venueboost.io for support.
                                                                         </p>
                                                                       </div>

                                                                       <div>
                                                                         <div style={{ lineHeight: "1.5" }}><br /></div>
                                                                         <strong>
                                                                           <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>Retention</span>
                                                                         </strong>
                                                                         <p style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                                           We retain personal information as long as reasonably necessary to provide the Services and carry out the purposes described in this Statement. However, if necessary, we may retain personal information for longer periods of time, until set retention periods and deadlines expire, or for instances where we are required to do so in accordance with legal, tax and accounting requirements set by a legislature, regulator or other government authority.
                                                                           <br />
                                                                           To determine the appropriate duration of the retention of personal information, we consider the amount, nature and sensitivity of the personal information, the potential risk of harm from unauthorized use or disclosure of personal information and if we can attain our objectives by other means, as well as our legal, regulatory, tax, accounting and other applicable obligations.
                                                                           <br />
                                                                           As to each of the categories of personal information collected, the retention period will vary depending on our relationship.
                                                                           For example,
                                                                            <ul style={{ fontSize: 15, color: "rgb(89, 89, 89)", paddingLeft: "15px", listStyle: "auto" }}>
                                                                             <li style={{textDecoration: "underline"}}>For Venues and Venues Employees, we will generally retain their personal information for the duration of our agreement with the Venue plus a period following termination as provided for in our retention schedules (30 days).</li>
                                                                             <li style={{textDecoration: "underline"}}>For Guests that have VenueBoost Accounts, VenueBoost will generally maintain these accounts for the duration of the individual’s use of service plus a period of inactivity.</li>
                                                                             <li style={{textDecoration: "underline"}}>TIn other cases, Guest information that is collected by the Venues but stored by VenueBoost will be retained for the duration of our agreement with the Venues plus a period following termination as provided for in our retention schedules. </li>
                                                                             <li style={{textDecoration: "underline"}}>Information pertaining to support calls are generally retained for one (1) year but may be retained for longer based on the nature of the relationship between VenueBoost and the individual.</li>
                                                                            </ul>
                                                                           
                                                                         </p>
                                                                       </div>
                                                                     </div>

                                                                   </div>


                                                      <div
                                                  id="policyupdates"
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                        <div style={{ lineHeight: "1.5" }}><br /></div>
                                                  <span
                                                    style={{
                                                      color:
                                                        "rgb(127, 127, 127)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                        fontSize: 15
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)"
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span
                                                            id="control"
                                                            style={{
                                                              color:
                                                                "rgb(0, 0, 0)"
                                                            }}
                                                          >
                                                            <strong>
                                                              <span data-custom-class="heading_1">
                                                                11. DO WE MAKE
                                                                UPDATES TO THIS
                                                                NOTICE?
                                                              </span>
                                                            </strong>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <em>
                                                          <strong>
                                                            In Short:&nbsp;
                                                          </strong>
                                                          Yes, we will update
                                                          this notice as
                                                          necessary to stay
                                                          compliant with
                                                          relevant laws.
                                                        </em>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        We may update this
                                                        privacy notice from time
                                                        to time. The updated
                                                        version will be
                                                        indicated by an updated{" "}
                                                        <bdt className="block-component" />
                                                        "Revised"
                                                        <bdt className="statement-end-if-in-editor" />{" "}
                                                        date and the updated
                                                        version will be
                                                        effective as soon as it
                                                        is accessible. If we
                                                        make material changes to
                                                        this privacy notice, we
                                                        may notify you either by
                                                        prominently posting a
                                                        notice of such changes
                                                        or by directly sending
                                                        you a notification. We
                                                        encourage you to review
                                                        this privacy notice
                                                        frequently to be
                                                        informed of how we are
                                                        protecting your
                                                        information.
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  id="contact"
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        "rgb(127, 127, 127)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                        fontSize: 15
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)"
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span
                                                            id="control"
                                                            style={{
                                                              color:
                                                                "rgb(0, 0, 0)"
                                                            }}
                                                          >
                                                            <strong>
                                                              <span data-custom-class="heading_1">
                                                                12. HOW CAN YOU
                                                                CONTACT US ABOUT
                                                                THIS NOTICE?
                                                              </span>
                                                            </strong>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        If you have questions or
                                                        comments about this
                                                        notice, you may{" "}
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <bdt className="block-component" />
                                                            email us at{" "}
                                                            <bdt className="question">
                                                              contact@venueboost.io
                                                            </bdt>
                                                            <bdt className="statement-end-if-in-editor" />
                                                          </span>
                                                        </span>
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              &nbsp;or contact
                                                              us by post at:
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <bdt className="question">
                                                                  VenueBoost
                                                                  Inc.
                                                                </bdt>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <bdt className="block-component" />
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <bdt className="question">
                                                        16192 Coastal Highway
                                                      </bdt>
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)"
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <bdt className="block-component" />
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <bdt className="question">
                                                        Lewes
                                                      </bdt>
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)"
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          ,{" "}
                                                          <bdt className="question">
                                                            DE
                                                          </bdt>
                                                          <bdt className="statement-end-if-in-editor" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />{" "}
                                                          <bdt className="question">
                                                            19958
                                                          </bdt>
                                                          <bdt className="statement-end-if-in-editor" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    data-custom-class="body_text"
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <bdt className="question">
                                                      United States
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <bdt className="statement-end-if-in-editor" />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </bdt>
                                                  </span>
                                                  <bdt className="block-component">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text" />
                                                    </span>
                                                  </bdt>
                                                  <bdt className="statement-end-if-in-editor" />
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <bdt className="statement-end-if-in-editor" />
                                                      </span>
                                                    </span>
                                                  </span>
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <bdt className="statement-end-if-in-editor">
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="block-component">
                                                                    <bdt className="block-component" />
                                                                  </bdt>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </bdt>
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <bdt className="block-component">
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      />
                                                    </bdt>
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <bdt className="statement-end-if-in-editor">
                                                                <bdt className="block-component" />
                                                              </bdt>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  id="request"
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        "rgb(127, 127, 127)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                        fontSize: 15
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)"
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span
                                                            id="control"
                                                            style={{
                                                              color:
                                                                "rgb(0, 0, 0)"
                                                            }}
                                                          >
                                                            <strong>
                                                              <span data-custom-class="heading_1">
                                                                13. HOW CAN YOU
                                                                REVIEW, UPDATE,
                                                                OR DELETE THE
                                                                DATA WE COLLECT
                                                                FROM YOU?
                                                              </span>
                                                            </strong>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <bdt className="block-component" />
                                                        Based on the applicable
                                                        laws of your country,
                                                        you may have the right
                                                        to request access to the
                                                        personal information we
                                                        collect from you, change
                                                        that information, or
                                                        delete it.{" "}
                                                        <bdt className="else-block">
                                                          <bdt className="block-component" />
                                                          To request to review,
                                                          update, or delete your
                                                          personal information,
                                                          please{" "}
                                                          <bdt className="block-component" />
                                                          fill out and submit
                                                          a&nbsp;
                                                        </bdt>
                                                      </span>
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(48, 48, 241)"
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <span
                                                            style={{
                                                              fontSize: 15
                                                            }}
                                                          >
                                                            <a
                                                              data-custom-class="link"
                                                              href="#"
                                                              rel="noopener noreferrer"
                                                              target="_blank"
                                                            >
                                                              data subject
                                                              access request
                                                            </a>
                                                          </span>
                                                        </span>
                                                      </span>
                                                      <bdt className="block-component">
                                                        <span data-custom-class="body_text" />
                                                      </bdt>
                                                    </span>
                                                  </span>
                                                  <span data-custom-class="body_text">
                                                    .
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  );
};

export default PrivacyPolicy;
