import React from 'react';
import Faq from '../../../components/Faq';

export default function Faqs() {
  const venueBoostAffiliateFAQ = [
    {
      id: 1,
      status: false,
      title: 'What is the VenueBoost Affiliate Program?',
      content: "The VenueBoost Affiliate Program allows partners to earn commissions by promoting\n" +
          "VenueBoost's platform to their audiences and networks. You'll receive a commission for each\n" +
          "successful referral.",
    },
    {
      id: 2,
      status: false,
      title: 'How can I become a VenueBoost Affiliate?',
      content: "Joining is simple! Just sign up through our affiliate registration page. Once approved, you'll get\n" +
          "access to your personalized affiliate dashboard."
    },
    {
      id: 3,
      status: false,
      title: "What commissions can I earn?",
      content: "Affiliates can enjoy up to 40% commission for each referred customer's subscription. That\n" +
          "means you can benefit from recurring income."
    },
    {
      id: 4,
      status: false,
      title: "Is there support for affiliates?",
      content: "Absolutely! We provide resources and support for your success. You'll have access to\n" +
          "marketing materials, a personal affiliate manager, and promotional guidance."
    },
    {
      id: 5,
      status: false,
      title: "Are there any costs to join the program?",
      content: "No, joining the VenueBoost Affiliate Program is entirely free. There are no upfront fees or\n" +
          "hidden costs."
    },
    {
      id: 6,
      status: false,
      title: "How do I track my referrals and commissions?",
      content: "You can easily monitor your referrals, commissions, and performance through your dedicated\n" +
          "affiliate dashboard, which offers real-time data and transparent reporting."
    },
  ];

  return (
    <div className='flex flex-col bg-transparent w-full px-4 xl:px-14 pt-8 xl:pt-20 pb-4 xl:pb-8 gap-4 xl:gap-8 '>
      <span className='text-xl xl:text-[42px] font-extrabold text-white text-center'>
        Frequently Asked Questions (FAQ(s))
      </span>
      <span className='text-[#D2D2D2] text-lg xl:text-2xl text-center'>
     VenueBoost Affiliate Program
      </span>
      <Faq faqData={venueBoostAffiliateFAQ} className="mt-2 mb-4 md:mb-16 rounded-lg" />
    </div>
  )
}
