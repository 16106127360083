import React from "react";
import "../index.css";

const ProductList = ({ products = [] }) => {
  return (
    <div className="border-none overflow-y-hidden bg-white rounded-2xl flex flex-col md:px-4 pb-4 gap-3">
      {products.map((product) => (
        <div
          key={product.id}
          className="bg-white flex flex-col gap-[15.5px] text-neutral-neutral-800"
        >
          <div
            className="bg-white h-11 flex flex-row items-start justify-between py-[11px] px-0 gap-[20px]"
            style={{ paddingLeft: product.imgSrc ? "0px" : "10px" }}
          >
            <div className="mt-[-20px] flex flex-row gap-[8px]">
              {product.imgSrc && (
                <div className="h-[62px] w-[33px] relative overflow-hidden shrink-0">
                  <img
                    className="absolute top-[calc(50%_-_19.9px)] left-[calc(50%_-_10.6px)] w-[21.7px] h-[40.3px] object-contain"
                    loading="lazy"
                    alt=""
                    src={product.imgSrc}
                  />
                </div>
              )}
              <div className="flex flex-col pt-5 px-0 pb-0">
                <div className="relative font-bold leading-[22px] inline-block min-w-[123px]">
                  {product.name} ( {product?.size})
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center justify-start gap-[3px] text-neutral-neutral-400 font-button-medium-extrabold">
              <div className="flex flex-row gap-[2px]">
                <b className="font-bold relative leading-[20px] inline-block min-w-[9px] text-[#A5A5BA]">
                  {product.value}
                </b>
                <b className="relative text-5xs text-[#C0C0CF] leading-[22px] inline-block text-neutral-neutral-300 min-w-[6px]">
                  X
                </b>
              </div>
              <div className="flex flex-row gap-[1px] text-5xs text-lightslategray">
                <div className="flex flex-col pt-0 px-0 pb-[5px]">
                  <b className="text-[#8E8EA9] currency-product relative leading-[15px] inline-block min-w-[15px]">
                    {product.currency}
                  </b>
                </div>
                <b className="text-[#17CE8C] relative text-sm leading-[20px] inline-block font-manrope text-mediumseagreen min-w-[27px]">
                  {product.price}
                </b>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProductList;
