import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {verifyEmail} from "../../../redux/actions/contactsales";
import {Spin} from "antd";

const VerifyEmail = () => {
  const navigate = useNavigate();
  const {token} = useParams();
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(null);
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    doVerifyEmail();
  }, [token]);

  const doVerifyEmail = () => {
    setLoading(true);
    verifyEmail({token})
      .then(data => {
        setLoading(false);
        setHasError(false);
        setTimeout(() => {
          // navigate(
          //   `/${data?.contact_token}/apply/complete-registration/choose-plan-and-pay`
          // );
          navigate(`/`);
        }, 3000);
      })
      .catch(error => {
        setLoading(false);
        setHasError(true);
        setErrMsg(error?.message || "Invalid link");
      });
  };

  const onGoDashboard = () => {
    navigate("/");
  };

  return (
    <div className={"align-col-middle view-terms my-10"}>
      <div className='w-full rounded-4xl p-14 gap-10'>
        <div className='relative started-form'>
          <div className='flex justify-center w-full '>
            {loading ? (
              <Spin />
            ) : hasError === false ? (
              <div className='flex flex-col justify-center items-center'>
                <p className=' text-primary1 my-5 text-28 w-[60%] ms:w-full text-center'>
                  Your email address has been successfully verified. Thank you
                  for confirming your email!
                </p>
              </div>
            ) : (
              hasError === true && (
                <div className='flex flex-col justify-center items-center'>
                  <p className=' text-red-600 my-5 text-28  text-center'>
                    {errMsg}
                  </p>
                  <button
                    className={`  hover:bg-secondary1 bg-primary1 text-white rounded-md font-normal text-18 mt-5  px-10`}
                    onClick={onGoDashboard}
                  >
                    Go Dashboard
                  </button>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
