export const SET_CONTACT_SALES = "SET_CONTACT_SALES";
export const SET_RESTAURANT_DATA = "SET_RESTAURANT_DATA";

export const SET_CHAT_MESSAGES = "SET_CHAT_MESSAGES";
export const SET_CHAT_LAST_MSG = "SET_CHAT_LAST_MSG";

export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_BYBESTLANGUAGE = "SET_BYBESTLANGUAGE";
export const SET_ACCOMMODATIONLANGUAGE = "SET_ACCOMMODATIONLANGUAGE";
export const SET_COUNTRY_CODE = "SET_COUNTRY_CODE";
export const SET_REFERRAL_CODE = "SET_REFERRAL_CODE";
export const SET_AFFILIATE_CODE = "SET_AFFILIATE_CODE";
export const SET_SHOW_TOP_BTN = "SET_SHOW_TOP_BTN";

export const SET_CART = "SET_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const ADD_TO_CART_BILLING_DETAILS = "ADD_TO_CART_BILLING_DETAILS";
export const REMOVE_FROM_CART_BILLING_DETAILS =
  "REMOVE_FROM_CART_BILLING_DETAILS";
export const ADD_TO_CART_COUPON = "ADD_TO_CART_COUPON";
export const REMOVE_FROM_CART_COUPON = "REMOVE_FROM_CART_COUPON";
export const CLEAR_CHECKOUT_FORM = "CLEAR_CHECKOUT_FORM";
export const SET_CART_SHIPPING_METHOD = "SET_CART_SHIPPING_METHOD";

export const SET_ORDER_SUCCESS_DATA = "SET_ORDER_SUCCESS_DATA";
export const CLEAR_ORDER_SUCCESS_DATA = "CLEAR_ORDER_SUCCESS_DATA";

export const SET_VENUE_DATA = "SET_VENUE_DATA";
export const SET_SEARCH = "SET_SEARCH";
// APP
export const APP_SET_LOGIN_USER = "APP_SET_LOGIN_USER";
export const APP_SET_LOGOUT = "APP_SET_LOGOUT";
