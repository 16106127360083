import React, { useEffect, useState } from "react";
import FormTextInput from "../../../components/FormTextInput";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import { NotificationManager } from "react-notifications";
import "./index.css";
import { useNavigate } from "react-router-dom";
import RouteNames from "../../../constants/route_names";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { loginAffiliate } from "../../../redux/actions/auth";

const validator = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required("Email is required")
    .max(72, "Max exceeded")
    .min(5, "Min not met")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email"
    ),
  password: yup
    .string()
    .trim()
    .required("Password is required")
    .max(250, "Max exceeded")
    .min(6, "Min not met"),
});

const AffiliatesLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);
  const formikProps = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validator,
    onSubmit: (values) => onSubmit(values),
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    window.sessionStorage.clear();
  }, []);

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  const handleChange = (e) => {
    formikProps.setFieldValue([e.target.name], e.target.value);
  };

  const onSubmit = (values) => {
    dispatch(loginAffiliate(values))
      .then((data) => {
        formikProps.resetForm();
      })
      .catch((error) => {
        NotificationManager.error(
          error.error || "Oops! Something went wrong. Please try again or contact us directly at contact@venueboost.io.",
          "Error",
          3000
        );
        // throw new Error("Error on login affiliate");
      });
  };

  const onRegister = () => {
    navigate(RouteNames.affiliates_register)
  }

  return (
    <div className="flex flex-col align-col-middle view-terms">
      <div className="py-10 w-full md:w-[40%]">
        <h1 className="text-24 md:text-28 font-bold leading-3 md:leading-[34px] text-[#240b3b]">
          Log In
        </h1>
        <p className="mt-5 text-18 md:text-20 font-medium leading-7 md:leading-[34px] text-[#240b3b]">
          Login into your <span className="font-bold"> Affiliate Dashboard</span>
        </p>
        <div className="mt-6 md:mt-10">
          <p className="mt-3 md:mt-5 mb-2 md:mb-3 text-[#240b3b] text-20 leading-[34px] font-semibold">
            Email Address
          </p>
          <FormTextInput
            enableLabel={false}
            name="email"
            type={"email"}
            placeholder="Enter email address"
            value={formikProps.values.email}
            error={formikProps.errors.email}
            onChange={handleChange}
          />

          <p className="mt-3 md:mt-5 mb-2 md:mb-3 text-[#240b3b] text-20 leading-[34px] font-semibold">
            Password
          </p>
          <div className="w-full h-[48px] px-5 border-[#CBD5E1] border-[1px] rounded-[5px] flex align-middle focus:border-blue-400 ">
            <input
              type={type}
              name="password"
              placeholder="Enter your password"
              value={formikProps.values.password}
              error={formikProps.errors.password}
              onChange={handleChange}
              autoComplete="current-password"
              className="flex-1 border-none focus-visible:border-none focus-visible:outline-none"
            />
            <span
              className="flex justify-around items-center"
              style={{ color: "#CBD5E1" }}
              onClick={handleToggle}
            >
              <Icon class="absolute " icon={icon} size={18} />
            </span>
          </div>
          {/*<div className=" mt-4">*/}
          {/*  <a className="text-[#707070] flex-1 text-20 font-medium leading-[34px]">*/}
          {/*    Forgot password?*/}
          {/*  </a>*/}
          {/*</div>*/}

          <button className="mt-6 md:mt-10 text-white text-20 font-bold bg-[#240b3b] border-[1px] border-[#CBD5E1] rounded-[5px] h-[60px] md:h-[70px] w-full"
            type="button"
            onClick={formikProps.handleSubmit}
          >
            Log In
          </button>
          <p className="text-[#707070] font-medium text-[18px] text-center my-5">
            Don't have an affiliate account?
          </p>
          <button className="text-[#240b3b] text-20 font-bold bg-white border-[1px] border-[#240b3b] rounded-[5px] h-[60px] md:h-[70px] w-full"
            onClick={onRegister}
          >
            Sign Up
          </button>
        </div>
      </div>
    </div>
  );
};

export default AffiliatesLogin;
