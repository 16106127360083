import React, { useState } from "react";
import { blogDetailsData } from "./blog-details-data";
import { Box } from "@mui/system";
import TextIcon from "../../assets/images/text-icon.png";
import SlideDots from "./slide-dots";
import { useKeenSlider } from "keen-slider/react";

export const RelatedBlogs = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const [sliderRef, instanceRef] = useKeenSlider({
    slides: {
      perView: 1,
    },
    breakpoints: {
      "(min-width: 768px)": {
        slides: {
          perView: 3,
          spacing: 16,
        },
      },
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });
  return (
    <div
      style={{ paddingLeft: "16px", paddingRight: "16px" }}
      className="w-full mt-6 mb-6"
    >
      <div className="">
        <div className="flex items-center">
          <img src={TextIcon} className="w-[35px] h-[35px]" alt="" />
          <h2 className="font-bold text-24 ml-4">Related Blogs</h2>
        </div>
        <div className="flex gap-[20px] flex-col md:flex-row mt-8">
          <Box ref={sliderRef} className="keen-slider">
            {blogDetailsData.map((article) => {
              return (
                <Box
                  className="keen-slider__slide px-8 py-8 shadow-lg rounded-md border border-gray-300 flex flex-col gap-[20px] justify-between"
                  key={article.id}
                >
                  <h2 className="text-22 font-semibold">{article.title}</h2>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <img
                        className="rounded-full"
                        src={article.author_avatar}
                        width="34"
                        height="34"
                        alt=""
                      />
                      <h4 className="text-sm font-semibold ml-2">
                        {article.author_name}
                      </h4>
                    </div>
                    <p className="text-sm text-slate-500">
                      {article.time_read} min read
                    </p>
                  </div>
                </Box>
              );
            })}
          </Box>
        </div>
        {loaded && instanceRef.current && (
          <SlideDots
            currentSlide={currentSlide}
            instanceRef={instanceRef}
            key="SlideDots"
          />
        )}
      </div>
    </div>
  );
};
